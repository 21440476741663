import React from 'react';
import { overrideClassName } from '../index';
import InputMode from '../inputMode';

let FormSection = (props) => {
  let { mode = InputMode.PRIMARY, label, sublabel, children, divClassName, childClassName } = props;
  let modeStyles = {
    [InputMode.PRIMARY]: 'text-zinc-900',
    [InputMode.ADMIN]: 'text-admin-normal'
  };
  return (
    <div
      className={overrideClassName(
        `${label ? 'pt-8' : ''} space-y-6 ${label ? 'sm:pt-10' : ''} sm:space-y-5`,
        divClassName
      )}>
      {(label || sublabel) && (
        <div>
          {label && <h3 className={`text-lg leading-6 font-medium ${modeStyles[mode]}`}>{label}</h3>}
          {sublabel && <p className="mt-1 max-w-2xl text-sm text-zinc-500">{sublabel}</p>}
        </div>
      )}
      <div className={overrideClassName(`mt-6 sm:mt-5 space-y-6 sm:space-y-5`, childClassName)}>{children}</div>
    </div>
  );
};
export default FormSection;
