import React, { useCallback, useRef } from 'react';
import { Group, Rect, Text } from 'react-konva';

let RECT_WIDTH = 60;
let RECT_HEIGHT = 100;
let PADDING = 20;

let LayoutPlot = (props) => {
  let { i, i2, plot, onClick, count, pi } = props;
  let groupRef = useRef<any>(null);

  let x = i2 * (RECT_WIDTH + 10) + PADDING;
  let y = i * (RECT_HEIGHT + 10) + PADDING;
  if (count > 1) {
    y += (RECT_HEIGHT / count) * pi;
  }

  let texts: string[] = [];
  if (plot) {
    if (plot.birth) {
      texts.push(`Born: ${plot.birth}`);
    }
    if (plot.death) {
      texts.push(`Died: ${plot.death}`);
    }
    if (plot.notes) {
      texts.push(plot.notes);
    }
  }

  let mouseEnter = useCallback((e) => {
    const container = e.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'pointer';
    }
  }, []);
  let mouseLeave = useCallback((e) => {
    const container = e.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'default';
    }
  }, []);
  let mouseMove = useCallback(() => {
    if (groupRef.current) {
      groupRef.current.moveToTop();
      groupRef.current.show();
    }
  }, []);
  let mouseOut = useCallback(() => {
    if (groupRef.current) {
      groupRef.current.hide();
    }
  }, []);

  return (
    <React.Fragment key={`${i}_${i2}`}>
      <Group
        perfectDrawEnabled={false}
        x={x}
        y={y}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onMouseMove={mouseMove}
        onMouseOut={mouseOut}
        onClick={onClick}
        onTap={onClick}>
        <Rect
          perfectDrawEnabled={false}
          width={RECT_WIDTH}
          height={RECT_HEIGHT / count}
          fill={plot.empty ? '#ddd' : '#a7e2fa'}
          strokeWidth={1}
          stroke="#999"
        />
        <Text text={plot.row} x={5} y={100 / count - 15} fill="#555" />
        <Text text={plot.plot} x={56 - ((plot.plot || '').length * 8)} y={100 / count - 15} align="right" fill="#555" />
        <Text text={plot.last} x={5} y={5} width={RECT_WIDTH - 10} height={RECT_HEIGHT / count} ellipsis={true} wrap="none" />
      </Group>
      {!plot.empty && (
        <Group ref={groupRef} visible={false} perfectDrawEnabled={false} x={x} y={y}>
          <Rect
            perfectDrawEnabled={false}
            fill="white"
            x={50}
            y={85 / count}
            width={180}
            height={30 + texts.length * 15}
            strokeWidth={1}
            stroke="#999"
          />
          <Text text={`${plot.last}, ${plot.first}`} perfectDrawEnabled={false} x={55} y={85 / count + 10} fontStyle="bold" />
          {texts.map((text, i) => (
            <Text text={text} key={i} perfectDrawEnabled={false} x={55} y={(85 / count + 25) + i * 15} />
          ))}
        </Group>
      )}
    </React.Fragment>
  );
};
export default LayoutPlot;
