import React, { Fragment } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { Icon, IconMode, Icons, theme, RawButton } from '../index';

let Dialog = (props) => {
  let { children, title, description, isOpen, closeAction, raw, initialFocus } = props;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog
        open={isOpen}
        as="div"
        onClose={closeAction}
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={initialFocus}>
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <HeadlessDialog.Overlay className="fixed inset-0" style={{ background: theme.overlayBackdrop }} />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="relative bg-white rounded max-w-[95vw] mx-auto min-w-[250px]">
              <div className="p-4">
                <HeadlessDialog.Title as="h3" className="flex items-center font-medium text-lg leading-6 text-zinc-900">
                  <div className="flex-auto truncate">{title}</div>
                  {raw && <RawButton data={raw} />}
                  <Icon icon={Icons.CLOSE} onClick={closeAction} mode={IconMode.PLAIN} />
                </HeadlessDialog.Title>
                {description && (
                  <HeadlessDialog.Description className="mt-1 max-w-2xl text-sm text-zinc-500">
                    {description}
                  </HeadlessDialog.Description>
                )}
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

export default Dialog;
