import React from 'react';
import useAuth from '@src/auth/useAuth';
import Login from '@src/auth/login';
import { Icons, IconSize, Icon } from '@src/components';
import {Outlet} from "react-router-dom";

let Protected = (props) => {
  let { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <Icon size={IconSize.MD} icon={Icons.LOADING} aria-hidden="true" className="animate-spin" />
      </div>
    );
  }
  if (!user) {
    return <Login />;
  }

  return <Outlet />;
};

export default Protected;
