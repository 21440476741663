import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormTemplate, AbsoluteFormWrapper } from '@src/components';
import UserFields from '@src/users/userFields';
import useAuth from '@src/auth/useAuth';
import { deleteUser, getUser, updateUser } from '@src/users/data';

let User = (props) => {
  let { db } = useAuth();
  let { userID } = useParams();
  let queryClient = useQueryClient();
  let { data, isLoading } = useQuery(['user', userID], getUser.bind(null, { params: { id: userID } }));
  let navigate = useNavigate();
  let updateMutation = useMutation(
    (newItem: any) => {
      return updateUser({
        db,
        data: {
          id: userID,
          ...newItem
        }
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('user');
        navigate('../');
      }
    }
  );
  let deleteMutation = useMutation(
    () => {
      return deleteUser({ db, params: { id: userID } });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('user');
        navigate(`../`);
      }
    }
  );

  return (
    <AbsoluteFormWrapper>
      <FormTemplate
        loading={isLoading}
        submitAction={updateMutation.mutateAsync}
        deleteAction={deleteMutation.mutate}
        title="User"
        breadcrumbsProps={{ pages: [{ label: 'Users' }], currentLabel: 'User' }}
        initialValues={data}>
        <UserFields />
      </FormTemplate>
    </AbsoluteFormWrapper>
  );
};
export default User;
