import React from 'react';
import { PageWrapper, Table, Button, useWindowState } from '@src/components';
import { Outlet } from 'react-router-dom';
import { exportPlots, getPlots, importPlots } from '@src/plots/data';
import { useQueryClient } from 'react-query';
import useAuth from '@src/auth/useAuth';

let rowOptions: any = [];
for (let i = 1; i <= 11; i++) {
  rowOptions.push({ label: i + 'E', value: i + 'E' });
  rowOptions.push({ label: i + 'W', value: i + 'W' });
}

let Plots = () => {
  let { db, user } = useAuth();
  let queryClient = useQueryClient();
  let { width } = useWindowState();

  let columns = [
    {
      dataKey: 'row',
      label: 'Row',
      width: 50,
      flexGrow: 0,
      filter: rowOptions
    },
    {
      dataKey: 'plot',
      label: 'Plot',
      width: 50,
      flexGrow: 0
    },
    {
      dataKey: 'name',
      label: 'Name',
      cellRenderer: ({ rowData }) => <span className="self-center truncate">{`${rowData.last}, ${rowData.first}`}</span>
    },
    ...(width > 1000
      ? [
          {
            dataKey: 'birth',
            label: 'Birth',
            valueType: 'date'
          },
          {
            dataKey: 'death',
            label: 'Death',
            valueType: 'date'
          },
          {
            dataKey: 'notes',
            label: 'Notes'
          }
        ]
      : [])
  ];

  return (
    <PageWrapper title="Cemetery Plots">
      <Table
        columns={columns}
        dataKey={['plot']}
        data={getPlots(db)}
        initialSort={{ row: 'ASC' }}
        getRowLink={(p: any) => `${p?.rowData?.id}`}
        otherButtons={
          <div className="flex space-x-2">
            {user?.email === 'jamesbillinger@gmail.com' && (
              <Button onClick={importPlots(queryClient, db)}>Import</Button>
            )}
            <Button onClick={exportPlots}>Export</Button>
          </div>
        }
        add="new"
      />
      <Outlet />
    </PageWrapper>
  );
};

export default Plots;
