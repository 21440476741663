import React, { useEffect, useState } from 'react';
import Badge from '../badge';
import format from 'date-fns/format';

let TopFilterBadge = (props) => {
  let { input, column } = props;
  let [label, setLabel] = useState(typeof column?.filter === 'function' ? '' : input.value);
  if (column.filter === 'dateRange') {
    let l: any = [];
    if (input.value?.from) {
      l.push(`From: ${format(new Date(input.value.from), 'yyyy-MM-dd')}`);
    }
    if (input.value?.to) {
      l.push(`To: ${format(new Date(input.value.to), 'yyyy-MM-dd')}`);
    }
    label = l.join(', ');
  } else if (column.filter === 'date') {
    if (input.value) {
      label = `${format(new Date(input.value), 'yyyy-MM-dd')}`;
    } else {
      label = '';
    }
  }
  useEffect(() => {
    let fn = async () => {
      if (typeof column.filter === 'function') {
        if (input.value === null && column.nullFilterLabel) {
          setLabel(column.nullFilterLabel);
        } else {
          let ret = await column.filter({ data: { values: [input.value] } });
          setLabel(ret?.[0]?.label || input.value);
        }
      }
    };
    fn();
  }, [column, input.value]);

  return <Badge removeAction={() => input.onChange(null)}>{label}</Badge>;
};
export default TopFilterBadge;
