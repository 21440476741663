import React, { useCallback, useState, useRef, useEffect } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import RVTable from 'react-virtualized/dist/commonjs/Table';
import Column from 'react-virtualized/dist/commonjs/Table/Column';
import HeaderRow from './headerRow';
import HeaderCell from './headerCell';
import Row from './row';
import Cell from './cell';
import { overrideClassName } from '../index';
import TopFilter from './topFilter';
import TableFooter from './tableFooter';
import { defaultCellDataGetter, loadInitialData, loadMoreData } from './utils';
import { useQueryClient, useQuery } from 'react-query';

let defaultDataKey = [new Date().toISOString()];

let Table = (props) => {
  let _table = useRef<RVTable>();
  let _loadedRows = useRef({});
  let _rows = useRef([]);
  let {
    className,
    data,
    minimumBatchSize = 100,
    initialFilter,
    initialSort,
    rowHeight = 52,
    headerHeight = 48,
    onRowClick,
    columns,
    getRowLink,
    showTopFilter = true,
    showSearch = true,
    showFooter = true,
    add,
    dataKey = defaultDataKey,
    checkboxKey,
    checkboxID = 'id',
    dataParams,
    staticFilter,
    title,
    otherButtons,
    autoSize,
    noRowsRenderer,
    searchOnKeyDown
  } = props;
  let queryClient = useQueryClient();
  let [filter, setFilter] = useState<{}>(initialFilter);
  let [sort, setSort] = useState<{}>(initialSort);
  let _sort = useRef(sort);
  let _filter = useRef(filter);
  let queryData: any = useQuery([...dataKey, filter, sort, dataParams, staticFilter], () =>
    loadInitialData({ data, _filter, _sort, minimumBatchSize, columns, _loadedRows, _rows, dataParams, staticFilter })
  );
  let _dataTS = useRef();

  let updated = false;
  if (queryData.data?.dataTS && _dataTS.current !== queryData.data.dataTS) {
    _rows.current = queryData.data.items;
    updated = true;
  }
  if (_dataTS.current !== queryData.data?.dataTS) {
    _dataTS.current = queryData.data?.dataTS;
  }

  useEffect(() => {
    if (updated) {
      _table.current?.forceUpdateGrid();
    }
  }, [updated]);

  let isRowLoaded = useCallback(({ index }) => {
    return !!_loadedRows.current[index];
  }, []);

  let rowGetter = useCallback(({ index }) => {
    return _rows.current[index] || {};
  }, []);

  let headerClick = useCallback((sortKey) => {
    _sort.current = {
      [sortKey]: _sort.current?.[sortKey] === 'ASC' ? 'DESC' : 'ASC'
    };
    setSort(_sort.current);
  }, []);

  let mySetFilter = useCallback((f) => {
    _filter.current = f;
    setFilter(_filter.current);
  }, []);

  let loadMoreRows = useCallback(
    ({ startIndex, stopIndex }) => {
      let fn = async () => {
        await loadMoreData({
          data,
          _filter,
          _sort,
          _loadedRows,
          _rows,
          startIndex,
          stopIndex,
          dataParams,
          staticFilter
        });

        queryClient.setQueryData([...dataKey, filter, sort, dataParams, staticFilter], (d: any) => {
          /*let total = d?.total;
          if (total === 10000) {
            for (let i = startIndex; i < stopIndex; i++) {
              if (!_rows.current[i]) {
                total = i;
                break;
              }
            }
          }*/
          return {
            ...d,
            // total,
            items: _rows.current
          };
        });
      };
      if (typeof data === 'function') {
        fn();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, dataKey, queryClient, dataParams, staticFilter]
  );

  let style;
  if (autoSize) {
    style = {
      height: `${(queryData.data?.total || 1) * rowHeight + headerHeight + 2}px`
    };
  }

  return (
    <>
      {(showTopFilter || title) && (
        <TopFilter
          filter={filter}
          setFilter={mySetFilter}
          showSearch={showSearch}
          columns={columns}
          add={add}
          debounce={typeof data === 'function'}
          title={title}
          otherButtons={otherButtons}
          _rows={_rows}
          searchOnKeyDown={searchOnKeyDown}
        />
      )}
      <div
        className={overrideClassName('flex-auto flex flex-col border border-zinc-200 rounded-lg', className)}
        style={style}>
        <AutoSizer>
          {({height, width}) =>
            width ? (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={queryData.data?.total || 0}
                minimumBatchSize={minimumBatchSize}>
                {({onRowsRendered, registerChild}) => (
                  <RVTable
                    onRowsRendered={onRowsRendered}
                    ref={(k) => {
                      _table.current = k;
                      registerChild(k);
                    }}
                    width={width - 2} // account for borders
                    height={height - 2}
                    rowCount={queryData.data?.total || 0}
                    rowHeight={rowHeight}
                    headerHeight={headerHeight}
                    rowGetter={rowGetter}
                    gridStyle={{
                      willChange: 'unset'
                    }}
                    headerRowRenderer={HeaderRow({
                      checkboxKey,
                      rowCount: queryData.data?.total || 0,
                      _rows,
                      checkboxID
                    })}
                    noRowsRenderer={noRowsRenderer ? noRowsRenderer(_filter) ?? undefined : undefined}
                    rowRenderer={Row({getRowLink, checkboxKey, checkboxID, _rows})}
                    onRowClick={onRowClick}
                    gridClassName="bg-white rounded-b-lg">
                    {columns?.map((c, ci) => {
                      let defaultFlexGrow = {
                        string: 1,
                        number: 0,
                        datetime: 0,
                        date: 0,
                        boolean: 0
                      };
                      let defaultWidths = {
                        string: 100,
                        number: 80,
                        datetime: 180,
                        date: 120,
                        boolean: 80
                      };
                      let {
                        width = defaultWidths[c.valueType] ?? 100,
                        flexGrow = defaultFlexGrow[c.valueType] ?? 1,
                        ...cProps
                      } = c;
                      if (!cProps.cellDataGetter) {
                        cProps.cellDataGetter = defaultCellDataGetter;
                      }
                      if (!c.width && c.dataKey === 'id') {
                        width = 50;
                        flexGrow = 0;
                      }
                      return (
                        <Column
                          key={ci}
                          headerRenderer={HeaderCell(cProps, headerClick)}
                          cellRenderer={Cell(cProps)}
                          width={width}
                          flexGrow={flexGrow}
                          headerClassName={overrideClassName(
                            'flex mr-3 first-of-type:ml-6 last-of-type:mr-6 text-sm overflow-hidden',
                            c.headerClassName
                          )}
                          className={overrideClassName(
                            'flex mr-3 first-of-type:ml-6 last-of-type:mr-6 text-sm overflow-hidden',
                            c.className
                          )}
                          {...cProps}
                        />
                      );
                    })}
                  </RVTable>
                )}
              </InfiniteLoader>
            ) : null
          }
        </AutoSizer>
      </div>
      {showFooter && <TableFooter queryData={queryData}/>}
    </>
  );
};
export default Table;
