import React, { useCallback } from 'react';
import Button, { ButtonMode, ButtonSize } from '../button';
import { Icons, overrideClassName, useConfirm } from '../index';

let FormFooterFull = (props) => {
  let {
    closeAction,
    deleteAction,
    deleteLabel = 'Delete',
    deleteIcon = Icons.DELETE,
    publishAction,
    publishLabel = 'Publish',
    publishIcon = Icons.PUBLISH,
    submitAction,
    submitText = 'Save',
    cancelAction,
    resetAction,
    deleteConfirmText = 'Are you sure?',
    deleteConfirmDescription = 'This item will be deleted immediately',
    submitDisabled,
    formFooterClassName,
    dataCy,
    submitting
  } = props;

  let confirm = useConfirm();

  let myDeleteAction = useCallback(() => {
    let fn = async () => {
      if (await confirm(deleteConfirmText, deleteConfirmDescription)) {
        deleteAction();
      }
    };
    fn();
  }, [deleteAction, deleteConfirmText, deleteConfirmDescription, confirm]);

  return (
    <div
      data-testid={dataCy}
      className={overrideClassName(
        'sticky bottom-0 max-w-7xl px-4 py-5 flex border-t bg-white dark:bg-zinc-800 border-zinc-200 rounded-b-lg',
        formFooterClassName
      )}>
      {deleteAction && (
        <Button icon={deleteIcon} mode={ButtonMode.DANGER} onClick={myDeleteAction} data-cy={dataCy + 'DelBtn'}>
          {deleteLabel}
        </Button>
      )}
      {resetAction && (
        <Button warning={true} size={ButtonSize.SM} onClick={resetAction} icon="refresh" data-cy={dataCy + 'resetBtn'}>
          Reset
        </Button>
      )}
      <div className="flex-auto" />
      {publishAction && (
        <Button icon={publishIcon} mode={ButtonMode.WARNING} onClick={publishAction} data-cy={dataCy + 'PubBtn'}>
          {publishLabel}
        </Button>
      )}
      {(cancelAction || closeAction) && (
        <Button
          mode={ButtonMode.SECONDARY}
          size={ButtonSize.SM}
          className="ml-3"
          onClick={cancelAction || closeAction}
          icon="close"
          disabled={submitting}
          data-cy={dataCy + 'CanBtn'}>
          Cancel
        </Button>
      )}
      {submitAction && (
        <Button
          size={ButtonSize.SM}
          mode={ButtonMode.SUCCESS}
          onClick={submitAction}
          icon={'check'}
          className="ml-3"
          disabled={submitDisabled}
          loading={submitting}
          data-cy={dataCy + 'SubBtn'}>
          {submitText}
        </Button>
      )}
    </div>
  );
};

export default FormFooterFull;
