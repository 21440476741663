import React, { useCallback, useRef } from 'react';
import { Group, Rect, Text } from 'react-konva';

let RECT_WIDTH = 60;
let RECT_HEIGHT = 100;
let PADDING = 20;

let LayoutEmptyPlot = (props) => {
  let { i, i2, plot, onClick } = props;
  let groupRef = useRef<any>(null);

  let x = i2 * (RECT_WIDTH + 10) + PADDING;
  let y = i * (RECT_HEIGHT + 10) + PADDING;

  let mouseEnter = useCallback((e) => {
    const container = e.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'pointer';
    }
  }, []);
  let mouseLeave = useCallback((e) => {
    const container = e.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'default';
    }
  }, []);
  let mouseMove = useCallback(() => {
    if (groupRef.current) {
      groupRef.current.moveToTop();
      groupRef.current.show();
    }
  }, []);
  let mouseOut = useCallback(() => {
    if (groupRef.current) {
      groupRef.current.hide();
    }
  }, []);

  return (
    <React.Fragment key={`${i}_${i2}`}>
      <Group
        perfectDrawEnabled={false}
        x={x}
        y={y}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onMouseMove={mouseMove}
        onMouseOut={mouseOut}
        onClick={onClick}
        onTap={onClick}>
        <Rect
          perfectDrawEnabled={false}
          width={RECT_WIDTH}
          height={RECT_HEIGHT}
          fill={plot.empty ? '#ddd' : '#a7e2fa'}
          strokeWidth={1}
          stroke="#999"
        />
        <Text text={plot.row} x={5} y={85} fill="#555" />
        <Text text={plot.plot} x={45} y={85} align="right" fill="#555" />
      </Group>
    </React.Fragment>
  );
};
export default LayoutEmptyPlot;
