import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB7zYde3BVXgA70ybkwl1eZKvw289n3eYk',
  authDomain: 'cemetery-76a8c.firebaseapp.com',
  projectId: 'cemetery-76a8c',
  storageBucket: 'cemetery-76a8c.appspot.com',
  messagingSenderId: '839720611218',
  appId: '1:839720611218:web:62a670123c127feb0ce54b',
  measurementId: 'G-DZ1546M71E'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// noinspection JSUnusedLocalSymbols
const auth = getAuth(app);
// noinspection JSUnusedLocalSymbols
const db = getFirestore(app);
// eslint-disable-next-line
const analytics = getAnalytics(app);

interface AuthContextType {
  app: any;
  auth: any;
  db: any;
  user: any;
  loading?: boolean;
  error?: any;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

let AuthProvider = (props) => {
  let { children } = props;
  const [user, loading, error] = useAuthState(auth);
  return <AuthContext.Provider value={{ app, auth, db, user, loading, error }}>{children}</AuthContext.Provider>;
};
export default AuthProvider;