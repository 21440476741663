import React from 'react';
import ReactJSON from 'react-json-view';
import Dialog from './dialog';

let InspectorModal = (props) => {
  let { title, description, data, ...dialogProps } = props;
  return (
    <Dialog {...dialogProps}>
      <div data-testid={'inspectormodal'} className="flex flex-col overflow-auto w-[80vw] h-[80vh] p-4">
        {data && <ReactJSON src={data} displayDataTypes={false} name={null} quotesOnKeys={false} />}
      </div>
    </Dialog>
  );
};
export default InspectorModal;
