import { Icons } from '@src/components';
import { RouteType } from '@src/components/utils/types';
import Layout from '@src/plots/layout';

import Plots from './plots';
import Plot from './plot';
import NewPlot from './newPlot';

export let plotsLinks = [
  {
    path: '/',
    to: '/',
    label: 'Plots',
    icon: Icons.GRAVE_STONE,
    group: 'Cemetery'
  },
  {
    path: '/layout',
    to: '/layout',
    label: 'Layout',
    icon: Icons.MAP,
    group: 'Cemetery'
  }
];

export let plotsRoutes: RouteType[] = [
  {
    path: '/layout',
    element: <Layout />,
    children: [
      {
        path: 'new',
        element: <NewPlot />
      },
      {
        path: ':plotID',
        element: <Plot />
      }
    ]
  },
  {
    path: '/',
    element: <Plots />,
    children: [
      {
        path: 'new',
        element: <NewPlot />
      },
      {
        path: ':plotID',
        element: <Plot />
      }
    ]
  }
];
