import React, { ReactNode } from 'react';
import { Link, resolvePath, Path } from 'react-router-dom';
import Icon from './icon';

let Breadcrumbs = (props: {
  currentLabel?: string | ReactNode;
  showHome?: boolean;
  homePath?: string;
  pages?: { to?: string | Path; label: string }[];
}) => {
  let { currentLabel = null, showHome = true, homePath = '/', pages } = props;
  return (
    <nav className="flex flex-auto items-center" aria-label="Breadcrumb" data-testid={'breadcrumbs'}>
      <ol className="flex items-center space-x-4">
        {showHome && (
          <li>
            <div>
              <Link to={homePath} className="text-zinc-400 hover:text-zinc-500">
                <Icon icon="home" className="flex-shrink-0" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
        )}
        {pages?.map((page, pi) => {
          let to = page.to;
          if (!to) {
            to = '..';
            for (let n = (pages || []).length; n > pi + 1; n--) {
              to += '/..';
            }
            // adding to workaround this issue: https://github.com/remix-run/react-router/issues/8350
            to = resolvePath('..', window.location.pathname);
          }
          return (
            <li key={page.label}>
              <div className="flex items-center">
                {(showHome || pi > 0) && (
                  <Icon className="flex-shrink-0 text-zinc-400" aria-hidden="true" icon="chevron-right" />
                )}
                <Link
                  to={to}
                  className={`${
                    showHome || pi > 0 ? 'ml-4' : ''
                  } text-sm font-medium text-zinc-500 hover:text-label-text`}>
                  {page.label}
                </Link>
              </div>
            </li>
          );
        })}
        {currentLabel && (
          <li>
            <div className="flex items-center">
              <Icon className="flex-shrink-0 text-zinc-400" aria-hidden="true" icon="chevron-right" />
              <div className={`ml-4 text-sm font-medium text-zinc-500 hover:text-label-text`} aria-current="page">
                {currentLabel}
              </div>
            </div>
          </li>
        )}
      </ol>
      <div className="flex-auto" />
    </nav>
  );
};
export default Breadcrumbs;
