import React, { useState } from 'react';
import { Icon, IconMode, Icons, IconSize } from '../index';
import InspectorModal from './inspectorModal';

let RawButton = (props) => {
  let { data } = props;
  let [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Icon
        icon={Icons.CODE_JSON}
        onClick={setIsOpen.bind(null, true)}
        size={IconSize.MD}
        className="mr-2"
        mode={IconMode.PLAIN}
      />
      <InspectorModal data={data} isOpen={isOpen} closeAction={setIsOpen.bind(null, false)} />
    </>
  );
};
export default RawButton;
