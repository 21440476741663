import React, { useEffect, useState } from 'react';
import FormCheckbox from './formCheckbox';
import { isPromise, overrideClassName } from '../index';

let FormCheckboxList = (props) => {
  let { options, input, divClassName, dataParams, nullFilterLabel } = props;
  let [items, setItems] = useState<{ value: any; label: string }[]>(options && Array.isArray(options) ? options : []);

  useEffect(() => {
    let fn = async () => {
      if (options && (typeof options === 'function' || isPromise(options))) {
        let items = await options({
          data: { ...(dataParams || {}) }
        });
        setItems([...(nullFilterLabel ? [{ value: null, label: nullFilterLabel }] : []), ...items]);
        items?.forEach((item) => {});
      } else if (options && Array.isArray(options)) {
        setItems([...(nullFilterLabel ? [{ value: null, label: nullFilterLabel }] : []), ...options]);
      }
    };
    fn();
  }, [options, dataParams, nullFilterLabel]);

  let onChange = (item) => (val) => {
    let nv = (input.value || []).slice();
    if (val) {
      if (!nv.includes(item.value)) {
        nv.push(item.value);
      }
    } else {
      let i = nv.indexOf(item.value);
      if (i > -1) {
        nv = [...nv.slice(0, i), ...nv.slice(i + 1)];
      }
    }
    input.onChange(nv?.length ? nv : undefined);
  };

  return (
    <div className={overrideClassName('p-5', divClassName)}>
      {items?.map((item) => (
        <FormCheckbox
          key={item?.value}
          label={item?.label ?? item?.value}
          input={{ value: input.value?.includes(item?.value), onChange: onChange(item) }}
          labelProps={{
            onClick: () => {
              onChange(item)(!input.value?.includes(item?.value));
            }
          }}
          labelClassName="cursor-pointer"
          labelOnRight={true}
          divClassName="mt-0"
        />
      ))}
    </div>
  );
};
export default FormCheckboxList;
