const isEmpty = (value) => value === undefined || value === null || value === '';
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0 /* first error */];

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function username(value) {
  if (!isEmpty(value) && (value.indexOf(' ') > -1 || value.indexOf('#') > -1 || value.indexOf('$') > -1)) {
    return 'A valid username may not contain spaces or the characters # or $';
  }
}

export function required(value) {
  if (isEmpty(value) || (Array.isArray(value) && value.length === 0)) {
    return '* This field is required';
  }
}

export function password(value) {
  if (isEmpty(value)) {
    return '* This field is required';
  } else if (value.length < 8) {
    return '* Minimum 8 characters';
  } else if (!/[a-z]/.test(value)) {
    return '* Needs least 1 lowercase letter';
  } else if (!/[A-Z]/.test(value)) {
    return '* Needs at least 1 uppercase letter';
  } else if (!/\d/.test(value)) {
    return '* Needs at least 1 number';
    // eslint-disable-next-line
  } else if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
    return '* Needs at least 1 special character';
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `* Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `* Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  Number.isInteger =
    Number.isInteger ||
    function (value) {
      return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    };

  if (!Number.isInteger(Number(value))) {
    return '* Must be a number';
  }
}

function isFloat(val) {
  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) return false;

  val = parseFloat(val);
  if (isNaN(val)) return false;
  return true;
}

export function float(value) {
  if (!isFloat(value)) {
    return '* Must be a number';
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function isIPAddress(value) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    value
  );
}

export function isNetwork(value) {
  let reg = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|(3[0-2])|([1-2][0-9]))$/
  );
  if (!reg.test(value)) {
    return 'Not a valid Network';
  }
}

export function awsPhone(value) {
  if (value && value.length < 12) {
    return '* A valid phone number (15557779999) is required';
  }
}

let exp = {
  email,
  username,
  required,
  password,
  minLength,
  maxLength,
  integer,
  float,
  oneOf,
  match,
  createValidator,
  isIPAddress,
  isNetwork
};
export default exp;
