import React from 'react';
import { Field } from 'react-final-form';
import { FormInput, FormSection } from '@src/components';

let DeviceConfigFields = (props) => {
  return (
    <>
      <FormSection label="">
        <Field component={FormInput} name="name" label="Name" labelOnLeft={true} divClassName="sm:border-t-0" />
        <Field component={FormInput} name="email" label="Email" labelOnLeft={true} divClassName="sm:border-t-0" />
      </FormSection>
    </>
  );
};
export default DeviceConfigFields;
