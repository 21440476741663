export { default as overrideClassName } from './overrideClassName';
export { default as theme } from './theme';
export { default as validators } from './validators';
export * from './jest';

export let copyToClipboard = (str) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  window.alert('copied');
};

export let updateArrayItem = (array = [], item, options?: any) => {
  let { upsert, key = 'id' } = options || {};
  let i = array.findIndex((a: any) => a[key] === item[key]);
  if (i > -1) {
    return [...array.slice(0, i), item, ...array.slice(i + 1)];
  } else if (upsert) {
    return [...array.slice(), item];
  } else {
    return array;
  }
};

export let removeArrayItem = (array = [], item, options?: any) => {
  let { key = 'id' } = options || {};
  let i = array.findIndex((a: any) => a[key] === item[key]);
  if (i > -1) {
    return [...array.slice(0, i), ...array.slice(i + 1)];
  } else {
    return array;
  }
};

function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

export let generateId = (len) => {
  let arr = new Uint8Array((len || 40) / 2);
  window?.crypto?.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export let getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export let isPromise = (value) => Boolean(value && typeof value.then === 'function');
