import React from 'react';
import { Information, overrideClassName } from './index';
import InputMode from './inputMode';

let LabelledField = (props) => {
  let {
    divProps = {},
    divClassName,
    inputWrapperProps = {},
    inputWrapperClassName,
    labelProps = {},
    labelClassName,
    label,
    labelOnLeft,
    labelOnRight,
    children,
    htmlFor,
    meta,
    placeholder,
    info,
    mode = InputMode.PRIMARY
  } = props;
  let { error, touched } = meta || {};
  let labelComponent;

  let labelModeStyle = {
    [InputMode.PRIMARY]: 'text-label-text',
    [InputMode.DISABLED]: 'text-disabledButtonText-50',
    [InputMode.SUCCESS]: 'text-successButtonBg-50',
    [InputMode.DANGER]: 'text-dangerButtonBg-50',
    [InputMode.ADMIN]: 'text-adminButtonBg-50'
  };
  let hasLabel = !!label;

  if (label) {
    labelComponent = (
      <label
        htmlFor={htmlFor || label}
        className={overrideClassName(
          `block text-sm font-medium sm:mt-px sm:pt-2 ${labelModeStyle[mode]}`,
          labelClassName
        )}
        {...labelProps}>
        {info && <Information {...info} />}
        {label}
      </label>
    );
  } else if (placeholder) {
    labelComponent = (
      <label htmlFor={htmlFor || label} className="sr-only">
        {placeholder}
      </label>
    );
  }
  if (labelOnLeft) {
    return (
      <div
        className={overrideClassName(
          'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-zinc-200 sm:pt-5',
          divClassName
        )}
        {...divProps}>
        {labelComponent}
        <div
          className={overrideClassName(
            `relative ${hasLabel ? 'mt-1' : 'mt-0'} sm:mt-0 sm:col-span-2`,
            inputWrapperClassName
          )}
          {...inputWrapperProps}>
          {children}
          {touched && error && <div className={'text-red-600 text-sm whitespace-nowrap mt-0.5'}>{error}</div>}
        </div>
      </div>
    );
  } else if (labelOnRight) {
    return (
      <div className={overrideClassName('mt-4 flex items-center', divClassName)} {...divProps}>
        <div
          className={overrideClassName(
            `'relative ${hasLabel ? 'mt-1' : 'mt-0'} mr-3 flex-shrink-0'`,
            inputWrapperClassName
          )}
          {...inputWrapperProps}>
          {children}
        </div>
        {labelComponent}
      </div>
    );
  } else {
    return (
      <div className={overrideClassName('mt-4', divClassName)} {...divProps}>
        {labelComponent}
        <div
          className={overrideClassName(`relative ${hasLabel ? 'mt-1' : 'mt-0'}`, inputWrapperClassName)}
          {...inputWrapperProps}>
          {children}
        </div>
        {touched && error && <div className={'text-red-600 text-sm whitespace-nowrap mt-0.5'}>{error}</div>}
      </div>
    );
  }
};
export default LabelledField;
