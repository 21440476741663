import React from 'react';
import Button from './button';
import Breadcrumbs from './breadcrumbs';
import { overrideClassName } from './index';

let PageHeading = (props) => {
  let { breadcrumbsProps, title, currentLabel, subtitle, closeAction, children, className = '' } = props;
  return (
    <>
      <div
        data-testid={'pageHeading'}
        className={overrideClassName(`flex-none relative pt-4 px-4 md:min-h-[56px]`, className)}>
        {breadcrumbsProps && <Breadcrumbs currentLabel={currentLabel || title} {...breadcrumbsProps} />}
        <div className={`md:flex md:items-center md:justify-between ${breadcrumbsProps ? 'mt-2' : ''}`}>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-semibold text-zinc-900 sm:text-3xl sm:truncate">{title}</h2>
            {subtitle && (
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-2 sm:space-x-6">{subtitle}</div>
            )}
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            {children}
            {closeAction && <Button icon="close" onClick={closeAction} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default PageHeading;
