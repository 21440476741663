enum Icons {
  'S_MODULE' = 's-module',
  'AULT' = 'ault',
  'AB_TESTING' = 'ab-testing',
  'ABACUS' = 'abacus',
  'ABJAD_ARABIC' = 'abjad-arabic',
  'WRITING_SYSTEM_ARABIC' = 'writing-system-arabic',
  'ABJAD_HEBREW' = 'abjad-hebrew',
  'WRITING_SYSTEM_HEBREW' = 'writing-system-hebrew',
  'ABUGIDA_DEVANAGARI' = 'abugida-devanagari',
  'WRITING_SYSTEM_DEVANAGARI' = 'writing-system-devanagari',
  'ABUGIDA_THAI' = 'abugida-thai',
  'WRITING_SYSTEM_THAI' = 'writing-system-thai',
  'ACCESS_POINT' = 'access-point',
  'WIRELESS' = 'wireless',
  'ACCESS_POINT_CHECK' = 'access-point-check',
  'ACCESS_POINT_MINUS' = 'access-point-minus',
  'ACCESS_POINT_NETWORK' = 'access-point-network',
  'ACCESS_POINT_NETWORK_OFF' = 'access-point-network-off',
  'ACCESS_POINT_OFF' = 'access-point-off',
  'ACCESS_POINT_PLUS' = 'access-point-plus',
  'ACCESS_POINT_REMOVE' = 'access-point-remove',
  'ACCOUNT' = 'account',
  'PERSON' = 'person',
  'USER' = 'user',
  'ACCOUNT_ALERT' = 'account-alert',
  'USER_ALERT' = 'user-alert',
  'ACCOUNT_WARNING' = 'account-warning',
  'USER_WARNING' = 'user-warning',
  'PERSON_ALERT' = 'person-alert',
  'PERSON_WARNING' = 'person-warning',
  'ACCOUNT_ALERT_OUTLINE' = 'account-alert-outline',
  'USER_ALERT_OUTLINE' = 'user-alert-outline',
  'ACCOUNT_WARNING_OUTLINE' = 'account-warning-outline',
  'USER_WARNING_OUTLINE' = 'user-warning-outline',
  'PERSON_WARNING_OUTLINE' = 'person-warning-outline',
  'PERSON_ALERT_OUTLINE' = 'person-alert-outline',
  'ACCOUNT_ARROW_DOWN' = 'account-arrow-down',
  'ACCOUNT_DOWNLOAD' = 'account-download',
  'ACCOUNT_ARROW_DOWN_OUTLINE' = 'account-arrow-down-outline',
  'ACCOUNT_DOWNLOAD_OUTLINE' = 'account-download-outline',
  'ACCOUNT_ARROW_LEFT' = 'account-arrow-left',
  'USER_ARROW_LEFT' = 'user-arrow-left',
  'PERSON_ARROW_LEFT' = 'person-arrow-left',
  'ACCOUNT_ARROW_LEFT_OUTLINE' = 'account-arrow-left-outline',
  'USER_ARROW_LEFT_OUTLINE' = 'user-arrow-left-outline',
  'PERSON_ARROW_LEFT_OUTLINE' = 'person-arrow-left-outline',
  'ACCOUNT_ARROW_RIGHT' = 'account-arrow-right',
  'USER_ARROW_RIGHT' = 'user-arrow-right',
  'PERSON_ARROW_RIGHT' = 'person-arrow-right',
  'ACCOUNT_ARROW_RIGHT_OUTLINE' = 'account-arrow-right-outline',
  'USER_ARROW_RIGHT_OUTLINE' = 'user-arrow-right-outline',
  'PERSON_ARROW_RIGHT_OUTLINE' = 'person-arrow-right-outline',
  'ACCOUNT_ARROW_UP' = 'account-arrow-up',
  'ACCOUNT_UPLOAD' = 'account-upload',
  'ACCOUNT_ARROW_UP_OUTLINE' = 'account-arrow-up-outline',
  'ACCOUNT_UPLOAD_OUTLINE' = 'account-upload-outline',
  'ACCOUNT_BOX' = 'account-box',
  'SELFIE' = 'selfie',
  'USER_BOX' = 'user-box',
  'PERSON_BOX' = 'person-box',
  'CONTACT' = 'contact',
  'ACCOUNT_BOX_MULTIPLE' = 'account-box-multiple',
  'SWITCH_ACCOUNT' = 'switch-account',
  'USER_BOX_MULTIPLE' = 'user-box-multiple',
  'ACCOUNT_BOXES' = 'account-boxes',
  'USER_BOXES' = 'user-boxes',
  'PERSON_BOX_MULTIPLE' = 'person-box-multiple',
  'PERSON_BOXES' = 'person-boxes',
  'ACCOUNT_BOX_MULTIPLE_OUTLINE' = 'account-box-multiple-outline',
  'ACCOUNT_BOX_OUTLINE' = 'account-box-outline',
  'SELFIE_OUTLINE' = 'selfie-outline',
  'USER_BOX_OUTLINE' = 'user-box-outline',
  'PORTRAIT' = 'portrait',
  'CONTACT_OUTLINE' = 'contact-outline',
  'PERSON_BOX_OUTLINE' = 'person-box-outline',
  'ACCOUNT_CANCEL' = 'account-cancel',
  'USER_CANCEL' = 'user-cancel',
  'USER_BLOCK' = 'user-block',
  'PERSON_CANCEL' = 'person-cancel',
  'PERSON_BLOCK' = 'person-block',
  'ACCOUNT_CANCEL_OUTLINE' = 'account-cancel-outline',
  'USER_CANCEL_OUTLINE' = 'user-cancel-outline',
  'USER_BLOCK_OUTLINE' = 'user-block-outline',
  'PERSON_CANCEL_OUTLINE' = 'person-cancel-outline',
  'PERSON_BLOCK_OUTLINE' = 'person-block-outline',
  'ACCOUNT_CASH' = 'account-cash',
  'ACCOUNT_CASH_OUTLINE' = 'account-cash-outline',
  'ACCOUNT_CHECK' = 'account-check',
  'USER_CHECK' = 'user-check',
  'ACCOUNT_TICK' = 'account-tick',
  'USER_TICK' = 'user-tick',
  'PERSON_CHECK' = 'person-check',
  'PERSON_TICK' = 'person-tick',
  'HOW_TO_REG' = 'how-to-reg',
  'ACCOUNT_CHECK_OUTLINE' = 'account-check-outline',
  'ACCOUNT_TICK_OUTLINE' = 'account-tick-outline',
  'USER_CHECK_OUTLINE' = 'user-check-outline',
  'USER_TICK_OUTLINE' = 'user-tick-outline',
  'PERSON_CHECK_OUTLINE' = 'person-check-outline',
  'PERSON_TICK_OUTLINE' = 'person-tick-outline',
  'HOW_TO_REG_OUTLINE' = 'how-to-reg-outline',
  'ACCOUNT_CHILD' = 'account-child',
  'USER_CHILD' = 'user-child',
  'PERSON_CHILD' = 'person-child',
  'GUARDIAN' = 'guardian',
  'ACCOUNT_CHILD_CIRCLE' = 'account-child-circle',
  'USER_CHILD_CIRCLE' = 'user-child-circle',
  'PERSON_CHILD_CIRCLE' = 'person-child-circle',
  'GUARDIAN_CIRCLE' = 'guardian-circle',
  'ACCOUNT_CHILD_OUTLINE' = 'account-child-outline',
  'ACCOUNT_CIRCLE' = 'account-circle',
  'USER_CIRCLE' = 'user-circle',
  'PERSON_CIRCLE' = 'person-circle',
  'ACCOUNT_CIRCLE_OUTLINE' = 'account-circle-outline',
  'USER_CIRCLE_OUTLINE' = 'user-circle-outline',
  'PERSON_CIRCLE_OUTLINE' = 'person-circle-outline',
  'ACCOUNT_CLOCK' = 'account-clock',
  'USER_CLOCK' = 'user-clock',
  'ACCOUNT_PENDING' = 'account-pending',
  'PERSON_CLOCK' = 'person-clock',
  'ACCOUNT_CLOCK_OUTLINE' = 'account-clock-outline',
  'USER_CLOCK_OUTLINE' = 'user-clock-outline',
  'ACCOUNT_PENDING_OUTLINE' = 'account-pending-outline',
  'PERSON_CLOCK_OUTLINE' = 'person-clock-outline',
  'ACCOUNT_COG' = 'account-cog',
  'ACCOUNT_SETTINGS' = 'account-settings',
  'ACCOUNT_COG_OUTLINE' = 'account-cog-outline',
  'ACCOUNT_SETTINGS_OUTLINE' = 'account-settings-outline',
  'ACCOUNT_CONVERT' = 'account-convert',
  'USER_CONVERT' = 'user-convert',
  'PERSON_CONVERT' = 'person-convert',
  'ACCOUNT_CONVERT_OUTLINE' = 'account-convert-outline',
  'ACCOUNT_COWBOY_HAT' = 'account-cowboy-hat',
  'RANCHER' = 'rancher',
  'ACCOUNT_COWBOY_HAT_OUTLINE' = 'account-cowboy-hat-outline',
  'RANCHER_OUTLINE' = 'rancher-outline',
  'ACCOUNT_DETAILS' = 'account-details',
  'USER_DETAILS' = 'user-details',
  'PERSON_DETAILS' = 'person-details',
  'ACCOUNT_DETAILS_OUTLINE' = 'account-details-outline',
  'PERSON_DETAILS_OUTLINE' = 'person-details-outline',
  'USER_DETAILS_OUTLINE' = 'user-details-outline',
  'ACCOUNT_EDIT' = 'account-edit',
  'USER_EDIT' = 'user-edit',
  'PERSON_EDIT' = 'person-edit',
  'ACCOUNT_EDIT_OUTLINE' = 'account-edit-outline',
  'ACCOUNT_EYE' = 'account-eye',
  'ACCOUNT_VIEW' = 'account-view',
  'ACCOUNT_EYE_OUTLINE' = 'account-eye-outline',
  'ACCOUNT_VIEW_OUTLINE' = 'account-view-outline',
  'ACCOUNT_FILTER' = 'account-filter',
  'ACCOUNT_FUNNEL' = 'account-funnel',
  'LEADS' = 'leads',
  'ACCOUNT_FILTER_OUTLINE' = 'account-filter-outline',
  'ACCOUNT_FUNNEL_OUTLINE' = 'account-funnel-outline',
  'LEADS_OUTLINE' = 'leads-outline',
  'ACCOUNT_GROUP' = 'account-group',
  'USER_GROUP' = 'user-group',
  'USERS_GROUP' = 'users-group',
  'PERSON_GROUP' = 'person-group',
  'PEOPLE_GROUP' = 'people-group',
  'ACCOUNTS_GROUP' = 'accounts-group',
  'ACCOUNT_GROUP_OUTLINE' = 'account-group-outline',
  'USER_GROUP_OUTLINE' = 'user-group-outline',
  'USERS_GROUP_OUTLINE' = 'users-group-outline',
  'PERSON_GROUP_OUTLINE' = 'person-group-outline',
  'PEOPLE_GROUP_OUTLINE' = 'people-group-outline',
  'ACCOUNTS_GROUP_OUTLINE' = 'accounts-group-outline',
  'ACCOUNT_HARD_HAT' = 'account-hard-hat',
  'WORKER' = 'worker',
  'CONSTRUCTION' = 'construction',
  'ACCOUNT_HARD_HAT_OUTLINE' = 'account-hard-hat-outline',
  'WORKER_OUTLINE' = 'worker-outline',
  'CONSTRUCTION_OUTLINE' = 'construction-outline',
  'ACCOUNT_HEART' = 'account-heart',
  'USER_HEART' = 'user-heart',
  'PERSON_HEART' = 'person-heart',
  'ACCOUNT_HEART_OUTLINE' = 'account-heart-outline',
  'USER_HEART_OUTLINE' = 'user-heart-outline',
  'PERSON_HEART_OUTLINE' = 'person-heart-outline',
  'ACCOUNT_INJURY' = 'account-injury',
  'ACCOUNT_DISABILITY' = 'account-disability',
  'ACCOUNT_INJURY_OUTLINE' = 'account-injury-outline',
  'ACCOUNT_DISABILITY_OUTLINE' = 'account-disability-outline',
  'ACCOUNT_KEY' = 'account-key',
  'USER_KEY' = 'user-key',
  'PERSON_KEY' = 'person-key',
  'ACCOUNT_KEY_OUTLINE' = 'account-key-outline',
  'USER_KEY_OUTLINE' = 'user-key-outline',
  'PERSON_KEY_OUTLINE' = 'person-key-outline',
  'ACCOUNT_LOCK' = 'account-lock',
  'ACCOUNT_SECURITY' = 'account-security',
  'ACCOUNT_SECURE' = 'account-secure',
  'USER_LOCK' = 'user-lock',
  'PERSON_LOCK' = 'person-lock',
  'ACCOUNT_LOCK_OPEN' = 'account-lock-open',
  'ACCOUNT_UNLOCKED' = 'account-unlocked',
  'USER_UNLOCKED' = 'user-unlocked',
  'USER_LOCK_OPEN' = 'user-lock-open',
  'ACCOUNT_LOCK_OPEN_OUTLINE' = 'account-lock-open-outline',
  'USER_LOCK_OPEN_OUTLINE' = 'user-lock-open-outline',
  'USER_UNLOCKED_OUTLINE' = 'user-unlocked-outline',
  'ACCOUNT_UNLOCKED_OUTLINE' = 'account-unlocked-outline',
  'ACCOUNT_LOCK_OUTLINE' = 'account-lock-outline',
  'ACCOUNT_SECURITY_OUTLINE' = 'account-security-outline',
  'ACCOUNT_SECURE_OUTLINE' = 'account-secure-outline',
  'PERSON_LOCK_OUTLINE' = 'person-lock-outline',
  'USER_LOCK_OUTLINE' = 'user-lock-outline',
  'ACCOUNT_MINUS' = 'account-minus',
  'USER_MINUS' = 'user-minus',
  'PERSON_MINUS' = 'person-minus',
  'ACCOUNT_MINUS_OUTLINE' = 'account-minus-outline',
  'USER_MINUS_OUTLINE' = 'user-minus-outline',
  'PERSON_MINUS_OUTLINE' = 'person-minus-outline',
  'ACCOUNT_MULTIPLE' = 'account-multiple',
  'PEOPLE' = 'people',
  'USER_MULTIPLE' = 'user-multiple',
  'GROUP' = 'group',
  'ACCOUNTS' = 'accounts',
  'USERS' = 'users',
  'PERSON_MULTIPLE' = 'person-multiple',
  'ACCOUNT_MULTIPLE_CHECK' = 'account-multiple-check',
  'USER_MULTIPLE_CHECK' = 'user-multiple-check',
  'ACCOUNT_MULTIPLE_TICK' = 'account-multiple-tick',
  'ACCOUNTS_CHECK' = 'accounts-check',
  'ACCOUNTS_TICK' = 'accounts-tick',
  'USERS_CHECK' = 'users-check',
  'USERS_TICK' = 'users-tick',
  'USER_MULTIPLE_TICK' = 'user-multiple-tick',
  'PERSON_MULTIPLE_CHECK' = 'person-multiple-check',
  'PERSON_MULTIPLE_TICK' = 'person-multiple-tick',
  'PEOPLE_CHECK' = 'people-check',
  'PEOPLE_TICK' = 'people-tick',
  'ACCOUNT_MULTIPLE_CHECK_OUTLINE' = 'account-multiple-check-outline',
  'USER_MULTIPLE_CHECK_OUTLINE' = 'user-multiple-check-outline',
  'ACCOUNT_MULTIPLE_TICK_OUTLINE' = 'account-multiple-tick-outline',
  'ACCOUNTS_CHECK_OUTLINE' = 'accounts-check-outline',
  'ACCOUNTS_TICK_OUTLINE' = 'accounts-tick-outline',
  'USERS_CHECK_OUTLINE' = 'users-check-outline',
  'USERS_TICK_OUTLINE' = 'users-tick-outline',
  'USER_MULTIPLE_TICK_OUTLINE' = 'user-multiple-tick-outline',
  'PERSON_MULTIPLE_CHECK_OUTLINE' = 'person-multiple-check-outline',
  'PERSON_MULTIPLE_TICK_OUTLINE' = 'person-multiple-tick-outline',
  'PEOPLE_CHECK_OUTLINE' = 'people-check-outline',
  'PEOPLE_TICK_OUTLINE' = 'people-tick-outline',
  'ACCOUNT_MULTIPLE_MINUS' = 'account-multiple-minus',
  'USER_MULTIPLE_MINUS' = 'user-multiple-minus',
  'ACCOUNTS_MINUS' = 'accounts-minus',
  'USERS_MINUS' = 'users-minus',
  'PEOPLE_MINUS' = 'people-minus',
  'PERSON_MULTIPLE_MINUS' = 'person-multiple-minus',
  'ACCOUNT_MULTIPLE_MINUS_OUTLINE' = 'account-multiple-minus-outline',
  'ACCOUNTS_MINUS_OUTLINE' = 'accounts-minus-outline',
  'PEOPLE_MINUS_OUTLINE' = 'people-minus-outline',
  'USER_MULTIPLE_MINUS_OUTLINE' = 'user-multiple-minus-outline',
  'USERS_MINUS_OUTLINE' = 'users-minus-outline',
  'PERSON_MULTIPLE_MINUS_OUTLINE' = 'person-multiple-minus-outline',
  'ACCOUNT_MULTIPLE_OUTLINE' = 'account-multiple-outline',
  'USER_MULTIPLE_OUTLINE' = 'user-multiple-outline',
  'PEOPLE_OUTLINE' = 'people-outline',
  'ACCOUNTS_OUTLINE' = 'accounts-outline',
  'USERS_OUTLINE' = 'users-outline',
  'ACCOUNT_MULTIPLE_PLUS' = 'account-multiple-plus',
  'USER_MULTIPLE_PLUS' = 'user-multiple-plus',
  'GROUP_ADD' = 'group-add',
  'ACCOUNTS_PLUS' = 'accounts-plus',
  'USERS_PLUS' = 'users-plus',
  'PERSON_MULTIPLE_PLUS' = 'person-multiple-plus',
  'PEOPLE_PLUS' = 'people-plus',
  'PERSON_MULTIPLE_ADD' = 'person-multiple-add',
  'PEOPLE_ADD' = 'people-add',
  'ACCOUNT_MULTIPLE_ADD' = 'account-multiple-add',
  'ACCOUNTS_ADD' = 'accounts-add',
  'USER_MULTIPLE_ADD' = 'user-multiple-add',
  'USERS_ADD' = 'users-add',
  'INVITE' = 'invite',
  'ACCOUNT_MULTIPLE_PLUS_OUTLINE' = 'account-multiple-plus-outline',
  'GROUP_ADD_OUTLINE' = 'group-add-outline',
  'USER_MULTIPLE_PLUS_OUTLINE' = 'user-multiple-plus-outline',
  'ACCOUNTS_PLUS_OUTLINE' = 'accounts-plus-outline',
  'USERS_PLUS_OUTLINE' = 'users-plus-outline',
  'PERSON_MULTIPLE_PLUS_OUTLINE' = 'person-multiple-plus-outline',
  'PEOPLE_PLUS_OUTLINE' = 'people-plus-outline',
  'PERSON_MULTIPLE_ADD_OUTLINE' = 'person-multiple-add-outline',
  'PEOPLE_ADD_OUTLINE' = 'people-add-outline',
  'ACCOUNT_MULTIPLE_ADD_OUTLINE' = 'account-multiple-add-outline',
  'ACCOUNTS_ADD_OUTLINE' = 'accounts-add-outline',
  'USER_MULTIPLE_ADD_OUTLINE' = 'user-multiple-add-outline',
  'USERS_ADD_OUTLINE' = 'users-add-outline',
  'ACCOUNT_MULTIPLE_REMOVE' = 'account-multiple-remove',
  'USER_MULTIPLE_REMOVE' = 'user-multiple-remove',
  'PERSON_MULTIPLE_REMOVE' = 'person-multiple-remove',
  'ACCOUNT_MULTIPLE_REMOVE_OUTLINE' = 'account-multiple-remove-outline',
  'USER_MULTIPLE_REMOVE_OUTLINE' = 'user-multiple-remove-outline',
  'PERSON_MULTIPLE_REMOVE_OUTLINE' = 'person-multiple-remove-outline',
  'ACCOUNT_MUSIC' = 'account-music',
  'ARTIST' = 'artist',
  'ACCOUNT_MUSIC_OUTLINE' = 'account-music-outline',
  'ARTIST_OUTLINE' = 'artist-outline',
  'ACCOUNT_NETWORK' = 'account-network',
  'USER_NETWORK' = 'user-network',
  'PERSON_NETWORK' = 'person-network',
  'ACCOUNT_NETWORK_OUTLINE' = 'account-network-outline',
  'USER_NETWORK_OUTLINE' = 'user-network-outline',
  'PERSON_NETWORK_OUTLINE' = 'person-network-outline',
  'ACCOUNT_OFF' = 'account-off',
  'USER_OFF' = 'user-off',
  'PERSON_OFF' = 'person-off',
  'ACCOUNT_OFF_OUTLINE' = 'account-off-outline',
  'USER_OFF_OUTLINE' = 'user-off-outline',
  'PERSON_OFF_OUTLINE' = 'person-off-outline',
  'ACCOUNT_OUTLINE' = 'account-outline',
  'USER_OUTLINE' = 'user-outline',
  'PERM_IDENTITY' = 'perm-identity',
  'PERSON_OUTLINE' = 'person-outline',
  'ACCOUNT_PLUS' = 'account-plus',
  'REGISTER' = 'register',
  'USER_PLUS' = 'user-plus',
  'PERSON_ADD' = 'person-add',
  'ACCOUNT_ADD' = 'account-add',
  'PERSON_PLUS' = 'person-plus',
  'USER_ADD' = 'user-add',
  'ACCOUNT_PLUS_OUTLINE' = 'account-plus-outline',
  'PERSON_ADD_OUTLINE' = 'person-add-outline',
  'REGISTER_OUTLINE' = 'register-outline',
  'USER_PLUS_OUTLINE' = 'user-plus-outline',
  'ACCOUNT_ADD_OUTLINE' = 'account-add-outline',
  'PERSON_PLUS_OUTLINE' = 'person-plus-outline',
  'USER_ADD_OUTLINE' = 'user-add-outline',
  'ACCOUNT_QUESTION' = 'account-question',
  'USER_HELP' = 'user-help',
  'ACCOUNT_QUESTION_MARK' = 'account-question-mark',
  'ACCOUNT_HELP' = 'account-help',
  'USER_QUESTION' = 'user-question',
  'PERSON_QUESTION' = 'person-question',
  'PERSON_HELP' = 'person-help',
  'ACCOUNT_QUESTION_OUTLINE' = 'account-question-outline',
  'ACCOUNT_QUESTION_MARK_OUTLINE' = 'account-question-mark-outline',
  'USER_HELP_OUTLINE' = 'user-help-outline',
  'ACCOUNT_HELP_OUTLINE' = 'account-help-outline',
  'USER_QUESTION_OUTLINE' = 'user-question-outline',
  'PERSON_QUESTION_OUTLINE' = 'person-question-outline',
  'PERSON_HELP_OUTLINE' = 'person-help-outline',
  'ACCOUNT_REACTIVATE' = 'account-reactivate',
  'ACCOUNT_REACTIVATE_OUTLINE' = 'account-reactivate-outline',
  'ACCOUNT_REMOVE' = 'account-remove',
  'USER_REMOVE' = 'user-remove',
  'PERSON_REMOVE' = 'person-remove',
  'ACCOUNT_REMOVE_OUTLINE' = 'account-remove-outline',
  'USER_REMOVE_OUTLINE' = 'user-remove-outline',
  'PERSON_REMOVE_OUTLINE' = 'person-remove-outline',
  'ACCOUNT_SCHOOL' = 'account-school',
  'ACCOUNT_STUDENT' = 'account-student',
  'ACCOUNT_GRADUATION' = 'account-graduation',
  'ACCOUNT_SCHOOL_OUTLINE' = 'account-school-outline',
  'ACCOUNT_STUDENT_OUTLINE' = 'account-student-outline',
  'ACCOUNT_GRADUATION_OUTLINE' = 'account-graduation-outline',
  'ACCOUNT_SEARCH' = 'account-search',
  'USER_SEARCH' = 'user-search',
  'PERSON_SEARCH' = 'person-search',
  'ACCOUNT_SEARCH_OUTLINE' = 'account-search-outline',
  'USER_SEARCH_OUTLINE' = 'user-search-outline',
  'PERSON_SEARCH_OUTLINE' = 'person-search-outline',
  'USER_SETTINGS' = 'user-settings',
  'PERSON_SETTINGS' = 'person-settings',
  'ACCOUNT_STAR' = 'account-star',
  'USER_STAR' = 'user-star',
  'PERSON_STAR' = 'person-star',
  'ACCOUNT_FAVORITE' = 'account-favorite',
  'ACCOUNT_STAR_OUTLINE' = 'account-star-outline',
  'USER_STAR_OUTLINE' = 'user-star-outline',
  'PERSON_STAR_OUTLINE' = 'person-star-outline',
  'ACCOUNT_SUPERVISOR' = 'account-supervisor',
  'USER_SUPERVISOR' = 'user-supervisor',
  'PERSON_SUPERVISOR' = 'person-supervisor',
  'ACCOUNT_SUPERVISOR_CIRCLE' = 'account-supervisor-circle',
  'USER_SUPERVISOR_CIRCLE' = 'user-supervisor-circle',
  'PERSON_SUPERVISOR_CIRCLE' = 'person-supervisor-circle',
  'ACCOUNT_SUPERVISOR_CIRCLE_OUTLINE' = 'account-supervisor-circle-outline',
  'ACCOUNT_SUPERVISOR_OUTLINE' = 'account-supervisor-outline',
  'ACCOUNT_SWITCH' = 'account-switch',
  'USER_SWITCH' = 'user-switch',
  'ACCOUNTS_SWITCH' = 'accounts-switch',
  'USERS_SWITCH' = 'users-switch',
  'PERSON_SWITCH' = 'person-switch',
  'PEOPLE_SWITCH' = 'people-switch',
  'ACCOUNT_SWITCH_OUTLINE' = 'account-switch-outline',
  'ACCOUNT_SYNC' = 'account-sync',
  'ACCOUNT_CACHE' = 'account-cache',
  'ACCOUNT_SYNC_OUTLINE' = 'account-sync-outline',
  'ACCOUNT_CACHE_OUTLINE' = 'account-cache-outline',
  'ACCOUNT_TIE' = 'account-tie',
  'PERSON_TIE' = 'person-tie',
  'USER_TIE' = 'user-tie',
  'ACCOUNT_TIE_HAT' = 'account-tie-hat',
  'ACCOUNT_PILOT' = 'account-pilot',
  'ACCOUNT_TIE_HAT_OUTLINE' = 'account-tie-hat-outline',
  'ACCOUNT_PILOT_OUTLINE' = 'account-pilot-outline',
  'ACCOUNT_TIE_OUTLINE' = 'account-tie-outline',
  'ACCOUNT_TIE_VOICE' = 'account-tie-voice',
  'ACCOUNT_TIE_VOICE_OFF' = 'account-tie-voice-off',
  'ACCOUNT_TIE_VOICE_OFF_OUTLINE' = 'account-tie-voice-off-outline',
  'ACCOUNT_TIE_VOICE_OUTLINE' = 'account-tie-voice-outline',
  'ACCOUNT_VOICE' = 'account-voice',
  'RECORD_VOICE_OVER' = 'record-voice-over',
  'SPEAK' = 'speak',
  'TALK' = 'talk',
  'SPEAKING' = 'speaking',
  'TALKING' = 'talking',
  'ACCOUNT_VOICE_OFF' = 'account-voice-off',
  'ACCOUNT_WRENCH' = 'account-wrench',
  'ACCOUNT_SERVICE' = 'account-service',
  'ACCOUNT_WRENCH_OUTLINE' = 'account-wrench-outline',
  'ACCOUNT_SERVICE_OUTLINE' = 'account-service-outline',
  'ADJUST' = 'adjust',
  'ADVERTISEMENTS' = 'advertisements',
  'ADS' = 'ads',
  'ADVERTISEMENTS_OFF' = 'advertisements-off',
  'ADS_OFF' = 'ads-off',
  'AIR_CONDITIONER' = 'air-conditioner',
  'AC_UNIT' = 'ac-unit',
  'AIR_FILTER' = 'air-filter',
  'WATER_FILTER' = 'water-filter',
  'FILTER' = 'filter',
  'AIR_HORN' = 'air-horn',
  'AIR_HUMIDIFIER' = 'air-humidifier',
  'AIR_HUMIDIFIER_OFF' = 'air-humidifier-off',
  'AIR_DEHUMIDIFIER' = 'air-dehumidifier',
  'AIR_PURIFIER' = 'air-purifier',
  'AIRBAG' = 'airbag',
  'AIRBALLOON' = 'airballoon',
  'HOT_AIR_BALLOON' = 'hot-air-balloon',
  'AIRBALLOON_OUTLINE' = 'airballoon-outline',
  'HOT_AIR_BALLOON_OUTLINE' = 'hot-air-balloon-outline',
  'AIRPLANE' = 'airplane',
  'AEROPLANE' = 'aeroplane',
  'AIRPLANEMODE_ACTIVE' = 'airplanemode-active',
  'FLIGHT' = 'flight',
  'LOCAL_AIRPORT' = 'local-airport',
  'FLIGHT_MODE' = 'flight-mode',
  'PLANE' = 'plane',
  'AIRPLANE_ALERT' = 'airplane-alert',
  'AIRPLANE_CHECK' = 'airplane-check',
  'AIRPLANE_CLOCK' = 'airplane-clock',
  'AIRPLANE_SCHEDULE' = 'airplane-schedule',
  'AIRPLANE_TIME' = 'airplane-time',
  'AIRPLANE_DATE' = 'airplane-date',
  'AIRPLANE_COG' = 'airplane-cog',
  'AIRPLANE_SETTINGS' = 'airplane-settings',
  'AIRPLANE_EDIT' = 'airplane-edit',
  'AIRPLANE_LANDING' = 'airplane-landing',
  'AEROPLANE_LANDING' = 'aeroplane-landing',
  'FLIGHT_LAND' = 'flight-land',
  'PLANE_LANDING' = 'plane-landing',
  'AIRPLANE_MARKER' = 'airplane-marker',
  'AIRPLANE_LOCATION' = 'airplane-location',
  'AIRPLANE_GPS' = 'airplane-gps',
  'AIRPLANE_MINUS' = 'airplane-minus',
  'AIRPLANE_OFF' = 'airplane-off',
  'AEROPLANE_OFF' = 'aeroplane-off',
  'AIRPLANEMODE_INACTIVE' = 'airplanemode-inactive',
  'FLIGHT_MODE_OFF' = 'flight-mode-off',
  'PLANE_OFF' = 'plane-off',
  'AIRPLANE_PLUS' = 'airplane-plus',
  'AIRPLANE_REMOVE' = 'airplane-remove',
  'AIRPLANE_SEARCH' = 'airplane-search',
  'AIRPLANE_FIND' = 'airplane-find',
  'AIRPLANE_TAKEOFF' = 'airplane-takeoff',
  'AEROPLANE_TAKEOFF' = 'aeroplane-takeoff',
  'FLIGHT_TAKEOFF' = 'flight-takeoff',
  'PLANE_TAKEOFF' = 'plane-takeoff',
  'AIRPLANE_TAKE_OFF' = 'airplane-take-off',
  'AIRPORT' = 'airport',
  'ALARM' = 'alarm',
  'ACCESS_ALARMS' = 'access-alarms',
  'ALARM_CLOCK' = 'alarm-clock',
  'ALARM_BELL' = 'alarm-bell',
  'ALARM_CHECK' = 'alarm-check',
  'ALARM_ON' = 'alarm-on',
  'ALARM_TICK' = 'alarm-tick',
  'ALARM_CLOCK_CHECK' = 'alarm-clock-check',
  'ALARM_CLOCK_TICK' = 'alarm-clock-tick',
  'ALARM_LIGHT' = 'alarm-light',
  'ALARM_LIGHT_OFF' = 'alarm-light-off',
  'ALARM_LIGHT_OFF_OUTLINE' = 'alarm-light-off-outline',
  'ALARM_LIGHT_OUTLINE' = 'alarm-light-outline',
  'ALARM_MULTIPLE' = 'alarm-multiple',
  'ALARMS' = 'alarms',
  'ALARM_CLOCK_MULTIPLE' = 'alarm-clock-multiple',
  'ALARM_CLOCKS' = 'alarm-clocks',
  'ALARM_NOTE' = 'alarm-note',
  'ALARM_NOTE_OFF' = 'alarm-note-off',
  'ALARM_OFF' = 'alarm-off',
  'ALARM_CLOCK_OFF' = 'alarm-clock-off',
  'ALARM_PANEL' = 'alarm-panel',
  'ALARM_PANEL_OUTLINE' = 'alarm-panel-outline',
  'ALARM_PLUS' = 'alarm-plus',
  'ADD_ALARM' = 'add-alarm',
  'ALARM_CLOCK_PLUS' = 'alarm-clock-plus',
  'ALARM_CLOCK_ADD' = 'alarm-clock-add',
  'ALARM_ADD' = 'alarm-add',
  'ALARM_SNOOZE' = 'alarm-snooze',
  'ALARM_CLOCK_SNOOZE' = 'alarm-clock-snooze',
  'ALBUM' = 'album',
  'VINYL' = 'vinyl',
  'RECORD' = 'record',
  'ALERT' = 'alert',
  'WARNING' = 'warning',
  'REPORT_PROBLEM' = 'report-problem',
  'ALERT_BOX' = 'alert-box',
  'WARNING_BOX' = 'warning-box',
  'ALERT_BOX_OUTLINE' = 'alert-box-outline',
  'WARNING_BOX_OUTLINE' = 'warning-box-outline',
  'ALERT_CIRCLE' = 'alert-circle',
  'WARNING_CIRCLE' = 'warning-circle',
  'ERROR' = 'error',
  'ALERT_CIRCLE_CHECK' = 'alert-circle-check',
  'ALERT_CIRCLE_CHECK_OUTLINE' = 'alert-circle-check-outline',
  'ALERT_CIRCLE_OUTLINE' = 'alert-circle-outline',
  'WARNING_CIRCLE_OUTLINE' = 'warning-circle-outline',
  'ERROR_OUTLINE' = 'error-outline',
  'GIT_ISSUE' = 'git-issue',
  'ALERT_DECAGRAM' = 'alert-decagram',
  'NEW_RELEASES' = 'new-releases',
  'WARNING_DECAGRAM' = 'warning-decagram',
  'ALERT_DECAGRAM_OUTLINE' = 'alert-decagram-outline',
  'WARNING_DECAGRAM_OUTLINE' = 'warning-decagram-outline',
  'ALERT_MINUS' = 'alert-minus',
  'ALERT_MINUS_OUTLINE' = 'alert-minus-outline',
  'ALERT_OCTAGON' = 'alert-octagon',
  'WARNING_OCTAGON' = 'warning-octagon',
  'REPORT' = 'report',
  'STOP_ALERT' = 'stop-alert',
  'ALERT_OCTAGON_OUTLINE' = 'alert-octagon-outline',
  'WARNING_OCTAGON_OUTLINE' = 'warning-octagon-outline',
  'STOP_ALERT_OUTLINE' = 'stop-alert-outline',
  'ALERT_OCTAGRAM' = 'alert-octagram',
  'WARNING_OCTAGRAM' = 'warning-octagram',
  'ALERT_OCTAGRAM_OUTLINE' = 'alert-octagram-outline',
  'WARNING_OCTAGRAM_OUTLINE' = 'warning-octagram-outline',
  'ALERT_OUTLINE' = 'alert-outline',
  'WARNING_OUTLINE' = 'warning-outline',
  'ALERT_PLUS' = 'alert-plus',
  'ALERT_PLUS_OUTLINE' = 'alert-plus-outline',
  'ALERT_REMOVE' = 'alert-remove',
  'ALERT_REMOVE_OUTLINE' = 'alert-remove-outline',
  'ALERT_RHOMBUS' = 'alert-rhombus',
  'ALERT_RHOMBUS_OUTLINE' = 'alert-rhombus-outline',
  'ALIEN' = 'alien',
  'ALIEN_OUTLINE' = 'alien-outline',
  'ALIGN_HORIZONTAL_CENTER' = 'align-horizontal-center',
  'ALIGN_HORIZONTAL_CENTRE' = 'align-horizontal-centre',
  'ALIGN_HORIZONTAL_DISTRIBUTE' = 'align-horizontal-distribute',
  'ALIGN_HORIZONTAL_LEFT' = 'align-horizontal-left',
  'ALIGN_HORIZONTAL_RIGHT' = 'align-horizontal-right',
  'ALIGN_VERTICAL_BOTTOM' = 'align-vertical-bottom',
  'ALIGN_VERTICAL_CENTER' = 'align-vertical-center',
  'ALIGN_VERTICAL_CENTRE' = 'align-vertical-centre',
  'ALIGN_VERTICAL_DISTRIBUTE' = 'align-vertical-distribute',
  'ALIGN_VERTICAL_TOP' = 'align-vertical-top',
  'ALL_INCLUSIVE' = 'all-inclusive',
  'INFINITY' = 'infinity',
  'FOREVER' = 'forever',
  'ALL_INCLUSIVE_BOX' = 'all-inclusive-box',
  'INFINITY_BOX' = 'infinity-box',
  'FOREVER_BOX' = 'forever-box',
  'ALL_INCLUSIVE_BOX_OUTLINE' = 'all-inclusive-box-outline',
  'FOREVER_BOX_OUTLINE' = 'forever-box-outline',
  'INFINITY_BOX_OUTLINE' = 'infinity-box-outline',
  'ALLERGY' = 'allergy',
  'HAND' = 'hand',
  'RASH' = 'rash',
  'GERM' = 'germ',
  'ALPHA' = 'alpha',
  'ALPHA_A' = 'alpha-a',
  'ALPHABET_A' = 'alphabet-a',
  'LETTER_A' = 'letter-a',
  'ALPHA_ABOX' = 'alpha-abox',
  'ALPHA_ABOX_OUTLINE' = 'alpha-abox-outline',
  'ALPHA_ACIRCLE' = 'alpha-acircle',
  'ALPHA_ACIRCLE_OUTLINE' = 'alpha-acircle-outline',
  'ALPHA_B' = 'alpha-b',
  'ALPHABET_B' = 'alphabet-b',
  'LETTER_B' = 'letter-b',
  'ALPHA_BBOX' = 'alpha-bbox',
  'ALPHA_BBOX_OUTLINE' = 'alpha-bbox-outline',
  'ALPHA_BCIRCLE' = 'alpha-bcircle',
  'ALPHA_BCIRCLE_OUTLINE' = 'alpha-bcircle-outline',
  'ALPHA_C' = 'alpha-c',
  'ALPHABET_C' = 'alphabet-c',
  'LETTER_C' = 'letter-c',
  'ALPHA_CBOX' = 'alpha-cbox',
  'ALPHA_CBOX_OUTLINE' = 'alpha-cbox-outline',
  'ALPHA_CCIRCLE' = 'alpha-ccircle',
  'ALPHA_CCIRCLE_OUTLINE' = 'alpha-ccircle-outline',
  'ALPHA_D' = 'alpha-d',
  'ALPHABET_D' = 'alphabet-d',
  'LETTER_D' = 'letter-d',
  'DRIVE' = 'drive',
  'ALPHA_DBOX' = 'alpha-dbox',
  'ALPHA_DBOX_OUTLINE' = 'alpha-dbox-outline',
  'ALPHA_DCIRCLE' = 'alpha-dcircle',
  'ALPHA_DCIRCLE_OUTLINE' = 'alpha-dcircle-outline',
  'ALPHA_E' = 'alpha-e',
  'ALPHABET_E' = 'alphabet-e',
  'LETTER_E' = 'letter-e',
  'ALPHA_EBOX' = 'alpha-ebox',
  'ALPHA_EBOX_OUTLINE' = 'alpha-ebox-outline',
  'ALPHA_ECIRCLE' = 'alpha-ecircle',
  'ALPHA_ECIRCLE_OUTLINE' = 'alpha-ecircle-outline',
  'ALPHA_F' = 'alpha-f',
  'ALPHABET_F' = 'alphabet-f',
  'LETTER_F' = 'letter-f',
  'ALPHA_FBOX' = 'alpha-fbox',
  'ALPHA_FBOX_OUTLINE' = 'alpha-fbox-outline',
  'ALPHA_FCIRCLE' = 'alpha-fcircle',
  'ALPHA_FCIRCLE_OUTLINE' = 'alpha-fcircle-outline',
  'ALPHA_G' = 'alpha-g',
  'ALPHABET_G' = 'alphabet-g',
  'LETTER_G' = 'letter-g',
  'ALPHA_GBOX' = 'alpha-gbox',
  'ALPHA_GBOX_OUTLINE' = 'alpha-gbox-outline',
  'ALPHA_GCIRCLE' = 'alpha-gcircle',
  'ALPHA_GCIRCLE_OUTLINE' = 'alpha-gcircle-outline',
  'ALPHA_H' = 'alpha-h',
  'ALPHABET_H' = 'alphabet-h',
  'LETTER_H' = 'letter-h',
  'ALPHA_HBOX' = 'alpha-hbox',
  'ALPHA_HBOX_OUTLINE' = 'alpha-hbox-outline',
  'ALPHA_HCIRCLE' = 'alpha-hcircle',
  'ALPHA_HCIRCLE_OUTLINE' = 'alpha-hcircle-outline',
  'ALPHA_I' = 'alpha-i',
  'ALPHABET_I' = 'alphabet-i',
  'LETTER_I' = 'letter-i',
  'ROMAN_NUMERAL_1' = 'roman-numeral-1',
  'ALPHA_IBOX' = 'alpha-ibox',
  'ALPHA_IBOX_OUTLINE' = 'alpha-ibox-outline',
  'ALPHA_ICIRCLE' = 'alpha-icircle',
  'ALPHA_ICIRCLE_OUTLINE' = 'alpha-icircle-outline',
  'ALPHA_J' = 'alpha-j',
  'ALPHABET_J' = 'alphabet-j',
  'LETTER_J' = 'letter-j',
  'ALPHA_JBOX' = 'alpha-jbox',
  'ALPHA_JBOX_OUTLINE' = 'alpha-jbox-outline',
  'ALPHA_JCIRCLE' = 'alpha-jcircle',
  'ALPHA_JCIRCLE_OUTLINE' = 'alpha-jcircle-outline',
  'ALPHA_K' = 'alpha-k',
  'ALPHABET_K' = 'alphabet-k',
  'LETTER_K' = 'letter-k',
  'ALPHA_KBOX' = 'alpha-kbox',
  'ALPHA_KBOX_OUTLINE' = 'alpha-kbox-outline',
  'ALPHA_KCIRCLE' = 'alpha-kcircle',
  'ALPHA_KCIRCLE_OUTLINE' = 'alpha-kcircle-outline',
  'ALPHA_L' = 'alpha-l',
  'ALPHABET_L' = 'alphabet-l',
  'LETTER_L' = 'letter-l',
  'ALPHA_LBOX' = 'alpha-lbox',
  'ALPHA_LBOX_OUTLINE' = 'alpha-lbox-outline',
  'ALPHA_LCIRCLE' = 'alpha-lcircle',
  'ALPHA_LCIRCLE_OUTLINE' = 'alpha-lcircle-outline',
  'ALPHA_M' = 'alpha-m',
  'ALPHABET_M' = 'alphabet-m',
  'LETTER_M' = 'letter-m',
  'ALPHA_MBOX' = 'alpha-mbox',
  'ALPHA_MBOX_OUTLINE' = 'alpha-mbox-outline',
  'ALPHA_MCIRCLE' = 'alpha-mcircle',
  'ALPHA_MCIRCLE_OUTLINE' = 'alpha-mcircle-outline',
  'ALPHA_N' = 'alpha-n',
  'ALPHABET_N' = 'alphabet-n',
  'LETTER_N' = 'letter-n',
  'NEUTRAL' = 'neutral',
  'ALPHA_NBOX' = 'alpha-nbox',
  'ALPHA_NBOX_OUTLINE' = 'alpha-nbox-outline',
  'ALPHA_NCIRCLE' = 'alpha-ncircle',
  'ALPHA_NCIRCLE_OUTLINE' = 'alpha-ncircle-outline',
  'ALPHA_O' = 'alpha-o',
  'ALPHABET_O' = 'alphabet-o',
  'LETTER_O' = 'letter-o',
  'ALPHA_OBOX' = 'alpha-obox',
  'ALPHA_OBOX_OUTLINE' = 'alpha-obox-outline',
  'ALPHA_OCIRCLE' = 'alpha-ocircle',
  'ALPHA_OCIRCLE_OUTLINE' = 'alpha-ocircle-outline',
  'ALPHA_P' = 'alpha-p',
  'ALPHABET_P' = 'alphabet-p',
  'LETTER_P' = 'letter-p',
  'PARK' = 'park',
  'ALPHA_PBOX' = 'alpha-pbox',
  'ALPHA_PBOX_OUTLINE' = 'alpha-pbox-outline',
  'ALPHA_PCIRCLE' = 'alpha-pcircle',
  'ALPHA_PCIRCLE_OUTLINE' = 'alpha-pcircle-outline',
  'ALPHA_Q' = 'alpha-q',
  'ALPHABET_Q' = 'alphabet-q',
  'LETTER_Q' = 'letter-q',
  'ALPHA_QBOX' = 'alpha-qbox',
  'ALPHA_QBOX_OUTLINE' = 'alpha-qbox-outline',
  'ALPHA_QCIRCLE' = 'alpha-qcircle',
  'ALPHA_QCIRCLE_OUTLINE' = 'alpha-qcircle-outline',
  'ALPHA_R' = 'alpha-r',
  'ALPHABET_R' = 'alphabet-r',
  'LETTER_R' = 'letter-r',
  'REVERSE' = 'reverse',
  'ALPHA_RBOX' = 'alpha-rbox',
  'ALPHA_RBOX_OUTLINE' = 'alpha-rbox-outline',
  'ALPHA_RCIRCLE' = 'alpha-rcircle',
  'ALPHA_RCIRCLE_OUTLINE' = 'alpha-rcircle-outline',
  'ALPHA_S' = 'alpha-s',
  'ALPHABET_S' = 'alphabet-s',
  'LETTER_S' = 'letter-s',
  'ALPHA_SBOX' = 'alpha-sbox',
  'ALPHA_SBOX_OUTLINE' = 'alpha-sbox-outline',
  'ALPHA_SCIRCLE' = 'alpha-scircle',
  'ALPHA_SCIRCLE_OUTLINE' = 'alpha-scircle-outline',
  'ALPHA_T' = 'alpha-t',
  'ALPHABET_T' = 'alphabet-t',
  'LETTER_T' = 'letter-t',
  'ALPHA_TBOX' = 'alpha-tbox',
  'ALPHA_TBOX_OUTLINE' = 'alpha-tbox-outline',
  'ALPHA_TCIRCLE' = 'alpha-tcircle',
  'ALPHA_TCIRCLE_OUTLINE' = 'alpha-tcircle-outline',
  'ALPHA_U' = 'alpha-u',
  'ALPHABET_U' = 'alphabet-u',
  'LETTER_U' = 'letter-u',
  'ALPHA_UBOX' = 'alpha-ubox',
  'ALPHA_UBOX_OUTLINE' = 'alpha-ubox-outline',
  'ALPHA_UCIRCLE' = 'alpha-ucircle',
  'ALPHA_UCIRCLE_OUTLINE' = 'alpha-ucircle-outline',
  'ALPHA_V' = 'alpha-v',
  'ALPHABET_V' = 'alphabet-v',
  'LETTER_V' = 'letter-v',
  'ROMAN_NUMERAL_5' = 'roman-numeral-5',
  'ALPHA_VBOX' = 'alpha-vbox',
  'ALPHA_VBOX_OUTLINE' = 'alpha-vbox-outline',
  'ALPHA_VCIRCLE' = 'alpha-vcircle',
  'ALPHA_VCIRCLE_OUTLINE' = 'alpha-vcircle-outline',
  'ALPHA_W' = 'alpha-w',
  'ALPHABET_W' = 'alphabet-w',
  'LETTER_W' = 'letter-w',
  'ALPHA_WBOX' = 'alpha-wbox',
  'ALPHA_WBOX_OUTLINE' = 'alpha-wbox-outline',
  'ALPHA_WCIRCLE' = 'alpha-wcircle',
  'ALPHA_WCIRCLE_OUTLINE' = 'alpha-wcircle-outline',
  'ALPHA_X' = 'alpha-x',
  'ALPHABET_X' = 'alphabet-x',
  'LETTER_X' = 'letter-x',
  'ROMAN_NUMERAL_10' = 'roman-numeral-10',
  'ALPHA_XBOX' = 'alpha-xbox',
  'ALPHA_XBOX_OUTLINE' = 'alpha-xbox-outline',
  'ALPHA_XCIRCLE' = 'alpha-xcircle',
  'ALPHA_XCIRCLE_OUTLINE' = 'alpha-xcircle-outline',
  'ALPHA_Y' = 'alpha-y',
  'ALPHABET_Y' = 'alphabet-y',
  'LETTER_Y' = 'letter-y',
  'ALPHA_YBOX' = 'alpha-ybox',
  'ALPHA_YBOX_OUTLINE' = 'alpha-ybox-outline',
  'ALPHA_YCIRCLE' = 'alpha-ycircle',
  'ALPHA_YCIRCLE_OUTLINE' = 'alpha-ycircle-outline',
  'ALPHA_Z' = 'alpha-z',
  'ALPHABET_Z' = 'alphabet-z',
  'LETTER_Z' = 'letter-z',
  'ALPHA_ZBOX' = 'alpha-zbox',
  'ALPHA_ZBOX_OUTLINE' = 'alpha-zbox-outline',
  'ALPHA_ZCIRCLE' = 'alpha-zcircle',
  'ALPHA_ZCIRCLE_OUTLINE' = 'alpha-zcircle-outline',
  'ALPHABET_AUREBESH' = 'alphabet-aurebesh',
  'WRITING_SYSTEM_AUREBESH' = 'writing-system-aurebesh',
  'ALPHABET_CYRILLIC' = 'alphabet-cyrillic',
  'WRITING_SYSTEM_CYRILLIC' = 'writing-system-cyrillic',
  'ALPHABET_GREEK' = 'alphabet-greek',
  'WRITING_SYSTEM_GREEK' = 'writing-system-greek',
  'ALPHABET_LATIN' = 'alphabet-latin',
  'WRITING_SYSTEM_LATIN' = 'writing-system-latin',
  'ALPHABET_PIQAD' = 'alphabet-piqad',
  'WRITING_SYSTEM_PIQAD' = 'writing-system-piqad',
  'ALPHABET_TENGWAR' = 'alphabet-tengwar',
  'WRITING_SYSTEM_TENGWAR' = 'writing-system-tengwar',
  'ALPHABETICAL' = 'alphabetical',
  'LETTERS' = 'letters',
  'A_B_C' = 'a-b-c',
  'ABC' = 'abc',
  'ALPHABETICAL_OFF' = 'alphabetical-off',
  'LETTERS_OFF' = 'letters-off',
  'ABC_OFF' = 'abc-off',
  'A_B_C_OFF' = 'a-b-c-off',
  'ALPHABETICAL_VARIANT' = 'alphabetical-variant',
  'ALPHABETICAL_VARIANT_OFF' = 'alphabetical-variant-off',
  'ALTIMETER' = 'altimeter',
  'AMBULANCE' = 'ambulance',
  'AMMUNITION' = 'ammunition',
  'BULLETS' = 'bullets',
  'AMPERSAND' = 'ampersand',
  'AND' = 'and',
  'AMPLIFIER' = 'amplifier',
  'AMPLIFIER_OFF' = 'amplifier-off',
  'ANCHOR' = 'anchor',
  'ANDROID' = 'android',
  'ANDROID_MESSAGES' = 'android-messages',
  'ANDROID_STUDIO' = 'android-studio',
  'MATH_COMPASS_VARIANT' = 'math-compass-variant',
  'ANGLE_ACUTE' = 'angle-acute',
  'ANGLE_OBTUSE' = 'angle-obtuse',
  'ANGLE_RIGHT' = 'angle-right',
  'ANGULAR' = 'angular',
  'ANGULARJS' = 'angularjs',
  'ANIMATION' = 'animation',
  'AUTO_AWESOME_MOTION' = 'auto-awesome-motion',
  'ANIMATION_OUTLINE' = 'animation-outline',
  'ANIMATION_PLAY' = 'animation-play',
  'ANIMATION_PLAY_OUTLINE' = 'animation-play-outline',
  'ANSIBLE' = 'ansible',
  'ANTENNA' = 'antenna',
  'ANVIL' = 'anvil',
  'APACHE_KAFKA' = 'apache-kafka',
  'API' = 'api',
  'API_OFF' = 'api-off',
  'APPLE' = 'apple',
  'APPLE_FINDER' = 'apple-finder',
  'APPLE_ICLOUD' = 'apple-icloud',
  'APPLE_MOBILEME' = 'apple-mobileme',
  'APPLE_IOS' = 'apple-ios',
  'APPLE_KEYBOARD_CAPS' = 'apple-keyboard-caps',
  'APPLE_KEYBOARD_COMMAND' = 'apple-keyboard-command',
  'APPLE_KEYBOARD_CONTROL' = 'apple-keyboard-control',
  'APPLE_KEYBOARD_OPTION' = 'apple-keyboard-option',
  'APPLE_KEYBOARD_SHIFT' = 'apple-keyboard-shift',
  'APPLE_SAFARI' = 'apple-safari',
  'APPLICATION' = 'application',
  'IFRAME' = 'iframe',
  'APPLICATION_ARRAY' = 'application-array',
  'IFRAME_ARRAY' = 'iframe-array',
  'APPLICATION_ARRAY_OUTLINE' = 'application-array-outline',
  'IFRAME_ARRAY_OUTLINE' = 'iframe-array-outline',
  'APPLICATION_BRACES' = 'application-braces',
  'IFRAME_BRACES' = 'iframe-braces',
  'APPLICATION_BRACES_OUTLINE' = 'application-braces-outline',
  'IFRAME_BRACES_OUTLINE' = 'iframe-braces-outline',
  'APPLICATION_BRACKETS' = 'application-brackets',
  'IFRAME_BRACKETS' = 'iframe-brackets',
  'APPLICATION_BRACKETS_OUTLINE' = 'application-brackets-outline',
  'IFRAME_BRACKETS_OUTLINE' = 'iframe-brackets-outline',
  'APPLICATION_COG' = 'application-cog',
  'IFRAME_COG' = 'iframe-cog',
  'APPLICATION_COG_OUTLINE' = 'application-cog-outline',
  'APPLICATION_SETTINGS' = 'application-settings',
  'IFRAME_COG_OUTLINE' = 'iframe-cog-outline',
  'APPLICATION_EDIT' = 'application-edit',
  'IFRAME_EDIT' = 'iframe-edit',
  'APPLICATION_EDIT_OUTLINE' = 'application-edit-outline',
  'IFRAME_EDIT_OUTLINE' = 'iframe-edit-outline',
  'APPLICATION_EXPORT' = 'application-export',
  'IFRAME_EXPORT_OUTLINE' = 'iframe-export-outline',
  'APPLICATION_IMPORT' = 'application-import',
  'IFRAME_IMPORT_OUTLINE' = 'iframe-import-outline',
  'APPLICATION_OUTLINE' = 'application-outline',
  'WEB_ASSET' = 'web-asset',
  'IFRAME_OUTLINE' = 'iframe-outline',
  'APPLICATION_PARENTHESES' = 'application-parentheses',
  'IFRAME_PARENTHESES' = 'iframe-parentheses',
  'APPLICATION_PARENTHESES_OUTLINE' = 'application-parentheses-outline',
  'IFRAME_PARENTHESES_OUTLINE' = 'iframe-parentheses-outline',
  'IFRAME_SETTINGS' = 'iframe-settings',
  'APPLICATION_SETTINGS_OUTLINE' = 'application-settings-outline',
  'IFRAME_SETTINGS_OUTLINE' = 'iframe-settings-outline',
  'APPLICATION_VARIABLE' = 'application-variable',
  'IFRAME_VARIABLE' = 'iframe-variable',
  'APPLICATION_VARIABLE_OUTLINE' = 'application-variable-outline',
  'IFRAME_VARIABLE_OUTLINE' = 'iframe-variable-outline',
  'APPROXIMATELY_EQUAL' = 'approximately-equal',
  'APPROXIMATELY_EQUAL_BOX' = 'approximately-equal-box',
  'APPS' = 'apps',
  'APPS_BOX' = 'apps-box',
  'ARCH' = 'arch',
  'ARCHIVE' = 'archive',
  'BOX' = 'box',
  'ARCHIVE_ALERT' = 'archive-alert',
  'BOX_ALERT' = 'box-alert',
  'ARCHIVE_ALERT_OUTLINE' = 'archive-alert-outline',
  'BOX_ALERT_OUTLINE' = 'box-alert-outline',
  'ARCHIVE_ARROW_DOWN' = 'archive-arrow-down',
  'BOX_ARROW_DOWN' = 'box-arrow-down',
  'THIS_SIDE_DOWN' = 'this-side-down',
  'ARCHIVE_ARROW_DOWN_OUTLINE' = 'archive-arrow-down-outline',
  'THIS_SIDE_DOWN_OUTLINE' = 'this-side-down-outline',
  'ARCHIVE_ARROW_UP' = 'archive-arrow-up',
  'BOX_ARROW_UP' = 'box-arrow-up',
  'THIS_SIDE_UP' = 'this-side-up',
  'ARCHIVE_ARROW_UP_OUTLINE' = 'archive-arrow-up-outline',
  'BOX_ARROW_UP_OUTLINE' = 'box-arrow-up-outline',
  'THIS_SIDE_UP_OUTLINE' = 'this-side-up-outline',
  'ARCHIVE_CANCEL' = 'archive-cancel',
  'BOX_CANCEL' = 'box-cancel',
  'ARCHIVE_CANCEL_OUTLINE' = 'archive-cancel-outline',
  'BOX_CANCEL_OUTLINE' = 'box-cancel-outline',
  'ARCHIVE_CHECK' = 'archive-check',
  'BOX_CHECK' = 'box-check',
  'ARCHIVE_CHECK_OUTLINE' = 'archive-check-outline',
  'BOX_CHECK_OUTLINE' = 'box-check-outline',
  'ARCHIVE_CLOCK' = 'archive-clock',
  'BOX_CLOCK' = 'box-clock',
  'BOX_TIME' = 'box-time',
  'ARCHIVE_TIME' = 'archive-time',
  'ARCHIVE_CLOCK_OUTLINE' = 'archive-clock-outline',
  'BOX_CLOCK_OUTLINE' = 'box-clock-outline',
  'BOX_TIME_OUTLINE' = 'box-time-outline',
  'ARCHIVE_TIME_OUTLINE' = 'archive-time-outline',
  'ARCHIVE_COG' = 'archive-cog',
  'BOX_COG' = 'box-cog',
  'ARCHIVE_COG_OUTLINE' = 'archive-cog-outline',
  'BOX_COG_OUTLINE' = 'box-cog-outline',
  'ARCHIVE_EDIT' = 'archive-edit',
  'BOX_EDIT' = 'box-edit',
  'ARCHIVE_EDIT_OUTLINE' = 'archive-edit-outline',
  'BOX_EDIT_OUTLINE' = 'box-edit-outline',
  'ARCHIVE_EYE' = 'archive-eye',
  'ARCHIVE_VIEW' = 'archive-view',
  'BOX_EYE' = 'box-eye',
  'BOX_VIEW' = 'box-view',
  'ARCHIVE_EYE_OUTLINE' = 'archive-eye-outline',
  'ARCHIVE_VIEW_OUTLINE' = 'archive-view-outline',
  'BOX_EYE_OUTLINE' = 'box-eye-outline',
  'BOX_VIEW_OUTLINE' = 'box-view-outline',
  'ARCHIVE_LOCK' = 'archive-lock',
  'BOX_LOCK' = 'box-lock',
  'ARCHIVE_LOCK_OPEN' = 'archive-lock-open',
  'BOX_LOCK_OPEN' = 'box-lock-open',
  'ARCHIVE_LOCK_OPEN_OUTLINE' = 'archive-lock-open-outline',
  'BOX_LOCK_OPEN_OUTLINE' = 'box-lock-open-outline',
  'ARCHIVE_LOCK_OUTLINE' = 'archive-lock-outline',
  'BOX_LOCK_OUTLINE' = 'box-lock-outline',
  'ARCHIVE_MARKER' = 'archive-marker',
  'ARCHIVE_LOCATION' = 'archive-location',
  'BOX_MARKER' = 'box-marker',
  'BOX_LOCATION' = 'box-location',
  'ARCHIVE_MARKER_OUTLINE' = 'archive-marker-outline',
  'ARCHIVE_LOCATION_OUTLINE' = 'archive-location-outline',
  'BOX_MARKER_OUTLINE' = 'box-marker-outline',
  'BOX_LOCATION_OUTLINE' = 'box-location-outline',
  'ARCHIVE_MINUS' = 'archive-minus',
  'BOX_MINUS' = 'box-minus',
  'ARCHIVE_MINUS_OUTLINE' = 'archive-minus-outline',
  'BOX_MINUS_OUTLINE' = 'box-minus-outline',
  'ARCHIVE_MUSIC' = 'archive-music',
  'BOX_MUSIC' = 'box-music',
  'ARCHIVE_MUSIC_OUTLINE' = 'archive-music-outline',
  'BOX_MUSIC_OUTLINE' = 'box-music-outline',
  'ARCHIVE_OFF' = 'archive-off',
  'BOX_OFF' = 'box-off',
  'ARCHIVE_OFF_OUTLINE' = 'archive-off-outline',
  'BOX_OFF_OUTLINE' = 'box-off-outline',
  'ARCHIVE_OUTLINE' = 'archive-outline',
  'BOX_OUTLINE' = 'box-outline',
  'ARCHIVE_PLUS' = 'archive-plus',
  'ARCHIVE_ADD' = 'archive-add',
  'BOX_PLUS' = 'box-plus',
  'BOX_ADD' = 'box-add',
  'ARCHIVE_PLUS_OUTLINE' = 'archive-plus-outline',
  'ARCHIVE_ADD_OUTLINE' = 'archive-add-outline',
  'BOX_PLUS_OUTLINE' = 'box-plus-outline',
  'BOX_ADD_OUTLINE' = 'box-add-outline',
  'ARCHIVE_REFRESH' = 'archive-refresh',
  'BOX_REFRESH' = 'box-refresh',
  'ARCHIVE_REFRESH_OUTLINE' = 'archive-refresh-outline',
  'BOX_REFRESH_OUTLINE' = 'box-refresh-outline',
  'ARCHIVE_REMOVE' = 'archive-remove',
  'BOX_REMOVE' = 'box-remove',
  'ARCHIVE_REMOVE_OUTLINE' = 'archive-remove-outline',
  'BOX_REMOVE_OUTLINE' = 'box-remove-outline',
  'ARCHIVE_SEARCH' = 'archive-search',
  'BOX_SEARCH' = 'box-search',
  'ARCHIVE_SEARCH_OUTLINE' = 'archive-search-outline',
  'BOX_SEARCH_OUTLINE' = 'box-search-outline',
  'ARCHIVE_SETTINGS' = 'archive-settings',
  'BOX_SETTINGS' = 'box-settings',
  'ARCHIVE_SETTINGS_OUTLINE' = 'archive-settings-outline',
  'BOX_SETTINGS_OUTLINE' = 'box-settings-outline',
  'ARCHIVE_STAR' = 'archive-star',
  'ARCHIVE_FAVORITE' = 'archive-favorite',
  'BOX_STAR' = 'box-star',
  'BOX_FAVORITE' = 'box-favorite',
  'ARCHIVE_STAR_OUTLINE' = 'archive-star-outline',
  'ARCHIVE_FAVORITE_OUTLINE' = 'archive-favorite-outline',
  'BOX_STAR_OUTLINE' = 'box-star-outline',
  'BOX_FAVORITE_OUTLINE' = 'box-favorite-outline',
  'ARCHIVE_SYNC' = 'archive-sync',
  'BOX_SYNC' = 'box-sync',
  'ARCHIVE_SYNC_OUTLINE' = 'archive-sync-outline',
  'BOX_SYNC_OUTLINE' = 'box-sync-outline',
  'ARM_FLEX' = 'arm-flex',
  'ARM_FLEX_OUTLINE' = 'arm-flex-outline',
  'ARRANGE_BRING_FORWARD' = 'arrange-bring-forward',
  'ARRANGE_BRING_TO_FRONT' = 'arrange-bring-to-front',
  'ARRANGE_SEND_BACKWARD' = 'arrange-send-backward',
  'ARRANGE_SEND_TO_BACK' = 'arrange-send-to-back',
  'ARROW_ALL' = 'arrow-all',
  'ARROW_BOTTOM_LEFT' = 'arrow-bottom-left',
  'ARROW_DOWN_LEFT' = 'arrow-down-left',
  'ARROW_BOTTOM_LEFT_BOLD_BOX' = 'arrow-bottom-left-bold-box',
  'ARROW_BOTTOM_LEFT_BOLD_BOX_OUTLINE' = 'arrow-bottom-left-bold-box-outline',
  'ARROW_BOTTOM_LEFT_BOLD_OUTLINE' = 'arrow-bottom-left-bold-outline',
  'ARROW_DOWN_LEFT_BOLD_OUTLINE' = 'arrow-down-left-bold-outline',
  'ARROW_BOTTOM_LEFT_THICK' = 'arrow-bottom-left-thick',
  'ARROW_DOWN_LEFT_THICK' = 'arrow-down-left-thick',
  'ARROW_BOTTOM_LEFT_BOLD' = 'arrow-bottom-left-bold',
  'ARROW_DOWN_LEFT_BOLD' = 'arrow-down-left-bold',
  'ARROW_BOTTOM_LEFT_THIN' = 'arrow-bottom-left-thin',
  'ARROW_BOTTOM_LEFT_THIN_CIRCLE_OUTLINE' = 'arrow-bottom-left-thin-circle-outline',
  'ARROW_BOTTOM_RIGHT' = 'arrow-bottom-right',
  'ARROW_DOWN_RIGHT' = 'arrow-down-right',
  'ARROW_BOTTOM_RIGHT_BOLD_BOX' = 'arrow-bottom-right-bold-box',
  'ARROW_BOTTOM_RIGHT_BOLD_BOX_OUTLINE' = 'arrow-bottom-right-bold-box-outline',
  'ARROW_BOTTOM_RIGHT_BOLD_OUTLINE' = 'arrow-bottom-right-bold-outline',
  'ARROW_DOWN_RIGHT_BOLD_OUTLINE' = 'arrow-down-right-bold-outline',
  'ARROW_BOTTOM_RIGHT_THICK' = 'arrow-bottom-right-thick',
  'ARROW_DOWN_RIGHT_THICK' = 'arrow-down-right-thick',
  'ARROW_BOTTOM_RIGHT_BOLD' = 'arrow-bottom-right-bold',
  'ARROW_DOWN_RIGHT_BOLD' = 'arrow-down-right-bold',
  'ARROW_BOTTOM_RIGHT_THIN' = 'arrow-bottom-right-thin',
  'ARROW_BOTTOM_RIGHT_THIN_CIRCLE_OUTLINE' = 'arrow-bottom-right-thin-circle-outline',
  'ARROW_COLLAPSE' = 'arrow-collapse',
  'ARROW_COMPRESS' = 'arrow-compress',
  'ARROW_COLLAPSE_ALL' = 'arrow-collapse-all',
  'ARROW_COMPRESS_ALL' = 'arrow-compress-all',
  'ARROW_COLLAPSE_DOWN' = 'arrow-collapse-down',
  'ARROW_COMPRESS_DOWN' = 'arrow-compress-down',
  'ARROW_COLLAPSE_HORIZONTAL' = 'arrow-collapse-horizontal',
  'ARROW_COLLAPSE_LEFT' = 'arrow-collapse-left',
  'ARROW_COMPRESS_LEFT' = 'arrow-compress-left',
  'ARROW_COLLAPSE_RIGHT' = 'arrow-collapse-right',
  'ARROW_COMPRESS_RIGHT' = 'arrow-compress-right',
  'ARROW_COLLAPSE_UP' = 'arrow-collapse-up',
  'ARROW_COMPRESS_UP' = 'arrow-compress-up',
  'ARROW_COLLAPSE_VERTICAL' = 'arrow-collapse-vertical',
  'COMPRESS' = 'compress',
  'ARROW_DECISION' = 'arrow-decision',
  'PROXY' = 'proxy',
  'ARROW_DECISION_AUTO' = 'arrow-decision-auto',
  'PROXY_AUTO' = 'proxy-auto',
  'ARROW_DECISION_AUTO_OUTLINE' = 'arrow-decision-auto-outline',
  'PROXY_AUTO_OUTLINE' = 'proxy-auto-outline',
  'ARROW_DECISION_OUTLINE' = 'arrow-decision-outline',
  'PROXY_OUTLINE' = 'proxy-outline',
  'ARROW_DOWN' = 'arrow-down',
  'ARROW_DOWNWARD' = 'arrow-downward',
  'ARROW_BOTTOM' = 'arrow-bottom',
  'ARROW_DOWN_BOLD' = 'arrow-down-bold',
  'ARROW_BOTTOM_BOLD' = 'arrow-bottom-bold',
  'ARROW_DOWN_BOLD_BOX' = 'arrow-down-bold-box',
  'ARROW_BOTTOM_BOLD_BOX' = 'arrow-bottom-bold-box',
  'ARROW_DOWN_BOLD_BOX_OUTLINE' = 'arrow-down-bold-box-outline',
  'ARROW_BOTTOM_BOLD_BOX_OUTLINE' = 'arrow-bottom-bold-box-outline',
  'ARROW_DOWN_BOLD_CIRCLE' = 'arrow-down-bold-circle',
  'ARROW_BOTTOM_BOLD_CIRCLE' = 'arrow-bottom-bold-circle',
  'ARROW_DOWN_BOLD_CIRCLE_OUTLINE' = 'arrow-down-bold-circle-outline',
  'ARROW_BOTTOM_BOLD_CIRCLE_OUTLINE' = 'arrow-bottom-bold-circle-outline',
  'ARROW_DOWN_BOLD_HEXAGON_OUTLINE' = 'arrow-down-bold-hexagon-outline',
  'ARROW_BOTTOM_BOLD_HEXAGON_OUTLINE' = 'arrow-bottom-bold-hexagon-outline',
  'ARROW_DOWN_BOLD_OUTLINE' = 'arrow-down-bold-outline',
  'ARROW_BOTTOM_BOLD_OUTLINE' = 'arrow-bottom-bold-outline',
  'ARROW_DOWN_BOX' = 'arrow-down-box',
  'ARROW_BOTTOM_BOX' = 'arrow-bottom-box',
  'ARROW_DOWN_CIRCLE' = 'arrow-down-circle',
  'ARROW_BOTTOM_CIRCLE' = 'arrow-bottom-circle',
  'ARROW_DOWN_CIRCLE_OUTLINE' = 'arrow-down-circle-outline',
  'ARROW_BOTTOM_CIRCLE_OUTLINE' = 'arrow-bottom-circle-outline',
  'ARROW_DOWN_DROP_CIRCLE' = 'arrow-down-drop-circle',
  'ARROW_DROP_DOWN_CIRCLE' = 'arrow-drop-down-circle',
  'ARROW_BOTTOM_DROP_CIRCLE' = 'arrow-bottom-drop-circle',
  'ARROW_DOWN_DROP_CIRCLE_OUTLINE' = 'arrow-down-drop-circle-outline',
  'ARROW_BOTTOM_DROP_CIRCLE_OUTLINE' = 'arrow-bottom-drop-circle-outline',
  'ARROW_DOWN_THICK' = 'arrow-down-thick',
  'ARROW_BOTTOM_THICK' = 'arrow-bottom-thick',
  'ARROW_DOWN_THIN' = 'arrow-down-thin',
  'ARROW_DOWN_THIN_CIRCLE_OUTLINE' = 'arrow-down-thin-circle-outline',
  'ARROW_EXPAND' = 'arrow-expand',
  'ARROW_EXPAND_ALL' = 'arrow-expand-all',
  'ARROW_EXPAND_DOWN' = 'arrow-expand-down',
  'ARROW_EXPAND_HORIZONTAL' = 'arrow-expand-horizontal',
  'ARROW_EXPAND_LEFT' = 'arrow-expand-left',
  'ARROW_EXPAND_RIGHT' = 'arrow-expand-right',
  'ARROW_EXPAND_UP' = 'arrow-expand-up',
  'ARROW_EXPAND_VERTICAL' = 'arrow-expand-vertical',
  'ARROW_HORIZONTAL_LOCK' = 'arrow-horizontal-lock',
  'SCROLL_HORIZONTAL_LOCK' = 'scroll-horizontal-lock',
  'ARROW_LEFT' = 'arrow-left',
  'ARROW_BACK' = 'arrow-back',
  'ARROW_LEFT_BOLD' = 'arrow-left-bold',
  'ARROW_LEFT_BOLD_BOX' = 'arrow-left-bold-box',
  'ARROW_LEFT_BOLD_BOX_OUTLINE' = 'arrow-left-bold-box-outline',
  'ARROW_LEFT_BOLD_CIRCLE' = 'arrow-left-bold-circle',
  'ARROW_LEFT_BOLD_CIRCLE_OUTLINE' = 'arrow-left-bold-circle-outline',
  'ARROW_LEFT_BOLD_HEXAGON_OUTLINE' = 'arrow-left-bold-hexagon-outline',
  'ARROW_LEFT_BOLD_OUTLINE' = 'arrow-left-bold-outline',
  'ARROW_LEFT_BOTTOM' = 'arrow-left-bottom',
  'ARROW_LEFT_BOTTOM_BOLD' = 'arrow-left-bottom-bold',
  'ARROW_LEFT_BOX' = 'arrow-left-box',
  'ARROW_LEFT_CIRCLE' = 'arrow-left-circle',
  'ARROW_BACK_CIRCLE' = 'arrow-back-circle',
  'ARROW_LEFT_CIRCLE_OUTLINE' = 'arrow-left-circle-outline',
  'ARROW_LEFT_DROP_CIRCLE' = 'arrow-left-drop-circle',
  'ARROW_LEFT_DROP_CIRCLE_OUTLINE' = 'arrow-left-drop-circle-outline',
  'ARROW_LEFT_RIGHT' = 'arrow-left-right',
  'ARROW_LEFT_RIGHT_BOLD' = 'arrow-left-right-bold',
  'ARROW_LEFT_RIGHT_BOLD_OUTLINE' = 'arrow-left-right-bold-outline',
  'ARROW_LEFT_THICK' = 'arrow-left-thick',
  'ARROW_LEFT_THIN' = 'arrow-left-thin',
  'ARROW_LEFT_THIN_CIRCLE_OUTLINE' = 'arrow-left-thin-circle-outline',
  'ARROW_LEFT_TOP' = 'arrow-left-top',
  'TURN_LEFT' = 'turn-left',
  'ARROW_LEFT_TOP_BOLD' = 'arrow-left-top-bold',
  'TURN_LEFT_BOLD' = 'turn-left-bold',
  'ARROW_PROJECTILE' = 'arrow-projectile',
  'ARROW_PROJECTILE_MULTIPLE' = 'arrow-projectile-multiple',
  'ARROW_RIGHT' = 'arrow-right',
  'ARROW_FORWARD' = 'arrow-forward',
  'ARROW_RIGHT_BOLD' = 'arrow-right-bold',
  'ARROW_RIGHT_BOLD_BOX' = 'arrow-right-bold-box',
  'ARROW_RIGHT_BOLD_BOX_OUTLINE' = 'arrow-right-bold-box-outline',
  'ARROW_RIGHT_BOLD_CIRCLE' = 'arrow-right-bold-circle',
  'ARROW_RIGHT_BOLD_CIRCLE_OUTLINE' = 'arrow-right-bold-circle-outline',
  'ARROW_RIGHT_BOLD_HEXAGON_OUTLINE' = 'arrow-right-bold-hexagon-outline',
  'ARROW_RIGHT_BOLD_OUTLINE' = 'arrow-right-bold-outline',
  'ARROW_RIGHT_BOTTOM' = 'arrow-right-bottom',
  'ARROW_RIGHT_BOTTOM_BOLD' = 'arrow-right-bottom-bold',
  'ARROW_RIGHT_BOX' = 'arrow-right-box',
  'ARROW_RIGHT_CIRCLE' = 'arrow-right-circle',
  'ARROW_FORWARD_CIRCLE' = 'arrow-forward-circle',
  'ARROW_RIGHT_CIRCLE_OUTLINE' = 'arrow-right-circle-outline',
  'ARROW_RIGHT_DROP_CIRCLE' = 'arrow-right-drop-circle',
  'ARROW_RIGHT_DROP_CIRCLE_OUTLINE' = 'arrow-right-drop-circle-outline',
  'ARROW_RIGHT_THICK' = 'arrow-right-thick',
  'ARROW_RIGHT_THIN' = 'arrow-right-thin',
  'ARROW_RIGHT_THIN_CIRCLE_OUTLINE' = 'arrow-right-thin-circle-outline',
  'ARROW_RIGHT_TOP' = 'arrow-right-top',
  'TURN_RIGHT' = 'turn-right',
  'ARROW_RIGHT_TOP_BOLD' = 'arrow-right-top-bold',
  'TURN_RIGHT_BOLD' = 'turn-right-bold',
  'ARROW_SPLIT_HORIZONTAL' = 'arrow-split-horizontal',
  'RESIZE_VERTICAL' = 'resize-vertical',
  'RESIZE' = 'resize',
  'ARROW_SPLIT_VERTICAL' = 'arrow-split-vertical',
  'RESIZE_HORIZONTAL' = 'resize-horizontal',
  'ARROW_TOP_LEFT' = 'arrow-top-left',
  'ARROW_UP_LEFT' = 'arrow-up-left',
  'ARROW_TOP_LEFT_BOLD_BOX' = 'arrow-top-left-bold-box',
  'ARROW_TOP_LEFT_BOLD_BOX_OUTLINE' = 'arrow-top-left-bold-box-outline',
  'ARROW_TOP_LEFT_BOLD_OUTLINE' = 'arrow-top-left-bold-outline',
  'ARROW_UP_LEFT_BOLD_OUTLINE' = 'arrow-up-left-bold-outline',
  'ARROW_TOP_LEFT_BOTTOM_RIGHT' = 'arrow-top-left-bottom-right',
  'ARROW_TOP_LEFT_BOTTOM_RIGHT_BOLD' = 'arrow-top-left-bottom-right-bold',
  'ARROW_TOP_LEFT_THICK' = 'arrow-top-left-thick',
  'ARROW_UP_LEFT_THICK' = 'arrow-up-left-thick',
  'ARROW_TOP_LEFT_BOLD' = 'arrow-top-left-bold',
  'ARROW_UP_LEFT_BOLD' = 'arrow-up-left-bold',
  'ARROW_TOP_LEFT_THIN' = 'arrow-top-left-thin',
  'ARROW_TOP_LEFT_THIN_CIRCLE_OUTLINE' = 'arrow-top-left-thin-circle-outline',
  'ARROW_TOP_RIGHT' = 'arrow-top-right',
  'ARROW_UP_RIGHT' = 'arrow-up-right',
  'ARROW_TOP_RIGHT_BOLD_BOX' = 'arrow-top-right-bold-box',
  'ARROW_TOP_RIGHT_BOLD_BOX_OUTLINE' = 'arrow-top-right-bold-box-outline',
  'ARROW_TOP_RIGHT_BOLD_OUTLINE' = 'arrow-top-right-bold-outline',
  'ARROW_UP_RIGHT_BOLD_OUTLINE' = 'arrow-up-right-bold-outline',
  'ARROW_TOP_RIGHT_BOTTOM_LEFT' = 'arrow-top-right-bottom-left',
  'ARROW_TOP_RIGHT_BOTTOM_LEFT_BOLD' = 'arrow-top-right-bottom-left-bold',
  'ARROW_TOP_RIGHT_THICK' = 'arrow-top-right-thick',
  'ARROW_UP_RIGHT_THICK' = 'arrow-up-right-thick',
  'ARROW_TOP_RIGHT_BOLD' = 'arrow-top-right-bold',
  'ARROW_UP_RIGHT_BOLD' = 'arrow-up-right-bold',
  'ARROW_TOP_RIGHT_THIN' = 'arrow-top-right-thin',
  'ARROW_TOP_RIGHT_THIN_CIRCLE_OUTLINE' = 'arrow-top-right-thin-circle-outline',
  'ARROW_UDOWN_LEFT' = 'arrow-udown-left',
  'ARROW_UDOWN_LEFT_BOLD' = 'arrow-udown-left-bold',
  'ARROW_UDOWN_RIGHT' = 'arrow-udown-right',
  'ARROW_UDOWN_RIGHT_BOLD' = 'arrow-udown-right-bold',
  'ARROW_ULEFT_BOTTOM' = 'arrow-uleft-bottom',
  'ARROW_ULEFT_BOTTOM_BOLD' = 'arrow-uleft-bottom-bold',
  'ARROW_ULEFT_TOP' = 'arrow-uleft-top',
  'ARROW_ULEFT_TOP_BOLD' = 'arrow-uleft-top-bold',
  'ARROW_URIGHT_BOTTOM' = 'arrow-uright-bottom',
  'ARROW_URIGHT_BOTTOM_BOLD' = 'arrow-uright-bottom-bold',
  'ARROW_URIGHT_TOP' = 'arrow-uright-top',
  'ARROW_URIGHT_TOP_BOLD' = 'arrow-uright-top-bold',
  'ARROW_UUP_LEFT' = 'arrow-uup-left',
  'ARROW_UUP_LEFT_BOLD' = 'arrow-uup-left-bold',
  'ARROW_UUP_RIGHT' = 'arrow-uup-right',
  'ARROW_UUP_RIGHT_BOLD' = 'arrow-uup-right-bold',
  'ARROW_UP' = 'arrow-up',
  'ARROW_UPWARD' = 'arrow-upward',
  'ARROW_TOP' = 'arrow-top',
  'ARROW_UP_BOLD' = 'arrow-up-bold',
  'ARROW_TOP_BOLD' = 'arrow-top-bold',
  'ARROW_UP_BOLD_BOX' = 'arrow-up-bold-box',
  'ARROW_TOP_BOLD_BOX' = 'arrow-top-bold-box',
  'ARROW_UP_BOLD_BOX_OUTLINE' = 'arrow-up-bold-box-outline',
  'ARROW_TOP_BOLD_BOX_OUTLINE' = 'arrow-top-bold-box-outline',
  'ARROW_UP_BOLD_CIRCLE' = 'arrow-up-bold-circle',
  'ARROW_TOP_BOLD_CIRCLE' = 'arrow-top-bold-circle',
  'ARROW_UP_BOLD_CIRCLE_OUTLINE' = 'arrow-up-bold-circle-outline',
  'ARROW_TOP_BOLD_CIRCLE_OUTLINE' = 'arrow-top-bold-circle-outline',
  'ARROW_UP_BOLD_HEXAGON_OUTLINE' = 'arrow-up-bold-hexagon-outline',
  'ARROW_TOP_BOLD_HEXAGON_OUTLINE' = 'arrow-top-bold-hexagon-outline',
  'ARROW_UP_BOLD_OUTLINE' = 'arrow-up-bold-outline',
  'ARROW_TOP_BOLD_OUTLINE' = 'arrow-top-bold-outline',
  'ARROW_UP_BOX' = 'arrow-up-box',
  'ARROW_UP_CIRCLE' = 'arrow-up-circle',
  'ARROW_TOP_CIRCLE' = 'arrow-top-circle',
  'ARROW_UP_CIRCLE_OUTLINE' = 'arrow-up-circle-outline',
  'ARROW_TOP_CIRCLE_OUTLINE' = 'arrow-top-circle-outline',
  'ARROW_UP_DOWN' = 'arrow-up-down',
  'ARROW_UP_DOWN_BOLD' = 'arrow-up-down-bold',
  'ARROW_UP_DOWN_BOLD_OUTLINE' = 'arrow-up-down-bold-outline',
  'ARROW_UP_DROP_CIRCLE' = 'arrow-up-drop-circle',
  'ARROW_TOP_DROP_CIRCLE' = 'arrow-top-drop-circle',
  'ARROW_UP_DROP_CIRCLE_OUTLINE' = 'arrow-up-drop-circle-outline',
  'ARROW_TOP_DROP_CIRCLE_OUTLINE' = 'arrow-top-drop-circle-outline',
  'ARROW_UP_THICK' = 'arrow-up-thick',
  'ARROW_TOP_THICK' = 'arrow-top-thick',
  'ARROW_UP_THIN' = 'arrow-up-thin',
  'ARROW_UP_THIN_CIRCLE_OUTLINE' = 'arrow-up-thin-circle-outline',
  'ARROW_VERTICAL_LOCK' = 'arrow-vertical-lock',
  'SCROLL_VERTICAL_LOCK' = 'scroll-vertical-lock',
  'ARTSTATION' = 'artstation',
  'ASPECT_RATIO' = 'aspect-ratio',
  'ASSISTANT' = 'assistant',
  'ASTERISK' = 'asterisk',
  'REQUIRED' = 'required',
  'ASTERISK_CIRCLE_OUTLINE' = 'asterisk-circle-outline',
  'REQUIRED_CIRCLE' = 'required-circle',
  'AT' = 'at',
  'ALTERNATE_EMAIL' = 'alternate-email',
  'ATLASSIAN' = 'atlassian',
  'ATM' = 'atm',
  'ATOM' = 'atom',
  'ATOM_VARIANT' = 'atom-variant',
  'ORBIT' = 'orbit',
  'ATTACHMENT' = 'attachment',
  'PAPERCLIP_HORIZONTAL' = 'paperclip-horizontal',
  'ATTACHMENT_LOCK' = 'attachment-lock',
  'PAPERCLIP_LOCK' = 'paperclip-lock',
  'AUDIO_INPUT_RCA' = 'audio-input-rca',
  'AUDIO_INPUT_STEREO_MINIJACK' = 'audio-input-stereo-minijack',
  'AUDIO_INPUT_XLR' = 'audio-input-xlr',
  'AUDIO_VIDEO' = 'audio-video',
  'AV_RECEIVER' = 'av-receiver',
  'AUDIO_VIDEO_OFF' = 'audio-video-off',
  'AV_RECEIVER_OFF' = 'av-receiver-off',
  'AUGMENTED_REALITY' = 'augmented-reality',
  'AUTO_DOWNLOAD' = 'auto-download',
  'AUTO_FIX' = 'auto-fix',
  'MAGIC' = 'magic',
  'WAND' = 'wand',
  'AUTO_FIX_HIGH' = 'auto-fix-high',
  'AUTO_UPLOAD' = 'auto-upload',
  'AUTORENEW' = 'autorenew',
  'CLOCKWISE_ARROWS' = 'clockwise-arrows',
  'CIRCULAR_ARROWS' = 'circular-arrows',
  'CIRCLE_ARROWS' = 'circle-arrows',
  'SYNC' = 'sync',
  'AUTORENEW_OFF' = 'autorenew-off',
  'CLOCKWISE_ARROWS_OFF' = 'clockwise-arrows-off',
  'CIRCULAR_ARROWS_OFF' = 'circular-arrows-off',
  'CIRCLE_ARROWS_OFF' = 'circle-arrows-off',
  'SYNC_OFF' = 'sync-off',
  'AV_TIMER' = 'av-timer',
  'AWS' = 'aws',
  'AXE' = 'axe',
  'AXE_BATTLE' = 'axe-battle',
  'AXIS' = 'axis',
  'AXIS_ARROW' = 'axis-arrow',
  'ACCELEROMETER' = 'accelerometer',
  'GYRO' = 'gyro',
  'AXIS_ARROW_INFO' = 'axis-arrow-info',
  'AXIS_ARROW_LOCK' = 'axis-arrow-lock',
  'AXIS_LOCK' = 'axis-lock',
  'AXIS_XARROW' = 'axis-xarrow',
  'AXIS_XARROW_LOCK' = 'axis-xarrow-lock',
  'AXIS_XROTATE_CLOCKWISE' = 'axis-xrotate-clockwise',
  'AXIS_XROTATE_COUNTERCLOCKWISE' = 'axis-xrotate-counterclockwise',
  'AXIS_XYARROW_LOCK' = 'axis-xyarrow-lock',
  'AXIS_YARROW' = 'axis-yarrow',
  'AXIS_YARROW_LOCK' = 'axis-yarrow-lock',
  'AXIS_YROTATE_CLOCKWISE' = 'axis-yrotate-clockwise',
  'AXIS_YROTATE_COUNTERCLOCKWISE' = 'axis-yrotate-counterclockwise',
  'AXIS_ZARROW' = 'axis-zarrow',
  'AXIS_ZARROW_LOCK' = 'axis-zarrow-lock',
  'AXIS_ZROTATE_CLOCKWISE' = 'axis-zrotate-clockwise',
  'AXIS_ZROTATE_COUNTERCLOCKWISE' = 'axis-zrotate-counterclockwise',
  'BABEL' = 'babel',
  'BABY' = 'baby',
  'BABY_BOTTLE' = 'baby-bottle',
  'BABY_BOTTLE_OUTLINE' = 'baby-bottle-outline',
  'BABY_BUGGY' = 'baby-buggy',
  'STROLLER' = 'stroller',
  'PRAM' = 'pram',
  'CARRIAGE' = 'carriage',
  'BABY_CARRIAGE' = 'baby-carriage',
  'CHILD_FRIENDLY' = 'child-friendly',
  'BUGGY' = 'buggy',
  'BABY_CARRIAGE_OFF' = 'baby-carriage-off',
  'CHILD_FRIENDLY_OFF' = 'child-friendly-off',
  'STROLLER_OFF' = 'stroller-off',
  'PRAM_OFF' = 'pram-off',
  'BUGGY_OFF' = 'buggy-off',
  'BABY_FACE' = 'baby-face',
  'EMOJI_BABY' = 'emoji-baby',
  'EMOTICON_BABY' = 'emoticon-baby',
  'BABY_FACE_OUTLINE' = 'baby-face-outline',
  'EMOJI_BABY_OUTLINE' = 'emoji-baby-outline',
  'EMOTICON_BABY_OUTLINE' = 'emoticon-baby-outline',
  'BACKBURGER' = 'backburger',
  'HAMBURGER_MENU_BACK' = 'hamburger-menu-back',
  'BACKSPACE' = 'backspace',
  'ERASE' = 'erase',
  'CLEAR' = 'clear',
  'BACKSPACE_OUTLINE' = 'backspace-outline',
  'ERASE_OUTLINE' = 'erase-outline',
  'CLEAR_OUTLINE' = 'clear-outline',
  'BACKSPACE_REVERSE' = 'backspace-reverse',
  'CLEAR_REVERSE' = 'clear-reverse',
  'ERASE_REVERSE' = 'erase-reverse',
  'BACKSPACE_REVERSE_OUTLINE' = 'backspace-reverse-outline',
  'CLEAR_REVERSE_OUTLINE' = 'clear-reverse-outline',
  'ERASE_REVERSE_OUTLINE' = 'erase-reverse-outline',
  'BACKUP_RESTORE' = 'backup-restore',
  'SETTINGS_BACKUP_RESTORE' = 'settings-backup-restore',
  'BACTERIA' = 'bacteria',
  'BACTERIA_OUTLINE' = 'bacteria-outline',
  'BADGE_ACCOUNT' = 'badge-account',
  'USER_BADGE' = 'user-badge',
  'PERSON_BADGE' = 'person-badge',
  'BADGE_ACCOUNT_ALERT' = 'badge-account-alert',
  'USER_BADGE_ALERT' = 'user-badge-alert',
  'PERSON_BADGE_ALERT' = 'person-badge-alert',
  'ACCOUNT_BADGE_WARNING' = 'account-badge-warning',
  'USER_BADGE_WARNING' = 'user-badge-warning',
  'PERSON_BADGE_WARNING' = 'person-badge-warning',
  'BADGE_ACCOUNT_ALERT_OUTLINE' = 'badge-account-alert-outline',
  'USER_BADGE_ALERT_OUTLINE' = 'user-badge-alert-outline',
  'PERSON_BADGE_ALERT_OUTLINE' = 'person-badge-alert-outline',
  'ACCOUNT_BADGE_WARNING_OUTLINE' = 'account-badge-warning-outline',
  'USER_BADGE_WARNING_OUTLINE' = 'user-badge-warning-outline',
  'PERSON_BADGE_WARNING_OUTLINE' = 'person-badge-warning-outline',
  'BADGE_ACCOUNT_HORIZONTAL' = 'badge-account-horizontal',
  'BADGE_ACCOUNT_HORIZONTAL_OUTLINE' = 'badge-account-horizontal-outline',
  'BADGE_ACCOUNT_OUTLINE' = 'badge-account-outline',
  'USER_BADGE_OUTLINE' = 'user-badge-outline',
  'PERSON_BADGE_OUTLINE' = 'person-badge-outline',
  'BADMINTON' = 'badminton',
  'SHUTTLECOCK' = 'shuttlecock',
  'BAG_CARRY_ON' = 'bag-carry-on',
  'CARRY_ON_LUGGAGE' = 'carry-on-luggage',
  'BAG_CARRY_ON_CHECK' = 'bag-carry-on-check',
  'CARRY_ON_BAG_TICK' = 'carry-on-bag-tick',
  'CARRY_ON_BAG_CHECK' = 'carry-on-bag-check',
  'BAG_CARRY_ON_OFF' = 'bag-carry-on-off',
  'CARRY_ON_LUGGAGE_OFF' = 'carry-on-luggage-off',
  'BAG_CHECKED' = 'bag-checked',
  'LUGGAGE' = 'luggage',
  'BAG_PERSONAL' = 'bag-personal',
  'BACKPACK' = 'backpack',
  'BAG_PERSONAL_OFF' = 'bag-personal-off',
  'BACKPACK_OFF' = 'backpack-off',
  'BAG_PERSONAL_OFF_OUTLINE' = 'bag-personal-off-outline',
  'BACKPACK_OFF_OUTLINE' = 'backpack-off-outline',
  'BAG_PERSONAL_OUTLINE' = 'bag-personal-outline',
  'BACKPACK_OUTLINE' = 'backpack-outline',
  'BAG_SUITCASE' = 'bag-suitcase',
  'BAG_SUITCASE_OFF' = 'bag-suitcase-off',
  'BAG_SUITCASE_OFF_OUTLINE' = 'bag-suitcase-off-outline',
  'BAG_SUITCASE_OUTLINE' = 'bag-suitcase-outline',
  'BAGUETTE' = 'baguette',
  'BREAD' = 'bread',
  'BAKERY' = 'bakery',
  'FRENCH_BAGUETTE' = 'french-baguette',
  'LOAF' = 'loaf',
  'BALCONY' = 'balcony',
  'TERRACE' = 'terrace',
  'PATIO' = 'patio',
  'VERANDA' = 'veranda',
  'BALLOON' = 'balloon',
  'PARTY_BALLOON' = 'party-balloon',
  'BALLOT' = 'ballot',
  'VOTE' = 'vote',
  'BALLOT_OUTLINE' = 'ballot-outline',
  'VOTE_OUTLINE' = 'vote-outline',
  'BALLOT_RECOUNT' = 'ballot-recount',
  'VOTE_RECOUNT' = 'vote-recount',
  'BALLOT_RECOUNT_OUTLINE' = 'ballot-recount-outline',
  'VOTE_RECOUNT_OUTLINE' = 'vote-recount-outline',
  'BANDAGE' = 'bandage',
  'BAND_AID' = 'band-aid',
  'PLASTER' = 'plaster',
  'BANK' = 'bank',
  'ACCOUNT_BALANCE' = 'account-balance',
  'MUSEUM' = 'museum',
  'BANK_CHECK' = 'bank-check',
  'BANK_MINUS' = 'bank-minus',
  'BANK_OFF' = 'bank-off',
  'BANK_OFF_OUTLINE' = 'bank-off-outline',
  'BANK_OUTLINE' = 'bank-outline',
  'MUSEUM_OUTLINE' = 'museum-outline',
  'BANK_PLUS' = 'bank-plus',
  'BANK_ADD' = 'bank-add',
  'BANK_REMOVE' = 'bank-remove',
  'BANK_TRANSFER' = 'bank-transfer',
  'BANK_TRANSFER_IN' = 'bank-transfer-in',
  'BANK_TRANSFER_OUT' = 'bank-transfer-out',
  'BARCODE' = 'barcode',
  'BARCODE_OFF' = 'barcode-off',
  'BARCODE_SCAN' = 'barcode-scan',
  'BARCODE_SCANNER' = 'barcode-scanner',
  'BARLEY' = 'barley',
  'GRAIN' = 'grain',
  'WHEAT' = 'wheat',
  'GLUTEN' = 'gluten',
  'BARLEY_OFF' = 'barley-off',
  'GLUTEN_FREE' = 'gluten-free',
  'GRAIN_OFF' = 'grain-off',
  'WHEAT_OFF' = 'wheat-off',
  'BARN' = 'barn',
  'FARM' = 'farm',
  'BARREL' = 'barrel',
  'OIL_BARREL' = 'oil-barrel',
  'ENERGY' = 'energy',
  'FOSSIL_FUEL' = 'fossil-fuel',
  'BASEBALL' = 'baseball',
  'BASEBALL_BAT' = 'baseball-bat',
  'BASEBALL_DIAMOND' = 'baseball-diamond',
  'BASEBALL_DIAMOND_OUTLINE' = 'baseball-diamond-outline',
  'BASH' = 'bash',
  'BASKET' = 'basket',
  'SHOPPING_BASKET' = 'shopping-basket',
  'SKIP' = 'skip',
  'BASKET_CHECK' = 'basket-check',
  'BASKET_CHECK_OUTLINE' = 'basket-check-outline',
  'BASKET_FILL' = 'basket-fill',
  'SKIP_FILL' = 'skip-fill',
  'BASKET_MINUS' = 'basket-minus',
  'SHOPPING_BASKET_MINUS' = 'shopping-basket-minus',
  'SKIP_MINUS' = 'skip-minus',
  'BASKET_MINUS_OUTLINE' = 'basket-minus-outline',
  'SHOPPING_BASKET_MINUS_OUTLINE' = 'shopping-basket-minus-outline',
  'SKIP_MINUS_OUTLINE' = 'skip-minus-outline',
  'BASKET_OFF' = 'basket-off',
  'SHOPPING_BASKET_OFF' = 'shopping-basket-off',
  'SKIP_OFF' = 'skip-off',
  'BASKET_OFF_OUTLINE' = 'basket-off-outline',
  'SHOPPING_BASKET_OFF_OUTLINE' = 'shopping-basket-off-outline',
  'SKIP_OFF_OUTLINE' = 'skip-off-outline',
  'BASKET_OUTLINE' = 'basket-outline',
  'SHOPPING_BASKET_OUTLINE' = 'shopping-basket-outline',
  'SKIP_OUTLINE' = 'skip-outline',
  'BASKET_PLUS' = 'basket-plus',
  'SHOPPING_BASKET_PLUS' = 'shopping-basket-plus',
  'SKIP_PLUS' = 'skip-plus',
  'BASKET_PLUS_OUTLINE' = 'basket-plus-outline',
  'SHOPPING_BASKET_PLUS_OUTLINE' = 'shopping-basket-plus-outline',
  'SKIP_PLUS_OUTLINE' = 'skip-plus-outline',
  'BASKET_REMOVE' = 'basket-remove',
  'SHOPPING_BASKET_REMOVE' = 'shopping-basket-remove',
  'SKIP_REMOVE' = 'skip-remove',
  'BASKET_REMOVE_OUTLINE' = 'basket-remove-outline',
  'SHOPPING_BASKET_REMOVE_OUTLINE' = 'shopping-basket-remove-outline',
  'SKIP_REMOVE_OUTLINE' = 'skip-remove-outline',
  'BASKET_UNFILL' = 'basket-unfill',
  'BASKETBALL' = 'basketball',
  'YOUTUBE_SPORTS' = 'youtube-sports',
  'BASKETBALL_HOOP' = 'basketball-hoop',
  'BASKETBALL_HOOP_OUTLINE' = 'basketball-hoop-outline',
  'BAT' = 'bat',
  'BATHTUB' = 'bathtub',
  'BATHTUB_OUTLINE' = 'bathtub-outline',
  'BATTERY' = 'battery',
  'BATTERY_FULL' = 'battery-full',
  'BATTERY_STD' = 'battery-std',
  'BATTERY_100' = 'battery-100',
  'BATTERY10' = 'battery10',
  'BATTERY10BLUETOOTH' = 'battery10bluetooth',
  'BATTERY20' = 'battery20',
  'BATTERY20BLUETOOTH' = 'battery20bluetooth',
  'BATTERY30' = 'battery30',
  'BATTERY30BLUETOOTH' = 'battery30bluetooth',
  'BATTERY40' = 'battery40',
  'BATTERY40BLUETOOTH' = 'battery40bluetooth',
  'BATTERY50' = 'battery50',
  'BATTERY50BLUETOOTH' = 'battery50bluetooth',
  'BATTERY60' = 'battery60',
  'BATTERY60BLUETOOTH' = 'battery60bluetooth',
  'BATTERY70' = 'battery70',
  'BATTERY70BLUETOOTH' = 'battery70bluetooth',
  'BATTERY80' = 'battery80',
  'BATTERY80BLUETOOTH' = 'battery80bluetooth',
  'BATTERY90' = 'battery90',
  'BATTERY90BLUETOOTH' = 'battery90bluetooth',
  'BATTERY_ALERT' = 'battery-alert',
  'BATTERY_WARNING' = 'battery-warning',
  'BATTERY_ALERT_BLUETOOTH' = 'battery-alert-bluetooth',
  'BATTERY_WARNING_BLUETOOTH' = 'battery-warning-bluetooth',
  'BATTERY_ALERT_VARIANT' = 'battery-alert-variant',
  'BATTERY_ALERT_VARIANT_OUTLINE' = 'battery-alert-variant-outline',
  'BATTERY_ARROW_DOWN' = 'battery-arrow-down',
  'BATTERY_ARROW_DOWN_OUTLINE' = 'battery-arrow-down-outline',
  'BATTERY_ARROW_UP' = 'battery-arrow-up',
  'BATTERY_ARROW_UP_OUTLINE' = 'battery-arrow-up-outline',
  'BATTERY_BLUETOOTH' = 'battery-bluetooth',
  'BATTERY_BLUETOOTH_100' = 'battery-bluetooth-100',
  'BATTERY_BLUETOOTH_FULL' = 'battery-bluetooth-full',
  'BATTERY_BLUETOOTH_VARIANT' = 'battery-bluetooth-variant',
  'BATTERY_CHARGING' = 'battery-charging',
  'BATTERY_CHARGING_FULL' = 'battery-charging-full',
  'BATTERY_CHARGING10' = 'battery-charging10',
  'BATTERY_CHARGING100' = 'battery-charging100',
  'BATTERY_CHARGING20' = 'battery-charging20',
  'BATTERY_CHARGING30' = 'battery-charging30',
  'BATTERY_CHARGING40' = 'battery-charging40',
  'BATTERY_CHARGING50' = 'battery-charging50',
  'BATTERY_CHARGING60' = 'battery-charging60',
  'BATTERY_CHARGING70' = 'battery-charging70',
  'BATTERY_CHARGING80' = 'battery-charging80',
  'BATTERY_CHARGING90' = 'battery-charging90',
  'BATTERY_CHARGING_HIGH' = 'battery-charging-high',
  'BATTERY_CHARGING_LOW' = 'battery-charging-low',
  'BATTERY_CHARGING_MEDIUM' = 'battery-charging-medium',
  'BATTERY_CHARGING_OUTLINE' = 'battery-charging-outline',
  'BATTERY_CHARGING_WIRELESS' = 'battery-charging-wireless',
  'BATTERY_CHARGING_WIRELESS_FULL' = 'battery-charging-wireless-full',
  'BATTERY_CHARGING_WIRELESS_100' = 'battery-charging-wireless-100',
  'BATTERY_CHARGING_WIRELESS10' = 'battery-charging-wireless10',
  'BATTERY_CHARGING_WIRELESS20' = 'battery-charging-wireless20',
  'BATTERY_CHARGING_WIRELESS30' = 'battery-charging-wireless30',
  'BATTERY_CHARGING_WIRELESS40' = 'battery-charging-wireless40',
  'BATTERY_CHARGING_WIRELESS50' = 'battery-charging-wireless50',
  'BATTERY_CHARGING_WIRELESS60' = 'battery-charging-wireless60',
  'BATTERY_CHARGING_WIRELESS70' = 'battery-charging-wireless70',
  'BATTERY_CHARGING_WIRELESS80' = 'battery-charging-wireless80',
  'BATTERY_CHARGING_WIRELESS90' = 'battery-charging-wireless90',
  'BATTERY_CHARGING_WIRELESS_ALERT' = 'battery-charging-wireless-alert',
  'BATTERY_CHARGING_WIRELESS_WARNING' = 'battery-charging-wireless-warning',
  'BATTERY_CHARGING_WIRELESS_OUTLINE' = 'battery-charging-wireless-outline',
  'BATTERY_CHARGING_WIRELESS_EMPTY' = 'battery-charging-wireless-empty',
  'BATTERY_CHARGING_WIRELESS_0' = 'battery-charging-wireless-0',
  'BATTERY_CHECK' = 'battery-check',
  'BATTERY_CHECK_OUTLINE' = 'battery-check-outline',
  'BATTERY_CLOCK' = 'battery-clock',
  'BATTERY_FULL_CLOCK' = 'battery-full-clock',
  'BATTERY_100_CLOCK' = 'battery-100-clock',
  'BATTERY_CLOCK_OUTLINE' = 'battery-clock-outline',
  'BATTER_0_CLOCK' = 'batter-0-clock',
  'BATTERY_EMPTY_CLOCK' = 'battery-empty-clock',
  'BATTERY_HEART' = 'battery-heart',
  'BATTERY_HEART_OUTLINE' = 'battery-heart-outline',
  'BATTERY_HEART_VARIANT' = 'battery-heart-variant',
  'BATTERY_HIGH' = 'battery-high',
  'BATTERY_LOCK' = 'battery-lock',
  'BATTERY_LOCK_OPEN' = 'battery-lock-open',
  'BATTERY_LOW' = 'battery-low',
  'BATTERY_MEDIUM' = 'battery-medium',
  'BATTERY_MINUS' = 'battery-minus',
  'BATTERY_MINUS_OUTLINE' = 'battery-minus-outline',
  'BATTERY_MINUS_VARIANT' = 'battery-minus-variant',
  'BATTERY_NEGATIVE' = 'battery-negative',
  'BATTERY_OFF' = 'battery-off',
  'BATTERY_OFF_OUTLINE' = 'battery-off-outline',
  'BATTERY_OUTLINE' = 'battery-outline',
  'BATTERY_0' = 'battery-0',
  'BATTERY_EMPTY' = 'battery-empty',
  'BATTERY_PLUS' = 'battery-plus',
  'BATTERY_PLUS_OUTLINE' = 'battery-plus-outline',
  'BATTERY_PLUS_VARIANT' = 'battery-plus-variant',
  'BATTERY_SAVER' = 'battery-saver',
  'BATTERY_ADD' = 'battery-add',
  'BATTERY_POSITIVE' = 'battery-positive',
  'BATTERY_REMOVE' = 'battery-remove',
  'BATTERY_REMOVE_OUTLINE' = 'battery-remove-outline',
  'BATTERY_SYNC' = 'battery-sync',
  'BATTERY_RECYCLE' = 'battery-recycle',
  'BATTERY_ECO' = 'battery-eco',
  'BATTERY_SYNC_OUTLINE' = 'battery-sync-outline',
  'BATTERY_SAVER_OUTLINE' = 'battery-saver-outline',
  'BATTERY_ECO_OUTLINE' = 'battery-eco-outline',
  'BATTERY_RECYCLE_OUTLINE' = 'battery-recycle-outline',
  'BATTERY_UNKNOWN' = 'battery-unknown',
  'BATTERY_UNKNOWN_BLUETOOTH' = 'battery-unknown-bluetooth',
  'BEACH' = 'beach',
  'PARASOL' = 'parasol',
  'BEAKER' = 'beaker',
  'BEAKER_ALERT' = 'beaker-alert',
  'BEAKER_ALERT_OUTLINE' = 'beaker-alert-outline',
  'BEAKER_CHECK' = 'beaker-check',
  'BEAKER_CHECK_OUTLINE' = 'beaker-check-outline',
  'BEAKER_MINUS' = 'beaker-minus',
  'BEAKER_MINUS_OUTLINE' = 'beaker-minus-outline',
  'BEAKER_OUTLINE' = 'beaker-outline',
  'BEAKER_PLUS' = 'beaker-plus',
  'BEAKER_PLUS_OUTLINE' = 'beaker-plus-outline',
  'BEAKER_QUESTION' = 'beaker-question',
  'BEAKER_QUESTION_OUTLINE' = 'beaker-question-outline',
  'BEAKER_REMOVE' = 'beaker-remove',
  'BEAKER_REMOVE_OUTLINE' = 'beaker-remove-outline',
  'BED' = 'bed',
  'HOTEL' = 'hotel',
  'GUEST_ROOM' = 'guest-room',
  'BED_DOUBLE' = 'bed-double',
  'BEDROOM' = 'bedroom',
  'BED_DOUBLE_OUTLINE' = 'bed-double-outline',
  'BEDROOM_OUTLINE' = 'bedroom-outline',
  'BED_EMPTY' = 'bed-empty',
  'BED_KING' = 'bed-king',
  'BED_KING_OUTLINE' = 'bed-king-outline',
  'BED_OUTLINE' = 'bed-outline',
  'HOTEL_OUTLINE' = 'hotel-outline',
  'GUEST_ROOM_OUTLINE' = 'guest-room-outline',
  'BED_QUEEN' = 'bed-queen',
  'BED_QUEEN_OUTLINE' = 'bed-queen-outline',
  'BED_SINGLE' = 'bed-single',
  'BED_SINGLE_OUTLINE' = 'bed-single-outline',
  'BEE' = 'bee',
  'FLY' = 'fly',
  'INSECT' = 'insect',
  'BEE_FLOWER' = 'bee-flower',
  'FLY_FLOWER' = 'fly-flower',
  'NATURE' = 'nature',
  'BEEHIVE_OFF_OUTLINE' = 'beehive-off-outline',
  'BEEHIVE_OUTLINE' = 'beehive-outline',
  'HONEY_OUTLINE' = 'honey-outline',
  'BEEKEEPER' = 'beekeeper',
  'APIARISTS' = 'apiarists',
  'APICULTURISTS' = 'apiculturists',
  'HONEY_FARMER' = 'honey-farmer',
  'BEER' = 'beer',
  'PINT' = 'pint',
  'PUB' = 'pub',
  'BAR' = 'bar',
  'DRINK' = 'drink',
  'CUP_FULL' = 'cup-full',
  'BEER_OUTLINE' = 'beer-outline',
  'DRINK_OUTLINE' = 'drink-outline',
  'CUP_FULL_OUTLINE' = 'cup-full-outline',
  'PINT_OUTLINE' = 'pint-outline',
  'PUB_OUTLINE' = 'pub-outline',
  'BAR_OUTLINE' = 'bar-outline',
  'BELL' = 'bell',
  'NOTIFICATIONS' = 'notifications',
  'BELL_ALERT' = 'bell-alert',
  'BELL_WARNING' = 'bell-warning',
  'BELL_ALERT_OUTLINE' = 'bell-alert-outline',
  'BELL_BADGE' = 'bell-badge',
  'BELL_NOTIFICATION' = 'bell-notification',
  'BELL_BADGE_OUTLINE' = 'bell-badge-outline',
  'BELL_NOTIFICATION_OUTLINE' = 'bell-notification-outline',
  'BELL_CANCEL' = 'bell-cancel',
  'BELL_CANCEL_OUTLINE' = 'bell-cancel-outline',
  'BELL_CHECK' = 'bell-check',
  'BELL_CHECK_OUTLINE' = 'bell-check-outline',
  'BELL_CIRCLE' = 'bell-circle',
  'BELL_CIRCLE_OUTLINE' = 'bell-circle-outline',
  'BELL_MINUS' = 'bell-minus',
  'BELL_MINUS_OUTLINE' = 'bell-minus-outline',
  'BELL_OFF' = 'bell-off',
  'NOTIFICATIONS_OFF' = 'notifications-off',
  'BELL_OFF_OUTLINE' = 'bell-off-outline',
  'BELL_OUTLINE' = 'bell-outline',
  'NOTIFICATIONS_NONE' = 'notifications-none',
  'BELL_PLUS' = 'bell-plus',
  'ADD_ALERT' = 'add-alert',
  'BELL_ADD' = 'bell-add',
  'BELL_PLUS_OUTLINE' = 'bell-plus-outline',
  'BELL_ADD_OUTLINE' = 'bell-add-outline',
  'ADD_ALERT_OUTLINE' = 'add-alert-outline',
  'BELL_REMOVE' = 'bell-remove',
  'BELL_REMOVE_OUTLINE' = 'bell-remove-outline',
  'BELL_RING' = 'bell-ring',
  'NOTIFICATIONS_ACTIVE' = 'notifications-active',
  'BELL_RING_OUTLINE' = 'bell-ring-outline',
  'BELL_SLEEP' = 'bell-sleep',
  'NOTIFICATIONS_PAUSED' = 'notifications-paused',
  'BELL_SLEEP_OUTLINE' = 'bell-sleep-outline',
  'BETA' = 'beta',
  'BETAMAX' = 'betamax',
  'BIATHLON' = 'biathlon',
  'HUMAN_BIATHLON' = 'human-biathlon',
  'BICYCLE' = 'bicycle',
  'BIKE' = 'bike',
  'CYCLING' = 'cycling',
  'BICYCLE_BASKET' = 'bicycle-basket',
  'BIKE_BASKET' = 'bike-basket',
  'BICYCLE_CARGO' = 'bicycle-cargo',
  'BIKE_CARGO' = 'bike-cargo',
  'BICYCLE_ELECTRIC' = 'bicycle-electric',
  'BIKE_ELECTRIC' = 'bike-electric',
  'BICYCLE_PENNY_FARTHING' = 'bicycle-penny-farthing',
  'BICYCLE_HIGH_WHEEL' = 'bicycle-high-wheel',
  'BICYCLE_ANTIQUE' = 'bicycle-antique',
  'DIRECTIONS_BIKE' = 'directions-bike',
  'BIKE_FAST' = 'bike-fast',
  'VELOCITY' = 'velocity',
  'BILLBOARD' = 'billboard',
  'BILLIARDS' = 'billiards',
  'POOL' = 'pool',
  'EIGHT_BALL' = 'eight-ball',
  'BILLIARDS_RACK' = 'billiards-rack',
  'POOL_TABLE' = 'pool-table',
  'POOL_RACK' = 'pool-rack',
  'SNOOKER_RACK' = 'snooker-rack',
  'POOL_TRIANGLE' = 'pool-triangle',
  'BILLIARDS_TRIANGLE' = 'billiards-triangle',
  'SNOOKER_TRIANGLE' = 'snooker-triangle',
  'BINOCULARS' = 'binoculars',
  'BIO' = 'bio',
  'BIOHAZARD' = 'biohazard',
  'BIRD' = 'bird',
  'BITBUCKET' = 'bitbucket',
  'BITCOIN' = 'bitcoin',
  'BLACK_MESA' = 'black-mesa',
  'BLENDER' = 'blender',
  'FOOD_PROCESSOR' = 'food-processor',
  'BLENDER_OUTLINE' = 'blender-outline',
  'FOOD_PROCESSOR_OUTLINE' = 'food-processor-outline',
  'BLENDER_SOFTWARE' = 'blender-software',
  'BLINDS' = 'blinds',
  'ROLLER_SHADE_CLOSED' = 'roller-shade-closed',
  'WINDOW_CLOSED' = 'window-closed',
  'BLINDS_OPEN' = 'blinds-open',
  'ROLLER_SHADE_OPEN' = 'roller-shade-open',
  'WINDOW_OPEN' = 'window-open',
  'BLOCK_HELPER' = 'block-helper',
  'BLOOD_BAG' = 'blood-bag',
  'BLUETOOTH' = 'bluetooth',
  'BLUETOOTH_AUDIO' = 'bluetooth-audio',
  'BLUETOOTH_SEARCHING' = 'bluetooth-searching',
  'BLUETOOTH_CONNECT' = 'bluetooth-connect',
  'BLUETOOTH_CONNECTED' = 'bluetooth-connected',
  'BLUETOOTH_OFF' = 'bluetooth-off',
  'BLUETOOTH_DISABLED' = 'bluetooth-disabled',
  'BLUETOOTH_SETTINGS' = 'bluetooth-settings',
  'SETTINGS_BLUETOOTH' = 'settings-bluetooth',
  'BLUETOOTH_TRANSFER' = 'bluetooth-transfer',
  'BLUR' = 'blur',
  'BLUR_ON' = 'blur-on',
  'BLUR_LINEAR' = 'blur-linear',
  'BLUR_OFF' = 'blur-off',
  'BLUR_RADIAL' = 'blur-radial',
  'BLUR_CIRCULAR' = 'blur-circular',
  'BOLT' = 'bolt',
  'BOMB' = 'bomb',
  'BOMB_OFF' = 'bomb-off',
  'BONE' = 'bone',
  'BONE_OFF' = 'bone-off',
  'BOOK' = 'book',
  'GIT_REPOSITORY' = 'git-repository',
  'BOOK_ACCOUNT' = 'book-account',
  'BOOK_ACCOUNT_OUTLINE' = 'book-account-outline',
  'BOOK_ALERT' = 'book-alert',
  'BOOK_ALERT_OUTLINE' = 'book-alert-outline',
  'BOOK_ALPHABET' = 'book-alphabet',
  'DICTIONARY' = 'dictionary',
  'BOOK_ARROW_DOWN' = 'book-arrow-down',
  'BOOK_ARROW_DOWN_OUTLINE' = 'book-arrow-down-outline',
  'BOOK_ARROW_LEFT' = 'book-arrow-left',
  'BOOK_ARROW_LEFT_OUTLINE' = 'book-arrow-left-outline',
  'BOOK_ARROW_RIGHT' = 'book-arrow-right',
  'BOOK_ARROW_RIGHT_OUTLINE' = 'book-arrow-right-outline',
  'BOOK_ARROW_UP' = 'book-arrow-up',
  'BOOK_ARROW_UP_OUTLINE' = 'book-arrow-up-outline',
  'BOOK_CANCEL' = 'book-cancel',
  'BOOK_CANCEL_OUTLINE' = 'book-cancel-outline',
  'BOOK_CHECK' = 'book-check',
  'BOOK_CHECK_OUTLINE' = 'book-check-outline',
  'BOOK_CLOCK' = 'book-clock',
  'BOOK_SCHEDULE' = 'book-schedule',
  'BOOK_TIME' = 'book-time',
  'BOOK_CLOCK_OUTLINE' = 'book-clock-outline',
  'BOOK_COG' = 'book-cog',
  'BOOK_SETTINGS' = 'book-settings',
  'BOOK_COG_OUTLINE' = 'book-cog-outline',
  'BOOK_SETTINGS_OUTLINE' = 'book-settings-outline',
  'BOOK_CROSS' = 'book-cross',
  'BIBLE' = 'bible',
  'BOOK_EDIT' = 'book-edit',
  'BOOK_EDIT_OUTLINE' = 'book-edit-outline',
  'BOOK_EDUCATION' = 'book-education',
  'BOOK_EDUCATION_OUTLINE' = 'book-education-outline',
  'BOOK_HEART' = 'book-heart',
  'BOOK_FAVORITE' = 'book-favorite',
  'BOOK_LOVE' = 'book-love',
  'BOOK_HEART_OUTLINE' = 'book-heart-outline',
  'BOOK_FAVORITE_OUTLINE' = 'book-favorite-outline',
  'BOOK_LOVE_OUTLINE' = 'book-love-outline',
  'BOOK_INFORMATION_VARIANT' = 'book-information-variant',
  'ENCYCLOPEDIA' = 'encyclopedia',
  'BOOK_LOCK' = 'book-lock',
  'BOOK_SECURE' = 'book-secure',
  'BOOK_LOCK_OPEN' = 'book-lock-open',
  'BOOK_UNSECURE' = 'book-unsecure',
  'BOOK_LOCK_OPEN_OUTLINE' = 'book-lock-open-outline',
  'BOOK_LOCK_OUTLINE' = 'book-lock-outline',
  'BOOK_SECURE_OUTLINE' = 'book-secure-outline',
  'BOOK_MARKER' = 'book-marker',
  'BOOK_LOCATION' = 'book-location',
  'BOOK_MARKER_OUTLINE' = 'book-marker-outline',
  'BOOK_LOCATION_OUTLINE' = 'book-location-outline',
  'BOOK_MINUS' = 'book-minus',
  'BOOK_MINUS_MULTIPLE' = 'book-minus-multiple',
  'BOOKS_MINUS' = 'books-minus',
  'BOOK_MINUS_MULTIPLE_OUTLINE' = 'book-minus-multiple-outline',
  'BOOK_MINUS_OUTLINE' = 'book-minus-outline',
  'BOOK_MULTIPLE' = 'book-multiple',
  'BOOKS' = 'books',
  'BOOK_MULTIPLE_OUTLINE' = 'book-multiple-outline',
  'BOOK_MUSIC' = 'book-music',
  'AUDIO_BOOK' = 'audio-book',
  'BOOK_MUSIC_OUTLINE' = 'book-music-outline',
  'BOOK_OFF' = 'book-off',
  'BOOK_OFF_OUTLINE' = 'book-off-outline',
  'BOOK_OPEN' = 'book-open',
  'CHROME_READER_MODE' = 'chrome-reader-mode',
  'BOOK_OPEN_BLANK_VARIANT' = 'book-open-blank-variant',
  'IMPORT_CONTACTS' = 'import-contacts',
  'BOOK_OPEN_OUTLINE' = 'book-open-outline',
  'BOOK_OPEN_PAGE_VARIANT' = 'book-open-page-variant',
  'AUTO_STORIES' = 'auto-stories',
  'BOOK_OPEN_PAGE_VARIANT_OUTLINE' = 'book-open-page-variant-outline',
  'BOOK_OPEN_VARIANT' = 'book-open-variant',
  'BOOK_OUTLINE' = 'book-outline',
  'BOOK_PLAY' = 'book-play',
  'BOOK_PLAY_OUTLINE' = 'book-play-outline',
  'BOOK_PLUS' = 'book-plus',
  'BOOK_ADD' = 'book-add',
  'BOOK_PLUS_MULTIPLE' = 'book-plus-multiple',
  'BOOKS_PLUS' = 'books-plus',
  'BOOK_MULTIPLE_ADD' = 'book-multiple-add',
  'BOOKS_ADD' = 'books-add',
  'BOOK_PLUS_MULTIPLE_OUTLINE' = 'book-plus-multiple-outline',
  'BOOK_PLUS_OUTLINE' = 'book-plus-outline',
  'BOOK_REFRESH' = 'book-refresh',
  'BOOK_REFRESH_OUTLINE' = 'book-refresh-outline',
  'BOOK_REMOVE' = 'book-remove',
  'BOOK_REMOVE_MULTIPLE' = 'book-remove-multiple',
  'BOOKS_REMOVE' = 'books-remove',
  'BOOK_REMOVE_MULTIPLE_OUTLINE' = 'book-remove-multiple-outline',
  'BOOK_REMOVE_OUTLINE' = 'book-remove-outline',
  'BOOK_SEARCH' = 'book-search',
  'BOOK_SEARCH_OUTLINE' = 'book-search-outline',
  'BOOK_SYNC' = 'book-sync',
  'BOOK_SYNC_OUTLINE' = 'book-sync-outline',
  'BOOK_VARIANT' = 'book-variant',
  'CLASS' = 'class',
  'BOOK_VARIANT_MULTIPLE' = 'book-variant-multiple',
  'BOOKS_VARIANT' = 'books-variant',
  'BOOKMARK' = 'bookmark',
  'TURNED_IN' = 'turned-in',
  'BOOKMARK_BOX_MULTIPLE' = 'bookmark-box-multiple',
  'COLLECTIONS_BOOKMARK' = 'collections-bookmark',
  'LIBRARY_BOOKMARK' = 'library-bookmark',
  'BOOKMARK_BOX_MULTIPLE_OUTLINE' = 'bookmark-box-multiple-outline',
  'COLLECTIONS_BOOKMARK_OUTLINE' = 'collections-bookmark-outline',
  'LIBRARY_BOOKMARK_OUTLINE' = 'library-bookmark-outline',
  'BOOKMARK_CHECK' = 'bookmark-check',
  'BOOKMARK_TICK' = 'bookmark-tick',
  'BOOKMARK_CHECK_OUTLINE' = 'bookmark-check-outline',
  'BOOKMARK_MINUS' = 'bookmark-minus',
  'BOOKMARK_MINUS_OUTLINE' = 'bookmark-minus-outline',
  'BOOKMARK_MULTIPLE' = 'bookmark-multiple',
  'BOOKMARK_MULTIPLE_OUTLINE' = 'bookmark-multiple-outline',
  'BOOKMARK_MUSIC' = 'bookmark-music',
  'BOOKMARK_MUSIC_OUTLINE' = 'bookmark-music-outline',
  'BOOKMARK_OFF' = 'bookmark-off',
  'BOOKMARK_OFF_OUTLINE' = 'bookmark-off-outline',
  'BOOKMARK_OUTLINE' = 'bookmark-outline',
  'BOOKMARK_BORDER' = 'bookmark-border',
  'TURNED_IN_NOT' = 'turned-in-not',
  'BOOKMARK_PLUS' = 'bookmark-plus',
  'BOOKMARK_ADD' = 'bookmark-add',
  'BOOKMARK_PLUS_OUTLINE' = 'bookmark-plus-outline',
  'BOOKMARK_ADD_OUTLINE' = 'bookmark-add-outline',
  'BOOKMARK_REMOVE' = 'bookmark-remove',
  'BOOKMARK_REMOVE_OUTLINE' = 'bookmark-remove-outline',
  'BOOKSHELF' = 'bookshelf',
  'BOOM_GATE' = 'boom-gate',
  'BOOM_ARM' = 'boom-arm',
  'BOOM_BARRIER' = 'boom-barrier',
  'ARM_BARRIER' = 'arm-barrier',
  'BARRIER' = 'barrier',
  'AUTOMATIC_GATE' = 'automatic-gate',
  'BOOM_GATE_ALERT' = 'boom-gate-alert',
  'BOOM_ARM_ALERT' = 'boom-arm-alert',
  'BOOM_BARRIER_ALERT' = 'boom-barrier-alert',
  'ARM_BARRIER_ALERT' = 'arm-barrier-alert',
  'BARRIER_ALERT' = 'barrier-alert',
  'AUTOMATIC_GATE_ALERT' = 'automatic-gate-alert',
  'BOOM_GATE_ALERT_OUTLINE' = 'boom-gate-alert-outline',
  'BOOM_ARM_ALERT_OUTLINE' = 'boom-arm-alert-outline',
  'BOOM_BARRIER_ALERT_OUTLINE' = 'boom-barrier-alert-outline',
  'ARM_BARRIER_ALERT_OUTLINE' = 'arm-barrier-alert-outline',
  'BARRIER_ALERT_OUTLINE' = 'barrier-alert-outline',
  'AUTOMATIC_GATE_ALERT_OUTLINE' = 'automatic-gate-alert-outline',
  'BOOM_GATE_ARROW_DOWN' = 'boom-gate-arrow-down',
  'BOOM_ARM_DOWN' = 'boom-arm-down',
  'BOOM_BARRIER_DOWN' = 'boom-barrier-down',
  'ARM_BARRIER_DOWN' = 'arm-barrier-down',
  'BARRIER_DOWN' = 'barrier-down',
  'AUTOMATIC_GATE_DOWN' = 'automatic-gate-down',
  'BOOM_GATE_ARROW_DOWN_OUTLINE' = 'boom-gate-arrow-down-outline',
  'BOOM_ARM_DOWN_OUTLINE' = 'boom-arm-down-outline',
  'BOOM_BARRIER_DOWN_OUTLINE' = 'boom-barrier-down-outline',
  'ARM_BARRIER_DOWN_OUTLINE' = 'arm-barrier-down-outline',
  'BARRIER_DOWN_OUTLINE' = 'barrier-down-outline',
  'AUTOMATIC_GATE_DOWN_OUTLINE' = 'automatic-gate-down-outline',
  'BOOM_GATE_ARROW_UP' = 'boom-gate-arrow-up',
  'BOOM_ARM_UP' = 'boom-arm-up',
  'BOOM_BARRIER_UP' = 'boom-barrier-up',
  'ARM_BARRIER_UP' = 'arm-barrier-up',
  'BARRIER_UP' = 'barrier-up',
  'AUTOMATIC_GATE_UP' = 'automatic-gate-up',
  'BOOM_GATE_ARROW_UP_OUTLINE' = 'boom-gate-arrow-up-outline',
  'BOOM_ARM_UP_OUTLINE' = 'boom-arm-up-outline',
  'BOOM_BARRIER_UP_OUTLINE' = 'boom-barrier-up-outline',
  'ARM_BARRIER_UP_OUTLINE' = 'arm-barrier-up-outline',
  'BARRIER_UP_OUTLINE' = 'barrier-up-outline',
  'AUTOMATIC_GATE_UP_OUTLINE' = 'automatic-gate-up-outline',
  'BOOM_GATE_OUTLINE' = 'boom-gate-outline',
  'BOOM_ARM_OUTLINE' = 'boom-arm-outline',
  'BOOM_BARRIER_OUTLINE' = 'boom-barrier-outline',
  'ARM_BARRIER_OUTLINE' = 'arm-barrier-outline',
  'BARRIER_OUTLINE' = 'barrier-outline',
  'AUTOMATIC_GATE_OUTLINE' = 'automatic-gate-outline',
  'BOOM_GATE_UP' = 'boom-gate-up',
  'BOOM_GATE_UP_OUTLINE' = 'boom-gate-up-outline',
  'BOOMBOX' = 'boombox',
  'BOOMERANG' = 'boomerang',
  'BOOTSTRAP' = 'bootstrap',
  'BORDER_ALL' = 'border-all',
  'BORDER_ALL_VARIANT' = 'border-all-variant',
  'BORDER_BOTTOM' = 'border-bottom',
  'BORDER_BOTTOM_VARIANT' = 'border-bottom-variant',
  'BORDER_COLOR' = 'border-color',
  'BORDER_COLOUR' = 'border-colour',
  'BORDER_HORIZONTAL' = 'border-horizontal',
  'BORDER_INSIDE' = 'border-inside',
  'BORDER_LEFT' = 'border-left',
  'BORDER_LEFT_VARIANT' = 'border-left-variant',
  'BORDER_NONE' = 'border-none',
  'BORDER_CLEAR' = 'border-clear',
  'BORDER_NONE_VARIANT' = 'border-none-variant',
  'BORDER_OUTSIDE' = 'border-outside',
  'BORDER_OUTER' = 'border-outer',
  'BORDER_RIGHT' = 'border-right',
  'BORDER_RIGHT_VARIANT' = 'border-right-variant',
  'BORDER_STYLE' = 'border-style',
  'BORDER_TOP' = 'border-top',
  'BORDER_TOP_VARIANT' = 'border-top-variant',
  'BORDER_VERTICAL' = 'border-vertical',
  'BOTTLE_SODA' = 'bottle-soda',
  'BOTTLE_COKE' = 'bottle-coke',
  'BOTTLE_POP' = 'bottle-pop',
  'BOTTLE_SODA_CLASSIC' = 'bottle-soda-classic',
  'BOTTLE_COKE_CLASSIC' = 'bottle-coke-classic',
  'BOTTLE_POP_CLASSIC' = 'bottle-pop-classic',
  'BOTTLE_SODA_CLASSIC_OUTLINE' = 'bottle-soda-classic-outline',
  'BOTTLE_SODA_OUTLINE' = 'bottle-soda-outline',
  'BOTTLE_COKE_OUTLINE' = 'bottle-coke-outline',
  'BOTTLE_POP_OUTLINE' = 'bottle-pop-outline',
  'BOTTLE_TONIC' = 'bottle-tonic',
  'FLASK' = 'flask',
  'BOTTLE_TONIC_OUTLINE' = 'bottle-tonic-outline',
  'FLASK_OUTLINE' = 'flask-outline',
  'BOTTLE_TONIC_PLUS' = 'bottle-tonic-plus',
  'HEALTH_POTION' = 'health-potion',
  'BOTTLE_TONIC_PLUS_OUTLINE' = 'bottle-tonic-plus-outline',
  'HEALTH_POTION_OUTLINE' = 'health-potion-outline',
  'BOTTLE_TONIC_SKULL' = 'bottle-tonic-skull',
  'POISON' = 'poison',
  'MOONSHINE' = 'moonshine',
  'BOTTLE_TONIC_SKULL_OUTLINE' = 'bottle-tonic-skull-outline',
  'POISON_OUTLINE' = 'poison-outline',
  'MOONSHINE_OUTLINE' = 'moonshine-outline',
  'BOTTLE_WINE' = 'bottle-wine',
  'BOTTLE_WINE_OUTLINE' = 'bottle-wine-outline',
  'BOW_ARROW' = 'bow-arrow',
  'BOW_TIE' = 'bow-tie',
  'BOWL' = 'bowl',
  'BOWL_MIX' = 'bowl-mix',
  'MIXING_BOWL' = 'mixing-bowl',
  'BOWL_MIX_OUTLINE' = 'bowl-mix-outline',
  'MIXING_BOWL_OUTLINE' = 'mixing-bowl-outline',
  'BOWL_OUTLINE' = 'bowl-outline',
  'BOWLING' = 'bowling',
  'BOX_CUTTER' = 'box-cutter',
  'STANLEY_KNIFE' = 'stanley-knife',
  'BOX_CUTTER_OFF' = 'box-cutter-off',
  'BOX_SHADOW' = 'box-shadow',
  'BOXING_GLOVE' = 'boxing-glove',
  'BRAILLE' = 'braille',
  'BRAIN' = 'brain',
  'BREAD_SLICE' = 'bread-slice',
  'BREAD_SLICE_OUTLINE' = 'bread-slice-outline',
  'BRIDGE' = 'bridge',
  'BRIEFCASE' = 'briefcase',
  'WORK' = 'work',
  'BRIEFCASE_ACCOUNT' = 'briefcase-account',
  'BRIEFCASE_PERSON' = 'briefcase-person',
  'BRIEFCASE_USER' = 'briefcase-user',
  'BRIEFCASE_ACCOUNT_OUTLINE' = 'briefcase-account-outline',
  'BRIEFCASE_PERSON_OUTLINE' = 'briefcase-person-outline',
  'BRIEFCASE_USER_OUTLINE' = 'briefcase-user-outline',
  'BRIEFCASE_CHECK' = 'briefcase-check',
  'BRIEFCASE_TICK' = 'briefcase-tick',
  'BRIEFCASE_CHECK_OUTLINE' = 'briefcase-check-outline',
  'BRIEFCASE_CLOCK' = 'briefcase-clock',
  'BRIEFCASE_CLOCK_OUTLINE' = 'briefcase-clock-outline',
  'BRIEFCASE_DOWNLOAD' = 'briefcase-download',
  'BRIEFCASE_DOWNLOAD_OUTLINE' = 'briefcase-download-outline',
  'BRIEFCASE_EDIT' = 'briefcase-edit',
  'BRIEFCASE_EDIT_OUTLINE' = 'briefcase-edit-outline',
  'BRIEFCASE_EYE' = 'briefcase-eye',
  'BRIEFCASE_VIEW' = 'briefcase-view',
  'BRIEFCASE_EYE_OUTLINE' = 'briefcase-eye-outline',
  'BRIEFCASE_VIEW_OUTLINE' = 'briefcase-view-outline',
  'BRIEFCASE_MINUS' = 'briefcase-minus',
  'BRIEFCASE_MINUS_OUTLINE' = 'briefcase-minus-outline',
  'BRIEFCASE_OFF' = 'briefcase-off',
  'BRIEFCASE_OFF_OUTLINE' = 'briefcase-off-outline',
  'BRIEFCASE_OUTLINE' = 'briefcase-outline',
  'WORK_OUTLINE' = 'work-outline',
  'BRIEFCASE_PLUS' = 'briefcase-plus',
  'BRIEFCASE_ADD' = 'briefcase-add',
  'BRIEFCASE_PLUS_OUTLINE' = 'briefcase-plus-outline',
  'BRIEFCASE_ADD_OUTLINE' = 'briefcase-add-outline',
  'BRIEFCASE_REMOVE' = 'briefcase-remove',
  'BRIEFCASE_REMOVE_OUTLINE' = 'briefcase-remove-outline',
  'BRIEFCASE_SEARCH' = 'briefcase-search',
  'BRIEFCASE_SEARCH_OUTLINE' = 'briefcase-search-outline',
  'BRIEFCASE_UPLOAD' = 'briefcase-upload',
  'BRIEFCASE_UPLOAD_OUTLINE' = 'briefcase-upload-outline',
  'BRIEFCASE_VARIANT' = 'briefcase-variant',
  'BRIEFCASE_VARIANT_OFF' = 'briefcase-variant-off',
  'BRIEFCASE_VARIANT_OFF_OUTLINE' = 'briefcase-variant-off-outline',
  'BRIEFCASE_VARIANT_OUTLINE' = 'briefcase-variant-outline',
  'BRIGHTNESS1' = 'brightness1',
  'BRIGHTNESS2' = 'brightness2',
  'BRIGHTNESS3' = 'brightness3',
  'BRIGHTNESS4' = 'brightness4',
  'BRIGHTNESS5' = 'brightness5',
  'BRIGHTNESS6' = 'brightness6',
  'BRIGHTNESS7' = 'brightness7',
  'BRIGHTNESS_AUTO' = 'brightness-auto',
  'BRIGHTNESS_PERCENT' = 'brightness-percent',
  'DISCOUNT' = 'discount',
  'SALE' = 'sale',
  'BROADCAST' = 'broadcast',
  'SIGNAL' = 'signal',
  'BROADCAST_OFF' = 'broadcast-off',
  'SIGNAL_OFF' = 'signal-off',
  'BROOM' = 'broom',
  'BRUSH' = 'brush',
  'PAINTBRUSH' = 'paintbrush',
  'BRUSH_OFF' = 'brush-off',
  'BRUSH_OUTLINE' = 'brush-outline',
  'PAINTBRUSH_OUTLINE' = 'paintbrush-outline',
  'BRUSH_VARIANT' = 'brush-variant',
  'BUCKET' = 'bucket',
  'BUCKET_OUTLINE' = 'bucket-outline',
  'BUFFET' = 'buffet',
  'SIDEBOARD' = 'sideboard',
  'BUG' = 'bug',
  'BUG_REPORT' = 'bug-report',
  'BUG_CHECK' = 'bug-check',
  'BUG_TICK' = 'bug-tick',
  'BUG_CHECK_OUTLINE' = 'bug-check-outline',
  'BUG_TICK_OUTLINE' = 'bug-tick-outline',
  'BUG_OUTLINE' = 'bug-outline',
  'BUGLE' = 'bugle',
  'CAR_HORN' = 'car-horn',
  'BULLDOZER' = 'bulldozer',
  'BULLET' = 'bullet',
  'BULLETIN_BOARD' = 'bulletin-board',
  'NOTICE_BOARD' = 'notice-board',
  'BULLHORN' = 'bullhorn',
  'ANNOUNCEMENT' = 'announcement',
  'MEGAPHONE' = 'megaphone',
  'LOUDSPEAKER' = 'loudspeaker',
  'BULLHORN_OUTLINE' = 'bullhorn-outline',
  'ANNOUNCEMENT_OUTLINE' = 'announcement-outline',
  'MEGAPHONE_OUTLINE' = 'megaphone-outline',
  'LOUDSPEAKER_OUTLINE' = 'loudspeaker-outline',
  'BULLHORN_VARIANT' = 'bullhorn-variant',
  'BULLHORN_VARIANT_OUTLINE' = 'bullhorn-variant-outline',
  'BULLSEYE' = 'bullseye',
  'TARGET' = 'target',
  'BULLSEYE_ARROW' = 'bullseye-arrow',
  'TARGET_ARROW' = 'target-arrow',
  'BULMA' = 'bulma',
  'BUNK_BED' = 'bunk-bed',
  'BUNK_BED_OUTLINE' = 'bunk-bed-outline',
  'BUS' = 'bus',
  'DIRECTIONS_BUS' = 'directions-bus',
  'BUS_ALERT' = 'bus-alert',
  'BUS_WARNING' = 'bus-warning',
  'BUS_ARTICULATED_END' = 'bus-articulated-end',
  'BUS_ARTICULATED_FRONT' = 'bus-articulated-front',
  'BUS_CLOCK' = 'bus-clock',
  'DEPARTURE_BOARD' = 'departure-board',
  'BUS_DOUBLE_DECKER' = 'bus-double-decker',
  'BUS_ELECTRIC' = 'bus-electric',
  'BUS_MARKER' = 'bus-marker',
  'BUS_LOCATION' = 'bus-location',
  'BUS_STOP' = 'bus-stop',
  'BUS_MULTIPLE' = 'bus-multiple',
  'FLEET' = 'fleet',
  'BUS_SCHOOL' = 'bus-school',
  'EDUCATION' = 'education',
  'BUS_SIDE' = 'bus-side',
  'BUS_STOP_COVERED' = 'bus-stop-covered',
  'BUS_STOP_UNCOVERED' = 'bus-stop-uncovered',
  'BUTTERFLY' = 'butterfly',
  'BUTTERFLY_OUTLINE' = 'butterfly-outline',
  'CABIN_AFRAME' = 'cabin-aframe',
  'CABLE_DATA' = 'cable-data',
  'CACHED' = 'cached',
  'COUNTERCLOCKWISE_ARROWS' = 'counterclockwise-arrows',
  'CACTUS' = 'cactus',
  'CAKE' = 'cake',
  'BIRTHDAY_CAKE' = 'birthday-cake',
  'CAKE_LAYERED' = 'cake-layered',
  'CAKE_VARIANT' = 'cake-variant',
  'CAKE_VARIANT_OUTLINE' = 'cake-variant-outline',
  'BIRTHDAY_CAKE_OUTLINE' = 'birthday-cake-outline',
  'CALCULATOR' = 'calculator',
  'CALCULATOR_VARIANT' = 'calculator-variant',
  'CALCULATOR_VARIANT_OUTLINE' = 'calculator-variant-outline',
  'CALENDAR' = 'calendar',
  'EVENT' = 'event',
  'INSERT_INVITATION' = 'insert-invitation',
  'CALENDAR_ACCOUNT' = 'calendar-account',
  'CALENDAR_ACCOUNT_OUTLINE' = 'calendar-account-outline',
  'CALENDAR_ALERT' = 'calendar-alert',
  'EVENT_ALERT' = 'event-alert',
  'CALENDAR_WARNING' = 'calendar-warning',
  'CALENDAR_ARROW_LEFT' = 'calendar-arrow-left',
  'RESCHEDULE' = 'reschedule',
  'CALENDAR_ARROW_RIGHT' = 'calendar-arrow-right',
  'CALENDAR_BLANK' = 'calendar-blank',
  'CALENDAR_TODAY' = 'calendar-today',
  'CALENDAR_BLANK_MULTIPLE' = 'calendar-blank-multiple',
  'CALENDAR_BLANK_OUTLINE' = 'calendar-blank-outline',
  'EVENT_BLANK_OUTLINE' = 'event-blank-outline',
  'CALENDAR_CHECK' = 'calendar-check',
  'EVENT_AVAILABLE' = 'event-available',
  'CALENDAR_TASK' = 'calendar-task',
  'CALENDAR_TICK' = 'calendar-tick',
  'EVENT_TICK' = 'event-tick',
  'EVENT_CHECK' = 'event-check',
  'CALENDAR_CHECK_OUTLINE' = 'calendar-check-outline',
  'EVENT_AVAILABLE_OUTLINE' = 'event-available-outline',
  'EVENT_CHECK_OUTLINE' = 'event-check-outline',
  'EVENT_TICK_OUTLINE' = 'event-tick-outline',
  'CALENDAR_TASK_OUTLINE' = 'calendar-task-outline',
  'CALENDAR_TICK_OUTLINE' = 'calendar-tick-outline',
  'CALENDAR_CLOCK' = 'calendar-clock',
  'EVENT_CLOCK' = 'event-clock',
  'EVENT_TIME' = 'event-time',
  'CALENDAR_TIME' = 'calendar-time',
  'CALENDAR_CLOCK_OUTLINE' = 'calendar-clock-outline',
  'CALENDAR_COLLAPSE_HORIZONTAL' = 'calendar-collapse-horizontal',
  'CALENDAR_CURSOR' = 'calendar-cursor',
  'CALENDAR_EDIT' = 'calendar-edit',
  'EVENT_EDIT' = 'event-edit',
  'CALENDAR_END' = 'calendar-end',
  'CALENDAR_EXPAND_HORIZONTAL' = 'calendar-expand-horizontal',
  'CALENDAR_EXPORT' = 'calendar-export',
  'CALENDAR_HEART' = 'calendar-heart',
  'EVENT_HEART' = 'event-heart',
  'CALENDAR_IMPORT' = 'calendar-import',
  'CALENDAR_LOCK' = 'calendar-lock',
  'CALENDAR_LOCK_OUTLINE' = 'calendar-lock-outline',
  'CALENDAR_MINUS' = 'calendar-minus',
  'EVENT_MINUS' = 'event-minus',
  'CALENDAR_MONTH' = 'calendar-month',
  'CALENDAR_MONTH_OUTLINE' = 'calendar-month-outline',
  'CALENDAR_MULTIPLE' = 'calendar-multiple',
  'EVENT_MULTIPLE' = 'event-multiple',
  'CALENDARS' = 'calendars',
  'EVENTS' = 'events',
  'CALENDAR_MULTIPLE_CHECK' = 'calendar-multiple-check',
  'EVENT_MULTIPLE_CHECK' = 'event-multiple-check',
  'CALENDAR_MULTIPLE_TICK' = 'calendar-multiple-tick',
  'CALENDARS_CHECK' = 'calendars-check',
  'CALENDARS_TICK' = 'calendars-tick',
  'EVENT_MULTIPLE_TICK' = 'event-multiple-tick',
  'EVENTS_CHECK' = 'events-check',
  'EVENTS_TICK' = 'events-tick',
  'CALENDAR_MULTISELECT' = 'calendar-multiselect',
  'CALENDAR_OUTLINE' = 'calendar-outline',
  'EVENT_OUTLINE' = 'event-outline',
  'CALENDAR_PLUS' = 'calendar-plus',
  'EVENT_PLUS' = 'event-plus',
  'CALENDAR_ADD' = 'calendar-add',
  'EVENT_ADD' = 'event-add',
  'CALENDAR_QUESTION' = 'calendar-question',
  'CALENDAR_RSVP' = 'calendar-rsvp',
  'EVENT_QUESTION' = 'event-question',
  'CALENDAR_RANGE' = 'calendar-range',
  'DATE_RANGE' = 'date-range',
  'CALENDAR_WEEK' = 'calendar-week',
  'EVENT_RANGE' = 'event-range',
  'CALENDAR_RANGE_OUTLINE' = 'calendar-range-outline',
  'EVENT_RANGE_OUTLINE' = 'event-range-outline',
  'CALENDAR_REFRESH' = 'calendar-refresh',
  'CALENDAR_REPEAT' = 'calendar-repeat',
  'CALENDAR_REFRESH_OUTLINE' = 'calendar-refresh-outline',
  'CALENDAR_REPEAT_OUTLINE' = 'calendar-repeat-outline',
  'CALENDAR_REMOVE' = 'calendar-remove',
  'EVENT_BUSY' = 'event-busy',
  'EVENT_REMOVE' = 'event-remove',
  'CALENDAR_REMOVE_OUTLINE' = 'calendar-remove-outline',
  'EVENT_BUSY_OUTLINE' = 'event-busy-outline',
  'EVENT_REMOVE_OUTLINE' = 'event-remove-outline',
  'CALENDAR_SEARCH' = 'calendar-search',
  'EVENT_SEARCH' = 'event-search',
  'CALENDAR_STAR' = 'calendar-star',
  'EVENT_STAR' = 'event-star',
  'CALENDAR_FAVORITE' = 'calendar-favorite',
  'CALENDAR_START' = 'calendar-start',
  'CALENDAR_SYNC' = 'calendar-sync',
  'CALENDAR_SYNC_OUTLINE' = 'calendar-sync-outline',
  'CALENDAR_TEXT' = 'calendar-text',
  'EVENT_NOTE' = 'event-note',
  'EVENT_TEXT' = 'event-text',
  'CALENDAR_TEXT_OUTLINE' = 'calendar-text-outline',
  'EVENT_TEXT_OUTLINE' = 'event-text-outline',
  'EVENT_NOTE_OUTLINE' = 'event-note-outline',
  'CALENDAR_DAY' = 'calendar-day',
  'EVENT_WEEK' = 'event-week',
  'CALENDAR_WEEK_BEGIN' = 'calendar-week-begin',
  'EVENT_WEEK_BEGIN' = 'event-week-begin',
  'CALENDAR_WEEKEND' = 'calendar-weekend',
  'CALENDAR_WEEKEND_OUTLINE' = 'calendar-weekend-outline',
  'CALL_MADE' = 'call-made',
  'CALL_MERGE' = 'call-merge',
  'MERGE_TYPE' = 'merge-type',
  'CALL_MISSED' = 'call-missed',
  'CALL_RECEIVED' = 'call-received',
  'CALL_SPLIT' = 'call-split',
  'CAMCORDER' = 'camcorder',
  'CAMCORDER_OFF' = 'camcorder-off',
  'CAMERA' = 'camera',
  'PHOTOGRAPHY' = 'photography',
  'CAMERA_ALT' = 'camera-alt',
  'LOCAL_SEE' = 'local-see',
  'PHOTO_CAMERA' = 'photo-camera',
  'CAMERA_ACCOUNT' = 'camera-account',
  'CAMERA_USER' = 'camera-user',
  'CAMERA_BURST' = 'camera-burst',
  'BURST_MODE' = 'burst-mode',
  'CAMERA_CONTROL' = 'camera-control',
  'CAMERA_DOCUMENT' = 'camera-document',
  'OVERHEAD_PROJECTOR' = 'overhead-projector',
  'CAMERA_DOCUMENT_OFF' = 'camera-document-off',
  'OVERHEAD_PROJECTOR_OFF' = 'overhead-projector-off',
  'CAMERA_ENHANCE' = 'camera-enhance',
  'CAMERA_ENHANCE_OUTLINE' = 'camera-enhance-outline',
  'CAMERA_FLIP' = 'camera-flip',
  'CAMERA_SYNC' = 'camera-sync',
  'CAMERA_REFRESH' = 'camera-refresh',
  'CAMERA_FLIP_OUTLINE' = 'camera-flip-outline',
  'CAMERA_SYNC_OUTLINE' = 'camera-sync-outline',
  'CAMERA_REFRESH_OUTLINE' = 'camera-refresh-outline',
  'CAMERA_FRONT' = 'camera-front',
  'CAMERA_FRONT_VARIANT' = 'camera-front-variant',
  'CAMERA_GOPRO' = 'camera-gopro',
  'CAMERA_IMAGE' = 'camera-image',
  'CAMERA_IRIS' = 'camera-iris',
  'CAMERA_LOCK' = 'camera-lock',
  'CAMERA_LOCK_OUTLINE' = 'camera-lock-outline',
  'CAMERA_MARKER' = 'camera-marker',
  'CAMERA_LOCATION' = 'camera-location',
  'CAMERA_MARKER_OUTLINE' = 'camera-marker-outline',
  'CAMERA_LOCATION_OUTLINE' = 'camera-location-outline',
  'CAMERA_METERING_CENTER' = 'camera-metering-center',
  'CAMERA_METERING_CENTRE' = 'camera-metering-centre',
  'CAMERA_METERING_MATRIX' = 'camera-metering-matrix',
  'CAMERA_METERING_PARTIAL' = 'camera-metering-partial',
  'CAMERA_METERING_SPOT' = 'camera-metering-spot',
  'CAMERA_OFF' = 'camera-off',
  'CAMERA_OFF_OUTLINE' = 'camera-off-outline',
  'CAMERA_OUTLINE' = 'camera-outline',
  'CAMERA_PARTY_MODE' = 'camera-party-mode',
  'CAMERA_PLUS' = 'camera-plus',
  'CAMERA_PLUS_OUTLINE' = 'camera-plus-outline',
  'CAMERA_REAR' = 'camera-rear',
  'CAMERA_REAR_VARIANT' = 'camera-rear-variant',
  'CAMERA_RETAKE' = 'camera-retake',
  'CAMERA_RETAKE_OUTLINE' = 'camera-retake-outline',
  'CAMERA_SWITCH' = 'camera-switch',
  'SWITCH_CAMERA' = 'switch-camera',
  'CAMERA_SWITCH_OUTLINE' = 'camera-switch-outline',
  'CAMERA_TIMER' = 'camera-timer',
  'CAMERA_WIRELESS' = 'camera-wireless',
  'CAMERA_WIRELESS_OUTLINE' = 'camera-wireless-outline',
  'CAMPFIRE' = 'campfire',
  'CANCEL' = 'cancel',
  'PROHIBITED' = 'prohibited',
  'BAN' = 'ban',
  'DO_NOT_DISTURB_ALT' = 'do-not-disturb-alt',
  'DENIED' = 'denied',
  'BLOCK' = 'block',
  'FORBID' = 'forbid',
  'NO' = 'no',
  'CANDELABRA' = 'candelabra',
  'CANDLE' = 'candle',
  'CANDELABRUM' = 'candelabrum',
  'CANDELABRA_FIRE' = 'candelabra-fire',
  'CANDELABRUM_FIRE' = 'candelabrum-fire',
  'CANDELABRUM_FLAME' = 'candelabrum-flame',
  'CANDELABRA_FLAME' = 'candelabra-flame',
  'CANDLE_FIRE' = 'candle-fire',
  'CANDLE_FLAME' = 'candle-flame',
  'CANDY' = 'candy',
  'TREAT' = 'treat',
  'CHOCOLATE' = 'chocolate',
  'CANDY_OFF' = 'candy-off',
  'CHOCOLATE_OFF' = 'chocolate-off',
  'TREAT_OFF' = 'treat-off',
  'CANDY_OFF_OUTLINE' = 'candy-off-outline',
  'CHOCOLATE_OFF_OUTLINE' = 'chocolate-off-outline',
  'TREAT_OFF_OUTLINE' = 'treat-off-outline',
  'NAVI_OFF' = 'navi-off',
  'CANDY_OUTLINE' = 'candy-outline',
  'CHOCOLATE_OUTLINE' = 'chocolate-outline',
  'TREAT_OUTLINE' = 'treat-outline',
  'NAVI' = 'navi',
  'HEY_LISTEN' = 'hey-listen',
  'FAIRY' = 'fairy',
  'CANDYCANE' = 'candycane',
  'CANNABIS' = 'cannabis',
  'WEED' = 'weed',
  'POT' = 'pot',
  'MARIJUANA' = 'marijuana',
  'CANNABIS_OFF' = 'cannabis-off',
  'CAPS_LOCK' = 'caps-lock',
  'CAR' = 'car',
  'DIRECTIONS_CAR' = 'directions-car',
  'DRIVE_ETA' = 'drive-eta',
  'TIME_TO_LEAVE' = 'time-to-leave',
  'CAR2PLUS' = 'car2plus',
  'CAR3PLUS' = 'car3plus',
  'CAR_ARROW_LEFT' = 'car-arrow-left',
  'CAR_ARROW_RIGHT' = 'car-arrow-right',
  'CAR_BACK' = 'car-back',
  'CAR_BATTERY' = 'car-battery',
  'CAR_BRAKE_ABS' = 'car-brake-abs',
  'ANTI_LOCK_BRAKE_SYSTEM' = 'anti-lock-brake-system',
  'ANTI_LOCK_BRAKING_SYSTEM' = 'anti-lock-braking-system',
  'CAR_BRAKE_ALERT' = 'car-brake-alert',
  'CAR_PARKING_BRAKE' = 'car-parking-brake',
  'CAR_HANDBRAKE' = 'car-handbrake',
  'CAR_HAND_BRAKE' = 'car-hand-brake',
  'CAR_EMERGENCY_BRAKE' = 'car-emergency-brake',
  'CAR_BRAKE_WARNING' = 'car-brake-warning',
  'CAR_BRAKE_FLUID_LEVEL' = 'car-brake-fluid-level',
  'CAR_BRAKE_HOLD' = 'car-brake-hold',
  'CAR_BRAKE_LOW_PRESSURE' = 'car-brake-low-pressure',
  'CAR_BRAKE_PARKING' = 'car-brake-parking',
  'CAR_BRAKE_RETARDER' = 'car-brake-retarder',
  'CAR_BRAKE_TEMPERATURE' = 'car-brake-temperature',
  'CAR_BRAKE_WORN_LININGS' = 'car-brake-worn-linings',
  'CAR_CHILD_SEAT' = 'car-child-seat',
  'CAR_CLOCK' = 'car-clock',
  'CAR_CLUTCH' = 'car-clutch',
  'CAR_COG' = 'car-cog',
  'CAR_SETTINGS' = 'car-settings',
  'CAR_CONNECTED' = 'car-connected',
  'CAR_CONVERTIBLE' = 'car-convertible',
  'CAR_COOLANT_LEVEL' = 'car-coolant-level',
  'CAR_CRUISE_CONTROL' = 'car-cruise-control',
  'CAR_DEFROST_FRONT' = 'car-defrost-front',
  'CAR_DEFROST_REAR' = 'car-defrost-rear',
  'CAR_DOOR' = 'car-door',
  'CAR_DOOR_LOCK' = 'car-door-lock',
  'CAR_ELECTRIC' = 'car-electric',
  'CAR_ELECTRIC_OUTLINE' = 'car-electric-outline',
  'CAR_EMERGENCY' = 'car-emergency',
  'CAR_POLICE' = 'car-police',
  'CAR_ESP' = 'car-esp',
  'ELECTRONIC_STABILITY_PROGRAM' = 'electronic-stability-program',
  'CAR_ESTATE' = 'car-estate',
  'CAR_SUV' = 'car-suv',
  'CAR_SPORTS_UTILITY_VEHICLE' = 'car-sports-utility-vehicle',
  'CAR_HATCHBACK' = 'car-hatchback',
  'CAR_INFO' = 'car-info',
  'CAR_KEY' = 'car-key',
  'CAR_RENTAL' = 'car-rental',
  'RENT_A_CAR' = 'rent-a-car',
  'CAR_LIFTED_PICKUP' = 'car-lifted-pickup',
  'CAR_LIGHT_ALERT' = 'car-light-alert',
  'CAR_LIGHT_DIMMED' = 'car-light-dimmed',
  'HEAD_LIGHT_DIMMED' = 'head-light-dimmed',
  'LOW_BEAM' = 'low-beam',
  'CAR_LIGHT_FOG' = 'car-light-fog',
  'HEAD_LIGHT_FOG' = 'head-light-fog',
  'CAR_LIGHT_HIGH' = 'car-light-high',
  'HEAD_LIGHT_HIGH' = 'head-light-high',
  'HIGH_BEAM' = 'high-beam',
  'CAR_LIMOUSINE' = 'car-limousine',
  'CAR_MULTIPLE' = 'car-multiple',
  'CAR_OFF' = 'car-off',
  'CAR_OUTLINE' = 'car-outline',
  'CAR_PARKING_LIGHTS' = 'car-parking-lights',
  'CAR_PICKUP' = 'car-pickup',
  'CAR_SEAT' = 'car-seat',
  'CAR_SEAT_COOLER' = 'car-seat-cooler',
  'CAR_SEAT_HEATER' = 'car-seat-heater',
  'CAR_SELECT' = 'car-select',
  'CAR_LOCATION' = 'car-location',
  'CAR_SHIFT_PATTERN' = 'car-shift-pattern',
  'CAR_TRANSMISSION' = 'car-transmission',
  'CAR_MANUAL_TRANSMISSION' = 'car-manual-transmission',
  'CAR_SIDE' = 'car-side',
  'CAR_SALOON' = 'car-saloon',
  'CAR_SPEED_LIMITER' = 'car-speed-limiter',
  'CAR_SPORTS' = 'car-sports',
  'CAR_TIRE_ALERT' = 'car-tire-alert',
  'CAR_TYRE_ALERT' = 'car-tyre-alert',
  'CAR_TYRE_WARNING' = 'car-tyre-warning',
  'CAR_TIRE_WARNING' = 'car-tire-warning',
  'CAR_TRACTION_CONTROL' = 'car-traction-control',
  'CAR_TURBOCHARGER' = 'car-turbocharger',
  'CAR_WASH' = 'car-wash',
  'LOCAL_CAR_WASH' = 'local-car-wash',
  'CAR_WINDSHIELD' = 'car-windshield',
  'CAR_FRONT_GLASS' = 'car-front-glass',
  'CAR_WINDSHIELD_OUTLINE' = 'car-windshield-outline',
  'CAR_FRONT_GLASS_OUTLINE' = 'car-front-glass-outline',
  'CAR_WIRELESS' = 'car-wireless',
  'CAR_AUTONOMOUS' = 'car-autonomous',
  'CAR_SELF_DRIVING' = 'car-self-driving',
  'CAR_SMART' = 'car-smart',
  'CAR_WRENCH' = 'car-wrench',
  'CAR_REPAIR' = 'car-repair',
  'MECHANIC' = 'mechanic',
  'CARABINER' = 'carabiner',
  'KARABINER' = 'karabiner',
  'ROCK_CLIMBING' = 'rock-climbing',
  'CARAVAN' = 'caravan',
  'CARD' = 'card',
  'BUTTON' = 'button',
  'CARD_ACCOUNT_DETAILS' = 'card-account-details',
  'IDENTIFICATION_CARD' = 'identification-card',
  'USER_CARD_DETAILS' = 'user-card-details',
  'ID_CARD' = 'id-card',
  'PERSON_CARD_DETAILS' = 'person-card-details',
  'DRIVERS_LICENSE' = 'drivers-license',
  'BUSINESS_CARD' = 'business-card',
  'CARD_ACCOUNT_DETAILS_OUTLINE' = 'card-account-details-outline',
  'IDENTIFICATION_CARD_OUTLINE' = 'identification-card-outline',
  'USER_CARD_DETAILS_OUTLINE' = 'user-card-details-outline',
  'ID_CARD_OUTLINE' = 'id-card-outline',
  'PERSON_CARD_DETAILS_OUTLINE' = 'person-card-details-outline',
  'DRIVERS_LICENSE_OUTLINE' = 'drivers-license-outline',
  'BUSINESS_CARD_OUTLINE' = 'business-card-outline',
  'CARD_ACCOUNT_DETAILS_STAR' = 'card-account-details-star',
  'CARD_ACCOUNT_DETAILS_FAVORITE' = 'card-account-details-favorite',
  'CARD_ACCOUNT_DETAILS_STAR_OUTLINE' = 'card-account-details-star-outline',
  'CARD_ACCOUNT_DETAILS_FAVORITE_OUTLINE' = 'card-account-details-favorite-outline',
  'CARD_ACCOUNT_MAIL' = 'card-account-mail',
  'CONTACT_MAIL' = 'contact-mail',
  'CARD_ACCOUNT_MAIL_OUTLINE' = 'card-account-mail-outline',
  'CONTACT_MAIL_OUTLINE' = 'contact-mail-outline',
  'CARD_ACCOUNT_PHONE' = 'card-account-phone',
  'CONTACT_PHONE' = 'contact-phone',
  'CARD_ACCOUNT_PHONE_OUTLINE' = 'card-account-phone-outline',
  'CONTACT_PHONE_OUTLINE' = 'contact-phone-outline',
  'CARD_BULLETED' = 'card-bulleted',
  'CARD_BULLETED_OFF' = 'card-bulleted-off',
  'CARD_BULLETED_OFF_OUTLINE' = 'card-bulleted-off-outline',
  'CARD_BULLETED_OUTLINE' = 'card-bulleted-outline',
  'CARD_BULLETED_SETTINGS' = 'card-bulleted-settings',
  'CARD_BULLETED_SETTINGS_OUTLINE' = 'card-bulleted-settings-outline',
  'CARD_MINUS' = 'card-minus',
  'CARD_MINUS_OUTLINE' = 'card-minus-outline',
  'CARD_MULTIPLE' = 'card-multiple',
  'CARD_MULTIPLE_OUTLINE' = 'card-multiple-outline',
  'CARD_OFF' = 'card-off',
  'CARD_OFF_OUTLINE' = 'card-off-outline',
  'CARD_OUTLINE' = 'card-outline',
  'BUTTON_OUTLINE' = 'button-outline',
  'CARD_PLUS' = 'card-plus',
  'CARD_PLUS_OUTLINE' = 'card-plus-outline',
  'CARD_REMOVE' = 'card-remove',
  'CARD_REMOVE_OUTLINE' = 'card-remove-outline',
  'CARD_SEARCH' = 'card-search',
  'PAGEVIEW' = 'pageview',
  'CARD_SEARCH_OUTLINE' = 'card-search-outline',
  'PAGEVIEW_OUTLINE' = 'pageview-outline',
  'CARD_TEXT' = 'card-text',
  'CARD_TEXT_OUTLINE' = 'card-text-outline',
  'CARDS' = 'cards',
  'CARDS_CLUB' = 'cards-club',
  'SUIT_CLUBS' = 'suit-clubs',
  'POKER_CLUB' = 'poker-club',
  'CARDS_CLUB_OUTLINE' = 'cards-club-outline',
  'CARDS_DIAMOND' = 'cards-diamond',
  'SUIT_DIAMONDS' = 'suit-diamonds',
  'HOV_LANE' = 'hov-lane',
  'HIGH_OCCUPANCY_VEHICLE_LANE' = 'high-occupancy-vehicle-lane',
  'CARPOOL_LANE' = 'carpool-lane',
  'POKER_DIAMOND' = 'poker-diamond',
  'CARDS_DIAMOND_OUTLINE' = 'cards-diamond-outline',
  'HOV_LANE_OUTLINE' = 'hov-lane-outline',
  'HIGH_OCCUPANCY_VEHICLE_LANE_OUTLINE' = 'high-occupancy-vehicle-lane-outline',
  'CARPOOL_LANE_OUTLINE' = 'carpool-lane-outline',
  'POKER_DIAMOND_OUTLINE' = 'poker-diamond-outline',
  'CARDS_HEART' = 'cards-heart',
  'SUIT_HEARTS' = 'suit-hearts',
  'POKER_HEART' = 'poker-heart',
  'CARDS_HEART_OUTLINE' = 'cards-heart-outline',
  'CARDS_OUTLINE' = 'cards-outline',
  'CARDS_PLAYING' = 'cards-playing',
  'CARDS_PLAYING_CLUB' = 'cards-playing-club',
  'CARDS_PLAYING_CLUB_MULTIPLE' = 'cards-playing-club-multiple',
  'CARDS_PLAYING_CLUB_MULTIPLE_OUTLINE' = 'cards-playing-club-multiple-outline',
  'CARDS_PLAYING_CLUB_OUTLINE' = 'cards-playing-club-outline',
  'CARDS_PLAYING_DIAMOND' = 'cards-playing-diamond',
  'CARDS_PLAYING_DIAMOND_MULTIPLE' = 'cards-playing-diamond-multiple',
  'CARDS_PLAYING_DIAMOND_MULTIPLE_OUTLINE' = 'cards-playing-diamond-multiple-outline',
  'CARDS_PLAYING_DIAMOND_OUTLINE' = 'cards-playing-diamond-outline',
  'CARDS_PLAYING_HEART' = 'cards-playing-heart',
  'CARDS_PLAYING_HEART_MULTIPLE' = 'cards-playing-heart-multiple',
  'CARDS_PLAYING_HEART_MULTIPLE_OUTLINE' = 'cards-playing-heart-multiple-outline',
  'CARDS_PLAYING_HEART_OUTLINE' = 'cards-playing-heart-outline',
  'CARDS_PLAYING_OUTLINE' = 'cards-playing-outline',
  'CARDS_PLAYING_SPADE' = 'cards-playing-spade',
  'CARDS_PLAYING_SPADE_MULTIPLE' = 'cards-playing-spade-multiple',
  'CARDS_PLAYING_SPADE_MULTIPLE_OUTLINE' = 'cards-playing-spade-multiple-outline',
  'CARDS_PLAYING_SPADE_OUTLINE' = 'cards-playing-spade-outline',
  'CARDS_SPADE' = 'cards-spade',
  'SUIT_SPADES' = 'suit-spades',
  'POKER_SPADE' = 'poker-spade',
  'CARDS_SPADE_OUTLINE' = 'cards-spade-outline',
  'CARDS_VARIANT' = 'cards-variant',
  'CARROT' = 'carrot',
  'CART' = 'cart',
  'TROLLEY' = 'trolley',
  'LOCAL_GROCERY_STORE' = 'local-grocery-store',
  'SHOPPING_CART' = 'shopping-cart',
  'CART_ARROW_DOWN' = 'cart-arrow-down',
  'SHOPPING_CART_ARROW_DOWN' = 'shopping-cart-arrow-down',
  'TROLLEY_ARROW_DOWN' = 'trolley-arrow-down',
  'CART_ARROW_RIGHT' = 'cart-arrow-right',
  'TROLLEY_ARROW_RIGHT' = 'trolley-arrow-right',
  'SHOPPING_CART_ARROW_RIGHT' = 'shopping-cart-arrow-right',
  'CART_ARROW_UP' = 'cart-arrow-up',
  'SHOPPING_CART_ARROW_UP' = 'shopping-cart-arrow-up',
  'TROLLEY_ARROW_UP' = 'trolley-arrow-up',
  'CART_CHECK' = 'cart-check',
  'CART_HEART' = 'cart-heart',
  'CART_FAVORITE' = 'cart-favorite',
  'SHOPPING_FAVORITE' = 'shopping-favorite',
  'CART_MINUS' = 'cart-minus',
  'SHOPPING_CART_MINUS' = 'shopping-cart-minus',
  'TROLLEY_MINUS' = 'trolley-minus',
  'CART_OFF' = 'cart-off',
  'TROLLEY_OFF' = 'trolley-off',
  'REMOVE_SHOPPING_CART' = 'remove-shopping-cart',
  'SHOPPING_CART_OFF' = 'shopping-cart-off',
  'CART_OUTLINE' = 'cart-outline',
  'TROLLEY_OUTLINE' = 'trolley-outline',
  'SHOPPING_CART_OUTLINE' = 'shopping-cart-outline',
  'CART_PLUS' = 'cart-plus',
  'TROLLEY_PLUS' = 'trolley-plus',
  'ADD_SHOPPING_CART' = 'add-shopping-cart',
  'SHOPPING_CART_PLUS' = 'shopping-cart-plus',
  'CART_ADD' = 'cart-add',
  'TROLLEY_ADD' = 'trolley-add',
  'SHOPPING_CART_ADD' = 'shopping-cart-add',
  'CART_REMOVE' = 'cart-remove',
  'TROLLEY_REMOVE' = 'trolley-remove',
  'SHOPPING_CART_REMOVE' = 'shopping-cart-remove',
  'CART_VARIANT' = 'cart-variant',
  'CASE_SENSITIVE_ALT' = 'case-sensitive-alt',
  'CASH' = 'cash',
  'MONEY' = 'money',
  'CASH100' = 'cash100',
  'CASH_CHECK' = 'cash-check',
  'CASH_FAST' = 'cash-fast',
  'INSTANT_DEPOSIT' = 'instant-deposit',
  'INSTANT_TRANSFER' = 'instant-transfer',
  'INSTANT_CASH' = 'instant-cash',
  'CASH_LOCK' = 'cash-lock',
  'CASH_LOCK_OPEN' = 'cash-lock-open',
  'CASH_MARKER' = 'cash-marker',
  'COD' = 'cod',
  'CASH_ON_DELIVERY' = 'cash-on-delivery',
  'CASH_LOCATION' = 'cash-location',
  'CASH_MINUS' = 'cash-minus',
  'CASH_MULTIPLE' = 'cash-multiple',
  'CASH_PLUS' = 'cash-plus',
  'CASH_REFUND' = 'cash-refund',
  'CASH_RETURN' = 'cash-return',
  'CASH_CHARGEBACK' = 'cash-chargeback',
  'CASH_REGISTER' = 'cash-register',
  'TILL' = 'till',
  'CASH_REMOVE' = 'cash-remove',
  'CASSETTE' = 'cassette',
  'TAPE' = 'tape',
  'CAST' = 'cast',
  'CAST_AUDIO' = 'cast-audio',
  'CAST_SPEAKER' = 'cast-speaker',
  'CAST_AUDIO_VARIANT' = 'cast-audio-variant',
  'APPLE_AIRPLAY' = 'apple-airplay',
  'CAST_CONNECTED' = 'cast-connected',
  'CAST_EDUCATION' = 'cast-education',
  'CAST_SCHOOL' = 'cast-school',
  'SCHOOL_ONLINE' = 'school-online',
  'CAST_TUTORIAL' = 'cast-tutorial',
  'CAST_OFF' = 'cast-off',
  'CAST_VARIANT' = 'cast-variant',
  'AIRPLAY' = 'airplay',
  'CASTLE' = 'castle',
  'CAT' = 'cat',
  'EMOJI_CAT' = 'emoji-cat',
  'EMOTICON_CAT' = 'emoticon-cat',
  'CCTV' = 'cctv',
  'CLOSED_CIRCUIT_TELEVISION' = 'closed-circuit-television',
  'SECURITY_CAMERA' = 'security-camera',
  'CCTV_OFF' = 'cctv-off',
  'CLOSED_CIRCUIT_TELEVISION_OFF' = 'closed-circuit-television-off',
  'SECURITY_CAMERA_OFF' = 'security-camera-off',
  'CEILING_FAN' = 'ceiling-fan',
  'CEILING_FAN_LIGHT' = 'ceiling-fan-light',
  'CEILING_FAN_ON' = 'ceiling-fan-on',
  'CEILING_LIGHT' = 'ceiling-light',
  'CEILING_LAMP' = 'ceiling-lamp',
  'CEILING_LIGHT_MULTIPLE' = 'ceiling-light-multiple',
  'CEILING_LAMP_MULTIPLE' = 'ceiling-lamp-multiple',
  'CEILING_LIGHT_MULTIPLE_OUTLINE' = 'ceiling-light-multiple-outline',
  'CEILING_LAMP_MULTIPLE_OUTLINE' = 'ceiling-lamp-multiple-outline',
  'CEILING_LIGHT_OUTLINE' = 'ceiling-light-outline',
  'CELLPHONE' = 'cellphone',
  'MOBILE_PHONE' = 'mobile-phone',
  'SMARTPHONE' = 'smartphone',
  'STAY_CURRENT_PORTRAIT' = 'stay-current-portrait',
  'STAY_PRIMARY_PORTRAIT' = 'stay-primary-portrait',
  'CELLPHONE_ARROW_DOWN' = 'cellphone-arrow-down',
  'CELLPHONE_SYSTEM_UPDATE' = 'cellphone-system-update',
  'MOBILE_PHONE_ARROW_DOWN' = 'mobile-phone-arrow-down',
  'SMARTPHONE_ARROW_DOWN' = 'smartphone-arrow-down',
  'CELLPHONE_ARROW_DOWN_VARIANT' = 'cellphone-arrow-down-variant',
  'CELLPHONE_DOWNLOAD' = 'cellphone-download',
  'CELLPHONE_BASIC' = 'cellphone-basic',
  'MOBILE_PHONE_BASIC' = 'mobile-phone-basic',
  'CELLPHONE_CHARGING' = 'cellphone-charging',
  'CELLPHONE_CHECK' = 'cellphone-check',
  'CELLPHONE_COG' = 'cellphone-cog',
  'PHONELINK_SETUP' = 'phonelink-setup',
  'MOBILE_PHONE_SETTINGS_VARIANT' = 'mobile-phone-settings-variant',
  'SMARTPHONE_SETTINGS_VARIANT' = 'smartphone-settings-variant',
  'CELLPHONE_DOCK' = 'cellphone-dock',
  'MOBILE_PHONE_DOCK' = 'mobile-phone-dock',
  'SMARTPHONE_DOCK' = 'smartphone-dock',
  'CELLPHONE_INFORMATION' = 'cellphone-information',
  'MOBILE_PHONE_INFORMATION' = 'mobile-phone-information',
  'SMARTPHONE_INFORMATION' = 'smartphone-information',
  'CELLPHONE_KEY' = 'cellphone-key',
  'MOBILE_PHONE_KEY' = 'mobile-phone-key',
  'SMARTPHONE_KEY' = 'smartphone-key',
  'CELLPHONE_LINK' = 'cellphone-link',
  'MOBILE_PHONE_LINK' = 'mobile-phone-link',
  'SMARTPHONE_LINK' = 'smartphone-link',
  'DEVICES' = 'devices',
  'CELLPHONE_LINK_OFF' = 'cellphone-link-off',
  'MOBILE_PHONE_LINK_OFF' = 'mobile-phone-link-off',
  'SMARTPHONE_LINK_OFF' = 'smartphone-link-off',
  'PHONELINK_OFF' = 'phonelink-off',
  'CELLPHONE_LOCK' = 'cellphone-lock',
  'PHONELINK_LOCK' = 'phonelink-lock',
  'MOBILE_PHONE_LOCK' = 'mobile-phone-lock',
  'SMARTPHONE_LOCK' = 'smartphone-lock',
  'CELLPHONE_MARKER' = 'cellphone-marker',
  'CELLPHONE_LOCATION' = 'cellphone-location',
  'CELLPHONE_MAP' = 'cellphone-map',
  'FIND_MY_PHONE' = 'find-my-phone',
  'CELLPHONE_GPS' = 'cellphone-gps',
  'CELLPHONE_MESSAGE' = 'cellphone-message',
  'MOBILE_PHONE_MESSAGE' = 'mobile-phone-message',
  'SMARTPHONE_MESSAGE' = 'smartphone-message',
  'CELLPHONE_MESSAGE_OFF' = 'cellphone-message-off',
  'CELLPHONE_NFC' = 'cellphone-nfc',
  'CELLPHONE_NFC_OFF' = 'cellphone-nfc-off',
  'CELLPHONE_OFF' = 'cellphone-off',
  'MOBILE_PHONE_OFF' = 'mobile-phone-off',
  'SMARTPHONE_OFF' = 'smartphone-off',
  'MOBILE_OFF' = 'mobile-off',
  'CELLPHONE_PLAY' = 'cellphone-play',
  'CELLPHONE_REMOVE' = 'cellphone-remove',
  'PHONELINK_ERASE' = 'phonelink-erase',
  'MOBILE_PHONE_ERASE' = 'mobile-phone-erase',
  'SMARTPHONE_ERASE' = 'smartphone-erase',
  'CELLPHONE_ERASE' = 'cellphone-erase',
  'CELLPHONE_SCREENSHOT' = 'cellphone-screenshot',
  'CELLPHONE_SETTINGS' = 'cellphone-settings',
  'MOBILE_PHONE_SETTINGS' = 'mobile-phone-settings',
  'SMARTPHONE_SETTINGS' = 'smartphone-settings',
  'SETTINGS_CELL' = 'settings-cell',
  'CELLPHONE_SOUND' = 'cellphone-sound',
  'PHONELINK_RING' = 'phonelink-ring',
  'MOBILE_PHONE_SOUND' = 'mobile-phone-sound',
  'SMARTPHONE_SOUND' = 'smartphone-sound',
  'CELLPHONE_TEXT' = 'cellphone-text',
  'MOBILE_PHONE_TEXT' = 'mobile-phone-text',
  'SMARTPHONE_TEXT' = 'smartphone-text',
  'CELLPHONE_WIRELESS' = 'cellphone-wireless',
  'MOBILE_PHONE_WIRELESS' = 'mobile-phone-wireless',
  'SMARTPHONE_WIRELESS' = 'smartphone-wireless',
  'CENTOS' = 'centos',
  'CERTIFICATE' = 'certificate',
  'DIPLOMA' = 'diploma',
  'SEAL' = 'seal',
  'CERTIFICATE_OUTLINE' = 'certificate-outline',
  'DIPLOMA_OUTLINE' = 'diploma-outline',
  'SEAL_OUTLINE' = 'seal-outline',
  'CHAIR_ROLLING' = 'chair-rolling',
  'OFFICE_CHAIR' = 'office-chair',
  'STUDY_CHAIR' = 'study-chair',
  'CHAIR_SCHOOL' = 'chair-school',
  'DESK' = 'desk',
  'LEARN' = 'learn',
  'CHANDELIER' = 'chandelier',
  'GIRANDOLE' = 'girandole',
  'CANDELABRA_LAMP' = 'candelabra-lamp',
  'SUSPENDED_LIGHT' = 'suspended-light',
  'CHARITY' = 'charity',
  'SUPER_CHAT_FOR_GOOD' = 'super-chat-for-good',
  'CHART_ARC' = 'chart-arc',
  'REPORT_ARC' = 'report-arc',
  'WIDGET_ARC' = 'widget-arc',
  'CHART_AREASPLINE' = 'chart-areaspline',
  'REPORT_AREASPLINE' = 'report-areaspline',
  'WIDGET_AREASPLINE' = 'widget-areaspline',
  'GRAPH_AREASPLINE' = 'graph-areaspline',
  'CHART_AREASPLINE_VARIANT' = 'chart-areaspline-variant',
  'REPORT_AREASPLINE_VARIANT' = 'report-areaspline-variant',
  'WIDGET_AREASPLINE_VARIANT' = 'widget-areaspline-variant',
  'GRAPH_AREASPLINE_VARIANT' = 'graph-areaspline-variant',
  'CHART_BAR' = 'chart-bar',
  'REPORT_BAR' = 'report-bar',
  'WIDGET_BAR' = 'widget-bar',
  'GRAPH_BAR' = 'graph-bar',
  'CHART_BAR_STACKED' = 'chart-bar-stacked',
  'REPORT_BAR_STACKED' = 'report-bar-stacked',
  'WIDGET_BAR_STACKED' = 'widget-bar-stacked',
  'GRAPH_BAR_STACKED' = 'graph-bar-stacked',
  'CHART_BELL_CURVE' = 'chart-bell-curve',
  'REPORT_BELL_CURVE' = 'report-bell-curve',
  'WIDGET_BELL_CURVE' = 'widget-bell-curve',
  'GRAPH_BELL_CURVE' = 'graph-bell-curve',
  'CHART_BELL_CURVE_CUMULATIVE' = 'chart-bell-curve-cumulative',
  'REPORT_BELL_CURVE_CUMULATIVE' = 'report-bell-curve-cumulative',
  'WIDGET_BELL_CURVE_CUMULATIVE' = 'widget-bell-curve-cumulative',
  'GRAPH_BELL_CURVE_CUMULATIVE' = 'graph-bell-curve-cumulative',
  'CHART_BOX' = 'chart-box',
  'POLL_BOX' = 'poll-box',
  'REPORT_BOX' = 'report-box',
  'WIDGET_BOX' = 'widget-box',
  'GRAPH_BOX' = 'graph-box',
  'CHART_BOX_OUTLINE' = 'chart-box-outline',
  'POLL_BOX_OUTLINE' = 'poll-box-outline',
  'REPORT_BOX_OUTLINE' = 'report-box-outline',
  'WIDGET_BOX_OUTLINE' = 'widget-box-outline',
  'GRAPH_BOX_OUTLINE' = 'graph-box-outline',
  'CHART_BOX_PLUS_OUTLINE' = 'chart-box-plus-outline',
  'REPORT_BOX_PLUS_OUTLINE' = 'report-box-plus-outline',
  'WIDGET_BOX_PLUS_OUTLINE' = 'widget-box-plus-outline',
  'GRAPH_BOX_PLUS_OUTLINE' = 'graph-box-plus-outline',
  'CHART_BUBBLE' = 'chart-bubble',
  'BUBBLE_CHART' = 'bubble-chart',
  'REPORT_BUBBLE' = 'report-bubble',
  'WIDGET_BUBBLE' = 'widget-bubble',
  'CHART_DONUT' = 'chart-donut',
  'CHART_DOUGHNUT' = 'chart-doughnut',
  'DATA_USAGE' = 'data-usage',
  'REPORT_DONUT' = 'report-donut',
  'WIDGET_DONUT' = 'widget-donut',
  'CHART_DONUT_VARIANT' = 'chart-donut-variant',
  'CHART_DOUGHNUT_VARIANT' = 'chart-doughnut-variant',
  'REPORT_DONUT_VARIANT' = 'report-donut-variant',
  'WIDGET_DONUT_VARIANT' = 'widget-donut-variant',
  'CHART_GANTT' = 'chart-gantt',
  'REPORT_GANTT' = 'report-gantt',
  'TIMELINE' = 'timeline',
  'WIDGET_GANTT' = 'widget-gantt',
  'ROADMAP' = 'roadmap',
  'CHART_HISTOGRAM' = 'chart-histogram',
  'REPORT_HISTOGRAM' = 'report-histogram',
  'WIDGET_HISTOGRAM' = 'widget-histogram',
  'GRAPH_HISTOGRAM' = 'graph-histogram',
  'CHART_LINE' = 'chart-line',
  'REPORT_LINE' = 'report-line',
  'WIDGET_LINE' = 'widget-line',
  'GRAPH_LINE' = 'graph-line',
  'CHART_LINE_STACKED' = 'chart-line-stacked',
  'REPORT_LINE_STACKED' = 'report-line-stacked',
  'WIDGET_LINE_STACKED' = 'widget-line-stacked',
  'GRAPH_LINE_STACKED' = 'graph-line-stacked',
  'CHART_LINE_VARIANT' = 'chart-line-variant',
  'SHOW_CHART' = 'show-chart',
  'REPORT_LINE_VARIANT' = 'report-line-variant',
  'WIDGET_LINE_VARIANT' = 'widget-line-variant',
  'GRAPH_LINE_VARIANT' = 'graph-line-variant',
  'CHART_MULTILINE' = 'chart-multiline',
  'REPORT_MULTILINE' = 'report-multiline',
  'WIDGET_MULTILINE' = 'widget-multiline',
  'GRAPH_MULTILINE' = 'graph-multiline',
  'CHART_MULTIPLE' = 'chart-multiple',
  'REPORT_MULTIPLE' = 'report-multiple',
  'WIDGET_MULTIPLE' = 'widget-multiple',
  'GRAPH_MULTIPLE' = 'graph-multiple',
  'CHART_PIE' = 'chart-pie',
  'REPORT_PIE' = 'report-pie',
  'WIDGET_PIE' = 'widget-pie',
  'GRAPH_PIE' = 'graph-pie',
  'CHART_PPF' = 'chart-ppf',
  'CHART_PRODUCTION_POSSIBILITY_FRONTIER' = 'chart-production-possibility-frontier',
  'REPORT_PPF' = 'report-ppf',
  'WIDGET_PPF' = 'widget-ppf',
  'GRAPH_PPF' = 'graph-ppf',
  'CHART_SANKEY' = 'chart-sankey',
  'CHART_SNAKEY' = 'chart-snakey',
  'REPORT_SANKEY' = 'report-sankey',
  'WIDGET_SANKEY' = 'widget-sankey',
  'GRAPH_SANKEY' = 'graph-sankey',
  'CHART_SANKEY_VARIANT' = 'chart-sankey-variant',
  'CHART_SNAKEY_VARIANT' = 'chart-snakey-variant',
  'REPORT_SANKEY_VARIANT' = 'report-sankey-variant',
  'WIDGET_SANKEY_VARIANT' = 'widget-sankey-variant',
  'GRAPH_SANKEY_VARIANT' = 'graph-sankey-variant',
  'CHART_SCATTER_PLOT' = 'chart-scatter-plot',
  'REPORT_SCATTER_PLOT' = 'report-scatter-plot',
  'WIDGET_SCATTER_PLOT' = 'widget-scatter-plot',
  'GRAPH_SCATTER_PLOT' = 'graph-scatter-plot',
  'CHART_SCATTER_PLOT_HEXBIN' = 'chart-scatter-plot-hexbin',
  'CHART_SCATTERPLOT_HEXBIN' = 'chart-scatterplot-hexbin',
  'REPORT_SCATTER_PLOT_HEXBIN' = 'report-scatter-plot-hexbin',
  'WIDGET_SCATTER_PLOT_HEXBIN' = 'widget-scatter-plot-hexbin',
  'GRAPH_SCATTER_PLOT_HEXBIN' = 'graph-scatter-plot-hexbin',
  'CHART_TIMELINE' = 'chart-timeline',
  'REPORT_TIMELINE' = 'report-timeline',
  'WIDGET_TIMELINE' = 'widget-timeline',
  'GRAPH_TIMELINE' = 'graph-timeline',
  'CHART_TIMELINE_VARIANT' = 'chart-timeline-variant',
  'REPORT_TIMELINE_VARIANT' = 'report-timeline-variant',
  'WIDGET_TIMELINE_VARIANT' = 'widget-timeline-variant',
  'GRAPH_TIMELINE_VARIANT' = 'graph-timeline-variant',
  'CHART_TIMELINE_VARIANT_SHIMMER' = 'chart-timeline-variant-shimmer',
  'REPORT_TIMELINE_VARIANT_SHIMMER' = 'report-timeline-variant-shimmer',
  'WIDGET_TIMELINE_VARIANT_SHIMMER' = 'widget-timeline-variant-shimmer',
  'GRAPH_TIMELINE_VARIANT_SHIMMER' = 'graph-timeline-variant-shimmer',
  'REPORT_LINE_SHIMMER' = 'report-line-shimmer',
  'WIDGET_LINE_SHIMMER' = 'widget-line-shimmer',
  'GRAPH_LINE_SHIMMER' = 'graph-line-shimmer',
  'CHART_TREE' = 'chart-tree',
  'REPORT_TREE' = 'report-tree',
  'WIDGET_TREE' = 'widget-tree',
  'CHART_WATERFALL' = 'chart-waterfall',
  'CHAT' = 'chat',
  'CHAT_ALERT' = 'chat-alert',
  'CHAT_WARNING' = 'chat-warning',
  'CHAT_ALERT_OUTLINE' = 'chat-alert-outline',
  'CHAT_MINUS' = 'chat-minus',
  'CHAT_MINUS_OUTLINE' = 'chat-minus-outline',
  'CHAT_OUTLINE' = 'chat-outline',
  'CHAT_PLUS' = 'chat-plus',
  'CHAT_PLUS_OUTLINE' = 'chat-plus-outline',
  'CHAT_PROCESSING' = 'chat-processing',
  'CHAT_TYPING' = 'chat-typing',
  'CHAT_PROCESSING_OUTLINE' = 'chat-processing-outline',
  'CHAT_TYPING_OUTLINE' = 'chat-typing-outline',
  'CHAT_QUESTION' = 'chat-question',
  'CHAT_QUESTION_OUTLINE' = 'chat-question-outline',
  'CHAT_REMOVE' = 'chat-remove',
  'CHAT_REMOVE_OUTLINE' = 'chat-remove-outline',
  'CHAT_SLEEP' = 'chat-sleep',
  'CHAT_SLEEP_OUTLINE' = 'chat-sleep-outline',
  'CHECK' = 'check',
  'TICK' = 'tick',
  'DONE' = 'done',
  'CHECK_ALL' = 'check-all',
  'TICK_ALL' = 'tick-all',
  'DONE_ALL' = 'done-all',
  'CHECK_MULTIPLE' = 'check-multiple',
  'CHECKS' = 'checks',
  'TICKS' = 'ticks',
  'CHECK_BOLD' = 'check-bold',
  'CHECK_THICK' = 'check-thick',
  'CHECK_CIRCLE' = 'check-circle',
  'TICK_CIRCLE' = 'tick-circle',
  'CHECK_CIRCLE_OUTLINE' = 'check-circle-outline',
  'TICK_CIRCLE_OUTLINE' = 'tick-circle-outline',
  'CHECK_DECAGRAM' = 'check-decagram',
  'VERIFIED' = 'verified',
  'DECAGRAM_CHECK' = 'decagram-check',
  'APPROVE' = 'approve',
  'APPROVAL' = 'approval',
  'TICK_DECAGRAM' = 'tick-decagram',
  'CHECK_DECAGRAM_OUTLINE' = 'check-decagram-outline',
  'CHECK_NETWORK' = 'check-network',
  'TICK_NETWORK' = 'tick-network',
  'CHECK_NETWORK_OUTLINE' = 'check-network-outline',
  'TICK_NETWORK_OUTLINE' = 'tick-network-outline',
  'CHECK_OUTLINE' = 'check-outline',
  'DONE_OUTLINE' = 'done-outline',
  'TICK_OUTLINE' = 'tick-outline',
  'CHECK_UNDERLINE' = 'check-underline',
  'CHECK_UNDERLINE_CIRCLE' = 'check-underline-circle',
  'CHECK_UNDERLINE_CIRCLE_OUTLINE' = 'check-underline-circle-outline',
  'CHECKBOOK' = 'checkbook',
  'CHEQUEBOOK' = 'chequebook',
  'CHEQUE_BOOK' = 'cheque-book',
  'CHECKBOX_BLANK' = 'checkbox-blank',
  'CHECKBOX_BLANK_BADGE' = 'checkbox-blank-badge',
  'CHECKBOX_BLANK_NOTIFICATION' = 'checkbox-blank-notification',
  'APP_NOTIFICATION' = 'app-notification',
  'APP_BADGE' = 'app-badge',
  'CHECKBOX_BLANK_BADGE_OUTLINE' = 'checkbox-blank-badge-outline',
  'CHECKBOX_BLANK_NOTIFICATION_OUTLINE' = 'checkbox-blank-notification-outline',
  'APP_NOTIFICATION_OUTLINE' = 'app-notification-outline',
  'APP_BADGE_OUTLINE' = 'app-badge-outline',
  'CHECKBOX_BLANK_CIRCLE' = 'checkbox-blank-circle',
  'CHECKBOX_BLANK_CIRCLE_OUTLINE' = 'checkbox-blank-circle-outline',
  'CHECKBOX_BLANK_OFF' = 'checkbox-blank-off',
  'CHECKBOX_BLANK_OFF_OUTLINE' = 'checkbox-blank-off-outline',
  'CHECKBOX_BLANK_OUTLINE' = 'checkbox-blank-outline',
  'CHECK_BOX_OUTLINE_BLANK' = 'check-box-outline-blank',
  'CHECKBOX_INTERMEDIATE' = 'checkbox-intermediate',
  'CHECKBOX_MARKED' = 'checkbox-marked',
  'CHECK_BOX' = 'check-box',
  'CHECKBOX_MARKED_CIRCLE' = 'checkbox-marked-circle',
  'CHECKBOX_MARKED_CIRCLE_OUTLINE' = 'checkbox-marked-circle-outline',
  'CHECKBOX_MARKED_CIRCLE_PLUS_OUTLINE' = 'checkbox-marked-circle-plus-outline',
  'TASK_PLUS' = 'task-plus',
  'TASK_ADD' = 'task-add',
  'TODO_PLUS' = 'todo-plus',
  'TODO_ADD' = 'todo-add',
  'CHECKBOX_MARKED_OUTLINE' = 'checkbox-marked-outline',
  'CHECKBOX_MULTIPLE_BLANK' = 'checkbox-multiple-blank',
  'CHECKBOXES_BLANK' = 'checkboxes-blank',
  'CHECKBOX_MULTIPLE_BLANK_CIRCLE' = 'checkbox-multiple-blank-circle',
  'CHECKBOXES_BLANK_CIRCLE' = 'checkboxes-blank-circle',
  'CHECKBOX_MULTIPLE_BLANK_CIRCLE_OUTLINE' = 'checkbox-multiple-blank-circle-outline',
  'CHECKBOXES_BLANK_CIRCLE_OUTLINE' = 'checkboxes-blank-circle-outline',
  'CHECKBOX_MULTIPLE_BLANK_OUTLINE' = 'checkbox-multiple-blank-outline',
  'CHECKBOXES_BLANK_OUTLINE' = 'checkboxes-blank-outline',
  'CHECKBOX_MULTIPLE_MARKED' = 'checkbox-multiple-marked',
  'CHECKBOXES_MARKED' = 'checkboxes-marked',
  'CHECKBOX_MULTIPLE_MARKED_CIRCLE' = 'checkbox-multiple-marked-circle',
  'CHECKBOXES_MARKED_CIRCLE' = 'checkboxes-marked-circle',
  'CHECKBOX_MULTIPLE_MARKED_CIRCLE_OUTLINE' = 'checkbox-multiple-marked-circle-outline',
  'CHECKBOXES_MARKED_CIRCLE_OUTLINE' = 'checkboxes-marked-circle-outline',
  'CHECKBOX_MULTIPLE_MARKED_OUTLINE' = 'checkbox-multiple-marked-outline',
  'CHECKBOXES_MARKED_OUTLINE' = 'checkboxes-marked-outline',
  'CHECKBOX_MULTIPLE_OUTLINE' = 'checkbox-multiple-outline',
  'CHECK_BOXES_OUTLINE' = 'check-boxes-outline',
  'TICK_BOX_MULTIPLE_OUTLINE' = 'tick-box-multiple-outline',
  'CHECKBOX_OUTLINE' = 'checkbox-outline',
  'CHECKERBOARD' = 'checkerboard',
  'RASTER' = 'raster',
  'CHECKERBOARD_MINUS' = 'checkerboard-minus',
  'RASTER_MINUS' = 'raster-minus',
  'CHECKERBOARD_PLUS' = 'checkerboard-plus',
  'RASTER_PLUS' = 'raster-plus',
  'CHECKERBOARD_REMOVE' = 'checkerboard-remove',
  'RASTER_REMOVE' = 'raster-remove',
  'CHEESE' = 'cheese',
  'SWISS_CHEESE' = 'swiss-cheese',
  'CHEESE_OFF' = 'cheese-off',
  'CHEF_HAT' = 'chef-hat',
  'TOQUE' = 'toque',
  'COOK' = 'cook',
  'CHEMICAL_WEAPON' = 'chemical-weapon',
  'CHESS_BISHOP' = 'chess-bishop',
  'CHESS_KING' = 'chess-king',
  'CROWN' = 'crown',
  'ROYALTY' = 'royalty',
  'CHESS_KNIGHT' = 'chess-knight',
  'CHESS_HORSE' = 'chess-horse',
  'CHESS_PAWN' = 'chess-pawn',
  'CHESS_QUEEN' = 'chess-queen',
  'CHESS_ROOK' = 'chess-rook',
  'CHESS_CASTLE' = 'chess-castle',
  'CHESS_TOWER' = 'chess-tower',
  'CHEVRON_DOUBLE_DOWN' = 'chevron-double-down',
  'CHEVRON_DOUBLE_LEFT' = 'chevron-double-left',
  'CHEVRON_DOUBLE_RIGHT' = 'chevron-double-right',
  'CHEVRON_DOUBLE_UP' = 'chevron-double-up',
  'CHEVRON_DOWN' = 'chevron-down',
  'EXPAND_MORE' = 'expand-more',
  'KEYBOARD_ARROW_DOWN' = 'keyboard-arrow-down',
  'CHEVRON_DOWN_BOX' = 'chevron-down-box',
  'CHEVRON_DOWN_BOX_OUTLINE' = 'chevron-down-box-outline',
  'CHEVRON_DOWN_CIRCLE' = 'chevron-down-circle',
  'CHEVRON_DOWN_CIRCLE_OUTLINE' = 'chevron-down-circle-outline',
  'CHEVRON_LEFT' = 'chevron-left',
  'KEYBOARD_ARROW_LEFT' = 'keyboard-arrow-left',
  'NAVIGATE_BEFORE' = 'navigate-before',
  'CHEVRON_LEFT_BOX' = 'chevron-left-box',
  'CHEVRON_LEFT_BOX_OUTLINE' = 'chevron-left-box-outline',
  'CHEVRON_LEFT_CIRCLE' = 'chevron-left-circle',
  'CHEVRON_LEFT_CIRCLE_OUTLINE' = 'chevron-left-circle-outline',
  'CHEVRON_RIGHT' = 'chevron-right',
  'KEYBOARD_ARROW_RIGHT' = 'keyboard-arrow-right',
  'NAVIGATE_NEXT' = 'navigate-next',
  'CHEVRON_RIGHT_BOX' = 'chevron-right-box',
  'CHEVRON_RIGHT_BOX_OUTLINE' = 'chevron-right-box-outline',
  'CHEVRON_RIGHT_CIRCLE' = 'chevron-right-circle',
  'CHEVRON_RIGHT_CIRCLE_OUTLINE' = 'chevron-right-circle-outline',
  'CHEVRON_TRIPLE_DOWN' = 'chevron-triple-down',
  'CHEVRON_TRIPLE_LEFT' = 'chevron-triple-left',
  'CHEVRON_TRIPLE_RIGHT' = 'chevron-triple-right',
  'CHEVRON_TRIPLE_UP' = 'chevron-triple-up',
  'RANK' = 'rank',
  'CHEVRON_UP' = 'chevron-up',
  'EXPAND_LESS' = 'expand-less',
  'KEYBOARD_ARROW_UP' = 'keyboard-arrow-up',
  'CARET' = 'caret',
  'CHEVRON_UP_BOX' = 'chevron-up-box',
  'CHEVRON_UP_BOX_OUTLINE' = 'chevron-up-box-outline',
  'CHEVRON_UP_CIRCLE' = 'chevron-up-circle',
  'CHEVRON_UP_CIRCLE_OUTLINE' = 'chevron-up-circle-outline',
  'CHILI_ALERT' = 'chili-alert',
  'CHILI_ALERT_OUTLINE' = 'chili-alert-outline',
  'CHILI_HOT' = 'chili-hot',
  'CHILLI_HOT' = 'chilli-hot',
  'PEPPER' = 'pepper',
  'SPICY' = 'spicy',
  'CHILI_HOT_OUTLINE' = 'chili-hot-outline',
  'CHILI_MEDIUM' = 'chili-medium',
  'CHILLI_MEDIUM' = 'chilli-medium',
  'CHILI_MEDIUM_OUTLINE' = 'chili-medium-outline',
  'CHILI_MILD' = 'chili-mild',
  'CHILLI_MILD' = 'chilli-mild',
  'CHILI_MILD_OUTLINE' = 'chili-mild-outline',
  'CHILI_OFF' = 'chili-off',
  'CHILLI_OFF' = 'chilli-off',
  'PEPPER_OFF' = 'pepper-off',
  'SPICY_OFF' = 'spicy-off',
  'CHILI_OFF_OUTLINE' = 'chili-off-outline',
  'CHIP' = 'chip',
  'INTEGRATED_CIRCUIT' = 'integrated-circuit',
  'CHURCH' = 'church',
  'CIGAR' = 'cigar',
  'CIGAR_OFF' = 'cigar-off',
  'CIRCLE' = 'circle',
  'LENS' = 'lens',
  'CIRCLE_BOX' = 'circle-box',
  'CIRCLE_BOX_OUTLINE' = 'circle-box-outline',
  'CIRCLE_DOUBLE' = 'circle-double',
  'CIRCLE_EDIT_OUTLINE' = 'circle-edit-outline',
  'CIRCLE_EXPAND' = 'circle-expand',
  'CIRCLE_HALF' = 'circle-half',
  'BRIGHTNESS_HALF' = 'brightness-half',
  'CIRCLE_HALF_FULL' = 'circle-half-full',
  'CIRCLE_MEDIUM' = 'circle-medium',
  'CIRCLE_MULTIPLE' = 'circle-multiple',
  'COINS' = 'coins',
  'CIRCLE_MULTIPLE_OUTLINE' = 'circle-multiple-outline',
  'TOLL' = 'toll',
  'COINS_OUTLINE' = 'coins-outline',
  'CIRCLE_OFF_OUTLINE' = 'circle-off-outline',
  'NULL_OFF' = 'null-off',
  'CIRCLE_OPACITY' = 'circle-opacity',
  'CIRCLE_TRANSPARENT' = 'circle-transparent',
  'CIRCLE_OUTLINE' = 'circle-outline',
  'NULL' = 'null',
  'CIRCLE_SLICE1' = 'circle-slice1',
  'CIRCLE_SLICE2' = 'circle-slice2',
  'CIRCLE_SLICE3' = 'circle-slice3',
  'CIRCLE_SLICE4' = 'circle-slice4',
  'CIRCLE_SLICE5' = 'circle-slice5',
  'CIRCLE_SLICE6' = 'circle-slice6',
  'CIRCLE_SLICE7' = 'circle-slice7',
  'CIRCLE_SLICE8' = 'circle-slice8',
  'CIRCLE_SMALL' = 'circle-small',
  'MULTIPLICATION' = 'multiplication',
  'DOT' = 'dot',
  'CIRCULAR_SAW' = 'circular-saw',
  'CITY' = 'city',
  'LOCATION_CITY' = 'location-city',
  'CITY_VARIANT' = 'city-variant',
  'CITY_VARIANT_OUTLINE' = 'city-variant-outline',
  'CLIPBOARD' = 'clipboard',
  'CLIPBOARD_ACCOUNT' = 'clipboard-account',
  'CLIPBOARD_USER' = 'clipboard-user',
  'ASSIGNMENT_IND' = 'assignment-ind',
  'CLIPBOARD_PERSON' = 'clipboard-person',
  'CLIPBOARD_ACCOUNT_OUTLINE' = 'clipboard-account-outline',
  'CLIPBOARD_USER_OUTLINE' = 'clipboard-user-outline',
  'CLIPBOARD_PERSON_OUTLINE' = 'clipboard-person-outline',
  'ASSIGNMENT_IND_OUTLINE' = 'assignment-ind-outline',
  'CLIPBOARD_ALERT' = 'clipboard-alert',
  'CLIPBOARD_WARNING' = 'clipboard-warning',
  'ASSIGNMENT_LATE' = 'assignment-late',
  'CLIPBOARD_ALERT_OUTLINE' = 'clipboard-alert-outline',
  'CLIPBOARD_WARNING_OUTLINE' = 'clipboard-warning-outline',
  'CLIPBOARD_ARROW_DOWN' = 'clipboard-arrow-down',
  'ASSIGNMENT_RETURNED' = 'assignment-returned',
  'CLIPBOARD_ARROW_BOTTOM' = 'clipboard-arrow-bottom',
  'CLIPBOARD_ARROW_DOWN_OUTLINE' = 'clipboard-arrow-down-outline',
  'ASSIGNMENT_RETURNED_OUTLINE' = 'assignment-returned-outline',
  'CLIPBOARD_ARROW_BOTTOM_OUTLINE' = 'clipboard-arrow-bottom-outline',
  'CLIPBOARD_ARROW_LEFT' = 'clipboard-arrow-left',
  'ASSIGNMENT_RETURN' = 'assignment-return',
  'CLIPBOARD_ARROW_LEFT_OUTLINE' = 'clipboard-arrow-left-outline',
  'CLIPBOARD_ARROW_RIGHT' = 'clipboard-arrow-right',
  'CLIPBOARD_ARROW_RIGHT_OUTLINE' = 'clipboard-arrow-right-outline',
  'CLIPBOARD_ARROW_UP' = 'clipboard-arrow-up',
  'CLIPBOARD_ARROW_TOP' = 'clipboard-arrow-top',
  'CLIPBOARD_ARROW_UP_OUTLINE' = 'clipboard-arrow-up-outline',
  'CLIPBOARD_ARROW_TOP_OUTLINE' = 'clipboard-arrow-top-outline',
  'CLIPBOARD_CHECK' = 'clipboard-check',
  'ASSIGNMENT_TURNED_IN' = 'assignment-turned-in',
  'CLIPBOARD_TICK' = 'clipboard-tick',
  'CLIPBOARD_CHECK_MULTIPLE' = 'clipboard-check-multiple',
  'CLIPBOARD_CHECK_MULTIPLE_OUTLINE' = 'clipboard-check-multiple-outline',
  'CLIPBOARD_CHECK_OUTLINE' = 'clipboard-check-outline',
  'CLIPBOARD_TICK_OUTLINE' = 'clipboard-tick-outline',
  'CLIPBOARD_CLOCK' = 'clipboard-clock',
  'CLIPBOARD_CLOCK_OUTLINE' = 'clipboard-clock-outline',
  'CLIPBOARD_EDIT' = 'clipboard-edit',
  'CLIPBOARD_EDIT_OUTLINE' = 'clipboard-edit-outline',
  'CLIPBOARD_FILE' = 'clipboard-file',
  'CLIPBOARD_FILE_OUTLINE' = 'clipboard-file-outline',
  'CLIPBOARD_FLOW' = 'clipboard-flow',
  'CLIPBOARD_FLOW_OUTLINE' = 'clipboard-flow-outline',
  'CLIPBOARD_LIST' = 'clipboard-list',
  'CLIPBOARD_LIST_OUTLINE' = 'clipboard-list-outline',
  'CLIPBOARD_MINUS' = 'clipboard-minus',
  'CLIPBOARD_MINUS_OUTLINE' = 'clipboard-minus-outline',
  'CLIPBOARD_MULTIPLE' = 'clipboard-multiple',
  'CLIPBOARD_MULTIPLE_OUTLINE' = 'clipboard-multiple-outline',
  'CLIPBOARD_OFF' = 'clipboard-off',
  'CLIPBOARD_OFF_OUTLINE' = 'clipboard-off-outline',
  'CLIPBOARD_OUTLINE' = 'clipboard-outline',
  'CLIPBOARD_PLAY' = 'clipboard-play',
  'CLIPBOARD_PLAY_MULTIPLE' = 'clipboard-play-multiple',
  'CLIPBOARD_PLAY_MULTIPLE_OUTLINE' = 'clipboard-play-multiple-outline',
  'CLIPBOARD_PLAY_OUTLINE' = 'clipboard-play-outline',
  'CLIPBOARD_PLUS' = 'clipboard-plus',
  'CLIPBOARD_ADD' = 'clipboard-add',
  'CLIPBOARD_PLUS_OUTLINE' = 'clipboard-plus-outline',
  'CLIPBOARD_PULSE' = 'clipboard-pulse',
  'CLIPBOARD_VITALS' = 'clipboard-vitals',
  'CLIPBOARD_PULSE_OUTLINE' = 'clipboard-pulse-outline',
  'CLIPBOARD_VITALS_OUTLINE' = 'clipboard-vitals-outline',
  'CLIPBOARD_REMOVE' = 'clipboard-remove',
  'CLIPBOARD_REMOVE_OUTLINE' = 'clipboard-remove-outline',
  'CLIPBOARD_SEARCH' = 'clipboard-search',
  'CLIPBOARD_SEARCH_OUTLINE' = 'clipboard-search-outline',
  'CLIPBOARD_TEXT' = 'clipboard-text',
  'ASSIGNMENT' = 'assignment',
  'CLIPBOARD_TEXT_CLOCK' = 'clipboard-text-clock',
  'CLIPBOARD_TEXT_DATE' = 'clipboard-text-date',
  'CLIPBOARD_TEXT_TIME' = 'clipboard-text-time',
  'CLIPBOARD_TEXT_HISTORY' = 'clipboard-text-history',
  'CLIPBOARD_TEXT_CLOCK_OUTLINE' = 'clipboard-text-clock-outline',
  'CLIPBOARD_TEXT_DATE_OUTLINE' = 'clipboard-text-date-outline',
  'CLIPBOARD_TEXT_TIME_OUTLINE' = 'clipboard-text-time-outline',
  'CLIPBOARD_TEXT_HISTORY_OUTLINE' = 'clipboard-text-history-outline',
  'CLIPBOARD_TEXT_MULTIPLE' = 'clipboard-text-multiple',
  'CLIPBOARD_TEXT_MULTIPLE_OUTLINE' = 'clipboard-text-multiple-outline',
  'CLIPBOARD_TEXT_OFF' = 'clipboard-text-off',
  'CLIPBOARD_TEXT_OFF_OUTLINE' = 'clipboard-text-off-outline',
  'CLIPBOARD_TEXT_OUTLINE' = 'clipboard-text-outline',
  'CLIPBOARD_TEXT_PLAY' = 'clipboard-text-play',
  'CLIPBOARD_TEXT_PLAY_OUTLINE' = 'clipboard-text-play-outline',
  'CLIPBOARD_TEXT_SEARCH' = 'clipboard-text-search',
  'CLIPBOARD_TEXT_SEARCH_OUTLINE' = 'clipboard-text-search-outline',
  'CLIPPY' = 'clippy',
  'CLOCK' = 'clock',
  'WATCH_LATER' = 'watch-later',
  'CLOCK_ALERT' = 'clock-alert',
  'CLOCK_WARNING' = 'clock-warning',
  'CLOCK_ALERT_OUTLINE' = 'clock-alert-outline',
  'CLOCK_CHECK' = 'clock-check',
  'CLOCK_CHECK_OUTLINE' = 'clock-check-outline',
  'CLOCK_DIGITAL' = 'clock-digital',
  'CLOCK_EDIT' = 'clock-edit',
  'CLOCK_EDIT_OUTLINE' = 'clock-edit-outline',
  'CLOCK_END' = 'clock-end',
  'CLOCK_FAST' = 'clock-fast',
  'CLOCK_IN' = 'clock-in',
  'CLOCK_MINUS' = 'clock-minus',
  'CLOCK_MINUS_OUTLINE' = 'clock-minus-outline',
  'CLOCK_OUT' = 'clock-out',
  'CLOCK_OUTLINE' = 'clock-outline',
  'ACCESS_TIME' = 'access-time',
  'QUERY_BUILDER' = 'query-builder',
  'SCHEDULE' = 'schedule',
  'CLOCK_PLUS' = 'clock-plus',
  'CLOCK_PLUS_OUTLINE' = 'clock-plus-outline',
  'CLOCK_REMOVE' = 'clock-remove',
  'CLOCK_REMOVE_OUTLINE' = 'clock-remove-outline',
  'CLOCK_START' = 'clock-start',
  'CLOCK_TIME_EIGHT' = 'clock-time-eight',
  'CLOCK_TIME_EIGHT_OUTLINE' = 'clock-time-eight-outline',
  'CLOCK_TIME_ELEVEN' = 'clock-time-eleven',
  'CLOCK_TIME_ELEVEN_OUTLINE' = 'clock-time-eleven-outline',
  'CLOCK_TIME_FIVE' = 'clock-time-five',
  'CLOCK_TIME_FIVE_OUTLINE' = 'clock-time-five-outline',
  'CLOCK_TIME_FOUR' = 'clock-time-four',
  'CLOCK_TIME_FOUR_OUTLINE' = 'clock-time-four-outline',
  'CLOCK_TIME_NINE' = 'clock-time-nine',
  'CLOCK_TIME_NINE_OUTLINE' = 'clock-time-nine-outline',
  'CLOCK_TIME_ONE' = 'clock-time-one',
  'CLOCK_TIME_ONE_OUTLINE' = 'clock-time-one-outline',
  'CLOCK_TIME_SEVEN' = 'clock-time-seven',
  'CLOCK_TIME_SEVEN_OUTLINE' = 'clock-time-seven-outline',
  'CLOCK_TIME_SIX' = 'clock-time-six',
  'CLOCK_TIME_SIX_OUTLINE' = 'clock-time-six-outline',
  'CLOCK_TIME_TEN' = 'clock-time-ten',
  'CLOCK_TIME_TEN_OUTLINE' = 'clock-time-ten-outline',
  'CLOCK_TIME_THREE' = 'clock-time-three',
  'CLOCK_TIME_THREE_OUTLINE' = 'clock-time-three-outline',
  'CLOCK_TIME_TWELVE' = 'clock-time-twelve',
  'CLOCK_TIME_TWELVE_OUTLINE' = 'clock-time-twelve-outline',
  'CLOCK_TIME_TWO' = 'clock-time-two',
  'CLOCK_TIME_TWO_OUTLINE' = 'clock-time-two-outline',
  'CLOSE' = 'close',
  'MULTIPLY' = 'multiply',
  'REMOVE' = 'remove',
  'CLOSE_BOX' = 'close-box',
  'MULTIPLY_BOX' = 'multiply-box',
  'CLEAR_BOX' = 'clear-box',
  'CANCEL_BOX' = 'cancel-box',
  'REMOVE_BOX' = 'remove-box',
  'CLOSE_BOX_MULTIPLE' = 'close-box-multiple',
  'CLOSE_BOXES' = 'close-boxes',
  'LIBRARY_REMOVE' = 'library-remove',
  'LIBRARY_CLOSE' = 'library-close',
  'MULTIPLY_BOXES' = 'multiply-boxes',
  'MULTIPLY_BOX_MULTIPLE' = 'multiply-box-multiple',
  'CANCEL_BOX_MULTIPLE' = 'cancel-box-multiple',
  'REMOVE_BOX_MULTIPLE' = 'remove-box-multiple',
  'CLOSE_BOX_MULTIPLE_OUTLINE' = 'close-box-multiple-outline',
  'CLOSE_BOXES_OUTLINE' = 'close-boxes-outline',
  'LIBRARY_REMOVE_OUTLINE' = 'library-remove-outline',
  'LIBRARY_CLOSE_OUTLINE' = 'library-close-outline',
  'MULTIPLY_BOXES_OUTLINE' = 'multiply-boxes-outline',
  'MULTIPLY_BOX_MULTIPLE_OUTLINE' = 'multiply-box-multiple-outline',
  'CLOSE_BOX_OUTLINE' = 'close-box-outline',
  'MULTIPLY_BOX_OUTLINE' = 'multiply-box-outline',
  'CLEAR_BOX_OUTLINE' = 'clear-box-outline',
  'REMOVE_BOX_OUTLINE' = 'remove-box-outline',
  'CANCEL_BOX_OUTLINE' = 'cancel-box-outline',
  'CLOSE_CIRCLE' = 'close-circle',
  'REMOVE_CIRCLE' = 'remove-circle',
  'CANCEL_CIRCLE' = 'cancel-circle',
  'MULTIPLY_CIRCLE' = 'multiply-circle',
  'CLEAR_CIRCLE' = 'clear-circle',
  'CLOSE_CIRCLE_MULTIPLE' = 'close-circle-multiple',
  'REMOVE_CIRCLE_MULTIPLE' = 'remove-circle-multiple',
  'COINS_CLOSE' = 'coins-close',
  'COINS_REMOVE' = 'coins-remove',
  'CLEAR_CIRCLE_MULTIPLE' = 'clear-circle-multiple',
  'MULTIPLY_CIRCLE_MULTIPLE' = 'multiply-circle-multiple',
  'CLOSE_CIRCLE_MULTIPLE_OUTLINE' = 'close-circle-multiple-outline',
  'REMOVE_CIRCLE_MULTIPLE_OUTLINE' = 'remove-circle-multiple-outline',
  'COINS_CLOSE_OUTLINE' = 'coins-close-outline',
  'COINS_REMOVE_OUTLINE' = 'coins-remove-outline',
  'CANCEL_CIRCLE_MULTIPLE_OUTLINE' = 'cancel-circle-multiple-outline',
  'MULTIPLY_CIRCLE_MULTIPLE_OUTLINE' = 'multiply-circle-multiple-outline',
  'CLEAR_CIRCLE_MULTIPLE_OUTLINE' = 'clear-circle-multiple-outline',
  'CLOSE_CIRCLE_OUTLINE' = 'close-circle-outline',
  'HIGHLIGHT_OFF' = 'highlight-off',
  'MULTIPLY_CIRCLE_OUTLINE' = 'multiply-circle-outline',
  'REMOVE_CIRCLE_OUTLINE' = 'remove-circle-outline',
  'CLEAR_CIRCLE_OUTLINE' = 'clear-circle-outline',
  'CANCEL_CIRCLE_OUTLINE' = 'cancel-circle-outline',
  'CLOSE_NETWORK' = 'close-network',
  'REMOVE_NETWORK' = 'remove-network',
  'CANCEL_NETWORK' = 'cancel-network',
  'MULTIPLY_NETWORK' = 'multiply-network',
  'CLEAR_NETWORK' = 'clear-network',
  'CLOSE_NETWORK_OUTLINE' = 'close-network-outline',
  'REMOVE_NETWORK_OUTLINE' = 'remove-network-outline',
  'CANCEL_NETWORK_OUTLINE' = 'cancel-network-outline',
  'MULTIPLY_NETWORK_OUTLINE' = 'multiply-network-outline',
  'CLEAR_NETWORK_OUTLINE' = 'clear-network-outline',
  'CLOSE_OCTAGON' = 'close-octagon',
  'DANGEROUS' = 'dangerous',
  'MULTIPLY_OCTAGON' = 'multiply-octagon',
  'REMOVE_OCTAGON' = 'remove-octagon',
  'CANCEL_OCTAGON' = 'cancel-octagon',
  'CLEAR_OCTAGON' = 'clear-octagon',
  'STOP_REMOVE' = 'stop-remove',
  'CLOSE_OCTAGON_OUTLINE' = 'close-octagon-outline',
  'REMOVE_OCTAGON_OUTLINE' = 'remove-octagon-outline',
  'MULTIPLY_OCTAGON_OUTLINE' = 'multiply-octagon-outline',
  'CLEAR_OCTAGON_OUTLINE' = 'clear-octagon-outline',
  'CANCEL_OCTAGON_OUTLINE' = 'cancel-octagon-outline',
  'STOP_REMOVE_OUTLINE' = 'stop-remove-outline',
  'CLOSE_OUTLINE' = 'close-outline',
  'REMOVE_OUTLINE' = 'remove-outline',
  'CANCEL_OUTLINE' = 'cancel-outline',
  'MULTIPLY_OUTLINE' = 'multiply-outline',
  'CLOSE_THICK' = 'close-thick',
  'CLOSE_BOLD' = 'close-bold',
  'REMOVE_THICK' = 'remove-thick',
  'REMOVE_BOLD' = 'remove-bold',
  'MULTIPLY_THICK' = 'multiply-thick',
  'MULTIPLY_BOLD' = 'multiply-bold',
  'CLEAR_THICK' = 'clear-thick',
  'CLEAR_BOLD' = 'clear-bold',
  'CANCEL_THICK' = 'cancel-thick',
  'CANCEL_BOLD' = 'cancel-bold',
  'CLOSED_CAPTION' = 'closed-caption',
  'CC' = 'cc',
  'CLOSED_CAPTION_OUTLINE' = 'closed-caption-outline',
  'CC_OUTLINE' = 'cc-outline',
  'CLOUD' = 'cloud',
  'WB_CLOUDY' = 'wb-cloudy',
  'CLOUD_ALERT' = 'cloud-alert',
  'CLOUD_WARNING' = 'cloud-warning',
  'CLOUD_BRACES' = 'cloud-braces',
  'CLOUD_JSON' = 'cloud-json',
  'CLOUD_CHECK' = 'cloud-check',
  'CLOUD_DONE' = 'cloud-done',
  'CLOUD_TICK' = 'cloud-tick',
  'CLOUD_CHECK_OUTLINE' = 'cloud-check-outline',
  'CLOUD_CIRCLE' = 'cloud-circle',
  'CLOUD_DOWNLOAD' = 'cloud-download',
  'CLOUD_DOWNLOAD_OUTLINE' = 'cloud-download-outline',
  'CLOUD_LOCK' = 'cloud-lock',
  'CLOUD_LOCK_OUTLINE' = 'cloud-lock-outline',
  'CLOUD_OFF_OUTLINE' = 'cloud-off-outline',
  'CLOUD_OUTLINE' = 'cloud-outline',
  'CLOUD_QUEUE' = 'cloud-queue',
  'CLOUD_PRINT' = 'cloud-print',
  'CLOUD_PRINT_OUTLINE' = 'cloud-print-outline',
  'CLOUD_QUESTION' = 'cloud-question',
  'CLOUD_REFRESH' = 'cloud-refresh',
  'CLOUD_SEARCH' = 'cloud-search',
  'CLOUD_SEARCH_OUTLINE' = 'cloud-search-outline',
  'CLOUD_SYNC' = 'cloud-sync',
  'CLOUD_SYNC_OUTLINE' = 'cloud-sync-outline',
  'CLOUD_TAGS' = 'cloud-tags',
  'CLOUD_XML' = 'cloud-xml',
  'CLOUD_UPLOAD' = 'cloud-upload',
  'BACKUP' = 'backup',
  'CLOUD_UPLOAD_OUTLINE' = 'cloud-upload-outline',
  'BACKUP_OUTLINE' = 'backup-outline',
  'CLOVER' = 'clover',
  'LUCK' = 'luck',
  'COACH_LAMP' = 'coach-lamp',
  'COACH_LIGHT' = 'coach-light',
  'CARRIAGE_LAMP' = 'carriage-lamp',
  'CARRIAGE_LIGHT' = 'carriage-light',
  'COAT_RACK' = 'coat-rack',
  'FOYER' = 'foyer',
  'HALLWAY' = 'hallway',
  'ENTRY_ROOM' = 'entry-room',
  'CODE_ARRAY' = 'code-array',
  'CODE_BRACES' = 'code-braces',
  'SET' = 'set',
  'CODE_BRACES_BOX' = 'code-braces-box',
  'CODE_BRACKETS' = 'code-brackets',
  'SQUARE_BRACKETS' = 'square-brackets',
  'CODE_EQUAL' = 'code-equal',
  'CODE_GREATER_THAN' = 'code-greater-than',
  'CODE_GREATER_THAN_OR_EQUAL' = 'code-greater-than-or-equal',
  'CODE_JSON' = 'code-json',
  'CODE_LESS_THAN' = 'code-less-than',
  'CODE_LESS_THAN_OR_EQUAL' = 'code-less-than-or-equal',
  'CODE_NOT_EQUAL' = 'code-not-equal',
  'CODE_NOT_EQUAL_VARIANT' = 'code-not-equal-variant',
  'CODE_PARENTHESES' = 'code-parentheses',
  'CODE_PARENTHESES_BOX' = 'code-parentheses-box',
  'CODE_STRING' = 'code-string',
  'CODE_TAGS' = 'code-tags',
  'CODE_TAGS_CHECK' = 'code-tags-check',
  'CODE_TAGS_TICK' = 'code-tags-tick',
  'CODEPEN' = 'codepen',
  'COFFEE' = 'coffee',
  'TEA' = 'tea',
  'CUP' = 'cup',
  'FREE_BREAKFAST' = 'free-breakfast',
  'LOCAL_CAFE' = 'local-cafe',
  'COFFEE_MAKER' = 'coffee-maker',
  'ESPRESSO_MAKER' = 'espresso-maker',
  'COFFEE_MACHINE' = 'coffee-machine',
  'ESPRESSO_MACHINE' = 'espresso-machine',
  'COFFEE_MAKER_CHECK' = 'coffee-maker-check',
  'COFFEE_MAKER_DONE' = 'coffee-maker-done',
  'COFFEE_MAKER_COMPLETE' = 'coffee-maker-complete',
  'COFFEE_MAKER_CHECK_OUTLINE' = 'coffee-maker-check-outline',
  'COFFEE_MAKER_COMPLETE_OUTLINE' = 'coffee-maker-complete-outline',
  'COFFEE_MAKER_DONE_OUTLINE' = 'coffee-maker-done-outline',
  'COFFEE_MAKER_OUTLINE' = 'coffee-maker-outline',
  'COFFEE_OFF' = 'coffee-off',
  'DRINK_OFF' = 'drink-off',
  'TEA_OFF' = 'tea-off',
  'CUP_OFF' = 'cup-off',
  'FREE_BREAKFAST_OFF' = 'free-breakfast-off',
  'LOCAL_CAFE_OFF' = 'local-cafe-off',
  'COFFEE_OFF_OUTLINE' = 'coffee-off-outline',
  'DRINK_OFF_OUTLINE' = 'drink-off-outline',
  'CUP_OFF_OUTLINE' = 'cup-off-outline',
  'TEA_OFF_OUTLINE' = 'tea-off-outline',
  'FREE_BREAKFAST_OFF_OUTLINE' = 'free-breakfast-off-outline',
  'LOCAL_CAFE_OFF_OUTLINE' = 'local-cafe-off-outline',
  'COFFEE_OUTLINE' = 'coffee-outline',
  'TEA_OUTLINE' = 'tea-outline',
  'CUP_OUTLINE' = 'cup-outline',
  'FREE_BREAKFAST_OUTLINE' = 'free-breakfast-outline',
  'LOCAL_CAFE_OUTLINE' = 'local-cafe-outline',
  'COFFEE_TO_GO' = 'coffee-to-go',
  'TEA_TO_GO' = 'tea-to-go',
  'DRINK_TO_GO' = 'drink-to-go',
  'CUP_TO_GO' = 'cup-to-go',
  'FREE_BREAKFAST_TO_GO' = 'free-breakfast-to-go',
  'LOCAL_CAFE_TO_GO' = 'local-cafe-to-go',
  'COFFEE_TO_GO_OUTLINE' = 'coffee-to-go-outline',
  'TEA_TO_GO_OUTLINE' = 'tea-to-go-outline',
  'CUP_TO_GO_OUTLINE' = 'cup-to-go-outline',
  'DRINK_TO_GO_OUTLINE' = 'drink-to-go-outline',
  'FREE_BREAKFAST_TO_GO_OUTLINE' = 'free-breakfast-to-go-outline',
  'LOCAL_CAFE_TO_GO_OUTLINE' = 'local-cafe-to-go-outline',
  'COFFIN' = 'coffin',
  'DEATH' = 'death',
  'DEAD' = 'dead',
  'COG' = 'cog',
  'SETTINGS' = 'settings',
  'GEAR' = 'gear',
  'COG_BOX' = 'cog-box',
  'GEAR_BOX' = 'gear-box',
  'SETTINGS_APPLICATIONS' = 'settings-applications',
  'SETTINGS_BOX' = 'settings-box',
  'COG_CLOCKWISE' = 'cog-clockwise',
  'COG_COUNTERCLOCKWISE' = 'cog-counterclockwise',
  'COG_OFF' = 'cog-off',
  'SETTINGS_OFF' = 'settings-off',
  'COG_OFF_OUTLINE' = 'cog-off-outline',
  'SETTINGS_OFF_OUTLINE' = 'settings-off-outline',
  'COG_OUTLINE' = 'cog-outline',
  'GEAR_OUTLINE' = 'gear-outline',
  'SETTINGS_OUTLINE' = 'settings-outline',
  'COG_PAUSE' = 'cog-pause',
  'SETTINGS_PAUSE' = 'settings-pause',
  'GEAR_PAUSE' = 'gear-pause',
  'COG_PAUSE_OUTLINE' = 'cog-pause-outline',
  'SETTINGS_PAUSE_OUTLINE' = 'settings-pause-outline',
  'GEAR_PAUSE_OUTLINE' = 'gear-pause-outline',
  'COG_PLAY' = 'cog-play',
  'SETTINGS_PLAY' = 'settings-play',
  'GEAR_PLAY' = 'gear-play',
  'COG_PLAY_OUTLINE' = 'cog-play-outline',
  'SETTINGS_PLAY_OUTLINE' = 'settings-play-outline',
  'GEAR_PLAY_OUTLINE' = 'gear-play-outline',
  'COG_REFRESH' = 'cog-refresh',
  'SETTINGS_REFRESH' = 'settings-refresh',
  'COG_REFRESH_OUTLINE' = 'cog-refresh-outline',
  'SETTINGS_REFRESH_OUTLINE' = 'settings-refresh-outline',
  'COG_STOP' = 'cog-stop',
  'SETTINGS_STOP' = 'settings-stop',
  'GEAR_STOP' = 'gear-stop',
  'COG_STOP_OUTLINE' = 'cog-stop-outline',
  'SETTINGS_STOP_OUTLINE' = 'settings-stop-outline',
  'GEAR_STOP_OUTLINE' = 'gear-stop-outline',
  'COG_SYNC' = 'cog-sync',
  'SETTINGS_SYNC' = 'settings-sync',
  'COG_SYNC_OUTLINE' = 'cog-sync-outline',
  'SETTINGS_SYNC_OUTLINE' = 'settings-sync-outline',
  'COG_TRANSFER' = 'cog-transfer',
  'SETTINGS_TRANSFER' = 'settings-transfer',
  'COG_TRANSFER_OUTLINE' = 'cog-transfer-outline',
  'SETTINGS_TRANSFER_OUTLINE' = 'settings-transfer-outline',
  'COGS' = 'cogs',
  'MANUFACTURING' = 'manufacturing',
  'COLLAGE' = 'collage',
  'AUTO_AWESOME_MOSAIC' = 'auto-awesome-mosaic',
  'COLLAPSE_ALL' = 'collapse-all',
  'ANIMATION_MINUS' = 'animation-minus',
  'COLLAPSE_ALL_OUTLINE' = 'collapse-all-outline',
  'ANIMATION_MINUS_OUTLINE' = 'animation-minus-outline',
  'COLOR_HELPER' = 'color-helper',
  'COLOUR_HELPER' = 'colour-helper',
  'COMMA' = 'comma',
  'COMMA_BOX' = 'comma-box',
  'COMMA_BOX_OUTLINE' = 'comma-box-outline',
  'COMMA_CIRCLE' = 'comma-circle',
  'COMMA_CIRCLE_OUTLINE' = 'comma-circle-outline',
  'COMMENT' = 'comment',
  'COMMENT_ACCOUNT' = 'comment-account',
  'COMMENT_USER' = 'comment-user',
  'COMMENT_PERSON' = 'comment-person',
  'COMMENT_ACCOUNT_OUTLINE' = 'comment-account-outline',
  'COMMENT_USER_OUTLINE' = 'comment-user-outline',
  'COMMENT_PERSON_OUTLINE' = 'comment-person-outline',
  'COMMENT_ALERT' = 'comment-alert',
  'COMMENT_WARNING' = 'comment-warning',
  'COMMENT_ALERT_OUTLINE' = 'comment-alert-outline',
  'COMMENT_WARNING_OUTLINE' = 'comment-warning-outline',
  'COMMENT_ARROW_LEFT' = 'comment-arrow-left',
  'COMMENT_PREVIOUS' = 'comment-previous',
  'COMMENT_ARROW_LEFT_OUTLINE' = 'comment-arrow-left-outline',
  'COMMENT_PREVIOUS_OUTLINE' = 'comment-previous-outline',
  'COMMENT_ARROW_RIGHT' = 'comment-arrow-right',
  'COMMENT_NEXT' = 'comment-next',
  'COMMENT_ARROW_RIGHT_OUTLINE' = 'comment-arrow-right-outline',
  'COMMENT_NEXT_OUTLINE' = 'comment-next-outline',
  'COMMENT_BOOKMARK' = 'comment-bookmark',
  'COMMENT_BOOKMARK_OUTLINE' = 'comment-bookmark-outline',
  'COMMENT_CHECK' = 'comment-check',
  'COMMENT_TICK' = 'comment-tick',
  'COMMENT_CHECK_OUTLINE' = 'comment-check-outline',
  'COMMENT_TICK_OUTLINE' = 'comment-tick-outline',
  'COMMENT_EDIT' = 'comment-edit',
  'COMMENT_EDIT_OUTLINE' = 'comment-edit-outline',
  'COMMENT_EYE' = 'comment-eye',
  'COMMENT_EYE_OUTLINE' = 'comment-eye-outline',
  'COMMENT_FLASH' = 'comment-flash',
  'COMMENT_QUICK' = 'comment-quick',
  'COMMENT_FLASH_OUTLINE' = 'comment-flash-outline',
  'COMMENT_QUICK_OUTLINE' = 'comment-quick-outline',
  'COMMENT_MINUS' = 'comment-minus',
  'COMMENT_MINUS_OUTLINE' = 'comment-minus-outline',
  'COMMENT_MULTIPLE' = 'comment-multiple',
  'COMMENTS' = 'comments',
  'COMMENT_MULTIPLE_OUTLINE' = 'comment-multiple-outline',
  'COMMENTS_OUTLINE' = 'comments-outline',
  'COMMENT_OFF' = 'comment-off',
  'COMMENT_OFF_OUTLINE' = 'comment-off-outline',
  'COMMENT_OUTLINE' = 'comment-outline',
  'COMMENT_PLUS' = 'comment-plus',
  'COMMENT_ADD' = 'comment-add',
  'COMMENT_PLUS_OUTLINE' = 'comment-plus-outline',
  'COMMENT_ADD_OUTLINE' = 'comment-add-outline',
  'COMMENT_PROCESSING' = 'comment-processing',
  'COMMENT_PROCESSING_OUTLINE' = 'comment-processing-outline',
  'COMMENT_QUESTION' = 'comment-question',
  'COMMENT_QUESTION_OUTLINE' = 'comment-question-outline',
  'COMMENT_QUOTE' = 'comment-quote',
  'FEEDBACK' = 'feedback',
  'COMMENT_QUOTE_OUTLINE' = 'comment-quote-outline',
  'FEEDBACK_OUTLINE' = 'feedback-outline',
  'COMMENT_REMOVE' = 'comment-remove',
  'COMMENT_REMOVE_OUTLINE' = 'comment-remove-outline',
  'COMMENT_SEARCH' = 'comment-search',
  'COMMENT_SEARCH_OUTLINE' = 'comment-search-outline',
  'COMMENT_TEXT' = 'comment-text',
  'COMMENT_TEXT_MULTIPLE' = 'comment-text-multiple',
  'COMMENTS_TEXT' = 'comments-text',
  'COMMENT_TEXT_MULTIPLE_OUTLINE' = 'comment-text-multiple-outline',
  'COMMENTS_TEXT_OUTLINE' = 'comments-text-outline',
  'COMMENT_TEXT_OUTLINE' = 'comment-text-outline',
  'COMPARE' = 'compare',
  'THEME_LIGHT_DARK' = 'theme-light-dark',
  'COMPARE_HORIZONTAL' = 'compare-horizontal',
  'COMPARE_REMOVE' = 'compare-remove',
  'COMPARE_VERTICAL' = 'compare-vertical',
  'COMPASS' = 'compass',
  'EXPLORE' = 'explore',
  'COMPASS_OFF' = 'compass-off',
  'COMPASS_OFF_OUTLINE' = 'compass-off-outline',
  'COMPASS_OUTLINE' = 'compass-outline',
  'COMPASS_ROSE' = 'compass-rose',
  'CONE' = 'cone',
  'CONE_OFF' = 'cone-off',
  'CONNECTION' = 'connection',
  'PLUG' = 'plug',
  'CONSOLE' = 'console',
  'TERMINAL' = 'terminal',
  'CONSOLE_LINE' = 'console-line',
  'TERMINAL_LINE' = 'terminal-line',
  'CONSOLE_NETWORK' = 'console-network',
  'TERMINAL_NETWORK' = 'terminal-network',
  'CONSOLE_NETWORK_OUTLINE' = 'console-network-outline',
  'TERMINAL_NETWORK_OUTLINE' = 'terminal-network-outline',
  'CONSOLIDATE' = 'consolidate',
  'CONTACTLESS_PAYMENT' = 'contactless-payment',
  'CONTACTLESS_PAYMENT_CIRCLE' = 'contactless-payment-circle',
  'CONTACTLESS_PAYMENT_CIRCLE_OUTLINE' = 'contactless-payment-circle-outline',
  'CONTACTS' = 'contacts',
  'CONTACTS_OUTLINE' = 'contacts-outline',
  'CONTAIN' = 'contain',
  'CONTAIN_END' = 'contain-end',
  'CONTAIN_START' = 'contain-start',
  'CONTENT_COPY' = 'content-copy',
  'CONTENT_CUT' = 'content-cut',
  'SCISSORS' = 'scissors',
  'CLIP' = 'clip',
  'CONTENT_DUPLICATE' = 'content-duplicate',
  'CONTENT_PASTE' = 'content-paste',
  'CONTENT_SAVE' = 'content-save',
  'FLOPPY_DISC' = 'floppy-disc',
  'FLOPPY_DISK' = 'floppy-disk',
  'CONTENT_SAVE_ALERT' = 'content-save-alert',
  'FLOPPY_DISC_ALERT' = 'floppy-disc-alert',
  'CONTENT_SAVE_ALERT_OUTLINE' = 'content-save-alert-outline',
  'FLOPPY_DISC_ALERT_OUTLINE' = 'floppy-disc-alert-outline',
  'CONTENT_SAVE_ALL' = 'content-save-all',
  'FLOPPY_DISC_MULTIPLE' = 'floppy-disc-multiple',
  'CONTENT_SAVE_ALL_OUTLINE' = 'content-save-all-outline',
  'FLOPPY_DISC_MULTIPLE_OUTLINE' = 'floppy-disc-multiple-outline',
  'CONTENT_SAVE_CHECK' = 'content-save-check',
  'CONTENT_SAVE_CHECK_OUTLINE' = 'content-save-check-outline',
  'CONTENT_SAVE_COG' = 'content-save-cog',
  'FLOPPY_DISC_COG' = 'floppy-disc-cog',
  'CONTENT_SAVE_COG_OUTLINE' = 'content-save-cog-outline',
  'FLOPPY_DISC_COG_OUTLINE' = 'floppy-disc-cog-outline',
  'CONTENT_SAVE_EDIT' = 'content-save-edit',
  'FLOPPY_DISC_EDIT' = 'floppy-disc-edit',
  'CONTENT_SAVE_EDIT_OUTLINE' = 'content-save-edit-outline',
  'FLOPPY_DISC_EDIT_OUTLINE' = 'floppy-disc-edit-outline',
  'CONTENT_SAVE_MOVE' = 'content-save-move',
  'FLOPPY_DISC_MOVE' = 'floppy-disc-move',
  'CONTENT_SAVE_MOVE_OUTLINE' = 'content-save-move-outline',
  'FLOPPY_DISC_MOVE_OUTLINE' = 'floppy-disc-move-outline',
  'CONTENT_SAVE_OFF' = 'content-save-off',
  'CONTENT_SAVE_OFF_OUTLINE' = 'content-save-off-outline',
  'CONTENT_SAVE_OUTLINE' = 'content-save-outline',
  'CONTENT_SAVE_SETTINGS' = 'content-save-settings',
  'FLOPPY_DISC_SETTINGS' = 'floppy-disc-settings',
  'CONTENT_SAVE_SETTINGS_OUTLINE' = 'content-save-settings-outline',
  'FLOPPY_DISC_SETTINGS_OUTLINE' = 'floppy-disc-settings-outline',
  'CONTRAST' = 'contrast',
  'CONTRAST_BOX' = 'contrast-box',
  'CONTRAST_CIRCLE' = 'contrast-circle',
  'CONTROLLER_CLASSIC' = 'controller-classic',
  'GAMEPAD_CLASSIC' = 'gamepad-classic',
  'CONTROLLER_CLASSIC_OUTLINE' = 'controller-classic-outline',
  'GAMEPAD_CLASSIC_OUTLINE' = 'gamepad-classic-outline',
  'COOKIE' = 'cookie',
  'BISCUIT' = 'biscuit',
  'COOKIE_ALERT' = 'cookie-alert',
  'BISCUIT_ALERT' = 'biscuit-alert',
  'COOKIE_ALERT_OUTLINE' = 'cookie-alert-outline',
  'BISCUIT_ALERT_OUTLINE' = 'biscuit-alert-outline',
  'COOKIE_CHECK' = 'cookie-check',
  'BISCUIT_CHECK' = 'biscuit-check',
  'COOKIE_CHECK_OUTLINE' = 'cookie-check-outline',
  'BISCUIT_CHECK_OUTLINE' = 'biscuit-check-outline',
  'COOKIE_CLOCK' = 'cookie-clock',
  'BISCUIT_CLOCK' = 'biscuit-clock',
  'COOKIE_CLOCK_OUTLINE' = 'cookie-clock-outline',
  'BISCUIT_CLOCK_OUTLINE' = 'biscuit-clock-outline',
  'COOKIE_COG' = 'cookie-cog',
  'BISCUIT_COG' = 'biscuit-cog',
  'COOKIE_COG_OUTLINE' = 'cookie-cog-outline',
  'BISCUIT_COG_OUTLINE' = 'biscuit-cog-outline',
  'COOKIE_EDIT' = 'cookie-edit',
  'BISCUIT_EDIT' = 'biscuit-edit',
  'COOKIE_EDIT_OUTLINE' = 'cookie-edit-outline',
  'BISCUIT_EDIT_OUTLINE' = 'biscuit-edit-outline',
  'COOKIE_LOCK' = 'cookie-lock',
  'BISCUIT_LOCK' = 'biscuit-lock',
  'COOKIE_LOCK_OUTLINE' = 'cookie-lock-outline',
  'BISCUIT_LOCK_OUTLINE' = 'biscuit-lock-outline',
  'COOKIE_MINUS' = 'cookie-minus',
  'BISCUIT_MINUS' = 'biscuit-minus',
  'COOKIE_MINUS_OUTLINE' = 'cookie-minus-outline',
  'BISCUIT_MINUS_OUTLINE' = 'biscuit-minus-outline',
  'COOKIE_OFF' = 'cookie-off',
  'BISCUIT_OFF' = 'biscuit-off',
  'COOKIE_OFF_OUTLINE' = 'cookie-off-outline',
  'BISCUIT_OFF_OUTLINE' = 'biscuit-off-outline',
  'COOKIE_OUTLINE' = 'cookie-outline',
  'BISCUIT_OUTLINE' = 'biscuit-outline',
  'COOKIE_PLUS' = 'cookie-plus',
  'BISCUIT_PLUS' = 'biscuit-plus',
  'COOKIE_PLUS_OUTLINE' = 'cookie-plus-outline',
  'BISCUIT_PLUS_OUTLINE' = 'biscuit-plus-outline',
  'COOKIE_REFRESH' = 'cookie-refresh',
  'BISCUIT_REFRESH' = 'biscuit-refresh',
  'COOKIE_REFRESH_OUTLINE' = 'cookie-refresh-outline',
  'BISCUIT_REFRESH_OUTLINE' = 'biscuit-refresh-outline',
  'COOKIE_REMOVE' = 'cookie-remove',
  'BISCUIT_REMOVE' = 'biscuit-remove',
  'COOKIE_REMOVE_OUTLINE' = 'cookie-remove-outline',
  'BISCUIT_REMOVE_OUTLINE' = 'biscuit-remove-outline',
  'COOKIE_SETTINGS' = 'cookie-settings',
  'BISCUIT_SETTINGS' = 'biscuit-settings',
  'COOKIE_CRUMBS' = 'cookie-crumbs',
  'BISCUIT_CRUMBS' = 'biscuit-crumbs',
  'COOKIE_SETTINGS_OUTLINE' = 'cookie-settings-outline',
  'BISCUIT_SETTINGS_OUTLINE' = 'biscuit-settings-outline',
  'COOKIE_CRUMBS_OUTLINE' = 'cookie-crumbs-outline',
  'BISCUIT_CRUMBS_OUTLINE' = 'biscuit-crumbs-outline',
  'COOLANT_TEMPERATURE' = 'coolant-temperature',
  'COPYLEFT' = 'copyleft',
  'COPYRIGHT' = 'copyright',
  'CORDOVA' = 'cordova',
  'CORN' = 'corn',
  'CORN_OFF' = 'corn-off',
  'COSINE_WAVE' = 'cosine-wave',
  'FREQUENCY' = 'frequency',
  'AMPLITUDE' = 'amplitude',
  'COUNTER' = 'counter',
  'SCORE' = 'score',
  'NUMBERS' = 'numbers',
  'ODOMETER' = 'odometer',
  'COUNTERTOP' = 'countertop',
  'KITCHEN_COUNTER' = 'kitchen-counter',
  'SINK' = 'sink',
  'COUNTERTOP_OUTLINE' = 'countertop-outline',
  'KITCHEN_COUNTER_OUTLINE' = 'kitchen-counter-outline',
  'SINK_OUTLINE' = 'sink-outline',
  'COW' = 'cow',
  'EMOJI_COW' = 'emoji-cow',
  'EMOTICON_COW' = 'emoticon-cow',
  'COW_OFF' = 'cow-off',
  'DAIRY_OFF' = 'dairy-off',
  'DAIRY_FREE' = 'dairy-free',
  'CPU32BIT' = 'cpu32bit',
  'CPU64BIT' = 'cpu64bit',
  'CRADLE' = 'cradle',
  'CRIB' = 'crib',
  'BASSINET' = 'bassinet',
  'NURSERY' = 'nursery',
  'BABY_ROOM' = 'baby-room',
  'CRADLE_OUTLINE' = 'cradle-outline',
  'NURSERY_OUTLINE' = 'nursery-outline',
  'BABY_ROOM_OUTLINE' = 'baby-room-outline',
  'CRANE' = 'crane',
  'CREATION' = 'creation',
  'AUTO_AWESOME' = 'auto-awesome',
  'CREATIVE_COMMONS' = 'creative-commons',
  'CREDIT_CARD' = 'credit-card',
  'CREDIT_CARD_CHECK' = 'credit-card-check',
  'CREDIT_CARD_CHECK_OUTLINE' = 'credit-card-check-outline',
  'CREDIT_CARD_CHIP' = 'credit-card-chip',
  'CREDIT_CARD_ICC_CHIP' = 'credit-card-icc-chip',
  'CREDIT_CARD_CHIP_OUTLINE' = 'credit-card-chip-outline',
  'CREDIT_CARD_ICC_CHIP_OUTLINE' = 'credit-card-icc-chip-outline',
  'CREDIT_CARD_CLOCK' = 'credit-card-clock',
  'CREDIT_CARD_CLOCK_OUTLINE' = 'credit-card-clock-outline',
  'CREDIT_CARD_EDIT' = 'credit-card-edit',
  'CREDIT_CARD_EDIT_OUTLINE' = 'credit-card-edit-outline',
  'CREDIT_CARD_FAST' = 'credit-card-fast',
  'CREDIT_CARD_SWIPE' = 'credit-card-swipe',
  'CREDIT_CARD_FAST_OUTLINE' = 'credit-card-fast-outline',
  'CREDIT_CARD_SWIPE_OUTLINE' = 'credit-card-swipe-outline',
  'CREDIT_CARD_LOCK' = 'credit-card-lock',
  'CREDIT_CARD_LOCK_OUTLINE' = 'credit-card-lock-outline',
  'CREDIT_CARD_MARKER' = 'credit-card-marker',
  'CREDIT_CARD_LOCATION' = 'credit-card-location',
  'PAYMENT_ON_DELIVERY' = 'payment-on-delivery',
  'CREDIT_CARD_MARKER_OUTLINE' = 'credit-card-marker-outline',
  'PAYMENT_ON_DELIVERY_OUTLINE' = 'payment-on-delivery-outline',
  'CREDIT_CARD_LOCATION_OUTLINE' = 'credit-card-location-outline',
  'CREDIT_CARD_MINUS' = 'credit-card-minus',
  'CREDIT_CARD_MINUS_OUTLINE' = 'credit-card-minus-outline',
  'CREDIT_CARD_MULTIPLE' = 'credit-card-multiple',
  'CREDIT_CARD_MULTIPLE_OUTLINE' = 'credit-card-multiple-outline',
  'CREDIT_CARDS' = 'credit-cards',
  'CREDIT_CARD_OFF' = 'credit-card-off',
  'CREDIT_CARD_OFF_OUTLINE' = 'credit-card-off-outline',
  'CREDIT_CARD_OUTLINE' = 'credit-card-outline',
  'PAYMENT' = 'payment',
  'CREDIT_CARD_PLUS' = 'credit-card-plus',
  'CREDIT_CARD_PLUS_OUTLINE' = 'credit-card-plus-outline',
  'CREDIT_CARD_ADD' = 'credit-card-add',
  'CREDIT_CARD_REFRESH' = 'credit-card-refresh',
  'CREDIT_CARD_REFRESH_OUTLINE' = 'credit-card-refresh-outline',
  'CREDIT_CARD_REFUND' = 'credit-card-refund',
  'CREDIT_CARD_REFUND_OUTLINE' = 'credit-card-refund-outline',
  'CREDIT_CARD_REMOVE' = 'credit-card-remove',
  'CREDIT_CARD_REMOVE_OUTLINE' = 'credit-card-remove-outline',
  'CREDIT_CARD_SCAN' = 'credit-card-scan',
  'CREDIT_CARD_SCAN_OUTLINE' = 'credit-card-scan-outline',
  'CREDIT_CARD_SEARCH' = 'credit-card-search',
  'CREDIT_CARD_SEARCH_OUTLINE' = 'credit-card-search-outline',
  'CREDIT_CARD_SETTINGS' = 'credit-card-settings',
  'CREDIT_CARD_SETTINGS_OUTLINE' = 'credit-card-settings-outline',
  'PAYMENT_SETTINGS' = 'payment-settings',
  'CREDIT_CARD_SYNC' = 'credit-card-sync',
  'CREDIT_CARD_SYNC_OUTLINE' = 'credit-card-sync-outline',
  'CREDIT_CARD_WIRELESS' = 'credit-card-wireless',
  'CREDIT_CARD_WIRELESS_OFF' = 'credit-card-wireless-off',
  'CREDIT_CARD_WIRELESS_OFF_OUTLINE' = 'credit-card-wireless-off-outline',
  'CREDIT_CARD_WIRELESS_OUTLINE' = 'credit-card-wireless-outline',
  'CREDIT_CARD_CONTACTLESS' = 'credit-card-contactless',
  'CRICKET' = 'cricket',
  'CRICKET_BAT' = 'cricket-bat',
  'CROP' = 'crop',
  'CROP_FREE' = 'crop-free',
  'CROP_LANDSCAPE' = 'crop-landscape',
  'CROP_5_4' = 'crop-5-4',
  'CROP_PORTRAIT' = 'crop-portrait',
  'CROP_ROTATE' = 'crop-rotate',
  'CROP_SQUARE' = 'crop-square',
  'CROSS' = 'cross',
  'CHRISTIANITY' = 'christianity',
  'RELIGION_CHRISTIAN' = 'religion-christian',
  'CROSS_BOLNISI' = 'cross-bolnisi',
  'CROSS_CELTIC' = 'cross-celtic',
  'CROSS_OUTLINE' = 'cross-outline',
  'RELIGION_CHRISTIAN_OUTLINE' = 'religion-christian-outline',
  'CHRISTIANITY_OUTLINE' = 'christianity-outline',
  'CROSSHAIRS' = 'crosshairs',
  'GPS_NOT_FIXED' = 'gps-not-fixed',
  'LOCATION_SEARCHING' = 'location-searching',
  'CROSSHAIRS_GPS' = 'crosshairs-gps',
  'GPS_FIXED' = 'gps-fixed',
  'MY_LOCATION' = 'my-location',
  'CROSSHAIRS_OFF' = 'crosshairs-off',
  'CROSSHAIRS_QUESTION' = 'crosshairs-question',
  'CROSSHAIRS_UNKNOWN' = 'crosshairs-unknown',
  'GPS_UNKNOWN' = 'gps-unknown',
  'CROWD' = 'crowd',
  'FAMILY' = 'family',
  'CROWD_SOURCE' = 'crowd-source',
  'CROWDSOURCE' = 'crowdsource',
  'CROWN_CIRCLE' = 'crown-circle',
  'CHECKERS' = 'checkers',
  'CROWN_CIRCLE_OUTLINE' = 'crown-circle-outline',
  'CHECKERS_OUTLINE' = 'checkers-outline',
  'CROWN_OUTLINE' = 'crown-outline',
  'CRYENGINE' = 'cryengine',
  'CRYSTAL_BALL' = 'crystal-ball',
  'CUBE' = 'cube',
  'CUBE_OFF' = 'cube-off',
  'CUBE_OFF_OUTLINE' = 'cube-off-outline',
  'SUGAR_OFF' = 'sugar-off',
  'SUGAR_CUBE_OFF' = 'sugar-cube-off',
  'SUGAR_FREE' = 'sugar-free',
  'CUBE_OUTLINE' = 'cube-outline',
  'SUGAR' = 'sugar',
  'SUGAR_CUBE' = 'sugar-cube',
  'CUBE_SCAN' = 'cube-scan',
  'VIEW_IN_AR' = 'view-in-ar',
  'VIEW_IN_AUGMENTED_REALITY' = 'view-in-augmented-reality',
  'CUBE_SEND' = 'cube-send',
  'CUBE_UNFOLDED' = 'cube-unfolded',
  'GLASS' = 'glass',
  'GLASS_OFF' = 'glass-off',
  'GLASS_OFF_OUTLINE' = 'glass-off-outline',
  'GLASS_OUTLINE' = 'glass-outline',
  'CUP_WATER' = 'cup-water',
  'LOCAL_DRINK' = 'local-drink',
  'GLASS_WATER' = 'glass-water',
  'DRINK_WATER' = 'drink-water',
  'CUP_LIQUID' = 'cup-liquid',
  'GLASS_LIQUID' = 'glass-liquid',
  'CUPBOARD' = 'cupboard',
  'CUPBOARD_OUTLINE' = 'cupboard-outline',
  'CUPCAKE' = 'cupcake',
  'CURLING' = 'curling',
  'CURRENCY_BDT' = 'currency-bdt',
  'TAKA' = 'taka',
  'BANGLADESHI_TAKA' = 'bangladeshi-taka',
  'CURRENCY_BRL' = 'currency-brl',
  'BRAZILIAN_REAL' = 'brazilian-real',
  'CURRENCY_BTC' = 'currency-btc',
  'CURRENCY_CNY' = 'currency-cny',
  'YUAN' = 'yuan',
  'RENMINBI' = 'renminbi',
  'CURRENCY_ETH' = 'currency-eth',
  'ETHEREUM' = 'ethereum',
  'XI' = 'xi',
  'CURRENCY_EUR' = 'currency-eur',
  'EURO' = 'euro',
  'EURO_SYMBOL' = 'euro-symbol',
  'CURRENCY_EUR_OFF' = 'currency-eur-off',
  'CURRENCY_GBP' = 'currency-gbp',
  'POUND' = 'pound',
  'STERLING' = 'sterling',
  'CURRENCY_ILS' = 'currency-ils',
  'CURRENCY_INR' = 'currency-inr',
  'RUPEE' = 'rupee',
  'CURRENCY_JPY' = 'currency-jpy',
  'YEN' = 'yen',
  'CURRENCY_KRW' = 'currency-krw',
  'WON' = 'won',
  'CURRENCY_KZT' = 'currency-kzt',
  'KAZAKHSTANI_TENGE' = 'kazakhstani-tenge',
  'CURRENCY_MNT' = 'currency-mnt',
  'CURRENCY_MONGOLIAN_TUGRUG' = 'currency-mongolian-tugrug',
  'CURRENCY_NGN' = 'currency-ngn',
  'NAIRA' = 'naira',
  'CURRENCY_PHP' = 'currency-php',
  'PHILIPPINE_PESO' = 'philippine-peso',
  'CURRENCY_RIAL' = 'currency-rial',
  'CURRENCY_RIYAL' = 'currency-riyal',
  'CURRENCY_IRR' = 'currency-irr',
  'CURRENCY_OMR' = 'currency-omr',
  'CURRENCY_YER' = 'currency-yer',
  'CURRENCY_SAR' = 'currency-sar',
  'CURRENCY_RUB' = 'currency-rub',
  'RUBLE' = 'ruble',
  'CURRENCY_RUPEE' = 'currency-rupee',
  'CURRENCY_NPR' = 'currency-npr',
  'CURRENCY_PKR' = 'currency-pkr',
  'CURRENCY_LKR' = 'currency-lkr',
  'CURRENCY_SIGN' = 'currency-sign',
  'CURRENCY_SCARAB' = 'currency-scarab',
  'CURRENCY_TRY' = 'currency-try',
  'LIRA' = 'lira',
  'CURRENCY_TWD' = 'currency-twd',
  'NEW_TAIWAN_DOLLAR' = 'new-taiwan-dollar',
  'CURRENCY_USD' = 'currency-usd',
  'ATTACH_MONEY' = 'attach-money',
  'DOLLAR' = 'dollar',
  'CURRENCY_USD_OFF' = 'currency-usd-off',
  'MONEY_OFF' = 'money-off',
  'DOLLAR_OFF' = 'dollar-off',
  'CURRENT_AC' = 'current-ac',
  'ALTERNATING_CURRENT' = 'alternating-current',
  'CURRENT_DC' = 'current-dc',
  'DIRECT_CURRENT' = 'direct-current',
  'CURSOR_DEFAULT' = 'cursor-default',
  'CURSOR_DEFAULT_CLICK' = 'cursor-default-click',
  'CURSOR_DEFAULT_CLICK_OUTLINE' = 'cursor-default-click-outline',
  'CURSOR_DEFAULT_GESTURE' = 'cursor-default-gesture',
  'CURSOR_DEFAULT_GESTURE_OUTLINE' = 'cursor-default-gesture-outline',
  'CURSOR_DEFAULT_OUTLINE' = 'cursor-default-outline',
  'CURSOR_MOVE' = 'cursor-move',
  'CURSOR_POINTER' = 'cursor-pointer',
  'CURSOR_HAND' = 'cursor-hand',
  'CURSOR_TEXT' = 'cursor-text',
  'CURTAINS' = 'curtains',
  'DRAPES' = 'drapes',
  'WINDOW' = 'window',
  'CURTAINS_CLOSED' = 'curtains-closed',
  'DRAPES_CLOSED' = 'drapes-closed',
  'CYLINDER' = 'cylinder',
  'CYLINDER_OFF' = 'cylinder-off',
  'DANCE_BALLROOM' = 'dance-ballroom',
  'HUMAN_DANCE_BALLROOM' = 'human-dance-ballroom',
  'DANCE_POLE' = 'dance-pole',
  'KHO_KHO' = 'kho-kho',
  'HUMAN_DANCE_POLE' = 'human-dance-pole',
  'DATA_MATRIX' = 'data-matrix',
  'DATA_MATRIX_EDIT' = 'data-matrix-edit',
  'DATA_MATRIX_MINUS' = 'data-matrix-minus',
  'DATA_MATRIX_PLUS' = 'data-matrix-plus',
  'DATA_MATRIX_REMOVE' = 'data-matrix-remove',
  'DATA_MATRIX_SCAN' = 'data-matrix-scan',
  'DATABASE' = 'database',
  'STORAGE' = 'storage',
  'DATABASE_ALERT' = 'database-alert',
  'DATABASE_ALERT_OUTLINE' = 'database-alert-outline',
  'DATABASE_ARROW_DOWN' = 'database-arrow-down',
  'DATABASE_ARROW_DOWN_OUTLINE' = 'database-arrow-down-outline',
  'DATABASE_ARROW_LEFT' = 'database-arrow-left',
  'DATABASE_ARROW_LEFT_OUTLINE' = 'database-arrow-left-outline',
  'DATABASE_ARROW_RIGHT' = 'database-arrow-right',
  'DATABASE_ARROW_RIGHT_OUTLINE' = 'database-arrow-right-outline',
  'DATABASE_ARROW_UP' = 'database-arrow-up',
  'DATABASE_ARROW_UP_OUTLINE' = 'database-arrow-up-outline',
  'DATABASE_CHECK' = 'database-check',
  'DATABASE_TICK' = 'database-tick',
  'DATABASE_CHECK_OUTLINE' = 'database-check-outline',
  'DATABASE_CLOCK' = 'database-clock',
  'DATABASE_CLOCK_OUTLINE' = 'database-clock-outline',
  'DATABASE_COG' = 'database-cog',
  'DATABASE_COG_OUTLINE' = 'database-cog-outline',
  'DATABASE_EDIT' = 'database-edit',
  'DATABASE_EDIT_OUTLINE' = 'database-edit-outline',
  'DATABASE_EXPORT' = 'database-export',
  'DATABASE_EXPORT_OUTLINE' = 'database-export-outline',
  'DATABASE_EYE' = 'database-eye',
  'DATABASE_VIEW' = 'database-view',
  'DATABASE_EYE_OFF' = 'database-eye-off',
  'DATABASE_VIEW_OFF' = 'database-view-off',
  'DATABASE_EYE_OFF_OUTLINE' = 'database-eye-off-outline',
  'DATABASE_VIEW_OFF_OUTLINE' = 'database-view-off-outline',
  'DATABASE_EYE_OUTLINE' = 'database-eye-outline',
  'DATABASE_VIEW_OUTLINE' = 'database-view-outline',
  'DATABASE_IMPORT' = 'database-import',
  'DATABASE_IMPORT_OUTLINE' = 'database-import-outline',
  'DATABASE_LOCK' = 'database-lock',
  'DATABASE_LOCK_OUTLINE' = 'database-lock-outline',
  'DATABASE_MARKER' = 'database-marker',
  'DATABASE_LOCATION' = 'database-location',
  'DATABASE_MARKER_OUTLINE' = 'database-marker-outline',
  'DATABASE_LOCATION_OUTLINE' = 'database-location-outline',
  'DATABASE_MINUS' = 'database-minus',
  'DATABASE_MINUS_OUTLINE' = 'database-minus-outline',
  'DATABASE_OFF' = 'database-off',
  'DATABASE_OFF_OUTLINE' = 'database-off-outline',
  'DATABASE_OUTLINE' = 'database-outline',
  'DATABASE_PLUS' = 'database-plus',
  'DATABASE_ADD' = 'database-add',
  'DATABASE_PLUS_OUTLINE' = 'database-plus-outline',
  'DATABASE_REFRESH' = 'database-refresh',
  'DATABASE_REFRESH_OUTLINE' = 'database-refresh-outline',
  'DATABASE_REMOVE' = 'database-remove',
  'DATABASE_REMOVE_OUTLINE' = 'database-remove-outline',
  'DATABASE_SEARCH' = 'database-search',
  'SQL_QUERY' = 'sql-query',
  'DATABASE_SEARCH_OUTLINE' = 'database-search-outline',
  'DATABASE_SETTINGS' = 'database-settings',
  'DATABASE_SETTINGS_OUTLINE' = 'database-settings-outline',
  'DATABASE_SYNC' = 'database-sync',
  'DATABASE_SYNC_OUTLINE' = 'database-sync-outline',
  'DEATH_STAR' = 'death-star',
  'DEATH_STAR_VARIANT' = 'death-star-variant',
  'DEATHLY_HALLOWS' = 'deathly-hallows',
  'HARRY_POTTER' = 'harry-potter',
  'DEBIAN' = 'debian',
  'DEBUG_STEP_INTO' = 'debug-step-into',
  'DEBUG_STEP_OUT' = 'debug-step-out',
  'DEBUG_STEP_OVER' = 'debug-step-over',
  'JUMP' = 'jump',
  'DECAGRAM' = 'decagram',
  'STARBURST' = 'starburst',
  'DECAGRAM_OUTLINE' = 'decagram-outline',
  'STARBURST_OUTLINE' = 'starburst-outline',
  'DECIMAL' = 'decimal',
  'DECIMAL_COMMA' = 'decimal-comma',
  'DECIMAL_COMMA_DECREASE' = 'decimal-comma-decrease',
  'DECIMAL_COMMA_INCREASE' = 'decimal-comma-increase',
  'DECIMAL_DECREASE' = 'decimal-decrease',
  'DECIMAL_INCREASE' = 'decimal-increase',
  'DELETE' = 'delete',
  'TRASH' = 'trash',
  'BIN' = 'bin',
  'RUBBISH' = 'rubbish',
  'GARBAGE' = 'garbage',
  'RUBBISH_BIN' = 'rubbish-bin',
  'TRASH_CAN' = 'trash-can',
  'GARBAGE_CAN' = 'garbage-can',
  'DELETE_ALERT' = 'delete-alert',
  'DELETE_ALERT_OUTLINE' = 'delete-alert-outline',
  'DELETE_CIRCLE' = 'delete-circle',
  'TRASH_CIRCLE' = 'trash-circle',
  'BIN_CIRCLE' = 'bin-circle',
  'GARBAGE_CAN_CIRCLE' = 'garbage-can-circle',
  'GARBAGE_CIRCLE' = 'garbage-circle',
  'RUBBISH_BIN_CIRCLE' = 'rubbish-bin-circle',
  'RUBBISH_CIRCLE' = 'rubbish-circle',
  'TRASH_CAN_CIRCLE' = 'trash-can-circle',
  'DELETE_CIRCLE_OUTLINE' = 'delete-circle-outline',
  'BIN_CIRCLE_OUTLINE' = 'bin-circle-outline',
  'GARBAGE_CAN_CIRCLE_OUTLINE' = 'garbage-can-circle-outline',
  'GARBAGE_CIRCLE_OUTLINE' = 'garbage-circle-outline',
  'RUBBISH_BIN_CIRCLE_OUTLINE' = 'rubbish-bin-circle-outline',
  'RUBBISH_CIRCLE_OUTLINE' = 'rubbish-circle-outline',
  'TRASH_CAN_CIRCLE_OUTLINE' = 'trash-can-circle-outline',
  'TRASH_CIRCLE_OUTLINE' = 'trash-circle-outline',
  'DELETE_CLOCK' = 'delete-clock',
  'DELETE_CLOCK_OUTLINE' = 'delete-clock-outline',
  'DELETE_EMPTY' = 'delete-empty',
  'TRASH_EMPTY' = 'trash-empty',
  'BIN_EMPTY' = 'bin-empty',
  'RUBBISH_EMPTY' = 'rubbish-empty',
  'RUBBISH_BIN_EMPTY' = 'rubbish-bin-empty',
  'TRASH_CAN_EMPTY' = 'trash-can-empty',
  'GARBAGE_EMPTY' = 'garbage-empty',
  'GARBAGE_CAN_EMPTY' = 'garbage-can-empty',
  'DELETE_EMPTY_OUTLINE' = 'delete-empty-outline',
  'DELETE_FOREVER' = 'delete-forever',
  'DELETE_FOREVER_OUTLINE' = 'delete-forever-outline',
  'DELETE_OFF' = 'delete-off',
  'DELETE_OFF_OUTLINE' = 'delete-off-outline',
  'DELETE_OUTLINE' = 'delete-outline',
  'GARBAGE_OUTLINE' = 'garbage-outline',
  'BIN_OUTLINE' = 'bin-outline',
  'RUBBISH_OUTLINE' = 'rubbish-outline',
  'GARBAGE_CAN_OUTLINE' = 'garbage-can-outline',
  'RUBBISH_BIN_OUTLINE' = 'rubbish-bin-outline',
  'TRASH_OUTLINE' = 'trash-outline',
  'TRASH_CAN_OUTLINE' = 'trash-can-outline',
  'DELETE_RESTORE' = 'delete-restore',
  'TRASH_RESTORE' = 'trash-restore',
  'BIN_RESTORE' = 'bin-restore',
  'RESTORE_FROM_TRASH' = 'restore-from-trash',
  'DELETE_SWEEP' = 'delete-sweep',
  'DELETE_SWEEP_OUTLINE' = 'delete-sweep-outline',
  'DELETE_VARIANT' = 'delete-variant',
  'TRASH_VARIANT' = 'trash-variant',
  'BIN_VARIANT' = 'bin-variant',
  'CUP_ICE' = 'cup-ice',
  'DRINK_ICE' = 'drink-ice',
  'DELTA' = 'delta',
  'CHANGE_HISTORY' = 'change-history',
  'DESK_LAMP' = 'desk-lamp',
  'DESKPHONE' = 'deskphone',
  'DESKTOP_CLASSIC' = 'desktop-classic',
  'COMPUTER_CLASSIC' = 'computer-classic',
  'DESKTOP_MAC' = 'desktop-mac',
  'DESKTOP_MAC_DASHBOARD' = 'desktop-mac-dashboard',
  'DESKTOP_TOWER' = 'desktop-tower',
  'DESKTOP_TOWER_MONITOR' = 'desktop-tower-monitor',
  'DETAILS' = 'details',
  'DEV_TO' = 'dev-to',
  'DEVELOPER_BOARD' = 'developer-board',
  'DEVIANTART' = 'deviantart',
  'MONITOR' = 'monitor',
  'WATCH' = 'watch',
  'SMARTWATCH' = 'smartwatch',
  'TELEVISION' = 'television',
  'DHARMACHAKRA' = 'dharmachakra',
  'DHARMA_WHEEL' = 'dharma-wheel',
  'RELIGION_BUDDHIST' = 'religion-buddhist',
  'BUDDHISM' = 'buddhism',
  'DIABETES' = 'diabetes',
  'HAND_BLOOD' = 'hand-blood',
  'DIALPAD' = 'dialpad',
  'KEYPAD' = 'keypad',
  'DIAMETER' = 'diameter',
  'CIRCLE_DIAMETER' = 'circle-diameter',
  'SPHERE_DIAMETER' = 'sphere-diameter',
  'DIAMETER_OUTLINE' = 'diameter-outline',
  'CIRCLE_DIAMETER_OUTLINE' = 'circle-diameter-outline',
  'SPHERE_DIAMETER_OUTLINE' = 'sphere-diameter-outline',
  'DIAMETER_VARIANT' = 'diameter-variant',
  'CIRCLE_DIAMETER_VARIANT' = 'circle-diameter-variant',
  'SPHERE_DIAMETER_VARIANT' = 'sphere-diameter-variant',
  'DIAMOND' = 'diamond',
  'DIAMOND_OUTLINE' = 'diamond-outline',
  'DIAMOND_STONE' = 'diamond-stone',
  'JEWEL' = 'jewel',
  'DICE1' = 'dice1',
  'DICE1OUTLINE' = 'dice1outline',
  'DICE2' = 'dice2',
  'DICE2OUTLINE' = 'dice2outline',
  'DICE3' = 'dice3',
  'DICE3OUTLINE' = 'dice3outline',
  'DICE4' = 'dice4',
  'DICE4OUTLINE' = 'dice4outline',
  'DICE5' = 'dice5',
  'DICE5OUTLINE' = 'dice5outline',
  'DICE6' = 'dice6',
  'DICE6OUTLINE' = 'dice6outline',
  'DICE_D10' = 'dice-d10',
  'DICE_D10OUTLINE' = 'dice-d10outline',
  'DICE_D12' = 'dice-d12',
  'DICE_D12OUTLINE' = 'dice-d12outline',
  'DICE_D20' = 'dice-d20',
  'DICE_D20OUTLINE' = 'dice-d20outline',
  'DICE_D4' = 'dice-d4',
  'DICE_D4OUTLINE' = 'dice-d4outline',
  'DICE_D6' = 'dice-d6',
  'DICE_D6OUTLINE' = 'dice-d6outline',
  'DICE_D8' = 'dice-d8',
  'DICE_D8OUTLINE' = 'dice-d8outline',
  'DICE_MULTIPLE' = 'dice-multiple',
  'DIE_MULTIPLE' = 'die-multiple',
  'DICE_MULTIPLE_OUTLINE' = 'dice-multiple-outline',
  'DIGITAL_OCEAN' = 'digital-ocean',
  'DIP_SWITCH' = 'dip-switch',
  'DIRECTIONS' = 'directions',
  'DIRECTIONS_FORK' = 'directions-fork',
  'DISC' = 'disc',
  'CD_ROM' = 'cd-rom',
  'DVD' = 'dvd',
  'DISC_ALERT' = 'disc-alert',
  'DISC_FULL' = 'disc-full',
  'DISC_WARNING' = 'disc-warning',
  'DISC_PLAYER' = 'disc-player',
  'DISCORD' = 'discord',
  'DISHWASHER' = 'dishwasher',
  'DISHWASHER_ALERT' = 'dishwasher-alert',
  'DISHWASHER_OFF' = 'dishwasher-off',
  'DISQUS' = 'disqus',
  'DISTRIBUTE_HORIZONTAL_CENTER' = 'distribute-horizontal-center',
  'DISTRIBUTE_HORIZONTAL_LEFT' = 'distribute-horizontal-left',
  'DISTRIBUTE_HORIZONTAL_RIGHT' = 'distribute-horizontal-right',
  'DISTRIBUTE_VERTICAL_BOTTOM' = 'distribute-vertical-bottom',
  'DISTRIBUTE_VERTICAL_CENTER' = 'distribute-vertical-center',
  'DISTRIBUTE_VERTICAL_TOP' = 'distribute-vertical-top',
  'DIVERSIFY' = 'diversify',
  'DIVING' = 'diving',
  'SWIM_DIVE' = 'swim-dive',
  'HUMAN_DIVING' = 'human-diving',
  'DIVING_FLIPPERS' = 'diving-flippers',
  'DIVING_HELMET' = 'diving-helmet',
  'DIVING_SCUBA' = 'diving-scuba',
  'DIVING_SCUBA_FLAG' = 'diving-scuba-flag',
  'DIVING_SCUBA_TANK' = 'diving-scuba-tank',
  'DIVING_SCUBA_TANK_MULTIPLE' = 'diving-scuba-tank-multiple',
  'DIVING_SNORKEL' = 'diving-snorkel',
  'DIVISION' = 'division',
  'OBELUS' = 'obelus',
  'DIVISION_BOX' = 'division-box',
  'DLNA' = 'dlna',
  'DNA' = 'dna',
  'HELIX' = 'helix',
  'DNS' = 'dns',
  'DNS_OUTLINE' = 'dns-outline',
  'DOCK_BOTTOM' = 'dock-bottom',
  'DOCK_LEFT' = 'dock-left',
  'DOCK_RIGHT' = 'dock-right',
  'DOCK_TOP' = 'dock-top',
  'DOCK_WINDOW' = 'dock-window',
  'DOCKER' = 'docker',
  'DOCTOR' = 'doctor',
  'DOG' = 'dog',
  'EMOJI_DOG' = 'emoji-dog',
  'EMOTICON_DOG' = 'emoticon-dog',
  'DOG_SERVICE' = 'dog-service',
  'GUIDE_DOG' = 'guide-dog',
  'K9' = 'k9',
  'CANINE' = 'canine',
  'DOG_SIDE' = 'dog-side',
  'DOG_SIDE_OFF' = 'dog-side-off',
  'DOLBY' = 'dolby',
  'DOLLY' = 'dolly',
  'HAND_TRUCK' = 'hand-truck',
  'DOLPHIN' = 'dolphin',
  'PORPOISE' = 'porpoise',
  'DOMAIN' = 'domain',
  'BUILDING' = 'building',
  'COMPANY' = 'company',
  'BUSINESS' = 'business',
  'DOMAIN_OFF' = 'domain-off',
  'DOMAIN_PLUS' = 'domain-plus',
  'DOMAIN_REMOVE' = 'domain-remove',
  'DOME_LIGHT' = 'dome-light',
  'DOMINO_MASK' = 'domino-mask',
  'ROBBER_MASK' = 'robber-mask',
  'ZORRO_MASK' = 'zorro-mask',
  'DONKEY' = 'donkey',
  'DOOR' = 'door',
  'DOOR_CLOSED' = 'door-closed',
  'DOOR_CLOSED_LOCK' = 'door-closed-lock',
  'DOOR_OPEN' = 'door-open',
  'DOOR_SLIDING' = 'door-sliding',
  'PATIO_DOOR' = 'patio-door',
  'FRENCH_DOOR' = 'french-door',
  'DOOR_SLIDING_LOCK' = 'door-sliding-lock',
  'PATIO_DOOR_LOCK' = 'patio-door-lock',
  'FRENCH_DOOR_LOCK' = 'french-door-lock',
  'DOOR_SLIDING_OPEN' = 'door-sliding-open',
  'PATIO_DOOR_OPEN' = 'patio-door-open',
  'FRENCH_DOOR_OPEN' = 'french-door-open',
  'DOORBELL' = 'doorbell',
  'DOORBELL_VIDEO' = 'doorbell-video',
  'DOT_NET' = 'dot-net',
  'MICROSOFT_DOT_NET' = 'microsoft-dot-net',
  'DOTS_CIRCLE' = 'dots-circle',
  'PERIMETER' = 'perimeter',
  'DOTS_GRID' = 'dots-grid',
  'DOTS_HEXAGON' = 'dots-hexagon',
  'DOTS_HORIZONTAL' = 'dots-horizontal',
  'MORE' = 'more',
  'ELLIPSIS_HORIZONTAL' = 'ellipsis-horizontal',
  'MORE_HORIZ' = 'more-horiz',
  'MENU' = 'menu',
  'DOTS_HORIZONTAL_CIRCLE' = 'dots-horizontal-circle',
  'ELLIPSIS_HORIZONTAL_CIRCLE' = 'ellipsis-horizontal-circle',
  'MORE_CIRCLE' = 'more-circle',
  'DOTS_HORIZONTAL_CIRCLE_OUTLINE' = 'dots-horizontal-circle-outline',
  'ELLIPSIS_HORIZONTAL_CIRCLE_OUTLINE' = 'ellipsis-horizontal-circle-outline',
  'MORE_CIRCLE_OUTLINE' = 'more-circle-outline',
  'DOTS_SQUARE' = 'dots-square',
  'DOTS_TRIANGLE' = 'dots-triangle',
  'DOTS_VERTICAL' = 'dots-vertical',
  'ELLIPSIS_VERTICAL' = 'ellipsis-vertical',
  'MORE_VERT' = 'more-vert',
  'DOTS_VERTICAL_CIRCLE' = 'dots-vertical-circle',
  'ELLIPSIS_VERTICAL_CIRCLE' = 'ellipsis-vertical-circle',
  'DOTS_VERTICAL_CIRCLE_OUTLINE' = 'dots-vertical-circle-outline',
  'ELLIPSIS_VERTICAL_CIRCLE_OUTLINE' = 'ellipsis-vertical-circle-outline',
  'DOWNLOAD' = 'download',
  'FILE_DOWNLOAD' = 'file-download',
  'GET_APP' = 'get-app',
  'DOWNLOAD_BOX' = 'download-box',
  'DOWNLOAD_BOX_OUTLINE' = 'download-box-outline',
  'DOWNLOAD_CIRCLE' = 'download-circle',
  'DOWNLOAD_CIRCLE_OUTLINE' = 'download-circle-outline',
  'DOWNLOAD_LOCK' = 'download-lock',
  'DOWNLOAD_LOCK_OUTLINE' = 'download-lock-outline',
  'DOWNLOAD_MULTIPLE' = 'download-multiple',
  'DOWNLOADS' = 'downloads',
  'DOWNLOAD_NETWORK' = 'download-network',
  'DOWNLOAD_NETWORK_OUTLINE' = 'download-network-outline',
  'DOWNLOAD_OFF' = 'download-off',
  'DOWNLOAD_OFF_OUTLINE' = 'download-off-outline',
  'DOWNLOAD_OUTLINE' = 'download-outline',
  'DRAG' = 'drag',
  'DRAG_HORIZONTAL' = 'drag-horizontal',
  'DRAG_HORIZONTAL_VARIANT' = 'drag-horizontal-variant',
  'DRAG_VARIANT' = 'drag-variant',
  'DRAG_VERTICAL' = 'drag-vertical',
  'DRAG_VERTICAL_VARIANT' = 'drag-vertical-variant',
  'DRAMA_MASKS' = 'drama-masks',
  'COMEDY' = 'comedy',
  'TRAGEDY' = 'tragedy',
  'THEATRE' = 'theatre',
  'DRAW' = 'draw',
  'SIGN' = 'sign',
  'SIGNATURE' = 'signature',
  'DRAW_PEN' = 'draw-pen',
  'DRAWING' = 'drawing',
  'DRAWING_BOX' = 'drawing-box',
  'DRESSER' = 'dresser',
  'DRESSER_OUTLINE' = 'dresser-outline',
  'DRONE' = 'drone',
  'DROPBOX' = 'dropbox',
  'DRUPAL' = 'drupal',
  'DUCK' = 'duck',
  'DUMBBELL' = 'dumbbell',
  'WEIGHTS' = 'weights',
  'FITNESS_CENTER' = 'fitness-center',
  'GYM' = 'gym',
  'BARBELL' = 'barbell',
  'DUMP_TRUCK' = 'dump-truck',
  'TIPPER_LORRY' = 'tipper-lorry',
  'EAR_HEARING' = 'ear-hearing',
  'EAR_HEARING_OFF' = 'ear-hearing-off',
  'HEARING_IMPAIRED' = 'hearing-impaired',
  'EARBUDS' = 'earbuds',
  'HEADPHONES' = 'headphones',
  'EARBUDS_OFF' = 'earbuds-off',
  'HEADPHONES_OFF' = 'headphones-off',
  'EARBUDS_OFF_OUTLINE' = 'earbuds-off-outline',
  'HEADPHONES_OFF_OUTLINE' = 'headphones-off-outline',
  'EARBUDS_OUTLINE' = 'earbuds-outline',
  'HEADPHONES_OUTLINE' = 'headphones-outline',
  'EARTH' = 'earth',
  'GLOBE' = 'globe',
  'PUBLIC' = 'public',
  'PLANET' = 'planet',
  'WORLD' = 'world',
  'EARTH_ARROW_RIGHT' = 'earth-arrow-right',
  'GLOBE_ARROW_RIGHT' = 'globe-arrow-right',
  'WORLD_ARROW_RIGHT' = 'world-arrow-right',
  'PLANET_ARROW_RIGHT' = 'planet-arrow-right',
  'EARTH_BOX' = 'earth-box',
  'GLOBE_BOX' = 'globe-box',
  'WORLD_BOX' = 'world-box',
  'PLANET_BOX' = 'planet-box',
  'EARTH_BOX_MINUS' = 'earth-box-minus',
  'GLOBE_BOX_MINUS' = 'globe-box-minus',
  'WORLD_BOX_MINUS' = 'world-box-minus',
  'PLANET_BOX_MINUS' = 'planet-box-minus',
  'EARTH_BOX_OFF' = 'earth-box-off',
  'GLOBE_BOX_OFF' = 'globe-box-off',
  'WORLD_BOX_OFF' = 'world-box-off',
  'PLANET_BOX_OFF' = 'planet-box-off',
  'EARTH_BOX_PLUS' = 'earth-box-plus',
  'GLOBE_BOX_PLUS' = 'globe-box-plus',
  'WORLD_BOX_PLUS' = 'world-box-plus',
  'PLANET_BOX_PLUS' = 'planet-box-plus',
  'EARTH_BOX_REMOVE' = 'earth-box-remove',
  'GLOBE_BOX_REMOVE' = 'globe-box-remove',
  'WORLD_BOX_REMOVE' = 'world-box-remove',
  'PLANET_BOX_REMOVE' = 'planet-box-remove',
  'EARTH_MINUS' = 'earth-minus',
  'GLOBE_MINUS' = 'globe-minus',
  'WORLD_MINUS' = 'world-minus',
  'PLANET_MINUS' = 'planet-minus',
  'EARTH_OFF' = 'earth-off',
  'GLOBE_OFF' = 'globe-off',
  'WORLD_OFF' = 'world-off',
  'PLANET_OFF' = 'planet-off',
  'EARTH_PLUS' = 'earth-plus',
  'GLOBE_PLUS' = 'globe-plus',
  'WORLD_PLUS' = 'world-plus',
  'PLANET_PLUS' = 'planet-plus',
  'EARTH_REMOVE' = 'earth-remove',
  'GLOBE_REMOVE' = 'globe-remove',
  'WORLD_REMOVE' = 'world-remove',
  'PLANET_REMOVE' = 'planet-remove',
  'EGG' = 'egg',
  'EGG_EASTER' = 'egg-easter',
  'EGG_FRIED' = 'egg-fried',
  'EGG_OFF' = 'egg-off',
  'EGG_OFF_OUTLINE' = 'egg-off-outline',
  'EGG_OUTLINE' = 'egg-outline',
  'EIFFEL_TOWER' = 'eiffel-tower',
  'PARIS' = 'paris',
  'FRANCE' = 'france',
  'EIGHT_TRACK' = 'eight-track',
  '8_TRACK' = '8-track',
  'EJECT' = 'eject',
  'EJECT_OUTLINE' = 'eject-outline',
  'ELECTRIC_SWITCH' = 'electric-switch',
  'ELECTRIC_SWITCH_CLOSED' = 'electric-switch-closed',
  'ELECTRON_FRAMEWORK' = 'electron-framework',
  'ELEPHANT' = 'elephant',
  'ELEVATION_DECLINE' = 'elevation-decline',
  'ELEVATION_RISE' = 'elevation-rise',
  'ELEVATOR' = 'elevator',
  'ELEVATOR_DOWN' = 'elevator-down',
  'ELEVATOR_PASSENGER' = 'elevator-passenger',
  'ELEVATOR_PASSENGER_OFF' = 'elevator-passenger-off',
  'ELEVATOR_PASSENGER_OFF_OUTLINE' = 'elevator-passenger-off-outline',
  'ELEVATOR_PASSENGER_OUTLINE' = 'elevator-passenger-outline',
  'ELEVATOR_UP' = 'elevator-up',
  'ELLIPSE' = 'ellipse',
  'ELLIPSE_OUTLINE' = 'ellipse-outline',
  'EMAIL' = 'email',
  'LOCAL_POST_OFFICE' = 'local-post-office',
  'MAIL' = 'mail',
  'MARKUNREAD' = 'markunread',
  'ENVELOPE' = 'envelope',
  'EMAIL_ALERT' = 'email-alert',
  'EMAIL_WARNING' = 'email-warning',
  'ENVELOPE_ALERT' = 'envelope-alert',
  'ENVELOPE_WARNING' = 'envelope-warning',
  'EMAIL_ALERT_OUTLINE' = 'email-alert-outline',
  'EMAIL_BOX' = 'email-box',
  'ENVELOPE_BOX' = 'envelope-box',
  'EMAIL_CHECK' = 'email-check',
  'EMAIL_TICK' = 'email-tick',
  'EMAIL_CHECK_OUTLINE' = 'email-check-outline',
  'EMAIL_TICK_OUTLINE' = 'email-tick-outline',
  'EMAIL_EDIT' = 'email-edit',
  'EMAIL_EDIT_OUTLINE' = 'email-edit-outline',
  'EMAIL_FAST' = 'email-fast',
  'ENVELOPE_FAST' = 'envelope-fast',
  'EMAIL_QUICK' = 'email-quick',
  'EMAIL_SENT' = 'email-sent',
  'EMAIL_SEND' = 'email-send',
  'EMAIL_FAST_OUTLINE' = 'email-fast-outline',
  'EMAIL_SEND_OUTLINE' = 'email-send-outline',
  'EMAIL_SENT_OUTLINE' = 'email-sent-outline',
  'ENVELOPE_FAST_OUTLINE' = 'envelope-fast-outline',
  'EMAIL_QUICK_OUTLINE' = 'email-quick-outline',
  'EMAIL_LOCK' = 'email-lock',
  'ENVELOPE_SECURE' = 'envelope-secure',
  'EMAIL_SECURE' = 'email-secure',
  'ENVELOPE_LOCK' = 'envelope-lock',
  'EMAIL_MARK_AS_UNREAD' = 'email-mark-as-unread',
  'EMAIL_MINUS' = 'email-minus',
  'EMAIL_MINUS_OUTLINE' = 'email-minus-outline',
  'EMAIL_MULTIPLE' = 'email-multiple',
  'EMAIL_MULTIPLE_OUTLINE' = 'email-multiple-outline',
  'EMAIL_NEWSLETTER' = 'email-newsletter',
  'EMAIL_OFF' = 'email-off',
  'EMAIL_OFF_OUTLINE' = 'email-off-outline',
  'EMAIL_OPEN' = 'email-open',
  'DRAFTS' = 'drafts',
  'ENVELOPE_OPEN' = 'envelope-open',
  'EMAIL_OPEN_MULTIPLE' = 'email-open-multiple',
  'EMAIL_OPEN_MULTIPLE_OUTLINE' = 'email-open-multiple-outline',
  'EMAIL_OPEN_OUTLINE' = 'email-open-outline',
  'ENVELOPE_OPEN_OUTLINE' = 'envelope-open-outline',
  'EMAIL_OUTLINE' = 'email-outline',
  'MAIL_OUTLINE' = 'mail-outline',
  'ENVELOPE_OUTLINE' = 'envelope-outline',
  'EMAIL_PLUS' = 'email-plus',
  'EMAIL_ADD' = 'email-add',
  'ENVELOPE_ADD' = 'envelope-add',
  'ENVELOPE_PLUS' = 'envelope-plus',
  'EMAIL_PLUS_OUTLINE' = 'email-plus-outline',
  'EMAIL_ADD_OUTLINE' = 'email-add-outline',
  'ENVELOPE_ADD_OUTLINE' = 'envelope-add-outline',
  'ENVELOPE_PLUS_OUTLINE' = 'envelope-plus-outline',
  'EMAIL_RECEIVE' = 'email-receive',
  'EMAIL_RECEIVE_OUTLINE' = 'email-receive-outline',
  'EMAIL_REMOVE' = 'email-remove',
  'EMAIL_REMOVE_OUTLINE' = 'email-remove-outline',
  'EMAIL_SEAL' = 'email-seal',
  'EMAIL_CERTIFIED' = 'email-certified',
  'MAIL_CERTIFIED' = 'mail-certified',
  'MAIL_SEAL' = 'mail-seal',
  'EMAIL_VERIFIED' = 'email-verified',
  'MAIL_VERIFIED' = 'mail-verified',
  'EMAIL_SEAL_OUTLINE' = 'email-seal-outline',
  'EMAIL_VERIFIED_OUTLINE' = 'email-verified-outline',
  'EMAIL_CERTIFIED_OUTLINE' = 'email-certified-outline',
  'MAIL_VERIFIED_OUTLINE' = 'mail-verified-outline',
  'MAIL_CERTIFIED_OUTLINE' = 'mail-certified-outline',
  'MAIL_SEAL_OUTLINE' = 'mail-seal-outline',
  'EMAIL_SEARCH' = 'email-search',
  'EMAIL_SEARCH_OUTLINE' = 'email-search-outline',
  'EMAIL_SYNC' = 'email-sync',
  'EMAIL_REFRESH' = 'email-refresh',
  'EMAIL_RESEND' = 'email-resend',
  'EMAIL_SYNC_OUTLINE' = 'email-sync-outline',
  'EMAIL_REFRESH_OUTLINE' = 'email-refresh-outline',
  'EMAIL_RESEND_OUTLINE' = 'email-resend-outline',
  'EMAIL_VARIANT' = 'email-variant',
  'ENVELOPE_VARIANT' = 'envelope-variant',
  'EMBER' = 'ember',
  'EMBY' = 'emby',
  'EMOTICON' = 'emoticon',
  'SMILEY' = 'smiley',
  'FACE' = 'face',
  'EMOJI' = 'emoji',
  'EMOTICON_ANGRY' = 'emoticon-angry',
  'SMILEY_ANGRY' = 'smiley-angry',
  'FACE_ANGRY' = 'face-angry',
  'EMOJI_ANGRY' = 'emoji-angry',
  'EMOTICON_ANGRY_OUTLINE' = 'emoticon-angry-outline',
  'SMILEY_ANGRY_OUTLINE' = 'smiley-angry-outline',
  'FACE_ANGRY_OUTLINE' = 'face-angry-outline',
  'EMOJI_ANGRY_OUTLINE' = 'emoji-angry-outline',
  'EMOTICON_CONFUSED' = 'emoticon-confused',
  'FACE_CONFUSED' = 'face-confused',
  'EMOJI_CONFUSED' = 'emoji-confused',
  'EMOTICON_CONFUSED_OUTLINE' = 'emoticon-confused-outline',
  'FACE_CONFUSED_OUTLINE' = 'face-confused-outline',
  'EMOJI_CONFUSED_OUTLINE' = 'emoji-confused-outline',
  'EMOTICON_COOL' = 'emoticon-cool',
  'SMILEY_COOL' = 'smiley-cool',
  'FACE_COOL' = 'face-cool',
  'FACE_SUNGLASSES' = 'face-sunglasses',
  'EMOJI_COOL' = 'emoji-cool',
  'EMOTICON_COOL_OUTLINE' = 'emoticon-cool-outline',
  'SMILEY_COOL_OUTLINE' = 'smiley-cool-outline',
  'FACE_COOL_OUTLINE' = 'face-cool-outline',
  'FACE_SUNGLASSES_OUTLINE' = 'face-sunglasses-outline',
  'EMOJI_COOL_OUTLINE' = 'emoji-cool-outline',
  'EMOTICON_CRY' = 'emoticon-cry',
  'SMILEY_CRY' = 'smiley-cry',
  'FACE_CRY' = 'face-cry',
  'EMOJI_CRY' = 'emoji-cry',
  'EMOTICON_CRY_OUTLINE' = 'emoticon-cry-outline',
  'SMILEY_CRY_OUTLINE' = 'smiley-cry-outline',
  'FACE_CRY_OUTLINE' = 'face-cry-outline',
  'EMOJI_CRY_OUTLINE' = 'emoji-cry-outline',
  'EMOTICON_DEAD' = 'emoticon-dead',
  'SMILEY_DEAD' = 'smiley-dead',
  'FACE_DEAD' = 'face-dead',
  'EMOJI_DEAD' = 'emoji-dead',
  'EMOTICON_DEAD_OUTLINE' = 'emoticon-dead-outline',
  'SMILEY_DEAD_OUTLINE' = 'smiley-dead-outline',
  'FACE_DEAD_OUTLINE' = 'face-dead-outline',
  'EMOJI_DEAD_OUTLINE' = 'emoji-dead-outline',
  'EMOTICON_DEVIL' = 'emoticon-devil',
  'SMILEY_DEVIL' = 'smiley-devil',
  'FACE_DEVIL' = 'face-devil',
  'EMOJI_DEVIL' = 'emoji-devil',
  'EMOTICON_DEVIL_OUTLINE' = 'emoticon-devil-outline',
  'SMILEY_DEVIL_OUTLINE' = 'smiley-devil-outline',
  'FACE_DEVIL_OUTLINE' = 'face-devil-outline',
  'EMOJI_DEVIL_OUTLINE' = 'emoji-devil-outline',
  'EMOTICON_EXCITED' = 'emoticon-excited',
  'SMILEY_EXCITED' = 'smiley-excited',
  'FACE_EXCITED' = 'face-excited',
  'EMOJI_EXCITED' = 'emoji-excited',
  'EMOTICON_EXCITED_OUTLINE' = 'emoticon-excited-outline',
  'SMILEY_EXCITED_OUTLINE' = 'smiley-excited-outline',
  'FACE_EXCITED_OUTLINE' = 'face-excited-outline',
  'EMOJI_EXCITED_OUTLINE' = 'emoji-excited-outline',
  'EMOTICON_FROWN' = 'emoticon-frown',
  'FACE_FROWN' = 'face-frown',
  'EMOJI_FROWN' = 'emoji-frown',
  'EMOTICON_FROWN_OUTLINE' = 'emoticon-frown-outline',
  'FACE_FROWN_OUTLINE' = 'face-frown-outline',
  'EMOJI_FROWN_OUTLINE' = 'emoji-frown-outline',
  'EMOTICON_HAPPY' = 'emoticon-happy',
  'SMILEY_HAPPY' = 'smiley-happy',
  'FACE_HAPPY' = 'face-happy',
  'EMOJI_HAPPY' = 'emoji-happy',
  'EMOTICON_HAPPY_OUTLINE' = 'emoticon-happy-outline',
  'SMILEY_HAPPY_OUTLINE' = 'smiley-happy-outline',
  'FACE_HAPPY_OUTLINE' = 'face-happy-outline',
  'EMOJI_HAPPY_OUTLINE' = 'emoji-happy-outline',
  'EMOTICON_KISS' = 'emoticon-kiss',
  'SMILEY_KISS' = 'smiley-kiss',
  'FACE_KISS' = 'face-kiss',
  'EMOJI_KISS' = 'emoji-kiss',
  'EMOTICON_KISS_OUTLINE' = 'emoticon-kiss-outline',
  'SMILEY_KISS_OUTLINE' = 'smiley-kiss-outline',
  'FACE_KISS_OUTLINE' = 'face-kiss-outline',
  'EMOJI_KISS_OUTLINE' = 'emoji-kiss-outline',
  'EMOTICON_LOL' = 'emoticon-lol',
  'FACE_LOL' = 'face-lol',
  'EMOJI_LOL' = 'emoji-lol',
  'EMOTICON_LOL_OUTLINE' = 'emoticon-lol-outline',
  'FACE_LOL_OUTLINE' = 'face-lol-outline',
  'EMOJI_LOL_OUTLINE' = 'emoji-lol-outline',
  'EMOTICON_NEUTRAL' = 'emoticon-neutral',
  'SMILEY_NEUTRAL' = 'smiley-neutral',
  'FACE_NEUTRAL' = 'face-neutral',
  'EMOJI_NEUTRAL' = 'emoji-neutral',
  'EMOTICON_NEUTRAL_OUTLINE' = 'emoticon-neutral-outline',
  'SMILEY_NEUTRAL_OUTLINE' = 'smiley-neutral-outline',
  'FACE_NEUTRAL_OUTLINE' = 'face-neutral-outline',
  'EMOJI_NEUTRAL_OUTLINE' = 'emoji-neutral-outline',
  'EMOTICON_OUTLINE' = 'emoticon-outline',
  'INSERT_EMOTICON' = 'insert-emoticon',
  'MOOD' = 'mood',
  'SENTIMENT_VERY_SATISFIED' = 'sentiment-very-satisfied',
  'TAG_FACES' = 'tag-faces',
  'SMILEY_OUTLINE' = 'smiley-outline',
  'FACE_OUTLINE' = 'face-outline',
  'EMOJI_OUTLINE' = 'emoji-outline',
  'EMOTICON_POOP' = 'emoticon-poop',
  'SMILEY_POOP' = 'smiley-poop',
  'FACE_POOP' = 'face-poop',
  'EMOJI_POOP' = 'emoji-poop',
  'EMOTICON_POOP_OUTLINE' = 'emoticon-poop-outline',
  'FACE_POOP_OUTLINE' = 'face-poop-outline',
  'EMOJI_POOP_OUTLINE' = 'emoji-poop-outline',
  'EMOTICON_SAD' = 'emoticon-sad',
  'SMILEY_SAD' = 'smiley-sad',
  'FACE_SAD' = 'face-sad',
  'EMOJI_SAD' = 'emoji-sad',
  'EMOTICON_SAD_OUTLINE' = 'emoticon-sad-outline',
  'SMILEY_SAD_OUTLINE' = 'smiley-sad-outline',
  'FACE_SAD_OUTLINE' = 'face-sad-outline',
  'EMOJI_SAD_OUTLINE' = 'emoji-sad-outline',
  'EMOTICON_SICK' = 'emoticon-sick',
  'FACE_SICK' = 'face-sick',
  'FEVER' = 'fever',
  'EMOJI_SICK' = 'emoji-sick',
  'EMOTICON_SICK_OUTLINE' = 'emoticon-sick-outline',
  'FACE_SICK_OUTLINE' = 'face-sick-outline',
  'FEVER_OUTLINE' = 'fever-outline',
  'EMOJI_SICK_OUTLINE' = 'emoji-sick-outline',
  'EMOTICON_TONGUE' = 'emoticon-tongue',
  'SMILEY_TONGUE' = 'smiley-tongue',
  'FACE_TONGUE' = 'face-tongue',
  'EMOJI_TONGUE' = 'emoji-tongue',
  'EMOTICON_TONGUE_OUTLINE' = 'emoticon-tongue-outline',
  'SMILEY_TONGUE_OUTLINE' = 'smiley-tongue-outline',
  'FACE_TONGUE_OUTLINE' = 'face-tongue-outline',
  'EMOJI_TONGUE_OUTLINE' = 'emoji-tongue-outline',
  'EMOTICON_WINK' = 'emoticon-wink',
  'SMILEY_WINK' = 'smiley-wink',
  'FACE_WINK' = 'face-wink',
  'EMOJI_WINK' = 'emoji-wink',
  'EMOTICON_WINK_OUTLINE' = 'emoticon-wink-outline',
  'SMILEY_WINK_OUTLINE' = 'smiley-wink-outline',
  'FACE_WINK_OUTLINE' = 'face-wink-outline',
  'EMOJI_WINK_OUTLINE' = 'emoji-wink-outline',
  'ENGINE' = 'engine',
  'MOTOR' = 'motor',
  'ENGINE_OFF' = 'engine-off',
  'MOTOR_OFF' = 'motor-off',
  'ENGINE_OFF_OUTLINE' = 'engine-off-outline',
  'MOTOR_OFF_OUTLINE' = 'motor-off-outline',
  'ENGINE_OUTLINE' = 'engine-outline',
  'MOTOR_OUTLINE' = 'motor-outline',
  'EPSILON' = 'epsilon',
  'EQUAL' = 'equal',
  'EQUAL_BOX' = 'equal-box',
  'EQUALIZER' = 'equalizer',
  'EQUALIZER_OUTLINE' = 'equalizer-outline',
  'ERASER' = 'eraser',
  'ERASER_VARIANT' = 'eraser-variant',
  'ESCALATOR' = 'escalator',
  'ESCALATOR_BOX' = 'escalator-box',
  'ESCALATOR_DOWN' = 'escalator-down',
  'ESCALATOR_UP' = 'escalator-up',
  'ESLINT' = 'eslint',
  'ET' = 'et',
  'ETHERNET' = 'ethernet',
  'ETHERNET_CABLE' = 'ethernet-cable',
  'ETHERNET_CABLE_OFF' = 'ethernet-cable-off',
  'EV_PLUG_CCS1' = 'ev-plug-ccs1',
  'EV_PLUG_CCS_COMBO_1' = 'ev-plug-ccs-combo-1',
  'EV_CHARGER_CCS1' = 'ev-charger-ccs1',
  'EV_PLUG_CCS2' = 'ev-plug-ccs2',
  'EV_PLUG_CCS_COMBO_2' = 'ev-plug-ccs-combo-2',
  'EV_CHARGER_CCS2' = 'ev-charger-ccs2',
  'EV_PLUG_CHADEMO' = 'ev-plug-chademo',
  'EV_CHARGER_CHADEMO' = 'ev-charger-chademo',
  'EV_PLUG_TESLA' = 'ev-plug-tesla',
  'EV_CHARGER_TESLA' = 'ev-charger-tesla',
  'EV_PLUG_TYPE1' = 'ev-plug-type1',
  'EV_PLUG_J1772' = 'ev-plug-j1772',
  'EV_CHARGER_TYPE1' = 'ev-charger-type1',
  'EV_PLUG_TYPE2' = 'ev-plug-type2',
  'EV_PLUG_MENNEKES' = 'ev-plug-mennekes',
  'EV_CHARGER_TYPE2' = 'ev-charger-type2',
  'EV_STATION' = 'ev-station',
  'CHARGING_STATION' = 'charging-station',
  'EV_CHARGER' = 'ev-charger',
  'WALL_CHARGER' = 'wall-charger',
  'WALLBOX' = 'wallbox',
  'ELECTRIC_VEHICLE_CHARGER' = 'electric-vehicle-charger',
  'EVSE' = 'evse',
  'ELECTRIC_CHARGER' = 'electric-charger',
  'EVERNOTE' = 'evernote',
  'EXCAVATOR' = 'excavator',
  'EXCLAMATION' = 'exclamation',
  'FACTORIAL' = 'factorial',
  'EXCLAMATION_THICK' = 'exclamation-thick',
  'EXCLAMATION_BOLD' = 'exclamation-bold',
  'EXIT_RUN' = 'exit-run',
  'EMERGENCY_EXIT' = 'emergency-exit',
  'EXIT_TO_APP' = 'exit-to-app',
  'EXPAND_ALL' = 'expand-all',
  'ANIMATION_PLUS' = 'animation-plus',
  'EXPAND_ALL_OUTLINE' = 'expand-all-outline',
  'ANIMATION_PLUS_OUTLINE' = 'animation-plus-outline',
  'EXPANSION_CARD' = 'expansion-card',
  'GPU' = 'gpu',
  'GRAPHICS_PROCESSING_UNIT' = 'graphics-processing-unit',
  'NIC' = 'nic',
  'NETWORK_INTERFACE_CARD' = 'network-interface-card',
  'EXPANSION_CARD_VARIANT' = 'expansion-card-variant',
  'NICE' = 'nice',
  'EXPONENT' = 'exponent',
  'POWER' = 'power',
  'EXPONENT_BOX' = 'exponent-box',
  'POWER_BOX' = 'power-box',
  'EXPORT' = 'export',
  'OUTPUT' = 'output',
  'EXPORT_VARIANT' = 'export-variant',
  'IOS_SHARE' = 'ios-share',
  'EYE' = 'eye',
  'SHOW' = 'show',
  'VISIBILITY' = 'visibility',
  'REMOVE_RED_EYE' = 'remove-red-eye',
  'EYE_ARROW_LEFT' = 'eye-arrow-left',
  'VIEW_ARROW_LEFT' = 'view-arrow-left',
  'EYE_ARROW_LEFT_OUTLINE' = 'eye-arrow-left-outline',
  'VIEW_ARROW_LEFT_OUTLINE' = 'view-arrow-left-outline',
  'EYE_ARROW_RIGHT' = 'eye-arrow-right',
  'VIEW_ARROW_RIGHT' = 'view-arrow-right',
  'EYE_ARROW_RIGHT_OUTLINE' = 'eye-arrow-right-outline',
  'VIEW_ARROW_RIGHT_OUTLINE' = 'view-arrow-right-outline',
  'EYE_CHECK' = 'eye-check',
  'EYE_TICK' = 'eye-tick',
  'EYE_CHECK_OUTLINE' = 'eye-check-outline',
  'EYE_TICK_OUTLINE' = 'eye-tick-outline',
  'EYE_CIRCLE' = 'eye-circle',
  'EYE_CIRCLE_OUTLINE' = 'eye-circle-outline',
  'EYE_MINUS' = 'eye-minus',
  'EYE_MINUS_OUTLINE' = 'eye-minus-outline',
  'EYE_OFF' = 'eye-off',
  'HIDE' = 'hide',
  'VISIBILITY_OFF' = 'visibility-off',
  'EYE_OFF_OUTLINE' = 'eye-off-outline',
  'HIDE_OUTLINE' = 'hide-outline',
  'VISIBILITY_OFF_OUTLINE' = 'visibility-off-outline',
  'EYE_OUTLINE' = 'eye-outline',
  'SHOW_OUTLINE' = 'show-outline',
  'VISIBILITY_OUTLINE' = 'visibility-outline',
  'EYE_PLUS' = 'eye-plus',
  'EYE_ADD' = 'eye-add',
  'EYE_PLUS_OUTLINE' = 'eye-plus-outline',
  'EYE_ADD_OUTLINE' = 'eye-add-outline',
  'EYE_REFRESH' = 'eye-refresh',
  'VIEW_REFRESH' = 'view-refresh',
  'EYE_REFRESH_OUTLINE' = 'eye-refresh-outline',
  'VIEW_REFRESH_OUTLINE' = 'view-refresh-outline',
  'EYE_REMOVE' = 'eye-remove',
  'EYE_REMOVE_OUTLINE' = 'eye-remove-outline',
  'EYE_SETTINGS' = 'eye-settings',
  'EYE_SETTINGS_OUTLINE' = 'eye-settings-outline',
  'EYEDROPPER' = 'eyedropper',
  'PIPETTE' = 'pipette',
  'EYEDROPPER_MINUS' = 'eyedropper-minus',
  'EYEDROPPER_OFF' = 'eyedropper-off',
  'EYEDROPPER_PLUS' = 'eyedropper-plus',
  'EYEDROPPER_REMOVE' = 'eyedropper-remove',
  'EYEDROPPER_VARIANT' = 'eyedropper-variant',
  'COLORIZE' = 'colorize',
  'COLOURISE' = 'colourise',
  'PIPETTE_VARIANT' = 'pipette-variant',
  'FACE_AGENT' = 'face-agent',
  'CUSTOMER_SERVICE' = 'customer-service',
  'SUPPORT' = 'support',
  'EMOJI_AGENT' = 'emoji-agent',
  'EMOTICON_AGENT' = 'emoticon-agent',
  'FACE_MAN' = 'face-man',
  'FACE_MALE' = 'face-male',
  'EMOJI_MAN' = 'emoji-man',
  'EMOTICON_MAN' = 'emoticon-man',
  'FACE_MAN_OUTLINE' = 'face-man-outline',
  'FACE_MALE_OUTLINE' = 'face-male-outline',
  'EMOJI_MAN_OUTLINE' = 'emoji-man-outline',
  'EMOTICON_MAN_OUTLINE' = 'emoticon-man-outline',
  'FACE_MAN_PROFILE' = 'face-man-profile',
  'FACE_MALE_PROFILE' = 'face-male-profile',
  'EMOJI_MAN_PROFILE' = 'emoji-man-profile',
  'EMOTICON_MAN_PROFILE' = 'emoticon-man-profile',
  'FACE_MAN_SHIMMER' = 'face-man-shimmer',
  'FACE_RETOUCHING_NATURAL' = 'face-retouching-natural',
  'FACE_MALE_SHIMMER' = 'face-male-shimmer',
  'EMOJI_MAN_SHIMMER' = 'emoji-man-shimmer',
  'EMOTICON_MAN_SHIMMER' = 'emoticon-man-shimmer',
  'FACE_MAN_SHIMMER_OUTLINE' = 'face-man-shimmer-outline',
  'FACE_RETOUCHING_NATURAL_OUTLINE' = 'face-retouching-natural-outline',
  'FACE_MALE_SHIMMER_OUTLINE' = 'face-male-shimmer-outline',
  'EMOJI_MAN_SHIMMER_OUTLINE' = 'emoji-man-shimmer-outline',
  'EMOTICON_MAN_SHIMMER_OUTLINE' = 'emoticon-man-shimmer-outline',
  'FACE_MASK' = 'face-mask',
  'FACE_MASK_OUTLINE' = 'face-mask-outline',
  'FACE_RECOGNITION' = 'face-recognition',
  'FACIAL_RECOGNITION' = 'facial-recognition',
  'SCAN' = 'scan',
  'FACE_WOMAN' = 'face-woman',
  'FACE_FEMALE' = 'face-female',
  'EMOJI_WOMAN' = 'emoji-woman',
  'EMOTICON_WOMAN' = 'emoticon-woman',
  'FACE_WOMAN_OUTLINE' = 'face-woman-outline',
  'FACE_FEMALE_OUTLINE' = 'face-female-outline',
  'EMOJI_WOMAN_OUTLINE' = 'emoji-woman-outline',
  'EMOTICON_WOMAN_OUTLINE' = 'emoticon-woman-outline',
  'FACE_WOMAN_PROFILE' = 'face-woman-profile',
  'FACE_FEMALE_PROFILE' = 'face-female-profile',
  'EMOJI_WOMAN_PROFILE' = 'emoji-woman-profile',
  'EMOTICON_WOMAN_PROFILE' = 'emoticon-woman-profile',
  'FACE_WOMAN_SHIMMER' = 'face-woman-shimmer',
  'FACE_RETOUCHING_NATURAL_WOMAN' = 'face-retouching-natural-woman',
  'FACE_FEMALE_SHIMMER' = 'face-female-shimmer',
  'EMOJI_WOMAN_SHIMMER' = 'emoji-woman-shimmer',
  'EMOTICON_WOMAN_SHIMMER' = 'emoticon-woman-shimmer',
  'FACE_WOMAN_SHIMMER_OUTLINE' = 'face-woman-shimmer-outline',
  'FACE_RETOUCHING_NATURAL_WOMAN_OUTLINE' = 'face-retouching-natural-woman-outline',
  'FACE_FEMALE_SHIMMER_OUTLINE' = 'face-female-shimmer-outline',
  'EMOJI_WOMAN_SHIMMER_OUTLINE' = 'emoji-woman-shimmer-outline',
  'EMOTICON_WOMAN_SHIMMER_OUTLINE' = 'emoticon-woman-shimmer-outline',
  'FACEBOOK' = 'facebook',
  'FACEBOOK_GAMING' = 'facebook-gaming',
  'FACEBOOK_MESSENGER' = 'facebook-messenger',
  'FACEBOOK_WORKPLACE' = 'facebook-workplace',
  'FACTORY' = 'factory',
  'INDUSTRIAL' = 'industrial',
  'FAMILY_TREE' = 'family-tree',
  'FAN' = 'fan',
  'FAN_ALERT' = 'fan-alert',
  'FAN_AUTO' = 'fan-auto',
  'FAN_CHEVRON_DOWN' = 'fan-chevron-down',
  'FAN_SPEED_DOWN' = 'fan-speed-down',
  'FAN_CHEVRON_UP' = 'fan-chevron-up',
  'FAN_SPEED_UP' = 'fan-speed-up',
  'FAN_MINUS' = 'fan-minus',
  'FAN_OFF' = 'fan-off',
  'FAN_PLUS' = 'fan-plus',
  'FAN_REMOVE' = 'fan-remove',
  'FAN_SPEED1' = 'fan-speed1',
  'FAN_SPEED2' = 'fan-speed2',
  'FAN_SPEED3' = 'fan-speed3',
  'FAST_FORWARD' = 'fast-forward',
  'FAST_FORWARD10' = 'fast-forward10',
  'FAST_FORWARD15' = 'fast-forward15',
  'FAST_FORWARD30' = 'fast-forward30',
  'FAST_FORWARD5' = 'fast-forward5',
  'FAST_FORWARD60' = 'fast-forward60',
  'FAST_FORWARD_OUTLINE' = 'fast-forward-outline',
  'FAX' = 'fax',
  'FEATHER' = 'feather',
  'QUILL' = 'quill',
  'FEATURE_SEARCH' = 'feature-search',
  'FEATURE_SEARCH_OUTLINE' = 'feature-search-outline',
  'FEDORA' = 'fedora',
  'FENCE' = 'fence',
  'RAILWAY' = 'railway',
  'TRAIN_TRACK' = 'train-track',
  'FENCE_ELECTRIC' = 'fence-electric',
  'RAILWAY_ELECTRIC' = 'railway-electric',
  'TRAIN_TRACK_ELECTRIC' = 'train-track-electric',
  'FENCING' = 'fencing',
  'SWORD_FIGHT' = 'sword-fight',
  'FERRIS_WHEEL' = 'ferris-wheel',
  'FERRY' = 'ferry',
  'CARGO_SHIP' = 'cargo-ship',
  'BOAT' = 'boat',
  'SHIP' = 'ship',
  'DIRECTIONS_BOAT' = 'directions-boat',
  'DIRECTIONS_FERRY' = 'directions-ferry',
  'FILE' = 'file',
  'INSERT_DRIVE_FILE' = 'insert-drive-file',
  'DRAFT' = 'draft',
  'PAPER' = 'paper',
  'FILE_ACCOUNT' = 'file-account',
  'FILE_USER' = 'file-user',
  'RESUME' = 'resume',
  'FILE_ACCOUNT_OUTLINE' = 'file-account-outline',
  'FILE_ALERT' = 'file-alert',
  'FILE_WARNING' = 'file-warning',
  'FILE_ALERT_OUTLINE' = 'file-alert-outline',
  'FILE_WARNING_OUTLINE' = 'file-warning-outline',
  'FILE_CABINET' = 'file-cabinet',
  'FILING_CABINET' = 'filing-cabinet',
  'FILE_CAD' = 'file-cad',
  'FILE_CAD_BOX' = 'file-cad-box',
  'FILE_CANCEL' = 'file-cancel',
  'FILE_CANCEL_OUTLINE' = 'file-cancel-outline',
  'FILE_CERTIFICATE' = 'file-certificate',
  'FILE_CERTIFICATE_OUTLINE' = 'file-certificate-outline',
  'FILE_CHART' = 'file-chart',
  'FILE_REPORT' = 'file-report',
  'FILE_GRAPH' = 'file-graph',
  'FILE_CHART_CHECK' = 'file-chart-check',
  'FILE_CHART_CHECK_OUTLINE' = 'file-chart-check-outline',
  'FILE_CHART_OUTLINE' = 'file-chart-outline',
  'FILE_GRAPH_OUTLINE' = 'file-graph-outline',
  'FILE_REPORT_OUTLINE' = 'file-report-outline',
  'FILE_CHECK' = 'file-check',
  'FILE_TICK' = 'file-tick',
  'FILE_CHECK_OUTLINE' = 'file-check-outline',
  'FILE_CLOCK' = 'file-clock',
  'FILE_CLOCK_OUTLINE' = 'file-clock-outline',
  'FILE_CLOUD' = 'file-cloud',
  'FILE_CLOUD_OUTLINE' = 'file-cloud-outline',
  'FILE_CODE' = 'file-code',
  'FILE_CODE_OUTLINE' = 'file-code-outline',
  'FILE_COG' = 'file-cog',
  'FILE_SETTINGS_COG' = 'file-settings-cog',
  'FILE_COG_OUTLINE' = 'file-cog-outline',
  'FILE_SETTINGS_COG_OUTLINE' = 'file-settings-cog-outline',
  'FILE_COMPARE' = 'file-compare',
  'FILE_DELIMITED' = 'file-delimited',
  'FILE_CSV' = 'file-csv',
  'FILE_DELIMITED_OUTLINE' = 'file-delimited-outline',
  'FILE_CSV_OUTLINE' = 'file-csv-outline',
  'FILE_DOCUMENT' = 'file-document',
  'FILE_DOCUMENT_EDIT' = 'file-document-edit',
  'CONTRACT' = 'contract',
  'FILE_DOCUMENT_EDIT_OUTLINE' = 'file-document-edit-outline',
  'CONTRACT_OUTLINE' = 'contract-outline',
  'FILE_DOCUMENT_MULTIPLE' = 'file-document-multiple',
  'FILE_DOCUMENT_MULTIPLE_OUTLINE' = 'file-document-multiple-outline',
  'FILE_DOCUMENT_OUTLINE' = 'file-document-outline',
  'FILE_DOWNLOAD_OUTLINE' = 'file-download-outline',
  'FILE_EDIT' = 'file-edit',
  'FILE_EDIT_OUTLINE' = 'file-edit-outline',
  'FILE_EXCEL' = 'file-excel',
  'FILE_EXCEL_BOX' = 'file-excel-box',
  'FILE_EXCEL_BOX_OUTLINE' = 'file-excel-box-outline',
  'FILE_EXCEL_OUTLINE' = 'file-excel-outline',
  'FILE_EXPORT' = 'file-export',
  'FILE_EXPORT_OUTLINE' = 'file-export-outline',
  'FILE_EYE' = 'file-eye',
  'FILE_EYE_OUTLINE' = 'file-eye-outline',
  'FILE_FIND' = 'file-find',
  'PRINT_PREVIEW' = 'print-preview',
  'FIND_IN_PAGE' = 'find-in-page',
  'FILE_FIND_OUTLINE' = 'file-find-outline',
  'FILE_GIF_BOX' = 'file-gif-box',
  'FILE_HIDDEN' = 'file-hidden',
  'FILE_IMAGE' = 'file-image',
  'FILE_IMAGE_MARKER' = 'file-image-marker',
  'FILE_IMAGE_LOCATION' = 'file-image-location',
  'FILE_IMAGE_MARKER_OUTLINE' = 'file-image-marker-outline',
  'FILE_IMAGE_LOCATION_OUTLINE' = 'file-image-location-outline',
  'FILE_IMAGE_MINUS' = 'file-image-minus',
  'FILE_IMAGE_MINUS_OUTLINE' = 'file-image-minus-outline',
  'FILE_IMAGE_OUTLINE' = 'file-image-outline',
  'FILE_IMAGE_PLUS' = 'file-image-plus',
  'FILE_IMAGE_ADD' = 'file-image-add',
  'FILE_IMAGE_PLUS_OUTLINE' = 'file-image-plus-outline',
  'FILE_IMAGE_ADD_OUTLINE' = 'file-image-add-outline',
  'FILE_IMAGE_REMOVE' = 'file-image-remove',
  'FILE_IMAGE_REMOVE_OUTLINE' = 'file-image-remove-outline',
  'FILE_IMPORT' = 'file-import',
  'FILE_IMPORT_OUTLINE' = 'file-import-outline',
  'FILE_JPG_BOX' = 'file-jpg-box',
  'FILE_JPEG_BOX' = 'file-jpeg-box',
  'IMAGE_JPG_BOX' = 'image-jpg-box',
  'IMAGE_JPEG_BOX' = 'image-jpeg-box',
  'FILE_KEY' = 'file-key',
  'FILE_KEY_OUTLINE' = 'file-key-outline',
  'FILE_LINK' = 'file-link',
  'FILE_LINK_OUTLINE' = 'file-link-outline',
  'FILE_LOCK' = 'file-lock',
  'FILE_LOCK_OPEN' = 'file-lock-open',
  'FILE_LOCK_OPEN_OUTLINE' = 'file-lock-open-outline',
  'FILE_LOCK_OUTLINE' = 'file-lock-outline',
  'FILE_MARKER' = 'file-marker',
  'FILE_LOCATION' = 'file-location',
  'FILE_MARKER_OUTLINE' = 'file-marker-outline',
  'FILE_LOCATION_OUTLINE' = 'file-location-outline',
  'FILE_MOVE' = 'file-move',
  'FILE_MOVE_OUTLINE' = 'file-move-outline',
  'FILE_MULTIPLE' = 'file-multiple',
  'FILES' = 'files',
  'FILE_MULTIPLE_OUTLINE' = 'file-multiple-outline',
  'FILE_MUSIC' = 'file-music',
  'FILE_MUSIC_OUTLINE' = 'file-music-outline',
  'FILE_OUTLINE' = 'file-outline',
  'PAPER_OUTLINE' = 'paper-outline',
  'FILE_PDF_BOX' = 'file-pdf-box',
  'FILE_ACROBAT_BOX' = 'file-acrobat-box',
  'ADOBE_ACROBAT' = 'adobe-acrobat',
  'FILE_PERCENT' = 'file-percent',
  'FILE_PERCENT_OUTLINE' = 'file-percent-outline',
  'FILE_PHONE' = 'file-phone',
  'FILE_PHONE_OUTLINE' = 'file-phone-outline',
  'FILE_PLUS' = 'file-plus',
  'NOTE_ADD' = 'note-add',
  'FILE_PLUS_OUTLINE' = 'file-plus-outline',
  'FILE_PNG_BOX' = 'file-png-box',
  'FILE_POWERPOINT' = 'file-powerpoint',
  'FILE_POWERPOINT_BOX' = 'file-powerpoint-box',
  'FILE_POWERPOINT_BOX_OUTLINE' = 'file-powerpoint-box-outline',
  'FILE_POWERPOINT_OUTLINE' = 'file-powerpoint-outline',
  'FILE_PRESENTATION_BOX' = 'file-presentation-box',
  'FILE_QUESTION' = 'file-question',
  'FILE_QUESTION_OUTLINE' = 'file-question-outline',
  'FILE_REFRESH' = 'file-refresh',
  'FILE_REFRESH_OUTLINE' = 'file-refresh-outline',
  'FILE_REMOVE' = 'file-remove',
  'FILE_REMOVE_OUTLINE' = 'file-remove-outline',
  'FILE_REPLACE' = 'file-replace',
  'FILE_REPLACE_OUTLINE' = 'file-replace-outline',
  'FILE_RESTORE' = 'file-restore',
  'RESTORE_PAGE' = 'restore-page',
  'FILE_RESTORE_OUTLINE' = 'file-restore-outline',
  'FILE_SEARCH' = 'file-search',
  'FILE_SEARCH_OUTLINE' = 'file-search-outline',
  'FILE_SEND' = 'file-send',
  'FILE_SEND_OUTLINE' = 'file-send-outline',
  'FILE_SETTINGS' = 'file-settings',
  'FILE_SETTINGS_OUTLINE' = 'file-settings-outline',
  'FILE_SIGN' = 'file-sign',
  'CONTRACT_SIGN' = 'contract-sign',
  'DOCUMENT_SIGN' = 'document-sign',
  'FILE_STAR' = 'file-star',
  'FILE_FAVORITE' = 'file-favorite',
  'FILE_STAR_OUTLINE' = 'file-star-outline',
  'FILE_FAVORITE_OUTLINE' = 'file-favorite-outline',
  'FILE_SWAP' = 'file-swap',
  'FILE_TRANSFER' = 'file-transfer',
  'FILE_SWAP_OUTLINE' = 'file-swap-outline',
  'FILE_TRANSFER_OUTLINE' = 'file-transfer-outline',
  'FILE_SYNC' = 'file-sync',
  'FILE_SYNC_OUTLINE' = 'file-sync-outline',
  'FILE_TABLE' = 'file-table',
  'FILE_TABLE_BOX' = 'file-table-box',
  'FILE_TABLE_BOX_MULTIPLE' = 'file-table-box-multiple',
  'FILE_TABLE_BOX_MULTIPLE_OUTLINE' = 'file-table-box-multiple-outline',
  'FILE_TABLE_BOX_OUTLINE' = 'file-table-box-outline',
  'FILE_TABLE_OUTLINE' = 'file-table-outline',
  'FILE_TREE' = 'file-tree',
  'SUBTASKS' = 'subtasks',
  'FILE_TREE_OUTLINE' = 'file-tree-outline',
  'FILE_UNDO' = 'file-undo',
  'FILE_REVERT' = 'file-revert',
  'FILE_DISCARD' = 'file-discard',
  'FILE_UNDO_OUTLINE' = 'file-undo-outline',
  'FILE_UPLOAD' = 'file-upload',
  'FILE_UPLOAD_OUTLINE' = 'file-upload-outline',
  'FILE_VIDEO' = 'file-video',
  'FILE_VIDEO_OUTLINE' = 'file-video-outline',
  'FILE_WORD' = 'file-word',
  'FILE_WORD_BOX' = 'file-word-box',
  'FILE_WORD_BOX_OUTLINE' = 'file-word-box-outline',
  'FILE_WORD_OUTLINE' = 'file-word-outline',
  'FILM' = 'film',
  'CAMERA_ROLL' = 'camera-roll',
  'FILMSTRIP' = 'filmstrip',
  'LOCAL_MOVIES' = 'local-movies',
  'THEATERS' = 'theaters',
  'FILMSTRIP_BOX' = 'filmstrip-box',
  'FILMSTRIP_BOX_MULTIPLE' = 'filmstrip-box-multiple',
  'LIBRARY_MOVIE' = 'library-movie',
  'FILMSTRIP_OFF' = 'filmstrip-off',
  'FUNNEL' = 'funnel',
  'FILTER_CHECK' = 'filter-check',
  'FUNNEL_CHECK' = 'funnel-check',
  'FILTER_CHECK_OUTLINE' = 'filter-check-outline',
  'FUNNEL_CHECK_OUTLINE' = 'funnel-check-outline',
  'FILTER_MENU' = 'filter-menu',
  'FILTER_MENU_OUTLINE' = 'filter-menu-outline',
  'FILTER_MINUS' = 'filter-minus',
  'FUNNEL_MINUS' = 'funnel-minus',
  'FILTER_MINUS_OUTLINE' = 'filter-minus-outline',
  'FUNNEL_MINUS_OUTLINE' = 'funnel-minus-outline',
  'FILTER_OFF' = 'filter-off',
  'FILTER_OFF_OUTLINE' = 'filter-off-outline',
  'FILTER_OUTLINE' = 'filter-outline',
  'FUNNEL_OUTLINE' = 'funnel-outline',
  'FILTER_PLUS' = 'filter-plus',
  'FUNNEL_PLUS' = 'funnel-plus',
  'FILTER_PLUS_OUTLINE' = 'filter-plus-outline',
  'FUNNEL_PLUS_OUTLINE' = 'funnel-plus-outline',
  'FILTER_REMOVE' = 'filter-remove',
  'FUNNEL_REMOVE' = 'funnel-remove',
  'FILTER_REMOVE_OUTLINE' = 'filter-remove-outline',
  'FUNNEL_REMOVE_OUTLINE' = 'funnel-remove-outline',
  'FILTER_VARIANT' = 'filter-variant',
  'FILTER_LIST' = 'filter-list',
  'FILTER_VARIANT_MINUS' = 'filter-variant-minus',
  'FILTER_VARIANT_PLUS' = 'filter-variant-plus',
  'FILTER_VARIANT_REMOVE' = 'filter-variant-remove',
  'FINANCE' = 'finance',
  'CHART_FINANCE' = 'chart-finance',
  'REPORT_FINANCE' = 'report-finance',
  'FIND_REPLACE' = 'find-replace',
  'FINGERPRINT' = 'fingerprint',
  'FINGERPRINT_OFF' = 'fingerprint-off',
  'FIRE' = 'fire',
  'WHATSHOT' = 'whatshot',
  'FLAME' = 'flame',
  'GAS' = 'gas',
  'NATURAL_GAS' = 'natural-gas',
  'HOT' = 'hot',
  'FIRE_ALERT' = 'fire-alert',
  'FLAME_ALERT' = 'flame-alert',
  'FIRE_CIRCLE' = 'fire-circle',
  'FLAME_CIRCLE' = 'flame-circle',
  'HOT_CIRCLE' = 'hot-circle',
  'GAS_CIRCLE' = 'gas-circle',
  'NATURAL_GAS_CIRCLE' = 'natural-gas-circle',
  'FIRE_EXTINGUISHER' = 'fire-extinguisher',
  'FIRE_HYDRANT' = 'fire-hydrant',
  'FIRE_HYDRANT_ALERT' = 'fire-hydrant-alert',
  'FIRE_HYDRANT_OFF' = 'fire-hydrant-off',
  'FIRE_OFF' = 'fire-off',
  'FLAME_OFF' = 'flame-off',
  'FIRE_TRUCK' = 'fire-truck',
  'FIRE_ENGINE' = 'fire-engine',
  'FIREBASE' = 'firebase',
  'FIREFOX' = 'firefox',
  'MOZILLA_FIREFOX' = 'mozilla-firefox',
  'FIREPLACE' = 'fireplace',
  'FIREPLACE_OFF' = 'fireplace-off',
  'FIREWIRE' = 'firewire',
  'FIREWORK' = 'firework',
  'BOTTLE_ROCKET' = 'bottle-rocket',
  'FIREWORK_OFF' = 'firework-off',
  'FISH' = 'fish',
  'FISH_OFF' = 'fish-off',
  'FISHBOWL' = 'fishbowl',
  'AQUARIUM' = 'aquarium',
  'FISHBOWL_OUTLINE' = 'fishbowl-outline',
  'AQUARIUM_OUTLINE' = 'aquarium-outline',
  'FIT_TO_PAGE' = 'fit-to-page',
  'FIT_TO_PAGE_OUTLINE' = 'fit-to-page-outline',
  'FIT_TO_SCREEN' = 'fit-to-screen',
  'FIT_TO_SCREEN_OUTLINE' = 'fit-to-screen-outline',
  'FLAG' = 'flag',
  'ASSISTANT_PHOTO' = 'assistant-photo',
  'FLAG_CHECKERED' = 'flag-checkered',
  'GOAL' = 'goal',
  'FLAG_MINUS' = 'flag-minus',
  'FLAG_MINUS_OUTLINE' = 'flag-minus-outline',
  'FLAG_OFF' = 'flag-off',
  'FLAG_OFF_OUTLINE' = 'flag-off-outline',
  'FLAG_OUTLINE' = 'flag-outline',
  'FLAG_PLUS' = 'flag-plus',
  'FLAG_ADD' = 'flag-add',
  'FLAG_PLUS_OUTLINE' = 'flag-plus-outline',
  'FLAG_REMOVE' = 'flag-remove',
  'FLAG_REMOVE_OUTLINE' = 'flag-remove-outline',
  'FLAG_TRIANGLE' = 'flag-triangle',
  'MILESTONE' = 'milestone',
  'FLAG_VARIANT' = 'flag-variant',
  'FLAG_VARIANT_OUTLINE' = 'flag-variant-outline',
  'FLARE' = 'flare',
  'STAR' = 'star',
  'FLASH' = 'flash',
  'LIGHTNING_BOLT' = 'lightning-bolt',
  'FLASH_ON' = 'flash-on',
  'ELECTRICITY' = 'electricity',
  'FLASH_ALERT' = 'flash-alert',
  'LIGHTNING_ALERT' = 'lightning-alert',
  'STORM_ADVISORY' = 'storm-advisory',
  'FLASH_ALERT_OUTLINE' = 'flash-alert-outline',
  'LIGHTNING_ALERT_OUTLINE' = 'lightning-alert-outline',
  'STORM_ADVISORY_OUTLINE' = 'storm-advisory-outline',
  'FLASH_AUTO' = 'flash-auto',
  'FLASH_OFF' = 'flash-off',
  'FLASH_OUTLINE' = 'flash-outline',
  'LIGHTNING_BOLT_OUTLINE' = 'lightning-bolt-outline',
  'FLASH_RED_EYE' = 'flash-red-eye',
  'FLASHLIGHT' = 'flashlight',
  'TORCH' = 'torch',
  'FLASHLIGHT_OFF' = 'flashlight-off',
  'TORCH_OFF' = 'torch-off',
  'FLASK_EMPTY' = 'flask-empty',
  'FLASK_EMPTY_MINUS' = 'flask-empty-minus',
  'FLASK_EMPTY_MINUS_OUTLINE' = 'flask-empty-minus-outline',
  'FLASK_EMPTY_OFF' = 'flask-empty-off',
  'FLASK_EMPTY_OFF_OUTLINE' = 'flask-empty-off-outline',
  'FLASK_EMPTY_OUTLINE' = 'flask-empty-outline',
  'FLASK_EMPTY_PLUS' = 'flask-empty-plus',
  'FLASK_EMPTY_PLUS_OUTLINE' = 'flask-empty-plus-outline',
  'FLASK_EMPTY_REMOVE' = 'flask-empty-remove',
  'FLASK_EMPTY_REMOVE_OUTLINE' = 'flask-empty-remove-outline',
  'FLASK_MINUS' = 'flask-minus',
  'FLASK_MINUS_OUTLINE' = 'flask-minus-outline',
  'FLASK_OFF' = 'flask-off',
  'FLASK_OFF_OUTLINE' = 'flask-off-outline',
  'FLASK_PLUS' = 'flask-plus',
  'FLASK_PLUS_OUTLINE' = 'flask-plus-outline',
  'FLASK_REMOVE' = 'flask-remove',
  'FLASK_REMOVE_OUTLINE' = 'flask-remove-outline',
  'FLASK_ROUND_BOTTOM' = 'flask-round-bottom',
  'FLASK_ROUND_BOTTOM_EMPTY' = 'flask-round-bottom-empty',
  'FLASK_ROUND_BOTTOM_EMPTY_OUTLINE' = 'flask-round-bottom-empty-outline',
  'FLASK_ROUND_BOTTOM_OUTLINE' = 'flask-round-bottom-outline',
  'FLEUR_DE_LIS' = 'fleur-de-lis',
  'FLIP_HORIZONTAL' = 'flip-horizontal',
  'FLIP_TO_BACK' = 'flip-to-back',
  'FLIP_TO_FRONT' = 'flip-to-front',
  'FLIP_VERTICAL' = 'flip-vertical',
  'FLOOR_LAMP' = 'floor-lamp',
  'FLOOR_LIGHT' = 'floor-light',
  'FLOOR_LAMP_DUAL' = 'floor-lamp-dual',
  'FLOOR_LIGHT_DUAL' = 'floor-light-dual',
  'FLOOR_LAMP_DUAL_OUTLINE' = 'floor-lamp-dual-outline',
  'FLOOR_LIGHT_DUAL_OUTLINE' = 'floor-light-dual-outline',
  'FLOOR_LAMP_OUTLINE' = 'floor-lamp-outline',
  'FLOOR_LIGHT_OUTLINE' = 'floor-light-outline',
  'FLOOR_LAMP_TORCHIERE' = 'floor-lamp-torchiere',
  'FLOOR_LIGHT_TORCHIERE' = 'floor-light-torchiere',
  'FLOOR_LAMP_TORCHIERE_OUTLINE' = 'floor-lamp-torchiere-outline',
  'FLOOR_LAMP_TORCHIERE_VARIANT' = 'floor-lamp-torchiere-variant',
  'FLOOR_LIGHT_TORCHIERE_VARIANT' = 'floor-light-torchiere-variant',
  'FLOOR_LAMP_TORCHIERE_VARIANT_OUTLINE' = 'floor-lamp-torchiere-variant-outline',
  'FLOOR_LIGHT_TORCHIERE_VARIANT_OUTLINE' = 'floor-light-torchiere-variant-outline',
  'FLOOR_PLAN' = 'floor-plan',
  'FLOPPY' = 'floppy',
  'FLOPPY_VARIANT' = 'floppy-variant',
  'FLOWER' = 'flower',
  'LOCAL_FLORIST' = 'local-florist',
  'PLANT' = 'plant',
  'FLOWER_OUTLINE' = 'flower-outline',
  'LOCAL_FLORIST_OUTLINE' = 'local-florist-outline',
  'FLOWER_POLLEN' = 'flower-pollen',
  'FLOWER_POLLEN_OUTLINE' = 'flower-pollen-outline',
  'ALLERGY_OUTLINE' = 'allergy-outline',
  'FLOWER_POPPY' = 'flower-poppy',
  'FLOWER_TULIP' = 'flower-tulip',
  'FLOWER_TULIP_OUTLINE' = 'flower-tulip-outline',
  'FOCUS_AUTO' = 'focus-auto',
  'FOCUS_FIELD' = 'focus-field',
  'FOCUS_FIELD_HORIZONTAL' = 'focus-field-horizontal',
  'FOCUS_FIELD_VERTICAL' = 'focus-field-vertical',
  'FOLDER' = 'folder',
  'FOLDER_ACCOUNT' = 'folder-account',
  'FOLDER_USER' = 'folder-user',
  'FOLDER_SHARED' = 'folder-shared',
  'FOLDER_ACCOUNT_OUTLINE' = 'folder-account-outline',
  'FOLDER_USER_OUTLINE' = 'folder-user-outline',
  'FOLDER_SHARED_OUTLINE' = 'folder-shared-outline',
  'FOLDER_ALERT' = 'folder-alert',
  'FOLDER_WARNING' = 'folder-warning',
  'FOLDER_ALERT_OUTLINE' = 'folder-alert-outline',
  'FOLDER_WARNING_OUTLINE' = 'folder-warning-outline',
  'FOLDER_ARROW_DOWN' = 'folder-arrow-down',
  'FOLDER_DOWNLOAD' = 'folder-download',
  'FOLDER_ARROW_DOWN_OUTLINE' = 'folder-arrow-down-outline',
  'FOLDER_DOWNLOAD_OUTLINE' = 'folder-download-outline',
  'FOLDER_ARROW_LEFT' = 'folder-arrow-left',
  'FOLDER_ARROW_LEFT_OUTLINE' = 'folder-arrow-left-outline',
  'FOLDER_ARROW_LEFT_RIGHT' = 'folder-arrow-left-right',
  'FOLDER_ARROW_LEFT_RIGHT_OUTLINE' = 'folder-arrow-left-right-outline',
  'FOLDER_ARROW_RIGHT' = 'folder-arrow-right',
  'FOLDER_ARROW_RIGHT_OUTLINE' = 'folder-arrow-right-outline',
  'FOLDER_ARROW_UP' = 'folder-arrow-up',
  'FOLDER_UPLOAD' = 'folder-upload',
  'FOLDER_ARROW_UP_DOWN' = 'folder-arrow-up-down',
  'FOLDER_TRANSFER' = 'folder-transfer',
  'FOLDER_ARROW_UP_DOWN_OUTLINE' = 'folder-arrow-up-down-outline',
  'FOLDER_TRANSFER_OUTLINE' = 'folder-transfer-outline',
  'FOLDER_ARROW_UP_OUTLINE' = 'folder-arrow-up-outline',
  'FOLDER_UPLOAD_OUTLINE' = 'folder-upload-outline',
  'FOLDER_CANCEL' = 'folder-cancel',
  'FOLDER_CANCEL_OUTLINE' = 'folder-cancel-outline',
  'FOLDER_CHECK' = 'folder-check',
  'FOLDER_CHECK_OUTLINE' = 'folder-check-outline',
  'FOLDER_CLOCK' = 'folder-clock',
  'FOLDER_CLOCK_OUTLINE' = 'folder-clock-outline',
  'FOLDER_COG' = 'folder-cog',
  'FOLDER_COG_OUTLINE' = 'folder-cog-outline',
  'FOLDER_EDIT' = 'folder-edit',
  'FOLDER_EDIT_OUTLINE' = 'folder-edit-outline',
  'FOLDER_EYE' = 'folder-eye',
  'FOLDER_EYE_OUTLINE' = 'folder-eye-outline',
  'FOLDER_FILE' = 'folder-file',
  'FOLDER_FILE_OUTLINE' = 'folder-file-outline',
  'FOLDER_GOOGLE_DRIVE' = 'folder-google-drive',
  'FOLDER_MYDRIVE' = 'folder-mydrive',
  'FOLDER_HEART' = 'folder-heart',
  'FOLDER_HEART_OUTLINE' = 'folder-heart-outline',
  'FOLDER_HIDDEN' = 'folder-hidden',
  'FOLDER_HOME' = 'folder-home',
  'FOLDER_HOME_OUTLINE' = 'folder-home-outline',
  'FOLDER_IMAGE' = 'folder-image',
  'FOLDER_INFORMATION' = 'folder-information',
  'FOLDER_INFORMATION_OUTLINE' = 'folder-information-outline',
  'FOLDER_KEY' = 'folder-key',
  'FOLDER_KEY_NETWORK' = 'folder-key-network',
  'FOLDER_KEY_NETWORK_OUTLINE' = 'folder-key-network-outline',
  'FOLDER_KEY_OUTLINE' = 'folder-key-outline',
  'FOLDER_LOCK' = 'folder-lock',
  'FOLDER_LOCK_OPEN' = 'folder-lock-open',
  'FOLDER_MARKER' = 'folder-marker',
  'FOLDER_LOCATION' = 'folder-location',
  'FOLDER_MARKER_OUTLINE' = 'folder-marker-outline',
  'FOLDER_LOCATION_OUTLINE' = 'folder-location-outline',
  'FOLDER_MOVE' = 'folder-move',
  'FOLDER_MOVE_OUTLINE' = 'folder-move-outline',
  'FOLDER_MULTIPLE' = 'folder-multiple',
  'FOLDERS' = 'folders',
  'FOLDER_MULTIPLE_IMAGE' = 'folder-multiple-image',
  'PERM_MEDIA' = 'perm-media',
  'FOLDERS_IMAGE' = 'folders-image',
  'FOLDER_MULTIPLE_OUTLINE' = 'folder-multiple-outline',
  'FOLDERS_OUTLINE' = 'folders-outline',
  'FOLDER_MULTIPLE_PLUS' = 'folder-multiple-plus',
  'FOLDER_MULTIPLE_PLUS_OUTLINE' = 'folder-multiple-plus-outline',
  'FOLDER_MUSIC' = 'folder-music',
  'FOLDER_MUSIC_OUTLINE' = 'folder-music-outline',
  'FOLDER_NETWORK' = 'folder-network',
  'FOLDER_NETWORK_OUTLINE' = 'folder-network-outline',
  'FOLDER_OFF' = 'folder-off',
  'FOLDER_OFF_OUTLINE' = 'folder-off-outline',
  'FOLDER_OPEN' = 'folder-open',
  'FOLDER_OPEN_OUTLINE' = 'folder-open-outline',
  'FOLDER_OUTLINE' = 'folder-outline',
  'FOLDER_PLAY' = 'folder-play',
  'FOLDER_MEDIA' = 'folder-media',
  'FOLDER_VIDEO' = 'folder-video',
  'FOLDER_PLAY_OUTLINE' = 'folder-play-outline',
  'FOLDER_MEDIA_OUTLINE' = 'folder-media-outline',
  'FOLDER_VIDEO_OUTLINE' = 'folder-video-outline',
  'FOLDER_PLUS' = 'folder-plus',
  'CREATE_NEW_FOLDER' = 'create-new-folder',
  'FOLDER_ADD' = 'folder-add',
  'FOLDER_PLUS_OUTLINE' = 'folder-plus-outline',
  'CREATE_NEW_FOLDER_OUTLINE' = 'create-new-folder-outline',
  'FOLDER_ADD_OUTLINE' = 'folder-add-outline',
  'FOLDER_POUND' = 'folder-pound',
  'FOLDER_HASH' = 'folder-hash',
  'FOLDER_POUND_OUTLINE' = 'folder-pound-outline',
  'FOLDER_HASH_OUTLINE' = 'folder-hash-outline',
  'FOLDER_QUESTION' = 'folder-question',
  'FOLDER_HELP' = 'folder-help',
  'FOLDER_QUESTION_OUTLINE' = 'folder-question-outline',
  'FOLDER_HELP_OUTLINE' = 'folder-help-outline',
  'FOLDER_REFRESH' = 'folder-refresh',
  'FOLDER_REFRESH_OUTLINE' = 'folder-refresh-outline',
  'FOLDER_REMOVE' = 'folder-remove',
  'FOLDER_REMOVE_OUTLINE' = 'folder-remove-outline',
  'FOLDER_SEARCH' = 'folder-search',
  'FOLDER_SEARCH_OUTLINE' = 'folder-search-outline',
  'FOLDER_SETTINGS' = 'folder-settings',
  'FOLDER_SETTINGS_OUTLINE' = 'folder-settings-outline',
  'FOLDER_STAR' = 'folder-star',
  'FOLDER_SPECIAL' = 'folder-special',
  'FOLDER_FAVORITE' = 'folder-favorite',
  'FOLDER_STAR_MULTIPLE' = 'folder-star-multiple',
  'FOLDER_FAVORITE_MULTIPLE' = 'folder-favorite-multiple',
  'FOLDER_STAR_MULTIPLE_OUTLINE' = 'folder-star-multiple-outline',
  'FOLDER_FAVORITE_MULTIPLE_OUTLINE' = 'folder-favorite-multiple-outline',
  'FOLDER_STAR_OUTLINE' = 'folder-star-outline',
  'FOLDER_SPECIAL_OUTLINE' = 'folder-special-outline',
  'FOLDER_FAVORITE_OUTLINE' = 'folder-favorite-outline',
  'FOLDER_SWAP' = 'folder-swap',
  'FOLDER_SWAP_OUTLINE' = 'folder-swap-outline',
  'FOLDER_SYNC' = 'folder-sync',
  'FOLDER_SYNC_OUTLINE' = 'folder-sync-outline',
  'FOLDER_TABLE' = 'folder-table',
  'FOLDER_TABLE_OUTLINE' = 'folder-table-outline',
  'FOLDER_TEXT' = 'folder-text',
  'FOLDER_TEXT_OUTLINE' = 'folder-text-outline',
  'FOLDER_WRENCH' = 'folder-wrench',
  'FOLDER_WRENCH_OUTLINE' = 'folder-wrench-outline',
  'FOLDER_ZIP' = 'folder-zip',
  'COMPRESSED_FOLDER' = 'compressed-folder',
  'FOLDER_ZIP_OUTLINE' = 'folder-zip-outline',
  'COMPRESSED_FOLDER_OUTLINE' = 'compressed-folder-outline',
  'FONT_AWESOME' = 'font-awesome',
  'FOOD' = 'food',
  'FAST_FOOD' = 'fast-food',
  'BURGER' = 'burger',
  'HAMBURGER' = 'hamburger',
  'FOOD_APPLE' = 'food-apple',
  'FOOD_APPLE_OUTLINE' = 'food-apple-outline',
  'FOOD_CROISSANT' = 'food-croissant',
  'FOOD_DRUMSTICK' = 'food-drumstick',
  'CHICKEN_LEG' = 'chicken-leg',
  'TURKEY_LEG' = 'turkey-leg',
  'MEAT' = 'meat',
  'FOOD_DRUMSTICK_OFF' = 'food-drumstick-off',
  'CHICKEN_LEG_OFF' = 'chicken-leg-off',
  'TURKEY_LEG_OFF' = 'turkey-leg-off',
  'MEAT_OFF' = 'meat-off',
  'FOOD_DRUMSTICK_OFF_OUTLINE' = 'food-drumstick-off-outline',
  'CHICKEN_LEG_OFF_OUTLINE' = 'chicken-leg-off-outline',
  'TURKEY_LEG_OFF_OUTLINE' = 'turkey-leg-off-outline',
  'MEAT_OFF_OUTLINE' = 'meat-off-outline',
  'FOOD_DRUMSTICK_OUTLINE' = 'food-drumstick-outline',
  'CHICKEN_LEG_OUTLINE' = 'chicken-leg-outline',
  'TURKEY_LEG_OUTLINE' = 'turkey-leg-outline',
  'MEAT_OUTLINE' = 'meat-outline',
  'FOOD_FORK_DRINK' = 'food-fork-drink',
  'FOOD_FORK_CUP' = 'food-fork-cup',
  'FOOD_HALAL' = 'food-halal',
  'FOOD_MUSLIM' = 'food-muslim',
  'DIETARY_RESTRICTION' = 'dietary-restriction',
  'FOOD_HOT_DOG' = 'food-hot-dog',
  'FOOD_WEINER' = 'food-weiner',
  'FOOD_FRANKFURTER' = 'food-frankfurter',
  'FOOD_KOSHER' = 'food-kosher',
  'FOOD_JEWISH' = 'food-jewish',
  'FOOD_OFF' = 'food-off',
  'FAST_FOOD_OFF' = 'fast-food-off',
  'BURGER_OFF' = 'burger-off',
  'HAMBURGER_OFF' = 'hamburger-off',
  'FOOD_OFF_OUTLINE' = 'food-off-outline',
  'FOOD_OUTLINE' = 'food-outline',
  'FOOD_STEAK' = 'food-steak',
  'BEEF' = 'beef',
  'FOOD_STEAK_OFF' = 'food-steak-off',
  'BEEF_OFF' = 'beef-off',
  'FOOD_TAKEOUT_BOX' = 'food-takeout-box',
  'FOOD_TAKEOUT_BOX_OUTLINE' = 'food-takeout-box-outline',
  'FOOD_TURKEY' = 'food-turkey',
  'DINNER' = 'dinner',
  'THANKSGIVING' = 'thanksgiving',
  'FOOD_VARIANT' = 'food-variant',
  'FOOD_VARIANT_OFF' = 'food-variant-off',
  'FOOT_PRINT' = 'foot-print',
  'FOOTBALL' = 'football',
  'FOOTBALL_AMERICAN' = 'football-american',
  'FOOTBALL_AUSTRALIAN' = 'football-australian',
  'FOOTBALL_HELMET' = 'football-helmet',
  'FOREST' = 'forest',
  'PINE_TREE' = 'pine-tree',
  'FORKLIFT' = 'forklift',
  'FORM_DROPDOWN' = 'form-dropdown',
  'FORM_SELECT' = 'form-select',
  'FORM_TEXTAREA' = 'form-textarea',
  'FORM_TEXTBOX' = 'form-textbox',
  'RENAME' = 'rename',
  'FORM_TEXTBOX_LOCK' = 'form-textbox-lock',
  'FORM_TEXTBOX_PASSWORD' = 'form-textbox-password',
  'FORMAT_ALIGN_BOTTOM' = 'format-align-bottom',
  'FORMAT_ALIGN_CENTER' = 'format-align-center',
  'FORMAT_ALIGN_CENTRE' = 'format-align-centre',
  'FORMAT_ALIGN_JUSTIFY' = 'format-align-justify',
  'FORMAT_ALIGN_LEFT' = 'format-align-left',
  'FORMAT_ALIGN_MIDDLE' = 'format-align-middle',
  'FORMAT_ALIGN_RIGHT' = 'format-align-right',
  'FORMAT_ALIGN_TOP' = 'format-align-top',
  'FORMAT_ANNOTATION_MINUS' = 'format-annotation-minus',
  'FORMAT_ANNOTATION_PLUS' = 'format-annotation-plus',
  'FORMAT_ANNOTATION_ADD' = 'format-annotation-add',
  'FORMAT_BOLD' = 'format-bold',
  'FORMAT_CLEAR' = 'format-clear',
  'FORMAT_COLOR_FILL' = 'format-color-fill',
  'FORMAT_COLOUR_FILL' = 'format-colour-fill',
  'PAINT' = 'paint',
  'PAINT_BUCKET' = 'paint-bucket',
  'INK_COLOR' = 'ink-color',
  'INK_COLOUR' = 'ink-colour',
  'FORMAT_COLOR_HIGHLIGHT' = 'format-color-highlight',
  'FORMAT_COLOUR_HIGHLIGHT' = 'format-colour-highlight',
  'FORMAT_COLOR_MARKER_CANCEL' = 'format-color-marker-cancel',
  'FORMAT_COLOR_REDACT' = 'format-color-redact',
  'FORMAT_COLOR_TEXT' = 'format-color-text',
  'FORMAT_COLOUR_TEXT' = 'format-colour-text',
  'FORMAT_COLUMNS' = 'format-columns',
  'FORMAT_FLOAT_CENTER' = 'format-float-center',
  'FORMAT_FLOAT_CENTRE' = 'format-float-centre',
  'FORMAT_FLOAT_LEFT' = 'format-float-left',
  'FORMAT_FLOAT_NONE' = 'format-float-none',
  'FORMAT_FLOAT_RIGHT' = 'format-float-right',
  'FORMAT_FONT' = 'format-font',
  'FORMAT_FONT_SIZE_DECREASE' = 'format-font-size-decrease',
  'FORMAT_FONT_SIZE_INCREASE' = 'format-font-size-increase',
  'FORMAT_HEADER1' = 'format-header1',
  'FORMAT_HEADER2' = 'format-header2',
  'FORMAT_HEADER3' = 'format-header3',
  'FORMAT_HEADER4' = 'format-header4',
  'FORMAT_HEADER5' = 'format-header5',
  'FORMAT_HEADER6' = 'format-header6',
  'FORMAT_HEADER_DECREASE' = 'format-header-decrease',
  'FORMAT_HEADING_DECEASE' = 'format-heading-decease',
  'FORMAT_HEADER_EQUAL' = 'format-header-equal',
  'FORMAT_HEADING_EQUAL' = 'format-heading-equal',
  'FORMAT_HEADER_INCREASE' = 'format-header-increase',
  'FORMAT_HEADING_INCREASE' = 'format-heading-increase',
  'FORMAT_HEADER_POUND' = 'format-header-pound',
  'FORMAT_HEADER_HASH' = 'format-header-hash',
  'FORMAT_HEADING_POUND' = 'format-heading-pound',
  'FORMAT_HEADING_HASH' = 'format-heading-hash',
  'FORMAT_HEADING_MARKDOWN' = 'format-heading-markdown',
  'FORMAT_HORIZONTAL_ALIGN_CENTER' = 'format-horizontal-align-center',
  'FORMAT_HORIZONTAL_ALIGN_CENTRE' = 'format-horizontal-align-centre',
  'ARROW_HORIZONTAL_COLLAPSE' = 'arrow-horizontal-collapse',
  'FORMAT_HORIZONTAL_ALIGN_LEFT' = 'format-horizontal-align-left',
  'FORMAT_HORIZONTAL_ALIGN_RIGHT' = 'format-horizontal-align-right',
  'FORMAT_INDENT_DECREASE' = 'format-indent-decrease',
  'FORMAT_INDENT_INCREASE' = 'format-indent-increase',
  'FORMAT_ITALIC' = 'format-italic',
  'FORMAT_LETTER_CASE' = 'format-letter-case',
  'FORMAT_LETTER_CASE_LOWER' = 'format-letter-case-lower',
  'FORMAT_LOWERCASE' = 'format-lowercase',
  'FORMAT_LETTER_CASE_UPPER' = 'format-letter-case-upper',
  'FORMAT_UPPERCASE' = 'format-uppercase',
  'FORMAT_LETTER_ENDS_WITH' = 'format-letter-ends-with',
  'FORMAT_LETTER_MATCHES' = 'format-letter-matches',
  'FORMAT_LETTER_SPACING' = 'format-letter-spacing',
  'FORMAT_KERNING' = 'format-kerning',
  'FORMAT_LETTER_STARTS_WITH' = 'format-letter-starts-with',
  'FORMAT_LINE_SPACING' = 'format-line-spacing',
  'FORMAT_LINE_STYLE' = 'format-line-style',
  'FORMAT_LINE_WEIGHT' = 'format-line-weight',
  'FORMAT_LIST_BULLETED' = 'format-list-bulleted',
  'FORMAT_LIST_BULLETED_SQUARE' = 'format-list-bulleted-square',
  'FORMAT_LIST_BULLETED_TRIANGLE' = 'format-list-bulleted-triangle',
  'FORMAT_LIST_BULLETED_TYPE' = 'format-list-bulleted-type',
  'FORMAT_LIST_CHECKBOX' = 'format-list-checkbox',
  'FORMAT_LIST_CHECKS' = 'format-list-checks',
  'TO_DO' = 'to-do',
  'FORMAT_LIST_GROUP' = 'format-list-group',
  'FORMAT_LIST_NUMBERED' = 'format-list-numbered',
  'FORMAT_LIST_NUMBERS' = 'format-list-numbers',
  'FORMAT_LIST_NUMBERED_RTL' = 'format-list-numbered-rtl',
  'FORMAT_LIST_NUMBERED_RIGHT_TO_LEFT' = 'format-list-numbered-right-to-left',
  'FORMAT_LIST_TEXT' = 'format-list-text',
  'FORMAT_OVERLINE' = 'format-overline',
  'FORMAT_PAGE_BREAK' = 'format-page-break',
  'FORMAT_PAGE_SPLIT' = 'format-page-split',
  'FORMAT_PAINT' = 'format-paint',
  'FORMAT_PARAGRAPH' = 'format-paragraph',
  'FORMAT_PILCROW' = 'format-pilcrow',
  'FORMAT_QUOTE_CLOSE' = 'format-quote-close',
  'FORMAT_QUOTE_CLOSE_OUTLINE' = 'format-quote-close-outline',
  'FORMAT_QUOTE_OPEN' = 'format-quote-open',
  'FORMAT_QUOTE_OPEN_OUTLINE' = 'format-quote-open-outline',
  'FORMAT_ROTATE90' = 'format-rotate90',
  'FORMAT_SECTION' = 'format-section',
  'FORMAT_SIZE' = 'format-size',
  'FONT_SIZE' = 'font-size',
  'FORMAT_STRIKETHROUGH' = 'format-strikethrough',
  'FORMAT_STRIKETHROUGH_VARIANT' = 'format-strikethrough-variant',
  'STRIKETHROUGH_S' = 'strikethrough-s',
  'FORMAT_SUBSCRIPT' = 'format-subscript',
  'FORMAT_SUPERSCRIPT' = 'format-superscript',
  'FORMAT_TEXT' = 'format-text',
  'FORMAT_TEXT_ROTATION_ANGLE_DOWN' = 'format-text-rotation-angle-down',
  'FORMAT_TEXT_ROTATION_ANGLE_UP' = 'format-text-rotation-angle-up',
  'FORMAT_TEXT_ROTATION_DOWN' = 'format-text-rotation-down',
  'FORMAT_TEXT_ROTATION_DOWN_VERTICAL' = 'format-text-rotation-down-vertical',
  'FORMAT_TEXT_ROTATION_NONE' = 'format-text-rotation-none',
  'FORMAT_TEXT_ROTATION_UP' = 'format-text-rotation-up',
  'FORMAT_TEXT_ROTATION_VERTICAL' = 'format-text-rotation-vertical',
  'FORMAT_TEXT_VARIANT' = 'format-text-variant',
  'FORMAT_TEXT_VARIANT_OUTLINE' = 'format-text-variant-outline',
  'FORMAT_TEXT_WRAPPING_CLIP' = 'format-text-wrapping-clip',
  'FORMAT_TEXT_WRAPPING_OVERFLOW' = 'format-text-wrapping-overflow',
  'FORMAT_TEXT_WRAPPING_WRAP' = 'format-text-wrapping-wrap',
  'FORMAT_TEXTBOX' = 'format-textbox',
  'FORMAT_TEXTDIRECTION_LTO_R' = 'format-textdirection-lto-r',
  'FORMAT_TEXTDIRECTION_RTO_L' = 'format-textdirection-rto-l',
  'FORMAT_TITLE' = 'format-title',
  'FORMAT_UNDERLINE' = 'format-underline',
  'FORMAT_UNDERLINED' = 'format-underlined',
  'FORMAT_UNDERLINE_WAVY' = 'format-underline-wavy',
  'FORMAT_VERTICAL_ALIGN_BOTTOM' = 'format-vertical-align-bottom',
  'FORMAT_VERTICAL_ALIGN_CENTER' = 'format-vertical-align-center',
  'FORMAT_VERTICAL_ALIGN_CENTRE' = 'format-vertical-align-centre',
  'ARROW_VERTICAL_COLLAPSE' = 'arrow-vertical-collapse',
  'FORMAT_VERTICAL_ALIGN_TOP' = 'format-vertical-align-top',
  'FORMAT_WRAP_INLINE' = 'format-wrap-inline',
  'FORMAT_WRAP_SQUARE' = 'format-wrap-square',
  'FORMAT_WRAP_TIGHT' = 'format-wrap-tight',
  'FORMAT_WRAP_TOP_BOTTOM' = 'format-wrap-top-bottom',
  'FORUM' = 'forum',
  'MESSAGE_GROUP' = 'message-group',
  'QUESTION_ANSWER' = 'question-answer',
  'FORUM_OUTLINE' = 'forum-outline',
  'FORWARD' = 'forward',
  'FORWARDBURGER' = 'forwardburger',
  'FOUNTAIN' = 'fountain',
  'FOUNTAIN_PEN' = 'fountain-pen',
  'FOUNTAIN_PEN_TIP' = 'fountain-pen-tip',
  'FRACTION_ONE_HALF' = 'fraction-one-half',
  'FREEBSD' = 'freebsd',
  'FRENCH_FRIES' = 'french-fries',
  'CHIPS' = 'chips',
  'FINGER_CHIPS' = 'finger-chips',
  'FRENCH_FRY' = 'french-fry',
  'FRIED_POTATOES' = 'fried-potatoes',
  'FRIES' = 'fries',
  'FRITES' = 'frites',
  'FREQUENTLY_ASKED_QUESTIONS' = 'frequently-asked-questions',
  'FAQ' = 'faq',
  'FRIDGE' = 'fridge',
  'FRIDGE_FILLED' = 'fridge-filled',
  'REFRIGERATOR' = 'refrigerator',
  'KITCHEN' = 'kitchen',
  'FRIDGE_ALERT' = 'fridge-alert',
  'FRIDGE_ALERT_OUTLINE' = 'fridge-alert-outline',
  'FRIDGE_BOTTOM' = 'fridge-bottom',
  'FRIDGE_FILLED_TOP' = 'fridge-filled-top',
  'REFRIGERATOR_BOTTOM' = 'refrigerator-bottom',
  'FRIDGE_INDUSTRIAL' = 'fridge-industrial',
  'FRIDGE_INDUSTRIAL_ALERT' = 'fridge-industrial-alert',
  'FRIDGE_INDUSTRIAL_ALERT_OUTLINE' = 'fridge-industrial-alert-outline',
  'FRIDGE_INDUSTRIAL_OFF' = 'fridge-industrial-off',
  'FRIDGE_INDUSTRIAL_OFF_OUTLINE' = 'fridge-industrial-off-outline',
  'FRIDGE_INDUSTRIAL_OUTLINE' = 'fridge-industrial-outline',
  'FRIDGE_OFF' = 'fridge-off',
  'FRIDGE_OFF_OUTLINE' = 'fridge-off-outline',
  'FRIDGE_OUTLINE' = 'fridge-outline',
  'REFRIGERATOR_OUTLINE' = 'refrigerator-outline',
  'FRIDGE_TOP' = 'fridge-top',
  'FRIDGE_FILLED_BOTTOM' = 'fridge-filled-bottom',
  'REFRIGERATOR_TOP' = 'refrigerator-top',
  'FRIDGE_VARIANT' = 'fridge-variant',
  'FRIDGE_VARIANT_ALERT' = 'fridge-variant-alert',
  'FRIDGE_VARIANT_ALERT_OUTLINE' = 'fridge-variant-alert-outline',
  'FRIDGE_VARIANT_OFF' = 'fridge-variant-off',
  'FRIDGE_VARIANT_OFF_OUTLINE' = 'fridge-variant-off-outline',
  'FRIDGE_VARIANT_OUTLINE' = 'fridge-variant-outline',
  'FRUIT_CHERRIES' = 'fruit-cherries',
  'FRUIT_CHERRIES_OFF' = 'fruit-cherries-off',
  'FRUIT_CITRUS' = 'fruit-citrus',
  'FRUIT_LEMON' = 'fruit-lemon',
  'FRUIT_LIME' = 'fruit-lime',
  'FRUIT_CITRUS_OFF' = 'fruit-citrus-off',
  'FRUIT_GRAPES' = 'fruit-grapes',
  'FRUIT_GRAPES_OUTLINE' = 'fruit-grapes-outline',
  'FRUIT_PEAR' = 'fruit-pear',
  'FRUIT_PINEAPPLE' = 'fruit-pineapple',
  'FRUIT_ANANAS' = 'fruit-ananas',
  'FRUIT_WATERMELON' = 'fruit-watermelon',
  'FUEL' = 'fuel',
  'PETROL' = 'petrol',
  'GASOLINE' = 'gasoline',
  'FUEL_CELL' = 'fuel-cell',
  'FULLSCREEN' = 'fullscreen',
  'FULLSCREEN_EXIT' = 'fullscreen-exit',
  'FUNCTION' = 'function',
  'FUNCTION_VARIANT' = 'function-variant',
  'FURIGANA_HORIZONTAL' = 'furigana-horizontal',
  'RUBY_HORIZONTAL' = 'ruby-horizontal',
  'FURIGANA_VERTICAL' = 'furigana-vertical',
  'ZHUYIN' = 'zhuyin',
  'RUBY_VERTICAL' = 'ruby-vertical',
  'FUSE' = 'fuse',
  'FUSE_ALERT' = 'fuse-alert',
  'FUSE_BLADE' = 'fuse-blade',
  'FUSE_OFF' = 'fuse-off',
  'GAMEPAD' = 'gamepad',
  'GAMES' = 'games',
  'CONTROLLER' = 'controller',
  'GAMEPAD_CIRCLE' = 'gamepad-circle',
  'CONTROLLER_CIRCLE' = 'controller-circle',
  'GAMEPAD_CIRCLE_DOWN' = 'gamepad-circle-down',
  'CONTROLLER_CIRCLE_DOWN' = 'controller-circle-down',
  'GAMEPAD_CIRCLE_LEFT' = 'gamepad-circle-left',
  'CONTROLLER_CIRCLE_LEFT' = 'controller-circle-left',
  'GAMEPAD_CIRCLE_OUTLINE' = 'gamepad-circle-outline',
  'CONTROLLER_CIRCLE_OUTLINE' = 'controller-circle-outline',
  'GAMEPAD_CIRCLE_RIGHT' = 'gamepad-circle-right',
  'CONTROLLER_CIRCLE_RIGHT' = 'controller-circle-right',
  'GAMEPAD_CIRCLE_UP' = 'gamepad-circle-up',
  'CONTROLLER_CIRCLE_UP' = 'controller-circle-up',
  'GAMEPAD_DOWN' = 'gamepad-down',
  'CONTROLLER_DOWN' = 'controller-down',
  'GAMEPAD_LEFT' = 'gamepad-left',
  'CONTROLLER_LEFT' = 'controller-left',
  'GAMEPAD_OUTLINE' = 'gamepad-outline',
  'CONTROLLER_OUTLINE' = 'controller-outline',
  'GAMES_OUTLINE' = 'games-outline',
  'GAMEPAD_RIGHT' = 'gamepad-right',
  'CONTROLLER_RIGHT' = 'controller-right',
  'GAMEPAD_ROUND' = 'gamepad-round',
  'CONTROLLER_ROUND' = 'controller-round',
  'GAMEPAD_ROUND_DOWN' = 'gamepad-round-down',
  'CONTROLLER_ROUND_DOWN' = 'controller-round-down',
  'GAMEPAD_ROUND_LEFT' = 'gamepad-round-left',
  'CONTROLLER_ROUND_LEFT' = 'controller-round-left',
  'GAMEPAD_ROUND_OUTLINE' = 'gamepad-round-outline',
  'CONTROLLER_ROUND_OUTLINE' = 'controller-round-outline',
  'GAMEPAD_ROUND_RIGHT' = 'gamepad-round-right',
  'CONTROLLER_ROUND_RIGHT' = 'controller-round-right',
  'GAMEPAD_ROUND_UP' = 'gamepad-round-up',
  'CONTROLLER_ROUND_UP' = 'controller-round-up',
  'GAMEPAD_SQUARE' = 'gamepad-square',
  'CONTROLLER_SQUARE' = 'controller-square',
  'GAMEPAD_SQUARE_OUTLINE' = 'gamepad-square-outline',
  'CONTROLLER_SQUARE_OUTLINE' = 'controller-square-outline',
  'GAMEPAD_UP' = 'gamepad-up',
  'CONTROLLER_UP' = 'controller-up',
  'GAMEPAD_VARIANT' = 'gamepad-variant',
  'CONTROLLER_VARIANT' = 'controller-variant',
  'GAMEPAD_VARIANT_OUTLINE' = 'gamepad-variant-outline',
  'CONTROLLER_VARIANT_OUTLINE' = 'controller-variant-outline',
  'GAMMA' = 'gamma',
  'GANTRY_CRANE' = 'gantry-crane',
  'GARAGE' = 'garage',
  'GARAGE_ALERT' = 'garage-alert',
  'GARAGE_WARNING' = 'garage-warning',
  'GARAGE_ALERT_VARIANT' = 'garage-alert-variant',
  'GARAGE_LOCK' = 'garage-lock',
  'GARAGE_OPEN' = 'garage-open',
  'GARAGE_OPEN_VARIANT' = 'garage-open-variant',
  'GARAGE_VARIANT' = 'garage-variant',
  'GARAGE_VARIANT_LOCK' = 'garage-variant-lock',
  'GAS_BURNER' = 'gas-burner',
  'STOVE_BURNER' = 'stove-burner',
  'COOKTOP_BURNER' = 'cooktop-burner',
  'GRILL' = 'grill',
  'GAS_CYLINDER' = 'gas-cylinder',
  'TANK' = 'tank',
  'OXYGEN_TANK' = 'oxygen-tank',
  'GAS_STATION' = 'gas-station',
  'GAS_PUMP' = 'gas-pump',
  'PETROL_PUMP' = 'petrol-pump',
  'PETROL_STATION' = 'petrol-station',
  'LOCAL_GAS_STATION' = 'local-gas-station',
  'FUEL_STATION' = 'fuel-station',
  'FUEL_PUMP' = 'fuel-pump',
  'GAS_STATION_OFF' = 'gas-station-off',
  'GAS_STATION_OFF_OUTLINE' = 'gas-station-off-outline',
  'GAS_STATION_OUTLINE' = 'gas-station-outline',
  'GAS_PUMP_OUTLINE' = 'gas-pump-outline',
  'PETROL_PUMP_OUTLINE' = 'petrol-pump-outline',
  'PETROL_STATION_OUTLINE' = 'petrol-station-outline',
  'FUEL_STATION_OUTLINE' = 'fuel-station-outline',
  'FUEL_PUMP_OUTLINE' = 'fuel-pump-outline',
  'GATE' = 'gate',
  'GATE_ALERT' = 'gate-alert',
  'GATE_AND' = 'gate-and',
  'LOGIC_GATE_AND' = 'logic-gate-and',
  'GATE_ARROW_LEFT' = 'gate-arrow-left',
  'GATE_ARROW_RIGHT' = 'gate-arrow-right',
  'GATE_NAND' = 'gate-nand',
  'LOGIC_GATE_NAND' = 'logic-gate-nand',
  'GATE_NOR' = 'gate-nor',
  'LOGIC_GATE_NOR' = 'logic-gate-nor',
  'GATE_NOT' = 'gate-not',
  'LOGIC_GATE_NOT' = 'logic-gate-not',
  'GATE_OPEN' = 'gate-open',
  'GATE_OR' = 'gate-or',
  'LOGIC_GATE_OR' = 'logic-gate-or',
  'GATE_XNOR' = 'gate-xnor',
  'LOGIC_GATE_XNOR' = 'logic-gate-xnor',
  'GATE_XOR' = 'gate-xor',
  'LOGIC_GATE_XOR' = 'logic-gate-xor',
  'GATSBY' = 'gatsby',
  'GAUGE' = 'gauge',
  'SWAP_DRIVING_APPS_WHEEL' = 'swap-driving-apps-wheel',
  'BAROMETER' = 'barometer',
  'GAUGE_EMPTY' = 'gauge-empty',
  'GAUGE_FULL' = 'gauge-full',
  'GAUGE_LOW' = 'gauge-low',
  'GAVEL' = 'gavel',
  'COURT_HAMMER' = 'court-hammer',
  'GENDER_FEMALE' = 'gender-female',
  'VENUS' = 'venus',
  'GENDER_MALE' = 'gender-male',
  'MARS' = 'mars',
  'GENDER_MALE_FEMALE' = 'gender-male-female',
  'GENDER_MALE_FEMALE_VARIANT' = 'gender-male-female-variant',
  'MERCURY' = 'mercury',
  'GENDER_NON_BINARY' = 'gender-non-binary',
  'GENDER_ENBY' = 'gender-enby',
  'GENDER_TRANSGENDER' = 'gender-transgender',
  'GENTOO' = 'gentoo',
  'GESTURE' = 'gesture',
  'FREEHAND_LINE' = 'freehand-line',
  'GESTURE_DOUBLE_TAP' = 'gesture-double-tap',
  'INTERACTION_DOUBLE_TAP' = 'interaction-double-tap',
  'HAND_DOUBLE_TAP' = 'hand-double-tap',
  'GESTURE_PINCH' = 'gesture-pinch',
  'GESTURE_SPREAD' = 'gesture-spread',
  'GESTURE_SWIPE' = 'gesture-swipe',
  'GESTURE_SWIPE_DOWN' = 'gesture-swipe-down',
  'GESTURE_SWIPE_HORIZONTAL' = 'gesture-swipe-horizontal',
  'GESTURE_SWIPE_LEFT' = 'gesture-swipe-left',
  'GESTURE_SWIPE_RIGHT' = 'gesture-swipe-right',
  'GESTURE_SWIPE_UP' = 'gesture-swipe-up',
  'GESTURE_SWIPE_VERTICAL' = 'gesture-swipe-vertical',
  'GESTURE_TAP' = 'gesture-tap',
  'INTERACTION_TAP' = 'interaction-tap',
  'HAND_TAP' = 'hand-tap',
  'GESTURE_TAP_BOX' = 'gesture-tap-box',
  'GESTURE_TAP_BUTTON' = 'gesture-tap-button',
  'CALL_TO_ACTION' = 'call-to-action',
  'CTA' = 'cta',
  'GESTURE_TAP_HOLD' = 'gesture-tap-hold',
  'GESTURE_TWO_DOUBLE_TAP' = 'gesture-two-double-tap',
  'GESTURE_TWO_TAP' = 'gesture-two-tap',
  'GHOST' = 'ghost',
  'INKY' = 'inky',
  'BLINKY' = 'blinky',
  'PINKY' = 'pinky',
  'CLYDE' = 'clyde',
  'GHOST_OFF' = 'ghost-off',
  'GHOST_OFF_OUTLINE' = 'ghost-off-outline',
  'GHOST_OUTLINE' = 'ghost-outline',
  'GIFT' = 'gift',
  'PRESENT' = 'present',
  'PACKAGE' = 'package',
  'DONATE' = 'donate',
  'GIFT_OFF' = 'gift-off',
  'PRESENT_OFF' = 'present-off',
  'PACKAGE_OFF' = 'package-off',
  'DONATE_OFF' = 'donate-off',
  'GIFT_OFF_OUTLINE' = 'gift-off-outline',
  'PRESENT_OFF_OUTLINE' = 'present-off-outline',
  'PACKAGE_OFF_OUTLINE' = 'package-off-outline',
  'DONATE_OFF_OUTLINE' = 'donate-off-outline',
  'GIFT_OPEN' = 'gift-open',
  'PRESENT_OPEN' = 'present-open',
  'PACKAGE_OPEN' = 'package-open',
  'GIFT_OPEN_OUTLINE' = 'gift-open-outline',
  'PRESENT_OPEN_OUTLINE' = 'present-open-outline',
  'PACKAGE_OPEN_OUTLINE' = 'package-open-outline',
  'GIFT_OUTLINE' = 'gift-outline',
  'DONATE_OUTLINE' = 'donate-outline',
  'PRESENT_OUTLINE' = 'present-outline',
  'PACKAGE_OUTLINE' = 'package-outline',
  'GIT' = 'git',
  'GITHUB' = 'github',
  'MICROSOFT_GITHUB' = 'microsoft-github',
  'GITLAB' = 'gitlab',
  'GLASS_COCKTAIL' = 'glass-cocktail',
  'LOCAL_BAR' = 'local-bar',
  'COCKTAIL' = 'cocktail',
  'MARTINI' = 'martini',
  'ALCOHOL' = 'alcohol',
  'GLASS_COCKTAIL_OFF' = 'glass-cocktail-off',
  'GLASS_FLUTE' = 'glass-flute',
  'GLASS_FRAGILE' = 'glass-fragile',
  'GLASS_BROKEN' = 'glass-broken',
  'GLASS_MUG' = 'glass-mug',
  'GLASS_MUG_OFF' = 'glass-mug-off',
  'GLASS_MUG_VARIANT' = 'glass-mug-variant',
  'GLASS_MUG_VARIANT_OFF' = 'glass-mug-variant-off',
  'GLASS_PINT_OUTLINE' = 'glass-pint-outline',
  'GLASS_STANGE' = 'glass-stange',
  'GLASS_TULIP' = 'glass-tulip',
  'GLASS_WINE' = 'glass-wine',
  'GLASSES' = 'glasses',
  'GLOBE_LIGHT' = 'globe-light',
  'GLOBE_MODEL' = 'globe-model',
  'GMAIL' = 'gmail',
  'GNOME' = 'gnome',
  'GO_KART' = 'go-kart',
  'GO_KART_TRACK' = 'go-kart-track',
  'GOG' = 'gog',
  'GOG_COM' = 'gog-com',
  'GOLD' = 'gold',
  'GOLF' = 'golf',
  'GOLF_COURSE' = 'golf-course',
  'GOLF_CART' = 'golf-cart',
  'GOLF_TEE' = 'golf-tee',
  'GONDOLA' = 'gondola',
  'CABLE_CAR' = 'cable-car',
  'GOODREADS' = 'goodreads',
  'GOOGLE' = 'google',
  'GOOGLE_ADS' = 'google-ads',
  'GOOGLE_ADWORDS' = 'google-adwords',
  'GOOGLE_ANALYTICS' = 'google-analytics',
  'GOOGLE_ASSISTANT' = 'google-assistant',
  'GOOGLE_CARDBOARD' = 'google-cardboard',
  'GOOGLE_CHROME' = 'google-chrome',
  'CHROMECAST' = 'chromecast',
  'GOOGLE_CIRCLES' = 'google-circles',
  'GOOGLE_CIRCLES_COMMUNITIES' = 'google-circles-communities',
  'GOOGLE_CIRCLES_EXTENDED' = 'google-circles-extended',
  'GOOGLE_CIRCLES_GROUP' = 'google-circles-group',
  'GOOGLE_CLASSROOM' = 'google-classroom',
  'GOOGLE_CLOUD' = 'google-cloud',
  'GOOGLE_CONTROLLER' = 'google-controller',
  'GOOGLE_GAMEPAD' = 'google-gamepad',
  'GOOGLE_CONTROLLER_OFF' = 'google-controller-off',
  'GOOGLE_GAMEPAD_OFF' = 'google-gamepad-off',
  'GOOGLE_DOWNASAUR' = 'google-downasaur',
  'DINOSAUR_PIXEL' = 'dinosaur-pixel',
  'T_REX' = 't-rex',
  'TYRANNOSAURUS_REX' = 'tyrannosaurus-rex',
  'GOOGLE_DRIVE' = 'google-drive',
  'ATTACH_DRIVE' = 'attach-drive',
  'GOOGLE_EARTH' = 'google-earth',
  'MARBLE' = 'marble',
  'GOOGLE_FIT' = 'google-fit',
  'GOOGLE_GLASS' = 'google-glass',
  'GOOGLE_HANGOUTS' = 'google-hangouts',
  'GOOGLE_HOME' = 'google-home',
  'GOOGLE_KEEP' = 'google-keep',
  'GOOGLE_LENS' = 'google-lens',
  'GOOGLE_MAPS' = 'google-maps',
  'GOOGLE_MY_BUSINESS' = 'google-my-business',
  'GOOGLE_NEARBY' = 'google-nearby',
  'GOOGLE_PLAY' = 'google-play',
  'GOOGLE_PLUS' = 'google-plus',
  'GOOGLE_PODCAST' = 'google-podcast',
  'GOOGLE_SPREADSHEET' = 'google-spreadsheet',
  'GOOGLE_STREET_VIEW' = 'google-street-view',
  'PEGMAN' = 'pegman',
  'GOOGLE_TRANSLATE' = 'google-translate',
  'G_TRANSLATE' = 'g-translate',
  'GRADIENT_HORIZONTAL' = 'gradient-horizontal',
  'GRADIENT_VERTICAL' = 'gradient-vertical',
  'GRAPH' = 'graph',
  'DEPENDENCY' = 'dependency',
  'DEPENDENCIES' = 'dependencies',
  'GRAPH_OUTLINE' = 'graph-outline',
  'GRAPHQL' = 'graphql',
  'GRASS' = 'grass',
  'LAWN' = 'lawn',
  'GRAVE_STONE' = 'grave-stone',
  'HEADSTONE' = 'headstone',
  'TOMBSTONE' = 'tombstone',
  'CEMETERY' = 'cemetery',
  'GRAVEYARD' = 'graveyard',
  'GREASE_PENCIL' = 'grease-pencil',
  'GREATER_THAN' = 'greater-than',
  'GREATER_THAN_OR_EQUAL' = 'greater-than-or-equal',
  'GREENHOUSE' = 'greenhouse',
  'GLASSHOUSE' = 'glasshouse',
  'HOTHOUSE' = 'hothouse',
  'SHED' = 'shed',
  'GRID' = 'grid',
  'GRID_ON' = 'grid-on',
  'GRID_LARGE' = 'grid-large',
  'GRID_OFF' = 'grid-off',
  'BBQ' = 'bbq',
  'BARBECUE' = 'barbecue',
  'CHARCOAL' = 'charcoal',
  'GRILL_OUTLINE' = 'grill-outline',
  'BARBECUE_OUTLINE' = 'barbecue-outline',
  'BBQ_OUTLINE' = 'bbq-outline',
  'CHARCOAL_OUTLINE' = 'charcoal-outline',
  'GUITAR_ACOUSTIC' = 'guitar-acoustic',
  'GUITAR_ELECTRIC' = 'guitar-electric',
  'GUITAR_PICK' = 'guitar-pick',
  'GUITAR_PICK_OUTLINE' = 'guitar-pick-outline',
  'GUY_FAWKES_MASK' = 'guy-fawkes-mask',
  'HAIL' = 'hail',
  'HAIL_TAXI' = 'hail-taxi',
  'HAIL_CAB' = 'hail-cab',
  'HAIR_DRYER' = 'hair-dryer',
  'HAIR_DRYER_OUTLINE' = 'hair-dryer-outline',
  'HALLOWEEN' = 'halloween',
  'PUMPKIN_FACE' = 'pumpkin-face',
  'PUMPKIN_CARVED' = 'pumpkin-carved',
  'JACK_O_LANTERN' = 'jack-o-lantern',
  'EMOJI_HALLOWEEN' = 'emoji-halloween',
  'EMOTICON_HALLOWEEN' = 'emoticon-halloween',
  'HAMBURGER_CHECK' = 'hamburger-check',
  'BURGER_CHECK' = 'burger-check',
  'HAMBURGER_MINUS' = 'hamburger-minus',
  'BURGER_MINUS' = 'burger-minus',
  'HAMBURGER_PLUS' = 'hamburger-plus',
  'BURGER_PLUS' = 'burger-plus',
  'BURGER_ADD' = 'burger-add',
  'HAMBURGER_REMOVE' = 'hamburger-remove',
  'BURGER_REMOVE' = 'burger-remove',
  'HAMMER' = 'hammer',
  'HAMMER_SCREWDRIVER' = 'hammer-screwdriver',
  'TOOLS' = 'tools',
  'HAMMER_SICKLE' = 'hammer-sickle',
  'COMMUNISM' = 'communism',
  'HAMMER_WRENCH' = 'hammer-wrench',
  'HAND_BACK_LEFT' = 'hand-back-left',
  'HAND_BACK_LEFT_OFF' = 'hand-back-left-off',
  'HAND_BACK_LEFT_OFF_OUTLINE' = 'hand-back-left-off-outline',
  'HAND_BACK_LEFT_OUTLINE' = 'hand-back-left-outline',
  'HAND_BACK_RIGHT' = 'hand-back-right',
  'HAND_BACK_RIGHT_OFF' = 'hand-back-right-off',
  'HAND_BACK_RIGHT_OFF_OUTLINE' = 'hand-back-right-off-outline',
  'HAND_BACK_RIGHT_OUTLINE' = 'hand-back-right-outline',
  'HAND_CLAP' = 'hand-clap',
  'APPLAUSE' = 'applause',
  'HAND_COIN' = 'hand-coin',
  'DONATION' = 'donation',
  'HAND_COIN_OUTLINE' = 'hand-coin-outline',
  'CHARITY_OUTLINE' = 'charity-outline',
  'DONATION_OUTLINE' = 'donation-outline',
  'HAND_EXTENDED' = 'hand-extended',
  'HAND_OPEN' = 'hand-open',
  'HAND_PALM' = 'hand-palm',
  'HAND_EXTENDED_OUTLINE' = 'hand-extended-outline',
  'HAND_OPEN_OUTLINE' = 'hand-open-outline',
  'HAND_PALM_OUTLINE' = 'hand-palm-outline',
  'HAND_FRONT_LEFT' = 'hand-front-left',
  'HAND_FRONT_LEFT_OUTLINE' = 'hand-front-left-outline',
  'HAND_FRONT_RIGHT' = 'hand-front-right',
  'HAND_FRONT_RIGHT_OUTLINE' = 'hand-front-right-outline',
  'HAND_HEART' = 'hand-heart',
  'VOLUNTEER' = 'volunteer',
  'LOVE' = 'love',
  'HOPE' = 'hope',
  'HAND_HEART_OUTLINE' = 'hand-heart-outline',
  'HAND_OKAY' = 'hand-okay',
  'HAND_PEACE' = 'hand-peace',
  'HAND_PEACE_VARIANT' = 'hand-peace-variant',
  'HAND_POINTING_DOWN' = 'hand-pointing-down',
  'HAND_POINTING_LEFT' = 'hand-pointing-left',
  'HAND_POINTING_RIGHT' = 'hand-pointing-right',
  'HAND_POINTING_UP' = 'hand-pointing-up',
  'HAND_SAW' = 'hand-saw',
  'HAND_WASH' = 'hand-wash',
  'HAND_WASH_OUTLINE' = 'hand-wash-outline',
  'HAND_WATER' = 'hand-water',
  'HAND_WAVE' = 'hand-wave',
  'GREETING' = 'greeting',
  'FAREWELL' = 'farewell',
  'HAND_WAVE_OUTLINE' = 'hand-wave-outline',
  'GREETING_OUTLINE' = 'greeting-outline',
  'FAREWELL_OUTLINE' = 'farewell-outline',
  'HANDBALL' = 'handball',
  'VOLLEYBALL' = 'volleyball',
  'HUMAN_HANDBALL' = 'human-handball',
  'HANDCUFFS' = 'handcuffs',
  'HANDS_PRAY' = 'hands-pray',
  'HANDSHAKE' = 'handshake',
  'DEAL' = 'deal',
  'HELP' = 'help',
  'PARTNERSHIP' = 'partnership',
  'HANDSHAKE_OUTLINE' = 'handshake-outline',
  'BUSINESS_OUTLINE' = 'business-outline',
  'DEAL_OUTLINE' = 'deal-outline',
  'HELP_OUTLINE' = 'help-outline',
  'PARTNERSHIP_OUTLINE' = 'partnership-outline',
  'HANGER' = 'hanger',
  'COAT_HANGER' = 'coat-hanger',
  'CLOTHES_HANGER' = 'clothes-hanger',
  'CLOSET' = 'closet',
  'HARD_HAT' = 'hard-hat',
  'HELMET' = 'helmet',
  'HARDDISK' = 'harddisk',
  'HDD' = 'hdd',
  'HARDDISK_PLUS' = 'harddisk-plus',
  'HDD_PLUS' = 'hdd-plus',
  'HARDDISK_REMOVE' = 'harddisk-remove',
  'HDD_REMOVE' = 'hdd-remove',
  'HAT_FEDORA' = 'hat-fedora',
  'HAZARD_LIGHTS' = 'hazard-lights',
  'WARNING_LIGHTS' = 'warning-lights',
  'HDR' = 'hdr',
  'HDR_OFF' = 'hdr-off',
  'HEAD' = 'head',
  'HEAD_ALERT' = 'head-alert',
  'HEAD_ALERT_OUTLINE' = 'head-alert-outline',
  'HEAD_CHECK' = 'head-check',
  'HEAD_CHECK_OUTLINE' = 'head-check-outline',
  'HEAD_COG' = 'head-cog',
  'PSYCHOLOGY' = 'psychology',
  'HEAD_COG_OUTLINE' = 'head-cog-outline',
  'PSYCHOLOGY_OUTLINE' = 'psychology-outline',
  'HEAD_DOTS_HORIZONTAL' = 'head-dots-horizontal',
  'HEAD_THINKING' = 'head-thinking',
  'HEAD_DOTS_HORIZONTAL_OUTLINE' = 'head-dots-horizontal-outline',
  'HEAD_THINKING_OUTLINE' = 'head-thinking-outline',
  'HEAD_FLASH' = 'head-flash',
  'HEAD_ACHE' = 'head-ache',
  'HEAD_FLASH_OUTLINE' = 'head-flash-outline',
  'HEAD_ACHE_OUTLINE' = 'head-ache-outline',
  'HEAD_HEART' = 'head-heart',
  'HEAD_LOVE' = 'head-love',
  'HEAD_HEART_OUTLINE' = 'head-heart-outline',
  'HEAD_LOVE_OUTLINE' = 'head-love-outline',
  'HEAD_LIGHTBULB' = 'head-lightbulb',
  'HEAD_IDEA' = 'head-idea',
  'HEAD_BULB' = 'head-bulb',
  'HEAD_LIGHTBULB_OUTLINE' = 'head-lightbulb-outline',
  'HEAD_IDEA_OUTLINE' = 'head-idea-outline',
  'HEAD_BULB_OUTLINE' = 'head-bulb-outline',
  'HEAD_MINUS' = 'head-minus',
  'HEAD_MINUS_OUTLINE' = 'head-minus-outline',
  'HEAD_OUTLINE' = 'head-outline',
  'HEAD_PLUS' = 'head-plus',
  'HEAD_PLUS_OUTLINE' = 'head-plus-outline',
  'HEAD_QUESTION' = 'head-question',
  'HEAD_QUESTION_OUTLINE' = 'head-question-outline',
  'HEAD_REMOVE' = 'head-remove',
  'HEAD_REMOVE_OUTLINE' = 'head-remove-outline',
  'HEAD_SNOWFLAKE' = 'head-snowflake',
  'HEAD_FREEZE' = 'head-freeze',
  'BRAIN_FREEZE' = 'brain-freeze',
  'HEAD_SNOWFLAKE_OUTLINE' = 'head-snowflake-outline',
  'HEAD_FREEZE_OUTLINE' = 'head-freeze-outline',
  'BRAIN_FREEZE_OUTLINE' = 'brain-freeze-outline',
  'HEAD_SYNC' = 'head-sync',
  'HEAD_RELOAD' = 'head-reload',
  'HEAD_REFRESH' = 'head-refresh',
  'HEAD_SYNC_OUTLINE' = 'head-sync-outline',
  'HEAD_RELOAD_OUTLINE' = 'head-reload-outline',
  'HEAD_REFRESH_OUTLINE' = 'head-refresh-outline',
  'HEADSET' = 'headset',
  'HEADPHONES_BLUETOOTH' = 'headphones-bluetooth',
  'HEADPHONES_BOX' = 'headphones-box',
  'HEADPHONES_SETTINGS' = 'headphones-settings',
  'HEADSET_MIC' = 'headset-mic',
  'HEADSET_DOCK' = 'headset-dock',
  'HEADSET_OFF' = 'headset-off',
  'HEART' = 'heart',
  'FAVORITE' = 'favorite',
  'FAVOURITE' = 'favourite',
  'HEART_BOX' = 'heart-box',
  'HEART_BOX_OUTLINE' = 'heart-box-outline',
  'HEART_BROKEN' = 'heart-broken',
  'HEART_BROKEN_OUTLINE' = 'heart-broken-outline',
  'HEART_CIRCLE' = 'heart-circle',
  'HEART_CIRCLE_OUTLINE' = 'heart-circle-outline',
  'HEART_COG' = 'heart-cog',
  'HEART_COG_OUTLINE' = 'heart-cog-outline',
  'HEART_FLASH' = 'heart-flash',
  'AED' = 'aed',
  'DEFIBRILLATOR' = 'defibrillator',
  'HEART_HALF' = 'heart-half',
  'HEART_HALF_FULL' = 'heart-half-full',
  'HEART_HALF_OUTLINE' = 'heart-half-outline',
  'HEART_MINUS' = 'heart-minus',
  'HEART_MINUS_OUTLINE' = 'heart-minus-outline',
  'HEART_MULTIPLE' = 'heart-multiple',
  'HEARTS' = 'hearts',
  'HEART_MULTIPLE_OUTLINE' = 'heart-multiple-outline',
  'HEARTS_OUTLINE' = 'hearts-outline',
  'HEART_OFF' = 'heart-off',
  'HEART_OFF_OUTLINE' = 'heart-off-outline',
  'HEART_OUTLINE' = 'heart-outline',
  'FAVORITE_BORDER' = 'favorite-border',
  'FAVOURITE_BORDER' = 'favourite-border',
  'FAVORITE_OUTLINE' = 'favorite-outline',
  'FAVOURITE_OUTLINE' = 'favourite-outline',
  'HEART_PLUS' = 'heart-plus',
  'HEART_PLUS_OUTLINE' = 'heart-plus-outline',
  'HEART_PULSE' = 'heart-pulse',
  'HEART_VITALS' = 'heart-vitals',
  'HEART_REMOVE' = 'heart-remove',
  'HEART_REMOVE_OUTLINE' = 'heart-remove-outline',
  'HEART_SETTINGS' = 'heart-settings',
  'HEART_SETTINGS_OUTLINE' = 'heart-settings-outline',
  'HELICOPTER' = 'helicopter',
  'QUESTION_MARK' = 'question-mark',
  'HELP_BOX' = 'help-box',
  'QUESTION_MARK_BOX' = 'question-mark-box',
  'HELP_CIRCLE' = 'help-circle',
  'QUESTION_MARK_CIRCLE' = 'question-mark-circle',
  'HELP_CIRCLE_OUTLINE' = 'help-circle-outline',
  'QUESTION_MARK_CIRCLE_OUTLINE' = 'question-mark-circle-outline',
  'HELP_NETWORK' = 'help-network',
  'QUESTION_NETWORK' = 'question-network',
  'HELP_NETWORK_OUTLINE' = 'help-network-outline',
  'QUESTION_NETWORK_OUTLINE' = 'question-network-outline',
  'HELP_RHOMBUS' = 'help-rhombus',
  'QUESTION_MARK_RHOMBUS' = 'question-mark-rhombus',
  'HELP_RHOMBUS_OUTLINE' = 'help-rhombus-outline',
  'QUESTION_MARK_RHOMBUS_OUTLINE' = 'question-mark-rhombus-outline',
  'HEXADECIMAL' = 'hexadecimal',
  'HEXAGON' = 'hexagon',
  'HEXAGON_MULTIPLE' = 'hexagon-multiple',
  'HEXAGONS' = 'hexagons',
  'HEXAGON_MULTIPLE_OUTLINE' = 'hexagon-multiple-outline',
  'HEXAGON_OUTLINE' = 'hexagon-outline',
  'HEXAGON_SLICE1' = 'hexagon-slice1',
  'HEXAGON_SLICE2' = 'hexagon-slice2',
  'HEXAGON_SLICE3' = 'hexagon-slice3',
  'HEXAGON_SLICE4' = 'hexagon-slice4',
  'HEXAGON_SLICE5' = 'hexagon-slice5',
  'HEXAGON_SLICE6' = 'hexagon-slice6',
  'HEXAGRAM' = 'hexagram',
  'CHRISTMAS_STAR' = 'christmas-star',
  'HEXAGRAM_OUTLINE' = 'hexagram-outline',
  'STAR_OUTLINE' = 'star-outline',
  'CHRISTMAS_STAR_OUTLINE' = 'christmas-star-outline',
  'HIGH_DEFINITION' = 'high-definition',
  'HD' = 'hd',
  'HIGH_DEFINITION_BOX' = 'high-definition-box',
  'HD_BOX' = 'hd-box',
  'HIGHWAY' = 'highway',
  'AUTOBAHN' = 'autobahn',
  'MOTORWAY' = 'motorway',
  'HIKING' = 'hiking',
  'HUMAN_HIKING' = 'human-hiking',
  'HISTORY' = 'history',
  'RECENT' = 'recent',
  'LATEST' = 'latest',
  'CLOCK_ARROW' = 'clock-arrow',
  'COUNTERCLOCKWISE' = 'counterclockwise',
  'RESTORE_CLOCK' = 'restore-clock',
  'HOCKEY_PUCK' = 'hockey-puck',
  'HOCKEY_STICKS' = 'hockey-sticks',
  'HOLOLENS' = 'hololens',
  'HOME' = 'home',
  'HOUSE' = 'house',
  'HOME_ACCOUNT' = 'home-account',
  'HOME_USER' = 'home-user',
  'HOME_ALERT' = 'home-alert',
  'HOME_WARNING' = 'home-warning',
  'HOME_ALERT_OUTLINE' = 'home-alert-outline',
  'HOME_ANALYTICS' = 'home-analytics',
  'CHART_HOME' = 'chart-home',
  'HOME_CHART' = 'home-chart',
  'HOME_REPORT' = 'home-report',
  'HOME_ASSISTANT' = 'home-assistant',
  'HOME_AUTOMATION' = 'home-automation',
  'HOME_BATTERY' = 'home-battery',
  'HOME_ENERGY' = 'home-energy',
  'HOME_POWER' = 'home-power',
  'HOME_ELECTRICITY' = 'home-electricity',
  'HOME_BATTERY_OUTLINE' = 'home-battery-outline',
  'HOME_ENERGY_OUTLINE' = 'home-energy-outline',
  'HOME_POWER_OUTLINE' = 'home-power-outline',
  'HOME_ELECTRICITY_OUTLINE' = 'home-electricity-outline',
  'HOME_CIRCLE' = 'home-circle',
  'HOUSE_CIRCLE' = 'house-circle',
  'HOME_CIRCLE_OUTLINE' = 'home-circle-outline',
  'HOUSE_CIRCLE_OUTLINE' = 'house-circle-outline',
  'HOME_CITY' = 'home-city',
  'HOUSE_CITY' = 'house-city',
  'HOME_CITY_OUTLINE' = 'home-city-outline',
  'HOUSE_CITY_OUTLINE' = 'house-city-outline',
  'HOME_CLOCK' = 'home-clock',
  'HOME_TIME' = 'home-time',
  'HOME_SCHEDULE' = 'home-schedule',
  'HOME_CLOCK_OUTLINE' = 'home-clock-outline',
  'HOME_TIME_OUTLINE' = 'home-time-outline',
  'HOME_SCHEDULE_OUTLINE' = 'home-schedule-outline',
  'HOME_EDIT' = 'home-edit',
  'HOME_EDIT_OUTLINE' = 'home-edit-outline',
  'HOME_EXPORT_OUTLINE' = 'home-export-outline',
  'HOME_FLOOD' = 'home-flood',
  'HOME_FLOOR0' = 'home-floor0',
  'HOME_FLOOR1' = 'home-floor1',
  'HOME_FLOOR2' = 'home-floor2',
  'HOME_FLOOR3' = 'home-floor3',
  'HOME_FLOOR_A' = 'home-floor-a',
  'HOME_FLOOR_ATTIC' = 'home-floor-attic',
  'HOUSE_FLOOR_A' = 'house-floor-a',
  'HOUSE_FLOOR_ATTIC' = 'house-floor-attic',
  'HOME_FLOOR_B' = 'home-floor-b',
  'HOME_FLOOR_BASEMENT' = 'home-floor-basement',
  'HOUSE_FLOOR_B' = 'house-floor-b',
  'HOUSE_FLOOR_BASEMENT' = 'house-floor-basement',
  'HOME_FLOOR_G' = 'home-floor-g',
  'HOME_FLOOR_GROUND' = 'home-floor-ground',
  'HOUSE_FLOOR_G' = 'house-floor-g',
  'HOUSE_FLOOR_GROUND' = 'house-floor-ground',
  'HOME_FLOOR_L' = 'home-floor-l',
  'HOME_FLOOR_LOFT' = 'home-floor-loft',
  'HOME_FLOOR_LOWER' = 'home-floor-lower',
  'HOUSE_FLOOR_L' = 'house-floor-l',
  'HOUSE_FLOOR_LOFT' = 'house-floor-loft',
  'HOUSE_FLOOR_LOWER' = 'house-floor-lower',
  'HOME_FLOOR_NEGATIVE1' = 'home-floor-negative1',
  'HOME_GROUP' = 'home-group',
  'HOUSE_GROUP' = 'house-group',
  'NEIGHBOURHOOD' = 'neighbourhood',
  'ESTATE' = 'estate',
  'HOUSING_ESTATE' = 'housing-estate',
  'HOME_GROUP_MINUS' = 'home-group-minus',
  'HOME_GROUP_PLUS' = 'home-group-plus',
  'HOME_GROUP_REMOVE' = 'home-group-remove',
  'HOME_HEART' = 'home-heart',
  'HOME_IMPORT_OUTLINE' = 'home-import-outline',
  'HOME_LIGHTBULB' = 'home-lightbulb',
  'HOME_BULB' = 'home-bulb',
  'HOME_LIGHTBULB_OUTLINE' = 'home-lightbulb-outline',
  'HOME_BULB_OUTLINE' = 'home-bulb-outline',
  'HOME_LIGHTNING_BOLT' = 'home-lightning-bolt',
  'HOME_FLASH' = 'home-flash',
  'HOME_LIGHTNING_BOLT_OUTLINE' = 'home-lightning-bolt-outline',
  'HOME_LOCK' = 'home-lock',
  'HOME_LOCK_OPEN' = 'home-lock-open',
  'HOME_MAP_MARKER' = 'home-map-marker',
  'HOUSE_MAP_MARKER' = 'house-map-marker',
  'HOME_LOCATION' = 'home-location',
  'HOME_MINUS' = 'home-minus',
  'HOME_MINUS_OUTLINE' = 'home-minus-outline',
  'HOME_MODERN' = 'home-modern',
  'HOUSE_MODERN' = 'house-modern',
  'HOME_OUTLINE' = 'home-outline',
  'HOUSE_OUTLINE' = 'house-outline',
  'HOME_PLUS' = 'home-plus',
  'HOME_ADD' = 'home-add',
  'HOME_PLUS_OUTLINE' = 'home-plus-outline',
  'HOME_REMOVE' = 'home-remove',
  'HOME_REMOVE_OUTLINE' = 'home-remove-outline',
  'HOME_ROOF' = 'home-roof',
  'HOME_CHIMNEY' = 'home-chimney',
  'HOME_ATTIC' = 'home-attic',
  'HOME_SEARCH' = 'home-search',
  'HOME_SEARCH_OUTLINE' = 'home-search-outline',
  'HOME_SWITCH' = 'home-switch',
  'HOME_SWAP' = 'home-swap',
  'HOME_SWITCH_OUTLINE' = 'home-switch-outline',
  'HOME_SWAP_OUTLINE' = 'home-swap-outline',
  'HOME_THERMOMETER' = 'home-thermometer',
  'HOME_CLIMATE' = 'home-climate',
  'HOME_TEMPERATURE' = 'home-temperature',
  'HOME_THERMOMETER_OUTLINE' = 'home-thermometer-outline',
  'HOME_CLIMATE_OUTLINE' = 'home-climate-outline',
  'HOME_TEMPERATURE_OUTLINE' = 'home-temperature-outline',
  'HOME_VARIANT' = 'home-variant',
  'HOUSE_VARIANT' = 'house-variant',
  'HOME_VARIANT_OUTLINE' = 'home-variant-outline',
  'HOUSE_VARIANT_OUTLINE' = 'house-variant-outline',
  'HOOK' = 'hook',
  'HOOK_OFF' = 'hook-off',
  'HOOP_HOUSE' = 'hoop-house',
  'GREEN_HOUSE' = 'green-house',
  'HOPS' = 'hops',
  'HORIZONTAL_ROTATE_CLOCKWISE' = 'horizontal-rotate-clockwise',
  'HORIZONTAL_ROTATE_COUNTERCLOCKWISE' = 'horizontal-rotate-counterclockwise',
  'HORSE' = 'horse',
  'EQUESTRIAN' = 'equestrian',
  'HORSE_HUMAN' = 'horse-human',
  'HORSEBACK_RIDING' = 'horseback-riding',
  'HORSE_RIDING' = 'horse-riding',
  'HORSE_VARIANT' = 'horse-variant',
  'EQUESTRIAN_VARIANT' = 'equestrian-variant',
  'HORSE_VARIANT_FAST' = 'horse-variant-fast',
  'HORSESHOE' = 'horseshoe',
  'HOSPITAL' = 'hospital',
  'SWISS_CROSS' = 'swiss-cross',
  'DISPENSARY' = 'dispensary',
  'HOSPITAL_BOX' = 'hospital-box',
  'LOCAL_HOSPITAL' = 'local-hospital',
  'SWISS_CROSS_BOX' = 'swiss-cross-box',
  'DISPENSARY_BOX' = 'dispensary-box',
  'HOSPITAL_BOX_OUTLINE' = 'hospital-box-outline',
  'SWISS_CROSS_BOX_OUTLINE' = 'swiss-cross-box-outline',
  'DISPENSARY_BOX_OUTLINE' = 'dispensary-box-outline',
  'HOSPITAL_BUILDING' = 'hospital-building',
  'HOSPITAL_MARKER' = 'hospital-marker',
  'HOSPITAL_LOCATION' = 'hospital-location',
  'HOT_TUB' = 'hot-tub',
  'HOURS24' = 'hours24',
  'HUBSPOT' = 'hubspot',
  'HULU' = 'hulu',
  'HUMAN' = 'human',
  'ACCESSIBILITY' = 'accessibility',
  'HUMAN_BABY_CHANGING_TABLE' = 'human-baby-changing-table',
  'HUMAN_CANE' = 'human-cane',
  'ELDERLY' = 'elderly',
  'HUMAN_CAPACITY_DECREASE' = 'human-capacity-decrease',
  'HUMAN_CAPACITY_REDUCE' = 'human-capacity-reduce',
  'HUMAN_CAPACITY_INCREASE' = 'human-capacity-increase',
  'HUMAN_CHILD' = 'human-child',
  'HUMAN_DOLLY' = 'human-dolly',
  'HUMAN_HAND_TRUCK' = 'human-hand-truck',
  'HUMAN_TROLLEY' = 'human-trolley',
  'HUMAN_EDIT' = 'human-edit',
  'HUMAN_FEMALE' = 'human-female',
  'WOMAN' = 'woman',
  'HUMAN_FEMALE_BOY' = 'human-female-boy',
  'MOTHER' = 'mother',
  'MOM' = 'mom',
  'WOMAN_CHILD' = 'woman-child',
  'MUM' = 'mum',
  'HUMAN_FEMALE_DANCE' = 'human-female-dance',
  'BALLET' = 'ballet',
  'HUMAN_FEMALE_FEMALE' = 'human-female-female',
  'WOMAN_WOMAN' = 'woman-woman',
  'WOMEN' = 'women',
  'HUMAN_FEMALE_GIRL' = 'human-female-girl',
  'HUMAN_GREETING' = 'human-greeting',
  'HUMAN_HELLO' = 'human-hello',
  'HUMAN_WELCOME' = 'human-welcome',
  'HUMAN_GREETING_PROXIMITY' = 'human-greeting-proximity',
  'CONNECT_WITHOUT_CONTACT' = 'connect-without-contact',
  'HUMAN_GREETING_VARIANT' = 'human-greeting-variant',
  'HUMAN_HELLO_VARIANT' = 'human-hello-variant',
  'HUMAN_HANDSDOWN' = 'human-handsdown',
  'HUMAN_HANDSUP' = 'human-handsup',
  'HUMAN_MALE' = 'human-male',
  'MAN' = 'man',
  'HUMAN_MALE_BOARD' = 'human-male-board',
  'TEACHER' = 'teacher',
  'TEACHING' = 'teaching',
  'LECTURE' = 'lecture',
  'COLLEGE' = 'college',
  'BLACKBOARD' = 'blackboard',
  'WHITEBOARD' = 'whiteboard',
  'HUMAN_MAN_BOARD' = 'human-man-board',
  'HUMAN_MALE_BOARD_POLL' = 'human-male-board-poll',
  'TEACH_POLL' = 'teach-poll',
  'HUMAN_MALE_BOY' = 'human-male-boy',
  'FATHER' = 'father',
  'DAD' = 'dad',
  'MAN_CHILD' = 'man-child',
  'HUMAN_MALE_CHILD' = 'human-male-child',
  'HUMAN_MALE_FEMALE' = 'human-male-female',
  'WC' = 'wc',
  'MAN_WOMAN' = 'man-woman',
  'HUMAN_MALE_FEMALE_CHILD' = 'human-male-female-child',
  'MOM_DAD_CHILD' = 'mom-dad-child',
  'HUMAN_MALE_GIRL' = 'human-male-girl',
  'HUMAN_MALE_HEIGHT' = 'human-male-height',
  'HUMAN_MALE_HEIGHT_VARIANT' = 'human-male-height-variant',
  'HUMAN_MALE_MALE' = 'human-male-male',
  'MAN_MAN' = 'man-man',
  'MEN' = 'men',
  'HUMAN_NON_BINARY' = 'human-non-binary',
  'HUMAN_GENDERLESS' = 'human-genderless',
  'HUMAN_TRANSGENDER' = 'human-transgender',
  'HUMAN_PREGNANT' = 'human-pregnant',
  'PREGNANT_WOMAN' = 'pregnant-woman',
  'HUMAN_QUEUE' = 'human-queue',
  'HUMAN_LINE' = 'human-line',
  'HUMAN_SCOOTER' = 'human-scooter',
  'HUMAN_WHEELCHAIR' = 'human-wheelchair',
  'HUMAN_ACCESSIBLE' = 'human-accessible',
  'HUMAN_WHITE_CANE' = 'human-white-cane',
  'HUMAN_BLIND' = 'human-blind',
  'HUMBLE_BUNDLE' = 'humble-bundle',
  'HVAC' = 'hvac',
  'HEATING' = 'heating',
  'VENTILATION' = 'ventilation',
  'AIR_CONDITIONING' = 'air-conditioning',
  'HVAC_OFF' = 'hvac-off',
  'HEATING_OFF' = 'heating-off',
  'VENTILATION_OFF' = 'ventilation-off',
  'AIR_CONDITIONING_OFF' = 'air-conditioning-off',
  'HYDRAULIC_OIL_LEVEL' = 'hydraulic-oil-level',
  'HYDRAULIC_OIL_TEMPERATURE' = 'hydraulic-oil-temperature',
  'HYDRO_POWER' = 'hydro-power',
  'HYDRAULIC_TURBINE' = 'hydraulic-turbine',
  'WATER_TURBINE' = 'water-turbine',
  'WATERMILL' = 'watermill',
  'HYDROGEN_STATION' = 'hydrogen-station',
  'ICE_CREAM' = 'ice-cream',
  'ICE_CREAM_OFF' = 'ice-cream-off',
  'ICE_POP' = 'ice-pop',
  'POPSICLE' = 'popsicle',
  'IDENTIFIER' = 'identifier',
  'KEY' = 'key',
  'IDEOGRAM_CJK' = 'ideogram-cjk',
  'IDEOGRAM_CHINESE_JAPANESE_KOREAN' = 'ideogram-chinese-japanese-korean',
  'WRITING_SYSTEM_CJK' = 'writing-system-cjk',
  'IDEOGRAM_CJK_VARIANT' = 'ideogram-cjk-variant',
  'IDEOGRAM_CHINESE_JAPANESE_KOREAN_VARIANT' = 'ideogram-chinese-japanese-korean-variant',
  'WRITING_SYSTEM_CJK_VARIANT' = 'writing-system-cjk-variant',
  'IMAGE' = 'image',
  'INSERT_PHOTO' = 'insert-photo',
  'IMAGE_ALBUM' = 'image-album',
  'PHOTO_ALBUM' = 'photo-album',
  'BOOK_IMAGE' = 'book-image',
  'IMAGE_AREA' = 'image-area',
  'IMAGE_AREA_CLOSE' = 'image-area-close',
  'IMAGE_AUTO_ADJUST' = 'image-auto-adjust',
  'IMAGE_BROKEN' = 'image-broken',
  'IMAGE_BROKEN_VARIANT' = 'image-broken-variant',
  'BROKEN_IMAGE' = 'broken-image',
  'IMAGE_EDIT' = 'image-edit',
  'IMAGE_EDIT_OUTLINE' = 'image-edit-outline',
  'IMAGE_FILTER_BLACK_WHITE' = 'image-filter-black-white',
  'FILTER_B_AND_W' = 'filter-b-and-w',
  'IMAGE_FILTER_CENTER_FOCUS' = 'image-filter-center-focus',
  'IMAGE_FILTER_CENTRE_FOCUS' = 'image-filter-centre-focus',
  'IMAGE_FILTER_CENTER_FOCUS_STRONG' = 'image-filter-center-focus-strong',
  'IMAGE_FILTER_CENTER_FOCUS_STRONG_OUTLINE' = 'image-filter-center-focus-strong-outline',
  'IMAGE_FILTER_CENTER_FOCUS_WEAK' = 'image-filter-center-focus-weak',
  'IMAGE_FILTER_CENTRE_FOCUS_WEAK' = 'image-filter-centre-focus-weak',
  'IMAGE_FILTER_DRAMA' = 'image-filter-drama',
  'IMAGE_FILTER_FRAMES' = 'image-filter-frames',
  'IMAGE_FILTER_HDR' = 'image-filter-hdr',
  'MOUNTAIN' = 'mountain',
  'LANDSCAPE' = 'landscape',
  'IMAGE_FILTER_NONE' = 'image-filter-none',
  'IMAGE_FILTER_TILT_SHIFT' = 'image-filter-tilt-shift',
  'IMAGE_FILTER_VINTAGE' = 'image-filter-vintage',
  'IMAGE_FRAME' = 'image-frame',
  'IMAGE_MARKER' = 'image-marker',
  'IMAGE_LOCATION' = 'image-location',
  'IMAGE_MARKER_OUTLINE' = 'image-marker-outline',
  'IMAGE_LOCATION_OUTLINE' = 'image-location-outline',
  'IMAGE_MINUS' = 'image-minus',
  'IMAGE_MOVE' = 'image-move',
  'IMAGE_MULTIPLE' = 'image-multiple',
  'COLLECTIONS' = 'collections',
  'PHOTO_LIBRARY' = 'photo-library',
  'IMAGES' = 'images',
  'IMAGE_MULTIPLE_OUTLINE' = 'image-multiple-outline',
  'IMAGE_FILTER' = 'image-filter',
  'IMAGES_OUTLINE' = 'images-outline',
  'IMAGE_OFF' = 'image-off',
  'IMAGE_OFF_OUTLINE' = 'image-off-outline',
  'IMAGE_OUTLINE' = 'image-outline',
  'IMAGE_PLUS' = 'image-plus',
  'IMAGE_ADD' = 'image-add',
  'IMAGE_REFRESH' = 'image-refresh',
  'IMAGE_REFRESH_OUTLINE' = 'image-refresh-outline',
  'IMAGE_REMOVE' = 'image-remove',
  'IMAGE_SEARCH' = 'image-search',
  'IMAGE_SEARCH_OUTLINE' = 'image-search-outline',
  'IMAGE_SIZE_SELECT_ACTUAL' = 'image-size-select-actual',
  'IMAGE_SIZE_SELECT_LARGE' = 'image-size-select-large',
  'IMAGE_SIZE_SELECT_SMALL' = 'image-size-select-small',
  'IMAGE_SYNC' = 'image-sync',
  'IMAGE_SYNC_OUTLINE' = 'image-sync-outline',
  'IMAGE_TEXT' = 'image-text',
  'IMAGE_DESCRIPTION' = 'image-description',
  'IMPORT' = 'import',
  'INPUT' = 'input',
  'INBOX' = 'inbox',
  'INBOX_ARROW_DOWN' = 'inbox-arrow-down',
  'MOVE_TO_INBOX' = 'move-to-inbox',
  'INBOX_ARROW_DOWN_OUTLINE' = 'inbox-arrow-down-outline',
  'INBOX_ARROW_UP' = 'inbox-arrow-up',
  'MOVE_FROM_INBOX' = 'move-from-inbox',
  'INBOX_ARROW_UP_OUTLINE' = 'inbox-arrow-up-outline',
  'INBOX_FULL' = 'inbox-full',
  'INBOX_FULL_OUTLINE' = 'inbox-full-outline',
  'INBOX_MULTIPLE' = 'inbox-multiple',
  'INBOXES' = 'inboxes',
  'INBOX_MULTIPLE_OUTLINE' = 'inbox-multiple-outline',
  'INBOXES_OUTLINE' = 'inboxes-outline',
  'INBOX_OUTLINE' = 'inbox-outline',
  'INBOX_REMOVE' = 'inbox-remove',
  'INBOX_REMOVE_OUTLINE' = 'inbox-remove-outline',
  'INCOGNITO' = 'incognito',
  'ANONYMOUS' = 'anonymous',
  'SPY' = 'spy',
  'INCOGNITO_CIRCLE' = 'incognito-circle',
  'ANONYMOUS_CIRCLE' = 'anonymous-circle',
  'SPY_CIRCLE' = 'spy-circle',
  'INCOGNITO_CIRCLE_OFF' = 'incognito-circle-off',
  'ANONYMOUS_CIRCLE_OFF' = 'anonymous-circle-off',
  'SPY_CIRCLE_OFF' = 'spy-circle-off',
  'INCOGNITO_OFF' = 'incognito-off',
  'SPY_OFF' = 'spy-off',
  'ANONYMOUS_OFF' = 'anonymous-off',
  'INDUCTION' = 'induction',
  'IGNITION' = 'ignition',
  'INFORMATION' = 'information',
  'ABOUT' = 'about',
  'INFORMATION_CIRCLE' = 'information-circle',
  'INFO_CIRCLE' = 'info-circle',
  'ABOUT_CIRCLE' = 'about-circle',
  'INFORMATION_OFF' = 'information-off',
  'INFORMATION_OFF_OUTLINE' = 'information-off-outline',
  'INFORMATION_OUTLINE' = 'information-outline',
  'INFO_OUTLINE' = 'info-outline',
  'ABOUT_OUTLINE' = 'about-outline',
  'INFORMATION_CIRCLE_OUTLINE' = 'information-circle-outline',
  'INFO_CIRCLE_OUTLINE' = 'info-circle-outline',
  'ABOUT_CIRCLE_OUTLINE' = 'about-circle-outline',
  'INFORMATION_VARIANT' = 'information-variant',
  'INFO_VARIANT' = 'info-variant',
  'ABOUT_VARIANT' = 'about-variant',
  'INSTAGRAM' = 'instagram',
  'INSTRUMENT_TRIANGLE' = 'instrument-triangle',
  'DINNER_BELL' = 'dinner-bell',
  'INTEGRATED_CIRCUIT_CHIP' = 'integrated-circuit-chip',
  'ICC' = 'icc',
  'INVERT_COLORS' = 'invert-colors',
  'INVERT_COLOURS' = 'invert-colours',
  'INVERT_COLORS_OFF' = 'invert-colors-off',
  'INVERT_COLOURS_OFF' = 'invert-colours-off',
  'IOBROKER' = 'iobroker',
  'IP' = 'ip',
  'INTERNET_PROTOCOL' = 'internet-protocol',
  'IP_NETWORK' = 'ip-network',
  'IP_NETWORK_OUTLINE' = 'ip-network-outline',
  'IP_OUTLINE' = 'ip-outline',
  'INTERNET_PROTOCOL_OUTLINE' = 'internet-protocol-outline',
  'IPOD' = 'ipod',
  'APPLE_IPOD' = 'apple-ipod',
  'IRON' = 'iron',
  'FLATIRON' = 'flatiron',
  'SMOOTHING_IRON' = 'smoothing-iron',
  'IRON_BOARD' = 'iron-board',
  'IRON_OUTLINE' = 'iron-outline',
  'FLATIRON_OUTLINE' = 'flatiron-outline',
  'SMOOTHING_IRON_OUTLINE' = 'smoothing-iron-outline',
  'ISLAND' = 'island',
  'IV_BAG' = 'iv-bag',
  'JABBER' = 'jabber',
  'JEEPNEY' = 'jeepney',
  'JELLYFISH' = 'jellyfish',
  'JELLYFISH_OUTLINE' = 'jellyfish-outline',
  'JIRA' = 'jira',
  'JQUERY' = 'jquery',
  'JSFIDDLE' = 'jsfiddle',
  'JUMP_ROPE' = 'jump-rope',
  'KABADDI' = 'kabaddi',
  'WRESTLING' = 'wrestling',
  'HUMAN_KABADDI' = 'human-kabaddi',
  'KANGAROO' = 'kangaroo',
  'MARSUPIAL' = 'marsupial',
  'KARATE' = 'karate',
  'MARTIAL_ARTS' = 'martial-arts',
  'KICKBOXING' = 'kickboxing',
  'HUMAN_KARATE' = 'human-karate',
  'KAYAKING' = 'kayaking',
  'HUMAN_KAYAKING' = 'human-kayaking',
  'KEG' = 'keg',
  'KETTLE' = 'kettle',
  'TEA_KETTLE' = 'tea-kettle',
  'KETTLE_FULL' = 'kettle-full',
  'TEA_KETTLE_FULL' = 'tea-kettle-full',
  'KETTLE_ALERT' = 'kettle-alert',
  'TEA_KETTLE_ALERT' = 'tea-kettle-alert',
  'KETTLE_FULL_ALERT' = 'kettle-full-alert',
  'TEA_KETTLE_FULL_ALERT' = 'tea-kettle-full-alert',
  'KETTLE_ALERT_OUTLINE' = 'kettle-alert-outline',
  'TEA_KETTLE_ALERT_OUTLINE' = 'tea-kettle-alert-outline',
  'KETTLE_EMPTY_ALERT' = 'kettle-empty-alert',
  'TEA_KETTLE_EMPTY_ALERT' = 'tea-kettle-empty-alert',
  'KETTLE_OFF' = 'kettle-off',
  'TEA_KETTLE_OFF' = 'tea-kettle-off',
  'TEA_KETTLE_FULL_OFF' = 'tea-kettle-full-off',
  'KETTLE_FULL_OFF' = 'kettle-full-off',
  'KETTLE_OFF_OUTLINE' = 'kettle-off-outline',
  'TEA_KETTLE_OFF_OUTLINE' = 'tea-kettle-off-outline',
  'KETTLE_EMPTY_OFF' = 'kettle-empty-off',
  'TEA_KETTLE_EMPTY_OFF' = 'tea-kettle-empty-off',
  'KETTLE_OUTLINE' = 'kettle-outline',
  'TEA_KETTLE_OUTLINE' = 'tea-kettle-outline',
  'KETTLE_EMPTY' = 'kettle-empty',
  'TEA_KETTLE_EMPTY' = 'tea-kettle-empty',
  'KETTLE_POUR_OVER' = 'kettle-pour-over',
  'KETTLE_STEAM' = 'kettle-steam',
  'TEA_KETTLE_STEAM' = 'tea-kettle-steam',
  'KETTLE_FULL_STEAM' = 'kettle-full-steam',
  'TEA_KETTLE_FULL_STEAM' = 'tea-kettle-full-steam',
  'KETTLE_STEAM_OUTLINE' = 'kettle-steam-outline',
  'TEA_KETTLE_STEAM_OUTLINE' = 'tea-kettle-steam-outline',
  'KETTLE_EMPTY_STEAM' = 'kettle-empty-steam',
  'TEA_KETTLE_EMPTY_STEAM' = 'tea-kettle-empty-steam',
  'KETTLEBELL' = 'kettlebell',
  'VPN_KEY' = 'vpn-key',
  'KEY_ALERT' = 'key-alert',
  'KEY_ALERT_OUTLINE' = 'key-alert-outline',
  'KEY_ARROW_RIGHT' = 'key-arrow-right',
  'KEY_CHAIN' = 'key-chain',
  'KEY_CHAIN_VARIANT' = 'key-chain-variant',
  'KEY_CHANGE' = 'key-change',
  'KEY_LINK' = 'key-link',
  'FOREIGN_KEY' = 'foreign-key',
  'SQL_FOREIGN_KEY' = 'sql-foreign-key',
  'KEY_MINUS' = 'key-minus',
  'KEY_OUTLINE' = 'key-outline',
  'KEY_PLUS' = 'key-plus',
  'KEY_ADD' = 'key-add',
  'KEY_REMOVE' = 'key-remove',
  'KEY_STAR' = 'key-star',
  'PRIMARY_KEY' = 'primary-key',
  'SQL_PRIMARY_KEY' = 'sql-primary-key',
  'KEY_FAVORITE' = 'key-favorite',
  'KEY_VARIANT' = 'key-variant',
  'KEY_WIRELESS' = 'key-wireless',
  'KEYBOARD' = 'keyboard',
  'KEYBOARD_BACKSPACE' = 'keyboard-backspace',
  'KEYBOARD_CLEAR' = 'keyboard-clear',
  'KEYBOARD_ERASE' = 'keyboard-erase',
  'KEYBOARD_CAPS' = 'keyboard-caps',
  'KEYBOARD_CAPSLOCK' = 'keyboard-capslock',
  'KEYBOARD_CLOSE' = 'keyboard-close',
  'KEYBOARD_HIDE' = 'keyboard-hide',
  'KEYBOARD_ESC' = 'keyboard-esc',
  'KEYBOARD_F1' = 'keyboard-f1',
  'KEYBOARD_F10' = 'keyboard-f10',
  'KEYBOARD_F11' = 'keyboard-f11',
  'KEYBOARD_F12' = 'keyboard-f12',
  'KEYBOARD_F2' = 'keyboard-f2',
  'KEYBOARD_F3' = 'keyboard-f3',
  'KEYBOARD_F4' = 'keyboard-f4',
  'KEYBOARD_F5' = 'keyboard-f5',
  'KEYBOARD_F6' = 'keyboard-f6',
  'KEYBOARD_F7' = 'keyboard-f7',
  'KEYBOARD_F8' = 'keyboard-f8',
  'KEYBOARD_F9' = 'keyboard-f9',
  'KEYBOARD_OFF' = 'keyboard-off',
  'KEYBOARD_OFF_OUTLINE' = 'keyboard-off-outline',
  'KEYBOARD_OUTLINE' = 'keyboard-outline',
  'KEYBOARD_RETURN' = 'keyboard-return',
  'KEYBOARD_SETTINGS' = 'keyboard-settings',
  'KEYBOARD_SETTINGS_OUTLINE' = 'keyboard-settings-outline',
  'KEYBOARD_SPACE' = 'keyboard-space',
  'KEYBOARD_TAB' = 'keyboard-tab',
  'KEYBOARD_TAB_REVERSE' = 'keyboard-tab-reverse',
  'KEYBOARD_VARIANT' = 'keyboard-variant',
  'KHANDA' = 'khanda',
  'SIKH' = 'sikh',
  'KICKSTARTER' = 'kickstarter',
  'KITE' = 'kite',
  'KITE_OUTLINE' = 'kite-outline',
  'KITESURFING' = 'kitesurfing',
  'KLINGON' = 'klingon',
  'KNIFE' = 'knife',
  'SILVERWARE_KNIFE' = 'silverware-knife',
  'CUTLERY_KNIFE' = 'cutlery-knife',
  'KNIFE_MILITARY' = 'knife-military',
  'DAGGER' = 'dagger',
  'KOALA' = 'koala',
  'EMOJI_KOALA' = 'emoji-koala',
  'EMOTICON_KOALA' = 'emoticon-koala',
  'KODI' = 'kodi',
  'KUBERNETES' = 'kubernetes',
  'LABEL' = 'label',
  'LABEL_MULTIPLE' = 'label-multiple',
  'LABEL_MULTIPLE_OUTLINE' = 'label-multiple-outline',
  'LABEL_OFF' = 'label-off',
  'LABEL_OFF_OUTLINE' = 'label-off-outline',
  'LABEL_OUTLINE' = 'label-outline',
  'LABEL_PERCENT' = 'label-percent',
  'LABEL_PERCENT_OUTLINE' = 'label-percent-outline',
  'LABEL_VARIANT' = 'label-variant',
  'LABEL_VARIANT_OUTLINE' = 'label-variant-outline',
  'LADDER' = 'ladder',
  'LADYBUG' = 'ladybug',
  'BUGFOOD' = 'bugfood',
  'LADYBIRD' = 'ladybird',
  'LAMBDA' = 'lambda',
  'LAMP' = 'lamp',
  'LAMP_OUTLINE' = 'lamp-outline',
  'LAMPS' = 'lamps',
  'LIGHTS' = 'lights',
  'LAMPS_OUTLINE' = 'lamps-outline',
  'LIGHTS_OUTLINE' = 'lights-outline',
  'LAN' = 'lan',
  'LOCAL_AREA_NETWORK' = 'local-area-network',
  'LAN_CHECK' = 'lan-check',
  'LAN_CONNECT' = 'lan-connect',
  'LOCAL_AREA_NETWORK_CONNECT' = 'local-area-network-connect',
  'LAN_DISCONNECT' = 'lan-disconnect',
  'LOCAL_AREA_NETWORK_DISCONNECT' = 'local-area-network-disconnect',
  'LAN_PENDING' = 'lan-pending',
  'LOCAL_AREA_NETWORK_PENDING' = 'local-area-network-pending',
  'LANGUAGE_C' = 'language-c',
  'LANGUAGE_CPP' = 'language-cpp',
  'LANGUAGE_CSHARP' = 'language-csharp',
  'LANGUAGE_CSS3' = 'language-css3',
  'LANGUAGE_FORTRAN' = 'language-fortran',
  'LANGUAGE_GO' = 'language-go',
  'LANGUAGE_HASKELL' = 'language-haskell',
  'LANGUAGE_HTML5' = 'language-html5',
  'LANGUAGE_JAVA' = 'language-java',
  'LANGUAGE_JAVASCRIPT' = 'language-javascript',
  'LANGUAGE_KOTLIN' = 'language-kotlin',
  'LANGUAGE_LUA' = 'language-lua',
  'LANGUAGE_MARKDOWN' = 'language-markdown',
  'LANGUAGE_MARKDOWN_OUTLINE' = 'language-markdown-outline',
  'LANGUAGE_PHP' = 'language-php',
  'LANGUAGE_PYTHON' = 'language-python',
  'LANGUAGE_R' = 'language-r',
  'LANGUAGE_RUBY' = 'language-ruby',
  'LANGUAGE_RUBY_ON_RAILS' = 'language-ruby-on-rails',
  'LANGUAGE_RUST' = 'language-rust',
  'LANGUAGE_SWIFT' = 'language-swift',
  'LANGUAGE_TYPESCRIPT' = 'language-typescript',
  'LANGUAGE_XAML' = 'language-xaml',
  'XAML' = 'xaml',
  'MICROSOFT_XAML' = 'microsoft-xaml',
  'LAPTOP' = 'laptop',
  'COMPUTER' = 'computer',
  'LAPTOP_OFF' = 'laptop-off',
  'LARAVEL' = 'laravel',
  'LASER_POINTER' = 'laser-pointer',
  'LASSO' = 'lasso',
  'LASTPASS' = 'lastpass',
  'LATITUDE' = 'latitude',
  'LAUNCH' = 'launch',
  'LAVA_LAMP' = 'lava-lamp',
  'LAYERS' = 'layers',
  'LAYERS_EDIT' = 'layers-edit',
  'LAYERS_MINUS' = 'layers-minus',
  'LAYERS_OFF' = 'layers-off',
  'LAYERS_CLEAR' = 'layers-clear',
  'LAYERS_OFF_OUTLINE' = 'layers-off-outline',
  'LAYERS_OUTLINE' = 'layers-outline',
  'LAYERS_PLUS' = 'layers-plus',
  'LAYERS_REMOVE' = 'layers-remove',
  'LAYERS_SEARCH' = 'layers-search',
  'LAYERS_SEARCH_OUTLINE' = 'layers-search-outline',
  'LAYERS_TRIPLE' = 'layers-triple',
  'LAYERS_TRIPLE_OUTLINE' = 'layers-triple-outline',
  'LEAD_PENCIL' = 'lead-pencil',
  'LEAF' = 'leaf',
  'LEAF_CIRCLE' = 'leaf-circle',
  'GREEN_CIRCLE' = 'green-circle',
  'LEAF_CIRCLE_OUTLINE' = 'leaf-circle-outline',
  'GREEN_CIRCLE_OUTLINE' = 'green-circle-outline',
  'LEAF_MAPLE' = 'leaf-maple',
  'LEAF_MAPLE_OFF' = 'leaf-maple-off',
  'LEAF_OFF' = 'leaf-off',
  'LEAK' = 'leak',
  'PROXIMITY_SENSOR' = 'proximity-sensor',
  'LEAK_OFF' = 'leak-off',
  'PROXIMITY_SENSOR_OFF' = 'proximity-sensor-off',
  'LECTURN' = 'lecturn',
  'PODIUM' = 'podium',
  'DAIS' = 'dais',
  'ROSTRUM' = 'rostrum',
  'LECTERN' = 'lectern',
  'LED_OFF' = 'led-off',
  'LED_ON' = 'led-on',
  'LED_OUTLINE' = 'led-outline',
  'LED_STRIP' = 'led-strip',
  'LIGHT_STRIP' = 'light-strip',
  'LED_STRIP_VARIANT' = 'led-strip-variant',
  'LIGHT_STRIP_VARIANT' = 'light-strip-variant',
  'LED_VARIANT_OFF' = 'led-variant-off',
  'LED_VARIANT_ON' = 'led-variant-on',
  'LED_VARIANT_OUTLINE' = 'led-variant-outline',
  'LEEK' = 'leek',
  'LESS_THAN' = 'less-than',
  'LESS_THAN_OR_EQUAL' = 'less-than-or-equal',
  'LIBRARY' = 'library',
  'LOCAL_LIBRARY' = 'local-library',
  'LIBRARY_OUTLINE' = 'library-outline',
  'LOCAL_LIBRARY_OUTLINE' = 'local-library-outline',
  'LIBRARY_SHELVES' = 'library-shelves',
  'LICENSE' = 'license',
  'RIBBON' = 'ribbon',
  'PRIZE' = 'prize',
  'AWARD' = 'award',
  'LIFEBUOY' = 'lifebuoy',
  'LIFE_PRESERVER' = 'life-preserver',
  'OVERBOARD' = 'overboard',
  'LIGHT_FLOOD_DOWN' = 'light-flood-down',
  'FLOODLIGHT_DOWN' = 'floodlight-down',
  'LIGHT_FLOOD_UP' = 'light-flood-up',
  'FLOODLIGHT_UP' = 'floodlight-up',
  'LIGHT_RECESSED' = 'light-recessed',
  'CAN_LIGHT' = 'can-light',
  'POT_LIGHT' = 'pot-light',
  'HIGH_HAT_LIGHT' = 'high-hat-light',
  'HI_HAT_LIGHT' = 'hi-hat-light',
  'DOWNLIGHT' = 'downlight',
  'LIGHT_SWITCH' = 'light-switch',
  'TOGGLE_SWITCH' = 'toggle-switch',
  'ROCKER_SWITCH' = 'rocker-switch',
  'LIGHT_SWITCH_OFF' = 'light-switch-off',
  'TOGGLE_SWITCH_OFF' = 'toggle-switch-off',
  'ROCKER_SWITCH_OFF' = 'rocker-switch-off',
  'LIGHTBULB' = 'lightbulb',
  'IDEA' = 'idea',
  'BULB' = 'bulb',
  'LIGHTBULB_ALERT' = 'lightbulb-alert',
  'LIGHTBULB_ERROR' = 'lightbulb-error',
  'LIGHTBULB_ALERT_OUTLINE' = 'lightbulb-alert-outline',
  'LIGHTBULB_ERROR_OUTLINE' = 'lightbulb-error-outline',
  'LIGHTBULB_AUTO' = 'lightbulb-auto',
  'LIGHTBULB_AUTOMATIC' = 'lightbulb-automatic',
  'LIGHTBULB_MOTION' = 'lightbulb-motion',
  'LIGHTBULB_AUTO_OUTLINE' = 'lightbulb-auto-outline',
  'LIGHTBULB_AUTOMATIC_OUTLINE' = 'lightbulb-automatic-outline',
  'LIGHTBULB_MOTION_OUTLINE' = 'lightbulb-motion-outline',
  'LIGHTBULB_CFL' = 'lightbulb-cfl',
  'BULB_CFL' = 'bulb-cfl',
  'LIGHTBULB_CFL_OFF' = 'lightbulb-cfl-off',
  'BULB_CFL_OFF' = 'bulb-cfl-off',
  'LIGHTBULB_CFL_SPIRAL' = 'lightbulb-cfl-spiral',
  'BULB_CFL_SPIRAL' = 'bulb-cfl-spiral',
  'LIGHTBULB_CFL_SPIRAL_OFF' = 'lightbulb-cfl-spiral-off',
  'BULB_CFL_SPIRAL_OFF' = 'bulb-cfl-spiral-off',
  'LIGHTBULB_FLUORESCENT_TUBE' = 'lightbulb-fluorescent-tube',
  'LIGHTBULB_FLUORESCENT_TUBE_OUTLINE' = 'lightbulb-fluorescent-tube-outline',
  'LIGHTBULB_GROUP' = 'lightbulb-group',
  'BULB_GROUP' = 'bulb-group',
  'LIGHTBULB_GROUP_OFF' = 'lightbulb-group-off',
  'BULB_GROUP_OFF' = 'bulb-group-off',
  'LIGHTBULB_GROUP_OFF_OUTLINE' = 'lightbulb-group-off-outline',
  'BULB_GROUP_OFF_OUTLINE' = 'bulb-group-off-outline',
  'LIGHTBULB_GROUP_OUTLINE' = 'lightbulb-group-outline',
  'BULB_GROUP_OUTLINE' = 'bulb-group-outline',
  'LIGHTBULB_MULTIPLE' = 'lightbulb-multiple',
  'LIGHTBULBS' = 'lightbulbs',
  'BULB_MULTIPLE' = 'bulb-multiple',
  'BULBS' = 'bulbs',
  'LIGHTBULB_MULTIPLE_OFF' = 'lightbulb-multiple-off',
  'LIGHTBULBS_OFF' = 'lightbulbs-off',
  'BULB_MULTIPLE_OFF' = 'bulb-multiple-off',
  'BULBS_OFF' = 'bulbs-off',
  'LIGHTBULB_MULTIPLE_OFF_OUTLINE' = 'lightbulb-multiple-off-outline',
  'LIGHTBULBS_OFF_OUTLINE' = 'lightbulbs-off-outline',
  'BULB_MULTIPLE_OFF_OUTLINE' = 'bulb-multiple-off-outline',
  'BULBS_OFF_OUTLINE' = 'bulbs-off-outline',
  'LIGHTBULB_MULTIPLE_OUTLINE' = 'lightbulb-multiple-outline',
  'LIGHTBULBS_OUTLINE' = 'lightbulbs-outline',
  'BULB_MULTIPLE_OUTLINE' = 'bulb-multiple-outline',
  'BULBS_OUTLINE' = 'bulbs-outline',
  'LIGHTBULB_OFF' = 'lightbulb-off',
  'BULB_OFF' = 'bulb-off',
  'LIGHTBULB_OFF_OUTLINE' = 'lightbulb-off-outline',
  'BULB_OFF_OUTLINE' = 'bulb-off-outline',
  'LIGHTBULB_ON' = 'lightbulb-on',
  'BULB_ON' = 'bulb-on',
  'LIGHTBULB_DIMMER_100' = 'lightbulb-dimmer-100',
  'LIGHTBULB_ON_OUTLINE' = 'lightbulb-on-outline',
  'BULB_ON_OUTLINE' = 'bulb-on-outline',
  'LIGHTBULB_OUTLINE' = 'lightbulb-outline',
  'BULB_OUTLINE' = 'bulb-outline',
  'LIGHTBULB_QUESTION' = 'lightbulb-question',
  'LIGHTBULB_HELP' = 'lightbulb-help',
  'LIGHTBULB_QUESTION_OUTLINE' = 'lightbulb-question-outline',
  'LIGHTBULB_HELP_OUTLINE' = 'lightbulb-help-outline',
  'LIGHTBULB_SPOT' = 'lightbulb-spot',
  'LIGHTBULB_HALOGEN' = 'lightbulb-halogen',
  'LIGHTBULB_GU10' = 'lightbulb-gu10',
  'LIGHTBULB_SPOT_OFF' = 'lightbulb-spot-off',
  'LIGHTBULB_HALOGEN_OFF' = 'lightbulb-halogen-off',
  'LIGHTBULB_GU10_OFF' = 'lightbulb-gu10-off',
  'LIGHTBULB_VARIANT' = 'lightbulb-variant',
  'LIGHTBULB_EDISON' = 'lightbulb-edison',
  'LIGHTBULB_FILAMENT' = 'lightbulb-filament',
  'LIGHTBULB_VARIANT_OUTLINE' = 'lightbulb-variant-outline',
  'LIGHTBULB_EDISON_OUTLINE' = 'lightbulb-edison-outline',
  'LIGHTBULB_FILAMENT_OUTLINE' = 'lightbulb-filament-outline',
  'LIGHTHOUSE' = 'lighthouse',
  'BEACON' = 'beacon',
  'LIGHTHOUSE_ON' = 'lighthouse-on',
  'THUNDER' = 'thunder',
  'STORM' = 'storm',
  'LIGHTNING_BOLT_CIRCLE' = 'lightning-bolt-circle',
  'AMP' = 'amp',
  'OFFLINE_BOLT' = 'offline-bolt',
  'FLASH_CIRCLE' = 'flash-circle',
  'ELECTRICITY_CIRCLE' = 'electricity-circle',
  'ENERGY_CIRCLE' = 'energy-circle',
  'THUNDER_CIRCLE' = 'thunder-circle',
  'STORM_CIRCLE' = 'storm-circle',
  'THUNDER_OUTLINE' = 'thunder-outline',
  'STORM_OUTLINE' = 'storm-outline',
  'ENERGY_OUTLINE' = 'energy-outline',
  'ELECTRICITY_OUTLINE' = 'electricity-outline',
  'LINE_SCAN' = 'line-scan',
  'LINGERIE' = 'lingerie',
  'UNDERWEAR' = 'underwear',
  'BRA' = 'bra',
  'PANTIES' = 'panties',
  'LINK' = 'link',
  'INSERT_LINK' = 'insert-link',
  'LINK_BOX' = 'link-box',
  'LINK_BOX_OUTLINE' = 'link-box-outline',
  'LINK_BOX_VARIANT' = 'link-box-variant',
  'LINK_BOX_VARIANT_OUTLINE' = 'link-box-variant-outline',
  'LINK_LOCK' = 'link-lock',
  'BLOCK_CHAIN' = 'block-chain',
  'LINK_OFF' = 'link-off',
  'LINK_PLUS' = 'link-plus',
  'LINK_ADD' = 'link-add',
  'LINK_VARIANT' = 'link-variant',
  'LINK_VARIANT_MINUS' = 'link-variant-minus',
  'LINK_VARIANT_OFF' = 'link-variant-off',
  'LINK_VARIANT_PLUS' = 'link-variant-plus',
  'LINK_VARIANT_REMOVE' = 'link-variant-remove',
  'LINKEDIN' = 'linkedin',
  'LINUX' = 'linux',
  'TUX' = 'tux',
  'LINUX_MINT' = 'linux-mint',
  'LIPSTICK' = 'lipstick',
  'LIQUID_SPOT' = 'liquid-spot',
  'INK_SPOT' = 'ink-spot',
  'PUDDLE' = 'puddle',
  'WATER' = 'water',
  'BLOOD' = 'blood',
  'SPILL' = 'spill',
  'OIL' = 'oil',
  'DIRTY' = 'dirty',
  'LIQUOR' = 'liquor',
  'BOOZE' = 'booze',
  'BEVERAGES' = 'beverages',
  'WHISKEY' = 'whiskey',
  'RUM' = 'rum',
  'WINE' = 'wine',
  'TEQUILA' = 'tequila',
  'SPIRITS' = 'spirits',
  'LIST_STATUS' = 'list-status',
  'LITECOIN' = 'litecoin',
  'LOADING' = 'loading',
  'LOCATION_ENTER' = 'location-enter',
  'PRESENCE_ENTER' = 'presence-enter',
  'LOCATION_EXIT' = 'location-exit',
  'PRESENCE_EXIT' = 'presence-exit',
  'LOCK' = 'lock',
  'HTTPS' = 'https',
  'PASSWORD' = 'password',
  'SECURE' = 'secure',
  'PROTECTED' = 'protected',
  'ENCRYPTION' = 'encryption',
  'LOCK_ALERT' = 'lock-alert',
  'LOCK_WARNING' = 'lock-warning',
  'PASSWORD_ALERT' = 'password-alert',
  'ENCRYPTION_ALERT' = 'encryption-alert',
  'PASSWORD_WARNING' = 'password-warning',
  'ENCRYPTION_WARNING' = 'encryption-warning',
  'LOCK_ALERT_OUTLINE' = 'lock-alert-outline',
  'LOCK_WARNING_OUTLINE' = 'lock-warning-outline',
  'PASSWORD_ALERT_OUTLINE' = 'password-alert-outline',
  'ENCRYPTION_ALERT_OUTLINE' = 'encryption-alert-outline',
  'PASSWORD_WARNING_OUTLINE' = 'password-warning-outline',
  'ENCRYPTION_WARNING_OUTLINE' = 'encryption-warning-outline',
  'LOCK_CHECK' = 'lock-check',
  'PASSWORD_CHECK' = 'password-check',
  'PASSWORD_SECURE' = 'password-secure',
  'ENCRYPTION_CHECK' = 'encryption-check',
  'ENCRYPTION_SECURE' = 'encryption-secure',
  'PASSWORD_VERIFIED' = 'password-verified',
  'ENCRYPTION_VERIFIED' = 'encryption-verified',
  'LOCK_CHECK_OUTLINE' = 'lock-check-outline',
  'PASSWORD_CHECK_OUTLINE' = 'password-check-outline',
  'PASSWORD_SECURE_OUTLINE' = 'password-secure-outline',
  'ENCRYPTION_CHECK_OUTLINE' = 'encryption-check-outline',
  'ENCRYPTION_SECURE_OUTLINE' = 'encryption-secure-outline',
  'PASSWORD_VERIFIED_OUTLINE' = 'password-verified-outline',
  'ENCRYPTION_VERIFIED_OUTLINE' = 'encryption-verified-outline',
  'LOCK_CLOCK' = 'lock-clock',
  'CONFIDENTIAL_MODE' = 'confidential-mode',
  'PASSWORD_CLOCK' = 'password-clock',
  'PASSWORD_EXPIRATION' = 'password-expiration',
  'ENCRYPTION_EXPIRATION' = 'encryption-expiration',
  'LOCK_MINUS' = 'lock-minus',
  'PASSWORD_MINUS' = 'password-minus',
  'ENCRYPTION_MINUS' = 'encryption-minus',
  'LOCK_MINUS_OUTLINE' = 'lock-minus-outline',
  'PASSWORD_MINUS_OUTLINE' = 'password-minus-outline',
  'LOCK_OFF' = 'lock-off',
  'PASSWORD_OFF' = 'password-off',
  'NOT_PROTECTED' = 'not-protected',
  'UNSECURE' = 'unsecure',
  'ENCRYPTION_OFF' = 'encryption-off',
  'LOCK_OFF_OUTLINE' = 'lock-off-outline',
  'PASSWORD_OFF_OUTLINE' = 'password-off-outline',
  'UNSECURE_OUTLINE' = 'unsecure-outline',
  'NOT_PROTECTED_OUTLINE' = 'not-protected-outline',
  'ENCRYPTION_OFF_OUTLINE' = 'encryption-off-outline',
  'LOCK_OPEN' = 'lock-open',
  'UNLOCKED' = 'unlocked',
  'DECRYPTED' = 'decrypted',
  'LOCK_OPEN_ALERT' = 'lock-open-alert',
  'UNLOCKED_ALERT' = 'unlocked-alert',
  'DECRYPTED_ALERT' = 'decrypted-alert',
  'LOCK_OPEN_WARNING' = 'lock-open-warning',
  'UNLOCKED_WARNING' = 'unlocked-warning',
  'DECRYPTED_WARNING' = 'decrypted-warning',
  'LOCK_OPEN_ALERT_OUTLINE' = 'lock-open-alert-outline',
  'UNLOCKED_ALERT_OUTLINE' = 'unlocked-alert-outline',
  'LOCK_OPEN_WARNING_OUTLINE' = 'lock-open-warning-outline',
  'DECRYPTED_ALERT_OUTLINE' = 'decrypted-alert-outline',
  'UNLOCKED_WARNING_OUTLINE' = 'unlocked-warning-outline',
  'DECRYPTED_WARNING_OUTLINE' = 'decrypted-warning-outline',
  'LOCK_OPEN_CHECK' = 'lock-open-check',
  'UNLOCKED_CHECK' = 'unlocked-check',
  'DECRYPTED_CHECK' = 'decrypted-check',
  'LOCK_OPEN_CHECK_OUTLINE' = 'lock-open-check-outline',
  'UNLOCKED_CHECK_OUTLINE' = 'unlocked-check-outline',
  'DECRYPTED_CHECK_OUTLINE' = 'decrypted-check-outline',
  'LOCK_OPEN_MINUS' = 'lock-open-minus',
  'UNLOCKED_MINUS' = 'unlocked-minus',
  'DECRYPTED_MINUS' = 'decrypted-minus',
  'LOCK_OPEN_MINUS_OUTLINE' = 'lock-open-minus-outline',
  'UNLOCKED_MINUS_OUTLINE' = 'unlocked-minus-outline',
  'DECRYPTED_MINUS_OUTLINE' = 'decrypted-minus-outline',
  'LOCK_OPEN_OUTLINE' = 'lock-open-outline',
  'UNLOCKED_OUTLINE' = 'unlocked-outline',
  'DECRYPTED_OUTLINE' = 'decrypted-outline',
  'LOCK_OPEN_PLUS' = 'lock-open-plus',
  'UNLOCKED_PLUS' = 'unlocked-plus',
  'DECRYPTED_PLUS' = 'decrypted-plus',
  'LOCK_OPEN_ADD' = 'lock-open-add',
  'UNLOCKED_ADD' = 'unlocked-add',
  'DECRYPTED_ADD' = 'decrypted-add',
  'LOCK_OPEN_PLUS_OUTLINE' = 'lock-open-plus-outline',
  'UNLOCKED_PLUS_OUTLINE' = 'unlocked-plus-outline',
  'LOCK_OPEN_ADD_OUTLINE' = 'lock-open-add-outline',
  'UNLOCKED_ADD_OUTLINE' = 'unlocked-add-outline',
  'DECRYPTED_PLUS_OUTLINE' = 'decrypted-plus-outline',
  'DECRYPTED_ADD_OUTLINE' = 'decrypted-add-outline',
  'LOCK_OPEN_REMOVE' = 'lock-open-remove',
  'UNLOCKED_REMOVE' = 'unlocked-remove',
  'DECRYPTED_REMOVE' = 'decrypted-remove',
  'LOCK_OPEN_REMOVE_OUTLINE' = 'lock-open-remove-outline',
  'UNLOCKED_REMOVE_OUTLINE' = 'unlocked-remove-outline',
  'DECRYPTED_REMOVE_OUTLINE' = 'decrypted-remove-outline',
  'LOCK_OPEN_VARIANT' = 'lock-open-variant',
  'UNLOCKED_VARIANT' = 'unlocked-variant',
  'DECRYPTED_VARIANT' = 'decrypted-variant',
  'LOCK_OPEN_VARIANT_OUTLINE' = 'lock-open-variant-outline',
  'UNLOCKED_VARIANT_OUTLINE' = 'unlocked-variant-outline',
  'DECRYPTED_VARIANT_OUTLINE' = 'decrypted-variant-outline',
  'LOCK_OUTLINE' = 'lock-outline',
  'PASSWORD_OUTLINE' = 'password-outline',
  'SECURE_OUTLINE' = 'secure-outline',
  'HTTPS_OUTLINE' = 'https-outline',
  'PROTECTED_OUTLINE' = 'protected-outline',
  'ENCRYPTION_OUTLINE' = 'encryption-outline',
  'LOCK_PATTERN' = 'lock-pattern',
  'LOCK_PLUS' = 'lock-plus',
  'ENHANCED_ENCRYPTION' = 'enhanced-encryption',
  'LOCK_ADD' = 'lock-add',
  'ENCRYPTION_ADD' = 'encryption-add',
  'PASSWORD_ADD' = 'password-add',
  'PASSWORD_PLUS' = 'password-plus',
  'ENCRYPTION_PLUS' = 'encryption-plus',
  'LOCK_PLUS_OUTLINE' = 'lock-plus-outline',
  'LOCK_ADD_OUTLINE' = 'lock-add-outline',
  'PASSWORD_PLUS_OUTLINE' = 'password-plus-outline',
  'PASSWORD_ADD_OUTLINE' = 'password-add-outline',
  'ENCRYPTION_PLUS_OUTLINE' = 'encryption-plus-outline',
  'ENCRYPTION_ADD_OUTLINE' = 'encryption-add-outline',
  'LOCK_QUESTION' = 'lock-question',
  'FORGOT_PASSWORD' = 'forgot-password',
  'PASSWORD_QUESTION' = 'password-question',
  'ENCRYPTION_QUESTION' = 'encryption-question',
  'LOCK_REMOVE' = 'lock-remove',
  'PASSWORD_REMOVE' = 'password-remove',
  'ENCRYPTION_REMOVE' = 'encryption-remove',
  'LOCK_REMOVE_OUTLINE' = 'lock-remove-outline',
  'PASSWORD_REMOVE_OUTLINE' = 'password-remove-outline',
  'ENCRYPTION_REMOVE_OUTLINE' = 'encryption-remove-outline',
  'LOCK_RESET' = 'lock-reset',
  'PASSWORD_RESET' = 'password-reset',
  'ENCRYPTION_RESET' = 'encryption-reset',
  'LOCK_SMART' = 'lock-smart',
  'LOCKER' = 'locker',
  'LOCKER_MULTIPLE' = 'locker-multiple',
  'LOCKERS' = 'lockers',
  'LOGIN' = 'login',
  'LOG_IN' = 'log-in',
  'SIGN_IN' = 'sign-in',
  'LOGIN_VARIANT' = 'login-variant',
  'LOG_IN_VARIANT' = 'log-in-variant',
  'SIGN_IN_VARIANT' = 'sign-in-variant',
  'LOGOUT' = 'logout',
  'LOG_OUT' = 'log-out',
  'SIGN_OUT' = 'sign-out',
  'LOGOUT_VARIANT' = 'logout-variant',
  'LOG_OUT_VARIANT' = 'log-out-variant',
  'SIGN_OUT_VARIANT' = 'sign-out-variant',
  'LONGITUDE' = 'longitude',
  'LOOKS' = 'looks',
  'RAINBOW' = 'rainbow',
  'LOTION' = 'lotion',
  'LOTION_OUTLINE' = 'lotion-outline',
  'LOTION_PLUS' = 'lotion-plus',
  'HAND_SANITIZER' = 'hand-sanitizer',
  'LOTION_PLUS_OUTLINE' = 'lotion-plus-outline',
  'HAND_SANITIZER_OUTLINE' = 'hand-sanitizer-outline',
  'LOUPE' = 'loupe',
  'LUMX' = 'lumx',
  'LUNGS' = 'lungs',
  'MACE' = 'mace',
  'MAGAZINE_PISTOL' = 'magazine-pistol',
  'AMMUNITION_PISTOL' = 'ammunition-pistol',
  'MAGAZINE_RIFLE' = 'magazine-rifle',
  'AMMUNITION_RIFLE' = 'ammunition-rifle',
  'MAGIC_STAFF' = 'magic-staff',
  'STAFF_SHIMMER' = 'staff-shimmer',
  'MAGIC_WAND' = 'magic-wand',
  'MAGNET' = 'magnet',
  'MAGNET_ON' = 'magnet-on',
  'MAGNIFY' = 'magnify',
  'SEARCH' = 'search',
  'MAGNIFY_CLOSE' = 'magnify-close',
  'MAGNIFY_EXPAND' = 'magnify-expand',
  'SEARCH_EXPAND' = 'search-expand',
  'MAGNIFY_MINUS' = 'magnify-minus',
  'ZOOM_OUT' = 'zoom-out',
  'SEARCH_MINUS' = 'search-minus',
  'MAGNIFY_MINUS_CURSOR' = 'magnify-minus-cursor',
  'ZOOM_OUT_CURSOR' = 'zoom-out-cursor',
  'MAGNIFY_MINUS_OUTLINE' = 'magnify-minus-outline',
  'ZOOM_OUT_OUTLINE' = 'zoom-out-outline',
  'SEARCH_MINUS_OUTLINE' = 'search-minus-outline',
  'MAGNIFY_PLUS' = 'magnify-plus',
  'ZOOM_IN' = 'zoom-in',
  'MAGNIFY_ADD' = 'magnify-add',
  'SEARCH_PLUS' = 'search-plus',
  'SEARCH_ADD' = 'search-add',
  'MAGNIFY_PLUS_CURSOR' = 'magnify-plus-cursor',
  'ZOOM_IN_CURSOR' = 'zoom-in-cursor',
  'MAGNIFY_ADD_CURSOR' = 'magnify-add-cursor',
  'MAGNIFY_PLUS_OUTLINE' = 'magnify-plus-outline',
  'ZOOM_IN_OUTLINE' = 'zoom-in-outline',
  'MAGNIFY_ADD_OUTLINE' = 'magnify-add-outline',
  'SEARCH_PLUS_OUTLINE' = 'search-plus-outline',
  'SEARCH_ADD_OUTLINE' = 'search-add-outline',
  'MAGNIFY_REMOVE_CURSOR' = 'magnify-remove-cursor',
  'MAGNIFY_REMOVE_OUTLINE' = 'magnify-remove-outline',
  'MAGNIFY_SCAN' = 'magnify-scan',
  'MAILBOX' = 'mailbox',
  'MAILBOX_OPEN' = 'mailbox-open',
  'MAILBOX_OPEN_OUTLINE' = 'mailbox-open-outline',
  'MAILBOX_OPEN_UP' = 'mailbox-open-up',
  'MAILBOX_OPEN_UP_OUTLINE' = 'mailbox-open-up-outline',
  'MAILBOX_OUTLINE' = 'mailbox-outline',
  'MAILBOX_UP' = 'mailbox-up',
  'MAILBOX_UP_OUTLINE' = 'mailbox-up-outline',
  'MANJARO' = 'manjaro',
  'MAP' = 'map',
  'MAP_CHECK' = 'map-check',
  'MAP_TICK' = 'map-tick',
  'MAP_CHECK_OUTLINE' = 'map-check-outline',
  'MAP_TICK_OUTLINE' = 'map-tick-outline',
  'MAP_CLOCK' = 'map-clock',
  'TIMEZONE' = 'timezone',
  'MAP_CLOCK_OUTLINE' = 'map-clock-outline',
  'TIMEZONE_OUTLINE' = 'timezone-outline',
  'MAP_LEGEND' = 'map-legend',
  'MAP_MARKER' = 'map-marker',
  'LOCATION' = 'location',
  'ADDRESS_MARKER' = 'address-marker',
  'LOCATION_ON' = 'location-on',
  'PLACE' = 'place',
  'ROOM' = 'room',
  'MAP_MARKER_ACCOUNT' = 'map-marker-account',
  'MAP_MARKER_ACCOUNT_OUTLINE' = 'map-marker-account-outline',
  'MAP_MARKER_ALERT' = 'map-marker-alert',
  'LOCATION_ALERT' = 'location-alert',
  'LOCATION_WARNING' = 'location-warning',
  'MAP_MARKER_ALERT_OUTLINE' = 'map-marker-alert-outline',
  'LOCATION_ALERT_OUTLINE' = 'location-alert-outline',
  'LOCATION_WARNING_OUTLINE' = 'location-warning-outline',
  'MAP_MARKER_CHECK' = 'map-marker-check',
  'MAP_MARKER_TICK' = 'map-marker-tick',
  'WHERE_TO_VOTE' = 'where-to-vote',
  'LOCATION_CHECK' = 'location-check',
  'MAP_MARKER_CHECK_OUTLINE' = 'map-marker-check-outline',
  'LOCATION_CHECK_OUTLINE' = 'location-check-outline',
  'WHERE_TO_VOTE_OUTLINE' = 'where-to-vote-outline',
  'MAP_MARKER_CIRCLE' = 'map-marker-circle',
  'EXPLORE_NEARBY' = 'explore-nearby',
  'LOCATION_CIRCLE' = 'location-circle',
  'MAP_MARKER_DISTANCE' = 'map-marker-distance',
  'LOCATION_DISTANCE' = 'location-distance',
  'MAP_MARKER_DOWN' = 'map-marker-down',
  'LOCATION_DOWN' = 'location-down',
  'MAP_MARKER_LEFT' = 'map-marker-left',
  'LOCATION_LEFT' = 'location-left',
  'MAP_MARKER_LEFT_OUTLINE' = 'map-marker-left-outline',
  'LOCATION_LEFT_OUTLINE' = 'location-left-outline',
  'MAP_MARKER_MINUS' = 'map-marker-minus',
  'LOCATION_MINUS' = 'location-minus',
  'MAP_MARKER_MINUS_OUTLINE' = 'map-marker-minus-outline',
  'LOCATION_MINUS_OUTLINE' = 'location-minus-outline',
  'MAP_MARKER_MULTIPLE' = 'map-marker-multiple',
  'MAP_MARKERS' = 'map-markers',
  'LOCATION_MULTIPLE' = 'location-multiple',
  'LOCATIONS' = 'locations',
  'MAP_MARKER_MULTIPLE_OUTLINE' = 'map-marker-multiple-outline',
  'LOCATIONS_OUTLINE' = 'locations-outline',
  'LOCATION_MULTIPLE_OUTLINE' = 'location-multiple-outline',
  'MAP_MARKERS_OUTLINE' = 'map-markers-outline',
  'MAP_MARKER_OFF' = 'map-marker-off',
  'LOCATION_OFF' = 'location-off',
  'MAP_MARKER_OFF_OUTLINE' = 'map-marker-off-outline',
  'LOCATION_OFF_OUTLINE' = 'location-off-outline',
  'MAP_MARKER_OUTLINE' = 'map-marker-outline',
  'LOCATION_OUTLINE' = 'location-outline',
  'ADDRESS_MARKER_OUTLINE' = 'address-marker-outline',
  'LOCATION_ON_OUTLINE' = 'location-on-outline',
  'PLACE_OUTLINE' = 'place-outline',
  'MAP_MARKER_PATH' = 'map-marker-path',
  'LOCATION_PATH' = 'location-path',
  'MAP_MARKER_PLUS' = 'map-marker-plus',
  'LOCATION_PLUS' = 'location-plus',
  'MAP_MARKER_ADD' = 'map-marker-add',
  'LOCATION_ADD' = 'location-add',
  'MAP_MARKER_PLUS_OUTLINE' = 'map-marker-plus-outline',
  'MAP_MARKER_ADD_OUTLINE' = 'map-marker-add-outline',
  'LOCATION_PLUS_OUTLINE' = 'location-plus-outline',
  'LOCATION_ADD_OUTLINE' = 'location-add-outline',
  'MAP_MARKER_QUESTION' = 'map-marker-question',
  'LOCATION_QUESTION' = 'location-question',
  'MAP_MARKER_QUESTION_OUTLINE' = 'map-marker-question-outline',
  'LOCATION_QUESTION_OUTLINE' = 'location-question-outline',
  'MAP_MARKER_RADIUS' = 'map-marker-radius',
  'LOCATION_RADIUS' = 'location-radius',
  'MAP_MARKER_RADIUS_OUTLINE' = 'map-marker-radius-outline',
  'LOCATION_RADIUS_OUTLINE' = 'location-radius-outline',
  'MAP_MARKER_REMOVE' = 'map-marker-remove',
  'LOCATION_REMOVE' = 'location-remove',
  'MAP_MARKER_REMOVE_OUTLINE' = 'map-marker-remove-outline',
  'LOCATION_REMOVE_OUTLINE' = 'location-remove-outline',
  'MAP_MARKER_REMOVE_VARIANT' = 'map-marker-remove-variant',
  'LOCATION_REMOVE_VARIANT_OUTLINE' = 'location-remove-variant-outline',
  'MAP_MARKER_RIGHT' = 'map-marker-right',
  'LOCATION_RIGHT' = 'location-right',
  'MAP_MARKER_RIGHT_OUTLINE' = 'map-marker-right-outline',
  'LOCATION_RIGHT_OUTLINE' = 'location-right-outline',
  'MAP_MARKER_STAR' = 'map-marker-star',
  'MAP_MARKER_FAVORITE' = 'map-marker-favorite',
  'LOCATION_STAR' = 'location-star',
  'LOCATION_FAVORITE' = 'location-favorite',
  'MAP_MARKER_STAR_OUTLINE' = 'map-marker-star-outline',
  'MAP_MARKER_FAVORITE_OUTLINE' = 'map-marker-favorite-outline',
  'LOCATION_STAR_OUTLINE' = 'location-star-outline',
  'LOCATION_FAVORITE_OUTLINE' = 'location-favorite-outline',
  'MAP_MARKER_UP' = 'map-marker-up',
  'LOCATION_UP' = 'location-up',
  'MAP_MINUS' = 'map-minus',
  'MAP_OUTLINE' = 'map-outline',
  'MAP_PLUS' = 'map-plus',
  'MAP_ADD' = 'map-add',
  'MAP_SEARCH' = 'map-search',
  'MAP_SEARCH_OUTLINE' = 'map-search-outline',
  'MAPBOX' = 'mapbox',
  'MARGIN' = 'margin',
  'MARKER' = 'marker',
  'HIGHLIGHTER' = 'highlighter',
  'MARKER_CANCEL' = 'marker-cancel',
  'MARKER_CHECK' = 'marker-check',
  'BEENHERE' = 'beenhere',
  'MARKER_TICK' = 'marker-tick',
  'MASTODON' = 'mastodon',
  'MATERIAL_DESIGN' = 'material-design',
  'MATERIAL_UI' = 'material-ui',
  'MATH_COMPASS' = 'math-compass',
  'MATHS_COMPASS' = 'maths-compass',
  'MATH_COS' = 'math-cos',
  'MATH_COSINE' = 'math-cosine',
  'MATHS_COS' = 'maths-cos',
  'MATH_INTEGRAL' = 'math-integral',
  'MATH_INTEGRAL_BOX' = 'math-integral-box',
  'MATH_LOG' = 'math-log',
  'MATH_NORM' = 'math-norm',
  'CODE_OR' = 'code-or',
  'PARALLEL' = 'parallel',
  'MATH_NORM_BOX' = 'math-norm-box',
  'CODE_OR_BOX' = 'code-or-box',
  'PARALLEL_BOX' = 'parallel-box',
  'MATH_SIN' = 'math-sin',
  'MATH_SINE' = 'math-sine',
  'MATHS_SIN' = 'maths-sin',
  'MATH_TAN' = 'math-tan',
  'MATH_TANGENT' = 'math-tangent',
  'MATHS_TAN' = 'maths-tan',
  'MATRIX' = 'matrix',
  'MEDAL' = 'medal',
  'MEDAL_OUTLINE' = 'medal-outline',
  'MEDICAL_BAG' = 'medical-bag',
  'FIRST_AID_KIT' = 'first-aid-kit',
  'MEDICINE' = 'medicine',
  'MEDITATION' = 'meditation',
  'HUMAN_MEDITATION' = 'human-meditation',
  'MEMORY' = 'memory',
  'MENORAH' = 'menorah',
  'MENORAH_FIRE' = 'menorah-fire',
  'MENORAH_FLAME' = 'menorah-flame',
  'HAMBURGER_MENU' = 'hamburger-menu',
  'MENU_DOWN' = 'menu-down',
  'ARROW_DROP_DOWN' = 'arrow-drop-down',
  'CARET_DOWN' = 'caret-down',
  'MENU_DOWN_OUTLINE' = 'menu-down-outline',
  'CARET_DOWN_OUTLINE' = 'caret-down-outline',
  'MENU_LEFT' = 'menu-left',
  'MENU_LEFT_OUTLINE' = 'menu-left-outline',
  'MENU_OPEN' = 'menu-open',
  'MENU_RIGHT' = 'menu-right',
  'MENU_RIGHT_OUTLINE' = 'menu-right-outline',
  'MENU_SWAP' = 'menu-swap',
  'MENU_SWAP_OUTLINE' = 'menu-swap-outline',
  'MENU_UP' = 'menu-up',
  'ARROW_DROP_UP' = 'arrow-drop-up',
  'CARET_UP' = 'caret-up',
  'MENU_UP_OUTLINE' = 'menu-up-outline',
  'CARET_UP_OUTLINE' = 'caret-up-outline',
  'MERGE' = 'merge',
  'MESSAGE' = 'message',
  'CHAT_BUBBLE' = 'chat-bubble',
  'MESSAGE_ALERT' = 'message-alert',
  'MESSAGE_WARNING' = 'message-warning',
  'SMS_FAILED' = 'sms-failed',
  'MESSAGE_ALERT_OUTLINE' = 'message-alert-outline',
  'MESSAGE_WARNING_OUTLINE' = 'message-warning-outline',
  'SMS_FAILED_OUTLINE' = 'sms-failed-outline',
  'MESSAGE_ARROW_LEFT' = 'message-arrow-left',
  'MESSAGE_ARROW_LEFT_OUTLINE' = 'message-arrow-left-outline',
  'MESSAGE_ARROW_RIGHT' = 'message-arrow-right',
  'MESSAGE_ARROW_RIGHT_OUTLINE' = 'message-arrow-right-outline',
  'MESSAGE_BADGE' = 'message-badge',
  'MESSAGE_UNREAD' = 'message-unread',
  'MESSAGE_NOTIFICATION' = 'message-notification',
  'MESSAGE_BADGE_OUTLINE' = 'message-badge-outline',
  'MESSAGE_UNREAD_OUTLINE' = 'message-unread-outline',
  'MESSAGE_NOTIFICATION_OUTLINE' = 'message-notification-outline',
  'MESSAGE_BOOKMARK' = 'message-bookmark',
  'MESSAGE_BOOKMARK_OUTLINE' = 'message-bookmark-outline',
  'MESSAGE_BULLETED' = 'message-bulleted',
  'SPEAKER_NOTES' = 'speaker-notes',
  'MESSAGE_BULLETED_OFF' = 'message-bulleted-off',
  'SPEAKER_NOTES_OFF' = 'speaker-notes-off',
  'MESSAGE_COG' = 'message-cog',
  'MESSAGE_COG_OUTLINE' = 'message-cog-outline',
  'MESSAGE_DRAW' = 'message-draw',
  'RATE_REVIEW' = 'rate-review',
  'MESSAGE_FAST' = 'message-fast',
  'MESSAGE_FAST_OUTLINE' = 'message-fast-outline',
  'MESSAGE_FLASH' = 'message-flash',
  'MESSAGE_QUICK' = 'message-quick',
  'MESSAGE_FLASH_OUTLINE' = 'message-flash-outline',
  'MESSAGE_QUICK_OUTLINE' = 'message-quick-outline',
  'MESSAGE_IMAGE' = 'message-image',
  'MMS' = 'mms',
  'MESSAGE_IMAGE_OUTLINE' = 'message-image-outline',
  'MESSAGE_LOCK' = 'message-lock',
  'MESSAGE_SECURE' = 'message-secure',
  'MESSAGE_LOCK_OUTLINE' = 'message-lock-outline',
  'MESSAGE_MINUS' = 'message-minus',
  'MESSAGE_MINUS_OUTLINE' = 'message-minus-outline',
  'MESSAGE_OFF' = 'message-off',
  'MESSAGE_OFF_OUTLINE' = 'message-off-outline',
  'MESSAGE_OUTLINE' = 'message-outline',
  'CHAT_BUBBLE_OUTLINE' = 'chat-bubble-outline',
  'MESSAGE_PLUS' = 'message-plus',
  'MESSAGE_ADD' = 'message-add',
  'MESSAGE_PLUS_OUTLINE' = 'message-plus-outline',
  'MESSAGE_PROCESSING' = 'message-processing',
  'SMS' = 'sms',
  'TEXTSMS' = 'textsms',
  'MESSAGE_PROCESSING_OUTLINE' = 'message-processing-outline',
  'MESSAGE_QUESTION' = 'message-question',
  'MESSAGE_QUESTION_OUTLINE' = 'message-question-outline',
  'MESSAGE_REPLY' = 'message-reply',
  'MODE_COMMENT' = 'mode-comment',
  'MESSAGE_REPLY_OUTLINE' = 'message-reply-outline',
  'MESSAGE_REPLY_TEXT' = 'message-reply-text',
  'INSERT_COMMENT' = 'insert-comment',
  'MESSAGE_REPLY_TEXT_OUTLINE' = 'message-reply-text-outline',
  'MESSAGE_SETTINGS' = 'message-settings',
  'MESSAGE_SETTINGS_OUTLINE' = 'message-settings-outline',
  'MESSAGE_STAR' = 'message-star',
  'MESSAGE_STAR_OUTLINE' = 'message-star-outline',
  'MESSAGE_TEXT' = 'message-text',
  'MESSAGE_TEXT_CLOCK' = 'message-text-clock',
  'MESSAGE_TEXT_CLOCK_OUTLINE' = 'message-text-clock-outline',
  'MESSAGE_TEXT_FAST' = 'message-text-fast',
  'MESSAGE_TEXT_FAST_OUTLINE' = 'message-text-fast-outline',
  'MESSAGE_TEXT_LOCK' = 'message-text-lock',
  'MESSAGE_TEXT_SECURE' = 'message-text-secure',
  'MESSAGE_TEXT_LOCK_OUTLINE' = 'message-text-lock-outline',
  'MESSAGE_TEXT_OUTLINE' = 'message-text-outline',
  'MESSAGE_VIDEO' = 'message-video',
  'VOICE_CHAT' = 'voice-chat',
  'METEOR' = 'meteor',
  'METRONOME' = 'metronome',
  'TEMPO' = 'tempo',
  'BPM' = 'bpm',
  'BEATS_PER_MINUTE' = 'beats-per-minute',
  'METRONOME_TICK' = 'metronome-tick',
  'TEMPO_TICK' = 'tempo-tick',
  'BPM_TICK' = 'bpm-tick',
  'BEATS_PER_MINUTE_TICK' = 'beats-per-minute-tick',
  'MICRO_SD' = 'micro-sd',
  'MICROPHONE' = 'microphone',
  'KEYBOARD_VOICE' = 'keyboard-voice',
  'MICROPHONE_MINUS' = 'microphone-minus',
  'MICROPHONE_REMOVE' = 'microphone-remove',
  'MICROPHONE_OFF' = 'microphone-off',
  'MIC_OFF' = 'mic-off',
  'MICROPHONE_OUTLINE' = 'microphone-outline',
  'MIC_NONE' = 'mic-none',
  'MICROPHONE_PLUS' = 'microphone-plus',
  'MICROPHONE_ADD' = 'microphone-add',
  'MICROPHONE_QUESTION' = 'microphone-question',
  'MICROPHONE_HELP' = 'microphone-help',
  'MICROPHONE_QUESTION_OUTLINE' = 'microphone-question-outline',
  'MICROPHONE_HELP_OUTLINE' = 'microphone-help-outline',
  'MICROPHONE_SETTINGS' = 'microphone-settings',
  'SETTINGS_VOICE' = 'settings-voice',
  'MICROPHONE_VARIANT' = 'microphone-variant',
  'MICROPHONE_VARIANT_OFF' = 'microphone-variant-off',
  'MICROSCOPE' = 'microscope',
  'MICROSOFT' = 'microsoft',
  'MICROSOFT_ACCESS' = 'microsoft-access',
  'MICROSOFT_AZURE' = 'microsoft-azure',
  'MICROSOFT_AZURE_DEVOPS' = 'microsoft-azure-devops',
  'MICROSOFT_BING' = 'microsoft-bing',
  'MICROSOFT_DYNAMICS365' = 'microsoft-dynamics365',
  'MICROSOFT_EDGE' = 'microsoft-edge',
  'MICROSOFT_EXCEL' = 'microsoft-excel',
  'MICROSOFT_INTERNET_EXPLORER' = 'microsoft-internet-explorer',
  'MICROSOFT_OFFICE' = 'microsoft-office',
  'MICROSOFT_ONEDRIVE' = 'microsoft-onedrive',
  'MICROSOFT_ONENOTE' = 'microsoft-onenote',
  'MICROSOFT_OUTLOOK' = 'microsoft-outlook',
  'MICROSOFT_POWERPOINT' = 'microsoft-powerpoint',
  'MICROSOFT_SHAREPOINT' = 'microsoft-sharepoint',
  'MICROSOFT_TEAMS' = 'microsoft-teams',
  'MICROSOFT_VISUAL_STUDIO' = 'microsoft-visual-studio',
  'VISUALSTUDIO' = 'visualstudio',
  'MICROSOFT_VISUAL_STUDIO_CODE' = 'microsoft-visual-studio-code',
  'VS_CODE' = 'vs-code',
  'MICROSOFT_WINDOWS' = 'microsoft-windows',
  'MICROSOFT_WINDOWS_CLASSIC' = 'microsoft-windows-classic',
  'MICROSOFT_WORD' = 'microsoft-word',
  'MICROSOFT_XBOX' = 'microsoft-xbox',
  'XBOX_LIVE' = 'xbox-live',
  'MICROSOFT_XBOX_CONTROLLER' = 'microsoft-xbox-controller',
  'MICROSOFT_XBOX_GAMEPAD' = 'microsoft-xbox-gamepad',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_ALERT' = 'microsoft-xbox-controller-battery-alert',
  'XBOX_CONTROLLER_BATTERY_WARNING' = 'xbox-controller-battery-warning',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_ALERT' = 'microsoft-xbox-gamepad-battery-alert',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_CHARGING' = 'microsoft-xbox-controller-battery-charging',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_CHARGING' = 'microsoft-xbox-gamepad-battery-charging',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_EMPTY' = 'microsoft-xbox-controller-battery-empty',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_EMPTY' = 'microsoft-xbox-gamepad-battery-empty',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_FULL' = 'microsoft-xbox-controller-battery-full',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_FULL' = 'microsoft-xbox-gamepad-battery-full',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_LOW' = 'microsoft-xbox-controller-battery-low',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_LOW' = 'microsoft-xbox-gamepad-battery-low',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_MEDIUM' = 'microsoft-xbox-controller-battery-medium',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_MEDIUM' = 'microsoft-xbox-gamepad-battery-medium',
  'MICROSOFT_XBOX_CONTROLLER_BATTERY_UNKNOWN' = 'microsoft-xbox-controller-battery-unknown',
  'MICROSOFT_XBOX_GAMEPAD_BATTERY_UNKNOWN' = 'microsoft-xbox-gamepad-battery-unknown',
  'MICROSOFT_XBOX_CONTROLLER_MENU' = 'microsoft-xbox-controller-menu',
  'MICROSOFT_XBOX_CONTROLLER_OFF' = 'microsoft-xbox-controller-off',
  'MICROSOFT_XBOX_GAMEPAD_OFF' = 'microsoft-xbox-gamepad-off',
  'MICROSOFT_XBOX_CONTROLLER_VIEW' = 'microsoft-xbox-controller-view',
  'MICROWAVE' = 'microwave',
  'MICROWAVE_OVEN' = 'microwave-oven',
  'MICROWAVE_OFF' = 'microwave-off',
  'MIDDLEWARE' = 'middleware',
  'MIDDLEWARE_OUTLINE' = 'middleware-outline',
  'MIDI' = 'midi',
  'MIDI_PORT' = 'midi-port',
  'MINE' = 'mine',
  'MINECRAFT' = 'minecraft',
  'MICROSOFT_MINECRAFT' = 'microsoft-minecraft',
  'MINI_SD' = 'mini-sd',
  'MINIDISC' = 'minidisc',
  'MINUS' = 'minus',
  'HORIZONTAL_LINE' = 'horizontal-line',
  'MINUS_BOX' = 'minus-box',
  'INDETERMINATE_CHECK_BOX' = 'indeterminate-check-box',
  'MINUS_BOX_MULTIPLE' = 'minus-box-multiple',
  'LIBRARY_MINUS' = 'library-minus',
  'MINUS_BOX_MULTIPLE_OUTLINE' = 'minus-box-multiple-outline',
  'LIBRARY_MINUS_OUTLINE' = 'library-minus-outline',
  'MINUS_BOX_OUTLINE' = 'minus-box-outline',
  'CHECKBOX_INDETERMINATE_OUTLINE' = 'checkbox-indeterminate-outline',
  'MINUS_CIRCLE' = 'minus-circle',
  'DO_NOT_DISTURB_ON' = 'do-not-disturb-on',
  'DO_NOT_ENTER' = 'do-not-enter',
  'PILL_TABLET' = 'pill-tablet',
  'PHARMACEUTICAL' = 'pharmaceutical',
  'MINUS_CIRCLE_MULTIPLE' = 'minus-circle-multiple',
  'COINS_MINUS' = 'coins-minus',
  'MINUS_CIRCLE_MULTIPLE_OUTLINE' = 'minus-circle-multiple-outline',
  'COINS_MINUS_OUTLINE' = 'coins-minus-outline',
  'MINUS_CIRCLE_OFF' = 'minus-circle-off',
  'DO_NOT_DISTURB_OFF' = 'do-not-disturb-off',
  'REMOVE_CIRCLE_OFF' = 'remove-circle-off',
  'DO_NOT_ENTER_OFF' = 'do-not-enter-off',
  'MINUS_CIRCLE_OFF_OUTLINE' = 'minus-circle-off-outline',
  'DO_NOT_DISTURB_OFF_OUTLINE' = 'do-not-disturb-off-outline',
  'REMOVE_CIRCLE_OFF_OUTLINE' = 'remove-circle-off-outline',
  'DO_NOT_ENTER_OFF_OUTLINE' = 'do-not-enter-off-outline',
  'MINUS_CIRCLE_OUTLINE' = 'minus-circle-outline',
  'DO_NOT_ENTER_OUTLINE' = 'do-not-enter-outline',
  'DO_NOT_DISTURB_OUTLINE' = 'do-not-disturb-outline',
  'PILL_TABLET_OUTLINE' = 'pill-tablet-outline',
  'MEDICINE_OUTLINE' = 'medicine-outline',
  'MINUS_NETWORK' = 'minus-network',
  'MINUS_NETWORK_OUTLINE' = 'minus-network-outline',
  'MINUS_THICK' = 'minus-thick',
  'MIRROR' = 'mirror',
  'MIRROR_RECTANGLE' = 'mirror-rectangle',
  'MIRROR_VARIANT' = 'mirror-variant',
  'MIXED_MARTIAL_ARTS' = 'mixed-martial-arts',
  'MMA' = 'mma',
  'GLOVE' = 'glove',
  'MIXED_REALITY' = 'mixed-reality',
  'MOLECULE' = 'molecule',
  'MOLECULE_CO' = 'molecule-co',
  'CARBON_MONOXIDE' = 'carbon-monoxide',
  'GAS_CO' = 'gas-co',
  'MOLECULE_CO2' = 'molecule-co2',
  'PERIODIC_TABLE_CARBON_DIOXIDE' = 'periodic-table-carbon-dioxide',
  'GAS_CO2' = 'gas-co2',
  'DESKTOP_WINDOWS' = 'desktop-windows',
  'MONITOR_ARROW_DOWN' = 'monitor-arrow-down',
  'MONITOR_DOWNLOAD' = 'monitor-download',
  'MONITOR_ARROW_DOWN_VARIANT' = 'monitor-arrow-down-variant',
  'MONITOR_CELLPHONE' = 'monitor-cellphone',
  'MONITOR_MOBILE_PHONE' = 'monitor-mobile-phone',
  'MONITOR_SMARTPHONE' = 'monitor-smartphone',
  'MONITOR_CELLPHONE_STAR' = 'monitor-cellphone-star',
  'IMPORTANT_DEVICES' = 'important-devices',
  'MONITOR_MOBILE_PHONE_STAR' = 'monitor-mobile-phone-star',
  'MONITOR_SMARTPHONE_STAR' = 'monitor-smartphone-star',
  'MONITOR_CELLPHONE_FAVORITE' = 'monitor-cellphone-favorite',
  'MONITOR_DASHBOARD' = 'monitor-dashboard',
  'MONITOR_EDIT' = 'monitor-edit',
  'MONITOR_EYE' = 'monitor-eye',
  'MONITOR_LOCK' = 'monitor-lock',
  'MONITOR_MULTIPLE' = 'monitor-multiple',
  'MONITORS' = 'monitors',
  'MONITOR_OFF' = 'monitor-off',
  'MONITOR_SCREENSHOT' = 'monitor-screenshot',
  'MONITOR_SHARE' = 'monitor-share',
  'MONITOR_SHIMMER' = 'monitor-shimmer',
  'MONITOR_CLEAN' = 'monitor-clean',
  'MONITOR_SMALL' = 'monitor-small',
  'MONITOR_CRT' = 'monitor-crt',
  'MONITOR_SPEAKER' = 'monitor-speaker',
  'MONITOR_SPEAKER_OFF' = 'monitor-speaker-off',
  'MONITOR_STAR' = 'monitor-star',
  'MONITOR_FAVORITE' = 'monitor-favorite',
  'MOON_FIRST_QUARTER' = 'moon-first-quarter',
  'MOON_FULL' = 'moon-full',
  'MOON_LAST_QUARTER' = 'moon-last-quarter',
  'MOON_NEW' = 'moon-new',
  'MOON_WANING_CRESCENT' = 'moon-waning-crescent',
  'MOON_WANING_GIBBOUS' = 'moon-waning-gibbous',
  'MOON_WAXING_CRESCENT' = 'moon-waxing-crescent',
  'MOON_WAXING_GIBBOUS' = 'moon-waxing-gibbous',
  'MOPED' = 'moped',
  'SCOOTER' = 'scooter',
  'VESPA' = 'vespa',
  'DELIVERY_DINING' = 'delivery-dining',
  'MOPED_ELECTRIC' = 'moped-electric',
  'SCOOTER_ELECTRIC' = 'scooter-electric',
  'VESPA_ELECTRIC' = 'vespa-electric',
  'DELIVERY_DINING_ELECTRIC' = 'delivery-dining-electric',
  'MOPED_ELECTRIC_OUTLINE' = 'moped-electric-outline',
  'SCOOTER_ELECTRIC_OUTLINE' = 'scooter-electric-outline',
  'VESPA_ELECTRIC_OUTLINE' = 'vespa-electric-outline',
  'DELIVERY_DINING_ELECTRIC_OUTLINE' = 'delivery-dining-electric-outline',
  'MOPED_OUTLINE' = 'moped-outline',
  'SCOOTER_OUTLINE' = 'scooter-outline',
  'VESPA_OUTLINE' = 'vespa-outline',
  'DELIVERY_DINING_OUTLINE' = 'delivery-dining-outline',
  'MORTAR_PESTLE' = 'mortar-pestle',
  'MORTAR_PESTLE_PLUS' = 'mortar-pestle-plus',
  'CHEMIST' = 'chemist',
  'LOCAL_PHARMACY' = 'local-pharmacy',
  'MOSQUE' = 'mosque',
  'ISLAM' = 'islam',
  'MUSLIM' = 'muslim',
  'MOTHER_HEART' = 'mother-heart',
  'MOTHER_NURSE' = 'mother-nurse',
  'BREAST_FEED' = 'breast-feed',
  'MOTION' = 'motion',
  'MOTION_OUTLINE' = 'motion-outline',
  'MOTION_PAUSE' = 'motion-pause',
  'MOTION_PAUSE_OUTLINE' = 'motion-pause-outline',
  'MOTION_PLAY' = 'motion-play',
  'MOTION_PLAY_OUTLINE' = 'motion-play-outline',
  'MOTION_SENSOR' = 'motion-sensor',
  'MOTION_DETECTOR' = 'motion-detector',
  'MOTION_SENSOR_OFF' = 'motion-sensor-off',
  'MOTORBIKE' = 'motorbike',
  'MOTORCYCLE' = 'motorcycle',
  'MOTORBIKE_ELECTRIC' = 'motorbike-electric',
  'MOTORCYCLE_ELECTRIC' = 'motorcycle-electric',
  'MOUSE' = 'mouse',
  'MOUSE_BLUETOOTH' = 'mouse-bluetooth',
  'MOUSE_MOVE_DOWN' = 'mouse-move-down',
  'MOUSE_MOVE_UP' = 'mouse-move-up',
  'MOUSE_MOVE_VERTICAL' = 'mouse-move-vertical',
  'MOUSE_OFF' = 'mouse-off',
  'MOUSE_VARIANT' = 'mouse-variant',
  'MOUSE_VARIANT_OFF' = 'mouse-variant-off',
  'MOVE_RESIZE' = 'move-resize',
  'MOVE_RESIZE_VARIANT' = 'move-resize-variant',
  'MOVIE' = 'movie',
  'SLATE' = 'slate',
  'CLAPPERBOARD' = 'clapperboard',
  'MOVIE_CREATION' = 'movie-creation',
  'MOVIE_CHECK' = 'movie-check',
  'SLATE_CHECK' = 'slate-check',
  'CLAPPERBOARD_CHECK' = 'clapperboard-check',
  'FILM_CHECK' = 'film-check',
  'MOVIE_CHECK_OUTLINE' = 'movie-check-outline',
  'SLATE_CHECK_OUTLINE' = 'slate-check-outline',
  'CLAPPERBOARD_CHECK_OUTLINE' = 'clapperboard-check-outline',
  'FILM_CHECK_OUTLINE' = 'film-check-outline',
  'MOVIE_COG' = 'movie-cog',
  'SLATE_COG' = 'slate-cog',
  'CLAPPERBOARD_COG' = 'clapperboard-cog',
  'FILM_COG' = 'film-cog',
  'MOVIE_COG_OUTLINE' = 'movie-cog-outline',
  'SLATE_COG_OUTLINE' = 'slate-cog-outline',
  'CLAPPERBOARD_COG_OUTLINE' = 'clapperboard-cog-outline',
  'FILM_COG_OUTLINE' = 'film-cog-outline',
  'MOVIE_EDIT' = 'movie-edit',
  'SLATE_EDIT' = 'slate-edit',
  'CLAPPERBOARD_EDIT' = 'clapperboard-edit',
  'FILM_EDIT' = 'film-edit',
  'MOVIE_EDIT_OUTLINE' = 'movie-edit-outline',
  'SLATE_EDIT_OUTLINE' = 'slate-edit-outline',
  'CLAPPERBOARD_EDIT_OUTLINE' = 'clapperboard-edit-outline',
  'FILM_EDIT_OUTLINE' = 'film-edit-outline',
  'MOVIE_FILTER' = 'movie-filter',
  'MOVIE_FILTER_OUTLINE' = 'movie-filter-outline',
  'MOVIE_MINUS' = 'movie-minus',
  'SLATE_MINUS' = 'slate-minus',
  'CLAPPERBOARD_MINUS' = 'clapperboard-minus',
  'FILM_MINUS' = 'film-minus',
  'MOVIE_MINUS_OUTLINE' = 'movie-minus-outline',
  'SLATE_MINUS_OUTLINE' = 'slate-minus-outline',
  'CLAPPERBOARD_MINUS_OUTLINE' = 'clapperboard-minus-outline',
  'FILM_MINUS_OUTLINE' = 'film-minus-outline',
  'MOVIE_OFF' = 'movie-off',
  'SLATE_OFF' = 'slate-off',
  'CLAPPERBOARD_OFF' = 'clapperboard-off',
  'FILM_OFF' = 'film-off',
  'MOVIE_OFF_OUTLINE' = 'movie-off-outline',
  'SLATE_OFF_OUTLINE' = 'slate-off-outline',
  'CLAPPERBOARD_OFF_OUTLINE' = 'clapperboard-off-outline',
  'FILM_OFF_OUTLINE' = 'film-off-outline',
  'MOVIE_OPEN' = 'movie-open',
  'SLATE_OPEN' = 'slate-open',
  'CLAPPERBOARD_OPEN' = 'clapperboard-open',
  'FILM_OPEN' = 'film-open',
  'MOVIE_OPEN_CHECK' = 'movie-open-check',
  'SLATE_OPEN_CHECK' = 'slate-open-check',
  'CLAPPERBOARD_OPEN_CHECK' = 'clapperboard-open-check',
  'FILM_OPEN_CHECK' = 'film-open-check',
  'MOVIE_OPEN_CHECK_OUTLINE' = 'movie-open-check-outline',
  'SLATE_OPEN_CHECK_OUTLINE' = 'slate-open-check-outline',
  'CLAPPERBOARD_OPEN_CHECK_OUTLINE' = 'clapperboard-open-check-outline',
  'FILM_OPEN_CHECK_OUTLINE' = 'film-open-check-outline',
  'MOVIE_OPEN_COG' = 'movie-open-cog',
  'SLATE_OPEN_COG' = 'slate-open-cog',
  'CLAPPERBOARD_OPEN_COG' = 'clapperboard-open-cog',
  'FILM_OPEN_COG' = 'film-open-cog',
  'MOVIE_OPEN_COG_OUTLINE' = 'movie-open-cog-outline',
  'SLATE_OPEN_COG_OUTLINE' = 'slate-open-cog-outline',
  'CLAPPERBOARD_OPEN_COG_OUTLINE' = 'clapperboard-open-cog-outline',
  'FILM_OPEN_COG_OUTLINE' = 'film-open-cog-outline',
  'MOVIE_OPEN_EDIT' = 'movie-open-edit',
  'SLATE_OPEN_EDIT' = 'slate-open-edit',
  'CLAPPERBOARD_OPEN_EDIT' = 'clapperboard-open-edit',
  'FILM_OPEN_EDIT' = 'film-open-edit',
  'MOVIE_OPEN_EDIT_OUTLINE' = 'movie-open-edit-outline',
  'SLATE_OPEN_EDIT_OUTLINE' = 'slate-open-edit-outline',
  'CLAPPERBOARD_OPEN_EDIT_OUTLINE' = 'clapperboard-open-edit-outline',
  'FILM_OPEN_EDIT_OUTLINE' = 'film-open-edit-outline',
  'MOVIE_OPEN_MINUS' = 'movie-open-minus',
  'SLATE_OPEN_MINUS' = 'slate-open-minus',
  'CLAPPERBOARD_OPEN_MINUS' = 'clapperboard-open-minus',
  'FILM_OPEN_MINUS' = 'film-open-minus',
  'MOVIE_OPEN_MINUS_OUTLINE' = 'movie-open-minus-outline',
  'SLATE_OPEN_MINUS_OUTLINE' = 'slate-open-minus-outline',
  'CLAPPERBOARD_OPEN_MINUS_OUTLINE' = 'clapperboard-open-minus-outline',
  'FILM_OPEN_MINUS_OUTLINE' = 'film-open-minus-outline',
  'MOVIE_OPEN_OFF' = 'movie-open-off',
  'SLATE_OPEN_OFF' = 'slate-open-off',
  'CLAPPERBOARD_OPEN_OFF' = 'clapperboard-open-off',
  'FILM_OPEN_OFF' = 'film-open-off',
  'MOVIE_OPEN_OFF_OUTLINE' = 'movie-open-off-outline',
  'SLATE_OPEN_OFF_OUTLINE' = 'slate-open-off-outline',
  'CLAPPERBOARD_OPEN_OFF_OUTLINE' = 'clapperboard-open-off-outline',
  'FILM_OPEN_OFF_OUTLINE' = 'film-open-off-outline',
  'MOVIE_OPEN_OUTLINE' = 'movie-open-outline',
  'SLATE_OPEN_OUTLINE' = 'slate-open-outline',
  'CLAPPERBOARD_OPEN_OUTLINE' = 'clapperboard-open-outline',
  'FILM_OPEN_OUTLINE' = 'film-open-outline',
  'MOVIE_OPEN_PLAY' = 'movie-open-play',
  'SLATE_OPEN_PLAY' = 'slate-open-play',
  'CLAPPERBOARD_OPEN_PLAY' = 'clapperboard-open-play',
  'FILM_OPEN_PLAY' = 'film-open-play',
  'MOVIE_OPEN_PLAY_OUTLINE' = 'movie-open-play-outline',
  'SLATE_OPEN_PLAY_OUTLINE' = 'slate-open-play-outline',
  'CLAPPERBOARD_OPEN_PLAY_OUTLINE' = 'clapperboard-open-play-outline',
  'FILM_OPEN_PLAY_OUTLINE' = 'film-open-play-outline',
  'MOVIE_OPEN_PLUS' = 'movie-open-plus',
  'CLAPPERBOARD_OPEN_PLUS' = 'clapperboard-open-plus',
  'SLATE_OPEN_PLUS' = 'slate-open-plus',
  'FLIM_OPEN_PLUS' = 'flim-open-plus',
  'MOVIE_OPEN_PLUS_OUTLINE' = 'movie-open-plus-outline',
  'SLATE_OPEN_PLUS_OUTLINE' = 'slate-open-plus-outline',
  'CLAPPERBOARD_OPEN_PLUS_OUTLINE' = 'clapperboard-open-plus-outline',
  'FILM_OPEN_PLUS_OUTLINE' = 'film-open-plus-outline',
  'MOVIE_OPEN_REMOVE' = 'movie-open-remove',
  'SLATE_OPEN_REMOVE' = 'slate-open-remove',
  'CLAPPERBOARD_OPEN_REMOVE' = 'clapperboard-open-remove',
  'FILM_OPEN_REMOVE' = 'film-open-remove',
  'MOVIE_OPEN_REMOVE_OUTLINE' = 'movie-open-remove-outline',
  'SLATE_OPEN_REMOVE_OUTLINE' = 'slate-open-remove-outline',
  'CLAPPERBOARD_OPEN_REMOVE_OUTLINE' = 'clapperboard-open-remove-outline',
  'FILM_OPEN_REMOVE_OUTLINE' = 'film-open-remove-outline',
  'MOVIE_OPEN_SETTINGS' = 'movie-open-settings',
  'SLATE_OPEN_SETTINGS' = 'slate-open-settings',
  'CLAPPERBOARD_OPEN_SETTINGS' = 'clapperboard-open-settings',
  'FILM_OPEN_SETTINGS' = 'film-open-settings',
  'MOVIE_OPEN_SETTINGS_OUTLINE' = 'movie-open-settings-outline',
  'SLATE_OPEN_SETTINGS_OUTLINE' = 'slate-open-settings-outline',
  'CLAPPERBOARD_OPEN_SETTINGS_OUTLINE' = 'clapperboard-open-settings-outline',
  'FILM_OPEN_SETTINGS_OUTLINE' = 'film-open-settings-outline',
  'MOVIE_OPEN_STAR' = 'movie-open-star',
  'SLATE_OPEN_STAR' = 'slate-open-star',
  'CLAPPERBOARD_OPEN_STAR' = 'clapperboard-open-star',
  'FILM_OPEN_STAR' = 'film-open-star',
  'MOVIE_OPEN_FAVORITE' = 'movie-open-favorite',
  'MOVIE_OPEN_STAR_OUTLINE' = 'movie-open-star-outline',
  'SLATE_OPEN_STAR_OUTLINE' = 'slate-open-star-outline',
  'CLAPPERBOARD_OPEN_STAR_OUTLINE' = 'clapperboard-open-star-outline',
  'FILM_OPEN_STAR_OUTLINE' = 'film-open-star-outline',
  'MOVIE_OPEN_FAVORITE_OUTLINE' = 'movie-open-favorite-outline',
  'MOVIE_OUTLINE' = 'movie-outline',
  'SLATE_OUTLINE' = 'slate-outline',
  'CLAPPERBOARD_OUTLINE' = 'clapperboard-outline',
  'FILM_OUTLINE' = 'film-outline',
  'MOVIE_PLAY' = 'movie-play',
  'SLATE_PLAY' = 'slate-play',
  'CLAPPERBOARD_PLAY' = 'clapperboard-play',
  'FILM_PLAY' = 'film-play',
  'MOVIE_PLAY_OUTLINE' = 'movie-play-outline',
  'SLATE_PLAY_OUTLINE' = 'slate-play-outline',
  'CLAPPERBOARD_PLAY_OUTLINE' = 'clapperboard-play-outline',
  'FILM_PLAY_OUTLINE' = 'film-play-outline',
  'MOVIE_PLUS' = 'movie-plus',
  'SLATE_PLUS' = 'slate-plus',
  'CLAPPERBOARD_PLUS' = 'clapperboard-plus',
  'FILM_PLUS' = 'film-plus',
  'MOVIE_PLUS_OUTLINE' = 'movie-plus-outline',
  'SLATE_PLUS_OUTLINE' = 'slate-plus-outline',
  'CLAPPERBOARD_PLUS_OUTLINE' = 'clapperboard-plus-outline',
  'FILM_PLUS_OUTLINE' = 'film-plus-outline',
  'MOVIE_REMOVE' = 'movie-remove',
  'SLATE_REMOVE' = 'slate-remove',
  'CLAPPERBOARD_REMOVE' = 'clapperboard-remove',
  'FILM_REMOVE' = 'film-remove',
  'MOVIE_REMOVE_OUTLINE' = 'movie-remove-outline',
  'SLATE_REMOVE_OUTLINE' = 'slate-remove-outline',
  'CLAPPERBOARD_REMOVE_OUTLINE' = 'clapperboard-remove-outline',
  'FILM_REMOVE_OUTLINE' = 'film-remove-outline',
  'MOVIE_ROLL' = 'movie-roll',
  'FILM_REEL' = 'film-reel',
  'MOVIE_SEARCH' = 'movie-search',
  'MOVIE_SEARCH_OUTLINE' = 'movie-search-outline',
  'MOVIE_SETTINGS' = 'movie-settings',
  'SLATE_SETTINGS' = 'slate-settings',
  'CLAPPERBOARD_SETTINGS' = 'clapperboard-settings',
  'FILM_SETTINGS' = 'film-settings',
  'MOVIE_SETTINGS_OUTLINE' = 'movie-settings-outline',
  'SLATE_SETTINGS_OUTLINE' = 'slate-settings-outline',
  'CLAPPERBOARD_SETTINGS_OUTLINE' = 'clapperboard-settings-outline',
  'FILM_SETTINGS_OUTLINE' = 'film-settings-outline',
  'MOVIE_STAR' = 'movie-star',
  'SLATE_STAR' = 'slate-star',
  'CLAPPERBOARD_STAR' = 'clapperboard-star',
  'FILM_STAR' = 'film-star',
  'MOVIE_FAVORITE' = 'movie-favorite',
  'MOVIE_STAR_OUTLINE' = 'movie-star-outline',
  'SLATE_STAR_OUTLINE' = 'slate-star-outline',
  'CLAPPERBOARD_STAR_OUTLINE' = 'clapperboard-star-outline',
  'FILM_STAR_OUTLINE' = 'film-star-outline',
  'MOVIE_FAVORITE_OUTLINE' = 'movie-favorite-outline',
  'MOWER' = 'mower',
  'MOWER_BAG' = 'mower-bag',
  'MUFFIN' = 'muffin',
  'MULTICAST' = 'multicast',
  'MULTIPLEX' = 'multiplex',
  'MULTIPLICATION_BOX' = 'multiplication-box',
  'MUSHROOM' = 'mushroom',
  'FUNGUS' = 'fungus',
  'MUSHROOM_OFF' = 'mushroom-off',
  'MUSHROOM_OFF_OUTLINE' = 'mushroom-off-outline',
  'MUSHROOM_OUTLINE' = 'mushroom-outline',
  'FUNGUS_OUTLINE' = 'fungus-outline',
  'MUSIC' = 'music',
  'MUSIC_ACCIDENTAL_DOUBLE_FLAT' = 'music-accidental-double-flat',
  'MUSIC_ACCIDENTAL_DOUBLE_SHARP' = 'music-accidental-double-sharp',
  'MUSIC_ACCIDENTAL_FLAT' = 'music-accidental-flat',
  'MUSIC_ACCIDENTAL_NATURAL' = 'music-accidental-natural',
  'MUSIC_ACCIDENTAL_SHARP' = 'music-accidental-sharp',
  'MUSIC_BOX' = 'music-box',
  'MUSIC_BOX_MULTIPLE' = 'music-box-multiple',
  'LIBRARY_MUSIC' = 'library-music',
  'MUSIC_BOX_MULTIPLE_OUTLINE' = 'music-box-multiple-outline',
  'LIBRARY_MUSIC_OUTLINE' = 'library-music-outline',
  'MUSIC_BOX_OUTLINE' = 'music-box-outline',
  'MUSIC_CIRCLE' = 'music-circle',
  'NOTE_CIRCLE' = 'note-circle',
  'MUSIC_CIRCLE_OUTLINE' = 'music-circle-outline',
  'NOTE_CIRCLE_OUTLINE' = 'note-circle-outline',
  'MUSIC_CLEF_ALTO' = 'music-clef-alto',
  'MUSIC_C_CLEF' = 'music-c-clef',
  'MUSIC_CLEF_TENOR' = 'music-clef-tenor',
  'MUSIC_CLEF_SOPRANO' = 'music-clef-soprano',
  'MUSIC_CLEF_BARITONE' = 'music-clef-baritone',
  'MUSIC_CLEF_BASS' = 'music-clef-bass',
  'MUSIC_F_CLEF' = 'music-f-clef',
  'MUSIC_CLEF_TREBLE' = 'music-clef-treble',
  'MUSIC_G_CLEF' = 'music-g-clef',
  'MUSIC_NOTE' = 'music-note',
  'MUSIC_NOTE_BLUETOOTH' = 'music-note-bluetooth',
  'MUSIC_NOTE_BLUETOOTH_OFF' = 'music-note-bluetooth-off',
  'MUSIC_NOTE_EIGHTH' = 'music-note-eighth',
  'MUSIC_NOTE_EIGHTH_DOTTED' = 'music-note-eighth-dotted',
  'MUSIC_NOTE_HALF' = 'music-note-half',
  'MUSIC_NOTE_HALF_DOTTED' = 'music-note-half-dotted',
  'MUSIC_NOTE_OFF' = 'music-note-off',
  'MUSIC_NOTE_OFF_OUTLINE' = 'music-note-off-outline',
  'MUSIC_NOTE_OUTLINE' = 'music-note-outline',
  'MUSIC_NOTE_PLUS' = 'music-note-plus',
  'MUSIC_NOTE_ADD' = 'music-note-add',
  'MUSIC_NOTE_QUARTER' = 'music-note-quarter',
  'MUSIC_NOTE_QUARTER_DOTTED' = 'music-note-quarter-dotted',
  'MUSIC_NOTE_SIXTEENTH' = 'music-note-sixteenth',
  'MUSIC_NOTE_SIXTEENTH_DOTTED' = 'music-note-sixteenth-dotted',
  'MUSIC_NOTE_WHOLE' = 'music-note-whole',
  'MUSIC_NOTE_WHOLE_DOTTED' = 'music-note-whole-dotted',
  'MUSIC_OFF' = 'music-off',
  'MUSIC_REST_EIGHTH' = 'music-rest-eighth',
  'MUSIC_REST_HALF' = 'music-rest-half',
  'MUSIC_REST_QUARTER' = 'music-rest-quarter',
  'MUSIC_REST_SIXTEENTH' = 'music-rest-sixteenth',
  'MUSIC_REST_WHOLE' = 'music-rest-whole',
  'MUSTACHE' = 'mustache',
  'NAIL' = 'nail',
  'NAS' = 'nas',
  'NETWORK_ATTACHED_STORAGE' = 'network-attached-storage',
  'NATIVESCRIPT' = 'nativescript',
  'NATURE_PEOPLE' = 'nature-people',
  'NAVIGATION' = 'navigation',
  'ARROW_COMPASS' = 'arrow-compass',
  'NAVIGATION_OUTLINE' = 'navigation-outline',
  'NAVIGATION_VARIANT' = 'navigation-variant',
  'NAVIGATION_VARIANT_OUTLINE' = 'navigation-variant-outline',
  'NEAR_ME' = 'near-me',
  'NECKLACE' = 'necklace',
  'NEEDLE' = 'needle',
  'SYRINGE' = 'syringe',
  'INJECTION' = 'injection',
  'SHOT' = 'shot',
  'DRUG' = 'drug',
  'IMMUNIZATION' = 'immunization',
  'NEEDLE_OFF' = 'needle-off',
  'SYRINGE_OFF' = 'syringe-off',
  'INJECTION_OFF' = 'injection-off',
  'MEDICINE_OFF' = 'medicine-off',
  'SHOT_OFF' = 'shot-off',
  'DRUG_OFF' = 'drug-off',
  'IMMUNIZATION_OFF' = 'immunization-off',
  'PHARMACEUTICAL_OFF' = 'pharmaceutical-off',
  'NETFLIX' = 'netflix',
  'NETWORK' = 'network',
  'NETWORK_OFF' = 'network-off',
  'NETWORK_OFF_OUTLINE' = 'network-off-outline',
  'NETWORK_OUTLINE' = 'network-outline',
  'NETWORK_STRENGTH1' = 'network-strength1',
  'NETWORK_STRENGTH1ALERT' = 'network-strength1alert',
  'NETWORK_STRENGTH2' = 'network-strength2',
  'NETWORK_STRENGTH2ALERT' = 'network-strength2alert',
  'NETWORK_STRENGTH3' = 'network-strength3',
  'NETWORK_STRENGTH3ALERT' = 'network-strength3alert',
  'NETWORK_STRENGTH4' = 'network-strength4',
  'NETWORK_STRENGTH4ALERT' = 'network-strength4alert',
  'NETWORK_STRENGTH4COG' = 'network-strength4cog',
  'NETWORK_STRENGTH_OFF' = 'network-strength-off',
  'NETWORK_STRENGTH_OFF_OUTLINE' = 'network-strength-off-outline',
  'NETWORK_STRENGTH_OUTLINE' = 'network-strength-outline',
  'NETWORK_STRENGTH_0' = 'network-strength-0',
  'NEW_BOX' = 'new-box',
  'FIBER_NEW' = 'fiber-new',
  'NEWSPAPER' = 'newspaper',
  'NEWSPAPER_CHECK' = 'newspaper-check',
  'NEWSPAPER_MINUS' = 'newspaper-minus',
  'NEWSPAPER_PLUS' = 'newspaper-plus',
  'NEWSPAPER_REMOVE' = 'newspaper-remove',
  'NEWSPAPER_VARIANT' = 'newspaper-variant',
  'NEWSPAPER_VARIANT_MULTIPLE' = 'newspaper-variant-multiple',
  'NEWSPAPER_VARIANT_MULTIPLE_OUTLINE' = 'newspaper-variant-multiple-outline',
  'NEWSPAPER_VARIANT_OUTLINE' = 'newspaper-variant-outline',
  'NFC' = 'nfc',
  'NEAR_FIELD_COMMUNICATION' = 'near-field-communication',
  'NFC_SEARCH_VARIANT' = 'nfc-search-variant',
  'NFC_TAP' = 'nfc-tap',
  'NEAR_FIELD_COMMUNICATION_TAP' = 'near-field-communication-tap',
  'NFC_VARIANT' = 'nfc-variant',
  'NEAR_FIELD_COMMUNICATION_VARIANT' = 'near-field-communication-variant',
  'NFC_VARIANT_OFF' = 'nfc-variant-off',
  'NEAR_FIELD_COMMUNICATION_OFF' = 'near-field-communication-off',
  'NINJA' = 'ninja',
  'NINTENDO_GAME_BOY' = 'nintendo-game-boy',
  'NINTENDO_SWITCH' = 'nintendo-switch',
  'NINTENDO_SWITCH_ONLINE' = 'nintendo-switch-online',
  'NINTENDO_WII' = 'nintendo-wii',
  'NINTENDO_WIIU' = 'nintendo-wiiu',
  'NIX' = 'nix',
  'NODEJS' = 'nodejs',
  'NOODLES' = 'noodles',
  'FOOD_RAMEN' = 'food-ramen',
  'ASIAN_NOODLES' = 'asian-noodles',
  'NOT_EQUAL' = 'not-equal',
  'NOT_EQUAL_VARIANT' = 'not-equal-variant',
  'NOTE' = 'note',
  'STICKY_NOTE' = 'sticky-note',
  'POST_IT_NOTE' = 'post-it-note',
  'NOTE_ALERT' = 'note-alert',
  'PAPER_ALERT' = 'paper-alert',
  'STICKY_NOTE_ALERT' = 'sticky-note-alert',
  'POST_IT_NOTE_ALERT' = 'post-it-note-alert',
  'NOTE_ALERT_OUTLINE' = 'note-alert-outline',
  'PAPER_ALERT_OUTLINE' = 'paper-alert-outline',
  'POST_IT_NOTE_ALERT_OUTLINE' = 'post-it-note-alert-outline',
  'STICKY_NOTE_ALERT_OUTLINE' = 'sticky-note-alert-outline',
  'NOTE_CHECK' = 'note-check',
  'PAPER_CHECK' = 'paper-check',
  'STICKY_NOTE_CHECK' = 'sticky-note-check',
  'POST_IT_NOTE_CHECK' = 'post-it-note-check',
  'NOTE_CHECK_OUTLINE' = 'note-check-outline',
  'PAPER_CHECK_OUTLINE' = 'paper-check-outline',
  'STICKY_NOTE_CHECK_OUTLINE' = 'sticky-note-check-outline',
  'POST_IT_NOTE_CHECK_OUTLINE' = 'post-it-note-check-outline',
  'NOTE_EDIT' = 'note-edit',
  'PAPER_EDIT' = 'paper-edit',
  'STICKY_NOTE_EDIT' = 'sticky-note-edit',
  'POST_IT_NOTE_EDIT' = 'post-it-note-edit',
  'NOTE_EDIT_OUTLINE' = 'note-edit-outline',
  'PAPER_EDIT_OUTLINE' = 'paper-edit-outline',
  'STICKY_NOTE_EDIT_OUTLINE' = 'sticky-note-edit-outline',
  'POST_IT_NOTE_EDIT_OUTLINE' = 'post-it-note-edit-outline',
  'NOTE_MINUS' = 'note-minus',
  'PAPER_MINUS' = 'paper-minus',
  'STICKY_NOTE_MINUS' = 'sticky-note-minus',
  'POST_IT_NOTE_MINUS' = 'post-it-note-minus',
  'NOTE_MINUS_OUTLINE' = 'note-minus-outline',
  'PAPER_MINUS_OUTLINE' = 'paper-minus-outline',
  'STICKY_NOTE_MINUS_OUTLINE' = 'sticky-note-minus-outline',
  'POST_IT_NOTE_MINUS_OUTLINE' = 'post-it-note-minus-outline',
  'NOTE_MULTIPLE' = 'note-multiple',
  'NOTES' = 'notes',
  'PAPERS' = 'papers',
  'STICKY_NOTES' = 'sticky-notes',
  'POST_IT_NOTES' = 'post-it-notes',
  'NOTE_MULTIPLE_OUTLINE' = 'note-multiple-outline',
  'NOTES_OUTLINE' = 'notes-outline',
  'PAPERS_OUTLINE' = 'papers-outline',
  'STICKY_NOTES_OUTLINE' = 'sticky-notes-outline',
  'POST_IT_NOTES_OUTLINE' = 'post-it-notes-outline',
  'NOTE_OFF' = 'note-off',
  'PAPER_OFF' = 'paper-off',
  'STICKY_NOTE_OFF' = 'sticky-note-off',
  'POST_IT_NOTE_OFF' = 'post-it-note-off',
  'NOTE_OFF_OUTLINE' = 'note-off-outline',
  'PAPER_OFF_OUTLINE' = 'paper-off-outline',
  'STICKY_NOTE_OFF_OUTLINE' = 'sticky-note-off-outline',
  'POST_IT_NOTE_OFF_OUTLINE' = 'post-it-note-off-outline',
  'NOTE_OUTLINE' = 'note-outline',
  'STICKY_NOTE_OUTLINE' = 'sticky-note-outline',
  'POST_IT_NOTE_OUTLINE' = 'post-it-note-outline',
  'NOTE_PLUS' = 'note-plus',
  'PAPER_PLUS' = 'paper-plus',
  'PAPER_ADD' = 'paper-add',
  'STICKY_NOTE_PLUS' = 'sticky-note-plus',
  'STICKY_NOTE_ADD' = 'sticky-note-add',
  'POST_IT_NOTE_PLUS' = 'post-it-note-plus',
  'POST_IT_NOTE_ADD' = 'post-it-note-add',
  'NOTE_PLUS_OUTLINE' = 'note-plus-outline',
  'NOTE_ADD_OUTLINE' = 'note-add-outline',
  'PAPER_PLUS_OUTLINE' = 'paper-plus-outline',
  'PAPER_ADD_OUTLINE' = 'paper-add-outline',
  'STICKY_NOTE_PLUS_OUTLINE' = 'sticky-note-plus-outline',
  'STICKY_NOTE_ADD_OUTLINE' = 'sticky-note-add-outline',
  'POST_IT_NOTE_PLUS_OUTLINE' = 'post-it-note-plus-outline',
  'POST_IT_NOTE_ADD_OUTLINE' = 'post-it-note-add-outline',
  'NOTE_REMOVE' = 'note-remove',
  'PAPER_REMOVE' = 'paper-remove',
  'STICKY_NOTE_REMOVE' = 'sticky-note-remove',
  'POST_IT_NOTE_REMOVE' = 'post-it-note-remove',
  'NOTE_REMOVE_OUTLINE' = 'note-remove-outline',
  'NOTE_SEARCH' = 'note-search',
  'PAPER_SEARCH' = 'paper-search',
  'STICKY_NOTE_SEARCH' = 'sticky-note-search',
  'POST_IT_NOTE_SEARCH' = 'post-it-note-search',
  'NOTE_SEARCH_OUTLINE' = 'note-search-outline',
  'PAPER_SEARCH_OUTLINE' = 'paper-search-outline',
  'STICKY_NOTE_SEARCH_OUTLINE' = 'sticky-note-search-outline',
  'POST_IT_NOTE_SEARCH_OUTLINE' = 'post-it-note-search-outline',
  'NOTE_TEXT' = 'note-text',
  'PAPER_TEXT' = 'paper-text',
  'STICKY_NOTE_TEXT' = 'sticky-note-text',
  'POST_IT_NOTE_TEXT' = 'post-it-note-text',
  'NOTE_TEXT_OUTLINE' = 'note-text-outline',
  'PAPER_TEXT_OUTLINE' = 'paper-text-outline',
  'STICKY_NOTE_TEXT_OUTLINE' = 'sticky-note-text-outline',
  'POST_IT_NOTE_TEXT_OUTLINE' = 'post-it-note-text-outline',
  'NOTEBOOK' = 'notebook',
  'JOURNAL' = 'journal',
  'PLANNER' = 'planner',
  'DIARY' = 'diary',
  'NOTEBOOK_CHECK' = 'notebook-check',
  'NOTEBOOK_CHECK_OUTLINE' = 'notebook-check-outline',
  'NOTEBOOK_EDIT' = 'notebook-edit',
  'NOTEBOOK_EDIT_OUTLINE' = 'notebook-edit-outline',
  'NOTEBOOK_HEART' = 'notebook-heart',
  'NOTEBOOK_FAVORITE' = 'notebook-favorite',
  'NOTEBOOK_LOVE' = 'notebook-love',
  'NOTEBOOK_HEART_OUTLINE' = 'notebook-heart-outline',
  'NOTEBOOK_FAVORITE_OUTLINE' = 'notebook-favorite-outline',
  'NOTEBOOK_LOVE_OUTLINE' = 'notebook-love-outline',
  'NOTEBOOK_MINUS' = 'notebook-minus',
  'NOTEBOOK_MINUS_OUTLINE' = 'notebook-minus-outline',
  'NOTEBOOK_MULTIPLE' = 'notebook-multiple',
  'JOURNAL_MULTIPLE' = 'journal-multiple',
  'PLANNER_MULTIPLE' = 'planner-multiple',
  'NOTEBOOK_OUTLINE' = 'notebook-outline',
  'JOURNAL_OUTLINE' = 'journal-outline',
  'PLANNER_OUTLINE' = 'planner-outline',
  'NOTEBOOK_PLUS' = 'notebook-plus',
  'NOTEBOOK_PLUS_OUTLINE' = 'notebook-plus-outline',
  'NOTEBOOK_REMOVE' = 'notebook-remove',
  'NOTEBOOK_REMOVE_OUTLINE' = 'notebook-remove-outline',
  'NOTIFICATION_CLEAR_ALL' = 'notification-clear-all',
  'NPM' = 'npm',
  'NUKE' = 'nuke',
  'NUCLEAR' = 'nuclear',
  'ATOMIC_BOMB' = 'atomic-bomb',
  'NUMERIC' = 'numeric',
  '1_2_3' = '1-2-3',
  'ONE_TWO_THREE' = 'one-two-three',
  '123_' = '123',
  'NUMERIC0' = 'numeric0',
  'NUMERIC0BOX' = 'numeric0box',
  'NUMERIC0BOX_MULTIPLE' = 'numeric0box-multiple',
  'NUMERIC0BOX_MULTIPLE_OUTLINE' = 'numeric0box-multiple-outline',
  'NUMERIC0BOX_OUTLINE' = 'numeric0box-outline',
  'NUMERIC0CIRCLE' = 'numeric0circle',
  'NUMERIC0CIRCLE_OUTLINE' = 'numeric0circle-outline',
  'NUMERIC1' = 'numeric1',
  'NUMERIC10' = 'numeric10',
  'NUMERIC10BOX' = 'numeric10box',
  'NUMERIC10BOX_MULTIPLE' = 'numeric10box-multiple',
  'NUMERIC10BOX_MULTIPLE_OUTLINE' = 'numeric10box-multiple-outline',
  'NUMERIC10BOX_OUTLINE' = 'numeric10box-outline',
  'NUMERIC10CIRCLE' = 'numeric10circle',
  'NUMERIC10CIRCLE_OUTLINE' = 'numeric10circle-outline',
  'NUMERIC1BOX' = 'numeric1box',
  'NUMERIC1BOX_MULTIPLE' = 'numeric1box-multiple',
  'NUMERIC1BOX_MULTIPLE_OUTLINE' = 'numeric1box-multiple-outline',
  'NUMERIC1BOX_OUTLINE' = 'numeric1box-outline',
  'NUMERIC1CIRCLE' = 'numeric1circle',
  'NUMERIC1CIRCLE_OUTLINE' = 'numeric1circle-outline',
  'NUMERIC2' = 'numeric2',
  'NUMERIC2BOX' = 'numeric2box',
  'NUMERIC2BOX_MULTIPLE' = 'numeric2box-multiple',
  'NUMERIC2BOX_MULTIPLE_OUTLINE' = 'numeric2box-multiple-outline',
  'NUMERIC2BOX_OUTLINE' = 'numeric2box-outline',
  'NUMERIC2CIRCLE' = 'numeric2circle',
  'NUMERIC2CIRCLE_OUTLINE' = 'numeric2circle-outline',
  'NUMERIC3' = 'numeric3',
  'NUMERIC3BOX' = 'numeric3box',
  'NUMERIC3BOX_MULTIPLE' = 'numeric3box-multiple',
  'NUMERIC3BOX_MULTIPLE_OUTLINE' = 'numeric3box-multiple-outline',
  'NUMERIC3BOX_OUTLINE' = 'numeric3box-outline',
  'NUMERIC3CIRCLE' = 'numeric3circle',
  'NUMERIC3CIRCLE_OUTLINE' = 'numeric3circle-outline',
  'NUMERIC4' = 'numeric4',
  'NUMERIC4BOX' = 'numeric4box',
  'NUMERIC4BOX_MULTIPLE' = 'numeric4box-multiple',
  'NUMERIC4BOX_MULTIPLE_OUTLINE' = 'numeric4box-multiple-outline',
  'NUMERIC4BOX_OUTLINE' = 'numeric4box-outline',
  'NUMERIC4CIRCLE' = 'numeric4circle',
  'NUMERIC4CIRCLE_OUTLINE' = 'numeric4circle-outline',
  'NUMERIC5' = 'numeric5',
  'NUMERIC5BOX' = 'numeric5box',
  'NUMERIC5BOX_MULTIPLE' = 'numeric5box-multiple',
  'NUMERIC5BOX_MULTIPLE_OUTLINE' = 'numeric5box-multiple-outline',
  'NUMERIC5BOX_OUTLINE' = 'numeric5box-outline',
  'NUMERIC5CIRCLE' = 'numeric5circle',
  'NUMERIC5CIRCLE_OUTLINE' = 'numeric5circle-outline',
  'NUMERIC6' = 'numeric6',
  'NUMERIC6BOX' = 'numeric6box',
  'NUMERIC6BOX_MULTIPLE' = 'numeric6box-multiple',
  'NUMERIC6BOX_MULTIPLE_OUTLINE' = 'numeric6box-multiple-outline',
  'NUMERIC6BOX_OUTLINE' = 'numeric6box-outline',
  'NUMERIC6CIRCLE' = 'numeric6circle',
  'NUMERIC6CIRCLE_OUTLINE' = 'numeric6circle-outline',
  'NUMERIC7' = 'numeric7',
  'NUMERIC7BOX' = 'numeric7box',
  'NUMERIC7BOX_MULTIPLE' = 'numeric7box-multiple',
  'NUMERIC7BOX_MULTIPLE_OUTLINE' = 'numeric7box-multiple-outline',
  'NUMERIC7BOX_OUTLINE' = 'numeric7box-outline',
  'NUMERIC7CIRCLE' = 'numeric7circle',
  'NUMERIC7CIRCLE_OUTLINE' = 'numeric7circle-outline',
  'NUMERIC8' = 'numeric8',
  'NUMERIC8BOX' = 'numeric8box',
  'NUMERIC8BOX_MULTIPLE' = 'numeric8box-multiple',
  'NUMERIC8BOX_MULTIPLE_OUTLINE' = 'numeric8box-multiple-outline',
  'NUMERIC8BOX_OUTLINE' = 'numeric8box-outline',
  'NUMERIC8CIRCLE' = 'numeric8circle',
  'NUMERIC8CIRCLE_OUTLINE' = 'numeric8circle-outline',
  'NUMERIC9' = 'numeric9',
  'NUMERIC9BOX' = 'numeric9box',
  'NUMERIC9BOX_MULTIPLE' = 'numeric9box-multiple',
  'NUMERIC9BOX_MULTIPLE_OUTLINE' = 'numeric9box-multiple-outline',
  'NUMERIC9BOX_OUTLINE' = 'numeric9box-outline',
  'NUMERIC9CIRCLE' = 'numeric9circle',
  'NUMERIC9CIRCLE_OUTLINE' = 'numeric9circle-outline',
  'NUMERIC9PLUS' = 'numeric9plus',
  'NUMERIC9PLUS_BOX' = 'numeric9plus-box',
  'NUMERIC9PLUS_BOX_MULTIPLE' = 'numeric9plus-box-multiple',
  'NUMERIC9PLUS_BOX_MULTIPLE_OUTLINE' = 'numeric9plus-box-multiple-outline',
  'NUMERIC9PLUS_BOX_OUTLINE' = 'numeric9plus-box-outline',
  'NUMERIC9PLUS_CIRCLE' = 'numeric9plus-circle',
  'NUMERIC9PLUS_CIRCLE_OUTLINE' = 'numeric9plus-circle-outline',
  'NUMERIC_NEGATIVE1' = 'numeric-negative1',
  'NUMERIC_OFF' = 'numeric-off',
  'NUMBERS_OFF' = 'numbers-off',
  '123_OFF' = '123-off',
  'ONE_TWO_THREE_OFF' = 'one-two-three-off',
  'NUMERIC_POSITIVE1' = 'numeric-positive1',
  'NUT' = 'nut',
  'NUTRITION' = 'nutrition',
  'NUXT' = 'nuxt',
  'OAR' = 'oar',
  'OCARINA' = 'ocarina',
  'OCI' = 'oci',
  'OPEN_CONTAINER_INITIATIVE' = 'open-container-initiative',
  'OCR' = 'ocr',
  'OPTICAL_CHARACTER_RECOGNITION' = 'optical-character-recognition',
  'OCTAGON' = 'octagon',
  'STOP' = 'stop',
  'OCTAGON_OUTLINE' = 'octagon-outline',
  'STOP_OUTLINE' = 'stop-outline',
  'OCTAGRAM' = 'octagram',
  'OCTAGRAM_OUTLINE' = 'octagram-outline',
  'OCTAHEDRON' = 'octahedron',
  'OCTAHEDRON_OFF' = 'octahedron-off',
  'ODNOKLASSNIKI' = 'odnoklassniki',
  'OK_RU' = 'ok-ru',
  'OFFER' = 'offer',
  'OFFICE_BUILDING' = 'office-building',
  'OFFICE_BUILDING_COG' = 'office-building-cog',
  'OFFICE_BUILDING_SETTINGS' = 'office-building-settings',
  'OFFICE_BUILDING_COG_OUTLINE' = 'office-building-cog-outline',
  'OFFICE_BUILDING_SETTINGS_OUTLINE' = 'office-building-settings-outline',
  'OFFICE_BUILDING_MARKER' = 'office-building-marker',
  'OFFICE_BUILDING_LOCATION' = 'office-building-location',
  'OFFICE_BUILDING_MARKER_OUTLINE' = 'office-building-marker-outline',
  'OFFICE_BUILDING_LOCATION_OUTLINE' = 'office-building-location-outline',
  'OFFICE_BUILDING_OUTLINE' = 'office-building-outline',
  'OIL_LAMP' = 'oil-lamp',
  'WISH' = 'wish',
  'GENIE_LAMP' = 'genie-lamp',
  'OIL_LEVEL' = 'oil-level',
  'OIL_TEMPERATURE' = 'oil-temperature',
  'OM' = 'om',
  'RELIGION_HINDU' = 'religion-hindu',
  'HINDUISM' = 'hinduism',
  'OMEGA' = 'omega',
  'OHM' = 'ohm',
  'ELECTRICAL_RESISTANCE' = 'electrical-resistance',
  'ONE_UP' = 'one-up',
  '1UP' = '1up',
  'EXTRA_LIFE' = 'extra-life',
  'ONEPASSWORD' = 'onepassword',
  '1PASSWORD' = '1password',
  'OPACITY' = 'opacity',
  'OPEN_IN_APP' = 'open-in-app',
  'OPEN_IN_BROWSER' = 'open-in-browser',
  'OPEN_IN_NEW' = 'open-in-new',
  'EXTERNAL_LINK' = 'external-link',
  'OPEN_SOURCE_INITIATIVE' = 'open-source-initiative',
  'OPENID' = 'openid',
  'OPERA' = 'opera',
  'ORBIT_VARIANT' = 'orbit-variant',
  'ORDER_ALPHABETICAL_ASCENDING' = 'order-alphabetical-ascending',
  'ORDER_ALPHABETICAL_DESCENDING' = 'order-alphabetical-descending',
  'ORDER_BOOL_ASCENDING' = 'order-bool-ascending',
  'ORDER_BOOL_ASCENDING_VARIANT' = 'order-bool-ascending-variant',
  'ORDER_CHECKBOX_ASCENDING' = 'order-checkbox-ascending',
  'ORDER_BOOL_DESCENDING' = 'order-bool-descending',
  'ORDER_CHECKBOX_DESCENDING' = 'order-checkbox-descending',
  'ORDER_BOOL_DESCENDING_VARIANT' = 'order-bool-descending-variant',
  'ORDER_NUMERIC_ASCENDING' = 'order-numeric-ascending',
  'ORDER_NUMERIC_DESCENDING' = 'order-numeric-descending',
  'ORIGIN' = 'origin',
  'ORNAMENT' = 'ornament',
  'ORNAMENT_VARIANT' = 'ornament-variant',
  'OUTDOOR_LAMP' = 'outdoor-lamp',
  'OUTDOOR_LIGHT' = 'outdoor-light',
  'OVERSCAN' = 'overscan',
  'OWL' = 'owl',
  'PAC_MAN' = 'pac-man',
  'PACKAGE_DOWN' = 'package-down',
  'BOX_DOWN' = 'box-down',
  'PACKAGE_UP' = 'package-up',
  'UNARCHIVE' = 'unarchive',
  'BOX_UP' = 'box-up',
  'PACKAGE_VARIANT' = 'package-variant',
  'BOX_VARIANT' = 'box-variant',
  'PACKAGE_VARIANT_CLOSED' = 'package-variant-closed',
  'BOX_VARIANT_CLOSED' = 'box-variant-closed',
  'PACKAGE_VARIANT_CLOSED_MINUS' = 'package-variant-closed-minus',
  'PACKAGE_VARIANT_CLOSED_SUBTRACT' = 'package-variant-closed-subtract',
  'BOX_VARIANT_CLOSED_MINUS' = 'box-variant-closed-minus',
  'BOX_VARIANT_CLOSED_SUBTRACT' = 'box-variant-closed-subtract',
  'PACKAGE_VARIANT_CLOSED_PLUS' = 'package-variant-closed-plus',
  'BOX_VARIANT_CLOSED_PLUS' = 'box-variant-closed-plus',
  'PACKAGE_VARIANT_CLOSED_ADD' = 'package-variant-closed-add',
  'BOX_VARIANT_CLOSED_ADD' = 'box-variant-closed-add',
  'PACKAGE_VARIANT_CLOSED_REMOVE' = 'package-variant-closed-remove',
  'BOX_VARIANT_CLOSED_REMOVE' = 'box-variant-closed-remove',
  'PACKAGE_VARIANT_MINUS' = 'package-variant-minus',
  'BOX_VARIANT_MINUS' = 'box-variant-minus',
  'PACKAGE_VARIANT_SUBTRACT' = 'package-variant-subtract',
  'BOX_VARIANT_SUBTRACT' = 'box-variant-subtract',
  'PACKAGE_VARIANT_PLUS' = 'package-variant-plus',
  'BOX_VARIANT_PLUS' = 'box-variant-plus',
  'PACKAGE_VARIANT_ADD' = 'package-variant-add',
  'BOX_VARIANT_ADD' = 'box-variant-add',
  'PACKAGE_VARIANT_REMOVE' = 'package-variant-remove',
  'BOX_VARIANT_REMOVE' = 'box-variant-remove',
  'PAGE_FIRST' = 'page-first',
  'FIRST_PAGE' = 'first-page',
  'CHEVRON_LEFT_FIRST' = 'chevron-left-first',
  'PAGE_LAST' = 'page-last',
  'LAST_PAGE' = 'last-page',
  'CHEVRON_RIGHT_LAST' = 'chevron-right-last',
  'PAGE_LAYOUT_BODY' = 'page-layout-body',
  'PAGE_LAYOUT_FOOTER' = 'page-layout-footer',
  'PAGE_LAYOUT_HEADER' = 'page-layout-header',
  'PAGE_LAYOUT_HEADER_FOOTER' = 'page-layout-header-footer',
  'PAGE_LAYOUT_MARGINALS' = 'page-layout-marginals',
  'PAGE_LAYOUT_SIDEBAR_LEFT' = 'page-layout-sidebar-left',
  'PAGE_LAYOUT_SIDEBAR_RIGHT' = 'page-layout-sidebar-right',
  'PAGE_NEXT' = 'page-next',
  'READ_MORE' = 'read-more',
  'PAGE_NEXT_OUTLINE' = 'page-next-outline',
  'READ_MORE_OUTLINE' = 'read-more-outline',
  'PAGE_PREVIOUS' = 'page-previous',
  'PAGE_PREVIOUS_OUTLINE' = 'page-previous-outline',
  'PAIL' = 'pail',
  'PAIL_MINUS' = 'pail-minus',
  'BUCKET_MINUS' = 'bucket-minus',
  'PAIL_MINUS_OUTLINE' = 'pail-minus-outline',
  'BUCKET_MINUS_OUTLINE' = 'bucket-minus-outline',
  'PAIL_OFF' = 'pail-off',
  'BUCKET_OFF' = 'bucket-off',
  'PAIL_OFF_OUTLINE' = 'pail-off-outline',
  'BUCKET_OFF_OUTLINE' = 'bucket-off-outline',
  'PAIL_OUTLINE' = 'pail-outline',
  'PAIL_PLUS' = 'pail-plus',
  'BUCKET_PLUS' = 'bucket-plus',
  'PAIL_PLUS_OUTLINE' = 'pail-plus-outline',
  'BUCKET_PLUS_OUTLINE' = 'bucket-plus-outline',
  'PAIL_REMOVE' = 'pail-remove',
  'BUCKET_REMOVE' = 'bucket-remove',
  'PAIL_REMOVE_OUTLINE' = 'pail-remove-outline',
  'BUCKET_REMOVE_OUTLINE' = 'bucket-remove-outline',
  'PALETTE' = 'palette',
  'COLOR_LENS' = 'color-lens',
  'COLOUR_LENS' = 'colour-lens',
  'ART' = 'art',
  'COLOR' = 'color',
  'PALETTE_ADVANCED' = 'palette-advanced',
  'PALETTE_OUTLINE' = 'palette-outline',
  'PAINT_OUTLINE' = 'paint-outline',
  'PALETTE_SWATCH' = 'palette-swatch',
  'STYLE' = 'style',
  'MATERIAL' = 'material',
  'PALETTE_SWATCH_OUTLINE' = 'palette-swatch-outline',
  'STYLE_OUTLINE' = 'style-outline',
  'PALETTE_SWATCH_VARIANT' = 'palette-swatch-variant',
  'PALM_TREE' = 'palm-tree',
  'PAN' = 'pan',
  'PAN_BOTTOM_LEFT' = 'pan-bottom-left',
  'PAN_DOWN_LEFT' = 'pan-down-left',
  'PAN_BOTTOM_RIGHT' = 'pan-bottom-right',
  'PAN_DOWN_RIGHT' = 'pan-down-right',
  'PAN_DOWN' = 'pan-down',
  'PAN_HORIZONTAL' = 'pan-horizontal',
  'PAN_LEFT' = 'pan-left',
  'PAN_RIGHT' = 'pan-right',
  'PAN_TOP_LEFT' = 'pan-top-left',
  'PAN_UP_LEFT' = 'pan-up-left',
  'PAN_TOP_RIGHT' = 'pan-top-right',
  'PAN_UP_RIGHT' = 'pan-up-right',
  'PAN_UP' = 'pan-up',
  'PAN_VERTICAL' = 'pan-vertical',
  'PANDA' = 'panda',
  'EMOJI_PANDA' = 'emoji-panda',
  'EMOTICON_PANDA' = 'emoticon-panda',
  'PANDORA' = 'pandora',
  'PANORAMA' = 'panorama',
  'PANORAMA_FISHEYE' = 'panorama-fisheye',
  'PANORAMA_FISH_EYE' = 'panorama-fish-eye',
  'PANORAMA_HORIZONTAL' = 'panorama-horizontal',
  'PANORAMA_HORIZONTAL_OUTLINE' = 'panorama-horizontal-outline',
  'PANORAMA_OUTLINE' = 'panorama-outline',
  'PANORAMA_SPHERE' = 'panorama-sphere',
  'PANORAMA_360' = 'panorama-360',
  'PANORAMA_SPHERE_OUTLINE' = 'panorama-sphere-outline',
  'PANORAMA_360_OUTLINE' = 'panorama-360-outline',
  'PANORAMA_VARIANT' = 'panorama-variant',
  'PANORAMA_VR' = 'panorama-vr',
  'IMAGE_VR' = 'image-vr',
  'PICTURE_VR' = 'picture-vr',
  'PICTURE_360' = 'picture-360',
  'IMAGE_360' = 'image-360',
  'PANORAMA_VARIANT_OUTLINE' = 'panorama-variant-outline',
  'PANORAMA_VR_OUTLINE' = 'panorama-vr-outline',
  'IMAGE_VR_OUTLINE' = 'image-vr-outline',
  'PICTURE_VR_OUTLINE' = 'picture-vr-outline',
  'PICTURE_360_OUTLINE' = 'picture-360-outline',
  'IMAGE_360_OUTLINE' = 'image-360-outline',
  'PANORAMA_VERTICAL' = 'panorama-vertical',
  'PANORAMA_VERTICAL_OUTLINE' = 'panorama-vertical-outline',
  'PANORAMA_WIDE_ANGLE' = 'panorama-wide-angle',
  'PANORAMA_WIDE_ANGLE_OUTLINE' = 'panorama-wide-angle-outline',
  'PAPER_CUT_VERTICAL' = 'paper-cut-vertical',
  'PAPER_ROLL' = 'paper-roll',
  'LAVATORY_ROLL' = 'lavatory-roll',
  'BATHROOM_TISSUE' = 'bathroom-tissue',
  'TOILET_PAPER' = 'toilet-paper',
  'KITCHEN_ROLL' = 'kitchen-roll',
  'PAPER_TOWELS' = 'paper-towels',
  'RECEIPT_ROLL' = 'receipt-roll',
  'PAPER_ROLL_OUTLINE' = 'paper-roll-outline',
  'LAVATORY_ROLL_OUTLINE' = 'lavatory-roll-outline',
  'BATHROOM_TISSUE_OUTLINE' = 'bathroom-tissue-outline',
  'KITCHEN_ROLL_OUTLINE' = 'kitchen-roll-outline',
  'PAPER_TOWELS_OUTLINE' = 'paper-towels-outline',
  'TOILET_PAPER_OUTLINE' = 'toilet-paper-outline',
  'RECEIPT_ROLL_OUTLINE' = 'receipt-roll-outline',
  'PAPERCLIP' = 'paperclip',
  'ATTACHMENT_VERTICAL' = 'attachment-vertical',
  'ATTACH_FILE' = 'attach-file',
  'PARACHUTE' = 'parachute',
  'PARACHUTE_OUTLINE' = 'parachute-outline',
  'PARAGLIDING' = 'paragliding',
  'PARASAIL' = 'parasail',
  'PARAGLIDE' = 'paraglide',
  'PARKING' = 'parking',
  'CAR_PARK' = 'car-park',
  'LOCAL_PARKING' = 'local-parking',
  'PARTY_POPPER' = 'party-popper',
  'CELEBRATION' = 'celebration',
  'PASSPORT' = 'passport',
  'PASSPORT_BIOMETRIC' = 'passport-biometric',
  'PASSPORT_ELECTRONIC' = 'passport-electronic',
  'PASTA' = 'pasta',
  'FOOD_ITALIAN' = 'food-italian',
  'SPAGHETTI' = 'spaghetti',
  'PATIO_HEATER' = 'patio-heater',
  'PATREON' = 'patreon',
  'PAUSE' = 'pause',
  'PAUSE_CIRCLE' = 'pause-circle',
  'PAUSE_CIRCLE_FILLED' = 'pause-circle-filled',
  'PAUSE_CIRCLE_OUTLINE' = 'pause-circle-outline',
  'PAUSE_OCTAGON' = 'pause-octagon',
  'STOP_PAUSE' = 'stop-pause',
  'PAUSE_OCTAGON_OUTLINE' = 'pause-octagon-outline',
  'STOP_PAUSE_OUTLINE' = 'stop-pause-outline',
  'PAW' = 'paw',
  'PETS' = 'pets',
  'PAW_OFF' = 'paw-off',
  'PAW_OFF_OUTLINE' = 'paw-off-outline',
  'PAW_OUTLINE' = 'paw-outline',
  'PEACE' = 'peace',
  'PEANUT' = 'peanut',
  'ALLERGEN' = 'allergen',
  'FOOD_ALLERGY' = 'food-allergy',
  'PEANUT_OFF' = 'peanut-off',
  'ALLERGEN_OFF' = 'allergen-off',
  'FOOD_ALLERGY_OFF' = 'food-allergy-off',
  'PEANUT_OFF_OUTLINE' = 'peanut-off-outline',
  'ALLERGEN_OFF_OUTLINE' = 'allergen-off-outline',
  'FOOD_ALLERGY_OFF_OUTLINE' = 'food-allergy-off-outline',
  'PEANUT_OUTLINE' = 'peanut-outline',
  'ALLERGEN_OUTLINE' = 'allergen-outline',
  'FOOD_ALLERGY_OUTLINE' = 'food-allergy-outline',
  'PEN' = 'pen',
  'PEN_LOCK' = 'pen-lock',
  'PEN_MINUS' = 'pen-minus',
  'PEN_OFF' = 'pen-off',
  'PEN_PLUS' = 'pen-plus',
  'PEN_ADD' = 'pen-add',
  'PEN_REMOVE' = 'pen-remove',
  'PENCIL' = 'pencil',
  'EDIT' = 'edit',
  'CREATE' = 'create',
  'MODE_EDIT' = 'mode-edit',
  'PENCIL_BOX' = 'pencil-box',
  'EDIT_BOX' = 'edit-box',
  'PENCIL_BOX_MULTIPLE' = 'pencil-box-multiple',
  'LIBRARY_EDIT' = 'library-edit',
  'PENCIL_BOX_MULTIPLE_OUTLINE' = 'pencil-box-multiple-outline',
  'LIBRARY_EDIT_OUTLINE' = 'library-edit-outline',
  'PENCIL_BOX_OUTLINE' = 'pencil-box-outline',
  'EDIT_BOX_OUTLINE' = 'edit-box-outline',
  'PENCIL_CIRCLE' = 'pencil-circle',
  'EDIT_CIRCLE' = 'edit-circle',
  'PENCIL_CIRCLE_OUTLINE' = 'pencil-circle-outline',
  'EDIT_CIRCLE_OUTLINE' = 'edit-circle-outline',
  'PENCIL_LOCK' = 'pencil-lock',
  'PENCIL_LOCK_OUTLINE' = 'pencil-lock-outline',
  'PENCIL_MINUS' = 'pencil-minus',
  'PENCIL_MINUS_OUTLINE' = 'pencil-minus-outline',
  'PENCIL_OFF' = 'pencil-off',
  'EDIT_OFF' = 'edit-off',
  'PENCIL_OFF_OUTLINE' = 'pencil-off-outline',
  'EDIT_OFF_OUTLINE' = 'edit-off-outline',
  'PENCIL_OUTLINE' = 'pencil-outline',
  'EDIT_OUTLINE' = 'edit-outline',
  'CREATE_OUTLINE' = 'create-outline',
  'MODE_EDIT_OUTLINE' = 'mode-edit-outline',
  'PENCIL_PLUS' = 'pencil-plus',
  'PENCIL_ADD' = 'pencil-add',
  'PENCIL_PLUS_OUTLINE' = 'pencil-plus-outline',
  'PENCIL_ADD_OUTLINE' = 'pencil-add-outline',
  'PENCIL_REMOVE' = 'pencil-remove',
  'PENCIL_REMOVE_OUTLINE' = 'pencil-remove-outline',
  'PENCIL_RULER' = 'pencil-ruler',
  'DESIGN' = 'design',
  'PENGUIN' = 'penguin',
  'EMOJI_PENGUIN' = 'emoji-penguin',
  'EMOTICON_PENGUIN' = 'emoticon-penguin',
  'PENTAGON' = 'pentagon',
  'PENTAGON_OUTLINE' = 'pentagon-outline',
  'PENTAGRAM' = 'pentagram',
  'PERCENT' = 'percent',
  'PERCENT_BOX' = 'percent-box',
  'DISCOUNT_BOX' = 'discount-box',
  'SALE_BOX' = 'sale-box',
  'PERCENT_BOX_OUTLINE' = 'percent-box-outline',
  'DISCOUNT_BOX_OUTLINE' = 'discount-box-outline',
  'SALE_BOX_OUTLINE' = 'sale-box-outline',
  'PERCENT_CIRCLE' = 'percent-circle',
  'DISCOUNT_CIRCLE' = 'discount-circle',
  'SALE_CIRCLE' = 'sale-circle',
  'PERCENT_CIRCLE_OUTLINE' = 'percent-circle-outline',
  'DISCOUNT_CIRCLE_OUTLINE' = 'discount-circle-outline',
  'SALE_CIRCLE_OUTLINE' = 'sale-circle-outline',
  'PERCENT_OUTLINE' = 'percent-outline',
  'DISCOUNT_OUTLINE' = 'discount-outline',
  'SALE_OUTLINE' = 'sale-outline',
  'PERIODIC_TABLE' = 'periodic-table',
  'PERSPECTIVE_LESS' = 'perspective-less',
  'PERSPECTIVE_DECREASE' = 'perspective-decrease',
  'PERSPECTIVE_MORE' = 'perspective-more',
  'PERSPECTIVE_INCREASE' = 'perspective-increase',
  'PH' = 'ph',
  'ACID' = 'acid',
  'BASE' = 'base',
  'POTENTIAL_OF_HYDROGEN' = 'potential-of-hydrogen',
  'POWER_OF_HYDROGEN' = 'power-of-hydrogen',
  'PHONE' = 'phone',
  'CALL' = 'call',
  'LOCAL_PHONE' = 'local-phone',
  'TELEPHONE' = 'telephone',
  'PHONE_ALERT' = 'phone-alert',
  'PHONE_ALERT_OUTLINE' = 'phone-alert-outline',
  'PHONE_BLUETOOTH' = 'phone-bluetooth',
  'PHONE_BLUETOOTH_SPEAKER' = 'phone-bluetooth-speaker',
  'TELEPHONE_BLUETOOTH' = 'telephone-bluetooth',
  'PHONE_BLUETOOTH_OUTLINE' = 'phone-bluetooth-outline',
  'PHONE_CANCEL' = 'phone-cancel',
  'PHONE_BLOCK' = 'phone-block',
  'PHONE_CANCEL_OUTLINE' = 'phone-cancel-outline',
  'PHONE_CHECK' = 'phone-check',
  'PHONE_CHECK_OUTLINE' = 'phone-check-outline',
  'PHONE_CLASSIC' = 'phone-classic',
  'PHONE_CLASSIC_OFF' = 'phone-classic-off',
  'PHONE_CLOCK' = 'phone-clock',
  'PHONE_SCHEDULE' = 'phone-schedule',
  'PHONE_TIME' = 'phone-time',
  'PHONE_DIAL' = 'phone-dial',
  'PHONE_KEYPAD' = 'phone-keypad',
  'PHONE_DIAL_OUTLINE' = 'phone-dial-outline',
  'PHONE_KEYPAD_OUTLINE' = 'phone-keypad-outline',
  'PHONE_FORWARD' = 'phone-forward',
  'PHONE_FORWARDED' = 'phone-forwarded',
  'TELEPHONE_FORWARD' = 'telephone-forward',
  'PHONE_FORWARD_OUTLINE' = 'phone-forward-outline',
  'PHONE_HANGUP' = 'phone-hangup',
  'CALL_END' = 'call-end',
  'TELEPHONE_HANGUP' = 'telephone-hangup',
  'PHONE_HANGUP_OUTLINE' = 'phone-hangup-outline',
  'PHONE_IN_TALK' = 'phone-in-talk',
  'TELEPHONE_IN_TALK' = 'telephone-in-talk',
  'PHONE_IN_TALK_OUTLINE' = 'phone-in-talk-outline',
  'TELEPHONE_IN_TALK_OUTLINE' = 'telephone-in-talk-outline',
  'PHONE_INCOMING' = 'phone-incoming',
  'TELEPHONE_INCOMING' = 'telephone-incoming',
  'PHONE_INCOMING_OUTLINE' = 'phone-incoming-outline',
  'PHONE_LOCK' = 'phone-lock',
  'TELEPHONE_LOCKED' = 'telephone-locked',
  'PHONE_LOCKED' = 'phone-locked',
  'TELEPHONE_LOCK' = 'telephone-lock',
  'PHONE_LOCK_OUTLINE' = 'phone-lock-outline',
  'PHONE_LOG' = 'phone-log',
  'PHONE_LOG_OUTLINE' = 'phone-log-outline',
  'PHONE_MESSAGE' = 'phone-message',
  'PHONE_MESSAGE_OUTLINE' = 'phone-message-outline',
  'PHONE_MINUS' = 'phone-minus',
  'PHONE_MINUS_OUTLINE' = 'phone-minus-outline',
  'PHONE_MISSED' = 'phone-missed',
  'PHONE_MISSED_OUTLINE' = 'phone-missed-outline',
  'PHONE_OFF' = 'phone-off',
  'PHONE_OFF_OUTLINE' = 'phone-off-outline',
  'PHONE_OUTGOING' = 'phone-outgoing',
  'PHONE_OUTGOING_OUTLINE' = 'phone-outgoing-outline',
  'PHONE_OUTLINE' = 'phone-outline',
  'TELEPHONE_OUTLINE' = 'telephone-outline',
  'CALL_OUTLINE' = 'call-outline',
  'PHONE_PAUSED' = 'phone-paused',
  'PHONE_PAUSED_OUTLINE' = 'phone-paused-outline',
  'PHONE_PLUS' = 'phone-plus',
  'ADD_CALL' = 'add-call',
  'PHONE_PLUS_OUTLINE' = 'phone-plus-outline',
  'PHONE_REFRESH' = 'phone-refresh',
  'PHONE_REDIAL' = 'phone-redial',
  'PHONE_REFRESH_OUTLINE' = 'phone-refresh-outline',
  'PHONE_REDIAL_OUTLINE' = 'phone-redial-outline',
  'PHONE_REMOVE' = 'phone-remove',
  'PHONE_REMOVE_OUTLINE' = 'phone-remove-outline',
  'PHONE_RETURN' = 'phone-return',
  'PHONE_RETURN_OUTLINE' = 'phone-return-outline',
  'PHONE_RING' = 'phone-ring',
  'PHONE_RING_OUTLINE' = 'phone-ring-outline',
  'PHONE_ROTATE_LANDSCAPE' = 'phone-rotate-landscape',
  'PHONE_ROTATE_PORTRAIT' = 'phone-rotate-portrait',
  'PHONE_SETTINGS' = 'phone-settings',
  'SETTINGS_PHONE' = 'settings-phone',
  'PHONE_SETTINGS_OUTLINE' = 'phone-settings-outline',
  'PHONE_SYNC' = 'phone-sync',
  'PHONE_SYNC_OUTLINE' = 'phone-sync-outline',
  'PHONE_VOIP' = 'phone-voip',
  'PI' = 'pi',
  'PI_BOX' = 'pi-box',
  'PI_HOLE' = 'pi-hole',
  'PIANO' = 'piano',
  'PIANO_OFF' = 'piano-off',
  'PICKAXE' = 'pickaxe',
  'PICTURE_IN_PICTURE_BOTTOM_RIGHT' = 'picture-in-picture-bottom-right',
  'PICTURE_IN_PICTURE_BOTTOM_RIGHT_OUTLINE' = 'picture-in-picture-bottom-right-outline',
  'PICTURE_IN_PICTURE_TOP_RIGHT' = 'picture-in-picture-top-right',
  'PICTURE_IN_PICTURE_TOP_RIGHT_OUTLINE' = 'picture-in-picture-top-right-outline',
  'PIER' = 'pier',
  'PIER_CRANE' = 'pier-crane',
  'PIG' = 'pig',
  'EMOJI_PIG' = 'emoji-pig',
  'EMOTICON_PIG' = 'emoticon-pig',
  'PIG_VARIANT' = 'pig-variant',
  'PIG_VARIANT_OUTLINE' = 'pig-variant-outline',
  'PIGGY_BANK' = 'piggy-bank',
  'PIGGY_BANK_OUTLINE' = 'piggy-bank-outline',
  'PILL' = 'pill',
  'CAPSULE' = 'capsule',
  'PILLAR' = 'pillar',
  'HISTORIC' = 'historic',
  'COLUMN' = 'column',
  'PIN' = 'pin',
  'KEEP' = 'keep',
  'PIN_OFF' = 'pin-off',
  'KEEP_OFF' = 'keep-off',
  'PIN_OFF_OUTLINE' = 'pin-off-outline',
  'KEEP_OFF_OUTLINE' = 'keep-off-outline',
  'PIN_OUTLINE' = 'pin-outline',
  'KEEP_OUTLINE' = 'keep-outline',
  'PINE_TREE_BOX' = 'pine-tree-box',
  'PINE_TREE_FIRE' = 'pine-tree-fire',
  'PINTEREST' = 'pinterest',
  'PINWHEEL' = 'pinwheel',
  'TOYS' = 'toys',
  'PINWHEEL_OUTLINE' = 'pinwheel-outline',
  'TOYS_OUTLINE' = 'toys-outline',
  'PIPE' = 'pipe',
  'PIPE_DISCONNECTED' = 'pipe-disconnected',
  'PIPE_LEAK' = 'pipe-leak',
  'PIPE_VALVE' = 'pipe-valve',
  'PIPE_WRENCH' = 'pipe-wrench',
  'MONKEY_WRENCH' = 'monkey-wrench',
  'PIRATE' = 'pirate',
  'PISTOL' = 'pistol',
  'GUN' = 'gun',
  'PISTON' = 'piston',
  'PITCHFORK' = 'pitchfork',
  'PIZZA' = 'pizza',
  'PIZZERIA' = 'pizzeria',
  'LOCAL_PIZZA' = 'local-pizza',
  'PLAY' = 'play',
  'PLAY_ARROW' = 'play-arrow',
  'PLAY_BOX' = 'play-box',
  'PLAY_BOX_LOCK' = 'play-box-lock',
  'PLAY_BOX_LOCK_OPEN' = 'play-box-lock-open',
  'PLAY_BOX_LOCK_OPEN_OUTLINE' = 'play-box-lock-open-outline',
  'PLAY_BOX_LOCK_OUTLINE' = 'play-box-lock-outline',
  'PLAY_BOX_MULTIPLE' = 'play-box-multiple',
  'PLAY_BOX_MULTIPLE_OUTLINE' = 'play-box-multiple-outline',
  'PLAY_BOX_OUTLINE' = 'play-box-outline',
  'SLIDESHOW' = 'slideshow',
  'PLAY_CIRCLE' = 'play-circle',
  'PLAY_CIRCLE_FILLED' = 'play-circle-filled',
  'PLAY_CIRCLE_OUTLINE' = 'play-circle-outline',
  'PLAY_NETWORK' = 'play-network',
  'MEDIA_NETWORK' = 'media-network',
  'PLAY_NETWORK_OUTLINE' = 'play-network-outline',
  'MEDIA_NETWORK_OUTLINE' = 'media-network-outline',
  'PLAY_OUTLINE' = 'play-outline',
  'PLAY_PAUSE' = 'play-pause',
  'PLAY_PROTECTED_CONTENT' = 'play-protected-content',
  'PLAY_SPEED' = 'play-speed',
  'PLAYLIST_CHECK' = 'playlist-check',
  'SUBSCRIPTIONS' = 'subscriptions',
  'PLAYLIST_ADD_CHECK' = 'playlist-add-check',
  'PLAYLIST_TICK' = 'playlist-tick',
  'PLAYLIST_EDIT' = 'playlist-edit',
  'PLAYLIST_MINUS' = 'playlist-minus',
  'PLAYLIST_MUSIC' = 'playlist-music',
  'PLAYLIST_NOTE' = 'playlist-note',
  'QUEUE_MUSIC' = 'queue-music',
  'PLAYLIST_MUSIC_OUTLINE' = 'playlist-music-outline',
  'PLAYLIST_NOTE_OUTLINE' = 'playlist-note-outline',
  'QUEUE_MUSIC_OUTLINE' = 'queue-music-outline',
  'PLAYLIST_PLAY' = 'playlist-play',
  'PLAYLIST_PLUS' = 'playlist-plus',
  'PLAYLIST_ADD' = 'playlist-add',
  'PLAYLIST_REMOVE' = 'playlist-remove',
  'PLAYLIST_STAR' = 'playlist-star',
  'PLAYLIST_FAVORITE' = 'playlist-favorite',
  'PLEX' = 'plex',
  'PLIERS' = 'pliers',
  'PLUS' = 'plus',
  'ADD' = 'add',
  'PLUS_BOX' = 'plus-box',
  'ADD_BOX' = 'add-box',
  'PLUS_BOX_MULTIPLE' = 'plus-box-multiple',
  'ADD_TO_PHOTOS' = 'add-to-photos',
  'LIBRARY_ADD' = 'library-add',
  'QUEUE' = 'queue',
  'LIBRARY_PLUS' = 'library-plus',
  'PLUS_BOX_MULTIPLE_OUTLINE' = 'plus-box-multiple-outline',
  'PLUS_BOX_OUTLINE' = 'plus-box-outline',
  'PLUS_CIRCLE' = 'plus-circle',
  'ADD_CIRCLE' = 'add-circle',
  'PLUS_CIRCLE_MULTIPLE' = 'plus-circle-multiple',
  'COINS_PLUS' = 'coins-plus',
  'PLUS_CIRCLE_MULTIPLE_OUTLINE' = 'plus-circle-multiple-outline',
  'CONTROL_POINT_DUPLICATE' = 'control-point-duplicate',
  'PLUS_CIRCLES_OUTLINE' = 'plus-circles-outline',
  'COINS_PLUS_OUTLINE' = 'coins-plus-outline',
  'PLUS_CIRCLE_OUTLINE' = 'plus-circle-outline',
  'ADD_CIRCLE_OUTLINE' = 'add-circle-outline',
  'CONTROL_POINT' = 'control-point',
  'CIRCLES_ADD' = 'circles-add',
  'PLUS_MINUS' = 'plus-minus',
  'PLUS_MINUS_BOX' = 'plus-minus-box',
  'PLUS_MINUS_VARIANT' = 'plus-minus-variant',
  'PLUS_NETWORK' = 'plus-network',
  'ADD_NETWORK' = 'add-network',
  'PLUS_NETWORK_OUTLINE' = 'plus-network-outline',
  'ADD_NETWORK_OUTLINE' = 'add-network-outline',
  'PLUS_OUTLINE' = 'plus-outline',
  'PLUS_THICK' = 'plus-thick',
  'ADD_THICK' = 'add-thick',
  'ADD_BOLD' = 'add-bold',
  'PLUS_BOLD' = 'plus-bold',
  'PODCAST' = 'podcast',
  'PODIUM_BRONZE' = 'podium-bronze',
  'PODIUM_THIRD' = 'podium-third',
  'PODIUM_GOLD' = 'podium-gold',
  'PODIUM_FIRST' = 'podium-first',
  'PODIUM_SILVER' = 'podium-silver',
  'PODIUM_SECOND' = 'podium-second',
  'POINT_OF_SALE' = 'point-of-sale',
  'POKEBALL' = 'pokeball',
  'POKEMON_GO' = 'pokemon-go',
  'POKER_CHIP' = 'poker-chip',
  'CASINO_CHIP' = 'casino-chip',
  'GAMBLING_CHIP' = 'gambling-chip',
  'POLAROID' = 'polaroid',
  'POLICE_BADGE' = 'police-badge',
  'POLICE_BADGE_OUTLINE' = 'police-badge-outline',
  'POLICE_STATION' = 'police-station',
  'POLL' = 'poll',
  'BAR_CHART' = 'bar-chart',
  'PERFORMANCE' = 'performance',
  'ANALYTICS' = 'analytics',
  'POLO' = 'polo',
  'POLYMER' = 'polymer',
  'SWIMMING_POOL' = 'swimming-pool',
  'POPCORN' = 'popcorn',
  'POST' = 'post',
  'BLOG' = 'blog',
  'POST_OUTLINE' = 'post-outline',
  'BLOG_OUTLINE' = 'blog-outline',
  'POSTAGE_STAMP' = 'postage-stamp',
  'POT_MIX' = 'pot-mix',
  'POT_MIX_OUTLINE' = 'pot-mix-outline',
  'POT_OUTLINE' = 'pot-outline',
  'POT_STEAM' = 'pot-steam',
  'POT_STEAM_OUTLINE' = 'pot-steam-outline',
  'HASHTAG' = 'hashtag',
  'POUND_BOX' = 'pound-box',
  'HASHTAG_BOX' = 'hashtag-box',
  'POUND_BOX_OUTLINE' = 'pound-box-outline',
  'HASHTAG_BOX_OUTLINE' = 'hashtag-box-outline',
  'POWER_SETTINGS_NEW' = 'power-settings-new',
  'SHUTDOWN' = 'shutdown',
  'POWER_CYCLE' = 'power-cycle',
  'POWER_OFF' = 'power-off',
  'POWER_ON' = 'power-on',
  'POWER_PLUG' = 'power-plug',
  'POWER_PLUG_OFF' = 'power-plug-off',
  'POWER_PLUG_OFF_OUTLINE' = 'power-plug-off-outline',
  'POWER_PLUG_OUTLINE' = 'power-plug-outline',
  'POWER_SETTINGS' = 'power-settings',
  'SETTINGS_POWER' = 'settings-power',
  'POWER_SLEEP' = 'power-sleep',
  'POWER_SOCKET' = 'power-socket',
  'PLUG_SOCKET' = 'plug-socket',
  'POWER_SOCKET_AU' = 'power-socket-au',
  'PLUG_SOCKET_AU' = 'plug-socket-au',
  'POWER_SOCKET_TYPE_I' = 'power-socket-type-i',
  'POWER_SOCKET_CN' = 'power-socket-cn',
  'POWER_SOCKET_AR' = 'power-socket-ar',
  'POWER_SOCKET_NZ' = 'power-socket-nz',
  'POWER_SOCKET_PG' = 'power-socket-pg',
  'POWER_SOCKET_AUSTRALIA' = 'power-socket-australia',
  'POWER_SOCKET_CHINA' = 'power-socket-china',
  'POWER_SOCKET_ARGENTINA' = 'power-socket-argentina',
  'POWER_SOCKET_NEW_ZEALAND' = 'power-socket-new-zealand',
  'POWER_SOCKET_PAPUA_NEW_GUINEA' = 'power-socket-papua-new-guinea',
  'POWER_SOCKET_CH' = 'power-socket-ch',
  'PLUG_SOCKET_CH' = 'plug-socket-ch',
  'POWER_SOCKET_TYPE_J' = 'power-socket-type-j',
  'PLUG_SOCKET_TYPE_J' = 'plug-socket-type-j',
  'POWER_SOCKET_SWITZERLAND' = 'power-socket-switzerland',
  'PLUG_SOCKET_SWITZERLAND' = 'plug-socket-switzerland',
  'POWER_SOCKET_DE' = 'power-socket-de',
  'POWER_SOCKET_EU' = 'power-socket-eu',
  'PLUG_SOCKET_EU' = 'plug-socket-eu',
  'POWER_SOCKET_EUROPE' = 'power-socket-europe',
  'POWER_SOCKET_FR' = 'power-socket-fr',
  'POWER_SOCKET_IT' = 'power-socket-it',
  'POWER_SOCKET_JP' = 'power-socket-jp',
  'POWER_SOCKET_UK' = 'power-socket-uk',
  'PLUG_SOCKET_UK' = 'plug-socket-uk',
  'POWER_SOCKET_TYPE_G' = 'power-socket-type-g',
  'POWER_SOCKET_IE' = 'power-socket-ie',
  'POWER_SOCKET_HK' = 'power-socket-hk',
  'POWER_SOCKET_MY' = 'power-socket-my',
  'POWER_SOCKET_CY' = 'power-socket-cy',
  'POWER_SOCKET_MT' = 'power-socket-mt',
  'POWER_SOCKET_SG' = 'power-socket-sg',
  'POWER_SOCKET_UNITED_KINGDOM' = 'power-socket-united-kingdom',
  'POWER_SOCKET_IRELAND' = 'power-socket-ireland',
  'POWER_SOCKET_HONG_KONG' = 'power-socket-hong-kong',
  'POWER_SOCKET_MALAYSIA' = 'power-socket-malaysia',
  'POWER_SOCKET_CYPRUS' = 'power-socket-cyprus',
  'POWER_SOCKET_MALTA' = 'power-socket-malta',
  'POWER_SOCKET_SINGAPORE' = 'power-socket-singapore',
  'POWER_SOCKET_US' = 'power-socket-us',
  'PLUG_SOCKET_US' = 'plug-socket-us',
  'POWER_SOCKET_CA' = 'power-socket-ca',
  'POWER_SOCKET_MX' = 'power-socket-mx',
  'POWER_SOCKET_TYPE_B' = 'power-socket-type-b',
  'POWER_SOCKET_UNITED_STATES' = 'power-socket-united-states',
  'POWER_SOCKET_JAPAN' = 'power-socket-japan',
  'POWER_SOCKET_CANADA' = 'power-socket-canada',
  'POWER_SOCKET_MEXICO' = 'power-socket-mexico',
  'POWER_STANDBY' = 'power-standby',
  'POWERSHELL' = 'powershell',
  'PRESCRIPTION' = 'prescription',
  'PRESENTATION' = 'presentation',
  'PRESENTATION_PLAY' = 'presentation-play',
  'PRETZEL' = 'pretzel',
  'PRINTER' = 'printer',
  'LOCAL_PRINTSHOP' = 'local-printshop',
  'LOCAL_PRINT_SHOP' = 'local-print-shop',
  'PRINTER3D' = 'printer3d',
  'PRINTER3D_NOZZLE' = 'printer3d-nozzle',
  'PRINTER3D_NOZZLE_ALERT' = 'printer3d-nozzle-alert',
  'PRINTER3D_NOZZLE_ALERT_OUTLINE' = 'printer3d-nozzle-alert-outline',
  'PRINTER3D_NOZZLE_HEAT' = 'printer3d-nozzle-heat',
  'PRINTER3D_NOZZLE_HEAT_OUTLINE' = 'printer3d-nozzle-heat-outline',
  'PRINTER3D_NOZZLE_OUTLINE' = 'printer3d-nozzle-outline',
  'PRINTER_ALERT' = 'printer-alert',
  'PRINTER_WARNING' = 'printer-warning',
  'PAPER_JAM' = 'paper-jam',
  'PRINTER_CHECK' = 'printer-check',
  'PRINTER_EYE' = 'printer-eye',
  'PRINTER_PREVIEW' = 'printer-preview',
  'PRINTER_VIEW' = 'printer-view',
  'PRINTER_OFF' = 'printer-off',
  'PRINTER_OFF_OUTLINE' = 'printer-off-outline',
  'PRINTER_OUTLINE' = 'printer-outline',
  'PRINTER_POS' = 'printer-pos',
  'PRINTER_POINT_OF_SALE' = 'printer-point-of-sale',
  'PRINTER_SEARCH' = 'printer-search',
  'PRINTER_MAGNIFY' = 'printer-magnify',
  'PRINTER_SETTINGS' = 'printer-settings',
  'PRINTER_WIRELESS' = 'printer-wireless',
  'PRIORITY_HIGH' = 'priority-high',
  'PRIORITY_LOW' = 'priority-low',
  'LOW_PRIORITY' = 'low-priority',
  'PROFESSIONAL_HEXAGON' = 'professional-hexagon',
  'PROGRESS_ALERT' = 'progress-alert',
  'PROGRESS_WARNING' = 'progress-warning',
  'PROGRESS_CHECK' = 'progress-check',
  'PROGRESS_TICK' = 'progress-tick',
  'PROGRESS_CLOCK' = 'progress-clock',
  'PROGRESS_CLOSE' = 'progress-close',
  'PROGRESS_DOWNLOAD' = 'progress-download',
  'PROGRESS_PENCIL' = 'progress-pencil',
  'PROGRESS_QUESTION' = 'progress-question',
  'PROGRESS_STAR' = 'progress-star',
  'PROGRESS_UPLOAD' = 'progress-upload',
  'PROGRESS_WRENCH' = 'progress-wrench',
  'PROGRESS_SPANNER' = 'progress-spanner',
  'PROJECTOR' = 'projector',
  'PROJECTOR_OFF' = 'projector-off',
  'PROJECTOR_SCREEN' = 'projector-screen',
  'PROJECTOR_SCREEN_OFF' = 'projector-screen-off',
  'PROJECTOR_SCREEN_OFF_OUTLINE' = 'projector-screen-off-outline',
  'PROJECTOR_SCREEN_OUTLINE' = 'projector-screen-outline',
  'PROJECTOR_SCREEN_VARIANT' = 'projector-screen-variant',
  'PROJECTOR_SCREEN_VARIANT_OFF' = 'projector-screen-variant-off',
  'PROJECTOR_SCREEN_VARIANT_OFF_OUTLINE' = 'projector-screen-variant-off-outline',
  'PROJECTOR_SCREEN_VARIANT_OUTLINE' = 'projector-screen-variant-outline',
  'PROPANE_TANK' = 'propane-tank',
  'PROPANE_TANK_OUTLINE' = 'propane-tank-outline',
  'PROTOCOL' = 'protocol',
  'PUBLISH' = 'publish',
  'PUBLISH_OFF' = 'publish-off',
  'PUBLISH_DISABLED' = 'publish-disabled',
  'PULSE' = 'pulse',
  'VITALS' = 'vitals',
  'PUMP' = 'pump',
  'PUMPKIN' = 'pumpkin',
  'PURSE' = 'purse',
  'PURSE_OUTLINE' = 'purse-outline',
  'PUZZLE' = 'puzzle',
  'EXTENSION' = 'extension',
  'JIGSAW' = 'jigsaw',
  'PUZZLE_CHECK' = 'puzzle-check',
  'PUZZLE_CHECK_OUTLINE' = 'puzzle-check-outline',
  'PUZZLE_EDIT' = 'puzzle-edit',
  'PUZZLE_EDIT_OUTLINE' = 'puzzle-edit-outline',
  'PUZZLE_HEART' = 'puzzle-heart',
  'PUZZLE_HEART_OUTLINE' = 'puzzle-heart-outline',
  'PUZZLE_MINUS' = 'puzzle-minus',
  'PUZZLE_MINUS_OUTLINE' = 'puzzle-minus-outline',
  'PUZZLE_OUTLINE' = 'puzzle-outline',
  'JIGSAW_OUTLINE' = 'jigsaw-outline',
  'EXTENSION_OUTLINE' = 'extension-outline',
  'PUZZLE_PLUS' = 'puzzle-plus',
  'PUZZLE_PLUS_OUTLINE' = 'puzzle-plus-outline',
  'PUZZLE_REMOVE' = 'puzzle-remove',
  'PUZZLE_REMOVE_OUTLINE' = 'puzzle-remove-outline',
  'PUZZLE_STAR' = 'puzzle-star',
  'PUZZLE_FAVORITE' = 'puzzle-favorite',
  'PUZZLE_STAR_OUTLINE' = 'puzzle-star-outline',
  'PUZZLE_FAVORITE_OUTLINE' = 'puzzle-favorite-outline',
  'PYRAMID' = 'pyramid',
  'PYRAMID_OFF' = 'pyramid-off',
  'QI' = 'qi',
  'QQCHAT' = 'qqchat',
  'QRCODE' = 'qrcode',
  'QRCODE_EDIT' = 'qrcode-edit',
  'QRCODE_MINUS' = 'qrcode-minus',
  'QRCODE_PLUS' = 'qrcode-plus',
  'QRCODE_REMOVE' = 'qrcode-remove',
  'QRCODE_SCAN' = 'qrcode-scan',
  'QUADCOPTER' = 'quadcopter',
  'QUALITY_HIGH' = 'quality-high',
  'HIGH_QUALITY' = 'high-quality',
  'HQ' = 'hq',
  'QUALITY_LOW' = 'quality-low',
  'LOW_QUALITY' = 'low-quality',
  'LQ' = 'lq',
  'QUALITY_MEDIUM' = 'quality-medium',
  'MEDIUM_QUALITY' = 'medium-quality',
  'MQ' = 'mq',
  'QUORA' = 'quora',
  'RABBIT' = 'rabbit',
  'BUNNY' = 'bunny',
  'HARE' = 'hare',
  'RACING_HELMET' = 'racing-helmet',
  'RACQUETBALL' = 'racquetball',
  'LACROSSE' = 'lacrosse',
  'SQUASH' = 'squash',
  'RADAR' = 'radar',
  'TRACK_CHANGES' = 'track-changes',
  'RADIATOR' = 'radiator',
  'HEATER' = 'heater',
  'RADIATOR_DISABLED' = 'radiator-disabled',
  'HEATER_DISABLED' = 'heater-disabled',
  'RADIATOR_OFF' = 'radiator-off',
  'HEATER_OFF' = 'heater-off',
  'RADIO' = 'radio',
  'RADIO_AM' = 'radio-am',
  'RADIO_FM' = 'radio-fm',
  'RADIO_HANDHELD' = 'radio-handheld',
  'RADIO_OFF' = 'radio-off',
  'RADIO_TOWER' = 'radio-tower',
  'RADIOACTIVE' = 'radioactive',
  'RADIATION' = 'radiation',
  'RADIOACTIVE_CIRCLE' = 'radioactive-circle',
  'RADIATION_CIRCLE' = 'radiation-circle',
  'RADIOACTIVE_CIRCLE_OUTLINE' = 'radioactive-circle-outline',
  'RADIATION_CIRCLE_OUTLINE' = 'radiation-circle-outline',
  'RADIOACTIVE_OFF' = 'radioactive-off',
  'RADIATION_OFF' = 'radiation-off',
  'RADIOBOX_BLANK' = 'radiobox-blank',
  'RADIO_BUTTON_UNCHECKED' = 'radio-button-unchecked',
  'RADIOBOX_MARKED' = 'radiobox-marked',
  'RADIO_BUTTON_CHECKED' = 'radio-button-checked',
  'RADIOLOGY_BOX' = 'radiology-box',
  'X_RAY_BOX' = 'x-ray-box',
  'RADIOLOGY_BOX_OUTLINE' = 'radiology-box-outline',
  'X_RAY_BOX_OUTLINE' = 'x-ray-box-outline',
  'RADIUS' = 'radius',
  'CIRCLE_RADIUS' = 'circle-radius',
  'SPHERE_RADIUS' = 'sphere-radius',
  'RADIUS_OUTLINE' = 'radius-outline',
  'CIRCLE_RADIUS_OUTLINE' = 'circle-radius-outline',
  'SPHERE_RADIUS_OUTLINE' = 'sphere-radius-outline',
  'RAILROAD_LIGHT' = 'railroad-light',
  'RAKE' = 'rake',
  'RASPBERRY_PI' = 'raspberry-pi',
  'RASPBERRYPI' = 'raspberrypi',
  'RAW' = 'raw',
  'RAW_OFF' = 'raw-off',
  'RAY_END' = 'ray-end',
  'RAY_END_ARROW' = 'ray-end-arrow',
  'RAY_START' = 'ray-start',
  'RAY_START_ARROW' = 'ray-start-arrow',
  'RAY_START_END' = 'ray-start-end',
  'RAY_START_VERTEX_END' = 'ray-start-vertex-end',
  'RAY_VERTEX' = 'ray-vertex',
  'RAZOR_DOUBLE_EDGE' = 'razor-double-edge',
  'RAZOR_SINGLE_EDGE' = 'razor-single-edge',
  'REACT' = 'react',
  'READ' = 'read',
  'RECEIPT' = 'receipt',
  'INVOICE' = 'invoice',
  'RECEIPT_OUTLINE' = 'receipt-outline',
  'INVOICE_OUTLINE' = 'invoice-outline',
  'FIBER_MANUAL_RECORD' = 'fiber-manual-record',
  'RECORD_CIRCLE' = 'record-circle',
  'RECORD_CIRCLE_OUTLINE' = 'record-circle-outline',
  'RECORD_PLAYER' = 'record-player',
  'RECORD_REC' = 'record-rec',
  'RECTANGLE' = 'rectangle',
  'RECTANGLE_OUTLINE' = 'rectangle-outline',
  'RECYCLE' = 'recycle',
  'RECYCLE_VARIANT' = 'recycle-variant',
  'REDDIT' = 'reddit',
  'REDHAT' = 'redhat',
  'REDO' = 'redo',
  'ARROW' = 'arrow',
  'REDO_VARIANT' = 'redo-variant',
  'REFLECT_HORIZONTAL' = 'reflect-horizontal',
  'REFLECT_VERTICAL' = 'reflect-vertical',
  'REFRESH' = 'refresh',
  'LOOP' = 'loop',
  'REFRESH_AUTO' = 'refresh-auto',
  'AUTO_START' = 'auto-start',
  'AUTOMATIC_START' = 'automatic-start',
  'AUTO_STOP' = 'auto-stop',
  'AUTOMATIC_STOP' = 'automatic-stop',
  'AUTOMATIC' = 'automatic',
  'REFRESH_AUTOMATIC' = 'refresh-automatic',
  'REFRESH_CIRCLE' = 'refresh-circle',
  'REGEX' = 'regex',
  'REGULAR_EXPRESSION' = 'regular-expression',
  'REGISTERED_TRADEMARK' = 'registered-trademark',
  'REITERATE' = 'reiterate',
  'RELATION_MANY_TO_MANY' = 'relation-many-to-many',
  'RELATION_MANY_TO_ONE' = 'relation-many-to-one',
  'RELATION_MANY_TO_ONE_OR_MANY' = 'relation-many-to-one-or-many',
  'RELATION_MANY_TO_ONLY_ONE' = 'relation-many-to-only-one',
  'RELATION_MANY_TO_ZERO_OR_MANY' = 'relation-many-to-zero-or-many',
  'RELATION_MANY_TO_ZERO_OR_ONE' = 'relation-many-to-zero-or-one',
  'RELATION_ONE_OR_MANY_TO_MANY' = 'relation-one-or-many-to-many',
  'RELATION_ONE_OR_MANY_TO_ONE' = 'relation-one-or-many-to-one',
  'RELATION_ONE_OR_MANY_TO_ONE_OR_MANY' = 'relation-one-or-many-to-one-or-many',
  'RELATION_ONE_OR_MANY_TO_ONLY_ONE' = 'relation-one-or-many-to-only-one',
  'RELATION_ONE_OR_MANY_TO_ZERO_OR_MANY' = 'relation-one-or-many-to-zero-or-many',
  'RELATION_ONE_OR_MANY_TO_ZERO_OR_ONE' = 'relation-one-or-many-to-zero-or-one',
  'RELATION_ONE_TO_MANY' = 'relation-one-to-many',
  'RELATION_ONE_TO_ONE' = 'relation-one-to-one',
  'RELATION_ONE_TO_ONE_OR_MANY' = 'relation-one-to-one-or-many',
  'RELATION_ONE_TO_ONLY_ONE' = 'relation-one-to-only-one',
  'RELATION_ONE_TO_ZERO_OR_MANY' = 'relation-one-to-zero-or-many',
  'RELATION_ONE_TO_ZERO_OR_ONE' = 'relation-one-to-zero-or-one',
  'RELATION_ONLY_ONE_TO_MANY' = 'relation-only-one-to-many',
  'RELATION_ONLY_ONE_TO_ONE' = 'relation-only-one-to-one',
  'RELATION_ONLY_ONE_TO_ONE_OR_MANY' = 'relation-only-one-to-one-or-many',
  'RELATION_ONLY_ONE_TO_ONLY_ONE' = 'relation-only-one-to-only-one',
  'RELATION_ONLY_ONE_TO_ZERO_OR_MANY' = 'relation-only-one-to-zero-or-many',
  'RELATION_ONLY_ONE_TO_ZERO_OR_ONE' = 'relation-only-one-to-zero-or-one',
  'RELATION_ZERO_OR_MANY_TO_MANY' = 'relation-zero-or-many-to-many',
  'RELATION_ZERO_OR_MANY_TO_ONE' = 'relation-zero-or-many-to-one',
  'RELATION_ZERO_OR_MANY_TO_ONE_OR_MANY' = 'relation-zero-or-many-to-one-or-many',
  'RELATION_ZERO_OR_MANY_TO_ONLY_ONE' = 'relation-zero-or-many-to-only-one',
  'RELATION_ZERO_OR_MANY_TO_ZERO_OR_MANY' = 'relation-zero-or-many-to-zero-or-many',
  'RELATION_ZERO_OR_MANY_TO_ZERO_OR_ONE' = 'relation-zero-or-many-to-zero-or-one',
  'RELATION_ZERO_OR_ONE_TO_MANY' = 'relation-zero-or-one-to-many',
  'RELATION_ZERO_OR_ONE_TO_ONE' = 'relation-zero-or-one-to-one',
  'RELATION_ZERO_OR_ONE_TO_ONE_OR_MANY' = 'relation-zero-or-one-to-one-or-many',
  'RELATION_ZERO_OR_ONE_TO_ONLY_ONE' = 'relation-zero-or-one-to-only-one',
  'RELATION_ZERO_OR_ONE_TO_ZERO_OR_MANY' = 'relation-zero-or-one-to-zero-or-many',
  'RELATION_ZERO_OR_ONE_TO_ZERO_OR_ONE' = 'relation-zero-or-one-to-zero-or-one',
  'RELATIVE_SCALE' = 'relative-scale',
  'IMAGE_ASPECT_RATIO' = 'image-aspect-ratio',
  'RELOAD' = 'reload',
  'CAR_ENGINE_START' = 'car-engine-start',
  'ROTATE_CLOCKWISE' = 'rotate-clockwise',
  'RELOAD_ALERT' = 'reload-alert',
  'REMINDER' = 'reminder',
  'REMOTE' = 'remote',
  'SETTINGS_REMOTE' = 'settings-remote',
  'REMOTE_DESKTOP' = 'remote-desktop',
  'REMOTE_OFF' = 'remote-off',
  'REMOTE_TV' = 'remote-tv',
  'REMOTE_TV_OFF' = 'remote-tv-off',
  'RENAME_BOX' = 'rename-box',
  'REORDER_HORIZONTAL' = 'reorder-horizontal',
  'REORDER_VERTICAL' = 'reorder-vertical',
  'REPEAT' = 'repeat',
  'REPOST' = 'repost',
  'REPEAT_OFF' = 'repeat-off',
  'REPEAT_ONCE' = 'repeat-once',
  'REPEAT_ONE' = 'repeat-one',
  'REPEAT_VARIANT' = 'repeat-variant',
  'TWITTER_RETWEET' = 'twitter-retweet',
  'REPLAY' = 'replay',
  'REPLY' = 'reply',
  'REPLY_ALL' = 'reply-all',
  'REPLY_ALL_OUTLINE' = 'reply-all-outline',
  'REPLY_CIRCLE' = 'reply-circle',
  'REPLY_OUTLINE' = 'reply-outline',
  'REPRODUCTION' = 'reproduction',
  'RESISTOR' = 'resistor',
  'RESISTOR_NODES' = 'resistor-nodes',
  'RESIZE_BOTTOM_RIGHT' = 'resize-bottom-right',
  'RESPONSIVE' = 'responsive',
  'RESTART' = 'restart',
  'RESTART_ALERT' = 'restart-alert',
  'RESTART_OFF' = 'restart-off',
  'RESTORE' = 'restore',
  'ROTATE_COUNTER_CLOCKWISE' = 'rotate-counter-clockwise',
  'RESTORE_ALERT' = 'restore-alert',
  'REWIND' = 'rewind',
  'FAST_REWIND' = 'fast-rewind',
  'REWIND10' = 'rewind10',
  'REWIND15' = 'rewind15',
  'REWIND30' = 'rewind30',
  'REWIND5' = 'rewind5',
  'REWIND60' = 'rewind60',
  'REWIND_OUTLINE' = 'rewind-outline',
  'RHOMBUS' = 'rhombus',
  'RHOMBUS_MEDIUM' = 'rhombus-medium',
  'RHOMBUS_MEDIUM_OUTLINE' = 'rhombus-medium-outline',
  'RHOMBUS_OUTLINE' = 'rhombus-outline',
  'RHOMBUS_SPLIT' = 'rhombus-split',
  'COLLECTION' = 'collection',
  'RHOMBUS_SPLIT_OUTLINE' = 'rhombus-split-outline',
  'RICE' = 'rice',
  'RICKSHAW' = 'rickshaw',
  'RICKSHAW_ELECTRIC' = 'rickshaw-electric',
  'RING' = 'ring',
  'RIVET' = 'rivet',
  'ROAD' = 'road',
  'ROAD_VARIANT' = 'road-variant',
  'ROBBER' = 'robber',
  'ROBOT' = 'robot',
  'EMOJI_ROBOT' = 'emoji-robot',
  'EMOTICON_ROBOT' = 'emoticon-robot',
  'ROBOT_ANGRY' = 'robot-angry',
  'EMOJI_ROBOT_ANGRY' = 'emoji-robot-angry',
  'EMOTICON_ROBOT_ANGRY' = 'emoticon-robot-angry',
  'ROBOT_ANGRY_OUTLINE' = 'robot-angry-outline',
  'EMOJI_ROBOT_ANGRY_OUTLINE' = 'emoji-robot-angry-outline',
  'EMOTICON_ROBOT_ANGRY_OUTLINE' = 'emoticon-robot-angry-outline',
  'ROBOT_CONFUSED' = 'robot-confused',
  'EMOJI_ROBOT_CONFUSED' = 'emoji-robot-confused',
  'EMOTICON_ROBOT_CONFUSED' = 'emoticon-robot-confused',
  'ROBOT_CONFUSED_OUTLINE' = 'robot-confused-outline',
  'EMOJI_ROBOT_CONFUSED_OUTLINE' = 'emoji-robot-confused-outline',
  'EMOTICON_ROBOT_CONFUSED_OUTLINE' = 'emoticon-robot-confused-outline',
  'ROBOT_DEAD' = 'robot-dead',
  'EMOJI_ROBOT_DEAD' = 'emoji-robot-dead',
  'EMOTICON_ROBOT_DEAD' = 'emoticon-robot-dead',
  'ROBOT_DEAD_OUTLINE' = 'robot-dead-outline',
  'EMOJI_ROBOT_DEAD_OUTLINE' = 'emoji-robot-dead-outline',
  'EMOTICON_ROBOT_DEAD_OUTLINE' = 'emoticon-robot-dead-outline',
  'ROBOT_EXCITED' = 'robot-excited',
  'EMOTICON_ROBOT_EXCITED' = 'emoticon-robot-excited',
  'EMOJI_ROBOT_EXCITED' = 'emoji-robot-excited',
  'ROBOT_EXCITED_OUTLINE' = 'robot-excited-outline',
  'EMOJI_ROBOT_EXCITED_OUTLINE' = 'emoji-robot-excited-outline',
  'EMOTICON_ROBOT_EXCITED_OUTLINE' = 'emoticon-robot-excited-outline',
  'ROBOT_HAPPY' = 'robot-happy',
  'EMOJI_ROBOT_HAPPY' = 'emoji-robot-happy',
  'EMOTICON_ROBOT_HAPPY' = 'emoticon-robot-happy',
  'ROBOT_HAPPY_OUTLINE' = 'robot-happy-outline',
  'EMOJI_ROBOT_HAPPY_OUTLINE' = 'emoji-robot-happy-outline',
  'EMOTICON_ROBOT_HAPPY_OUTLINE' = 'emoticon-robot-happy-outline',
  'ROBOT_INDUSTRIAL' = 'robot-industrial',
  'AUTONOMOUS' = 'autonomous',
  'ASSEMBLY' = 'assembly',
  'ROBOT_INDUSTRIAL_OUTLINE' = 'robot-industrial-outline',
  'ROBOT_LOVE' = 'robot-love',
  'EMOJI_ROBOT_LOVE' = 'emoji-robot-love',
  'EMOTICON_ROBOT_LOVE' = 'emoticon-robot-love',
  'ROBOT_LOVE_OUTLINE' = 'robot-love-outline',
  'ROBOT_MOWER' = 'robot-mower',
  'LAWN_MOWER' = 'lawn-mower',
  'ROBOT_MOWER_OUTLINE' = 'robot-mower-outline',
  'LAWN_MOWER_OUTLINE' = 'lawn-mower-outline',
  'ROBOT_OFF' = 'robot-off',
  'EMOJI_ROBOT_OFF' = 'emoji-robot-off',
  'EMOTICON_ROBOT_OFF' = 'emoticon-robot-off',
  'ROBOT_OFF_OUTLINE' = 'robot-off-outline',
  'ROBOT_OUTLINE' = 'robot-outline',
  'EMOJI_ROBOT_OUTLINE' = 'emoji-robot-outline',
  'EMOTICON_ROBOT_OUTLINE' = 'emoticon-robot-outline',
  'ROBOT_VACUUM' = 'robot-vacuum',
  'ROOMBA' = 'roomba',
  'ROBOT_VACUUM_VARIANT' = 'robot-vacuum-variant',
  'NEATO' = 'neato',
  'ROCKET' = 'rocket',
  'ROCKET_LAUNCH' = 'rocket-launch',
  'ROCKET_LAUNCH_OUTLINE' = 'rocket-launch-outline',
  'ROCKET_OUTLINE' = 'rocket-outline',
  'RODENT' = 'rodent',
  'RAT' = 'rat',
  'ROLLER_SKATE' = 'roller-skate',
  'ROLLER_SKATE_OFF' = 'roller-skate-off',
  'ROLLERBLADE' = 'rollerblade',
  'ROLLERBLADE_OFF' = 'rollerblade-off',
  'ROLLUPJS' = 'rollupjs',
  'ROLLUP_JS' = 'rollup-js',
  'ROMAN_NUMERAL1' = 'roman-numeral1',
  'ROMAN_NUMERAL10' = 'roman-numeral10',
  'ROMAN_NUMERAL2' = 'roman-numeral2',
  'ROMAN_NUMERAL3' = 'roman-numeral3',
  'ROMAN_NUMERAL4' = 'roman-numeral4',
  'ROMAN_NUMERAL5' = 'roman-numeral5',
  'ROMAN_NUMERAL6' = 'roman-numeral6',
  'ROMAN_NUMERAL7' = 'roman-numeral7',
  'ROMAN_NUMERAL8' = 'roman-numeral8',
  'ROMAN_NUMERAL9' = 'roman-numeral9',
  'ROOM_SERVICE' = 'room-service',
  'ROOM_SERVICE_OUTLINE' = 'room-service-outline',
  'ROTATE360' = 'rotate360',
  'ROTATE3D' = 'rotate3d',
  'ROTATE3D_VARIANT' = 'rotate3d-variant',
  'ROTATE_LEFT' = 'rotate-left',
  'ARROW_ROTATE_LEFT' = 'arrow-rotate-left',
  'ROTATE_LEFT_VARIANT' = 'rotate-left-variant',
  'ROTATE_ORBIT' = 'rotate-orbit',
  'ROTATE_RIGHT' = 'rotate-right',
  'ARROW_ROTATE_RIGHT' = 'arrow-rotate-right',
  'ROTATE_RIGHT_VARIANT' = 'rotate-right-variant',
  'ROUNDED_CORNER' = 'rounded-corner',
  'ROUTER' = 'router',
  'ROUTER_NETWORK' = 'router-network',
  'ROUTER_WIRELESS' = 'router-wireless',
  'ROUTER_WIRELESS_OFF' = 'router-wireless-off',
  'ROUTER_WIRELESS_SETTINGS' = 'router-wireless-settings',
  'ROUTES' = 'routes',
  'SIGN_ROUTES' = 'sign-routes',
  'ROUTES_CLOCK' = 'routes-clock',
  'ROWING' = 'rowing',
  'HUMAN_ROWING' = 'human-rowing',
  'RSS' = 'rss',
  'RSS_FEED' = 'rss-feed',
  'RSS_BOX' = 'rss-box',
  'RSS_FEED_BOX' = 'rss-feed-box',
  'RSS_OFF' = 'rss-off',
  'RUG' = 'rug',
  'CARPET' = 'carpet',
  'RUGBY' = 'rugby',
  'RUGBY_BALL' = 'rugby-ball',
  'RULER' = 'ruler',
  'RULER_SQUARE' = 'ruler-square',
  'SQUARE' = 'square',
  'CARPENTRY' = 'carpentry',
  'ARCHITECTURE' = 'architecture',
  'RULER_SQUARE_COMPASS' = 'ruler-square-compass',
  'MASON' = 'mason',
  'MASONIC' = 'masonic',
  'FREEMASONRY' = 'freemasonry',
  'RUN' = 'run',
  'DIRECTIONS_RUN' = 'directions-run',
  'HUMAN_RUN' = 'human-run',
  'RUN_FAST' = 'run-fast',
  'HUMAN_RUN_FAST' = 'human-run-fast',
  'RV_TRUCK' = 'rv-truck',
  'RECREATIONAL_VEHICLE' = 'recreational-vehicle',
  'CAMPERVAN' = 'campervan',
  'SACK' = 'sack',
  'SACK_PERCENT' = 'sack-percent',
  'SAFE' = 'safe',
  'SAFE_SQUARE' = 'safe-square',
  'SAFE_SQUARE_OUTLINE' = 'safe-square-outline',
  'SAFETY_GOGGLES' = 'safety-goggles',
  'SAFETY_GLASSES' = 'safety-glasses',
  'SAIL_BOAT' = 'sail-boat',
  'SAILING' = 'sailing',
  'SALESFORCE' = 'salesforce',
  'SASS' = 'sass',
  'SATELLITE' = 'satellite',
  'SATELLITE_UPLINK' = 'satellite-uplink',
  'SATELLITE_VARIANT' = 'satellite-variant',
  'SAUSAGE' = 'sausage',
  'SAUSAGE_OFF' = 'sausage-off',
  'SAW_BLADE' = 'saw-blade',
  'SAWTOOTH_WAVE' = 'sawtooth-wave',
  'SAXOPHONE' = 'saxophone',
  'SCALE' = 'scale',
  'SCALE_BALANCE' = 'scale-balance',
  'JUSTICE' = 'justice',
  'LEGAL' = 'legal',
  'SCALE_BATHROOM' = 'scale-bathroom',
  'SCALE_OFF' = 'scale-off',
  'SCALE_UNBALANCED' = 'scale-unbalanced',
  'SCAN_HELPER' = 'scan-helper',
  'SCANNER' = 'scanner',
  'SCANNER_OFF' = 'scanner-off',
  'SCATTER_PLOT' = 'scatter-plot',
  'SCATTER_PLOT_OUTLINE' = 'scatter-plot-outline',
  'SCENT' = 'scent',
  'AROMA' = 'aroma',
  'FRAGRANCE' = 'fragrance',
  'SMELL' = 'smell',
  'ODOR' = 'odor',
  'SCENT_OFF' = 'scent-off',
  'AROMA_OFF' = 'aroma-off',
  'SMELL_OFF' = 'smell-off',
  'FRAGRANCE_OFF' = 'fragrance-off',
  'ODOR_OFF' = 'odor-off',
  'SCHOOL' = 'school',
  'GRADUATION_CAP' = 'graduation-cap',
  'UNIVERSITY' = 'university',
  'ACADEMIC_CAP' = 'academic-cap',
  'SCHOOL_OUTLINE' = 'school-outline',
  'ACADEMIC_CAP_OUTLINE' = 'academic-cap-outline',
  'COLLEGE_OUTLINE' = 'college-outline',
  'GRADUATION_CAP_OUTLINE' = 'graduation-cap-outline',
  'UNIVERSITY_OUTLINE' = 'university-outline',
  'EDUCATION_OUTLINE' = 'education-outline',
  'LEARN_OUTLINE' = 'learn-outline',
  'SCISSORS_CUTTING' = 'scissors-cutting',
  'SCOREBOARD' = 'scoreboard',
  'SCOREBOARD_OUTLINE' = 'scoreboard-outline',
  'SCREEN_ROTATION' = 'screen-rotation',
  'SCREEN_ROTATION_LOCK' = 'screen-rotation-lock',
  'SCREEN_LOCK_ROTATION' = 'screen-lock-rotation',
  'SCREW_FLAT_TOP' = 'screw-flat-top',
  'SCREW_LAG' = 'screw-lag',
  'SCREW_MACHINE_FLAT_TOP' = 'screw-machine-flat-top',
  'SCREW_MACHINE_ROUND_TOP' = 'screw-machine-round-top',
  'SCREW_ROUND_TOP' = 'screw-round-top',
  'SCREWDRIVER' = 'screwdriver',
  'SCRIPT' = 'script',
  'SCROLL' = 'scroll',
  'SCRIPT_OUTLINE' = 'script-outline',
  'SCROLL_OUTLINE' = 'scroll-outline',
  'SCRIPT_TEXT' = 'script-text',
  'SCROLL_TEXT' = 'scroll-text',
  'SCRIPT_TEXT_KEY' = 'script-text-key',
  'SCRIPT_TEXT_KEY_OUTLINE' = 'script-text-key-outline',
  'SCRIPT_TEXT_OUTLINE' = 'script-text-outline',
  'SCROLL_TEXT_OUTLINE' = 'scroll-text-outline',
  'SCRIPT_TEXT_PLAY' = 'script-text-play',
  'SCRIPT_TEXT_PLAY_OUTLINE' = 'script-text-play-outline',
  'SD' = 'sd',
  'SD_CARD' = 'sd-card',
  'SD_STORAGE' = 'sd-storage',
  'SEAL_VARIANT' = 'seal-variant',
  'SEARCH_WEB' = 'search-web',
  'SEARCH_GLOBE' = 'search-globe',
  'GLOBAL_SEARCH' = 'global-search',
  'INTERNET_SEARCH' = 'internet-search',
  'SEAT' = 'seat',
  'EVENT_SEAT' = 'event-seat',
  'CHAIR' = 'chair',
  'CHAIR_ACCENT' = 'chair-accent',
  'HOME_THEATER' = 'home-theater',
  'HOME_THEATRE' = 'home-theatre',
  'SEAT_FLAT' = 'seat-flat',
  'AIRLINE_SEAT_FLAT' = 'airline-seat-flat',
  'SEAT_FLAT_ANGLED' = 'seat-flat-angled',
  'AIRLINE_SEAT_FLAT_ANGLED' = 'airline-seat-flat-angled',
  'SEAT_INDIVIDUAL_SUITE' = 'seat-individual-suite',
  'AIRLINE_SEAT_INDIVIDUAL_SUITE' = 'airline-seat-individual-suite',
  'SEAT_LEGROOM_EXTRA' = 'seat-legroom-extra',
  'AIRLINE_SEAT_LEGROOM_EXTRA' = 'airline-seat-legroom-extra',
  'SEAT_LEGROOM_NORMAL' = 'seat-legroom-normal',
  'AIRLINE_SEAT_LEGROOM_NORMAL' = 'airline-seat-legroom-normal',
  'SEAT_LEGROOM_REDUCED' = 'seat-legroom-reduced',
  'AIRLINE_SEAT_LEGROOM_REDUCED' = 'airline-seat-legroom-reduced',
  'SEAT_OUTLINE' = 'seat-outline',
  'EVENT_SEAT_OUTLINE' = 'event-seat-outline',
  'CHAIR_OUTLINE' = 'chair-outline',
  'CHAIR_ACCENT_OUTLINE' = 'chair-accent-outline',
  'SEAT_PASSENGER' = 'seat-passenger',
  'SEAT_RECLINE_EXTRA' = 'seat-recline-extra',
  'AIRLINE_SEAT_RECLINE_EXTRA' = 'airline-seat-recline-extra',
  'SEAT_RECLINE_NORMAL' = 'seat-recline-normal',
  'AIRLINE_SEAT_RECLINE_NORMAL' = 'airline-seat-recline-normal',
  'SEATBELT' = 'seatbelt',
  'SEAT_BELT' = 'seat-belt',
  'SAFETY_BELT' = 'safety-belt',
  'SECURITY' = 'security',
  'SECURITY_NETWORK' = 'security-network',
  'SEED' = 'seed',
  'SEED_OFF' = 'seed-off',
  'SEED_OFF_OUTLINE' = 'seed-off-outline',
  'SEED_OUTLINE' = 'seed-outline',
  'SEESAW' = 'seesaw',
  'PLAYGROUND_SEESAW' = 'playground-seesaw',
  'SEGMENT' = 'segment',
  'SELECT' = 'select',
  'SELECT_ALL' = 'select-all',
  'SELECT_COLOR' = 'select-color',
  'SELECT_COLOUR' = 'select-colour',
  'SELECT_COMPARE' = 'select-compare',
  'SELECT_DRAG' = 'select-drag',
  'SELECT_GROUP' = 'select-group',
  'SELECT_INVERSE' = 'select-inverse',
  'SELECTION_INVERT' = 'selection-invert',
  'SELECT_MARKER' = 'select-marker',
  'SELECT_LOCATION' = 'select-location',
  'SELECT_MULTIPLE' = 'select-multiple',
  'SELECT_MULTIPLE_MARKER' = 'select-multiple-marker',
  'SELECT_MULTIPLE_LOCATION' = 'select-multiple-location',
  'SELECT_OFF' = 'select-off',
  'SELECT_PLACE' = 'select-place',
  'SELECT_REMOVE' = 'select-remove',
  'SELECT_SEARCH' = 'select-search',
  'SELECTION' = 'selection',
  'SELECTION_DRAG' = 'selection-drag',
  'SELECTION_ELLIPSE' = 'selection-ellipse',
  'SELECTION_ELLIPSE_ARROW_INSIDE' = 'selection-ellipse-arrow-inside',
  'SELECTION_ELLIPSE_REMOVE' = 'selection-ellipse-remove',
  'SELECTION_MARKER' = 'selection-marker',
  'SELECTION_LOCATION' = 'selection-location',
  'SELECTION_MULTIPLE' = 'selection-multiple',
  'SELECTION_MULTIPLE_MARKER' = 'selection-multiple-marker',
  'SELECTION_MULTIPLE_LOCATION' = 'selection-multiple-location',
  'SELECTION_OFF' = 'selection-off',
  'SELECTION_REMOVE' = 'selection-remove',
  'SELECTION_SEARCH' = 'selection-search',
  'SEMANTIC_WEB' = 'semantic-web',
  'RDF' = 'rdf',
  'RESOURCE_DESCRIPTION_FRAMEWORK' = 'resource-description-framework',
  'WEB_ONTOLOGY_LANGUAGE' = 'web-ontology-language',
  'W3C' = 'w3c',
  'SEND' = 'send',
  'PAPER_AIRPLANE' = 'paper-airplane',
  'PAPER_PLANE' = 'paper-plane',
  'SEND_CHECK' = 'send-check',
  'SEND_CHECK_OUTLINE' = 'send-check-outline',
  'SEND_CIRCLE' = 'send-circle',
  'SEND_CIRCLE_OUTLINE' = 'send-circle-outline',
  'SEND_CLOCK' = 'send-clock',
  'SEND_CLOCK_OUTLINE' = 'send-clock-outline',
  'SEND_LOCK' = 'send-lock',
  'SEND_SECURE' = 'send-secure',
  'SEND_LOCK_OUTLINE' = 'send-lock-outline',
  'SEND_OUTLINE' = 'send-outline',
  'PAPER_AIRPLANE_OUTLINE' = 'paper-airplane-outline',
  'PAPER_PLANE_OUTLINE' = 'paper-plane-outline',
  'SERIAL_PORT' = 'serial-port',
  'VGA' = 'vga',
  'SERVER' = 'server',
  'SERVER_MINUS' = 'server-minus',
  'SERVER_REMOVE' = 'server-remove',
  'SERVER_NETWORK' = 'server-network',
  'SERVER_NETWORK_OFF' = 'server-network-off',
  'SERVER_OFF' = 'server-off',
  'SERVER_PLUS' = 'server-plus',
  'SERVER_ADD' = 'server-add',
  'SERVER_SECURITY' = 'server-security',
  'SET_ALL' = 'set-all',
  'SET_UNION' = 'set-union',
  'SET_OR' = 'set-or',
  'FULL_OUTER_JOIN' = 'full-outer-join',
  'SQL_FULL_OUTER_JOIN' = 'sql-full-outer-join',
  'SET_CENTER' = 'set-center',
  'SET_CENTRE' = 'set-centre',
  'SET_INTERSECTION' = 'set-intersection',
  'SET_AND' = 'set-and',
  'INNER_JOIN' = 'inner-join',
  'SQL_INNER_JOIN' = 'sql-inner-join',
  'SET_CENTER_RIGHT' = 'set-center-right',
  'SET_CENTRE_RIGHT' = 'set-centre-right',
  'OUTER_JOIN_RIGHT' = 'outer-join-right',
  'SQL_RIGHT_OUTER_JOIN' = 'sql-right-outer-join',
  'SET_LEFT' = 'set-left',
  'DIFFERENCE_LEFT' = 'difference-left',
  'SET_LEFT_CENTER' = 'set-left-center',
  'SET_LEFT_CENTRE' = 'set-left-centre',
  'OUTER_JOIN_LEFT' = 'outer-join-left',
  'SQL_LEFT_OUTER_JOIN' = 'sql-left-outer-join',
  'SET_LEFT_RIGHT' = 'set-left-right',
  'EXCLUSION' = 'exclusion',
  'SET_XOR' = 'set-xor',
  'SET_MERGE' = 'set-merge',
  'SET_NONE' = 'set-none',
  'SET_NULL' = 'set-null',
  'SET_NOT' = 'set-not',
  'VENN_DIAGRAM' = 'venn-diagram',
  'SET_RIGHT' = 'set-right',
  'DIFFERENCE_RIGHT' = 'difference-right',
  'SET_SPLIT' = 'set-split',
  'SET_SQUARE' = 'set-square',
  'SET_TOP_BOX' = 'set-top-box',
  'SETTINGS_HELPER' = 'settings-helper',
  'SHAKER' = 'shaker',
  'FISH_FOOD' = 'fish-food',
  'SHAKER_OUTLINE' = 'shaker-outline',
  'SALT' = 'salt',
  'FISH_FOOD_OUTLINE' = 'fish-food-outline',
  'SHAPE' = 'shape',
  'CATEGORY' = 'category',
  'THEME' = 'theme',
  'SHAPE_CIRCLE_PLUS' = 'shape-circle-plus',
  'SHAPE_CIRCLE_ADD' = 'shape-circle-add',
  'SHAPE_OUTLINE' = 'shape-outline',
  'THEME_OUTLINE' = 'theme-outline',
  'CATEGORY_OUTLINE' = 'category-outline',
  'SHAPE_OVAL_PLUS' = 'shape-oval-plus',
  'SHAPE_PLUS' = 'shape-plus',
  'SHAPE_ADD' = 'shape-add',
  'SHAPE_POLYGON_PLUS' = 'shape-polygon-plus',
  'SHAPE_POLYGON_ADD' = 'shape-polygon-add',
  'SHAPE_RECTANGLE_PLUS' = 'shape-rectangle-plus',
  'SHAPE_RECTANGLE_ADD' = 'shape-rectangle-add',
  'SHAPE_SQUARE_PLUS' = 'shape-square-plus',
  'SHAPE_SQUARE_ADD' = 'shape-square-add',
  'SHAPE_SQUARE_ROUNDED_PLUS' = 'shape-square-rounded-plus',
  'SHARE' = 'share',
  'SHARE_ALL' = 'share-all',
  'SHARE_ALL_OUTLINE' = 'share-all-outline',
  'SHARE_CIRCLE' = 'share-circle',
  'SHARE_OFF' = 'share-off',
  'FORWARD_OFF' = 'forward-off',
  'SHARE_OFF_OUTLINE' = 'share-off-outline',
  'FORWARD_OFF_OUTLINE' = 'forward-off-outline',
  'SHARE_OUTLINE' = 'share-outline',
  'FORWARD_OUTLINE' = 'forward-outline',
  'SHARE_VARIANT' = 'share-variant',
  'SHARE_VARIANT_OUTLINE' = 'share-variant-outline',
  'SHARK' = 'shark',
  'JAWS' = 'jaws',
  'SHARK_FIN' = 'shark-fin',
  'SHARK_FIN_OUTLINE' = 'shark-fin-outline',
  'SHARK_OFF' = 'shark-off',
  'JAWS_OFF' = 'jaws-off',
  'SHEEP' = 'sheep',
  'EMOJI_SHEEP' = 'emoji-sheep',
  'EMOTICON_SHEEP' = 'emoticon-sheep',
  'SHIELD' = 'shield',
  'SHIELD_ACCOUNT' = 'shield-account',
  'SECURITY_ACCOUNT' = 'security-account',
  'SHIELD_USER' = 'shield-user',
  'SHIELD_PERSON' = 'shield-person',
  'ALARM_ARM_HOME' = 'alarm-arm-home',
  'SHIELD_ACCOUNT_OUTLINE' = 'shield-account-outline',
  'SECURITY_ACCOUNT_OUTLINE' = 'security-account-outline',
  'SHIELD_USER_OUTLINE' = 'shield-user-outline',
  'SHIELD_PERSON_OUTLINE' = 'shield-person-outline',
  'ALARM_ARM_HOME_OUTLINE' = 'alarm-arm-home-outline',
  'SHIELD_ACCOUNT_VARIANT' = 'shield-account-variant',
  'SHIELD_ACCOUNT_VARIANT_OUTLINE' = 'shield-account-variant-outline',
  'SHIELD_AIRPLANE' = 'shield-airplane',
  'SHIELD_AEROPLANE' = 'shield-aeroplane',
  'SHIELD_PLANE' = 'shield-plane',
  'PLANE_SHIELD' = 'plane-shield',
  'SHIELD_AIRPLANE_OUTLINE' = 'shield-airplane-outline',
  'SHIELD_AEROPLANE_OUTLINE' = 'shield-aeroplane-outline',
  'SHIELD_PLANE_OUTLINE' = 'shield-plane-outline',
  'SHIELD_ALERT' = 'shield-alert',
  'SHIELD_WARNING' = 'shield-warning',
  'SHIELD_ALERT_OUTLINE' = 'shield-alert-outline',
  'SHIELD_WARNING_OUTLINE' = 'shield-warning-outline',
  'SHIELD_BUG' = 'shield-bug',
  'ANTIVIRUS' = 'antivirus',
  'SHIELD_BUG_OUTLINE' = 'shield-bug-outline',
  'ANTIVIRUS_OUTLINE' = 'antivirus-outline',
  'SHIELD_CAR' = 'shield-car',
  'CAR_SECURITY' = 'car-security',
  'CAR_INSURANCE' = 'car-insurance',
  'SHIELD_CHECK' = 'shield-check',
  'VERIFIED_USER' = 'verified-user',
  'SHIELD_TICK' = 'shield-tick',
  'SHIELD_CHECK_OUTLINE' = 'shield-check-outline',
  'SHIELD_TICK_OUTLINE' = 'shield-tick-outline',
  'SHIELD_CROSS' = 'shield-cross',
  'SHIELD_TEMPLAR' = 'shield-templar',
  'SHIELD_CHRISTIANITY' = 'shield-christianity',
  'SHIELD_CROSS_OUTLINE' = 'shield-cross-outline',
  'SHIELD_TEMPLAR_OUTLINE' = 'shield-templar-outline',
  'SHIELD_CHRISTIANITY_OUTLINE' = 'shield-christianity-outline',
  'SHIELD_CROWN' = 'shield-crown',
  'ADMINISTRATOR' = 'administrator',
  'SHIELD_CROWN_OUTLINE' = 'shield-crown-outline',
  'ADMINISTRATOR_OUTLINE' = 'administrator-outline',
  'SHIELD_EDIT' = 'shield-edit',
  'SHIELD_EDIT_OUTLINE' = 'shield-edit-outline',
  'SHIELD_HALF' = 'shield-half',
  'SHIELD_HALF_FULL' = 'shield-half-full',
  'SHIELD_HOME' = 'shield-home',
  'SECURITY_HOME' = 'security-home',
  'SHIELD_HOUSE' = 'shield-house',
  'SHIELD_HOME_OUTLINE' = 'shield-home-outline',
  'SHIELD_HOUSE_OUTLINE' = 'shield-house-outline',
  'SHIELD_KEY' = 'shield-key',
  'SHIELD_KEY_OUTLINE' = 'shield-key-outline',
  'SHIELD_LINK_VARIANT' = 'shield-link-variant',
  'SHIELD_LINK_VARIANT_OUTLINE' = 'shield-link-variant-outline',
  'SHIELD_LOCK' = 'shield-lock',
  'SECURITY_LOCK' = 'security-lock',
  'ALARM_ARM_AWAY' = 'alarm-arm-away',
  'SHIELD_LOCK_OPEN' = 'shield-lock-open',
  'SHIELD_UNLOCKED' = 'shield-unlocked',
  'SHIELD_LOCK_OPEN_OUTLINE' = 'shield-lock-open-outline',
  'SHIELD_UNLOCKED_OUTLINE' = 'shield-unlocked-outline',
  'SHIELD_LOCK_OUTLINE' = 'shield-lock-outline',
  'ALARM_ARM_AWAY_OUTLINE' = 'alarm-arm-away-outline',
  'SECURITY_LOCK_OUTLINE' = 'security-lock-outline',
  'SHIELD_MOON' = 'shield-moon',
  'ALARM_ARM_NIGHT' = 'alarm-arm-night',
  'SHIELD_MOON_OUTLINE' = 'shield-moon-outline',
  'ALARM_ARM_NIGHT_OUTLINE' = 'alarm-arm-night-outline',
  'SHIELD_OFF' = 'shield-off',
  'SECURITY_OFF' = 'security-off',
  'SHIELD_OFF_OUTLINE' = 'shield-off-outline',
  'SHIELD_OUTLINE' = 'shield-outline',
  'SHIELD_PLUS' = 'shield-plus',
  'SHIELD_ADD' = 'shield-add',
  'SHIELD_PLUS_OUTLINE' = 'shield-plus-outline',
  'SHIELD_ADD_OUTLINE' = 'shield-add-outline',
  'SHIELD_REFRESH' = 'shield-refresh',
  'SHIELD_REFRESH_OUTLINE' = 'shield-refresh-outline',
  'SHIELD_REMOVE' = 'shield-remove',
  'SHIELD_REMOVE_OUTLINE' = 'shield-remove-outline',
  'SHIELD_SEARCH' = 'shield-search',
  'SHIELD_STAR' = 'shield-star',
  'BADGE' = 'badge',
  'SHIELD_FAVORITE' = 'shield-favorite',
  'SHIELD_STAR_OUTLINE' = 'shield-star-outline',
  'BADGE_OUTLINE' = 'badge-outline',
  'SHIELD_FAVORITE_OUTLINE' = 'shield-favorite-outline',
  'SHIELD_SUN' = 'shield-sun',
  'SUN_PROTECTION' = 'sun-protection',
  'SHIELD_SUN_OUTLINE' = 'shield-sun-outline',
  'SUN_PROTECTION_OUTLINE' = 'sun-protection-outline',
  'SHIELD_SWORD' = 'shield-sword',
  'MODERATOR' = 'moderator',
  'SHIELD_SWORD_OUTLINE' = 'shield-sword-outline',
  'MODERATOR_OUTLINE' = 'moderator-outline',
  'SHIELD_SYNC' = 'shield-sync',
  'SHIELD_SYNC_OUTLINE' = 'shield-sync-outline',
  'SHIMMER' = 'shimmer',
  'SPARKLES' = 'sparkles',
  'SHIP_WHEEL' = 'ship-wheel',
  'VOYAGER' = 'voyager',
  'HELM' = 'helm',
  'SHIPPING_PALLET' = 'shipping-pallet',
  'SHOE_BALLET' = 'shoe-ballet',
  'SLIPPERS_BALLET' = 'slippers-ballet',
  'SHOE_CLEAT' = 'shoe-cleat',
  'SHOE_FORMAL' = 'shoe-formal',
  'SHOE_HEEL' = 'shoe-heel',
  'SHOE_PRINT' = 'shoe-print',
  'FOOTPRINTS' = 'footprints',
  'SHOE_SNEAKER' = 'shoe-sneaker',
  'SHOE_RUNNING' = 'shoe-running',
  'SHOPPING' = 'shopping',
  'LOCAL_MALL' = 'local-mall',
  'MARKETPLACE' = 'marketplace',
  'SHOPPING_MUSIC' = 'shopping-music',
  'SHOPPING_OUTLINE' = 'shopping-outline',
  'LOCAL_MALL_OUTLINE' = 'local-mall-outline',
  'MARKETPLACE_OUTLINE' = 'marketplace-outline',
  'SHOPPING_SEARCH' = 'shopping-search',
  'SHORE' = 'shore',
  'SHOVEL' = 'shovel',
  'GARDENING' = 'gardening',
  'SHOVEL_OFF' = 'shovel-off',
  'SHOWER' = 'shower',
  'BATHROOM' = 'bathroom',
  'SHOWER_HEAD' = 'shower-head',
  'SHREDDER' = 'shredder',
  'SHUFFLE' = 'shuffle',
  'SHUFFLE_DISABLED' = 'shuffle-disabled',
  'SHUFFLE_VARIANT' = 'shuffle-variant',
  'SHURIKEN' = 'shuriken',
  'SICKLE' = 'sickle',
  'SIGMA' = 'sigma',
  'SUMMATION' = 'summation',
  'SIGMA_LOWER' = 'sigma-lower',
  'SIGN_CAUTION' = 'sign-caution',
  'SIGN_DIRECTION' = 'sign-direction',
  'SIGN_DIRECTION_MINUS' = 'sign-direction-minus',
  'MILESTONE_MINUS' = 'milestone-minus',
  'SIGN_DIRECTION_PLUS' = 'sign-direction-plus',
  'MILESTONE_PLUS' = 'milestone-plus',
  'SIGN_DIRECTION_ADD' = 'sign-direction-add',
  'MILESTONE_ADD' = 'milestone-add',
  'SIGN_DIRECTION_REMOVE' = 'sign-direction-remove',
  'MILESTONE_REMOVE' = 'milestone-remove',
  'SIGN_POLE' = 'sign-pole',
  'SIGN_REAL_ESTATE' = 'sign-real-estate',
  'SIGN_TEXT' = 'sign-text',
  'SIGNAL2G' = 'signal2g',
  'SIGNAL3G' = 'signal3g',
  'SIGNAL4G' = 'signal4g',
  'SIGNAL5G' = 'signal5g',
  'SIGNAL_CELLULAR1' = 'signal-cellular1',
  'SIGNAL_CELLULAR2' = 'signal-cellular2',
  'SIGNAL_CELLULAR3' = 'signal-cellular3',
  'SIGNAL_CELLULAR_OUTLINE' = 'signal-cellular-outline',
  'SIGNAL_CELLULAR_0' = 'signal-cellular-0',
  'SIGNAL_DISTANCE_VARIANT' = 'signal-distance-variant',
  'SIGNAL_HSPA' = 'signal-hspa',
  'SIGNAL_HSPA_PLUS' = 'signal-hspa-plus',
  'SIGNAL_VARIANT' = 'signal-variant',
  'SIGNATURE_FREEHAND' = 'signature-freehand',
  'SIGNATURE_IMAGE' = 'signature-image',
  'SIGNATURE_TEXT' = 'signature-text',
  'SILO' = 'silo',
  'SILVERWARE' = 'silverware',
  'LOCAL_DINING' = 'local-dining',
  'RESTAURANT_MENU' = 'restaurant-menu',
  'LOCAL_RESTAURANT' = 'local-restaurant',
  'CUTLERY' = 'cutlery',
  'SILVERWARE_CLEAN' = 'silverware-clean',
  'SILVERWARE_SHIMMER' = 'silverware-shimmer',
  'CUTLERY_CLEAN' = 'cutlery-clean',
  'SILVERWARE_FORK' = 'silverware-fork',
  'CUTLERY_FORK' = 'cutlery-fork',
  'SILVERWARE_FORK_KNIFE' = 'silverware-fork-knife',
  'RESTAURANT' = 'restaurant',
  'FORTNITE' = 'fortnite',
  'CUTLERY_FORK_KNIFE' = 'cutlery-fork-knife',
  'PLACE_SETTING' = 'place-setting',
  'SILVERWARE_SPOON' = 'silverware-spoon',
  'CUTLERY_SPOON' = 'cutlery-spoon',
  'SILVERWARE_VARIANT' = 'silverware-variant',
  'CUTLERY_VARIANT' = 'cutlery-variant',
  'SIM' = 'sim',
  'SIM_CARD' = 'sim-card',
  'SUBSCRIBER_IDENTITY_MODULE' = 'subscriber-identity-module',
  'SUBSCRIBER_IDENTIFICATION_MODULE' = 'subscriber-identification-module',
  'SIM_ALERT' = 'sim-alert',
  'SIM_WARNING' = 'sim-warning',
  'SIM_CARD_ALERT' = 'sim-card-alert',
  'SIM_ALERT_OUTLINE' = 'sim-alert-outline',
  'SIM_OFF' = 'sim-off',
  'SIGNAL_CELLULAR_NO_SIM' = 'signal-cellular-no-sim',
  'SIM_OFF_OUTLINE' = 'sim-off-outline',
  'SIM_OUTLINE' = 'sim-outline',
  'SIM_CARD_OUTLINE' = 'sim-card-outline',
  'SUBSCRIBER_IDENTITY_MODULE_OUTLINE' = 'subscriber-identity-module-outline',
  'SUBSCRIBER_IDENTIFICATION_MODULE_OUTLINE' = 'subscriber-identification-module-outline',
  'SIMPLE_ICONS' = 'simple-icons',
  'SINA_WEIBO' = 'sina-weibo',
  'SINE_WAVE' = 'sine-wave',
  'WAVE' = 'wave',
  'ANALOG' = 'analog',
  'SITEMAP' = 'sitemap',
  'WORKFLOW' = 'workflow',
  'FLOWCHART' = 'flowchart',
  'SITEMAP_OUTLINE' = 'sitemap-outline',
  'WORKFLOW_OUTLINE' = 'workflow-outline',
  'FLOWCHART_OUTLINE' = 'flowchart-outline',
  'SIZE_L' = 'size-l',
  'SIZE_LARGE' = 'size-large',
  'SIZE_M' = 'size-m',
  'SIZE_MEDIUM' = 'size-medium',
  'SIZE_S' = 'size-s',
  'SIZE_SMALL' = 'size-small',
  'SIZE_XL' = 'size-xl',
  'SIZE_EXTRA_LARGE' = 'size-extra-large',
  'SIZE_XS' = 'size-xs',
  'SIZE_EXTRA_SMALL' = 'size-extra-small',
  'SIZE_XXL' = 'size-xxl',
  'SIZE_EXTRA_EXTRA_LARGE' = 'size-extra-extra-large',
  'SIZE_XXS' = 'size-xxs',
  'SIZE_EXTRA_EXTRA_SMALL' = 'size-extra-extra-small',
  'SIZE_XXXL' = 'size-xxxl',
  'SKATE' = 'skate',
  'ICE_SKATE' = 'ice-skate',
  'SKATE_OFF' = 'skate-off',
  'SKATEBOARD' = 'skateboard',
  'SKATEBOARDING' = 'skateboarding',
  'HUMAN_SKATEBOARDING' = 'human-skateboarding',
  'SKEW_LESS' = 'skew-less',
  'SKEW_DECREASE' = 'skew-decrease',
  'SKEW_MORE' = 'skew-more',
  'SKEW_INCREASE' = 'skew-increase',
  'SKI' = 'ski',
  'HUMAN_SKI' = 'human-ski',
  'SKI_CROSS_COUNTRY' = 'ski-cross-country',
  'NORDIC_WALKING' = 'nordic-walking',
  'HUMAN_SKI_CROSS_COUNTRY' = 'human-ski-cross-country',
  'SKI_WATER' = 'ski-water',
  'HUMAN_SKI_WATER' = 'human-ski-water',
  'SKIP_BACKWARD' = 'skip-backward',
  'TITLE_BACKWARD' = 'title-backward',
  'PREVIOUS_TITLE' = 'previous-title',
  'SKIP_BACKWARD_OUTLINE' = 'skip-backward-outline',
  'SKIP_FORWARD' = 'skip-forward',
  'TITLE_FORWARD' = 'title-forward',
  'NEXT_TITLE' = 'next-title',
  'SKIP_FORWARD_OUTLINE' = 'skip-forward-outline',
  'SKIP_NEXT' = 'skip-next',
  'SKIP_NEXT_CIRCLE' = 'skip-next-circle',
  'SKIP_NEXT_CIRCLE_OUTLINE' = 'skip-next-circle-outline',
  'SKIP_NEXT_OUTLINE' = 'skip-next-outline',
  'SKIP_PREVIOUS' = 'skip-previous',
  'SKIP_PREVIOUS_CIRCLE' = 'skip-previous-circle',
  'SKIP_PREVIOUS_CIRCLE_OUTLINE' = 'skip-previous-circle-outline',
  'SKIP_PREVIOUS_OUTLINE' = 'skip-previous-outline',
  'SKULL' = 'skull',
  'SKULL_CROSSBONES' = 'skull-crossbones',
  'JOLLY_ROGER' = 'jolly-roger',
  'SKULL_CROSSBONES_OUTLINE' = 'skull-crossbones-outline',
  'JOLLY_ROGER_OUTLINE' = 'jolly-roger-outline',
  'SKULL_OUTLINE' = 'skull-outline',
  'SKULL_SCAN' = 'skull-scan',
  'X_RAY' = 'x-ray',
  'RADIOLOGY' = 'radiology',
  'SKULL_SCAN_OUTLINE' = 'skull-scan-outline',
  'X_RAY_OUTLINE' = 'x-ray-outline',
  'RADIOLOGY_OUTLINE' = 'radiology-outline',
  'SKYPE' = 'skype',
  'MICROSOFT_SKYPE' = 'microsoft-skype',
  'SKYPE_BUSINESS' = 'skype-business',
  'SLACK' = 'slack',
  'SLASH_FORWARD' = 'slash-forward',
  'DIVIDE' = 'divide',
  'SLASH_FORWARD_BOX' = 'slash-forward-box',
  'DIVIDE_BOX' = 'divide-box',
  'SLEDDING' = 'sledding',
  'HUMAN_SLEDDING' = 'human-sledding',
  'SLEEP' = 'sleep',
  'SLEEP_OFF' = 'sleep-off',
  'SLIDE' = 'slide',
  'PLAYGROUND_SLIDE' = 'playground-slide',
  'SLOPE_DOWNHILL' = 'slope-downhill',
  'SLOPE_UPHILL' = 'slope-uphill',
  'SLOT_MACHINE' = 'slot-machine',
  'CASINO' = 'casino',
  'GAMBLING' = 'gambling',
  'SLOT_MACHINE_OUTLINE' = 'slot-machine-outline',
  'CASINO_OUTLINE' = 'casino-outline',
  'GAMBLING_OUTLINE' = 'gambling-outline',
  'SMART_CARD' = 'smart-card',
  'SMART_CARD_OFF' = 'smart-card-off',
  'SMART_CARD_OFF_OUTLINE' = 'smart-card-off-outline',
  'SMART_CARD_OUTLINE' = 'smart-card-outline',
  'SMART_CARD_READER' = 'smart-card-reader',
  'SMART_CARD_READER_OUTLINE' = 'smart-card-reader-outline',
  'SMOG' = 'smog',
  'SMOKE' = 'smoke',
  'SMOKE_DETECTOR' = 'smoke-detector',
  'NEST_PROTECT' = 'nest-protect',
  'SUBWOOFER' = 'subwoofer',
  'SMOKE_DETECTOR_ALERT' = 'smoke-detector-alert',
  'SMOKE_DETECTOR_ALERT_OUTLINE' = 'smoke-detector-alert-outline',
  'SMOKE_DETECTOR_OFF' = 'smoke-detector-off',
  'SMOKE_DETECTOR_OFF_OUTLINE' = 'smoke-detector-off-outline',
  'SMOKE_DETECTOR_OUTLINE' = 'smoke-detector-outline',
  'SMOKE_DETECTOR_VARIANT' = 'smoke-detector-variant',
  'SMOKE_DETECTOR_VARIANT_ALERT' = 'smoke-detector-variant-alert',
  'SMOKE_DETECTOR_VARIANT_OFF' = 'smoke-detector-variant-off',
  'SMOKING' = 'smoking',
  'CIGARETTE' = 'cigarette',
  'SMOKING_AREA' = 'smoking-area',
  'SMOKING_ROOMS' = 'smoking-rooms',
  'SMOKING_OFF' = 'smoking-off',
  'NO_SMOKING' = 'no-smoking',
  'CIGARETTE_OFF' = 'cigarette-off',
  'SMOKE_FREE' = 'smoke-free',
  'SMOKING_PIPE' = 'smoking-pipe',
  'SMOKING_PIPE_OFF' = 'smoking-pipe-off',
  'SNAIL' = 'snail',
  'GASTROPOD' = 'gastropod',
  'SNAKE' = 'snake',
  'REPTILE' = 'reptile',
  'SNAPCHAT' = 'snapchat',
  'SNOWBOARD' = 'snowboard',
  'HUMAN_SNOWBOARD' = 'human-snowboard',
  'SNOWFLAKE' = 'snowflake',
  'SNOWFLAKE_ALERT' = 'snowflake-alert',
  'COLD_ALERT' = 'cold-alert',
  'SNOW_ADVISORY' = 'snow-advisory',
  'FREEZE_ADVISORY' = 'freeze-advisory',
  'SNOWFLAKE_MELT' = 'snowflake-melt',
  'DEFROST' = 'defrost',
  'SNOWFLAKE_OFF' = 'snowflake-off',
  'SNOWFLAKE_VARIANT' = 'snowflake-variant',
  'SNOWMAN' = 'snowman',
  'SNOWMOBILE' = 'snowmobile',
  'SOCCER' = 'soccer',
  'SOCCER_FIELD' = 'soccer-field',
  'FOOTBALL_PITCH' = 'football-pitch',
  'SOCIAL_DISTANCE2METERS' = 'social-distance2meters',
  'SOCIAL_DISTANCE6FEET' = 'social-distance6feet',
  'SOFA' = 'sofa',
  'COUCH' = 'couch',
  'LIVING_ROOM' = 'living-room',
  'FAMILY_ROOM' = 'family-room',
  'SOFA_OUTLINE' = 'sofa-outline',
  'COUCH_OUTLINE' = 'couch-outline',
  'LIVING_ROOM_OUTLINE' = 'living-room-outline',
  'FAMILY_ROOM_OUTLINE' = 'family-room-outline',
  'SOFA_SINGLE' = 'sofa-single',
  'LOVESEAT' = 'loveseat',
  'LOVE_SEAT' = 'love-seat',
  'SOFA_SINGLE_OUTLINE' = 'sofa-single-outline',
  'LOVESEAT_OUTLINE' = 'loveseat-outline',
  'LOVE_SEAT_OUTLINE' = 'love-seat-outline',
  'SOLAR_PANEL' = 'solar-panel',
  'SOLAR_ENERGY' = 'solar-energy',
  'SOLAR_ELECTRICITY' = 'solar-electricity',
  'SOLAR_PANEL_LARGE' = 'solar-panel-large',
  'SOLAR_PANEL_ENERGY' = 'solar-panel-energy',
  'SOLAR_PANEL_ELECTRICITY' = 'solar-panel-electricity',
  'SOLAR_POWER' = 'solar-power',
  'SOLDERING_IRON' = 'soldering-iron',
  'SOLID' = 'solid',
  'SONY_PLAYSTATION' = 'sony-playstation',
  'PLAYSTATION_NETWORK' = 'playstation-network',
  'SORT' = 'sort',
  'SORT_ALPHABETICAL_ASCENDING' = 'sort-alphabetical-ascending',
  'SORT_ALPHABETICAL_ASCENDING_VARIANT' = 'sort-alphabetical-ascending-variant',
  'SORT_ALPHABETICAL_DESCENDING' = 'sort-alphabetical-descending',
  'SORT_ALPHABETICAL_DESCENDING_VARIANT' = 'sort-alphabetical-descending-variant',
  'SORT_ALPHABETICAL_VARIANT' = 'sort-alphabetical-variant',
  'SORT_BY_ALPHA' = 'sort-by-alpha',
  'SORT_ALPHABETICALLY' = 'sort-alphabetically',
  'SORT_ASCENDING' = 'sort-ascending',
  'SORT_BOOL_ASCENDING' = 'sort-bool-ascending',
  'SORT_BOOL_ASCENDING_VARIANT' = 'sort-bool-ascending-variant',
  'SORT_CHECKBOX_ASCENDING' = 'sort-checkbox-ascending',
  'SORT_BOOL_DESCENDING' = 'sort-bool-descending',
  'SORT_BOOL_DESCENDING_VARIANT' = 'sort-bool-descending-variant',
  'SORT_CHECKBOX_DESCENDING' = 'sort-checkbox-descending',
  'SORT_CALENDAR_ASCENDING' = 'sort-calendar-ascending',
  'SORT_DATE_ASCENDING' = 'sort-date-ascending',
  'SORT_CALENDAR_DESCENDING' = 'sort-calendar-descending',
  'SORT_DATE_DESCENDING' = 'sort-date-descending',
  'SORT_CLOCK_ASCENDING' = 'sort-clock-ascending',
  'SORT_TIME_ASCENDING' = 'sort-time-ascending',
  'SORT_CLOCK_ASCENDING_OUTLINE' = 'sort-clock-ascending-outline',
  'SORT_TIME_ASCENDING_OUTLINE' = 'sort-time-ascending-outline',
  'SORT_CLOCK_DESCENDING' = 'sort-clock-descending',
  'SORT_TIME_DESCENDING' = 'sort-time-descending',
  'SORT_CLOCK_DESCENDING_OUTLINE' = 'sort-clock-descending-outline',
  'SORT_TIME_DESCENDING_OUTLINE' = 'sort-time-descending-outline',
  'SORT_DESCENDING' = 'sort-descending',
  'SORT_NUMERIC_ASCENDING' = 'sort-numeric-ascending',
  'SORT_NUMERIC_ASCENDING_VARIANT' = 'sort-numeric-ascending-variant',
  'SORT_NUMERIC_DESCENDING' = 'sort-numeric-descending',
  'SORT_NUMERIC_DESCENDING_VARIANT' = 'sort-numeric-descending-variant',
  'SORT_NUMERIC_VARIANT' = 'sort-numeric-variant',
  'SORT_NUMERICALLY' = 'sort-numerically',
  'SORT_REVERSE_VARIANT' = 'sort-reverse-variant',
  'SORT_VARIANT' = 'sort-variant',
  'SORT_VARIANT_LOCK' = 'sort-variant-lock',
  'SORT_VARIANT_LOCK_OPEN' = 'sort-variant-lock-open',
  'SORT_VARIANT_REMOVE' = 'sort-variant-remove',
  'SOUNDBAR' = 'soundbar',
  'SPEAKER_BAR' = 'speaker-bar',
  'SOUNDCLOUD' = 'soundcloud',
  'SOURCE_BRANCH' = 'source-branch',
  'SOURCE_BRANCH_CHECK' = 'source-branch-check',
  'SOURCE_BRANCH_MINUS' = 'source-branch-minus',
  'SOURCE_BRANCH_PLUS' = 'source-branch-plus',
  'SOURCE_BRANCH_REFRESH' = 'source-branch-refresh',
  'SOURCE_BRANCH_REMOVE' = 'source-branch-remove',
  'SOURCE_BRANCH_SYNC' = 'source-branch-sync',
  'SOURCE_COMMIT' = 'source-commit',
  'SOURCE_COMMIT_END' = 'source-commit-end',
  'SOURCE_COMMIT_END_LOCAL' = 'source-commit-end-local',
  'SOURCE_COMMIT_LOCAL' = 'source-commit-local',
  'SOURCE_COMMIT_NEXT_LOCAL' = 'source-commit-next-local',
  'SOURCE_COMMIT_START' = 'source-commit-start',
  'SOURCE_COMMIT_START_NEXT_LOCAL' = 'source-commit-start-next-local',
  'SOURCE_FORK' = 'source-fork',
  'SOURCE_MERGE' = 'source-merge',
  'SOURCE_PULL' = 'source-pull',
  'SOURCE_REPOSITORY' = 'source-repository',
  'SOURCE_REPOSITORY_MULTIPLE' = 'source-repository-multiple',
  'SOURCE_REPOSITORIES' = 'source-repositories',
  'SOY_SAUCE' = 'soy-sauce',
  'SOYA_SAUCE' = 'soya-sauce',
  'SOY_SAUCE_OFF' = 'soy-sauce-off',
  'SPA' = 'spa',
  'FLOWER_LOTUS' = 'flower-lotus',
  'SPA_OUTLINE' = 'spa-outline',
  'FLOWER_LOTUS_OUTLINE' = 'flower-lotus-outline',
  'SPACE_INVADERS' = 'space-invaders',
  'SPACE_STATION' = 'space-station',
  'SPADE' = 'spade',
  'SPEAKER' = 'speaker',
  'SPEAKER_BLUETOOTH' = 'speaker-bluetooth',
  'SPEAKER_MULTIPLE' = 'speaker-multiple',
  'SPEAKERS' = 'speakers',
  'SPEAKER_OFF' = 'speaker-off',
  'SPEAKER_WIRELESS' = 'speaker-wireless',
  'SPEAR' = 'spear',
  'STAFF' = 'staff',
  'FISHING' = 'fishing',
  'SPEEDOMETER' = 'speedometer',
  'SPEEDOMETER_MEDIUM' = 'speedometer-medium',
  'SPEEDOMETER_SLOW' = 'speedometer-slow',
  'SPELLCHECK' = 'spellcheck',
  'SPHERE' = 'sphere',
  'SPHERE_OFF' = 'sphere-off',
  'SPIDER' = 'spider',
  'ARACHNID' = 'arachnid',
  'SPIDER_THREAD' = 'spider-thread',
  'ARACHNID_THREAD' = 'arachnid-thread',
  'SPIDER_WEB' = 'spider-web',
  'COBWEB' = 'cobweb',
  'ARACHNID_WEB' = 'arachnid-web',
  'SPIRIT_LEVEL' = 'spirit-level',
  'SPOON_SUGAR' = 'spoon-sugar',
  'SPOTIFY' = 'spotify',
  'SPOTLIGHT' = 'spotlight',
  'SPOTLIGHT_BEAM' = 'spotlight-beam',
  'SPRAY' = 'spray',
  'AEROSOL' = 'aerosol',
  'SPRAY_BOTTLE' = 'spray-bottle',
  'CLEANING' = 'cleaning',
  'SPRINKLER' = 'sprinkler',
  'IRRIGATION' = 'irrigation',
  'SPRINKLER_FIRE' = 'sprinkler-fire',
  'SPRINKLER_MIST' = 'sprinkler-mist',
  'MISTER' = 'mister',
  'SPRINKLER_HEAD' = 'sprinkler-head',
  'SPRINKLER_VARIANT' = 'sprinkler-variant',
  'SPROUT' = 'sprout',
  'SEEDLING' = 'seedling',
  'SPROUT_OUTLINE' = 'sprout-outline',
  'SEEDLING_OUTLINE' = 'seedling-outline',
  'PLANT_OUTLINE' = 'plant-outline',
  'SQUARE_CIRCLE' = 'square-circle',
  'VEGETARIAN' = 'vegetarian',
  'LACTO_VEGETARIAN' = 'lacto-vegetarian',
  'SQUARE_EDIT_OUTLINE' = 'square-edit-outline',
  'SQUARE_MEDIUM' = 'square-medium',
  'SQUARE_MEDIUM_OUTLINE' = 'square-medium-outline',
  'SQUARE_OFF' = 'square-off',
  'SQUARE_OFF_OUTLINE' = 'square-off-outline',
  'SQUARE_OPACITY' = 'square-opacity',
  'SQUARE_TRANSPARENT' = 'square-transparent',
  'SQUARE_OUTLINE' = 'square-outline',
  'SQUARE_ROOT' = 'square-root',
  'SQUARE_ROOT_BOX' = 'square-root-box',
  'SQUARE_ROUNDED' = 'square-rounded',
  'SQUARE_ROUNDED_BADGE' = 'square-rounded-badge',
  'PUSH_NOTIFICATION' = 'push-notification',
  'SQUARE_ROUNDED_BADGE_OUTLINE' = 'square-rounded-badge-outline',
  'PUSH_NOTIFICATION_OUTLINE' = 'push-notification-outline',
  'SQUARE_ROUNDED_OUTLINE' = 'square-rounded-outline',
  'SQUARE_SMALL' = 'square-small',
  'SQUARE_WAVE' = 'square-wave',
  'SQUEEGEE' = 'squeegee',
  'SSH' = 'ssh',
  'STACK_EXCHANGE' = 'stack-exchange',
  'STACKEXCHANGE' = 'stackexchange',
  'STACK_OVERFLOW' = 'stack-overflow',
  'STACKOVERFLOW' = 'stackoverflow',
  'STACKPATH' = 'stackpath',
  'STADIUM' = 'stadium',
  'ARENA' = 'arena',
  'STADIUM_VARIANT' = 'stadium-variant',
  'STAIRS' = 'stairs',
  'STAIRS_BOX' = 'stairs-box',
  'STAIRS_DOWN' = 'stairs-down',
  'STAIRS_UP' = 'stairs-up',
  'STAMPER' = 'stamper',
  'STANDARD_DEFINITION' = 'standard-definition',
  'GRADE' = 'grade',
  'STAR_RATE' = 'star-rate',
  'STAR_BOX' = 'star-box',
  'FAVORITE_BOX' = 'favorite-box',
  'STAR_BOX_MULTIPLE' = 'star-box-multiple',
  'FAVORITE_BOX_MULTIPLE' = 'favorite-box-multiple',
  'STAR_BOX_MULTIPLE_OUTLINE' = 'star-box-multiple-outline',
  'FAVORITE_BOX_MULTIPLE_OUTLINE' = 'favorite-box-multiple-outline',
  'STAR_BOX_OUTLINE' = 'star-box-outline',
  'FAVORITE_BOX_OUTLINE' = 'favorite-box-outline',
  'STAR_CHECK' = 'star-check',
  'FAVORITE_CHECK' = 'favorite-check',
  'STAR_CHECK_OUTLINE' = 'star-check-outline',
  'FAVORITE_CHECK_OUTLINE' = 'favorite-check-outline',
  'STAR_CIRCLE' = 'star-circle',
  'STARS' = 'stars',
  'FAVORITE_CIRCLE' = 'favorite-circle',
  'STAR_CIRCLE_OUTLINE' = 'star-circle-outline',
  'FEATURE_HIGHLIGHT' = 'feature-highlight',
  'FAVORITE_CIRCLE_OUTLINE' = 'favorite-circle-outline',
  'STAR_COG' = 'star-cog',
  'FAVORITE_COG' = 'favorite-cog',
  'STAR_COG_OUTLINE' = 'star-cog-outline',
  'FAVORITE_COG_OUTLINE' = 'favorite-cog-outline',
  'STAR_CRESCENT' = 'star-crescent',
  'RELIGION_ISLAMIC' = 'religion-islamic',
  'RELIGION_MUSLIM' = 'religion-muslim',
  'STAR_DAVID' = 'star-david',
  'JEWISH' = 'jewish',
  'RELIGION_JUDAIC' = 'religion-judaic',
  'JUDAISM' = 'judaism',
  'MAGEN_DAVID' = 'magen-david',
  'STAR_FACE' = 'star-face',
  'FAVORITE_FACE' = 'favorite-face',
  'EMOJI_STAR' = 'emoji-star',
  'EMOTICON_STAR' = 'emoticon-star',
  'STAR_FOUR_POINTS' = 'star-four-points',
  'STAR_FOUR_POINTS_OUTLINE' = 'star-four-points-outline',
  'STAR_HALF' = 'star-half',
  'FAVORITE_HALF' = 'favorite-half',
  'STAR_HALF_FULL' = 'star-half-full',
  'FAVORITE_HALF_FULL' = 'favorite-half-full',
  'STAR_MINUS' = 'star-minus',
  'FAVORITE_MINUS' = 'favorite-minus',
  'STAR_MINUS_OUTLINE' = 'star-minus-outline',
  'FAVORITE_MINUS_OUTLINE' = 'favorite-minus-outline',
  'STAR_OFF' = 'star-off',
  'FAVORITE_OFF' = 'favorite-off',
  'STAR_OFF_OUTLINE' = 'star-off-outline',
  'FAVORITE_OFF_OUTLINE' = 'favorite-off-outline',
  'STAR_BORDER' = 'star-border',
  'STAR_PLUS' = 'star-plus',
  'FAVORITE_PLUS' = 'favorite-plus',
  'STAR_ADD' = 'star-add',
  'FAVORITE_ADD' = 'favorite-add',
  'STAR_PLUS_OUTLINE' = 'star-plus-outline',
  'STAR_ADD_OUTLINE' = 'star-add-outline',
  'FAVORITE_PLUS_OUTLINE' = 'favorite-plus-outline',
  'FAVORITE_ADD_OUTLINE' = 'favorite-add-outline',
  'STAR_REMOVE' = 'star-remove',
  'FAVORITE_REMOVE' = 'favorite-remove',
  'STAR_REMOVE_OUTLINE' = 'star-remove-outline',
  'FAVORITE_REMOVE_OUTLINE' = 'favorite-remove-outline',
  'STAR_SETTINGS' = 'star-settings',
  'FAVORITE_SETTINGS' = 'favorite-settings',
  'STAR_SETTINGS_OUTLINE' = 'star-settings-outline',
  'FAVORITE_SETTINGS_OUTLINE' = 'favorite-settings-outline',
  'STAR_SHOOTING' = 'star-shooting',
  'FAVORITE_SHOOTING' = 'favorite-shooting',
  'STAR_SHOOTING_OUTLINE' = 'star-shooting-outline',
  'FAVORITE_SHOOTING_OUTLINE' = 'favorite-shooting-outline',
  'STAR_THREE_POINTS' = 'star-three-points',
  'STAR_THREE_POINTS_OUTLINE' = 'star-three-points-outline',
  'STATE_MACHINE' = 'state-machine',
  'STEAM' = 'steam',
  'STEERING' = 'steering',
  'SEARCH_HANDS_FREE' = 'search-hands-free',
  'STEERING_OFF' = 'steering-off',
  'SEARCH_HANDS_FREE_OFF' = 'search-hands-free-off',
  'STEP_BACKWARD' = 'step-backward',
  'STEP_BACKWARD2' = 'step-backward2',
  'STEP_FORWARD' = 'step-forward',
  'STEP_FORWARD2' = 'step-forward2',
  'STETHOSCOPE' = 'stethoscope',
  'STICKER' = 'sticker',
  'STICKER_ALERT' = 'sticker-alert',
  'STICKER_ALERT_OUTLINE' = 'sticker-alert-outline',
  'STICKER_CHECK' = 'sticker-check',
  'STICKER_CHECK_OUTLINE' = 'sticker-check-outline',
  'STICKER_CIRCLE_OUTLINE' = 'sticker-circle-outline',
  'STICKER_EMOJI' = 'sticker-emoji',
  'STICKER_MINUS' = 'sticker-minus',
  'STICKER_MINUS_OUTLINE' = 'sticker-minus-outline',
  'STICKER_OUTLINE' = 'sticker-outline',
  'STICKER_PLUS' = 'sticker-plus',
  'STICKER_PLUS_OUTLINE' = 'sticker-plus-outline',
  'STICKER_REMOVE' = 'sticker-remove',
  'STICKER_REMOVE_OUTLINE' = 'sticker-remove-outline',
  'STICKER_TEXT' = 'sticker-text',
  'STICKER_TEXT_OUTLINE' = 'sticker-text-outline',
  'STOCKING' = 'stocking',
  'STOMACH' = 'stomach',
  'STOOL' = 'stool',
  'STOOL_OUTLINE' = 'stool-outline',
  'STOP_CIRCLE' = 'stop-circle',
  'STOP_CIRCLE_OUTLINE' = 'stop-circle-outline',
  'STORE' = 'store',
  'SHOP' = 'shop',
  'STORE_MALL_DIRECTORY' = 'store-mall-directory',
  'STORE24HOUR' = 'store24hour',
  'STORE_ALERT' = 'store-alert',
  'SHOP_ALERT' = 'shop-alert',
  'STORE_ALERT_OUTLINE' = 'store-alert-outline',
  'SHOP_ALERT_OUTLINE' = 'shop-alert-outline',
  'STORE_CHECK' = 'store-check',
  'SHOP_CHECK' = 'shop-check',
  'SHOP_COMPLETE' = 'shop-complete',
  'STORE_COMPLETE' = 'store-complete',
  'STORE_CHECK_OUTLINE' = 'store-check-outline',
  'STORE_COMPLETE_OUTLINE' = 'store-complete-outline',
  'SHOP_CHECK_OUTLINE' = 'shop-check-outline',
  'STORE_CLOCK' = 'store-clock',
  'STORE_SCHEDULE' = 'store-schedule',
  'STORE_HOURS' = 'store-hours',
  'SHOP_CLOCK' = 'shop-clock',
  'SHOP_HOURS' = 'shop-hours',
  'SHOP_SCHEDULE' = 'shop-schedule',
  'STORE_TIME' = 'store-time',
  'SHOP_TIME' = 'shop-time',
  'STORE_CLOCK_OUTLINE' = 'store-clock-outline',
  'SHOP_CLOCK_OUTLINE' = 'shop-clock-outline',
  'STORE_HOURS_OUTLINE' = 'store-hours-outline',
  'SHOP_HOURS_OUTLINE' = 'shop-hours-outline',
  'STORE_TIME_OUTLINE' = 'store-time-outline',
  'SHOP_TIME_OUTLINE' = 'shop-time-outline',
  'STORE_SCHEDULE_OUTLINE' = 'store-schedule-outline',
  'SHOP_SCHEDULE_OUTLINE' = 'shop-schedule-outline',
  'STORE_COG' = 'store-cog',
  'STORE_SETTINGS' = 'store-settings',
  'SHOP_SETTINGS' = 'shop-settings',
  'STORE_COG_OUTLINE' = 'store-cog-outline',
  'STORE_SETTINGS_OUTLINE' = 'store-settings-outline',
  'SHOP_SETTINGS_OUTLINE' = 'shop-settings-outline',
  'SHOP_COG_OUTLINE' = 'shop-cog-outline',
  'STORE_EDIT' = 'store-edit',
  'SHOP_EDIT' = 'shop-edit',
  'STORE_EDIT_OUTLINE' = 'store-edit-outline',
  'SHOP_EDIT_OUTLINE' = 'shop-edit-outline',
  'STORE_MARKER' = 'store-marker',
  'STORE_LOCATION' = 'store-location',
  'SHOP_MARKER' = 'shop-marker',
  'SHOP_LOCATION' = 'shop-location',
  'STORE_MARKER_OUTLINE' = 'store-marker-outline',
  'STORE_LOCATION_OUTLINE' = 'store-location-outline',
  'SHOP_MARKER_OUTLINE' = 'shop-marker-outline',
  'SHOP_LOCATION_OUTLINE' = 'shop-location-outline',
  'STORE_MINUS' = 'store-minus',
  'SHOP_MINUS' = 'shop-minus',
  'STORE_MINUS_OUTLINE' = 'store-minus-outline',
  'SHOP_MINUS_OUTLINE' = 'shop-minus-outline',
  'STORE_OFF' = 'store-off',
  'SHOP_OFF' = 'shop-off',
  'STORE_OFF_OUTLINE' = 'store-off-outline',
  'SHOP_OFF_OUTLINE' = 'shop-off-outline',
  'STORE_OUTLINE' = 'store-outline',
  'SHOP_OUTLINE' = 'shop-outline',
  'STORE_MALL_DIRECTORY_OUTLINE' = 'store-mall-directory-outline',
  'STORE_PLUS' = 'store-plus',
  'SHOP_PLUS' = 'shop-plus',
  'STORE_PLUS_OUTLINE' = 'store-plus-outline',
  'SHOP_PLUS_OUTLINE' = 'shop-plus-outline',
  'STORE_REMOVE' = 'store-remove',
  'SHOP_REMOVE' = 'shop-remove',
  'STORE_DELETE' = 'store-delete',
  'SHOP_DELETE' = 'shop-delete',
  'STORE_REMOVE_OUTLINE' = 'store-remove-outline',
  'SHOP_REMOVE_OUTLINE' = 'shop-remove-outline',
  'STORE_DELETE_OUTLINE' = 'store-delete-outline',
  'SHOP_DELETE_OUTLINE' = 'shop-delete-outline',
  'STORE_SEARCH' = 'store-search',
  'SHOP_SEARCH' = 'shop-search',
  'STORE_FIND' = 'store-find',
  'SHOP_FIND' = 'shop-find',
  'STORE_LOCATOR' = 'store-locator',
  'SHOP_LOCATOR' = 'shop-locator',
  'STORE_LOOK_UP' = 'store-look-up',
  'SHOP_LOOK_UP' = 'shop-look-up',
  'STORE_SEARCH_OUTLINE' = 'store-search-outline',
  'STORE_FIND_OUTLINE' = 'store-find-outline',
  'SHOP_SEARCH_OUTLINE' = 'shop-search-outline',
  'SHOP_FIND_OUTLINE' = 'shop-find-outline',
  'STORE_LOCATOR_OUTLINE' = 'store-locator-outline',
  'SHOP_LOCATOR_OUTLINE' = 'shop-locator-outline',
  'STORE_LOOK_UP_OUTLINE' = 'store-look-up-outline',
  'SHOP_LOOK_UP_OUTLINE' = 'shop-look-up-outline',
  'STOREFRONT' = 'storefront',
  'AWNING' = 'awning',
  'STOREFRONT_OUTLINE' = 'storefront-outline',
  'STOVE' = 'stove',
  'COOKER' = 'cooker',
  'OVEN' = 'oven',
  'STRATEGY' = 'strategy',
  'FOOTBALL_PLAY' = 'football-play',
  'STRETCH_TO_PAGE' = 'stretch-to-page',
  'STRETCH_TO_PAGE_OUTLINE' = 'stretch-to-page-outline',
  'STRING_LIGHTS' = 'string-lights',
  'ITALIAN_LIGHTS' = 'italian-lights',
  'CHRISTMAS_LIGHTS' = 'christmas-lights',
  'FAIRY_LIGHTS' = 'fairy-lights',
  'STRING_LIGHTS_OFF' = 'string-lights-off',
  'ITALIAN_LIGHTS_OFF' = 'italian-lights-off',
  'CHRISTMAS_LIGHTS_OFF' = 'christmas-lights-off',
  'FAIRY_LIGHTS_OFF' = 'fairy-lights-off',
  'SUBDIRECTORY_ARROW_LEFT' = 'subdirectory-arrow-left',
  'SUBDIRECTORY_ARROW_RIGHT' = 'subdirectory-arrow-right',
  'SUBMARINE' = 'submarine',
  'SUBTITLES' = 'subtitles',
  'SUBTITLES_OUTLINE' = 'subtitles-outline',
  'SUBWAY' = 'subway',
  'METRO' = 'metro',
  'TUBE' = 'tube',
  'UNDERGROUND' = 'underground',
  'SUBWAY_ALERT_VARIANT' = 'subway-alert-variant',
  'SUBWAY_WARNING_VARIANT' = 'subway-warning-variant',
  'SUBWAY_VARIANT' = 'subway-variant',
  'METRO_VARIANT' = 'metro-variant',
  'TUBE_VARIANT' = 'tube-variant',
  'UNDERGROUND_VARIANT' = 'underground-variant',
  'DIRECTIONS_SUBWAY' = 'directions-subway',
  'DIRECTIONS_TRANSIT' = 'directions-transit',
  'SUMMIT' = 'summit',
  'PEAK' = 'peak',
  'SUN_COMPASS' = 'sun-compass',
  'SUN_AZIMUTH' = 'sun-azimuth',
  'SOLAR_COMPASS' = 'solar-compass',
  'SOLAR_ASIMUTH' = 'solar-asimuth',
  'SUN_SNOWFLAKE' = 'sun-snowflake',
  'HOT_COLD' = 'hot-cold',
  'HEAT_COOL' = 'heat-cool',
  'SUN_THERMOMETER' = 'sun-thermometer',
  'HEAT_INDEX' = 'heat-index',
  'SUN_TEMPERATURE' = 'sun-temperature',
  'DAY_TEMPERATURE' = 'day-temperature',
  'EXTERNAL_TEMPERATURE' = 'external-temperature',
  'OUTDOOR_TEMPERATURE' = 'outdoor-temperature',
  'SUN_THERMOMETER_OUTLINE' = 'sun-thermometer-outline',
  'OUTSIDE_TEMPERATURE' = 'outside-temperature',
  'SUN_WIRELESS' = 'sun-wireless',
  'WEATHER_SUN_WIRELESS' = 'weather-sun-wireless',
  'ILLUMINANCE' = 'illuminance',
  'UV_RAY' = 'uv-ray',
  'ULTRAVIOLET' = 'ultraviolet',
  'SUN_WIRELESS_OUTLINE' = 'sun-wireless-outline',
  'WEATHER_SUN_WIRELESS_OUTLINE' = 'weather-sun-wireless-outline',
  'ILLUMINANCE_OUTLINE' = 'illuminance-outline',
  'UV_RAY_OUTLINE' = 'uv-ray-outline',
  'ULTRAVIOLET_OUTLINE' = 'ultraviolet-outline',
  'SUNGLASSES' = 'sunglasses',
  'SURFING' = 'surfing',
  'SURROUND_SOUND' = 'surround-sound',
  'SURROUND_SOUND20' = 'surround-sound20',
  'SURROUND_SOUND21' = 'surround-sound21',
  'SURROUND_SOUND31' = 'surround-sound31',
  'SURROUND_SOUND51' = 'surround-sound51',
  'SURROUND_SOUND512' = 'surround-sound512',
  'SURROUND_SOUND71' = 'surround-sound71',
  'SVG' = 'svg',
  'SWAP_HORIZONTAL' = 'swap-horizontal',
  'TRANSFER' = 'transfer',
  'EXCHANGE' = 'exchange',
  'SWITCH' = 'switch',
  'SWAP_HORIZONTAL_BOLD' = 'swap-horizontal-bold',
  'SWAP_HORIZONTAL_CIRCLE' = 'swap-horizontal-circle',
  'SWAP_HORIZONTAL_CIRCLE_OUTLINE' = 'swap-horizontal-circle-outline',
  'SWAP_HORIZONTAL_VARIANT' = 'swap-horizontal-variant',
  'SWAP_VERTICAL' = 'swap-vertical',
  'IMPORT_EXPORT' = 'import-export',
  'SWAP_VERTICAL_BOLD' = 'swap-vertical-bold',
  'IMPORT_EXPORT_BOLD' = 'import-export-bold',
  'SWAP_VERTICAL_CIRCLE' = 'swap-vertical-circle',
  'SWAP_VERTICAL_CIRCLE_OUTLINE' = 'swap-vertical-circle-outline',
  'SWAP_VERTICAL_VARIANT' = 'swap-vertical-variant',
  'SWAP_CALLS' = 'swap-calls',
  'SWIM' = 'swim',
  'SWORD' = 'sword',
  'SWORD_CROSS' = 'sword-cross',
  'SYLLABARY_HANGUL' = 'syllabary-hangul',
  'WRITING_SYSTEM_HANGUL' = 'writing-system-hangul',
  'SYLLABARY_HIRAGANA' = 'syllabary-hiragana',
  'WRITING_SYSTEM_HIRAGANA' = 'writing-system-hiragana',
  'SYLLABARY_KATAKANA' = 'syllabary-katakana',
  'WRITING_SYSTEM_KATAKANA' = 'writing-system-katakana',
  'SYLLABARY_KATAKANA_HALFWIDTH' = 'syllabary-katakana-halfwidth',
  'WRITING_SYSTEM_KATAKANA_HALF_WIDTH' = 'writing-system-katakana-half-width',
  'SYMBOL' = 'symbol',
  'SYMFONY' = 'symfony',
  'SYNC_ALERT' = 'sync-alert',
  'SYNC_WARNING' = 'sync-warning',
  'SYNC_PROBLEM' = 'sync-problem',
  'SYNC_CIRCLE' = 'sync-circle',
  'SYNC_DISABLED' = 'sync-disabled',
  'TAB' = 'tab',
  'TAB_MINUS' = 'tab-minus',
  'TAB_PLUS' = 'tab-plus',
  'TAB_ADD' = 'tab-add',
  'TAB_REMOVE' = 'tab-remove',
  'TAB_SEARCH' = 'tab-search',
  'TAB_FIND' = 'tab-find',
  'TAB_UNSELECTED' = 'tab-unselected',
  'TABLE' = 'table',
  'TABLE_ACCOUNT' = 'table-account',
  'TABLE_USER' = 'table-user',
  'TABLE_ALERT' = 'table-alert',
  'TABLE_ARROW_DOWN' = 'table-arrow-down',
  'TABLE_DOWNLOAD' = 'table-download',
  'TABLE_ARROW_LEFT' = 'table-arrow-left',
  'TABLE_IMPORT' = 'table-import',
  'TABLE_ARROW_RIGHT' = 'table-arrow-right',
  'TABLE_SHARE' = 'table-share',
  'TABLE_EXPORT' = 'table-export',
  'TABLE_ARROW_UP' = 'table-arrow-up',
  'TABLE_UPLOAD' = 'table-upload',
  'TABLE_BORDER' = 'table-border',
  'TABLE_CANCEL' = 'table-cancel',
  'TABLE_CHAIR' = 'table-chair',
  'DINING' = 'dining',
  'DINING_ROOM' = 'dining-room',
  'TABLE_CHECK' = 'table-check',
  'TABLE_CLOCK' = 'table-clock',
  'TABLE_COG' = 'table-cog',
  'TABLE_SETTINGS' = 'table-settings',
  'TABLE_COLUMN' = 'table-column',
  'TABLE_COLUMN_PLUS_AFTER' = 'table-column-plus-after',
  'TABLE_COLUMN_ADD_AFTER' = 'table-column-add-after',
  'TABLE_COLUMN_PLUS_BEFORE' = 'table-column-plus-before',
  'TABLE_COLUMN_ADD_BEFORE' = 'table-column-add-before',
  'TABLE_COLUMN_REMOVE' = 'table-column-remove',
  'TABLE_COLUMN_WIDTH' = 'table-column-width',
  'TABLE_EDIT' = 'table-edit',
  'TABLE_EYE' = 'table-eye',
  'TABLE_EYE_OFF' = 'table-eye-off',
  'TABLE_FURNITURE' = 'table-furniture',
  'TABLE_HEADERS_EYE' = 'table-headers-eye',
  'TABLE_HEADERS_EYE_OFF' = 'table-headers-eye-off',
  'TABLE_HEART' = 'table-heart',
  'TABLE_FAVORITE' = 'table-favorite',
  'TABLE_KEY' = 'table-key',
  'TABLE_LARGE' = 'table-large',
  'TABLE_LARGE_PLUS' = 'table-large-plus',
  'TABLE_LARGE_ADD' = 'table-large-add',
  'TABLE_LARGE_REMOVE' = 'table-large-remove',
  'TABLE_LOCK' = 'table-lock',
  'TABLE_MERGE_CELLS' = 'table-merge-cells',
  'TABLE_MINUS' = 'table-minus',
  'TABLE_MULTIPLE' = 'table-multiple',
  'TABLE_NETWORK' = 'table-network',
  'TABLE_OF_CONTENTS' = 'table-of-contents',
  'TOC' = 'toc',
  'TABLE_OFF' = 'table-off',
  'TABLE_PICNIC' = 'table-picnic',
  'TABLE_PIVOT' = 'table-pivot',
  'TABLE_PLUS' = 'table-plus',
  'TABLE_ADD' = 'table-add',
  'TABLE_REFRESH' = 'table-refresh',
  'TABLE_REMOVE' = 'table-remove',
  'TABLE_ROW' = 'table-row',
  'TABLE_ROW_HEIGHT' = 'table-row-height',
  'TABLE_ROW_PLUS_AFTER' = 'table-row-plus-after',
  'TABLE_ROW_ADD_AFTER' = 'table-row-add-after',
  'TABLE_ROW_PLUS_BEFORE' = 'table-row-plus-before',
  'TABLE_ROW_ADD_BEFORE' = 'table-row-add-before',
  'TABLE_ROW_REMOVE' = 'table-row-remove',
  'TABLE_SEARCH' = 'table-search',
  'TABLE_SPLIT_CELL' = 'table-split-cell',
  'TABLE_STAR' = 'table-star',
  'TABLE_SYNC' = 'table-sync',
  'TABLE_TENNIS' = 'table-tennis',
  'PING_PONG' = 'ping-pong',
  'WHIFF_WHAFF' = 'whiff-whaff',
  'TABLET' = 'tablet',
  'TABLET_ANDROID' = 'tablet-android',
  'TABLET_CELLPHONE' = 'tablet-cellphone',
  'MOBILE_DEVICES' = 'mobile-devices',
  'TABLET_MOBILE_PHONE' = 'tablet-mobile-phone',
  'TABLET_SMARTPHONE' = 'tablet-smartphone',
  'TABLET_DASHBOARD' = 'tablet-dashboard',
  'TACO' = 'taco',
  'TAG' = 'tag',
  'LOCAL_OFFER' = 'local-offer',
  'TAG_ARROW_DOWN' = 'tag-arrow-down',
  'TAG_ARROW_DOWN_OUTLINE' = 'tag-arrow-down-outline',
  'TAG_ARROW_LEFT' = 'tag-arrow-left',
  'TAG_ARROW_LEFT_OUTLINE' = 'tag-arrow-left-outline',
  'TAG_ARROW_RIGHT' = 'tag-arrow-right',
  'TAG_ARROW_RIGHT_OUTLINE' = 'tag-arrow-right-outline',
  'TAG_ARROW_UP' = 'tag-arrow-up',
  'TAG_ARROW_UP_OUTLINE' = 'tag-arrow-up-outline',
  'TAG_EMOJI' = 'tag-emoji',
  'TAG_EMOTICON' = 'tag-emoticon',
  'TAG_HEART' = 'tag-heart',
  'LOYALTY' = 'loyalty',
  'TAG_HEART_OUTLINE' = 'tag-heart-outline',
  'TAG_MINUS' = 'tag-minus',
  'TAG_MINUS_OUTLINE' = 'tag-minus-outline',
  'TAG_MULTIPLE' = 'tag-multiple',
  'TAGS' = 'tags',
  'TAG_MULTIPLE_OUTLINE' = 'tag-multiple-outline',
  'TAG_OFF' = 'tag-off',
  'TAG_OFF_OUTLINE' = 'tag-off-outline',
  'TAG_OUTLINE' = 'tag-outline',
  'TAG_PLUS' = 'tag-plus',
  'TAG_ADD' = 'tag-add',
  'TAG_PLUS_OUTLINE' = 'tag-plus-outline',
  'TAG_REMOVE' = 'tag-remove',
  'TAG_REMOVE_OUTLINE' = 'tag-remove-outline',
  'TAG_SEARCH' = 'tag-search',
  'TAG_FIND' = 'tag-find',
  'TAG_SEARCH_OUTLINE' = 'tag-search-outline',
  'TAG_FIND_OUTLINE' = 'tag-find-outline',
  'TAG_TEXT' = 'tag-text',
  'TAG_TEXT_OUTLINE' = 'tag-text-outline',
  'TAILWIND' = 'tailwind',
  'TANGRAM' = 'tangram',
  'TANKER_TRUCK' = 'tanker-truck',
  'FUEL_TRUCK' = 'fuel-truck',
  'OIL_TRUCK' = 'oil-truck',
  'WATER_TRUCK' = 'water-truck',
  'TANKER' = 'tanker',
  'TAPE_DRIVE' = 'tape-drive',
  'TAPE_MEASURE' = 'tape-measure',
  'MEASURING_TAPE' = 'measuring-tape',
  'REGISTRATION_MARK' = 'registration-mark',
  'TARGET_ACCOUNT' = 'target-account',
  'CROSSHAIRS_ACCOUNT' = 'crosshairs-account',
  'TARGET_USER' = 'target-user',
  'TARGET_VARIANT' = 'target-variant',
  'TAXI' = 'taxi',
  'LOCAL_TAXI' = 'local-taxi',
  'CAB' = 'cab',
  'TEAMVIEWER' = 'teamviewer',
  'TEDDY_BEAR' = 'teddy-bear',
  'CHILD_TOY' = 'child-toy',
  'CHILDREN_TOY' = 'children-toy',
  'KIDS_ROOM' = 'kids-room',
  'CHILDRENS_ROOM' = 'childrens-room',
  'PLAY_ROOM' = 'play-room',
  'TELESCOPE' = 'telescope',
  'TV' = 'tv',
  'TELEVISION_AMBIENT_LIGHT' = 'television-ambient-light',
  'TELEVISION_BOX' = 'television-box',
  'TV_BOX' = 'tv-box',
  'TV_GUIDE' = 'tv-guide',
  'TELEVISION_CLASSIC' = 'television-classic',
  'TV_CLASSIC' = 'tv-classic',
  'TELEVISION_CLASSIC_OFF' = 'television-classic-off',
  'TV_CLASSIC_OFF' = 'tv-classic-off',
  'TELEVISION_GUIDE' = 'television-guide',
  'TELEVISION_OFF' = 'television-off',
  'TV_OFF' = 'tv-off',
  'TELEVISION_PAUSE' = 'television-pause',
  'TELEVISION_PLAY' = 'television-play',
  'TELEVISION_SHIMMER' = 'television-shimmer',
  'TELEVISION_CLEAN' = 'television-clean',
  'TELEVISION_STOP' = 'television-stop',
  'TEMPERATURE_CELSIUS' = 'temperature-celsius',
  'TEMPERATURE_CENTIGRADE' = 'temperature-centigrade',
  'TEMPERATURE_FAHRENHEIT' = 'temperature-fahrenheit',
  'TEMPERATURE_KELVIN' = 'temperature-kelvin',
  'TENNIS' = 'tennis',
  'TENNIS_RACQUET' = 'tennis-racquet',
  'TENNIS_RACKET' = 'tennis-racket',
  'TENNIS_BALL' = 'tennis-ball',
  'TENT' = 'tent',
  'CAMPING' = 'camping',
  'TERRAFORM' = 'terraform',
  'TERRAIN' = 'terrain',
  'TEST_TUBE' = 'test-tube',
  'TEST_TUBE_EMPTY' = 'test-tube-empty',
  'TEST_TUBE_OFF' = 'test-tube-off',
  'TEXT' = 'text',
  'TEXT_ACCOUNT' = 'text-account',
  'BIOGRAPHY' = 'biography',
  'TEXT_USER' = 'text-user',
  'TEXT_BOX' = 'text-box',
  'DRIVE_DOCUMENT' = 'drive-document',
  'FILE_DOCUMENT_BOX' = 'file-document-box',
  'TEXT_BOX_CHECK' = 'text-box-check',
  'FILE_DOCUMENT_BOX_TICK' = 'file-document-box-tick',
  'FILE_DOCUMENT_BOX_CHECK' = 'file-document-box-check',
  'TEXT_BOX_CHECK_OUTLINE' = 'text-box-check-outline',
  'FILE_DOCUMENT_BOX_TICK_OUTLINE' = 'file-document-box-tick-outline',
  'FILE_DOCUMENT_BOX_CHECK_OUTLINE' = 'file-document-box-check-outline',
  'TEXT_BOX_MINUS' = 'text-box-minus',
  'FILE_DOCUMENT_BOX_MINUS' = 'file-document-box-minus',
  'TEXT_BOX_MINUS_OUTLINE' = 'text-box-minus-outline',
  'FILE_DOCUMENT_BOX_MINUS_OUTLINE' = 'file-document-box-minus-outline',
  'TEXT_BOX_MULTIPLE' = 'text-box-multiple',
  'FILE_DOCUMENT_BOXES' = 'file-document-boxes',
  'FILE_DOCUMENT_BOX_MULTIPLE' = 'file-document-box-multiple',
  'TEXT_BOX_MULTIPLE_OUTLINE' = 'text-box-multiple-outline',
  'FILE_DOCUMENT_BOXES_OUTLINE' = 'file-document-boxes-outline',
  'FILE_DOCUMENT_BOX_MULTIPLE_OUTLINE' = 'file-document-box-multiple-outline',
  'TEXT_BOX_OUTLINE' = 'text-box-outline',
  'FILE_DOCUMENT_BOX_OUTLINE' = 'file-document-box-outline',
  'TEXT_BOX_PLUS' = 'text-box-plus',
  'FILE_DOCUMENT_BOX_PLUS' = 'file-document-box-plus',
  'TEXT_BOX_PLUS_OUTLINE' = 'text-box-plus-outline',
  'FILE_DOCUMENT_BOX_PLUS_OUTLINE' = 'file-document-box-plus-outline',
  'TEXT_BOX_REMOVE' = 'text-box-remove',
  'FILE_DOCUMENT_BOX_REMOVE' = 'file-document-box-remove',
  'TEXT_BOX_REMOVE_OUTLINE' = 'text-box-remove-outline',
  'FILE_DOCUMENT_BOX_REMOVE_OUTLINE' = 'file-document-box-remove-outline',
  'TEXT_BOX_SEARCH' = 'text-box-search',
  'FILE_DOCUMENT_BOX_SEARCH' = 'file-document-box-search',
  'TEXT_BOX_SEARCH_OUTLINE' = 'text-box-search-outline',
  'FILE_DOCUMENT_BOX_SEARCH_OUTLINE' = 'file-document-box-search-outline',
  'TEXT_LONG' = 'text-long',
  'TEXT_SUBJECT' = 'text-subject',
  'TEXT_RECOGNITION' = 'text-recognition',
  'TEXT_SEARCH' = 'text-search',
  'NOTES_SEARCH' = 'notes-search',
  'TEXT_SHADOW' = 'text-shadow',
  'TEXT_SHORT' = 'text-short',
  'TEXT_TO_SPEECH' = 'text-to-speech',
  'TTS' = 'tts',
  'MICROPHONE_MESSAGE' = 'microphone-message',
  'TEXT_TO_SPEECH_OFF' = 'text-to-speech-off',
  'TTS_OFF' = 'tts-off',
  'MICROPHONE_MESSAGE_OFF' = 'microphone-message-off',
  'TEXTURE' = 'texture',
  'TEXTURE_BOX' = 'texture-box',
  'SURFACE_AREA' = 'surface-area',
  'THEATER' = 'theater',
  'CINEMA' = 'cinema',
  'SUN_MOON_STARS' = 'sun-moon-stars',
  'THERMOMETER' = 'thermometer',
  'TEMPERATURE' = 'temperature',
  'THERMOMETER_ALERT' = 'thermometer-alert',
  'THERMOMETER_WARNING' = 'thermometer-warning',
  'TEMPERATURE_ALERT' = 'temperature-alert',
  'TEMPERATURE_WARNING' = 'temperature-warning',
  'THERMOMETER_BLUETOOTH' = 'thermometer-bluetooth',
  'TEMPERATURE_BLUETOOTH' = 'temperature-bluetooth',
  'THERMOMETER_CHEVRON_DOWN' = 'thermometer-chevron-down',
  'TEMPERATURE_CHEVRON_DOWN' = 'temperature-chevron-down',
  'TEMPERATURE_DECREASE' = 'temperature-decrease',
  'THERMOMETER_DECREASE' = 'thermometer-decrease',
  'THERMOMETER_CHEVRON_UP' = 'thermometer-chevron-up',
  'TEMPERATURE_CHEVRON_UP' = 'temperature-chevron-up',
  'TEMPERATURE_INCREASE' = 'temperature-increase',
  'THERMOMETER_INCREASE' = 'thermometer-increase',
  'THERMOMETER_HIGH' = 'thermometer-high',
  'TEMPERATURE_HIGH' = 'temperature-high',
  'THERMOMETER_LINES' = 'thermometer-lines',
  'TEMPERATURE_LINES' = 'temperature-lines',
  'THERMOMETER_LOW' = 'thermometer-low',
  'TEMPERATURE_LOW' = 'temperature-low',
  'THERMOMETER_MINUS' = 'thermometer-minus',
  'TEMPERATURE_MINUS' = 'temperature-minus',
  'THERMOMETER_OFF' = 'thermometer-off',
  'TEMPERATURE_OFF' = 'temperature-off',
  'THERMOMETER_PLUS' = 'thermometer-plus',
  'THERMOMETER_ADD' = 'thermometer-add',
  'TEMPERATURE_PLUS' = 'temperature-plus',
  'TEMPERATURE_ADD' = 'temperature-add',
  'THERMOSTAT' = 'thermostat',
  'NEST' = 'nest',
  'THERMOSTAT_BOX' = 'thermostat-box',
  'THOUGHT_BUBBLE' = 'thought-bubble',
  'COMIC_BUBBLE' = 'comic-bubble',
  'THINKING' = 'thinking',
  'THOUGHT_BUBBLE_OUTLINE' = 'thought-bubble-outline',
  'COMIC_THOUGHT_BUBBLE_OUTLINE' = 'comic-thought-bubble-outline',
  'THINKING_OUTLINE' = 'thinking-outline',
  'THINK_OUTLINE' = 'think-outline',
  'THUMB_DOWN' = 'thumb-down',
  'DISLIKE' = 'dislike',
  'THUMBS_DOWN' = 'thumbs-down',
  'THUMB_DOWN_OUTLINE' = 'thumb-down-outline',
  'DISLIKE_OUTLINE' = 'dislike-outline',
  'THUMBS_DOWN_OUTLINE' = 'thumbs-down-outline',
  'THUMB_UP' = 'thumb-up',
  'LIKE' = 'like',
  'THUMBS_UP' = 'thumbs-up',
  'THUMB_UP_OUTLINE' = 'thumb-up-outline',
  'LIKE_OUTLINE' = 'like-outline',
  'THUMBS_UP_OUTLINE' = 'thumbs-up-outline',
  'THUMBS_UP_DOWN' = 'thumbs-up-down',
  'LIKE_DISLIKE' = 'like-dislike',
  'THUMBS_UP_DOWN_OUTLINE' = 'thumbs-up-down-outline',
  'LIKE_DISLIKE_OUTLINE' = 'like-dislike-outline',
  'TICKET' = 'ticket',
  'LOCAL_ACTIVITY' = 'local-activity',
  'LOCAL_PLAY' = 'local-play',
  'LOCAL_ATTRACTION' = 'local-attraction',
  'TICKET_ACCOUNT' = 'ticket-account',
  'TICKET_USER' = 'ticket-user',
  'TICKET_CONFIRMATION' = 'ticket-confirmation',
  'CONFIRMATION_NUMBER' = 'confirmation-number',
  'TICKET_CONFIRMATION_OUTLINE' = 'ticket-confirmation-outline',
  'CONFIRMATION_NUMBER_OUTLINE' = 'confirmation-number-outline',
  'TICKET_OUTLINE' = 'ticket-outline',
  'TICKET_PERCENT' = 'ticket-percent',
  'COUPON' = 'coupon',
  'VOUCHER' = 'voucher',
  'TICKET_PERCENT_OUTLINE' = 'ticket-percent-outline',
  'COUPON_OUTLINE' = 'coupon-outline',
  'VOUCHER_OUTLINE' = 'voucher-outline',
  'TIE' = 'tie',
  'TILDE' = 'tilde',
  'TILDE_OFF' = 'tilde-off',
  'TIMELAPSE' = 'timelapse',
  'TIMELINE_ALERT' = 'timeline-alert',
  'TIMELINE_ALERT_OUTLINE' = 'timeline-alert-outline',
  'TIMELINE_CHECK' = 'timeline-check',
  'TIMELINE_CHECK_OUTLINE' = 'timeline-check-outline',
  'TIMELINE_CLOCK' = 'timeline-clock',
  'TIMELINE_CLOCK_OUTLINE' = 'timeline-clock-outline',
  'TIMELINE_HELP' = 'timeline-help',
  'TIMELINE_HELP_OUTLINE' = 'timeline-help-outline',
  'TIMELINE_MINUS' = 'timeline-minus',
  'TIMELINE_MINUS_OUTLINE' = 'timeline-minus-outline',
  'TIMELINE_OUTLINE' = 'timeline-outline',
  'TIMELINE_PLUS' = 'timeline-plus',
  'TIMELINE_PLUS_OUTLINE' = 'timeline-plus-outline',
  'TIMELINE_REMOVE' = 'timeline-remove',
  'TIMELINE_REMOVE_OUTLINE' = 'timeline-remove-outline',
  'TIMELINE_TEXT' = 'timeline-text',
  'TIMELINE_TEXT_OUTLINE' = 'timeline-text-outline',
  'TIMER' = 'timer',
  'STOPWATCH' = 'stopwatch',
  'TIMER10' = 'timer10',
  'TIMER3' = 'timer3',
  'TIMER_COG' = 'timer-cog',
  'TIMER_SETTINGS' = 'timer-settings',
  'TIMER_COG_OUTLINE' = 'timer-cog-outline',
  'TIMER_SETTINGS_OUTLINE' = 'timer-settings-outline',
  'TIMER_OFF' = 'timer-off',
  'STOPWATCH_OFF' = 'stopwatch-off',
  'TIMER_OFF_OUTLINE' = 'timer-off-outline',
  'STOPWATCH_OFF_OUTLINE' = 'stopwatch-off-outline',
  'TIMER_OUTLINE' = 'timer-outline',
  'STOPWATCH_OUTLINE' = 'stopwatch-outline',
  'TIMER_SAND' = 'timer-sand',
  'HOURGLASS' = 'hourglass',
  'TIMER_SAND_COMPLETE' = 'timer-sand-complete',
  'HOURGLASS_COMPLETE' = 'hourglass-complete',
  'TIMER_SAND_EMPTY' = 'timer-sand-empty',
  'HOURGLASS_EMPTY' = 'hourglass-empty',
  'TIMER_SAND_FULL' = 'timer-sand-full',
  'HOURGLASS_FULL' = 'hourglass-full',
  'TIMER_SAND_PAUSED' = 'timer-sand-paused',
  'HOURGLASS_PAUSED' = 'hourglass-paused',
  'TIMETABLE' = 'timetable',
  'TIRE' = 'tire',
  'TYRE' = 'tyre',
  'WHEEL' = 'wheel',
  'TOASTER' = 'toaster',
  'TOASTER_OFF' = 'toaster-off',
  'TOASTER_OVEN' = 'toaster-oven',
  'TOGGLE_SWITCH_OFF_OUTLINE' = 'toggle-switch-off-outline',
  'TOGGLE_SWITCH_OUTLINE' = 'toggle-switch-outline',
  'TOGGLE_SWITCH_VARIANT' = 'toggle-switch-variant',
  'LIGHT_SWITCH_ON' = 'light-switch-on',
  'TOGGLE_SWITCH_VARIANT_OFF' = 'toggle-switch-variant-off',
  'TOILET' = 'toilet',
  'LAVATORY' = 'lavatory',
  'BIDET' = 'bidet',
  'TOOLBOX' = 'toolbox',
  'TOOLBOX_OUTLINE' = 'toolbox-outline',
  'SERVICE_TOOLBOX' = 'service-toolbox',
  'WRENCH' = 'wrench',
  'TOOLTIP' = 'tooltip',
  'TOOLTIP_ACCOUNT' = 'tooltip-account',
  'TOOLTIP_USER' = 'tooltip-user',
  'TOOLTIP_PERSON' = 'tooltip-person',
  'ACCOUNT_LOCATION' = 'account-location',
  'TOOLTIP_CELLPHONE' = 'tooltip-cellphone',
  'TOOLTIP_CHECK' = 'tooltip-check',
  'TOOLTIP_CHECK_OUTLINE' = 'tooltip-check-outline',
  'TOOLTIP_EDIT' = 'tooltip-edit',
  'TOOLTIP_EDIT_OUTLINE' = 'tooltip-edit-outline',
  'TOOLTIP_IMAGE' = 'tooltip-image',
  'TOOLTIP_IMAGE_OUTLINE' = 'tooltip-image-outline',
  'TOOLTIP_MINUS' = 'tooltip-minus',
  'TOOLTIP_MINUS_OUTLINE' = 'tooltip-minus-outline',
  'TOOLTIP_OUTLINE' = 'tooltip-outline',
  'TOOLTIP_PLUS' = 'tooltip-plus',
  'TOOLTIP_ADD' = 'tooltip-add',
  'TOOLTIP_PLUS_OUTLINE' = 'tooltip-plus-outline',
  'TOOLTIP_OUTLINE_PLUS' = 'tooltip-outline-plus',
  'TOOLTIP_ADD_OUTLINE' = 'tooltip-add-outline',
  'TOOLTIP_REMOVE' = 'tooltip-remove',
  'TOOLTIP_REMOVE_OUTLINE' = 'tooltip-remove-outline',
  'TOOLTIP_TEXT' = 'tooltip-text',
  'TOOLTIP_TEXT_OUTLINE' = 'tooltip-text-outline',
  'TOOTH' = 'tooth',
  'DENTIST' = 'dentist',
  'TOOTH_OUTLINE' = 'tooth-outline',
  'TOOTHBRUSH' = 'toothbrush',
  'ORAL_HYGIENE' = 'oral-hygiene',
  'TOOTHBRUSH_ELECTRIC' = 'toothbrush-electric',
  'TOOTHBRUSH_PASTE' = 'toothbrush-paste',
  'OLYMPICS' = 'olympics',
  'TORTOISE' = 'tortoise',
  'TURTLE' = 'turtle',
  'TOSLINK' = 'toslink',
  'OPTICAL_AUDIO' = 'optical-audio',
  'TOURNAMENT' = 'tournament',
  'BRACKET' = 'bracket',
  'TOW_TRUCK' = 'tow-truck',
  'AUTO_TOWING' = 'auto-towing',
  'TRUCK' = 'truck',
  'TOWER_BEACH' = 'tower-beach',
  'TOWER_FIRE' = 'tower-fire',
  'TOWN_HALL' = 'town-hall',
  'TOY_BRICK' = 'toy-brick',
  'LEGO' = 'lego',
  'PLUGIN' = 'plugin',
  'TOY_BRICK_MARKER' = 'toy-brick-marker',
  'LEGO_LOCATION' = 'lego-location',
  'TOY_BRICK_LOCATION' = 'toy-brick-location',
  'TOY_BRICK_MARKER_OUTLINE' = 'toy-brick-marker-outline',
  'LEGO_LOCATION_OUTLINE' = 'lego-location-outline',
  'TOY_BRICK_LOCATION_OUTLINE' = 'toy-brick-location-outline',
  'PLUGIN_OUTLINE' = 'plugin-outline',
  'LEGO_OUTLINE' = 'lego-outline',
  'TOY_BRICK_MINUS' = 'toy-brick-minus',
  'TOY_BRICK_MINUS_OUTLINE' = 'toy-brick-minus-outline',
  'TOY_BRICK_OUTLINE' = 'toy-brick-outline',
  'TOY_BRICK_PLUS' = 'toy-brick-plus',
  'TOY_BRICK_PLUS_OUTLINE' = 'toy-brick-plus-outline',
  'TOY_BRICK_REMOVE' = 'toy-brick-remove',
  'TOY_BRICK_REMOVE_OUTLINE' = 'toy-brick-remove-outline',
  'TOY_BRICK_SEARCH' = 'toy-brick-search',
  'TOY_BRICK_SEARCH_OUTLINE' = 'toy-brick-search-outline',
  'TRACK_LIGHT' = 'track-light',
  'TRACKPAD' = 'trackpad',
  'TRACKPAD_LOCK' = 'trackpad-lock',
  'TRACTOR' = 'tractor',
  'TRACTOR_VARIANT' = 'tractor-variant',
  'AGRICULTURE' = 'agriculture',
  'TRADEMARK' = 'trademark',
  'TM' = 'tm',
  'TRAFFIC_CONE' = 'traffic-cone',
  'TRAFFIC_LIGHT' = 'traffic-light',
  'TRAFFIC_SIGNAL' = 'traffic-signal',
  'STOP_LIGHT' = 'stop-light',
  'TRAFFIC_LIGHT_OUTLINE' = 'traffic-light-outline',
  'TRAFFIC_SIGNAL_OUTLINE' = 'traffic-signal-outline',
  'STOP_LIGHT_OUTLINE' = 'stop-light-outline',
  'TRAIN' = 'train',
  'DIRECTIONS_RAILWAY' = 'directions-railway',
  'LOCOMOTIVE' = 'locomotive',
  'RAILROAD' = 'railroad',
  'TRAIN_CAR' = 'train-car',
  'COMMUTE' = 'commute',
  'TRANSPORTATION' = 'transportation',
  'TRAVEL' = 'travel',
  'TRAIN_CAR_PASSENGER' = 'train-car-passenger',
  'TRAIN_CAR_PASSENGER_DOOR' = 'train-car-passenger-door',
  'TRAIN_CAR_PASSENGER_DOOR_OPEN' = 'train-car-passenger-door-open',
  'TRAIN_CAR_PASSENGER_VARIANT' = 'train-car-passenger-variant',
  'TRAIN_VARIANT' = 'train-variant',
  'LOCOMOTIVE_VARIANT' = 'locomotive-variant',
  'RAILROAD_VARIANT' = 'railroad-variant',
  'TRAM' = 'tram',
  'TRAM_SIDE' = 'tram-side',
  'TRANSCRIBE' = 'transcribe',
  'TRANSCRIBE_CLOSE' = 'transcribe-close',
  'TRANSFER_DOWN' = 'transfer-down',
  'TRANSFER_LEFT' = 'transfer-left',
  'TRANSFER_RIGHT' = 'transfer-right',
  'TRANSFER_UP' = 'transfer-up',
  'TRANSIT_CONNECTION' = 'transit-connection',
  'TRANSIT_CONNECTION_HORIZONTAL' = 'transit-connection-horizontal',
  'TRANSIT_CONNECTION_VARIANT' = 'transit-connection-variant',
  'TRANSIT_DETOUR' = 'transit-detour',
  'TRANSIT_SKIP' = 'transit-skip',
  'TRANSIT_TRANSFER' = 'transit-transfer',
  'TRANSFER_WITHIN_A_STATION' = 'transfer-within-a-station',
  'TRANSITION' = 'transition',
  'TRANSLATE' = 'translate',
  'TRANSITION_MASKED' = 'transition-masked',
  'MASKED_TRANSITIONS' = 'masked-transitions',
  'LANGUAGE' = 'language',
  'TRANSLATE_OFF' = 'translate-off',
  'TRANSMISSION_TOWER' = 'transmission-tower',
  'PYLON' = 'pylon',
  'POWERLINE' = 'powerline',
  'TRANSMISSION_TOWER_EXPORT' = 'transmission-tower-export',
  'POWER_FROM_GRID' = 'power-from-grid',
  'ENERGY_FROM_GRID' = 'energy-from-grid',
  'ELECTRICITY_FROM_GRID' = 'electricity-from-grid',
  'TRANSMISSION_TOWER_IMPORT' = 'transmission-tower-import',
  'POWER_TO_GRID' = 'power-to-grid',
  'ENERGY_TO_GRID' = 'energy-to-grid',
  'ELECTRICITY_TO_GRID' = 'electricity-to-grid',
  'RETURN_TO_GRID' = 'return-to-grid',
  'TRANSMISSION_TOWER_OFF' = 'transmission-tower-off',
  'POWERLINE_OFF' = 'powerline-off',
  'PYLON_OFF' = 'pylon-off',
  'TRASHCAN' = 'trashcan',
  'TRASHCAN_OUTLINE' = 'trashcan-outline',
  'TRAY' = 'tray',
  'TRAY_ALERT' = 'tray-alert',
  'TRAY_ARROW_DOWN' = 'tray-arrow-down',
  'TRAY_DOWNLOAD' = 'tray-download',
  'TRAY_ARROW_UP' = 'tray-arrow-up',
  'TRAY_UPLOAD' = 'tray-upload',
  'TRAY_FULL' = 'tray-full',
  'TRAY_MINUS' = 'tray-minus',
  'TRAY_PLUS' = 'tray-plus',
  'TRAY_REMOVE' = 'tray-remove',
  'TREASURE_CHEST' = 'treasure-chest',
  'TREE' = 'tree',
  'TREE_OUTLINE' = 'tree-outline',
  'TRELLO' = 'trello',
  'TRENDING_DOWN' = 'trending-down',
  'TRENDING_NEUTRAL' = 'trending-neutral',
  'TRENDING_FLAT' = 'trending-flat',
  'TRENDING_UP' = 'trending-up',
  'TRIANGLE' = 'triangle',
  'TRIANGLE_OUTLINE' = 'triangle-outline',
  'TRIANGLE_SMALL_DOWN' = 'triangle-small-down',
  'TRENDING_DOWN_VARIANT' = 'trending-down-variant',
  'TRIANGLE_SMALL_UP' = 'triangle-small-up',
  'TRENDING_UP_VARIANT' = 'trending-up-variant',
  'TRIANGLE_WAVE' = 'triangle-wave',
  'TRIFORCE' = 'triforce',
  'ZELDA' = 'zelda',
  'TROPHY' = 'trophy',
  'ACHIEVEMENT' = 'achievement',
  'TROPHY_AWARD' = 'trophy-award',
  'ACHIEVEMENT_AWARD' = 'achievement-award',
  'TROPHY_BROKEN' = 'trophy-broken',
  'TROPHY_OUTLINE' = 'trophy-outline',
  'ACHIEVEMENT_OUTLINE' = 'achievement-outline',
  'TROPHY_VARIANT' = 'trophy-variant',
  'ACHIEVEMENT_VARIANT' = 'achievement-variant',
  'TROPHY_VARIANT_OUTLINE' = 'trophy-variant-outline',
  'ACHIEVEMENT_VARIANT_OUTLINE' = 'achievement-variant-outline',
  'LORRY' = 'lorry',
  'LOCAL_SHIPPING' = 'local-shipping',
  'COURIER' = 'courier',
  'TRUCK_ALERT' = 'truck-alert',
  'TRUCK_ERROR' = 'truck-error',
  'TRUCK_ALERT_OUTLINE' = 'truck-alert-outline',
  'TRUCK_ERROR_OUTLINE' = 'truck-error-outline',
  'TRUCK_CARGO_CONTAINER' = 'truck-cargo-container',
  'TRUCK_SHIPPING' = 'truck-shipping',
  'TRUCK_CHECK' = 'truck-check',
  'TRUCK_TICK' = 'truck-tick',
  'LORRY_CHECK' = 'lorry-check',
  'COURIER_CHECK' = 'courier-check',
  'TRUCK_CHECK_OUTLINE' = 'truck-check-outline',
  'TRUCK_DELIVERY' = 'truck-delivery',
  'LORRY_DELIVERY' = 'lorry-delivery',
  'TRUCK_DELIVERY_OUTLINE' = 'truck-delivery-outline',
  'TRUCK_FAST' = 'truck-fast',
  'LORRY_FAST' = 'lorry-fast',
  'COURIER_FAST' = 'courier-fast',
  'TRUCK_FAST_OUTLINE' = 'truck-fast-outline',
  'TRUCK_FLATBED' = 'truck-flatbed',
  'TRUCK_FLATBED_TOW' = 'truck-flatbed-tow',
  'TRUCK_MINUS' = 'truck-minus',
  'TRUCK_SUBTRACT' = 'truck-subtract',
  'TRUCK_MINUS_OUTLINE' = 'truck-minus-outline',
  'TRUCK_SUBTRACT_OUTLINE' = 'truck-subtract-outline',
  'TRUCK_OUTLINE' = 'truck-outline',
  'TRUCK_PLUS' = 'truck-plus',
  'TRUCK_ADD' = 'truck-add',
  'TRUCK_PLUS_OUTLINE' = 'truck-plus-outline',
  'TRUCK_ADD_OUTLINE' = 'truck-add-outline',
  'TRUCK_REMOVE' = 'truck-remove',
  'TRUCK_REMOVE_OUTLINE' = 'truck-remove-outline',
  'TRUCK_SNOWFLAKE' = 'truck-snowflake',
  'TRUCK_REFRIGERATOR' = 'truck-refrigerator',
  'TRUCK_FREEZER' = 'truck-freezer',
  'TRUCK_TRAILER' = 'truck-trailer',
  'TRUMPET' = 'trumpet',
  'TSHIRT_CREW' = 'tshirt-crew',
  'T_SHIRT_CREW' = 't-shirt-crew',
  'TSHIRT_CREW_OUTLINE' = 'tshirt-crew-outline',
  'T_SHIRT_CREW_OUTLINE' = 't-shirt-crew-outline',
  'TSHIRT_V' = 'tshirt-v',
  'T_SHIRT_V' = 't-shirt-v',
  'TSHIRT_VOUTLINE' = 'tshirt-voutline',
  'TUMBLE_DRYER' = 'tumble-dryer',
  'LAUNDRY_ROOM' = 'laundry-room',
  'TUMBLE_DRYER_ALERT' = 'tumble-dryer-alert',
  'LAUNDRY_ROOM_ALERT' = 'laundry-room-alert',
  'TUMBLE_DRYER_OFF' = 'tumble-dryer-off',
  'LAUNDRY_ROOM_OFF' = 'laundry-room-off',
  'TUNE' = 'tune',
  'MIXER_SETTINGS' = 'mixer-settings',
  'EQUALISER' = 'equaliser',
  'TUNE_VARIANT' = 'tune-variant',
  'TUNE_VERTICAL' = 'tune-vertical',
  'EQUALISER_VERTICAL' = 'equaliser-vertical',
  'INSTANT_MIX' = 'instant-mix',
  'SETTINGS_VERTICAL' = 'settings-vertical',
  'MIXER_SETTINGS_VERTICAL' = 'mixer-settings-vertical',
  'TUNE_VERTICAL_VARIANT' = 'tune-vertical-variant',
  'EQUALIZER_VERTICAL' = 'equalizer-vertical',
  'TUNNEL' = 'tunnel',
  'TUNNEL_OUTLINE' = 'tunnel-outline',
  'TURKEY' = 'turkey',
  'TURNSTILE' = 'turnstile',
  'TURNSTILE_OUTLINE' = 'turnstile-outline',
  'TWITCH' = 'twitch',
  'TWITTER' = 'twitter',
  'TWO_FACTOR_AUTHENTICATION' = 'two-factor-authentication',
  'TYPEWRITER' = 'typewriter',
  'UBISOFT' = 'ubisoft',
  'UPLAY' = 'uplay',
  'UBUNTU' = 'ubuntu',
  'UFO' = 'ufo',
  'UNIDENTIFIED_FLYING_OBJECT' = 'unidentified-flying-object',
  'UFO_OUTLINE' = 'ufo-outline',
  'UNIDENTIFIED_FLYING_OBJECT_OUTLINE' = 'unidentified-flying-object-outline',
  'ULTRA_HIGH_DEFINITION' = 'ultra-high-definition',
  'UHD' = 'uhd',
  'UMBRACO' = 'umbraco',
  'UMBRELLA' = 'umbrella',
  'UMBRELLA_BEACH' = 'umbrella-beach',
  'UMBRELLA_BEACH_OUTLINE' = 'umbrella-beach-outline',
  'UMBRELLA_CLOSED' = 'umbrella-closed',
  'UMBRELLA_CLOSED_OUTLINE' = 'umbrella-closed-outline',
  'UMBRELLA_CLOSED_VARIANT' = 'umbrella-closed-variant',
  'UMBRELLA_OUTLINE' = 'umbrella-outline',
  'UNDO' = 'undo',
  'UNDO_VARIANT' = 'undo-variant',
  'UNFOLD_LESS_HORIZONTAL' = 'unfold-less-horizontal',
  'CHEVRON_DOWN_UP' = 'chevron-down-up',
  'COLLAPSE_HORIZONTAL' = 'collapse-horizontal',
  'UNFOLD_LESS_VERTICAL' = 'unfold-less-vertical',
  'CHEVRON_RIGHT_LEFT' = 'chevron-right-left',
  'COLLAPSE_VERTICAL' = 'collapse-vertical',
  'UNFOLD_MORE_HORIZONTAL' = 'unfold-more-horizontal',
  'CHEVRON_UP_DOWN' = 'chevron-up-down',
  'EXPAND_HORIZONTAL' = 'expand-horizontal',
  'UNFOLD_MORE_VERTICAL' = 'unfold-more-vertical',
  'CHEVRON_LEFT_RIGHT' = 'chevron-left-right',
  'EXPAND_VERTICAL' = 'expand-vertical',
  'UNGROUP' = 'ungroup',
  'UNICODE' = 'unicode',
  'UNICORN' = 'unicorn',
  'FANTASY' = 'fantasy',
  'UNICORN_VARIANT' = 'unicorn-variant',
  'FANTASY_VARIANT' = 'fantasy-variant',
  'UNICYCLE' = 'unicycle',
  'UNITY' = 'unity',
  'UNREAL' = 'unreal',
  'UNREAL_ENGINE' = 'unreal-engine',
  'UPDATE' = 'update',
  'CLOCKWISE' = 'clockwise',
  'UPLOAD' = 'upload',
  'UPLOAD_LOCK' = 'upload-lock',
  'UPLOAD_LOCK_OUTLINE' = 'upload-lock-outline',
  'UPLOAD_MULTIPLE' = 'upload-multiple',
  'UPLOADS' = 'uploads',
  'UPLOAD_NETWORK' = 'upload-network',
  'UPLOAD_NETWORK_OUTLINE' = 'upload-network-outline',
  'UPLOAD_OFF' = 'upload-off',
  'UPLOAD_OFF_OUTLINE' = 'upload-off-outline',
  'UPLOAD_OUTLINE' = 'upload-outline',
  'USB' = 'usb',
  'USB_FLASH_DRIVE' = 'usb-flash-drive',
  'USB_FLASH_DRIVE_OUTLINE' = 'usb-flash-drive-outline',
  'USB_PORT' = 'usb-port',
  'VACUUM' = 'vacuum',
  'VACUUM_CLEANER' = 'vacuum-cleaner',
  'VACUUM_OUTLINE' = 'vacuum-outline',
  'VACUUM_CLEANER_OUTLINE' = 'vacuum-cleaner-outline',
  'VALVE' = 'valve',
  'VALVE_CLOSED' = 'valve-closed',
  'VALVE_OPEN' = 'valve-open',
  'VAN_PASSENGER' = 'van-passenger',
  'VAN_UTILITY' = 'van-utility',
  'VAN_CANDY' = 'van-candy',
  'VANISH' = 'vanish',
  'VANISH_QUARTER' = 'vanish-quarter',
  'VANITY_LIGHT' = 'vanity-light',
  'VARIABLE' = 'variable',
  'VARIABLE_BOX' = 'variable-box',
  'VECTOR_ARRANGE_ABOVE' = 'vector-arrange-above',
  'VECTOR_ARRANGE_BELOW' = 'vector-arrange-below',
  'VECTOR_BEZIER' = 'vector-bezier',
  'VECTOR_CIRCLE' = 'vector-circle',
  'VECTOR_CIRCLE_VARIANT' = 'vector-circle-variant',
  'VECTOR_COMBINE' = 'vector-combine',
  'VECTOR_CURVE' = 'vector-curve',
  'BEZIER' = 'bezier',
  'VECTOR_DIFFERENCE' = 'vector-difference',
  'VECTOR_DIFFERENCE_AB' = 'vector-difference-ab',
  'VECTOR_DIFFERENCE_BA' = 'vector-difference-ba',
  'VECTOR_ELLIPSE' = 'vector-ellipse',
  'VECTOR_INTERSECTION' = 'vector-intersection',
  'VECTOR_LINE' = 'vector-line',
  'VECTOR_LINK' = 'vector-link',
  'VECTOR_POINT' = 'vector-point',
  'VECTOR_POLYGON' = 'vector-polygon',
  'VECTOR_POLYGON_VARIANT' = 'vector-polygon-variant',
  'VECTOR_POLYLINE' = 'vector-polyline',
  'VECTOR_POLYLINE_EDIT' = 'vector-polyline-edit',
  'VECTOR_POLYLINE_MINUS' = 'vector-polyline-minus',
  'VECTOR_POLYLINE_PLUS' = 'vector-polyline-plus',
  'VECTOR_POLYLINE_REMOVE' = 'vector-polyline-remove',
  'VECTOR_RADIUS' = 'vector-radius',
  'VECTOR_RECTANGLE' = 'vector-rectangle',
  'VECTOR_SELECTION' = 'vector-selection',
  'VECTOR_SQUARE' = 'vector-square',
  'MDI' = 'mdi',
  'VECTOR_SQUARE_CLOSE' = 'vector-square-close',
  'VECTOR_SQUARE_EDIT' = 'vector-square-edit',
  'VECTOR_SQUARE_MINUS' = 'vector-square-minus',
  'VECTOR_SQUARE_SUBTRACT' = 'vector-square-subtract',
  'VECTOR_SQUARE_OPEN' = 'vector-square-open',
  'VECTOR_SQUARE_PLUS' = 'vector-square-plus',
  'VECTOR_SQUARE_ADD' = 'vector-square-add',
  'VECTOR_SQUARE_REMOVE' = 'vector-square-remove',
  'VECTOR_SQUARE_DELETE' = 'vector-square-delete',
  'VECTOR_TRIANGLE' = 'vector-triangle',
  'VECTOR_UNION' = 'vector-union',
  'VHS' = 'vhs',
  'VIDEO_HOME_SYSTEM' = 'video-home-system',
  'VHS_CASSETTE' = 'vhs-cassette',
  'VHS_TAPE' = 'vhs-tape',
  'VIBRATE' = 'vibrate',
  'VIBRATION' = 'vibration',
  'VIBRATE_OFF' = 'vibrate-off',
  'VIDEO' = 'video',
  'VIDEOCAM' = 'videocam',
  'VIDEO2D' = 'video2d',
  'VIDEO3D' = 'video3d',
  'VIDEO3D_OFF' = 'video3d-off',
  'VIDEO3D_VARIANT' = 'video3d-variant',
  'VIDEO4K_BOX' = 'video4k-box',
  'VIDEO_ACCOUNT' = 'video-account',
  'VIDEO_USER' = 'video-user',
  'VIDEO_BOX' = 'video-box',
  'VIDEO_BOX_OFF' = 'video-box-off',
  'VIDEO_CHECK' = 'video-check',
  'VIDEO_CHECK_OUTLINE' = 'video-check-outline',
  'VIDEO_HIGH_DEFINITION' = 'video-high-definition',
  'VIDEO_IMAGE' = 'video-image',
  'VIDEO_INPUT_ANTENNA' = 'video-input-antenna',
  'SETTINGS_INPUT_ANTENNA' = 'settings-input-antenna',
  'VIDEO_INPUT_COMPONENT' = 'video-input-component',
  'VIDEO_INPUT_COMPOSITE' = 'video-input-composite',
  'SETTINGS_INPUT_COMPONENT' = 'settings-input-component',
  'SETTINGS_INPUT_COMPOSITE' = 'settings-input-composite',
  'VIDEO_INPUT_YPBPR' = 'video-input-ypbpr',
  'RCA' = 'rca',
  'VIDEO_INPUT_HDMI' = 'video-input-hdmi',
  'SETTINGS_INPUT_HDMI' = 'settings-input-hdmi',
  'VIDEO_INPUT_SCART' = 'video-input-scart',
  'VIDEO_INPUT_SVIDEO' = 'video-input-svideo',
  'SETTINGS_INPUT_SVIDEO' = 'settings-input-svideo',
  'VIDEO_MARKER' = 'video-marker',
  'VIDEO_LOCATION' = 'video-location',
  'VIDEO_MARKER_OUTLINE' = 'video-marker-outline',
  'VIDEO_LOCATION_OUTLINE' = 'video-location-outline',
  'VIDEO_MINUS' = 'video-minus',
  'VIDEO_REMOVE' = 'video-remove',
  'VIDEO_MINUS_OUTLINE' = 'video-minus-outline',
  'VIDEO_OFF' = 'video-off',
  'VIDEOCAM_OFF' = 'videocam-off',
  'VIDEO_OFF_OUTLINE' = 'video-off-outline',
  'VIDEOCAM_OFF_OUTLINE' = 'videocam-off-outline',
  'VIDEO_OUTLINE' = 'video-outline',
  'VIDEOCAM_OUTLINE' = 'videocam-outline',
  'VIDEO_PLUS' = 'video-plus',
  'VIDEO_CALL' = 'video-call',
  'VIDEO_ADD' = 'video-add',
  'VIDEO_PLUS_OUTLINE' = 'video-plus-outline',
  'VIDEO_STABILIZATION' = 'video-stabilization',
  'VIDEO_STABILISATION' = 'video-stabilisation',
  'VIDEO_SWITCH' = 'video-switch',
  'SWITCH_VIDEO' = 'switch-video',
  'VIDEO_SWITCH_OUTLINE' = 'video-switch-outline',
  'VIDEO_VINTAGE' = 'video-vintage',
  'VIDEO_FILM' = 'video-film',
  'VIDEO_CLASSIC' = 'video-classic',
  'VIDEO_WIRELESS' = 'video-wireless',
  'VIDEO_WIRELESS_OUTLINE' = 'video-wireless-outline',
  'VIEW_AGENDA' = 'view-agenda',
  'VIEW_AGENDA_OUTLINE' = 'view-agenda-outline',
  'VIEW_ARRAY' = 'view-array',
  'VIEW_ARRAY_OUTLINE' = 'view-array-outline',
  'VIEW_CAROUSEL' = 'view-carousel',
  'VIEW_CAROUSEL_OUTLINE' = 'view-carousel-outline',
  'VIEW_COLUMN' = 'view-column',
  'VIEW_COLUMN_OUTLINE' = 'view-column-outline',
  'VIEW_COMFY' = 'view-comfy',
  'VIEW_COMFY_OUTLINE' = 'view-comfy-outline',
  'VIEW_COMPACT' = 'view-compact',
  'VIEW_COMPACT_OUTLINE' = 'view-compact-outline',
  'VIEW_DASHBOARD' = 'view-dashboard',
  'VIEW_DASHBOARD_EDIT' = 'view-dashboard-edit',
  'VIEW_DASHBOARD_EDIT_OUTLINE' = 'view-dashboard-edit-outline',
  'VIEW_DASHBOARD_OUTLINE' = 'view-dashboard-outline',
  'VIEW_DASHBOARD_VARIANT' = 'view-dashboard-variant',
  'VIEW_DASHBOARD_VARIANT_OUTLINE' = 'view-dashboard-variant-outline',
  'VIEW_DAY' = 'view-day',
  'VIEW_DAY_OUTLINE' = 'view-day-outline',
  'VIEW_GALLERY' = 'view-gallery',
  'VIEW_GALLERY_OUTLINE' = 'view-gallery-outline',
  'VIEW_GRID' = 'view-grid',
  'VIEW_GRID_OUTLINE' = 'view-grid-outline',
  'VIEW_GRID_PLUS' = 'view-grid-plus',
  'VIEW_GRID_ADD' = 'view-grid-add',
  'VIEW_GRID_PLUS_OUTLINE' = 'view-grid-plus-outline',
  'VIEW_HEADLINE' = 'view-headline',
  'VIEW_LIST' = 'view-list',
  'VIEW_LIST_OUTLINE' = 'view-list-outline',
  'VIEW_MODULE' = 'view-module',
  'VIEW_MODULE_OUTLINE' = 'view-module-outline',
  'VIEW_PARALLEL' = 'view-parallel',
  'VIEW_PARALLEL_OUTLINE' = 'view-parallel-outline',
  'VIEW_QUILT' = 'view-quilt',
  'VIEW_QUILT_OUTLINE' = 'view-quilt-outline',
  'VIEW_SEQUENTIAL' = 'view-sequential',
  'VIEW_SEQUENTIAL_OUTLINE' = 'view-sequential-outline',
  'VIEW_SPLIT_HORIZONTAL' = 'view-split-horizontal',
  'VIEW_SPLIT_VERTICAL' = 'view-split-vertical',
  'VIEW_STREAM' = 'view-stream',
  'VIEW_STREAM_OUTLINE' = 'view-stream-outline',
  'VIEW_WEEK' = 'view-week',
  'VIEW_WEEK_OUTLINE' = 'view-week-outline',
  'VIMEO' = 'vimeo',
  'VIOLIN' = 'violin',
  'VIRTUAL_REALITY' = 'virtual-reality',
  'VR' = 'vr',
  'VIRUS' = 'virus',
  'VIRUS_OFF' = 'virus-off',
  'VIRUS_OFF_OUTLINE' = 'virus-off-outline',
  'VIRUS_OUTLINE' = 'virus-outline',
  'VLC' = 'vlc',
  'VOICEMAIL' = 'voicemail',
  'VOLUME_HIGH' = 'volume-high',
  'AUDIO' = 'audio',
  'SPEAKERPHONE' = 'speakerphone',
  'VOLUME_LOW' = 'volume-low',
  'VOLUME_MEDIUM' = 'volume-medium',
  'VOLUME_MINUS' = 'volume-minus',
  'VOLUME_DECREASE' = 'volume-decrease',
  'VOLUME_MUTE' = 'volume-mute',
  'VOLUME_OFF' = 'volume-off',
  'MUTE' = 'mute',
  'AUDIO_OFF' = 'audio-off',
  'SPEAKERPHONE_OFF' = 'speakerphone-off',
  'VOLUME_PLUS' = 'volume-plus',
  'VOLUME_INCREASE' = 'volume-increase',
  'VOLUME_SOURCE' = 'volume-source',
  'VOLUME_VARIANT_OFF' = 'volume-variant-off',
  'VOLUME_VIBRATE' = 'volume-vibrate',
  'HOW_TO_VOTE' = 'how-to-vote',
  'HOW_TO_VOTE_OUTLINE' = 'how-to-vote-outline',
  'VPN' = 'vpn',
  'VIRTUAL_PRIVATE_NETWORK' = 'virtual-private-network',
  'VUEJS' = 'vuejs',
  'VUE_JS' = 'vue-js',
  'VUETIFY' = 'vuetify',
  'WALK' = 'walk',
  'DIRECTIONS_WALK' = 'directions-walk',
  'WALKER' = 'walker',
  'WALKING' = 'walking',
  'HUMAN_WALK' = 'human-walk',
  'WALL' = 'wall',
  'BRICKS' = 'bricks',
  'WALL_FIRE' = 'wall-fire',
  'FIREWALL' = 'firewall',
  'WALL_SCONCE' = 'wall-sconce',
  'WALL_SCONCE_FLAT' = 'wall-sconce-flat',
  'CEILING_LIGHT_FLAT' = 'ceiling-light-flat',
  'POT_LIGHT_FLAT' = 'pot-light-flat',
  'WALL_SCONCE_FLAT_OUTLINE' = 'wall-sconce-flat-outline',
  'WALL_SCONCE_FLAT_VARIANT' = 'wall-sconce-flat-variant',
  'POT_LIGHT_FLAT_VARIANT' = 'pot-light-flat-variant',
  'WALL_SCONCE_FLAT_VARIANT_OUTLINE' = 'wall-sconce-flat-variant-outline',
  'WALL_SCONCE_OUTLINE' = 'wall-sconce-outline',
  'WALL_SCONCE_ROUND' = 'wall-sconce-round',
  'POT_LIGHT_ROUND' = 'pot-light-round',
  'WALL_SCONCE_ROUND_OUTLINE' = 'wall-sconce-round-outline',
  'WALL_SCONCE_ROUND_VARIANT' = 'wall-sconce-round-variant',
  'POT_LIGHT_ROUND_VARIANT' = 'pot-light-round-variant',
  'WALL_SCONCE_ROUND_VARIANT_OUTLINE' = 'wall-sconce-round-variant-outline',
  'WALLET' = 'wallet',
  'ACCOUNT_BALANCE_WALLET' = 'account-balance-wallet',
  'WALLET_GIFTCARD' = 'wallet-giftcard',
  'CARD_GIFTCARD' = 'card-giftcard',
  'REDEEM' = 'redeem',
  'WALLET_MEMBERSHIP' = 'wallet-membership',
  'CARD_MEMBERSHIP' = 'card-membership',
  'WALLET_OUTLINE' = 'wallet-outline',
  'ACCOUNT_BALANCE_WALLET_OUTLINE' = 'account-balance-wallet-outline',
  'WALLET_PLUS' = 'wallet-plus',
  'WALLET_ADD' = 'wallet-add',
  'WALLET_PLUS_OUTLINE' = 'wallet-plus-outline',
  'WALLET_ADD_OUTLINE' = 'wallet-add-outline',
  'WALLET_TRAVEL' = 'wallet-travel',
  'CARD_TRAVEL' = 'card-travel',
  'WALLPAPER' = 'wallpaper',
  'WAN' = 'wan',
  'WIDE_AREA_NETWORK' = 'wide-area-network',
  'WARDROBE' = 'wardrobe',
  'WARDROBE_OUTLINE' = 'wardrobe-outline',
  'CLOSET_OUTLINE' = 'closet-outline',
  'WAREHOUSE' = 'warehouse',
  'WASHING_MACHINE' = 'washing-machine',
  'LAUNDRETTE' = 'laundrette',
  'LOCAL_LAUNDRY_SERVICE' = 'local-laundry-service',
  'WASHING_MACHINE_ALERT' = 'washing-machine-alert',
  'WASHING_MACHINE_OFF' = 'washing-machine-off',
  'WATCH_EXPORT' = 'watch-export',
  'WATCH_EXPORT_VARIANT' = 'watch-export-variant',
  'WATCH_IMPORT' = 'watch-import',
  'WATCH_IMPORT_VARIANT' = 'watch-import-variant',
  'WATCH_VARIANT' = 'watch-variant',
  'WATCH_VIBRATE' = 'watch-vibrate',
  'WATCH_VIBRATE_OFF' = 'watch-vibrate-off',
  'DROP' = 'drop',
  'WATER_DROP' = 'water-drop',
  'TRANS_FAT' = 'trans-fat',
  'INK' = 'ink',
  'WATER_ALERT' = 'water-alert',
  'DROP_ALERT' = 'drop-alert',
  'BLOOD_ALERT' = 'blood-alert',
  'INK_ALERT' = 'ink-alert',
  'WATER_ALERT_OUTLINE' = 'water-alert-outline',
  'DROP_ALERT_OUTLINE' = 'drop-alert-outline',
  'BLOOD_ALERT_OUTLINE' = 'blood-alert-outline',
  'INK_ALERT_OUTLINE' = 'ink-alert-outline',
  'WATER_BOILER' = 'water-boiler',
  'WATER_HEATER' = 'water-heater',
  'GAS_WATER_BOILER' = 'gas-water-boiler',
  'ELECTRIC_WATER_BOILER' = 'electric-water-boiler',
  'GAS_WATER_HEATER' = 'gas-water-heater',
  'ELECTRIC_WATER_HEATER' = 'electric-water-heater',
  'WATER_BOILER_ALERT' = 'water-boiler-alert',
  'WATER_HEATER_ALERT' = 'water-heater-alert',
  'WATER_BOILER_ERROR' = 'water-boiler-error',
  'WATER_HEATER_ERROR' = 'water-heater-error',
  'WATER_BOILER_OFF' = 'water-boiler-off',
  'WATER_HEATER_OFF' = 'water-heater-off',
  'WATER_CHECK' = 'water-check',
  'DROP_CHECK' = 'drop-check',
  'BLOOD_CHECK' = 'blood-check',
  'INK_CHECK' = 'ink-check',
  'WATER_CHECK_OUTLINE' = 'water-check-outline',
  'DROP_CHECK_OUTLINE' = 'drop-check-outline',
  'BLOOD_CHECK_OUTLINE' = 'blood-check-outline',
  'INK_CHECK_OUTLINE' = 'ink-check-outline',
  'WATER_CIRCLE' = 'water-circle',
  'DROP_CIRCLE' = 'drop-circle',
  'BLOOD_CIRCLE' = 'blood-circle',
  'INK_CIRCLE' = 'ink-circle',
  'WATER_MINUS' = 'water-minus',
  'DROP_MINUS' = 'drop-minus',
  'BLOOD_MINUS' = 'blood-minus',
  'INK_MINUS' = 'ink-minus',
  'WATER_MINUS_OUTLINE' = 'water-minus-outline',
  'DROP_MINUS_OUTLINE' = 'drop-minus-outline',
  'BLOOD_MINUS_OUTLINE' = 'blood-minus-outline',
  'INK_MINUS_OUTLINE' = 'ink-minus-outline',
  'WATER_OFF' = 'water-off',
  'FORMAT_COLOR_RESET' = 'format-color-reset',
  'TRANS_FAT_OFF' = 'trans-fat-off',
  'BLOOD_OFF' = 'blood-off',
  'INK_OFF' = 'ink-off',
  'WATER_OFF_OUTLINE' = 'water-off-outline',
  'DROP_OFF_OUTLINE' = 'drop-off-outline',
  'BLOOD_OFF_OUTLINE' = 'blood-off-outline',
  'TRANS_FAT_OFF_OUTLINE' = 'trans-fat-off-outline',
  'INK_OFF_OUTLINE' = 'ink-off-outline',
  'WATER_OPACITY' = 'water-opacity',
  'WATER_TRANSPARENT' = 'water-transparent',
  'WATER_SAVER' = 'water-saver',
  'BLOOD_SAVER' = 'blood-saver',
  'BLOOD_TRANSPARENT' = 'blood-transparent',
  'OIL_SAVER' = 'oil-saver',
  'OIL_TRANSPARENT' = 'oil-transparent',
  'DROP_TRANSPARENT' = 'drop-transparent',
  'DROP_SAVER' = 'drop-saver',
  'WATER_OUTLINE' = 'water-outline',
  'DROP_OUTLINE' = 'drop-outline',
  'BLOOD_OUTLINE' = 'blood-outline',
  'WATER_DROP_OUTLINE' = 'water-drop-outline',
  'INK_OUTLINE' = 'ink-outline',
  'WATER_PERCENT' = 'water-percent',
  'HUMIDITY' = 'humidity',
  'INK_PERCENT' = 'ink-percent',
  'WATER_PERCENT_ALERT' = 'water-percent-alert',
  'HUMIDITY_ALERT' = 'humidity-alert',
  'INK_PERCENT_ALERT' = 'ink-percent-alert',
  'WATER_PLUS' = 'water-plus',
  'DROP_PLUS' = 'drop-plus',
  'BLOOD_PLUS' = 'blood-plus',
  'INK_PLUS' = 'ink-plus',
  'WATER_PLUS_OUTLINE' = 'water-plus-outline',
  'DROP_PLUS_OUTLINE' = 'drop-plus-outline',
  'BLOOD_PLUS_OUTLINE' = 'blood-plus-outline',
  'INK_PLUS_OUTLINE' = 'ink-plus-outline',
  'WATER_POLO' = 'water-polo',
  'WATER_PUMP' = 'water-pump',
  'TAP' = 'tap',
  'KITCHEN_TAP' = 'kitchen-tap',
  'FAUCET' = 'faucet',
  'WATER_PUMP_OFF' = 'water-pump-off',
  'TAP_OFF' = 'tap-off',
  'KITCHEN_TAP_OFF' = 'kitchen-tap-off',
  'FAUCET_OFF' = 'faucet-off',
  'WATER_REMOVE' = 'water-remove',
  'DROP_REMOVE' = 'drop-remove',
  'BLOOD_REMOVE' = 'blood-remove',
  'INK_REMOVE' = 'ink-remove',
  'WATER_REMOVE_OUTLINE' = 'water-remove-outline',
  'DROP_REMOVE_OUTLINE' = 'drop-remove-outline',
  'BLOOD_REMOVE_OUTLINE' = 'blood-remove-outline',
  'INK_REMOVE_OUTLINE' = 'ink-remove-outline',
  'WATER_SYNC' = 'water-sync',
  'WATER_RECYCLE' = 'water-recycle',
  'WATER_REUSE' = 'water-reuse',
  'WATER_WELL' = 'water-well',
  'WATER_WELL_OUTLINE' = 'water-well-outline',
  'WATERFALL' = 'waterfall',
  'WATERING_CAN' = 'watering-can',
  'WATERING_POT' = 'watering-pot',
  'WATERING_CAN_OUTLINE' = 'watering-can-outline',
  'WATERING_POT_OUTLINE' = 'watering-pot-outline',
  'WATERMARK' = 'watermark',
  'BRANDING_WATERMARK' = 'branding-watermark',
  'WAVEFORM' = 'waveform',
  'WAVES' = 'waves',
  'OCEAN' = 'ocean',
  'LAKE' = 'lake',
  'FLOOD' = 'flood',
  'WAVES_ARROW_LEFT' = 'waves-arrow-left',
  'TIDE_IN' = 'tide-in',
  'WATER_FLOW' = 'water-flow',
  'WAVES_ARROW_RIGHT' = 'waves-arrow-right',
  'TIDE_OUT' = 'tide-out',
  'WAVES_ARROW_UP' = 'waves-arrow-up',
  'WATER_EVAPORATION' = 'water-evaporation',
  'SEA_LEVEL_RISE' = 'sea-level-rise',
  'OCEAN_LEVEL_RISE' = 'ocean-level-rise',
  'CLIMATE_CHANGE' = 'climate-change',
  'WAZE' = 'waze',
  'WEATHER_CLOUDY' = 'weather-cloudy',
  'WEATHER_CLOUDY_ALERT' = 'weather-cloudy-alert',
  'WEATHER_CLOUDY_ARROW_RIGHT' = 'weather-cloudy-arrow-right',
  'WEATHER_CLOUDY_CLOCK' = 'weather-cloudy-clock',
  'WEATHER_HISTORY' = 'weather-history',
  'WEATHER_TIME' = 'weather-time',
  'WEATHER_DATE' = 'weather-date',
  'WEATHER_FOG' = 'weather-fog',
  'WEATHER_MIST' = 'weather-mist',
  'WEATHER_HAIL' = 'weather-hail',
  'WEATHER_HAZY' = 'weather-hazy',
  'WEATHER_HURRICANE' = 'weather-hurricane',
  'CYCLONE' = 'cyclone',
  'WEATHER_LIGHTNING' = 'weather-lightning',
  'WEATHER_STORM' = 'weather-storm',
  'WEATHER_THUNDER' = 'weather-thunder',
  'WEATHER_FLASH' = 'weather-flash',
  'WEATHER_LIGHTNING_RAINY' = 'weather-lightning-rainy',
  'WEATHER_THUNDER_RAINY' = 'weather-thunder-rainy',
  'WEATHER_NIGHT' = 'weather-night',
  'MOON_AND_STARS' = 'moon-and-stars',
  'NIGHT_SKY' = 'night-sky',
  'WEATHER_NIGHT_PARTLY_CLOUDY' = 'weather-night-partly-cloudy',
  'WEATHER_PARTLY_CLOUDY' = 'weather-partly-cloudy',
  'WEATHER_PARTLYCLOUDY' = 'weather-partlycloudy',
  'WEATHER_PARTLY_LIGHTNING' = 'weather-partly-lightning',
  'WEATHER_PARTLY_RAINY' = 'weather-partly-rainy',
  'WEATHER_PARTLY_SNOWY' = 'weather-partly-snowy',
  'WEATHER_PARTLY_SNOWY_RAINY' = 'weather-partly-snowy-rainy',
  'WEATHER_POURING' = 'weather-pouring',
  'WEATHER_HEAVY_RAIN' = 'weather-heavy-rain',
  'WEATHER_RAINY' = 'weather-rainy',
  'WEATHER_DRIZZLE' = 'weather-drizzle',
  'WEATHER_SPITTING' = 'weather-spitting',
  'WEATHER_SNOWY' = 'weather-snowy',
  'WEATHER_SNOWY_HEAVY' = 'weather-snowy-heavy',
  'FLURRIES' = 'flurries',
  'WEATHER_SNOWY_RAINY' = 'weather-snowy-rainy',
  'WEATHER_SLEET' = 'weather-sleet',
  'WEATHER_SUNNY' = 'weather-sunny',
  'WEATHER_SUNNY_ALERT' = 'weather-sunny-alert',
  'HEAT_ALERT' = 'heat-alert',
  'HEAT_ADVISORY' = 'heat-advisory',
  'SUN_ADVISORY' = 'sun-advisory',
  'WEATHER_SUNNY_OFF' = 'weather-sunny-off',
  'WEATHER_SUNSET' = 'weather-sunset',
  'WEATHER_SUNSET_DOWN' = 'weather-sunset-down',
  'WEATHER_SUNSET_UP' = 'weather-sunset-up',
  'SUNRISE' = 'sunrise',
  'WEATHER_TORNADO' = 'weather-tornado',
  'WEATHER_WINDY' = 'weather-windy',
  'WEATHER_WINDY_VARIANT' = 'weather-windy-variant',
  'WEB' = 'web',
  'INTERNET' = 'internet',
  'WORLD_WIDE_WEB' = 'world-wide-web',
  'WEB_BOX' = 'web-box',
  'LANGUAGE_BOX' = 'language-box',
  'INTERNET_BOX' = 'internet-box',
  'WEB_CANCEL' = 'web-cancel',
  'WEB_CHECK' = 'web-check',
  'WEB_CLOCK' = 'web-clock',
  'WEB_MINUS' = 'web-minus',
  'WEB_OFF' = 'web-off',
  'WEB_PLUS' = 'web-plus',
  'WEB_REFRESH' = 'web-refresh',
  'WEB_REMOVE' = 'web-remove',
  'WEB_SYNC' = 'web-sync',
  'WEBCAM' = 'webcam',
  'WEB_CAMERA' = 'web-camera',
  'WEBCAM_OFF' = 'webcam-off',
  'WEBHOOK' = 'webhook',
  'WEBPACK' = 'webpack',
  'WEBRTC' = 'webrtc',
  'WECHAT' = 'wechat',
  'WEIGHT' = 'weight',
  'WEIGHT_GRAM' = 'weight-gram',
  'WEIGHT_KILOGRAM' = 'weight-kilogram',
  'WEIGHT_KG' = 'weight-kg',
  'WEIGHT_LIFTER' = 'weight-lifter',
  'CROSSFIT' = 'crossfit',
  'HUMAN_BARBELL' = 'human-barbell',
  'WEIGHT_POUND' = 'weight-pound',
  'WEIGHT_LB' = 'weight-lb',
  'WHATSAPP' = 'whatsapp',
  'WHEEL_BARROW' = 'wheel-barrow',
  'WHEELCHAIR_ACCESSIBILITY' = 'wheelchair-accessibility',
  'ACCESSIBLE' = 'accessible',
  'WHISTLE' = 'whistle',
  'WHISTLE_OUTLINE' = 'whistle-outline',
  'WHITE_BALANCE_AUTO' = 'white-balance-auto',
  'WB_AUTO' = 'wb-auto',
  'WHITE_BALANCE_INCANDESCENT' = 'white-balance-incandescent',
  'WB_INCANDESCENT' = 'wb-incandescent',
  'WHITE_BALANCE_IRIDESCENT' = 'white-balance-iridescent',
  'WB_IRIDESCENT' = 'wb-iridescent',
  'WHITE_BALANCE_SUNNY' = 'white-balance-sunny',
  'WB_SUNNY' = 'wb-sunny',
  'WIDGETS' = 'widgets',
  'WIDGETS_OUTLINE' = 'widgets-outline',
  'WIFI' = 'wifi',
  'WIFI_ALERT' = 'wifi-alert',
  'WIFI_ARROW_DOWN' = 'wifi-arrow-down',
  'WIFI_ARROW_LEFT' = 'wifi-arrow-left',
  'WIFI_ARROW_LEFT_RIGHT' = 'wifi-arrow-left-right',
  'WIFI_ARROW_RIGHT' = 'wifi-arrow-right',
  'WIFI_ARROW_UP' = 'wifi-arrow-up',
  'WIFI_ARROW_UP_DOWN' = 'wifi-arrow-up-down',
  'WIFI_CANCEL' = 'wifi-cancel',
  'WIFI_CHECK' = 'wifi-check',
  'WIFI_COG' = 'wifi-cog',
  'WIFI_LOCK' = 'wifi-lock',
  'WIFI_LOCK_OPEN' = 'wifi-lock-open',
  'WIFI_MARKER' = 'wifi-marker',
  'WIFI_LOCATION' = 'wifi-location',
  'WIFI_MINUS' = 'wifi-minus',
  'WIFI_OFF' = 'wifi-off',
  'WIFI_PLUS' = 'wifi-plus',
  'WIFI_REFRESH' = 'wifi-refresh',
  'WIFI_REMOVE' = 'wifi-remove',
  'WIFI_SETTINGS' = 'wifi-settings',
  'WIFI_STAR' = 'wifi-star',
  'WIFI_FAVOURITE' = 'wifi-favourite',
  'NETWORK_FAVOURITE' = 'network-favourite',
  'WIFI_FAVORITE' = 'wifi-favorite',
  'NETWORK_FAVORITE' = 'network-favorite',
  'WIFI_STRENGTH1' = 'wifi-strength1',
  'WIFI_STRENGTH1ALERT' = 'wifi-strength1alert',
  'WIFI_STRENGTH1LOCK' = 'wifi-strength1lock',
  'WIFI_STRENGTH1LOCK_OPEN' = 'wifi-strength1lock-open',
  'WIFI_STRENGTH2' = 'wifi-strength2',
  'WIFI_STRENGTH2ALERT' = 'wifi-strength2alert',
  'WIFI_STRENGTH2LOCK' = 'wifi-strength2lock',
  'WIFI_STRENGTH2LOCK_OPEN' = 'wifi-strength2lock-open',
  'WIFI_STRENGTH3' = 'wifi-strength3',
  'WIFI_STRENGTH3ALERT' = 'wifi-strength3alert',
  'WIFI_STRENGTH3LOCK' = 'wifi-strength3lock',
  'WIFI_STRENGTH3LOCK_OPEN' = 'wifi-strength3lock-open',
  'WIFI_STRENGTH4' = 'wifi-strength4',
  'WIFI_STRENGTH4ALERT' = 'wifi-strength4alert',
  'WIFI_STRENGTH4LOCK' = 'wifi-strength4lock',
  'WIFI_STRENGTH4LOCK_OPEN' = 'wifi-strength4lock-open',
  'WIFI_STRENGTH_ALERT_OUTLINE' = 'wifi-strength-alert-outline',
  'WIFI_STRENGTH_WARNING_OUTLINE' = 'wifi-strength-warning-outline',
  'WIFI_STRENGTH_0_ALERT' = 'wifi-strength-0-alert',
  'WIFI_STRENGTH_0_WARNING' = 'wifi-strength-0-warning',
  'WIFI_STRENGTH_LOCK_OPEN_OUTLINE' = 'wifi-strength-lock-open-outline',
  'WIFI_STRENGTH_LOCK_OUTLINE' = 'wifi-strength-lock-outline',
  'WIFI_STRENGTH_0_LOCK' = 'wifi-strength-0-lock',
  'WIFI_STRENGTH_OFF' = 'wifi-strength-off',
  'WIFI_STRENGTH_OFF_OUTLINE' = 'wifi-strength-off-outline',
  'WIFI_STRENGTH_OUTLINE' = 'wifi-strength-outline',
  'WIFI_STRENGTH_0' = 'wifi-strength-0',
  'WIFI_SYNC' = 'wifi-sync',
  'WIKIPEDIA' = 'wikipedia',
  'WIND_TURBINE' = 'wind-turbine',
  'WIND_POWER' = 'wind-power',
  'WIND_ELECTRICITY' = 'wind-electricity',
  'WIND_TURBINE_ALERT' = 'wind-turbine-alert',
  'WIND_POWER_ALERT' = 'wind-power-alert',
  'WIND_TURBINE_WARNING' = 'wind-turbine-warning',
  'WIND_TURBINE_CHECK' = 'wind-turbine-check',
  'WIND_POWER_CHECK' = 'wind-power-check',
  'WIND_TURBINE_SUCCESS' = 'wind-turbine-success',
  'WIND_POWER_SUCCESS' = 'wind-power-success',
  'WINDOW_CLOSE' = 'window-close',
  'WINDOW_CLOSED_VARIANT' = 'window-closed-variant',
  'WINDOW_MAXIMIZE' = 'window-maximize',
  'WINDOW_MINIMIZE' = 'window-minimize',
  'WINDOW_OPEN_VARIANT' = 'window-open-variant',
  'WINDOW_RESTORE' = 'window-restore',
  'WINDOW_SHUTTER' = 'window-shutter',
  'WINDOW_SHUTTER_ALERT' = 'window-shutter-alert',
  'WINDOW_SHUTTER_OPEN' = 'window-shutter-open',
  'WINDSOCK' = 'windsock',
  'WIPER' = 'wiper',
  'WIPER_WASH' = 'wiper-wash',
  'WIPER_FLUID' = 'wiper-fluid',
  'WASHER_FLUID' = 'washer-fluid',
  'WIPER_WASH_ALERT' = 'wiper-wash-alert',
  'WIPER_FLUID_ALERT' = 'wiper-fluid-alert',
  'WASHER_FLUID_ALERT' = 'washer-fluid-alert',
  'WIPER_FLUID_LOW' = 'wiper-fluid-low',
  'WASHER_FLUID_LOW' = 'washer-fluid-low',
  'WIZARD_HAT' = 'wizard-hat',
  'WORDPRESS' = 'wordpress',
  'WRAP' = 'wrap',
  'WRAP_DISABLED' = 'wrap-disabled',
  'UNWRAP' = 'unwrap',
  'BUILD' = 'build',
  'SPANNER' = 'spanner',
  'WRENCH_CLOCK' = 'wrench-clock',
  'SCHEDULED_MAINTENANCE' = 'scheduled-maintenance',
  'WRENCH_TIME' = 'wrench-time',
  'TOOL_TIME' = 'tool-time',
  'TOOL_CLOCK' = 'tool-clock',
  'WRENCH_OUTLINE' = 'wrench-outline',
  'BUILD_OUTLINE' = 'build-outline',
  'SPANNER_OUTLINE' = 'spanner-outline',
  'XAMARIN' = 'xamarin',
  'MICROSOFT_XAMARIN' = 'microsoft-xamarin',
  'XML' = 'xml',
  'CODE' = 'code',
  'XMPP' = 'xmpp',
  'YAHOO' = 'yahoo',
  'YEAST' = 'yeast',
  'YIN_YANG' = 'yin-yang',
  'TAOISM' = 'taoism',
  'YOGA' = 'yoga',
  'YOUTUBE' = 'youtube',
  'VIDEO_YOUTUBE' = 'video-youtube',
  'YOUTUBE_PLAY' = 'youtube-play',
  'YOUTUBE_GAMING' = 'youtube-gaming',
  'YOUTUBE_STUDIO' = 'youtube-studio',
  'YOUTUBE_CREATOR_STUDIO' = 'youtube-creator-studio',
  'YOUTUBE_SUBSCRIPTION' = 'youtube-subscription',
  'YOUTUBE_TV' = 'youtube-tv',
  'YURT' = 'yurt',
  'ZWAVE' = 'zwave',
  'ZEND' = 'zend',
  'ZIGBEE' = 'zigbee',
  'ZIP_BOX' = 'zip-box',
  'COMPRESSED_FILE' = 'compressed-file',
  'ZIP_BOX_OUTLINE' = 'zip-box-outline',
  'COMPRESSED_FILE_OUTLINE' = 'compressed-file-outline',
  'ZIP_DISK' = 'zip-disk',
  'ZODIAC_AQUARIUS' = 'zodiac-aquarius',
  'HOROSCOPE_AQUARIUS' = 'horoscope-aquarius',
  'ZODIAC_ARIES' = 'zodiac-aries',
  'HOROSCOPE_ARIES' = 'horoscope-aries',
  'ZODIAC_CANCER' = 'zodiac-cancer',
  'HOROSCOPE_CANCER' = 'horoscope-cancer',
  'ZODIAC_CAPRICORN' = 'zodiac-capricorn',
  'HOROSCOPE_CAPRICORN' = 'horoscope-capricorn',
  'ZODIAC_GEMINI' = 'zodiac-gemini',
  'HOROSCOPE_GEMINI' = 'horoscope-gemini',
  'ZODIAC_LEO' = 'zodiac-leo',
  'HOROSCOPE_LEO' = 'horoscope-leo',
  'ZODIAC_LIBRA' = 'zodiac-libra',
  'HOROSCOPE_LIBRA' = 'horoscope-libra',
  'ZODIAC_PISCES' = 'zodiac-pisces',
  'HOROSCOPE_PISCES' = 'horoscope-pisces',
  'ZODIAC_SAGITTARIUS' = 'zodiac-sagittarius',
  'HOROSCOPE_SAGITTARIUS' = 'horoscope-sagittarius',
  'ZODIAC_SCORPIO' = 'zodiac-scorpio',
  'HOROSCOPE_SCORPIO' = 'horoscope-scorpio',
  'ZODIAC_TAURUS' = 'zodiac-taurus',
  'HOROSCOPE_TAURUS' = 'horoscope-taurus',
  'ZODIAC_VIRGO' = 'zodiac-virgo',
  'HOROSCOPE_VIRGO' = 'horoscope-virgo',
  'OVERSCORE' = 'overscore',
  'UNDERSCORE' = 'underscore',
  'LOADING_SPINNER' = 'loading-spinner'
}
export default Icons;
