import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import FormInput from '../form/formInput';
import SidebarLinkLg from './sidebarLinkLg';
import SidebarLinkSm from './sidebarLinkSm';
import { Link } from 'react-router-dom';
import UserProfilePopover from '@src/auth/userProfilePopover';

let Sidebar = (props) => {
  let { sidebarOpen, setSidebarOpen, links, showSearch, searchField, title, useUser, logo, checkRoles } = props;
  let [search, setSearch] = useState('');
  //@ts-ignore
  let user = useUser();
  let myLinks = links.filter((n) => !n.index && (!n.requiredRoles?.length || checkRoles(n.requiredRoles, user?.roles)));
  if (search) {
    myLinks = links.filter((n) => n.name.toLowerCase().includes(search.toLowerCase()));
  }
  myLinks = myLinks.sort((a, b) =>
    a.group === b.group && a.group === 'Admin' ? (a.label > b.label ? -1 : 1) : a.group > b.group ? 1 : -1
  );
  let linkGroups: { [key: string]: any[] } = {
    _: []
  };
  myLinks.forEach((l) => {
    if (!linkGroups[l.group || '_']) {
      linkGroups[l.group || '_'] = [];
    }
    linkGroups[l.group || '_'].push(l);
  });
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-zinc-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-4 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex items-center justify-center flex-shrink-0 px-6">
                <div className="text-blue-800 dark:text-blue-400 text-4xl justify-center font-black">{title}</div>
              </div>
              <div className="pt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {myLinks.map((item) => (
                      <SidebarLinkSm key={item.label} item={item} setSidebarOpen={setSidebarOpen} />
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-white lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-zinc-200 lg:pt-4 lg:pb-4">
        <div className="flex items-center justify-center flex-shrink-0 px-6">
          <Link to="/" className="text-blue-800 dark:text-blue-400 text-4xl justify-center font-black">
            {logo || title}
          </Link>
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="pt-6 h-0 flex-1 flex flex-col overflow-y-auto">
          {/* User account dropdown */}
          {/* Sidebar Search */}
          {showSearch && (
            <FormInput
              placeholder="Search"
              icon="magnify"
              showClear={true}
              input={{ value: search, onChange: setSearch }}
              divClassName="mt-5 ml-0 px-3"
            />
          )}
          {searchField}
          {/* Navigation */}
          <nav className="px-3 mt-6 flex-auto">
            {Object.entries(linkGroups)
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .map(([k, v]) => (
                <div key={k} className="space-y-1 mb-8">
                  {k !== '_' && (
                    <h3
                      className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                      id="projects-headline">
                      {k}
                    </h3>
                  )}
                  {v
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((item) => (
                      <SidebarLinkLg key={item.label} item={item} />
                    ))}
                </div>
              ))}
          </nav>
          <UserProfilePopover {...props} />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
