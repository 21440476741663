import React from 'react';
import Icon, { IconSize } from './icon';
import { overrideClassName } from './index';

export enum AlertMode {
  PRIMARY = 'Primary',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger'
}

let Alert = (props) => {
  let {
    children,
    mode = AlertMode.PRIMARY,
    icon,
    actions,
    closeAction,
    label = 'Alert Title',
    loading,
    className
  } = props;
  let modeStyles = {
    [AlertMode.PRIMARY]: `bg-primaryAlertBg-50`,
    [AlertMode.SUCCESS]: `bg-successAlertBg-50`,
    [AlertMode.WARNING]: `bg-warningAlertBg-50`,
    [AlertMode.DANGER]: `bg-dangerAlertBg-50`
  };
  let buttonHoverStyles = {
    [AlertMode.PRIMARY]: `hover:bg-primaryAlertHover-50`,
    [AlertMode.SUCCESS]: `hover:bg-successAlertHover-50`,
    [AlertMode.WARNING]: `hover:bg-warningAlertHover-50`,
    [AlertMode.DANGER]: `hover:bg-dangerAlertHover-50`
  };
  let textStyles = {
    [AlertMode.PRIMARY]: 'text-primaryAlertText-50',
    [AlertMode.SUCCESS]: 'text-successAlertText-50',
    [AlertMode.WARNING]: 'text-warningAlertText-50',
    [AlertMode.DANGER]: 'text-dangerAlertText-50'
  };
  let icons = {
    [AlertMode.PRIMARY]: 'information',
    [AlertMode.SUCCESS]: 'check-circle',
    [AlertMode.WARNING]: 'alert-circle',
    [AlertMode.DANGER]: 'alert-circle'
  };

  let iconClassName;
  if (loading) {
    icon = 'loading';
    iconClassName = 'animate-spin';
  }

  return (
    <div className={overrideClassName(`rounded-md ${modeStyles[mode]} p-4`, className)} data-testid="alert-container">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            mode={mode}
            size={IconSize.MD}
            icon={icon || icons[mode]}
            aria-hidden="true"
            className={iconClassName}
          />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textStyles[mode]}`}>{label}</h3>
          {React.Children.toArray(children).length ? (
            <div className={`mt-2 text-sm ${textStyles[mode]}`}>{children}</div>
          ) : null}
          {actions && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                {actions.map((a, ai) => (
                  <button
                    key={ai}
                    type="button"
                    onClick={a.onClick}
                    className={`${modeStyles[mode]} px-2 py-1.5 rounded-md text-sm font-medium ${textStyles[mode]} ${buttonHoverStyles[mode]} focus:outline-none focus:ring-2 focus:ring-offset-2`}>
                    {a.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {closeAction && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <Icon icon="close" aria-hidden={true} size={IconSize.MD} mode={mode} onClick={closeAction} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;
