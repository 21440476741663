let fakeKeys = {
  hidden: 'display',
  block: 'display',
  flex: 'display',
  inline: 'display',
  'inline-flex': 'display',
  'inline-block': 'display',
  grid: 'display',
  'inline-grid': 'display',
  uppercase: 'textTransform',
  lowercase: 'textTransform',
  capitalize: 'textTransform',
  'flex-row': 'flexDirection',
  'flex-row-reverse': 'flexDirection',
  'flex-col': 'flexDirection',
  'flex-col-reverse': 'flexDirection',
  'flex-wrap': 'flexWrap',
  'flex-wrap-reverse': 'flexWrap',
  'flex-nowrap': 'flexWrap',
  'flex-1': 'flexx',
  'flex-auto': 'flexx',
  'flex-initial': 'flexx',
  'flex-none': 'flexx',
  'flex-grow-0': 'flexGrow',
  'flex-grow': 'flexGrow',
  'flex-shrink-0': 'flexShrink',
  'flex-shrink': 'flexShrink',
  'whitespace-normal': 'whitespace',
  'whitespace-nowrap': 'whitespace',
  'whitespace-pre': 'whitespace',
  'whitespace-pre-line': 'whitespace',
  'whitespace-pre-wrap': 'whitespace'
};

let textKeys = {
  xs: 'fontSize',
  sm: 'fontSize',
  base: 'fontSize',
  lg: 'fontSize',
  xl: 'fontSize',
  '2xl': 'fontSize',
  '3xl': 'fontSize',
  '4xl': 'fontSize',
  '5xl': 'fontSize',
  '6xl': 'fontSize',
  '7xl': 'fontSize',
  '8xl': 'fontSize',
  '9xl': 'fontSize',
  left: 'textAlign',
  center: 'textAlign',
  right: 'textAlign',
  justify: 'textAlign'
};

let bgKeys = {
  bg_position: [
    'auto',
    'cover',
    'contain',
    'bottom',
    'top',
    'center',
    'left',
    'fixed',
    'scroll',
    'no-repeat',
    'repeat',
    'repeat-x',
    'repeat-y'
  ],
  bg_opacity: ['opacity'],
  bg_clip: ['clip'],
  bg_gradient: ['gradient']
};

let processClass = (cName, classObject) => {
  if (cName?.startsWith('text-')) {
    let c3s = cName.split('-');
    if (textKeys[c3s[1]]) {
      classObject[textKeys[c3s[1]]] = c3s[1];
    } else if (c3s[1] === 'opacity') {
      classObject.textOpacity = c3s[2];
    } else if (['ring', 'bg'].includes(c3s[1])) {
      classObject[c3s[1]] = c3s.slice(1).join('-');
    } else {
      //color
      classObject.textColor = c3s.slice(1).join('-');
    }
  } else if (fakeKeys[cName]) {
    classObject[fakeKeys[cName]] = cName;
  } else {
    /*let i = cName.lastIndexOf('-');
    if (i > -1) {
      classObject[cName.substring(0, i)] = cName.substring(i + 1);
    } else {
      classObject[cName] = true;
    }*/
    let i = cName.lastIndexOf('-');
    if (i > -1) {
      if (classObject[cName]) {
        //find non-shorthand version and replace it
        delete classObject[cName];
        classObject[cName.substring(0, i)] = cName.substring(i + 1);
      } else if (classObject[cName.split('-')[0]] === cName.split('-')[1]) {
        //overwrite shortand version
        delete classObject[cName.split('-')[0]];
        classObject[cName.substring(0, i)] = cName.substring(i + 1);
      } else {
        let [zero, one, ...rest] = cName.split('-');
        if (zero === 'bg') {
          let bgKey = Object.keys(bgKeys).find((k) => {
            let v = bgKeys[k];
            return v.some((vv) => vv === one);
          });
          if (!bgKey) {
            bgKey = 'bg_color';
          }
          classObject[bgKey] = `${one}${rest?.length ? `-${rest.join('-')}` : ''}`;
        } else {
          classObject[cName.substring(0, i)] = cName.substring(i + 1);
        }
      }
    } else {
      classObject[cName] = true;
    }
  }
};

let overrideClassName = (oldClassnames, newClassnames, options?) => {
  let classObject = {};
  oldClassnames?.split(' ').forEach((oldCname) => {
    processClass(oldCname, classObject);
  });
  newClassnames?.split(' ').forEach((newCname) => {
    processClass(newCname, classObject);
  });
  let ret = Object.entries(classObject)
    .map(([key, value]: [string, any]) => {
      if (['fontSize', 'textAlign'].includes(key)) {
        return `text-${value}`;
      } else if (key === 'textOpacity') {
        return `text-opacity-${value}`;
      } else if (key === 'textColor') {
        return `text-${value}`;
      } else if (key.startsWith('bg_')) {
        return `bg-${value}`;
      } else if (['ring', 'bg'].includes(key)) {
        return `${key}-${value}`;
      } else if (fakeKeys[value] && fakeKeys[value] === key) {
        return value;
      } else if (value === true) {
        return key;
      } else {
        return `${key}-${value}`;
      }
    })
    .join(' ');
  if (options?.debug) {
    console.log({ oldClassnames, newClassnames, ret });
  }
  return ret;
};
export default overrideClassName;
