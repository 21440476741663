import React from 'react';
import LayoutPlot from '@src/plots/layoutPlot';

let LayoutPlots = (props) => {
  let { plots, plotClick, ...rest } = props;
  return (
    <>
      {plots.map((p, pi) => (
        <LayoutPlot key={p.id} plot={p} onClick={plotClick.bind(null, p)} {...rest} count={plots.length} pi={pi} />
      ))}
    </>
  );
};
export default LayoutPlots;
