import React, { useCallback, useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import FormFooterFull from './formFooterFull';
import arrayMutators from 'final-form-arrays';
import PageHeading from '../pageHeading';
import { overrideClassName, InspectorModal, Tabs, Icon, Icons } from '../index';
import { deleteWrapper, submitWrapper, publishWrapper } from './formUtils';
import { Actions } from '../actions';
import AbsoluteFormWrapper from './absoluteFormWrapper';
import { FormContext } from '../index';

export enum FormMode {
  FULL_PAGE = 'fullPage',
  MODAL = 'modal'
}

let FormTemplate = (props) => {
  let {
    children,
    closeAction,
    deleteAction,
    deleteConfirm,
    deleteLabel,
    deleteIcon,
    publishAction,
    publishConfirm,
    publishLabel,
    publishIcon,
    submitAction,
    submitText,
    initialValues,
    dataCy,
    icon,
    title,
    subtitle,
    modalForm,
    headerClassName,
    formClassName,
    wrapperClassName,
    showReset,
    cancelAction,
    submitPristine,
    submitDisabled,
    hideFooter,
    loading,
    breadcrumbsProps,
    actions,
    tabs,
    allowRaw = false,
    mode = FormMode.FULL_PAGE,
    disabled,
    outerComponent
  } = props;
  //@ts-ignore
  let [raw, setRaw] = useState(false);

  let openRaw = useCallback(() => {
    setRaw(true);
  }, []);
  let closeRaw = useCallback(() => {
    setRaw(false);
  }, []);

  let C: any = outerComponent || AbsoluteFormWrapper;
  if (!outerComponent && mode === FormMode.MODAL) {
    C = Fragment;
  }

  let modeDivStyles = {
    [FormMode.FULL_PAGE]: 'border border-zinc-200 mx-4',
    [FormMode.MODAL]: 'sm:min-w-[500px] max-h-[80vh] overflow-auto'
  };

  if (loading) {
    return (
      <>
        <PageHeading
          breadcrumbsProps={breadcrumbsProps}
          icon={icon}
          closeAction={modalForm ? closeAction : null}
          title={title}
          subtitle={subtitle}
          className={overrideClassName('max-w-7xl pb-4', headerClassName)}
        />
        <div
          className={overrideClassName(
            `bg-white dark:bg-zinc-800 rounded-lg flex-auto flex flex-col items-center justify-center ${modeDivStyles[mode]}`,
            wrapperClassName
          )}>
          <Icon icon={Icons.LOADING} className="animate-spin" />
        </div>
      </>
    );
  } else if (!initialValues) {
    return (
      <>
        <PageHeading
          breadcrumbsProps={breadcrumbsProps}
          icon={icon}
          closeAction={modalForm ? closeAction : null}
          title={title}
          subtitle={subtitle}
          className={overrideClassName('max-w-7xl pb-4', headerClassName)}
        />
        <div
          className={overrideClassName(
            `bg-white dark:bg-zinc-800 rounded-lg flex-auto flex flex-col items-center justify-center ${modeDivStyles[mode]}`,
            wrapperClassName
          )}>
          <div>Item not found</div>
        </div>
      </>
    );
  }

  return (
    <C>
      <FormContext.Provider
        value={{
          disabled
        }}>
        <Form
          initialValues={initialValues}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={submitAction ? submitWrapper(submitAction, initialValues) : () => {}}>
          {/*
      // @ts-ignore */}
          {({
            handleSubmit,
            form,
            pristine,
            submitting,
            modifiedSinceLastSubmit,
            hasValidationErrors,
            hasSubmitErrors,
            submitError,
            validating,
            form: { getState }
          }) => (
            <>
              {(title || breadcrumbsProps) && (
                <PageHeading
                  breadcrumbsProps={breadcrumbsProps}
                  icon={icon}
                  closeAction={modalForm ? closeAction : null}
                  title={title}
                  subtitle={subtitle}
                  className={overrideClassName('max-w-7xl pb-4', headerClassName)}>
                  <Actions
                    actions={[
                      ...(actions || []),
                      ...(allowRaw
                        ? [
                            {
                              label: 'View Raw Values',
                              onClick: openRaw
                            }
                          ]
                        : [])
                    ]}
                    getState={getState}
                    className="ml-2"
                  />
                  <InspectorModal data={initialValues} isOpen={raw} closeAction={closeRaw} />
                </PageHeading>
              )}
              <div
                className={overrideClassName(
                  `bg-white dark:bg-zinc-800 rounded-lg flex-auto flex flex-col overflow-auto ${modeDivStyles[mode]}`,
                  wrapperClassName
                )}>
                {tabs && <Tabs tabs={tabs} className="rounded-t-lg" />}
                <form
                  className={overrideClassName('max-w-7xl px-4 pb-8 flex flex-col flex-10auto', formClassName)}
                  onSubmit={
                    submitAction &&
                    !submitDisabled &&
                    (!pristine || submitPristine) &&
                    !hasValidationErrors &&
                    (!hasSubmitErrors || modifiedSinceLastSubmit)
                      ? handleSubmit
                      : undefined
                  }>
                  {children}
                </form>
                {!modalForm && !hideFooter && (
                  <FormFooterFull
                    initialValues={initialValues}
                    closeAction={cancelAction || closeAction}
                    resetAction={showReset && form.reset}
                    deleteAction={!disabled && deleteAction && deleteWrapper(deleteAction, deleteConfirm)}
                    deleteLabel={deleteLabel}
                    deleteIcon={deleteIcon}
                    publishAction={!disabled && publishAction && publishWrapper(publishAction, publishConfirm)}
                    publishLabel={publishLabel}
                    publishIcon={publishIcon}
                    submitAction={submitAction && handleSubmit}
                    submitText={submitText}
                    submitting={submitting || validating}
                    submitDisabled={
                      disabled ||
                      submitDisabled ||
                      (pristine && !submitPristine) ||
                      hasValidationErrors ||
                      (hasSubmitErrors && !modifiedSinceLastSubmit)
                    }
                    dataCy={dataCy + 'Footer'}
                  />
                )}
              </div>
            </>
          )}
        </Form>
      </FormContext.Provider>
    </C>
  );
};

export default FormTemplate;
