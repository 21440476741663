import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FormTemplate, FormSection, AbsoluteFormWrapper } from '@src/components';
import UserFields from '@src/users/userFields';
import useAuth from '@src/auth/useAuth';
import { createUser } from '@src/users/data';

let NewUser = () => {
  let { db } = useAuth();
  let navigate = useNavigate();
  let queryClient = useQueryClient();
  let mutation = useMutation(
    (newItem) => {
      return createUser({ db, data: newItem });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('user');
        navigate('../');
      }
    }
  );

  return (
    <AbsoluteFormWrapper>
      <FormTemplate title={'User'} submitAction={mutation.mutateAsync} initialValues={{}}>
        <FormSection label="User Form">
          <UserFields />
        </FormSection>
      </FormTemplate>
    </AbsoluteFormWrapper>
  );
};
export default NewUser;
