import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { plotsLinks, plotsRoutes } from './plots';
import { Sidebar, Main } from '@src/components';
import useUser from '@src/auth/useUser';
import useSignOut from '@src/auth/useSignOut';
import {
  // usersLinks,
  usersRoutes
} from '@src/users';
import Protected from '@src/auth/protected';
import { MenuAlt1Icon } from '@heroicons/react/outline';
import UserProfilePopover from '@src/auth/userProfilePopover';
import useAuth from '@src/auth/useAuth';

function App() {
  let [sidebarOpen, setSidebarOpen] = useState(false);
  let { user } = useAuth();

  let routes = useRoutes([
    {
      path: '/',
      element: <Protected />,
      children: [...usersRoutes, ...plotsRoutes]
    }
  ]);

  let links = [
    // ...usersLinks,
    ...plotsLinks
  ];

  return (
    <div className="min-h-full bg-white flex flex-col text-black">
      {user && (
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          links={links}
          title="HCC"
          popoverTitle="Evoca Admin Portal"
          useUser={useUser}
          useSignOut={useSignOut}
          // logo={<Logo />}
          // searchField={<SearchField />}
        />
      )}
      {/* Search header */}
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-zinc-200 lg:hidden">
        <button
          type="button"
          className="px-4 border-r border-zinc-200 text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
          onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex-1 flex"></div>
          <div className="flex items-center">
            <UserProfilePopover
              popoverTitle="Evoca Admin Portal"
              useUser={useUser}
              useSignOut={useSignOut}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
        </div>
      </div>
      <div className={`${user ? 'lg:pl-64' : ''} flex flex-col flex-10auto`}>
        <Main>{routes}</Main>
      </div>
    </div>
  );
}

export default App;
