import React from 'react';

export enum DarkMode {
  STATIC_TRUE = 'StaticTrue',
  STATIC_FALSE = 'StaticFalse',
  WINDOW_TRUE = 'WindowTrue',
  WINDOW_FALSE = 'WindowFalse'
}

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local'
}

export interface WindowStateContextType {
  width: number;
  height: number;
  darkMode?: DarkMode;
  setDarkMode: Function;
  environment: Environment;
}

export const WindowStateContext = React.createContext<WindowStateContextType>(null!);
