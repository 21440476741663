import React from 'react';
import TableCheckboxHeader from './tableCheckboxHeader';

let HeaderRow = (tableProps) => (props: { className?: string; columns: any; style?: any }) => {
  let { columns, style } = props;
  let className = `flex bg-zinc-50 dark:bg-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-700 font-bold rounded-t-lg border-b border-zinc-200 text-sm text-zinc-900`;
  return (
    <div style={style} className={className}>
      {tableProps?.checkboxKey ? (
        <div className="px-4 flex items-center justify-center">
          <TableCheckboxHeader {...(tableProps || {})} />
        </div>
      ) : null}
      {columns}
    </div>
  );
};
export default HeaderRow;
