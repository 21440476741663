import orderBy from 'lodash/orderBy';
import { addDoc, getDocs, collection, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import format from 'date-fns/format';
import writeXlsxFile from 'write-excel-file';

let PLOTS: any = [];
let loading = true;
let lastSort: any = {
  row: 'ASC'
};

let getMonthFromString = (mon) => {
  let d = Date.parse(mon + '1, 2012');
  let ret = 1;
  if (!isNaN(d)) {
    ret = new Date(d).getMonth() + 1;
  }
  return ret.toString().padStart(2, '0');
};

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let sortPlotsByRow = (items, dir = 'asc') => {
  return orderBy(
    items,
    [
      (r) => {
        let ret = (r.row || '').replace(/\d/g, '');
        if (ret === 'AE') {
          ret = 'E';
        }
        return ret;
      },
      (r) => {
        if (r.row) {
          let rn = r.row.replace(/\D/g, '');
          rn = parseInt(rn || '0');
          let dir = r.row.replace(/\d/g, '');
          if (dir === 'E' || dir === 'AE') {
            return rn * -1;
          } else {
            return rn;
          }
        } else {
          return r.row;
        }
      },
      (r) => (r.plot ? parseInt(r.plot) : 0)
    ],
    [dir, dir, dir]
  );
};

let sortPlots = (items) => {
  let sk = Object.keys(lastSort)[0];
  if (sk === 'row') {
    return sortPlotsByRow(items, lastSort.row === 'ASC' ? 'asc' : 'desc');
  } else if (sk === 'name') {
    return orderBy(items, ['last', 'first'], [lastSort.name.toLowerCase(), lastSort.name.toLowerCase()]);
  } else {
    return orderBy(
      items,
      Object.keys(lastSort),
      Object.values(lastSort).map((s: any) => s.toLowerCase())
    );
  }
};

export let importPlots = (queryClient, db) => async () => {
  let text = await navigator.clipboard.readText();
  // use this - have to comment out to make it work in safari
  // let ts = text.split(/(?<=^[^"]*(?:"[^"]*"[^"]*)*)\r?\n/g);
  let ts = text.split(/\n/g);
  let rows = ts.map((t) => t.split('\t'));
  for (let row of rows) {
    // noinspection JSUnusedLocalSymbols
    let [location, plot, , first, last, month, day, year, , month2, day2, year2, notes] = row;
    if (location) {
      let [na, row, direction] = location.split(' ');
      let r: any = {};
      if (row) {
        r.row = row + direction;
      } else if (na) {
        r.na = na;
      }
      if (first) {
        r.first = first;
      }
      if (last) {
        r.last = last;
      }
      if (notes) {
        r.notes = notes;
      }
      if (year) {
        r.birth = `${year}-${getMonthFromString(month)}-${(day || '1').padStart(2, '0')}`;
      }
      if (year2) {
        r.death = `${year2}-${getMonthFromString(month2).toString().padStart(2, '0')}-${(day2 || '1').padStart(
          2,
          '0'
        )}`;
      }
      if (plot) {
        r.plot = plot;
      }

      let i = PLOTS.find((p) => p.row === r.row && p.plot === r.plot && p.last === r.last && p.first === r.first);
      if (!i) {
        await addDoc(collection(db, 'plots'), r);
      }
    }
  }
  PLOTS = [];
  let p = getPlots(db);
  console.log(await p({}));
  queryClient.invalidateQueries('plot');
};

export let getPlot = async ({ params }) => {
  while (loading) {
    await sleep(100);
  }
  return PLOTS.find((p) => p.id === params.id);
};

export let createPlot = ({ data, db }) => {
  return new Promise((resolve) => {
    let { id, ...rest } = data;
    let fn = async () => {
      let r = await addDoc(collection(db, 'plots'), rest);
      let n = {
        id: r.id,
        ...rest
      };
      PLOTS.push(n);
      PLOTS = sortPlots(PLOTS);
      resolve(n);
    };
    fn();
  });
};

export let updatePlot = ({ data, db }) => {
  return new Promise((resolve, reject) => {
    let { id, ...rest } = data;
    let fn = async () => {
      if (id) {
        await updateDoc(doc(db, 'plots', id), rest);
        // await setDoc(doc(db, 'plots', id), rest);
        let pi = PLOTS.findIndex((p) => p.id === id);
        PLOTS[pi] = { ...PLOTS[pi], ...rest };
        PLOTS = sortPlots(PLOTS);
        resolve(PLOTS[pi]);
      } else {
        reject('No ID');
      }
    };
    fn();
  });
};

export let deletePlot = ({ params, db }) => {
  PLOTS = PLOTS.filter((p) => p.id !== params.id);
  return deleteDoc(doc(db, 'plots', params.id));
};

export let getPlots =
  (db) =>
  async ({ params }: { params?: any }) => {
    let { json } = params || {};
    if (json) {
      json = JSON.parse(json);
    }
    let { filter, sort, startIndex = 0, stopIndex = 100 } = json || {};
    let { search, row } = filter || {};

    if (loading) {
      let ret = await getDocs(collection(db, 'plots'));
      PLOTS = [];
      ret.forEach((doc) => {
        PLOTS.push({
          id: doc.id,
          ...doc.data()
        });
      });
      PLOTS = sortPlots(PLOTS);
      loading = false;
    }

    let items = PLOTS;

    if (row?.length) {
      items = items.filter((item) => row.includes(item.row));
    }

    if (search?.trim()) {
      let stl = search.trim().toLowerCase();
      items = items.filter((plot) => {
        let { row, direction, plot: plotNumber, last, first, notes } = plot;
        let searchTerms = [row, direction, plotNumber, last, first, notes].join(' ').toLowerCase();
        return searchTerms.includes(stl) || last?.includes(stl) || first?.includes(stl);
      });
    }
    let total = items.length;

    if (sort) {
      lastSort = sort;
      items = sortPlots(items);
    }

    items = items.slice(startIndex, stopIndex);
    return {
      items,
      total
    };
  };

export let exportPlots = async () => {
  let schema = [
    {
      column: 'Row',
      type: String,
      value: (p) => p.row
    },
    {
      column: 'Plot',
      type: String,
      value: (p) => p.plot
    },
    {
      column: 'Last',
      type: String,
      value: (p) => p.last,
      width: 20
    },
    {
      column: 'First',
      type: String,
      value: (p) => p.first,
      width: 20
    },
    {
      column: 'Birth',
      type: String,
      value: (p) => p.birth,
      width: 12
    },
    {
      column: 'Death',
      type: String,
      value: (p) => p.death,
      width: 12
    },
    {
      column: 'Notes',
      type: String,
      value: (p) => p.notes,
      width: 40
    }
  ];

  await writeXlsxFile(PLOTS, {
    schema,
    fileName: `Cemetery_Plots_${format(new Date(), 'yyyy-MM-dd_hh-mm')}.xlsx`
  });
};

export default PLOTS;
