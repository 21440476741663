import React, { useState } from 'react';
import Button, { ButtonSize } from '../button';
import FormCheckboxList from '../form/formCheckboxList';
import { Popover } from '../index';
import DateRange from './dateRange';
import DateValue from './dateValue';

let FilterField = (props) => {
  let { c, input, debug } = props;
  let [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <Popover
        debug={debug}
        sameWidth={false}
        isOpen={isOpen}
        closeAction={() => setIsOpen(false)}
        title={c.label}
        source={(ref) => (
          <Button ref={ref} onClick={(e) => setIsOpen(!isOpen)} size={ButtonSize.SM}>
            {c.label}
          </Button>
        )}>
        <div className="max-h-[70vh] overflow-auto min-w-[150px]">
          {c.filter === 'date' ? (
            <DateValue input={input} label={c.filterLabel} />
          ) : c.filter === 'dateRange' ? (
            <DateRange input={input} />
          ) : (
            <FormCheckboxList options={c.filter} input={input} dataParams={c.dataParams} nullFilterLabel={c.nullFilterLabel} />
          )}
        </div>
      </Popover>
    </React.Fragment>
  );
};
export default FilterField;
