import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import LabelledField from '../labelledField';
import { generateId, overrideClassName, useDisabled } from '../index';
import Icon from '../icon';
import InputMode from '../inputMode';
import format from 'date-fns/format';
import lodashDebounce from 'lodash/debounce';

let FormInput = forwardRef((props: any, ref) => {
  let formDisabled = useDisabled();
  let {
    placeholder,
    autoFocus,
    label,
    inputType = 'text',
    type = inputType,
    meta,
    multiLine,
    dataCy,
    decorator,
    rows,
    disableBrowserAutocomplete = true,
    step,
    inputClassName = '',
    divProps = {},
    divClassName,
    inputWrapperProps = {},
    inputWrapperClassName,
    labelProps = {},
    labelClassName,
    labelOnLeft,
    labelOnRight,
    input,
    icon,
    showClear,
    id,
    mode = InputMode.PRIMARY,
    disabled = formDisabled,
    options,
    debounce,
    debounceTimeout = 200,
    debounceMaxWait = 1000
  } = props;
  let { value, onChange, onBlur, onFocus, onKeyDown, name } = input || {};
  let { touched, error } = meta || {};
  let [debounceValue, setDebounceValue] = React.useState(value);
  let valueHistory = useRef<string[]>([]);
  let debouncedChange = useRef(
    lodashDebounce(
      (e) => {
        let v = e.target.value || '';
        onChange(v);
        valueHistory.current.push(v);
        valueHistory.current.slice(0, 5);
      },
      debounceTimeout,
      { maxWait: debounceMaxWait }
    )
  );

  useEffect(() => {
    if (debounce) {
      if (!valueHistory.current.includes(value)) {
        // accept external change
        setDebounceValue(value);
        debouncedChange.current.cancel();
      }
    }
  }, [debounce, value]);

  if (type === 'datetime-local' && typeof value === 'string' && value?.endsWith('Z')) {
    value = format(new Date(value), `yyyy-MM-dd'T'HH:mm:ss`);
  } else if (type === 'datetime-local' && value && (typeof value === 'object' || typeof value === 'number')) {
    value = format(value, `yyyy-MM-dd'T'HH:mm:ss`);
  }

  if (disabled) {
    mode = InputMode.DISABLED;
  } else if (touched && error) {
    mode = InputMode.DANGER;
  }
  if (!disabled && mode === InputMode.DISABLED) {
    disabled = true;
  }

  let change = useCallback(
    (e) => {
      if (debounce) {
        setDebounceValue(e.target.value || '');
        debouncedChange.current(e);
      } else {
        onChange(e.target.value || '');
      }
    },
    [debounce, onChange]
  );

  let modeStyle = {
    [InputMode.PRIMARY]: 'focus:ring-primaryRing-50 focus:border-primaryRing-50',
    [InputMode.SUCCESS]: 'focus:ring-successRing-50 focus:border-successRing-50',
    [InputMode.DISABLED]: '',
    [InputMode.DANGER]: 'focus:ring-dangerRing-50 focus:border-dangerRing-50',
    [InputMode.ADMIN]: 'focus:ring-admin-ring focus:border-admin-ring'
  };

  let cn = `shadow-sm ${modeStyle[mode]} block w-full sm:text-sm border-zinc-300 rounded-md`;
  if (!multiLine) {
    cn += ` sm:max-w-xs`;
  }
  if (icon) {
    cn += ' pl-8';
  }
  let myID = useRef(id || generateId(12));
  let inp;
  if (multiLine) {
    inp = (
      <textarea
        data-cy={dataCy}
        ref={ref as any}
        id={myID.current}
        name={name}
        className={overrideClassName(cn, inputClassName)}
        value={debounce ? debounceValue : value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={change}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        autoComplete={disableBrowserAutocomplete ? 'off' : ''}
        onFocus={onFocus}
        rows={rows}
      />
    );
  } else {
    inp = (
      <input
        data-cy={dataCy}
        ref={ref as any}
        id={myID.current}
        name={name}
        className={overrideClassName(cn, inputClassName)}
        value={debounce ? debounceValue : value || ''}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        step={step}
        onChange={change}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete={disableBrowserAutocomplete ? 'off' : ''}
        list={options ? `${myID.current}_suggestions` : undefined}
      />
    );
  }
  return (
    <LabelledField
      label={label}
      divProps={divProps}
      divClassName={divClassName}
      inputWrapperProps={inputWrapperProps}
      inputWrapperClassName={inputWrapperClassName}
      labelProps={labelProps}
      labelClassName={labelClassName}
      htmlFor={myID.current}
      labelOnLeft={labelOnLeft}
      labelOnRight={labelOnRight}
      meta={meta}
      mode={mode}
      placeholder={placeholder}>
      {decorator && (
        <div className="absolute text-zinc-100" style={{ left: '-10px', bottom: '12px' }}>
          {decorator}
        </div>
      )}
      {icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">
          <Icon icon={icon} className="mr-3 h-4 w-4 text-zinc-400" aria-hidden="true" />
        </div>
      )}
      {inp}
      {showClear && value ? (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          aria-hidden="true"
          onClick={() => onChange('')}>
          <Icon icon="close" className="mr-3 h-4 w-4 text-zinc-400" aria-hidden="true" />
        </div>
      ) : null}
      {options && (
        <datalist id={`${myID.current}_suggestions`}>
          {options.map((o) => (
            <option key={o.value} value={o.value} />
          ))}
        </datalist>
      )}
    </LabelledField>
  );
});
export default FormInput;
