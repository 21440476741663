import React, { useState } from 'react';
import { SelectorIcon } from '@heroicons/react/solid';
import { DarkMode, Icon, IconMode, Icons, LabelledField, Popover, useWindowState } from '@src/components';

let UserProfilePopover = (props) => {
  let { useUser, useSignOut, popoverTitle, stopEmulating } = props;
  let { darkMode, setDarkMode } = useWindowState();
  let [isOpen, setIsOpen] = useState(false);

  let user = useUser();
  let signOut = useSignOut();

  return (
    <Popover
      isOpen={isOpen}
      closeAction={() => setIsOpen(false)}
      source={(ref) => (
        <div ref={ref} onClick={() => setIsOpen(true)} className="mx-2 cursor-pointer">
          <div
            className={`group w-full rounded-md px-3 py-2 text-sm text-left font-medium text-label-text hover:bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-zinc-100 focus:ring-blue-500 ${
              stopEmulating ? 'border-2 border-red-500' : ''
            }`}>
            <span className="flex w-full justify-between items-center">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <span className="flex-1 flex flex-col min-w-0">
                  <span className="text-zinc-900 text-sm font-medium truncate">
                    {user?.name || user?.attributes?.email}
                  </span>
                  <span className="text-zinc-500 text-sm truncate">
                    @{user?.username || (user?.email || '').split('@')[0]}
                  </span>
                </span>
              </span>
              <SelectorIcon
                className="flex-shrink-0 h-5 w-5 text-zinc-400 group-hover:text-zinc-500"
                aria-hidden="true"
              />
            </span>
          </div>
        </div>
      )}>
      <>
        {process.env.REACT_APP_VERSION && (
          <>
            <div className="text-center text-sm text-zinc-500 py-2">
              {popoverTitle || null} {process.env.REACT_APP_VERSION}
            </div>
            <hr />
          </>
        )}
        <div className={'text-label-text block px-4 pb-2 text-sm'}>
          <LabelledField label="Display Mode" divClassName="mt-0">
            <div className="flex justify-around">
              <Icon
                icon={Icons.WEATHER_NIGHT}
                className="cursor-pointer hover:text-primary-hover"
                mode={darkMode === DarkMode.STATIC_TRUE ? IconMode.PRIMARY : null}
                onClick={setDarkMode.bind(null, DarkMode.STATIC_TRUE)}
              />
              <Icon
                icon={Icons.WEATHER_SUNNY}
                className="cursor-pointer hover:text-primary-hover"
                mode={darkMode === DarkMode.STATIC_FALSE ? IconMode.PRIMARY : null}
                onClick={setDarkMode.bind(null, DarkMode.STATIC_FALSE)}
              />
              <Icon
                icon={Icons.LAPTOP}
                className="cursor-pointer hover:text-primary-hover"
                mode={darkMode !== DarkMode.STATIC_TRUE && darkMode !== DarkMode.STATIC_FALSE ? IconMode.PRIMARY : null}
                onClick={setDarkMode.bind(null, null)}
              />
            </div>
          </LabelledField>
        </div>
        <hr />
        {stopEmulating && (
          <>
            <div
              className={'text-red-500 text-label-text block px-4 py-2 text-sm hover:bg-zinc-100 cursor-pointer'}
              onClick={stopEmulating}>
              Stop Emulating
            </div>
            <hr />
          </>
        )}
        <div className={'text-label-text block px-4 py-2 text-sm hover:bg-zinc-100 cursor-pointer'} onClick={signOut}>
          Logout
        </div>
      </>
    </Popover>
  );
};

export default UserProfilePopover;
