import { Icons } from '@src/components';
import { RouteType } from '@src/components/utils/types';
import NewUser from '@src/users/newUser';
import Users from '@src/users/users';
import User from '@src/users/user';

export let usersLinks = [
  {
    path: '/users/*',
    to: '/users',
    label: 'Users',
    icon: Icons.ACCOUNT_CANCEL_OUTLINE,
    group: 'Admin'
  }
];

export let usersRoutes: RouteType[] = [
  {
    path: 'users',
    element: <Users />,
    children: [
      {
        path: 'new',
        element: <NewUser />
      },
      {
        path: ':userID',
        element: <User />
      }
    ]
  }
];
