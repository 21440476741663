import orderBy from 'lodash/orderBy';
import { addDoc, getDocs, collection, setDoc, doc, deleteDoc } from 'firebase/firestore';

let USERS: any = [];
let loading = true;
let lastSort: any = {
  row: 'ASC'
};

let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let sortUsers = (items) => {
  return orderBy(
    items,
    Object.keys(lastSort),
    Object.values(lastSort).map((s: any) => s.toLowerCase())
  );
};

export let getUser = async ({ params }) => {
  while (loading) {
    await sleep(100);
  }
  return USERS.find((p) => p.id === params.id);
};

export let createUser = ({ data, db }) => {
  return new Promise((resolve) => {
    let { id, ...rest } = data;
    let fn = async () => {
      let r = await addDoc(collection(db, 'users'), rest);
      let n = {
        id: r.id,
        ...rest
      };
      USERS.push(n);
      USERS = sortUsers(USERS);
      resolve(n);
    };
    fn();
  });
};

export let updateUser = ({ data, db }) => {
  return new Promise((resolve, reject) => {
    let { id, ...rest } = data;
    let fn = async () => {
      if (id) {
        await setDoc(doc(db, 'users', id), rest);
        let pi = USERS.findIndex((p) => p.id === id);
        USERS[pi] = { ...USERS[pi], ...rest };
        USERS = sortUsers(USERS);
        resolve(USERS[pi]);
      } else {
        reject('No ID');
      }
    };
    fn();
  });
};

export let deleteUser = ({ params, db }) => {
  USERS = USERS.filter((p) => p.id !== params.id);
  return deleteDoc(doc(db, 'users', params.id));
};

export let getUsers =
  (db) =>
  async ({ params }: { params?: any }) => {
    let { json } = params || {};
    if (json) {
      json = JSON.parse(json);
    }
    let { filter, sort, startIndex = 0, stopIndex = 100 } = json || {};
    let { search, row } = filter || {};

    if (loading) {
      let ret = await getDocs(collection(db, 'users'));
      ret.forEach((doc) => {
        USERS.push({
          id: doc.id,
          ...doc.data()
        });
      });
      USERS = sortUsers(USERS);
      loading = false;
    }

    let items = USERS;

    if (row?.length) {
      items = items.filter((item) => row.includes(item.row));
    }

    if (search?.trim()) {
      let stl = search.trim().toLowerCase();
      items = items.filter((user) => {
        let { row, direction, user: userNumber, last, first, notes } = user;
        let searchTerms = [row, direction, userNumber, last, first, notes].join(' ').toLowerCase();
        return searchTerms.includes(stl) || last?.includes(stl) || first?.includes(stl);
      });
    }
    let total = items.length;

    if (sort) {
      lastSort = sort;
      items = sortUsers(items);
    }

    items = items.slice(startIndex, stopIndex);

    return {
      items,
      total
    };
  };

export default USERS;
