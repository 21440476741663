import React from 'react';
import { Link } from 'react-router-dom';
import TableCheckbox from './tableCheckbox';
import get from 'lodash/get';

let Row =
  ({
    getRowLink,
    checkboxKey,
    checkboxID,
    _rows
  }: {
    getRowLink?: Function;
    checkboxKey?: string;
    checkboxID?: string;
    _rows?: any;
  }) =>
  (props: {
    className?: string;
    columns: any;
    index: number;
    key?: string;
    onRowClick?: any;
    onRowDoubleClick?: any;
    onRowMouseOut?: any;
    onRowMouseOver?: any;
    onRowRightClick?: any;
    rowData?: any;
    style?: any;
  }) => {
    let { key, index, columns, style, onRowClick, rowData } = props;
    let className = `flex dark:hover:bg-primary-900 dark:border-zinc-700 dark:border-b ${
      index % 2 === 0 ? 'bg-white dark:bg-zinc-800 hover:bg-zinc-100' : 'bg-zinc-50 dark:bg-zinc-800 hover:bg-zinc-100'
    }`;

    let C: any = 'div';
    let dProps: any = {
      'aria-rowindex': index + 1,
      role: 'row',
      key,
      style,
      onClick: onRowClick
        ? (e) =>
            onRowClick({
              ...props,
              event: e
            })
        : null
    };
    if (getRowLink) {
      C = Link;
      dProps.tabIndex = 0;
      dProps.to = getRowLink(props);
    } else if (onRowClick) {
      dProps.tabIndex = 0;
      className += ' cursor-pointer';
    }
    return (
      <C className={className} {...dProps}>
        {checkboxKey ? (
          <div className="px-4 flex items-center justify-center">
            <TableCheckbox
              id={get(rowData, checkboxID as string)}
              checkboxID={checkboxID}
              checkboxKey={checkboxKey}
              _rows={_rows}
            />
          </div>
        ) : null}
        {columns}
      </C>
    );
  };
export default Row;
