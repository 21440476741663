import React, { useState, useRef, useCallback, useEffect } from 'react';
import Confirm from './confirm';

interface Confirming {
  label?: string;
  children?: any;
  coming?: boolean;
  going?: boolean;
}

interface ConfirmContextType {
  confirm: Function;
}

export const ConfirmContext = React.createContext<ConfirmContextType>(null!);

let ConfirmProvider = (props) => {
  let { children, ConfirmComponent = Confirm } = props;
  let setConfirm = useRef<any>(null);
  let [data, setData] = useState<Confirming | null>(null);

  let confirm = useCallback(
    (label: string = 'Are you sure?', children: any = 'This action is destructive, please handle with care') => {
      // @ts-ignore
      return new Promise((resolve, reject) => {
        setConfirm.current = (value) => {
          resolve(value);
          setData({
            label,
            children,
            going: true
          });
        };
        setData({
          label,
          children,
          coming: true
        });
      });
    },
    []
  );

  useEffect(() => {
    let timer;
    if (data?.coming || data?.going) {
      timer = setTimeout(() => {
        if (data?.coming) {
          setData({
            label: data?.label,
            children: data?.children
          });
        } else {
          setData(null);
        }
      }, 150);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [data]);

  return (
    <ConfirmContext.Provider
      value={{
        confirm
      }}>
      {children}
      {data && (
        <div className={`absolute inset-0 text-zinc-900`}>
          <div
            className={`absolute inset-0 z-10 ${
              data && !data.coming && !data.going
                ? 'opacity-50 bg-black dark:bg-black'
                : 'opacity-0 bg-black dark:bg-black'
            }`}
            style={{ transition: 'opacity 150ms ease-in-out' }}
          />
          <div
            className={`absolute left-0 right-0 z-20 ${
              data && !data.coming && !data.going ? 'top-36' : '-top-52'
            } flex flex-col items-center`}
            style={{ transition: 'top 150ms ease-in-out' }}>
            <ConfirmComponent setConfirm={setConfirm} label={data.label} children={data.children} />
          </div>
        </div>
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
