import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WindowStateContext, Environment, DarkMode } from './windowStateContext';
import debounce from 'lodash/debounce';

let DARK_MODE_KEY = 'darkMode';
let environment = Environment.LOCAL;
if (Object.values(Environment).includes(process.env.REACT_APP_ENVIRONMENT as Environment)) {
  environment = process.env.REACT_APP_ENVIRONMENT as Environment;
}

export let WindowStateProvider = ({ children, delay = 100, title }) => {
  let darkModeRef = useRef<DarkMode>();
  let [, setUpdated] = useState(new Date());
  useEffect(() => {
    let debouncedListener = debounce(() => {
      setUpdated(new Date());
    }, delay);
    window.addEventListener('resize', debouncedListener);
    return () => {
      window.removeEventListener('resize', debouncedListener);
    };
  }, [delay]);

  let setDarkMode = useCallback((mode?: DarkMode) => {
    if (mode === DarkMode.STATIC_TRUE) {
      darkModeRef.current = DarkMode.STATIC_TRUE;
      global.storage?.setItem(DARK_MODE_KEY, darkModeRef.current);
      document.documentElement.classList.add('dark');
    } else if (mode === DarkMode.STATIC_FALSE) {
      darkModeRef.current = DarkMode.STATIC_FALSE;
      global.storage?.setItem(DARK_MODE_KEY, darkModeRef.current);
      document.documentElement.classList.remove('dark');
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      darkModeRef.current = DarkMode.WINDOW_TRUE;
      global.storage?.removeItem(DARK_MODE_KEY);
      document.documentElement.classList.add('dark');
    } else {
      darkModeRef.current = DarkMode.WINDOW_FALSE;
      global.storage?.removeItem(DARK_MODE_KEY);
      document.documentElement.classList.remove('dark');
    }
    setUpdated(new Date());
  }, []);

  useEffect(() => {
    if (title) {
      document.title = `${title}${environment === Environment.PRODUCTION ? '' : ` (${environment})`}`;
    }
  }, [title]);

  useEffect(() => {
    if (window.matchMedia) {
      let listener = (event) => {
        if (![DarkMode.STATIC_TRUE, DarkMode.STATIC_FALSE].includes(darkModeRef.current!)) {
          if (event.matches) {
            //dark mode
            setDarkMode(DarkMode.WINDOW_TRUE);
          } else {
            //light mode
            setDarkMode(DarkMode.WINDOW_FALSE);
          }
        }
      };
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener);
      return () => {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener);
      };
    }
  }, [setDarkMode]);

  useEffect(() => {
    let fn = async () => {
      let dm = await global.storage?.getItem(DARK_MODE_KEY);
      if (dm) {
        setDarkMode(dm);
      } else {
        setDarkMode();
      }
    };
    fn();
  }, [setDarkMode]);

  return (
    <WindowStateContext.Provider
      value={{
        width: window.innerWidth,
        height: window.innerHeight,
        darkMode: darkModeRef.current,
        environment,
        setDarkMode
      }}>
      {children}
    </WindowStateContext.Provider>
  );
};
