import React, { forwardRef } from 'react';
import Icon from './icon';
import { overrideClassName } from './index';

export enum ButtonMode {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  DISABLED = 'Disabled',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger'
}

export enum ButtonSize {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL'
}

let Button = forwardRef((props: any, ref) => {
  let {
    children,
    mode = ButtonMode.PRIMARY,
    size = ButtonSize.SM,
    icon,
    className,
    iconClassName,
    disabled,
    loading,
    ...otherProps
  } = props;
  if (disabled) {
    mode = ButtonMode.DISABLED;
  }
  let sharedStyles = `inline-flex justify-center items-center border focus:outline-none ${
    mode === ButtonMode.DISABLED ? '' : 'focus:ring-2 focus:ring-offset-2 ring-offset-white dark:ring-offset-zinc-900'
  }`;
  let modeStyles = {
    [ButtonMode.PRIMARY]: `border-primaryButtonBorder-50 border-opacity-0 shadow-sm text-primaryButtonText-50 bg-primaryButtonBg-50 hover:bg-primaryButtonHover-50 focus:ring-primaryRing-50`,
    [ButtonMode.SECONDARY]: `border-secondaryButtonBorder-50 border-opacity-0 shadow-sm text-secondaryButtonText-50 bg-secondaryButtonBg-50 hover:bg-secondaryButtonHover-50 focus:ring-secondaryRing-50`,
    [ButtonMode.DISABLED]: `border-disabledButtonBorder-50 text-disabledButtonText-50 bg-disabledButtonBg-50 cursor-not-allowed`,
    [ButtonMode.SUCCESS]: `border-successButtonBorder-50 border-opacity-0 shadow-sm text-successButtonText-50 bg-successButtonBg-50 hover:bg-successButtonHover-50 focus:ring-successRing-50`,
    [ButtonMode.WARNING]: `border-warningButtonBorder-50 border-opacity-0 shadow-sm text-warningButtonText-50 bg-warningButtonBg-50 hover:bg-warningButtonHover-50 focus:ring-warningRing-50`,
    [ButtonMode.DANGER]: `border-dangerButtonBorder-50 border-opacity-0 shadow-sm text-dangerButtonText-50 bg-dangerButtonBg-50 hover:bg-dangerButtonHover-50 focus:ring-dangerRing-50`
  };
  let sizeStyles = {
    [ButtonSize.XS]: 'px-2.5 py-1.5 text-xs font-medium rounded',
    [ButtonSize.SM]: 'px-3 py-2 text-sm font-medium rounded-md leading-4',
    [ButtonSize.MD]: 'px-4 py-2 text-sm font-medium rounded-md',
    [ButtonSize.LG]: 'px-4 py-2 text-base font-medium rounded-md',
    [ButtonSize.XL]: 'px-6 py-3 text-base font-medium rounded-md'
  };
  let iconSizeStyles = {
    [ButtonSize.XS]: '-ml-0.5 mr-2 h-4 w-4',
    [ButtonSize.SM]: '-ml-0.5 mr-2 h-4 w-4',
    [ButtonSize.MD]: '-ml-1 mr-2 h-5 w-5',
    [ButtonSize.LG]: '-ml-1 mr-3 h-5 w-5',
    [ButtonSize.XL]: '-ml-1 mr-3 h-5 w-5'
  };
  if (!React.Children.toArray(children).length) {
    // icon-only
    sizeStyles = {
      [ButtonSize.XS]: 'p-1 rounded-full',
      [ButtonSize.SM]: 'p-1.5 rounded-full',
      [ButtonSize.MD]: 'p-2 rounded-full',
      [ButtonSize.LG]: 'p-2 rounded-full',
      [ButtonSize.XL]: 'p-3 rounded-full'
    };
    iconSizeStyles = {
      [ButtonSize.XS]: 'h-5 w-5',
      [ButtonSize.SM]: 'h-5 w-5',
      [ButtonSize.MD]: 'h-5 w-5',
      [ButtonSize.LG]: 'h-6 w-6',
      [ButtonSize.XL]: 'h-6 w-6'
    };
  }
  if (loading && icon) {
    icon = 'loading';
    iconClassName = `${iconClassName || ''} animate-spin`;
  }
  return (
    <button
      ref={ref}
      type="button"
      disabled={mode === ButtonMode.DISABLED}
      className={overrideClassName(`${sharedStyles} ${modeStyles[mode]} ${sizeStyles[size]}`, className)}
      {...otherProps}>
      {icon ? (
        <Icon icon={icon} className={overrideClassName(`${iconSizeStyles[size]}`, iconClassName)} aria-hidden={true} />
      ) : null}
      {children}
    </button>
  );
});

export default Button;
