import React, { useCallback } from 'react';
import FormInput from './formInput';
import format from 'date-fns/format';

let FormDate = (props) => {
  let { input, onChangeFormat, ...otherProps } = props;
  let { value, onChange, ...otherInput } = input || {};
  let myOnChange = useCallback(
    (val) => {
      if (val) {
        onChange(onChangeFormat(new Date(val + 'T00:00:00')));
      } else {
        onChange(null);
      }
    },
    [onChange]
  );
  let vs = value;
  if (value && typeof value === 'string' && !value.includes('T')) {
    vs = value + 'T00:00:00';
  }
  let myValue: any = null;
  try {
    myValue = vs ? format(typeof vs === 'string' ? new Date(vs) : vs, 'yyyy-MM-dd') : null;
  } catch (err) {
    console.log('error parsing date value', value);
  }
  return <FormInput input={{ value: myValue, onChange: myOnChange, ...otherInput }} {...otherProps} type="date" />;
};
export default FormDate;
