import { FORM_ERROR } from 'final-form';

export let deleteWrapper = (deleteAction, deleteConfirm?: Function) => async (data) => {
  try {
    if (!deleteConfirm || await deleteConfirm()) {
      await deleteAction(data);
    }
  } catch (err) {
    console.log(err);
    return {
      [FORM_ERROR]: err
    };
  }
};

export let submitWrapper = (submitAction: Function, initialValues?: any) => async (data, form) => {
  let d = data;
  if (initialValues?.id) {
    d = {};
    let { dirtyFields } = form.getState();
    Object.keys(dirtyFields).forEach((k) => {
      k = k.split('.')[0];
      d[k] = data[k] ?? null;
    });
  }
  try {
    await submitAction(d);
  } catch (err) {
    console.log(err);
    return {
      [FORM_ERROR]: err
    };
  }
};


export let publishWrapper = (publishAction: Function, initialValues?: any) => async (data) => {
  try {
    await publishAction(data);
  } catch (err) {
    console.log(err);
    return {
      [FORM_ERROR]: err
    };
  }
};