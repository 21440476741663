import React from 'react';
import { valueTypeClassName } from './utils';
import { overrideClassName } from '../index';
import Icon from '../icon';

let HeaderCell =
  (
    cProps?: {
      valueType?: string;
      headerClassName?: string;
      sortKey?: string;
    },
    headerClick?: Function
  ) =>
  (props: {
    columnData: any;
    dataKey: string;
    disableSort?: boolean;
    label: any;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC';
  }) => {
    const { dataKey, disableSort, label, sortBy, sortDirection } = props;
    let { valueType, headerClassName } = cProps || {};
    let className = `self-center flex-auto truncate relative ${valueTypeClassName(valueType)}`;
    let onClick;
    if (headerClick && !disableSort) {
      className += ' cursor-pointer';
      onClick = () => headerClick(sortKey);
    }

    let sortKey = cProps?.sortKey || dataKey;
    return (
      <div className={overrideClassName(className, headerClassName)} title={label} onClick={onClick}>
        {label}
        {sortKey === sortBy && (
          <Icon
            icon={sortDirection === 'ASC' ? 'triangle-small-up' : 'triangle-small-down'}
            className="absolute right-2"
          />
        )}
      </div>
    );
  };
export default HeaderCell;
