import React from 'react';
import { Outlet } from 'react-router-dom';
import { Table, PageWrapper } from '@src/components';
import { getUsers } from '@src/users/data';
import useAuth from '@src/auth/useAuth';

let columns = [
  {
    dataKey: 'name',
    label: 'Name',
    valueType: 'string'
  },
  {
    dataKey: 'email',
    label: 'Email',
    valueType: 'string'
  }
];

let Users = () => {
  let { db } = useAuth();
  return (
    <PageWrapper breadcrumbsProps={{ pages: [], currentLabel: 'Users' }} title="Users">
      <Table
        columns={columns}
        dataKey={['user']}
        data={getUsers(db)}
        getRowLink={(p: any) => `${p?.rowData?.id}`}
        add="new"
      />
      <Outlet />
    </PageWrapper>
  );
};
export default Users;
