import React from 'react';
import { AbsoluteFormWrapper, FormTemplate } from '@src/components';
import PlotFields from '@src/plots/plotFields';
import { useQueryClient, useMutation } from 'react-query';
import { createPlot } from '@src/plots/data';
import useAuth from '@src/auth/useAuth';
import {useNavigate, useSearchParams} from 'react-router-dom';

let NewPlot = () => {
  let { db } = useAuth();
  let [searchParams] = useSearchParams();
  let queryClient = useQueryClient();
  let navigate = useNavigate();

  let mutation = useMutation(
    (newItem) => {
      return createPlot({ db, data: newItem });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('plot');
        navigate('../');
      }
    }
  );

  return (
    <AbsoluteFormWrapper>
      <FormTemplate
        submitAction={mutation.mutateAsync}
        breadcrumbsProps={{ pages: [{ label: 'Plots' }], currentLabel: 'New Plot' }}
        initialValues={{
          row: searchParams.get('row'),
          plot: searchParams.get('plot'),
        }}>
        <PlotFields />
      </FormTemplate>
    </AbsoluteFormWrapper>
  );
};

export default NewPlot;
