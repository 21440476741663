import React from 'react';
import { FormInput } from '../form';
import Button, { ButtonMode, ButtonSize } from '../button';
import { Link } from 'react-router-dom';
import FilterField from './filterField';
import TopFilterBadge from './topFilterBadge';

let TopFilter = (props) => {
  let { filter, setFilter, showSearch, add, columns, debounce, title, otherButtons, _rows, searchOnKeyDown } = props;
  let setValue = (k) => (v) => {
    let nf = Object.assign({}, filter);
    if ((typeof v === 'object' && v !== null) || v?.length) {
      nf[k] = v;
    } else {
      delete nf[k];
    }
    setFilter(nf);
  };

  let pullValue = (k, v) => () => {
    let nv = (filter?.[k] || []).slice();
    let i = nv.indexOf(v);
    if (i > -1) {
      nv = [...nv.slice(0, i), ...nv.slice(i + 1)];
    }
    setValue(k)(nv);
  };

  let fields: any[] = [];
  if (showSearch) {
    fields.push(
      <FormInput
        key="search"
        input={{ value: filter?.search, onChange: setValue('search'), onKeyDown: searchOnKeyDown }}
        placeholder="Search"
        icon="magnify"
        divClassName="mt-0"
        debounce={debounce}
      />
    );
  }

  //loop over columns
  columns.forEach((c) => {
    if (c.filter) {
      // render fixed filter options
      fields.push(
        <FilterField key={c.dataKey} c={c} input={{ value: filter?.[c.dataKey], onChange: setValue(c.dataKey) }} />
      );
    }
  });

  if (typeof add === 'string') {
    fields.push(
      <React.Fragment key="add">
        <div className="flex-auto" />
        {otherButtons}
        <Link to={add}>
          <Button icon="plus" mode={ButtonMode.PRIMARY} size={ButtonSize.SM} />
        </Link>
      </React.Fragment>
    );
  } else if (typeof add === 'function') {
    fields.push(
      <React.Fragment key="add">
        <div className="flex-auto" />
        {otherButtons}
        <Button icon="plus" mode={ButtonMode.PRIMARY} size={ButtonSize.SM} onClick={add.bind(null, _rows)} />
      </React.Fragment>
    );
  } else if (otherButtons) {
    fields.push(
      <React.Fragment key="add">
        <div className="flex-auto" />
        {otherButtons}
      </React.Fragment>
    );
  }
  if (title) {
    fields.unshift(
      <h3 key="title" className={`text-lg leading-6 font-medium`}>
        {title}
      </h3>
    );
  }
  if (fields.length) {
    let { search, ...otherFilters } = filter || {};
    return (
      <>
        <div className={`flex-none flex items-center mb-4 space-x-2 ${title ? 'pt-4' : ''}`}>{fields}</div>
        {Object.keys(otherFilters)?.length ? (
          <div className="flex-none flex items-center mb-4 space-x-2">
            {Object.entries(otherFilters).map(([k, f]) =>
              Array.isArray(f) ? (
                f.map((v) => (
                  <TopFilterBadge
                    key={`${k}_${v}`}
                    column={columns.find((c) => c.dataKey === k)}
                    input={{ value: v, onChange: pullValue(k, v) }}
                  />
                ))
              ) : (
                <TopFilterBadge
                  key={k}
                  column={columns.find((c) => c.dataKey === k)}
                  input={{ value: f, onChange: setValue(k) }}
                />
              )
            )}
          </div>
        ) : null}
      </>
    );
  } else {
    return null;
  }
};
export default TopFilter;
