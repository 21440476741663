import React from 'react';
import { Link, useMatch } from 'react-router-dom';

let Tab = (props) => {
  let { tab, ti, activeTabIndex, onTabIndexChange } = props;
  let match: any = useMatch({ path: tab.path || '' });
  if (typeof activeTabIndex === 'number') {
    match = ti === activeTabIndex;
  }
  let C: any = 'div';
  let cProps = {};
  if (tab.to || tab.path) {
    C = Link;
    cProps = {
      to: tab.to || tab.path
    };
  } else if (typeof activeTabIndex === 'number') {
    cProps = {
      onClick: () => {
        onTabIndexChange(ti);
      }
    };
  }
  return (
    <C
      key={tab.label}
      {...cProps}
      className={`${
        match
          ? 'border-primaryIcon-50 text-primaryIconHover-50 dark:border-primary-400 dark:text-primary-400'
          : 'border-transparent text-zinc-500 hover:text-label-text hover:border-zinc-200'
      } whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer`}
      aria-current={match ? 'page' : undefined}>
      {tab.label}
      {tab.count ? (
        <span
          className={`${
            match
              ? 'bg-primaryAlertHover-50 text-primaryIconHover-50 dark:text-primary-200'
              : 'bg-zinc-100 text-zinc-900'
          } hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`}>
          {tab.count}
        </span>
      ) : null}
    </C>
  );
};
export default Tab;
