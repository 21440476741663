import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import Icon from '../icon';

let SidebarLinkLg = (props) => {
  let { item } = props;
  let match = useMatch({ path: item.path });
  return (
    <Link
      to={item.to || item.path}
      className={`${
        match
          ? 'bg-zinc-100 text-zinc-900'
          : 'text-label-text hover:text-zinc-900 hover:bg-zinc-50 dark:hover:text-zinc-100 dark:hover:bg-zinc-800 dark:text-zinc-300'
      } group flex items-center px-2 py-2 text-sm font-medium rounded-md`}
      aria-current={match ? 'page' : undefined}>
      <Icon
        icon={item.icon}
        className={`${match ? 'text-zinc-500' : 'text-zinc-400 group-hover:text-zinc-500'} mr-3 flex-shrink-0 h-6 w-6`}
        aria-hidden="true"
      />
      {item.label}
    </Link>
  );
};
export default SidebarLinkLg;
