import React from 'react';
import { AuthContext } from './authProvider';
import { signOut } from 'firebase/auth';

let useSignOut = () => {
  let { auth } = React.useContext(AuthContext);
  return async () => {
    await signOut(auth);
  };
};

export default useSignOut;
