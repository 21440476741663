import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ActionsMode } from './index';

let ActionsListItem = (props) => {
  let { action, closeAction, getState, mode } = props;
  let myOnClick = useCallback(
    (e) => {
      if (action.onClick) {
        if (getState) {
          action.onClick(getState().values, e);
        } else {
          action.onClick(e);
        }
      }
      closeAction();
    },
    [action, closeAction, getState]
  );
  let { onClick, label, ...otherProps } = action || {};
  let C: any = 'div';
  if (action.to) {
    C = Link;
  }

  let modeStyles = {
    [ActionsMode.LEFT]: '',
    [ActionsMode.RIGHT]: 'text-right'
  };

  return (
    <C className={`px-8 py-4 hover:bg-zinc-100 cursor-pointer ${modeStyles[mode]}`} onClick={myOnClick} {...otherProps}>
      {action.label}
    </C>
  );
};
export default ActionsListItem;
