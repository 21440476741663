import React, { useCallback } from 'react';
import { FormDate } from '../form';

let DateRange = (props) => {
  let { input } = props;
  let fromOnChange = useCallback(
    (v) => {
      let nv = Object.assign({}, input.value);
      if (v) {
        nv.from = v;
      } else {
        delete nv.from;
      }
      if (!Object.keys(nv)) {
        nv = null;
      }
      input.onChange(nv);
    },
    [input]
  );
  let toOnChange = useCallback(
    (v) => {
      let nv = Object.assign({}, input.value);
      if (v) {
        nv.to = v;
      } else {
        delete nv.to;
      }
      if (!Object.keys(nv)) {
        nv = null;
      }
      input.onChange(nv);
    },
    [input]
  );

  return (
    <div className="p-4">
      <FormDate input={{ value: input.value?.from, onChange: fromOnChange }} label="From" divClassName="ml-0 mt-0" />
      <FormDate input={{ value: input.value?.to, onChange: toOnChange }} label="To" divClassName="ml-0" />
    </div>
  );
};
export default DateRange;
