import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from '@src/auth/authProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfirmProvider, WindowStateProvider } from '@src/components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3600000 // 1 hour
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfirmProvider>
        <WindowStateProvider title="HCC Cemetery Admin">
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </WindowStateProvider>
      </ConfirmProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
