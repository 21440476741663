import React, { useEffect, useRef, useMemo } from 'react';
import * as ReactDOM from 'react-dom';
import { Modifier, usePopper } from 'react-popper';
import maxSize from 'popper-max-size-modifier';
import { overrideClassName } from '../index';

let Popover = (props) => {
  let { children, isOpen, closeAction, source, popperClassName, sameWidth = true, debug } = props;
  let isOpenRef = useRef(isOpen);
  let [referenceElement, setReferenceElement] = React.useState<any>(null);
  let [popperElement, setPopperElement] = React.useState<any>(null);
  isOpenRef.current = isOpen;
  const modifiers = useMemo<Modifier<any, any>[]>(
    () =>
      sameWidth
        ? [
            {
              name: 'sameWidth',
              enabled: true,
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              fn: ({ state }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
              },
              effect({ state }) {
                state.elements.popper.style.width = `${
                  // @ts-ignore
                  state.elements.reference.offsetWidth
                }px`;
              }
            },
            {
              ...maxSize,
              options: {
                padding: 10
              }
            },
            {
              name: 'applyMaxSize',
              enabled: true,
              phase: 'beforeWrite',
              requires: ['maxSize'],
              fn({ state }) {
                // The `maxSize` modifier provides this data
                const { width, height } = state.modifiersData.maxSize;
                state.styles.popper = {
                  ...state.styles.popper,
                  maxWidth: `${Math.max(100, width)}px`,
                  maxHeight: `${Math.max(100, height)}px`
                };
              }
            },
            {
              name: 'flip',
              enabled: true
            }
          ]
        : [],
    [sameWidth]
  );
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers
  });
  let clickCount = useRef(0); // this is to handle the fact that isOpen becomes true before documentClick fires
  if (debug) {
    console.log({ isOpen });
  }

  useEffect(() => {
    // listen for clicks and close dropdown on body
    let documentClick = (e) => {
      if (debug) {
        console.log('documentClick', clickCount.current);
      }
      if (isOpenRef.current) {
        if (clickCount.current) {
          closeAction && closeAction();
          clickCount.current = 0;
        } else {
          clickCount.current += 1;
        }
      }
    };

    document.addEventListener('click', documentClick);
    document.addEventListener('touchend', documentClick);
    return () => {
      document.removeEventListener('click', documentClick);
      document.removeEventListener('touchend', documentClick);
    };
  }, [closeAction, debug]);

  return (
    <>
      {source(setReferenceElement)}
      {isOpen &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            onClick={(e) => e.stopPropagation()}
            className={overrideClassName(
              'mt-1 z-10 bg-white dark:bg-zinc-700 shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 overflow-auto text-zinc-900 dark:text-zinc-100 border-zinc-100 border text-sm',
              popperClassName
            )}
            style={styles.popper}
            {...attributes.popper}>
            {children}
          </div>,
          document.body
        )}
    </>
  );
};
export default Popover;
