import React, { useCallback } from 'react';
import FormCheckbox from '../form/formCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, checkAll } from '../redux';
import get from 'lodash/get';

let TableCheckboxHeader = (props) => {
  let { checkboxKey, rowCount, _rows, checkboxID } = props;
  let dispatch = useDispatch();
  let checked = useSelector((state: RootState) => state.table?.[checkboxKey]);
  let value = false;
  if (rowCount && Object.values(checked || {}).filter((v) => !!v).length === rowCount) {
    value = true;
  }
  let onChange = useCallback(() => {
    let allChecked = true;
    let ids: any[] = [];
    if (_rows) {
      _rows.current?.forEach((r, ri) => {
        if (r) {
          let id = checkboxID === 'rowIndex' ? ri : get(r, checkboxID);
          ids.push(id);
          if (!checked?.[id]) {
            allChecked = false;
          }
        }
      });
      dispatch(
        checkAll({
          checkboxKey,
          ids,
          checked: !allChecked
        })
      );
    }
  }, [checkboxKey, checkboxID, dispatch, checked, _rows]);
  if (!_rows) {
    return <div className="mt-0 self-center w-5" />;
  }
  return <FormCheckbox input={{ value, onChange }} divClassName="mt-0 self-center" />;
};
export default TableCheckboxHeader;
