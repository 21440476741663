import React, { useCallback, useRef } from 'react';
import FormCheckbox from '../form/formCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, checkRow } from '../redux';
import get from 'lodash/get';

let TableCheckbox = (props) => {
  let { checkboxKey, checkboxID, id, _rows } = props;
  let isShiftKey = useRef(false);
  let dispatch = useDispatch();
  let checked = useSelector((state: RootState) => state.table?.[checkboxKey]?.[id]);
  let lastChecked = useSelector((state: RootState) => state.table?.[`${checkboxKey}_lastChecked`]);
  let onChange = useCallback(
    (checked) => {
      if (isShiftKey.current && lastChecked) {
        let isActive = false;
        _rows.current?.forEach((r, ri) => {
          if (r) {
            let rid = checkboxID === 'rowIndex' ? ri : get(r, checkboxID);
            if (rid === lastChecked) {
              isActive = true;
            } else if (isActive) {
              dispatch(
                checkRow({
                  checkboxKey,
                  id: rid,
                  checked
                })
              );
              if (rid === id) {
                isActive = false;
              }
            }
          }
        });
      } else {
        dispatch(
          checkRow({
            checkboxKey,
            id,
            checked
          })
        );
      }
    },
    [id, checkboxKey, dispatch, lastChecked, _rows, checkboxID]
  );

  return (
    <FormCheckbox
      input={{ value: !!checked, onChange }}
      divClassName="mt-0 ml-0"
      inputProps={{
        onClick: (e) => {
          isShiftKey.current = e.shiftKey;
          e.stopPropagation();
        }
      }}
    />
  );
};
export default TableCheckbox;
