import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import type { RootState } from './index';

export interface TableSlice {
  [key: string]: {
    [key: string]: boolean;
  };
}

let initialState: TableSlice = {};

export let tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    checkRow: (
      state,
      action: PayloadAction<{
        checkboxKey: string;
        id: any;
        checked: boolean;
      }>
    ) => {
      let { checkboxKey, id, checked } = action.payload;
      if (!state[checkboxKey]) {
        state[checkboxKey] = {};
      }
      state[checkboxKey][id] = checked;
      if (checked) {
        state[`${checkboxKey}_lastChecked`] = id;
      } else {
        delete state[`${checkboxKey}_lastChecked`];
      }
    },
    checkAll: (
      state,
      action: PayloadAction<{
        checkboxKey: string;
        ids: any[];
        checked: boolean;
      }>
    ) => {
      let { checkboxKey, ids, checked } = action.payload;
      if (!state[checkboxKey]) {
        state[checkboxKey] = {};
      }
      for (let id of ids) {
        state[checkboxKey][id] = checked;
      }
    },
    uncheckAll: (
      state,
      action: PayloadAction<{
        checkboxKey: string;
      }>
    ) => {
      let { checkboxKey } = action.payload;
      state[checkboxKey] = {};
    }
  }
});

export let { checkRow, checkAll, uncheckAll } = tableSlice.actions;
export default tableSlice.reducer;
