import React, { forwardRef } from 'react';
import { overrideClassName } from '../index';
import * as paths from '@mdi/js';
import customPaths from './paths';
import gs from './gs';
import camelCase from 'lodash/camelCase';

export { default as Icons } from './icons';

export enum IconMode {
  INHERIT = 'Inherit',
  PLAIN = 'Plain',
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  DISABLED = 'Disabled',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger'
}

export enum IconSize {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  '2XL' = '2XL'
}

let Icon = forwardRef((props: any, ref) => {
  let { icon, className, size = IconSize.LG, mode = IconMode.INHERIT, onClick, ...otherProps } = props;
  let sizeStyles = {
    [IconSize.XS]: 'h-3 w-3',
    [IconSize.SM]: 'h-4 w-4',
    [IconSize.MD]: 'h-5 w-5',
    [IconSize.LG]: 'h-6 w-6',
    [IconSize.XL]: 'h-8 w-8',
    [IconSize['2XL']]: 'h-10 w-10'
  };

  let modeStyles = {
    [IconMode.INHERIT]: '',
    [IconMode.PLAIN]: `text-zinc-600 ${onClick ? 'cursor-pointer hover:text-zincIconHover-50' : ''}`,
    [IconMode.PRIMARY]: `text-primaryIcon-50 ${onClick ? 'cursor-pointer hover:text-primaryIconHover-50' : ''}`,
    [IconMode.SECONDARY]: `text-secondaryIcon-50 ${onClick ? 'cursor-pointer hover:text-secondaryIconHover-50' : ''}`,
    [IconMode.DISABLED]: 'text-disabledIcon-50 cursor-not-allowed',
    [IconMode.SUCCESS]: `text-successIcon-50 ${onClick ? 'cursor-pointer hover:text-successIconHover-50' : ''}`,
    [IconMode.WARNING]: `text-warningIcon-50 ${onClick ? 'cursor-pointer hover:text-warningIconHover-50' : ''}`,
    [IconMode.DANGER]: `text-dangerIcon-50 ${onClick ? 'cursor-pointer hover:text-dangerIconHover-50' : ''}`
  };

  // @ts-ignore
  let path = paths[camelCase(`mdi-${icon}`)] || customPaths[icon] || null;
  let g = null;
  if (!path) {
    g = gs[icon] || null;
  }
  return (
    <svg
      ref={ref}
      className={overrideClassName(`fill-current ${sizeStyles[size]} ${modeStyles[mode]}`, className)}
      data-testid={`icon-${icon}`}
      viewBox="0 0 24 24"
      onClick={onClick}
      aria-hidden="true"
      {...otherProps}>
      {path ? <path d={path} /> : g}
    </svg>
  );
});
export default Icon;
