import React from 'react';

let TableFooter = (props) => {
  const { queryData } = props;
  if (queryData?.data?.total) {
    let total = queryData.data.total.toLocaleString();
    if (queryData.data.total === 10000) {
      total = '10,000+';
    }
    return (
      <div className="flex justify-end mt-2 text-xs">Total Records: {total}</div>
    )
  } else {
    return null;
  }
}
export default TableFooter;
