import React from 'react';
import PageHeading from './pageHeading';

let PageWrapper = (props) => {
  let { children, breadcrumbsProps, title } = props;
  return (
    <div data-testid={'pageWrapper'} className="flex-auto flex flex-col relative">
      <PageHeading breadcrumbsProps={breadcrumbsProps} title={title} />
      <div className="p-4 flex-10auto flex flex-col">{children}</div>
    </div>
  );
};
export default PageWrapper;
