import React from 'react';
import { valueTypeClassName } from './utils';
import { overrideClassName } from '../index';
import formatISO9075 from 'date-fns/formatISO9075';
import format from 'date-fns/format';

let Cell =
  (cProps?: { valueType?: string; className?: string }) =>
  (props: {
    cellData: any;
    columnData: any;
    columnIndex: number;
    dataKey?: string;
    isScrolling?: boolean;
    rowData?: any;
    rowIndex: number;
  }) => {
    let { cellData } = props;
    let { valueType, className } = cProps || {};
    let ret = cellData;
    if (ret && valueType === 'datetime') {
      try {
        ret = formatISO9075(new Date(ret));
      } catch (e) {
        ret = ret?.toString();
        console.log('unable to format date', ret, e);
      }
    } else if (ret && valueType === 'date') {
      try {
        if (ret && typeof ret === 'string' && !ret.includes('T')) {
          ret = ret + 'T00:00:00';
        }
        ret = format(new Date(ret), 'yyyy-MM-dd');
      } catch (e) {
        ret = ret?.toString();
        console.log('unable to format date', ret, e);
      }
    } else if (valueType === 'boolean') {
      ret = cellData === true ? 'Y' : cellData === false ? 'N' : '';
    }
    return (
      <div
        className={overrideClassName(
          `self-center flex-auto truncate text-sm text-zinc-900 ${valueTypeClassName(valueType)}`,
          className
        )}>
        {ret}
      </div>
    );
  };
export default Cell;
