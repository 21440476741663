import React, { useCallback } from 'react';
import uniqueId from 'lodash/uniqueId';
import LabelledField from '../labelledField';
import { overrideClassName, useDisabled } from '../index';
import InputMode from '../inputMode';

let FormCheckbox = (props) => {
  let formDisabled = useDisabled();
  let {
    style,
    disabled = formDisabled,
    label,
    input,
    meta,
    id,
    divClassName,
    inputWrapperProps = {},
    inputWrapperClassName,
    labelProps = {},
    labelClassName,
    labelOnLeft,
    labelOnRight,
    inputClassName,
    mode = InputMode.PRIMARY,
    inputProps = {}
  } = props;
  let { value, onChange, name } = input || props;

  let checked = !!value;
  let modeStyles = {
    [InputMode.PRIMARY]: `focus:ring-primaryRing-50 text-primaryRing-50 rounded cursor-pointer ${
      checked ? 'focus:border-primaryRing-50 bg-primaryRing-50' : 'focus:border-zinc-200 border-zinc-300'
    }`,
    [InputMode.SUCCESS]: `focus:ring-successRing-50 text-successRing-50 rounded cursor-pointer ${
      checked ? 'focus:border-successRing-50 bg-successRing-50' : 'focus:border-zinc-200 border-zinc-300'
    }`,
    [InputMode.DISABLED]: ` ${
      checked
        ? 'bg-disabled-500 rounded cursor-not-allowed'
        : 'focus:border-zinc-200 border-zinc-300 rounded cursor-not-allowed'
    }`,
    [InputMode.DANGER]: `focus:ring-dangerRing-50 text-dangerRing-50 rounded cursor-pointer ${
      checked ? 'focus:border-dangerRing-50 bg-dangerRing-50' : 'focus:border-zinc-200 border-zinc-300'
    }`,
    [InputMode.ADMIN]: `focus:ring-admin-ring text-admin-ring rounded cursor-pointer ${
      checked ? 'focus:border-admin-ring bg-admin-ring' : 'focus:border-zinc-200 border-zinc-300'
    }`
  };

  let myID = id || name || `FormCheckbox_${uniqueId()}`;
  let change = useCallback((e) => onChange(!value), [value, onChange]);
  return (
    <LabelledField
      style={style}
      label={label}
      divClassName={divClassName}
      inputWrapperProps={inputWrapperProps}
      inputWrapperClassName={inputWrapperClassName}
      labelProps={labelProps}
      labelClassName={labelClassName}
      htmlFor={myID}
      labelOnLeft={labelOnLeft}
      labelOnRight={labelOnRight}
      meta={meta}
      mode={mode}>
      <input
        name={myID}
        type="checkbox"
        className={overrideClassName(`${modeStyles[mode]} h-4 w-4`, inputClassName)}
        checked={checked}
        disabled={disabled}
        onChange={change}
        {...inputProps}
      />
    </LabelledField>
  );
};

export default FormCheckbox;
