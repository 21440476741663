import React, { useCallback, useState } from 'react';
import { Popover } from '../index';
import Button, { ButtonMode, ButtonSize } from '../button';
import ActionsListItem from './actionsListItem';

export enum ActionsMode {
  RIGHT = 'right',
  LEFT = 'left'
}

export let Actions = (props) => {
  let { actions, getState, className, mode = ActionsMode.RIGHT } = props;
  let [isOpen, setIsOpen] = useState(false);
  let closeAction = useCallback(() => {
    setIsOpen(false);
  }, []);
  if (actions?.length) {
    return (
      <Popover
        sameWidth={false}
        isOpen={isOpen}
        closeAction={closeAction}
        source={(ref) => (
          <Button
            ref={ref}
            onClick={(e) => setIsOpen(!isOpen)}
            size={ButtonSize.SM}
            mode={ButtonMode.SECONDARY}
            className={className}>
            Actions
          </Button>
        )}>
        {actions?.map((a, ai) => (
          <ActionsListItem key={ai} action={a} closeAction={closeAction} getState={getState} mode={mode} />
        ))}
      </Popover>
    );
  } else {
    return null;
  }
};
