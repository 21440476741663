import React, { useCallback } from 'react';
import { Button } from '@src/components';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import useAuth from '@src/auth/useAuth';

let Login = () => {
  let { auth } = useAuth();
  const [signInWithGoogle] = useSignInWithGoogle(auth);

  let signIn = useCallback(() => {
    let fn = async () => {
      await signInWithGoogle();
    };
    fn();
  }, [signInWithGoogle]);

  return (
    <div className="flex w-full h-full items-center justify-center">
      <Button onClick={signIn}>Login</Button>
    </div>
  );
};
export default Login;
