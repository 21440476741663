import React, { useCallback, useState } from 'react';
import { Icon, IconMode, Icons, IconSize, overrideClassName, Popover } from './index';

let Information = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  let closeAction = useCallback(() => {
    setIsOpen(false);
  }, []);
  let {
    icon = Icons.INFORMATION,
    size = IconSize.MD,
    iconClassName,
    iconMode = IconMode.PRIMARY,
    title,
    description
  } = props;
  return (
    <Popover
      sameWidth={false}
      isOpen={isOpen}
      closeAction={closeAction}
      source={(ref) => (
        <Icon
          ref={ref}
          icon={icon}
          size={size}
          className={overrideClassName('inline-block mr-1 mb-1', iconClassName)}
          mode={iconMode}
          onClick={setIsOpen.bind(null, true)}
        />
      )}>
      <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
        <div className="bg-white px-4 pt-5 pb-2 sm:px-6">
          <p className="text-lg leading-6 font-medium text-zinc-900">{title}</p>
        </div>
        <div className="px-4 pb-5 sm:p-6 sm:pt-0 max-w-lg" dangerouslySetInnerHTML={{__html:description || ''}} />
      </div>
    </Popover>
  );
};
export default Information;
