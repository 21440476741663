import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormTemplate, AbsoluteFormWrapper } from '@src/components';
import useAuth from '@src/auth/useAuth';
import PlotFields from '@src/plots/plotFields';
import { deletePlot, getPlot, updatePlot } from '@src/plots/data';

let Plot = (props) => {
  let { db } = useAuth();
  let { plotID } = useParams();
  let queryClient = useQueryClient();
  let { data, isLoading } = useQuery(['plot', plotID], getPlot.bind(null, { params: { id: plotID } }));
  let navigate = useNavigate();

  let updateMutation = useMutation(
    (newItem: any) => {
      return updatePlot({
        db,
        data: {
          id: plotID,
          ...newItem
        }
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('plot');
        navigate('../');
      }
    }
  );

  let deleteMutation = useMutation(
    () => {
      return deletePlot({ db, params: { id: plotID } });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('plot');
        navigate('../');
      }
    }
  );

  return (
    <AbsoluteFormWrapper>
      <FormTemplate
        loading={isLoading}
        submitAction={updateMutation.mutateAsync}
        deleteAction={deleteMutation.mutate}
        breadcrumbsProps={{
          pages: [{ label: 'Plots' }],
          currentLabel: `${data?.row || ''}${data?.plot ? `-${data?.plot}` : 'Plot'}`
        }}
        initialValues={data}>
        <PlotFields />
      </FormTemplate>
    </AbsoluteFormWrapper>
  );
};
export default Plot;
