import { createSlice } from '@reduxjs/toolkit';

export interface UserSlice {
  user?: any;
}

let initialState: UserSlice = {};

export let userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {}
});

// export let {  } = userSlice.actions;
export default userSlice.reducer;
