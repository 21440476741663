import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

export let valueTypeClassName = (valueType) => {
  let ret: string[] = [];
  if (valueType === 'number') {
    ret.push('text-right');
  }
  return ret.join(' ');
};

export let defaultFilter = (items, filter, columns) => {
  return items.filter((r) => {
    let ret = true;
    Object.entries(filter).forEach(([k, v]) => {
      if (ret) {
        if (k === 'search') {
          let search = (v as string)?.toLowerCase() || '';
          // loop over columns to check for match
          let columnMatch = false;
          for (let c of columns) {
            let rc = get(r, c.dataKey);
            if (c?.valueType === 'string') {
              columnMatch = rc?.toLowerCase().includes(search);
            } else if (c?.valueType === 'number') {
              columnMatch = rc?.toString() === search;
            }
            if (columnMatch) {
              break;
            }
          }
          ret = columnMatch;
        } else {
          let c = columns?.find((c) => c.dataKey === k);
          let rc = get(r, k);
          if (Array.isArray(v)) {
            if (['string', 'number'].includes(c.valueType)) {
              ret = v.includes(rc);
            }
          } else {
            if (c.valueType === 'string') {
              ret = v === rc;
            } else if (c?.valueType === 'number') {
              ret = (v as string)?.toString() === rc?.toString();
            }
          }
        }
      }
    });
    return ret;
  });
};

export let defaultCellDataGetter = ({ rowData, dataKey }) => {
  if (dataKey?.includes('.')) {
    return get(rowData, dataKey);
  } else {
    return rowData?.[dataKey];
  }
};

let genParams = (obj) => {
  return {
    json: JSON.stringify(obj)
  };
};

export let loadInitialData = async ({
  data,
  _filter,
  _sort,
  minimumBatchSize,
  columns,
  _loadedRows,
  _rows,
  dataParams,
  staticFilter
}) => {
  if (typeof data === 'function') {
    for (let x = 0; x < minimumBatchSize; x++) {
      _loadedRows.current[x] = true;
    }
    let ret: {
      total: number;
      items: [];
    } = await data({
      ...(dataParams || {}),
      params: genParams({
        filter: {
          ...(_filter.current || {}),
          ...(staticFilter || {})
        },
        sort: _sort.current,
        startIndex: 0,
        stopIndex: minimumBatchSize
      })
    });
    _rows.current = ret.items || [];
    if (ret?.total === 10000) {
      for (let i = 0; i < minimumBatchSize; i++) {
        if (!_rows.current[i]) {
          ret.total = i;
          break;
        }
      }
    }
    return {
      ...ret,
      dataTS: new Date().getTime()
    };
  } else {
    let ret = (data?.items || []).slice();
    // filter and sort
    if (Object.keys(_filter.current || {}).length) {
      ret = defaultFilter(ret, _filter.current, columns);
    }
    if (Object.keys(_sort.current || {}).length) {
      ret = orderBy(
        ret,
        Object.keys(_sort.current),
        // @ts-ignore
        Object.values(_sort.current).map((s) => (s as string).toLowerCase())
      );
    }
    for (let x = 0; x < ret.length - 1; x++) {
      _loadedRows.current[x] = true;
    }
    _rows.current = ret;
    return {
      dataTS: new Date().getTime(),
      items: ret,
      total: ret.length
    };
  }
};

export let loadMoreData = async ({
  data,
  _filter,
  _sort,
  startIndex,
  stopIndex,
  _loadedRows,
  _rows,
  dataParams,
  staticFilter
}) => {
  for (let x = startIndex; x < stopIndex; x++) {
    _loadedRows.current[x] = true;
  }
  let ret: {
    items: [];
  } = await data({
    ...(dataParams || {}),
    params: genParams({
      filter: {
        ...(_filter.current || {}),
        ...(staticFilter || {})
      },
      sort: _sort.current,
      startIndex,
      stopIndex
    })
  });
  _rows.current.splice(startIndex, (ret?.items || []).length, ...(ret?.items || []));
};
