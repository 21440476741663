import React from 'react';
import { Field } from 'react-final-form';
import { FormInput, FormSection, FormDate } from '@src/components';
import format from 'date-fns/format';

let PlotFields = (props) => {
  return (
    <FormSection label="">
      <Field
        component={FormInput}
        name="row"
        label="Row"
        labelOnLeft={true}
        divClassName="sm:border-t-0"
        autoFocus={true}
      />
      <Field component={FormInput} name="plot" label="Plot" labelOnLeft={true} />
      <Field component={FormInput} name="first" label="First Name" labelOnLeft={true} />
      <Field component={FormInput} name="last" label="Last Name" labelOnLeft={true} />
      <Field
        component={FormDate}
        name="birth"
        label="Birth"
        labelOnLeft={true}
        onChangeFormat={(val) => format(val, 'yyyy-MM-dd')}
      />
      <Field
        component={FormDate}
        name="death"
        label="Death"
        labelOnLeft={true}
        onChangeFormat={(val) => format(val, 'yyyy-MM-dd')}
      />
      <Field component={FormInput} name="notes" label="Notes" labelOnLeft={true} multiLine={true} />
    </FormSection>
  );
};
export default PlotFields;
