import React, { useCallback } from 'react';
import Tab from './tab';
import { useNavigate } from 'react-router-dom';
import { overrideClassName } from '../index';

let Tabs = (props) => {
  let { tabs, activeTabIndex, onTabIndexChange, className } = props;
  let navigate = useNavigate();
  let tabChange = useCallback(
    (tab) => {
      if (tab?.to || tab?.path) {
        navigate(tab.to || tab.path);
      }
    },
    [navigate]
  );
  return (
    <div>
      <div className="sm:hidden" data-testid="mw-tabs-nav">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={tabChange}
          className="block w-full pl-3 pr-10 py-2 text-base border-zinc-300 focus:outline-none focus:ring-primaryButtonBg-50 focus:border-primaryButtonBg-50 sm:text-sm rounded-md"
          // defaultValue={tabs.find((tab) => tab.current).label}
          // how to do this??
        >
          {tabs.map((tab) => (
            <option key={tab.label}>{tab.label}</option>
          ))}
        </select>
      </div>
      <div className={overrideClassName('hidden sm:block bg-white', className)}>
        <div className="border-b border-zinc-200">
          <nav className="-mb-px flex space-x-8 px-4" aria-label="Tabs" data-testid="desktop-tabs-nav">
            {tabs.map((tab, ti) => (
              <Tab
                key={tab.label}
                tab={tab}
                activeTabIndex={activeTabIndex}
                onTabIndexChange={onTabIndexChange}
                ti={ti}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Tabs;
