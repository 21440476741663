import React from 'react';
import { overrideClassName } from './index';
import Button, { ButtonSize } from './button';

export enum BadgeMode {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DANGER = 'Danger'
}

export enum BadgeSize {
  MD = 'md',
  LG = 'lg'
}

let Badge = (props) => {
  let { size = BadgeSize.MD, mode = BadgeMode.PRIMARY, children, className, removeAction } = props;

  let shared = 'inline-flex items-center rounded-full font-medium';
  let modeStyles = {
    [BadgeMode.PRIMARY]: `text-primaryButtonText-50 bg-primaryButtonBg-50`,
    [BadgeMode.SECONDARY]: `text-secondaryButtonText-50 bg-secondaryButtonBg-50`,
    [BadgeMode.SUCCESS]: `text-successButtonText-50 bg-successButtonBg-50`,
    [BadgeMode.WARNING]: `text-warningButtonText-50 bg-warningButtonBg-50`,
    [BadgeMode.DANGER]: `text-dangerButtonText-50 bg-dangerButtonBg-50`
  };

  let sizeStyles = {
    [BadgeSize.MD]: 'text-xs px-2.5 py-0.5',
    [BadgeSize.LG]: 'text-xs px-4 py-0.5'
  };

  if (removeAction) {
    sizeStyles = {
      [BadgeSize.MD]: 'text-xs pl-2.5 pr-0 py-0.5',
      [BadgeSize.LG]: 'text-xs pl-2.5 pr-0 py-0.5'
    };
  }

  return (
    <span
      className={overrideClassName(`${shared} ${modeStyles[mode]} ${sizeStyles[size]}`, className)}
      data-testid="badge-container">
      {children}
      {removeAction ? (
        <Button
          icon="close"
          mode={mode}
          size={ButtonSize.XS}
          iconClassName="h-4 w-4"
          className="ml-0.5"
          onClick={removeAction}
          data-testid="badge-remove-button"
        />
      ) : null}
    </span>
  );
};
export default Badge;
