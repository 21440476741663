import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getPlots } from '@src/plots/data';
import useAuth from '@src/auth/useAuth';
import { Layer, Stage } from 'react-konva';
import Konva from 'konva';
import LayoutPlot from '@src/plots/layoutPlot';
import LayoutPlots from "@src/plots/layoutPlots";
import LayoutEmptyPlot from "@src/plots/layoutEmptyPlot";

let START_ROW = -14;
let ROWS = 26;
let PLOTS_PER_ROW = 49;

let scaleBy = 1.05;

Konva.hitOnDragEnabled = true;

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2
  };
}

// function isTouchEnabled() {
//   return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
// }

let Layout = () => {
  let { db } = useAuth();
  let [rows, setRows] = useState<any>();
  let stageRef = useRef<any>(null);
  let lastCenter = useRef<any>(null);
  let lastDist = useRef(0);
  let navigate = useNavigate();

  // load and parse data
  useEffect(() => {
    let fn = async () => {
      let pf = getPlots(db);
      let d = await pf({
        params: {
          json: JSON.stringify({
            stopIndex: 10000
          })
        }
      });
      let rk = {};
      d.items.forEach((p: any) => {
        if (/[EW]$/.test(p.row)) {
          let rowNumber = parseFloat(p.row.replace(/E|W/, '')) * (p.row.includes('E') ? 1 : -1);
          if (!rk[rowNumber]) {
            rk[rowNumber] = {
              row: p.row,
              rowNumber,
              plots: {}
            };
          }
          let plotNumber = (p.plot || '').replace(/\D+/, '');
          if (!rk[rowNumber].plots[plotNumber]) {
            rk[rowNumber].plots[plotNumber] = [];
          }
          rk[rowNumber].plots[plotNumber].push(p);
        }
      });

      let rows: any = [];
      for (let i = START_ROW; i < ROWS + START_ROW; i++) {
        if (i) {
          let row: any = [];
          for (let i2 = 0; i2 < PLOTS_PER_ROW; i2++) {
            row.push(
              rk[i]?.plots[i2 + 1] || {
                row: `${Math.abs(i)}${i > 0 ? 'E' : 'W'}`,
                rowNumber: i,
                plot: i2 + 1,
                empty: true
              }
            );
          }
          rows.push(row);
        }
      }
      setRows(rows);
    };
    fn();
  }, [db]);

  let zoomStage = useCallback((event) => {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      let stage = stageRef.current;
      let oldScale = stage.scaleX();
      let { x: pointerX, y: pointerY } = stage.getPointerPosition();
      let mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale
      };
      let newScale = event.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      let newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  }, []);

  let handleTouch = useCallback((e) => {
    e.evt.preventDefault();
    let touch1 = e.evt.touches[0];
    let touch2 = e.evt.touches[1];
    let stage = stageRef.current;
    if (stage !== null) {
      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        let p1 = {
          x: touch1.clientX,
          y: touch1.clientY
        };
        let p2 = {
          x: touch2.clientX,
          y: touch2.clientY
        };

        if (!lastCenter.current) {
          lastCenter.current = getCenter(p1, p2);
          return;
        }
        let newCenter = getCenter(p1, p2);

        let dist = getDistance(p1, p2);

        if (!lastDist.current) {
          lastDist.current = dist;
        }

        // local coordinates of center point
        let pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX()
        };

        let scale = stage.scaleX() * (dist / lastDist.current);

        stage.scaleX(scale);
        stage.scaleY(scale);

        // calculate new position of the stage
        let dx = newCenter.x - lastCenter.current.x;
        let dy = newCenter.y - lastCenter.current.y;

        let newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy
        };

        stage.position(newPos);
        stage.batchDraw();

        lastDist.current = dist;
        lastCenter.current = newCenter;
      }
    }
  }, []);

  let handleTouchEnd = useCallback(() => {
    lastCenter.current = null;
    lastDist.current = 0;
  }, []);

  let plotClick = (plot) => {
    if (plot.id) {
      navigate(`/layout/${plot.id}`);
    } else {
      navigate(`/layout/new?row=${plot.row}&plot=${plot.plot}`);
    }
  };

  let ST = Stage as any;
  return (
    <>
      <div className="flex-auto flex flex-col relative overflow-hidden">
        <ST
          width={window.innerWidth}
          height={window.innerHeight}
          draggable={true}
          onWheel={zoomStage}
          onTouchMove={handleTouch}
          onTouchEnd={handleTouchEnd}
          ref={stageRef}
          perfectDrawEnabled={false}>
          <Layer perfectDrawEnabled={false}>
            {rows?.map((row: any, i: number) =>
              row.map((plot, i2) => Array.isArray(plot) ? (
                <LayoutPlots
                  key={i + '-' + i2}
                  plots={plot}
                  i={i}
                  i2={i2}
                  plotClick={plotClick}
                />
              ) : (
                <LayoutEmptyPlot
                  key={i + '-' + i2}
                  plot={plot}
                  i={i}
                  i2={i2}
                  onClick={plotClick.bind(null, plot)}
                />))
            )}
          </Layer>
        </ST>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
