import React from 'react';
import Icon, { IconSize } from '../icon';
import { overrideClassName } from '../index';
import Button, { ButtonMode, ButtonSize } from '../button';

export enum ConfirmMode {
  //WARNING = 'Warning',
  //SUCCESS = 'Success',
  DANGER = 'Danger'
}

let Confirm = (props) => {
  let { setConfirm, label, children, mode = ConfirmMode.DANGER, className, icon, submitLabel = 'OK' } = props;

  let modeStyles = {
    //[ConfirmMode.WARNING]: `bg-white shadow-xl`,
    //[ConfirmMode.SUCCESS]: `bg-white shadow-xl`,
    [ConfirmMode.DANGER]: `bg-white dark:bg-zinc-700 shadow-xl`
  };
  let icons = {
    // [ConfirmMode.WARNING]: 'information',
    //[ConfirmMode.SUCCESS]: 'check-circle',
    [ConfirmMode.DANGER]: 'information'
  };

  return (
    <div
      className={overrideClassName(`rounded-md  ${modeStyles[mode]} p-6`, className)}
      data-testid="confirm-container">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon mode={mode} size={IconSize.XL} icon={icon || icons[mode]} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-lg leading-6 font-medium`}>{label}</h3>
          {React.Children.toArray(children).length ? (
            <div className={`mt-2 text-sm text-zinc-500`}>{children}</div>
          ) : null}
        </div>
      </div>
      <div className="flex mt-8">
        <div className="flex-auto" />
        <Button
          size={ButtonSize.SM}
          tabIndex={'2'}
          autoFocus={false}
          mode={ButtonMode.SECONDARY}
          className="ml-3 w-[92px]"
          onClick={() => {
            setConfirm.current(false);
          }}
          icon="close">
          Cancel
        </Button>
        <Button
          size={ButtonSize.SM}
          tabIndex={'1'}
          autoFocus={true}
          mode={ButtonMode.PRIMARY}
          onClick={() => {
            setConfirm.current(true);
          }}
          icon={'check'}
          className="ml-3 w-[92px]">
          {submitLabel}
        </Button>
      </div>
    </div>
  );
};

export default Confirm;
