import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import Icon from '../icon';

let SidebarLinkSm = (props) => {
  let { item, setSidebarOpen } = props;
  let match = useMatch({ path: item.path });
  return (
    <Link
      to={item.to || item.path}
      onClick={() => setSidebarOpen?.(false)}
      className={`${
        match ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-600 hover:text-zinc-900 hover:bg-zinc-50'
      } group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md`}
      aria-current={match ? 'page' : undefined}>
      <Icon
        icon={item.icon}
        className={`${
          item.current ? 'text-zinc-500' : 'text-zinc-400 group-hover:text-zinc-500'
        } mr-3 flex-shrink-0 h-6 w-6`}
        aria-hidden="true"
      />
      {item.label}
    </Link>
  );
};
export default SidebarLinkSm;
