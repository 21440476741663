import React from 'react';
import { FormDate } from '../form';

let DateValue = (props) => {
  let { input, label = 'Date' } = props;
  return (
    <div className="p-4">
      <FormDate input={input} label={label} divClassName="ml-0 mt-0" />
    </div>
  );
};
export default DateValue;
